import { combineReducers } from 'redux';
import { LIST_DELIVERY_RADIUSES } from '../actions/types';

const initialState = {}

export default function(state = initialState, action) {
    switch(action.type) {
        case LIST_DELIVERY_RADIUSES: {
            return action.payload
        }
        default:
            return state;
    }
}