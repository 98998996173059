import axios from 'axios';
import { LIST_RESERVATIONS } from './types';
import { API_URL, ADMIN_URL } from './../../../constants';
import { addSuccessMessage, addErrorMessages } from './../../messages/messages.actions';
import { changeLanguage } from './../../../actions/languagesActions';
import Auth from './../../../utils/Auth';
import jwtDecode from 'jwt-decode';
import { showLoading, hideLoading } from './../../common/loading/loading.actions';
import moment from 'moment';

export const listReservations = (start_time=null, end_time=null, status=null, place_id = 0) => dispatch => {
    let query = '';
    // if(place_id == 0) {
        place_id = Auth.getUserPlace();
        query += "?place_id=" + place_id;
    // }

    if(start_time) {
        start_time = moment(start_time).format('YYYY-MM-DD')
        query += `&start_time=${start_time}`;
    }
    if(end_time) {
        end_time = moment(end_time).format('YYYY-MM-DD') + " 23:59";
        query += `&end_time=${encodeURIComponent(end_time)}`;
    }
    if(status) {
        query += `&status=${status}`
    }

    axios
        .get(API_URL + 'reservations' + query)
        .then(res => {
            dispatch({
                type: LIST_RESERVATIONS,
                payload: res.data
            });
        })
        .catch(error => {
            throw (error);
        });
}

export const editReservation = (data) => dispatch => {
    axios
        .post(API_URL + 'reservations/edit', data)
        .then(res => {
            dispatch(listReservations());
        })
        .catch(error => {
            throw (error);
        });
}