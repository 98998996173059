import axios from 'axios';
import { LIST_PROFORMS } from './types';
import { API_URL, ADMIN_URL } from './../../../constants';
import { addSuccessMessage, addErrorMessages } from './../../messages/messages.actions';
import { changeLanguage } from './../../../actions/languagesActions';
import Auth from './../../../utils/Auth';
import jwtDecode from 'jwt-decode';

export const listProforms = () => dispatch => {
    let currUser = jwtDecode(Auth.getToken());

    let query = "";

    axios
        .get(API_URL + `proforms${query}`)
        .then(res => {
            dispatch({
                type: LIST_PROFORMS,
                payload: res && res.data ? res.data : []
            });
        })
        .catch(error => {
            throw (error);
        });
}

export const addEditProform = (postData, history) => dispatch => {
    return axios
        .post(API_URL + 'proforms', postData)
        // .then((post) => {
        //     post = post.data;
        //     // dispatch(addEditAllergenTranslation(post.id, langId, postData))
        //     // if (!postData.id && typeof postData.deleted === "undefined") { // edit
        //     // } else {
        //     dispatch(listProforms());
        //     history.push(ADMIN_URL + 'proforms/edit/'+post.id);
        //     dispatch(addSuccessMessage("Changes saved"))

        //     // }
        // })
        // .catch(function (error) {
        //     if(error && error.response && error.response.data) {
        //         dispatch(addErrorMessages(error.response.data.error.errors));
        //     } else {
        //         dispatch(addErrorMessages('Error occured updating the record. Please contact us'));
        //     }
        // })
}