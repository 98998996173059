import axios from 'axios';
import { LIST_TABLE_REGIONS, LIST_TABLE_REGIONS_FOR_ORDER } from './types';
import { API_URL, ADMIN_URL, FILTER_PLACES_NAME } from './../../../constants';
import { addSuccessMessage, addErrorMessages } from './../../messages/messages.actions';
import { changeLanguage } from './../../../actions/languagesActions';
import Auth from './../../../utils/Auth';
import jwtDecode from 'jwt-decode';
import { showLoading, hideLoading } from './../../common/loading/loading.actions';




export const listtableRegionsForSelectedPlaces = (place_id = 0) => dispatch => {
let getParam = '';
// let query = '';
    // if(place_id == 0) {
    //     place_id = Auth.getUserPlace();
    //     query += "?place_id=" + place_id;
    // }

    if (place_id == 0) {
        place_id = (!window.localStorage.getItem(FILTER_PLACES_NAME) || window.localStorage.getItem(FILTER_PLACES_NAME) == '[]') ? Auth.getUserPlace() : (window.localStorage.getItem(FILTER_PLACES_NAME).slice(1, -1))
        getParam += "?place_id=" + place_id;
    }

    axios
        .get(API_URL + 'tableRegionsForSelectedPlaces' + getParam)
        .then(res => {
            dispatch({
                type: LIST_TABLE_REGIONS_FOR_ORDER,
                payload: res.data
            });
        })
        .catch(error => {
            throw (error);
        });
}

export const listTableRegions = (place_id = 0) => dispatch => {
    let query = '';
    if(place_id == 0) {
        place_id = Auth.getUserPlace();
        query += "?place_id=" + place_id;
    }

    axios
        .get(API_URL + 'tableRegions' + query)
        .then(res => {
            dispatch({
                type: LIST_TABLE_REGIONS,
                payload: res.data
            });
        })
        .catch(error => {
            throw (error);
        });
}


export const addEditTableRegion = (data, history = null, langId = null) => dispatch => {
    if(!data.placeId) {
        data.placeId = Auth.getUserPlace();
    }


    return axios.post(API_URL + 'tableRegions', data)
        
}