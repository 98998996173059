import React from 'react'
import { NavLink } from 'react-router-dom';
import { ADMIN_URL, IMG_PATH } from '../../../constants.js'
import DeleteModal from './../../common/DeleteModal';
import Utils from '../../../utils/Utils';
import moment from 'moment';
import Select from 'react-select'

const ListBookingsItem = (props) => (
    <div className="col-sm-6 col-md-4 col-lg-3">
        <div className="card">
            <div className="card-body p-0 white-space-break-all-container">

                <div style={{ display: 'grid', gridTemplateColumns: '50% 50%', textAlign: 'center' }} >

                    <div>

                        {props.booking.client_name}<br />
                        <a href={'tel:' + props.booking.client_phone}> {props.booking.client_phone}</a><br />
                        <a href={'mailto:' + props.booking.client_email}> {props.booking.client_email}</a>

                        <br />
                        {moment(props.booking.start_time).format('DD/MM HH:mm')} - {moment(props.booking.end_time).format('DD/MM HH:mm')}
                    </div>
                    <div>
                        <br />
                        {props.booking.product_variant && props.booking.product_variant.product.name || ''} - {props.booking.product_variant && props.booking.product_variant.booking_duration || ''} мин.
                        <br />
                        {props.booking.product_variant && props.booking.product_variant.price || ''} {props.translations.common.currency}
                        <br />
                    </div>

                </div>

                <br />

                <Select name="status" options={props.autocompleteStatuses}
                    // style={{ width: '90% !important', margin: '0 auto' }}
                    onChange={(newValue) => props.onChangeSelectAutocompleteStatus(props.booking.id, newValue)}
                    value={{ value: props.booking.status, label: props.translations.bookings.statuses[props.booking.status] }}
                />

            </div>
        </div>
    </div >
)

export default ListBookingsItem;