import React, { Component } from 'react'
import SimpleLineIcon from 'react-simple-line-icons'; /* DO NOT REMOVE, IT IS USED */
import { connect } from 'react-redux';
import Utils from '../utils/Utils';
import { NavLink } from 'react-router-dom';
import { ADMIN_URL } from '../constants.js'
import ViewVersionFeaturesDialog from './common/ViewVersionFeaturesDialog';
import { viewVersionOpened } from './versions/actions/versions.action'
import moment from 'moment';

/*
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
*/

class DashboardPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lastVersionSeen: parseInt(window.localStorage.getItem('tabl_last_version_seen') || 0),
    }
  }

  render() {
    let prevMonth = 0;

    return (
      <main className="main">

        <ViewVersionFeaturesDialog />

        {/* <!-- Breadcrumb--> */}
        <ol className="breadcrumb">
          <li className="breadcrumb-item">{this.props.translations.common.dashboard}</li>
          {/* <!--<li className="breadcrumb-item">
            <a href="#">Admin</a>
          </li>
          <li className="breadcrumb-item active">Dashboard</li>
          --> */}
          {/* <!-- Breadcrumb Menu--> */}
          <li className="breadcrumb-menu d-md-down-none">
            <div className="btn-group" role="group" aria-label="Button group">
              {/* <!--<a className="btn" href="https://test.tabl.site" target="_blank">
                <i className="icon-screen-tablet"></i> Клиентска система
              </a>--> */}
              {/* <!--<a className="btn" href="./">
                <i className="icon-graph"></i>  Dashboard</a>
              <a className="btn" href="#">
                <i className="icon-settings"></i>  Settings</a>
                --> */}
            </div>
          </li>
        </ol>


        <div className="container-fluid">

          <div className="animated fadeIn">

            {/* <div class="tab-content"> */}
            <div class="">
              <div class="tab-pane" id="timeline" role="tabpanel">

                {Object.values(this.props.versions).reverse().map(v => {
                  let month = ''
                  if (new Date(v.createdAt).getMonth() != prevMonth) {
                    month =
                      <>
                        <div class="callout callout-info m-0 py-2 text-muted text-center bg-light text-uppercase">
                          <small><b>{this.props.translations.common.months[(new Date(v.createdAt).getMonth() + 1)]} - {new Date(v.createdAt).getFullYear()}</b></small>
                        </div>
                        <hr class="transparent mx-3 my-0" />
                      </>
                  }
                  prevMonth = new Date(v.createdAt).getMonth();

                  return (
                    <>

                      {month}

                      <div class={"callout m-0 py-3 feature_hoverable_gray " + ((this.state.lastVersionSeen && this.state.lastVersionSeen >= v.id) ? 'feature_seen' : 'callout-warning')} >
                        {/* <button type="button" class="btn btn-warning">Warning</button> */}
                        <div class="float-right">
                          <button type="button" class={"btn btn-" + ((this.state.lastVersionSeen && this.state.lastVersionSeen >= v.id) ? 'secondary' : 'warning')} onClick={() => { this.props.viewVersionOpened(v.id); window.localStorage.setItem('tabl_last_version_seen', Math.max(this.state.lastVersionSeen, v.id)); this.setState({ lastVersionSeen: Math.max(this.state.lastVersionSeen, v.id) }) }} >Отвори</button>
                          {/* <img src="https://tabl.bg/img/transparent-logo.png" class="img-avatar" alt="admin@bootstrapmaster.com" /> */}
                        </div>
                        <div>
                          {v.title}
                          {/* Добавена опция за включване и изключване на&nbsp;
                      <strong>поръчки</strong> */}
                        </div>
                        <small class="text-muted mr-3"><i class="icon-calendar"></i>&nbsp; {moment(v.createdAt).format("DD.MM.Y")}</small>{/* pin */}
                        {/* <small class="text-muted"><i class="icon-location-pin"></i>&nbsp; Palo Alto, CA </small> */}
                      </div>
                    </>
                  )
                })}

                {/* <hr class="mx-3 my-0" />
                <div class="callout m-0 py-3 feature_hoverable_gray feature_seen">
                  <div class=" float-right">
                    <button type="button" class="btn btn-secondary">Отвори</button>
                  </div>
                  <div>Skype with
                      <strong>Megan</strong>
                  </div>
                  <small class="text-muted mr-3"><i class="icon-calendar"></i>&nbsp; 4 - 5pm</small>
                  <small class="text-muted"><i class="icon-social-skype"></i>&nbsp; On-line </small>
                </div> */}
                {/* <hr class="transparent mx-3 my-0" />
                <div class="callout callout-info m-0 py-2 text-muted text-center bg-light text-uppercase">
                  <small><b>Февруари - 2021</b></small>
                </div>
                <hr class="transparent mx-3 my-0" /> */}
              </div>

            </div>


            <br />
            <br />

            <div className="row" style={{ display: 'none' }}>

              <div className="col-sm-6 col-md-3">

                <div className="card">
                  <div className="card-body">
                    <div className="h1 text-muted text-right mb-4 float-right">
                      <i className="icon-basket-loaded"></i>
                    </div>
                    <div className="text-value">1238</div>
                    <small className="text-muted text-uppercase font-weight-bold">Orders today</small>
                    <div className="progress progress-xs mt-3 mb-0">
                      {/* <div className="progress-bar bg-warning" role="progressbar" style={{width: "25%"}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div> */}
                      {/* <div>{Utils.translate(this.props.translations.dashboard.totalAndTodayOrders, { total: 13466, today: 5133 })}</div> */}
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-sm-6 col-md-3">
                <div className="card">
                  <div className="card-body">
                    <div className="h1 text-muted text-right mb-4 float-right">
                      <i className="icon-speedometer"></i>
                    </div>
                    <div className="text-value">2511.25</div>
                    <small className="text-muted text-uppercase font-weight-bold">Daily Turnover</small>
                    <div className="progress progress-xs mt-3 mb-0">
                      {/* <div className="progress-bar bg-danger" role="progressbar" style={{width: "25%"}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div> */}
                    </div>
                  </div>
                </div>
              </div>


              <div className="col-sm-6 col-md-3">
                <div className="card">
                  <div className="card-body">
                    <div className="h1 text-muted text-right mb-4 float-right">
                      <i className="icon-fire"></i>
                    </div>
                    <div className="text-value">Pizza italiana</div>
                    <small className="text-muted text-uppercase font-weight-bold">Fav. Product</small>
                    <div className="progress progress-xs mt-3 mb-0">
                      {/* <div className="progress-bar bg-info" role="progressbar" style={{width: "25%"}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div> */}
                    </div>
                  </div>
                </div>
              </div>


              <div className="col-sm-6 col-md-3">
                <div className="card">
                  <div className="card-body">
                    <div className="h1 text-muted text-right mb-4 float-right">
                      <i className="icon-star"></i>
                    </div>
                    <div className="text-value">4.3 of 5</div>
                    <small className="text-muted text-uppercase font-weight-bold">Rating</small>
                    <div className="progress progress-xs mt-3 mb-0">
                      <div className="progress-bar bg-warning" role="progressbar" style={{ width: "65%" }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                  </div>
                </div>
              </div>


            </div>






            <div className="row" style={{ display: 'none' }}>
              <div className="col-sm-6 col-md-3">
                <div className="card">
                  <div className="card-header">Orders</div>
                  <div className="card-body p-0 pt-3">
                    <ul className="nav nav-tabs" role="tablist">
                      <li className="nav-item">
                        <a className="nav-link active border-left-0" data-toggle="tab" href="#orders-daily" role="tab" aria-controls="home">Daily</a>
                        {/* TODO */}
                        {/* VNIMANIEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEE */}
                        {/* NIKIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIII */}
                        {/* TUK SAMO PURViQ <a> tag ima tezi 2 klasa: "active border-left-0" */}
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" data-toggle="tab" href="#orders-weekly" role="tab" aria-controls="profile">Weekly</a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" data-toggle="tab" href="#orders-monthly" role="tab" aria-controls="messages">Monthly</a>
                      </li>
                    </ul>
                    <div className="tab-content border-right-0 border-bottom-0 border-left-0" >
                      <div className="tab-pane active p-0" id="orders-daily" role="tabpanel">
                        <ul className="list-group dashboard-ui-container">
                          <li className="list-group-item d-flex list-group-item-action justify-content-between align-items-center">
                            05.04.20 - Sun
                            <span className="badge badge-primary badge-pill">14</span>
                          </li>
                          <li className="list-group-item d-flex list-group-item-action justify-content-between align-items-center">
                            04.04.20 - Sat
                            <span className="badge badge-primary badge-pill">342</span>
                          </li>
                          <li className="list-group-item d-flex list-group-item-action justify-content-between align-items-center">
                            03.04.20 - Fri
                            <span className="badge badge-primary badge-pill">251</span>
                          </li>
                        </ul>
                      </div>
                      <div className="tab-pane p-0" id="orders-weekly" role="tabpanel">
                        <ul className="list-group dashboard-ui-container">
                          <li className="list-group-item d-flex list-group-item-action justify-content-between align-items-center">
                            30.03.20 - 05.04.20
                            <span className="badge badge-primary badge-pill">6273</span>
                          </li>
                          <li className="list-group-item d-flex list-group-item-action justify-content-between align-items-center">
                            23.03.20 - 29.03.20
                            <span className="badge badge-primary badge-pill">6277</span>
                          </li>
                          <li className="list-group-item d-flex list-group-item-action justify-content-between align-items-center">
                            16.03.20 - 22.03.20
                            <span className="badge badge-primary badge-pill">10033</span>
                          </li>
                        </ul>
                      </div>
                      <div className="tab-pane p-0" id="orders-monthly" role="tabpanel">
                        <ul className="list-group dashboard-ui-container">
                          <li className="list-group-item d-flex list-group-item-action justify-content-between align-items-center">
                            April
                            <span className="badge badge-primary badge-pill">273</span>
                          </li>
                          <li className="list-group-item d-flex list-group-item-action justify-content-between align-items-center">
                            March
                            <span className="badge badge-primary badge-pill">24623</span>
                          </li>
                          <li className="list-group-item d-flex list-group-item-action justify-content-between align-items-center">
                            February
                            <span className="badge badge-primary badge-pill">16322</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>






              <div className="col-sm-6 col-md-3">
                <div className="card">
                  <div className="card-header">Turnover</div>
                  <div className="card-body p-0 pt-3">
                    <ul className="nav nav-tabs" role="tablist">
                      <li className="nav-item">
                        <a className="nav-link active border-left-0" data-toggle="tab" href="#turnover-daily" role="tab" aria-controls="home">Daily</a>
                        {/* TODO */}
                        {/* VNIMANIEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEE */}
                        {/* NIKIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIII */}
                        {/* TUK SAMO PURViQ <a> tag ima tezi 2 klasa: "active border-left-0" */}
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" data-toggle="tab" href="#turnover-weekly" role="tab" aria-controls="profile">Weekly</a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" data-toggle="tab" href="#turnover-monthly" role="tab" aria-controls="messages">Monthly</a>
                      </li>
                    </ul>
                    <div className="tab-content border-right-0 border-bottom-0 border-left-0" >
                      <div className="tab-pane active p-0" id="turnover-daily" role="tabpanel">
                        <ul className="list-group dashboard-ui-container">
                          <li className="list-group-item d-flex list-group-item-action justify-content-between align-items-center">
                            05.04.20 - Sun
                            <span className="badge badge-primary badge-pill">250</span>
                          </li>
                          <li className="list-group-item d-flex list-group-item-action justify-content-between align-items-center">
                            04.04.20 - Sat
                            <span className="badge badge-primary badge-pill">632</span>
                          </li>
                          <li className="list-group-item d-flex list-group-item-action justify-content-between align-items-center">
                            03.04.20 - Fri
                            <span className="badge badge-primary badge-pill">724</span>
                          </li>
                        </ul>
                      </div>
                      <div className="tab-pane p-0" id="turnover-weekly" role="tabpanel">
                        <ul className="list-group dashboard-ui-container">
                          <li className="list-group-item d-flex list-group-item-action justify-content-between align-items-center">
                            30.03.20 - 05.04.20
                            <span className="badge badge-primary badge-pill">6273</span>
                          </li>
                          <li className="list-group-item d-flex list-group-item-action justify-content-between align-items-center">
                            23.03.20 - 29.03.20
                            <span className="badge badge-primary badge-pill">6277</span>
                          </li>
                          <li className="list-group-item d-flex list-group-item-action justify-content-between align-items-center">
                            16.03.20 - 22.03.20
                            <span className="badge badge-primary badge-pill">10033</span>
                          </li>
                        </ul>
                      </div>
                      <div className="tab-pane p-0" id="turnover-monthly" role="tabpanel">
                        <ul className="list-group dashboard-ui-container">
                          <li className="list-group-item d-flex list-group-item-action justify-content-between align-items-center">
                            April
                            <span className="badge badge-primary badge-pill">273</span>
                          </li>
                          <li className="list-group-item d-flex list-group-item-action justify-content-between align-items-center">
                            March
                            <span className="badge badge-primary badge-pill">24623</span>
                          </li>
                          <li className="list-group-item d-flex list-group-item-action justify-content-between align-items-center">
                            February
                            <span className="badge badge-primary badge-pill">16322</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>





              <div className="col-sm-6 col-md-6">
                <div className="card">
                  <div className="card-header">Favourite Product</div>
                  <div className="card-body p-0 pt-3">
                    <ul className="nav nav-tabs" role="tablist">
                      <li className="nav-item">
                        <a className="nav-link active border-left-0" data-toggle="tab" href="#fav-product-daily" role="tab" aria-controls="home">Daily</a>
                        {/* TODO */}
                        {/* VNIMANIEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEE */}
                        {/* NIKIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIII */}
                        {/* TUK SAMO PURViQ <a> tag ima tezi 2 klasa: "active border-left-0" */}
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" data-toggle="tab" href="#fav-product-weekly" role="tab" aria-controls="profile">Weekly</a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" data-toggle="tab" href="#fav-product-monthly" role="tab" aria-controls="messages">Monthly</a>
                      </li>
                    </ul>
                    <div className="tab-content border-right-0 border-bottom-0 border-left-0" >
                      <div className="tab-pane active p-0" id="fav-product-daily" role="tabpanel">
                        <ul className="list-group dashboard-ui-container">
                          <li className="list-group-item d-flex list-group-item-action justify-content-between align-items-center">
                            05.04.20 - Sun
                            <NavLink to={ADMIN_URL + `products/edit/167`}>
                              <span className="badge badge-primary badge-pill">5 x Pizza italiana</span>
                            </NavLink>
                          </li>
                          <li className="list-group-item d-flex list-group-item-action justify-content-between align-items-center">
                            04.04.20 - Sat
                            <NavLink to={ADMIN_URL + `products/edit/167`}>
                              <span className="badge badge-primary badge-pill">2 x Salad with ice</span>
                            </NavLink>
                          </li>
                          <li className="list-group-item d-flex list-group-item-action justify-content-between align-items-center">
                            03.04.20 - Fri
                            <NavLink to={ADMIN_URL + `products/edit/167`}>
                              <span className="badge badge-primary badge-pill">1 x Ice tea</span>
                            </NavLink>
                          </li>
                        </ul>
                      </div>
                      <div className="tab-pane p-0" id="fav-product-weekly" role="tabpanel">
                        <ul className="list-group dashboard-ui-container">
                          <li className="list-group-item d-flex list-group-item-action justify-content-between align-items-center">
                            30.03.20 - 05.04.20
                              <NavLink to={ADMIN_URL + `products/edit/167`}>
                              <span className="badge badge-primary badge-pill">77 x Pizza italiana</span>
                            </NavLink>
                          </li>
                          <li className="list-group-item d-flex list-group-item-action justify-content-between align-items-center">
                            23.03.20 - 29.03.20
                              <NavLink to={ADMIN_URL + `products/edit/167`}>
                              <span className="badge badge-primary badge-pill">34 x Salad with ice</span>
                            </NavLink>
                          </li>
                          <li className="list-group-item d-flex list-group-item-action justify-content-between align-items-center">
                            16.03.20 - 22.03.20
                              <NavLink to={ADMIN_URL + `products/edit/167`}>
                              <span className="badge badge-primary badge-pill">11 x Ice tea</span>
                            </NavLink>
                          </li>
                        </ul>
                      </div>
                      <div className="tab-pane p-0" id="fav-product-monthly" role="tabpanel">
                        <ul className="list-group dashboard-ui-container">
                          <li className="list-group-item d-flex list-group-item-action justify-content-between align-items-center">
                            April
                            <NavLink to={ADMIN_URL + `products/edit/167`}>
                              <span className="badge badge-primary badge-pill">175 x Pizza italiana</span>
                            </NavLink>
                          </li>
                          <li className="list-group-item d-flex list-group-item-action justify-content-between align-items-center">
                            March
                              <NavLink to={ADMIN_URL + `products/edit/167`}>
                              <span className="badge badge-primary badge-pill">62 x Salad with ice</span>
                            </NavLink>
                          </li>
                          <li className="list-group-item d-flex list-group-item-action justify-content-between align-items-center">
                            February
                              <NavLink to={ADMIN_URL + `products/edit/167`}>
                              <span className="badge badge-primary badge-pill">7 x Ice tea</span>
                            </NavLink>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>




          </div>
        </div>
      </main>
    )
  }
}

const mapStateToProps = state => ({
  translations: state.lang,
  versions: state.versions.versions
});

export default connect(mapStateToProps, { viewVersionOpened })(DashboardPage);