import React from 'react'
import { NavLink } from 'react-router-dom';
import { ADMIN_URL, IMG_PATH } from '../../../constants.js'
import DeleteAlergenModal from './DeleteAlergenModal';
import Utils from '../../../utils/Utils';

const ListEachCategory = (props) => (
    <tr key={props.alergen.id}>
        <td>
            {/* If not added translation for the defailt language */}
            {/* {props.alergen.name} */}
            {props.alergen.translations[props.currLang] && props.alergen.translations[props.currLang].name 
			? props.alergen.translations[props.currLang].name : props.alergen.name}
        </td>
        <td>
            {props.alergen.image ? <img style={{ maxHeight: 100 }} src={Utils.getCategoryImagePath(props.alergen.image)} /> : ""}
            {/* {props.alergen.icon} */}
        </td>
        <td>
            <NavLink to={ADMIN_URL + 'alergens/edit/' + props.alergen.id}>
                <button className="btn btn-pill btn-link" type="button">
                    <i className="fas fa-edit"></i>
                </button>
            </NavLink>

            <button className="btn btn-pill btn-link" type="button" data-toggle="modal" data-target={"#dangerModal" + props.alergen.id} >
                <i className="fas fa-trash"></i>
            </button>

            <DeleteAlergenModal
                alergenId={props.alergen.id}
                alergenName={props.alergen.name}
                deleteAlergen={props.deleteAlergen}
                translations={props.translations}
            />

        </td>
    </tr>
)

export default ListEachCategory;