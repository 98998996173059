import React, { Component } from 'react';
import { NavLink } from "react-router-dom";
import { connect } from 'react-redux';
// import { history } from '../../utils/history';
import { history } from './../../store';

import { login, setCurrentUser } from '../../actions/authActions';
import jwt from 'jsonwebtoken';
import {ADMIN_URL, FILTER_PLACES_NAME} from './../../constants';
import Auth from './../../utils/Auth';
import MessagesContainer  from './../messages/MessagesContainer';
import { addErrorMessages }  from './../messages/messages.actions';

import jwtDecode from 'jwt-decode';
import setAuthorizationToken from '../../utils/setAuthorizationToken';

// import '../../../css/login.css';

class Login extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            password: ''
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    isLoggedIn(){
        if (jwt.decode(Auth.getToken()) && jwt.decode(Auth.getToken()) != undefined){
            history.push('/admin/list-orders/all');
        }
    }

    componentWillMount() {
        this.isLoggedIn();
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleSubmit(e) {
        e.preventDefault();

        if (!!this.state.email && !!this.state.password) {
            this.props.login(this.state).then(res => {
                const token = res.data.token;
                if (token) {
                    Auth.setToken(token);
                    setAuthorizationToken(token);
    
                    let currUser = jwtDecode(token);
                    this.props.setCurrentUser(currUser);
                    Auth.setUserPlace(currUser.place_id)
                    window.localStorage.setItem(FILTER_PLACES_NAME, JSON.stringify([currUser.place_id]));

                    if(currUser.role != "PDF_MENU_ADMIN") {
                        window.location.href = '/admin/list-orders/all';
                    } else {
                        window.location.href = '/admin';                        
                    }
    
                } else {
                    this.props.addErrorMessages([this.props.translations.messages.errors.invalid_credentials]);
                }
            })
            .catch(err => {
                this.props.addErrorMessages([this.props.translations.messages.errors.invalid_credentials]);
            });
        } else {
            this.props.addErrorMessages([this.props.translations.messages.errors.invalid_credentials]);
        }
    }

    render(){
        const { email, password } = this.state;

        return (
            <div className="col-md-6 col-md-offset-3 m0auto mtop50">
                <h2>{this.props.translations.login.title}</h2>
                <MessagesContainer/>
                <form name="form" onSubmit={this.handleSubmit}>
                    <div className={'form-group'}>
                        <label htmlFor="username">{this.props.translations.login.email}</label>
                        <input type="text" className="form-control" name="email" value={email} onChange={this.handleChange} />
                    </div>
                    <div className={'form-group'}>
                        <label htmlFor="password">{this.props.translations.login.password}</label>
                        <input type="password" className="form-control" name="password" value={password} onChange={this.handleChange} />
                    </div>
                    <div className="form-group">
                        <button className="btn btn-primary">{this.props.translations.login.login}</button>
                    </div>
                </form>
            </div>
        );
    }
}

const mapStateToProps = state => {
	return {
		translations: state.lang,
	}
};
export default connect(mapStateToProps, { login, setCurrentUser, addErrorMessages })(Login);