import React, { Component } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import moment from 'moment';
// import { listPlacesByUserRole, addEditPlace } from '../actions/places.actions';
import { NavLink } from 'react-router-dom';
import { ADMIN_URL, ITEMS_PER_PAGE, ITEMS_PAGE_RANGE_DISPLAYED } from '../../../constants.js'
import Pagination from "react-js-pagination";
import { push } from 'react-router-redux';
// import Filter from './Filter';
import MessagesContainer from '../../messages/MessagesContainer';
import { translations } from '../../common/translations';

import UsersByDateChart from './UsersByDateChart';
import AvgTimeSpentPerPageTable from './AvgTimeSpentPerPageTable';
import { countTotalUniqueUsers, countPeopleSawTheMenu, getAvgTimeSpentPerPage } from './../actions/statistics.actions';
import Auth from './../../../utils/Auth';
import Filter from './Filter';
import "react-datepicker/dist/react-datepicker.css";

import "./styles/styles.css";
import AvgTimeSpentPerPagePieChart from './AvgTimeSpentPerPagePieChart.js';
import queryString from 'query-string';


class StatisticsPage extends React.Component {
    constructor(props) {
        super(props);
        const query = queryString.parse(this.props.location.search);
        
        this.state = {
            filter_start_date: query.filter_start_date ? moment(query.filter_start_date).toDate() : moment().subtract(1, 'months').toDate(),
            filter_end_date: query.filter_start_date ? moment(query.filter_end_date).toDate() :  moment().toDate(),
            filter_place: null,

            currFilteredPlace: '',
            currFilteredStartDate: moment().subtract(1, 'months').toDate(),
            currFilteredEndDate: moment().toDate(),
        }
    }


    componentWillMount() {

        // this.props.countTotalUniqueUsers();
        this.props.countTotalUniqueUsers(null, this.state.filter_start_date, this.state.filter_end_date);
        this.props.countPeopleSawTheMenu(null, this.state.filter_start_date, this.state.filter_end_date);
        this.props.getAvgTimeSpentPerPage(null, this.state.filter_start_date, this.state.filter_end_date);

        if(this.props.places) {
            this.setCurrFilteredPlace()
        }
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.places) {
            this.setCurrFilteredPlace()
        }
    }


    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
        this.handlePageChange(1);
    }

    onDateChange = (fieldName, date) => {
        this.setState({ [fieldName]: date });
    }

	onChangeSelectAutocomplete = (fieldName, valueLabel) => {
		this.setState({ [fieldName]: valueLabel.value });
	}

    filter = () => {
        var query = queryString.parse(this.props.location.search);

        // if(this.state.filter_start_date) {
        //     let formattedStartDate = moment(this.state.filter_start_date).format('YYYY-MM-DD');
        // }
        // let formattedEndDate = moment(this.state.filter_end_date).format('YYYY-MM-DD');

        this.props.countTotalUniqueUsers(this.state.filter_place, this.state.filter_start_date, this.state.filter_end_date);
        this.props.countPeopleSawTheMenu(this.state.filter_place, this.state.filter_start_date, this.state.filter_end_date);

        this.props.getAvgTimeSpentPerPage(this.state.filter_place, this.state.filter_start_date, this.state.filter_end_date);


        this.setCurrFilteredPlace();
        this.setState({
            currFilteredStartDate: this.state.filter_start_date,
            currFilteredEndDate: this.state.filter_end_date
        })

        query.filter_start_date = moment(this.state.filter_start_date).format('Y-M-D');
        query.filter_end_date = moment(this.state.filter_end_date).format('Y-M-D');
        query = new URLSearchParams(query).toString()

        this.props.history.push(this.props.history.location.pathname + "?" + query );       

    }

    setCurrFilteredPlace = () => {
        let currPlaceName = '';
        if(this.state.filter_place == 'all') {
            currPlaceName = 'Всички обекти';
        } else {
            let currPlace = this.state.filter_place 
            ? this.props.places.find(p=>p.id == this.state.filter_place)
            : this.props.places.find(p=>p.id == Auth.getUserPlace());
            if(currPlace) { 
                currPlaceName = currPlace.name;
            }
        }

        this.setState({
            currFilteredPlace: currPlaceName
        })
    }

    render() {
        return (
            <main className="main" id="statistics-page">

                {/* <!-- Breadcrumb--> */}
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><NavLink to={ADMIN_URL}>{this.props.translations.common.dashboard}</NavLink></li>
                    <li className="breadcrumb-item active">{this.props.translations.sidemenu.statistics_orders}</li>
                    
                </ol>

                <div className="container-fluid">
                    <div className="animated fadeIn">
                        <div className="row">

                            <div className="col-lg-12">
                                <div className="card">

                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <Filter 
                                                    onDateChange={this.onDateChange}
                                                    filter={this.filter}
                                                    filter_start_date={this.state.filter_start_date}
                                                    filter_end_date={this.state.filter_end_date}
                                                    onChangeSelectAutocomplete={this.onChangeSelectAutocomplete}
                                                    translations={this.props.translations}
                                                    places={this.props.places}
                                                />
                                            </div>
                                        </div>
                                        <hr/>

                                        <div className="row">
                                            <div className="col-md-12">
                                                <h3>Обект: {this.state.currFilteredPlace}</h3>
                                                <br/>
                                            </div>
                                            <div className="col-md-7">
                                                <UsersByDateChart 
                                                    filter_start_date={this.state.currFilteredStartDate}
                                                    filter_end_date={this.state.currFilteredEndDate}
                                                />
                                            </div>
                                            <div className="col-md-5">
                                                <h5>
                                                    {this.props.translations.statistics.total_unique_users} : {moment(this.state.currFilteredStartDate).format("D/M/Y")} - {moment(this.state.currFilteredEndDate).format("D/M/Y")}
                                                </h5>
                                                <strong>{this.props.totalUniqueUsers}</strong>
                                            </div>
                                        </div>
                                        <hr/>

                                        <AvgTimeSpentPerPagePieChart
                                            filter_start_date={this.state.filter_start_date}
                                            filter_end_date={this.state.filter_end_date}
                                        />

                                        <hr/>

                                        <AvgTimeSpentPerPageTable
                                            filter_start_date={this.state.filter_start_date}
                                            filter_end_date={this.state.filter_end_date}
                                        />

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        )
    }
}

const mapStateToProps = (state, dispatch) => {
    return {
        totalUniqueUsers: state.statistics.total_unique_users && state.statistics.total_unique_users.uniqueUsersCount || 0,
        translations: state.lang,
        places: state.places.places || [],
        currLang: "bg",
    }
};

const mapDispatchToProps = (dispatch, props) => {
    return {
        dispatch,
        ...bindActionCreators({ countTotalUniqueUsers, countPeopleSawTheMenu, getAvgTimeSpentPerPage }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(StatisticsPage);
