import React, { Component } from 'react'
import ListEachProductSize from './../ListEachProductSize';
import { NavLink } from 'react-router-dom';
import { ADMIN_URL, IMG_PATH } from '../../../constants.js'
import DeleteProductModal from '../common/DeleteProductModal';
import Utils from '../../../utils/Utils';
import Auth from '../../../utils/Auth';
import Select from 'react-select'
import { editProduct } from '../../../actions/productsActions';

const ListEachProduct = (props) => {
    let catNames = [];
    props.product.cat_hierarchies.map(cat => {
        catNames.push(<li key={cat.id}>
            <b>{cat.name}</b> {cat.code ? <i>({cat.code})</i> : ''}

            {props.categoriesHierarchy && props.categoriesHierarchy[cat.parentId]
                && props.categoriesHierarchy[cat.parentId].name
                ? (" / " + props.categoriesHierarchy[cat.parentId].name + ' / ')
                : ''}

        </li>)

        // catName += '<br/>';
    })
    // props.product.cat_hierarchy && props.product.cat_hierarchy.name || ""

    // let parentCatName = "";
    // let cat = props.product.cat_hierarchy;

    // parentCatName = (props.categoriesHierarchy && props.categoriesHierarchy[cat.parentId] 
    //     && props.categoriesHierarchy[cat.parentId].name 
    //     ? (" / " + props.categoriesHierarchy[cat.parentId].name + ' / ') 
    //     : '')

    // parentCatName += props.categoriesHierarchy && props.categoriesHierarchy[cat.parentId] && props.categoriesHierarchy[cat.parentId].parentId && props.categoriesHierarchy[props.categoriesHierarchy[cat.parentId].parentId] && props.categoriesHierarchy[props.categoriesHierarchy[cat.parentId].parentId].name ? props.categoriesHierarchy[props.categoriesHierarchy[cat.parentId].parentId].name : ''

    // const [currCatHierarchy, setCurrCatHierarchy] = React.useState([]);

    const [currCategoriesHierarchy, setCurrCategoriesHierarchy] = React.useState([])
    const [editableCategory, setEditableCat] = React.useState(false);
    const [uploadedImgName, setUploadedImgName] = React.useState(null);
    const [editedProdId, setEditedProdId] = React.useState(null);

    const onChangeSelectAutocompleteCategory = (valueLabel) => {
        if (!valueLabel) {
            setEditableCat(false)
        }

        const setCurrHierarchyIds = valueLabel && Object.values(valueLabel).length > 0 ? Object.values(valueLabel).map(a => a.value) : []

        if (!setCurrHierarchyIds || setCurrHierarchyIds.length == 0) {
            return;
        }

        setCurrCategoriesHierarchy(valueLabel)
        const post = {
            id: props.product.id,
            catHierarchyIds: setCurrHierarchyIds,
        }

        props.editProduct(post);

    }

    let currCats = [];

    if (editableCategory && currCategoriesHierarchy.length == 0 && Object.keys(props.categoriesHierarchy).length !== 0 && props.product.cat_hierarchies) {
        props.product.cat_hierarchies.map(c => {
            let dashes = '';
            for (let i = 0; i < c.hierarchyLevel - 1; i++) { dashes += "-"; }

            currCats.push({
                value: c.id, 'label': dashes + c.name + (c.code ? ' (' + c.code + ')' : '') +
                    (props.categoriesHierarchy && props.categoriesHierarchy[c.parentId] &&
                        props.categoriesHierarchy[c.parentId].name
                        ? (" /" + props.categoriesHierarchy[c.parentId].name + '/')
                        : '')
            })
        })

        setCurrCategoriesHierarchy(currCats)
    }

    const addUploadedFileText = (e, editedProdId) => {
        if (e && e.target && e.target.files && e.target.files[0] && e.target.files[0].name) {
            setUploadedImgName(e.target.files[0].name);
            setEditedProdId(editedProdId);
            console.log('editedProdId', editedProdId);
        }
    }

    const editImg = (prodId) => {
        // Image append
        const files = Array.from(document.querySelector('#multi' + prodId).files)
        let formData = new FormData()

        files.forEach((file, i) => {
            formData.append("file", file)
        })
        if (files.length == 0) {
            formData = null;
        }
        props.addImage(formData, prodId, props.history);
    }

    const addImg = (prodId) => {
        // Image append
        const files = Array.from(document.querySelector('#multi' + prodId).files)
        let formData = new FormData()

        files.forEach((file, i) => {
            formData.append("file", file)
        })
        if (files.length == 0) {
            formData = null;
        }
        props.addImage(formData, prodId, props.history);
    }


    return (
        <tr key={props.product.id}>
            <td>
                {/* {props.product.translations[props.currLang] && props.product.translations[props.currLang].name
                    ? props.product.translations[props.currLang].name : props.product.name} */}


                <textarea rows="3"

                    // {/* <input */}
                    // onClick={() => this.props.toggleProductVariantEditMode(false)}
                    onBlur={e => props.updateProdName(e, props.product.id)}
                    type="text"
                    // defaultValue={props.product.translations[props.currLang] && props.product.translations[props.currLang].name
                    //     ? props.product.translations[props.currLang].name : props.product.name}
                    style={{
                        border: 'none', background: 'transparent',
                        // fontWeight: 'bold',
                        minWidth: 200,
                        width: '100%',
                        display: 'block'
                    }}
                    // title={props.product.translations[props.currLang] && props.product.translations[props.currLang].name
                    //     ? props.product.translations[props.currLang].name : props.product.name}
                    // onBlur={(ev) => props.updateWsCode(ev, props.product.id)}
                    // value={props.product.translations[props.currLang] && props.product.translations[props.currLang].name
                    //     ? props.product.translations[props.currLang].name : props.product.name}
                    value={props.productName}
                    onChange={(e) => props.onProdNameChange(e, props.product.id)}
                >
                    {/* {props.product.translations[props.currLang] && props.product.translations[props.currLang].name
                    ? props.product.translations[props.currLang].name : props.product.name} */}
                </textarea>


                {/* {typeof props.product.translations[props.currLang] !== "undefined" ? props.product.translations.bg.name : props.product.name} */}
            </td>
            {Utils.getSettingsValue(props.settings, 'show_edit_description_in_list_products_page') == '1' ?
                <td>
                    <textarea rows="3"
                        // {/* <input */}
                        // onClick={() => this.props.toggleProductVariantEditMode(false)}
                        onBlur={e => props.updateProd(e, props.product.id, 'description')}
                        type="text"
                        // defaultValue={props.product.translations[props.currLang] && props.product.translations[props.currLang].name
                        //     ? props.product.translations[props.currLang].name : props.product.name}
                        style={{
                            border: 'none', background: 'transparent',
                            // fontWeight: 'bold',
                            minWidth: 200,
                            width: '100%',
                            display: 'block'
                        }}
                        // title={props.product.translations[props.currLang] && props.product.translations[props.currLang].name
                        //     ? props.product.translations[props.currLang].name : props.product.name}
                        // onBlur={(ev) => props.updateWsCode(ev, props.product.id)}
                        // value={props.product.translations[props.currLang] && props.product.translations[props.currLang].name
                        //     ? props.product.translations[props.currLang].name : props.product.name}
                        value={props.productDesc}
                        onChange={(e) => props.onProdDescChange(e, props.product.id)}
                    >
                        {/* {props.product.translations[props.currLang] && props.product.translations[props.currLang].name
                ? props.product.translations[props.currLang].name : props.product.name} */}
                    </textarea>
                </td>
                :
                null
            }

            {/* {props.noCategoriesView != 4 ? 
                <React.Fragment>
                    <td>
                        {catName}
                    </td>
                    <td>
                        {subcatName}
                    </td>
                </React.Fragment>
            : */}
            <td>
                {editableCategory ?
                    <Select
                        value={currCategoriesHierarchy}
                        isMulti
                        name="colors"
                        options={Utils.getOptionsForAutocompleteSelect(props.categoriesHierarchy)}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={onChangeSelectAutocompleteCategory}
                    />
                    :
                    <ul onClick={() => setEditableCat(true)}>
                        {catNames}
                    </ul>
                }


            </td>
            {/* } */}


            <td>
                {props.product.product_images && props.product.product_images[0] ?
                    <div style={{
                        display: 'grid',
                        'justify-items': 'center'
                    }}>
                        <img style={{ maxHeight: 100 }} src={Utils.getProductImagePath(props.product.product_images[0].path)} />
                        <div style={{ width: '-webkit-fill-available' }}>
                            <input type='file' name='file' id={'multi' + props.product.id} multiple style={{ display: 'none' }}
                                onChange={e => editImg(props.product.id)}
                            // addUploadedFileText(e, props.product.id)}
                            />
                            <label for={"multi" + props.product.id} type="button" className="btn btn-info"
                                style={{ borderTopLeftRadius: 0, borderTopRightRadius: 0, margin: 0, width: '-webkit-fill-available' }}>
                                {props.translations.products.replace_image}
                            </label>
                            {editedProdId == props.product.id ? uploadedImgName : null}
                        </div>
                    </div>
                    :
                    <div style={{ width: '-webkit-fill-available', display: 'grid' }}>
                        <input type='file' name='file' id={'multi' + props.product.id} multiple style={{ display: 'none', width: '-webkit-fill-available' }}
                            onChange={e => addImg(props.product.id)}
                        // addUploadedFileText(e, props.product.id)}
                        />
                        <label for={"multi" + props.product.id} type="button" className="btn btn-secondary"
                            style={{ margin: 0 }}>
                            {props.translations.products.upload_image}
                        </label>
                        {editedProdId == props.product.id ? uploadedImgName : null}
                    </div>

                }
            </td>
            <td>
                {/* SIZE */}
                {/* //TODO Get real language */}
                {Object.keys(props.product.variants).map((keySize, indexSize) => {
                    // if (typeof props.product.variants[keySize].translations.bg !== "undefined") /* Show only BG translated variants */
                    return <ListEachProductSize
                        key={indexSize}
                        currentEdit='size'
                        productSize={props.product.variants[keySize]}
                        updateProductVariantPrice={props.updateProductVariantPrice}
                        // productSizeValue={typeof props.product.variants[keySize].translations.bg !== "undefined" ? props.product.variants[keySize].translations.bg.size : props.product.variants[keySize].size} key={keySize} 
                        toggleProductVariantEditMode={props.toggleProductVariantEditMode}
                        productSizeValue={props.product.variants[keySize].translations[props.currLang]
                            && props.product.variants[keySize].translations[props.currLang].size
                            ? props.product.variants[keySize].translations[props.currLang].size : props.product.variants[keySize].size}
                    />
                })}
            </td>
            <td>
                {/* PRICE */}
                {Object.keys(props.product.variants).map((keySize, indexSize) => {
                    // if (typeof props.product.variants[keySize].translations.bg !== "undefined") /* Show only BG translated variants */
                    return <ListEachProductSize enablePriceEdit={true}
                        currentEdit='price'
                        productVariantEditMode={props.productVariantEditMode == props.product.variants[keySize].id}
                        toggleProductVariantEditMode={props.toggleProductVariantEditMode}
                        updateProductVariantPrice={props.updateProductVariantPrice}
                        productSize={props.product.variants[keySize]}
                        productSizeValue={props.product.variants[keySize].price}
                        productAdditionalPrice={props.product.variants[keySize].additional_price}
                        key={keySize}
                    />
                })}
            </td>
            {
                (Auth.hasAccess("SUPER_ADMIN") || Auth.hasAccess('CLIENT_ADMIN') || Auth.hasAccess('PLACE_ADMIN')) && props.currPlace && props.currPlace.warehouseSystemId ?
                    <td>
                        {/* PRODUCT VARIANT WS CODE */}
                        {Object.keys(props.product.variants).map((keySize, indexSize) => {
                            // if (typeof props.product.variants[keySize].translations.bg !== "undefined") /* Show only BG translated variants */
                            return <ListEachProductSize enablePriceEdit={true}
                                currentEdit='ws_code'
                                productVariantEditMode={props.productVariantEditMode == props.product.variants[keySize].id}
                                toggleProductVariantEditMode={props.toggleProductVariantEditMode}
                                updateProductVariantPrice={props.updateProductVariantPrice}
                                productSize={props.product.variants[keySize]}
                                productSizeValue={props.product.variants[keySize].price}
                                productAdditionalPrice={props.product.variants[keySize].additional_price}
                                key={keySize}
                                updateProductVariantWSCode={props.updateProductVariantWSCode}

                            />
                        })}
                    </td>
                    :
                    null
            }
            <td>
                {/* {props.product.active ? <span className="badge badge-success">{props.translations.common.active}</span> : <span className="badge badge-secondary">{props.translations.common.inactive}</span>} */}
                {/* <label className="col-md-1 col-form-label">{props.translations.common.active}</label> */}
                <div className="col-md-2 col-form-label">
                    <label className="switch switch-label switch-pill switch-outline-primary-alt">
                        <input className="switch-input" type="checkbox" name="active" onChange={props.onChangeCheckbox} checked={props.product.active} />
                        <span className="switch-slider" data-checked={props.translations.common.yes} data-unchecked={props.translations.common.no}></span>
                    </label>
                </div>
            </td>
            <td>
                <input type='text'
                    onChange={(ev) => props.onPriorityChange(ev, props.product.id)}
                    onBlur={(ev) => props.updatePriority(ev, props.product.id)}
                    value={props.priority} className="form-control"
                    style={{ maxWidth: 80 }}
                />
            </td>
            {/* {Auth.hasAccess("SUPER_ADMIN") ? */}
            {
                (Auth.hasAccess("SUPER_ADMIN") || Auth.hasAccess('CLIENT_ADMIN') || Auth.hasAccess('PLACE_ADMIN')) && props.currPlace && props.currPlace.warehouseSystemId ?
                    <td>
                        <input type='text'
                            onChange={(ev) => props.onWsCodeChange(ev, props.product.id)}
                            onBlur={(ev) => props.updateWsCode(ev, props.product.id)}
                            value={props.wsCode} className="form-control"
                            style={{ maxWidth: 80, minWidth: 65 }}
                        />
                        {/* {props.product.ws_code || "-"} */}
                    </td>
                    :
                    null
            }
            <td>
                <NavLink to={ADMIN_URL + 'products/edit/' + props.product.id}>
                    <button className="btn btn-pill btn-link" type="button">
                        <i className="fas fa-edit"></i>
                    </button>
                </NavLink>

                <button className="btn btn-pill btn-link" type="button" data-toggle="modal" data-target={"#dangerModal" + props.product.id} >
                    <i className="fas fa-trash"></i>
                </button>

                <DeleteProductModal
                    productId={props.product.id}
                    productName={props.product.name}
                    deleteProduct={props.deleteProduct}
                    translations={props.translations}
                />
            </td>
        </tr >
    )
}


export default ListEachProduct;