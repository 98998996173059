import React, { Component } from 'react'
import { connect } from 'react-redux';
import { addEditProform, listProforms } from '../actions/proforms.actions';
import { getSmsesForPlacesByMonth } from './../../places/actions/places.actions';
import AddEditProformForm from './AddEditProformForm';
import { NavLink } from 'react-router-dom';
import { ADMIN_URL } from '../../../constants';
import { addErrorMessages, addSuccessMessage} from '../../messages/messages.actions';
import { showLoading, hideLoading } from './../../common/loading/loading.actions';
import moment from 'moment';

class AddProformPage extends React.Component {
	constructor(props) {
		super(props)
		let d = new Date();

		this.state = {
			number: this.props.nextProformNumber,
            placeId: '',
            company_name: '',
            company_eik: '',
            company_vat_registered: '',
            company_city: '',
            company_address: '',
            company_person: '',
            price: '',
			price_with_words: '',
			proform_date: moment().toDate(),
			services: [{
				service: "",
				price: "",
			}]
		}
	
	}

    componentWillMount() {
		this.props.listProforms();
		this.props.getSmsesForPlacesByMonth();
	}
	
	componentWillReceiveProps(nextProps) {
		if(nextProps.nextProformNumber) {
			this.setState({
				number: nextProps.nextProformNumber
			})
		}
	}

	onChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
    }
	
	onChangeCheckbox = (e) => {
		this.setState({ [e.target.name]: e.target.checked });
	}

	onFieldChange = (fieldName, date) => {
        this.setState({ [fieldName]: date });
	}
	
    onChangeSelectAutocomplete = (name, valueLabel) => {
		this.setState({ [name]: valueLabel.value });
		let d = new Date();
		let placeId = valueLabel.value;
		let place = this.props.places.find(p => p.id == placeId);
		if(place) {
			let services = [];
			services.push({
				service: "Месечен абонамент за Дигитално меню - " + (d.getMonth()*1+1) + "." + d.getFullYear(),
				price: place.monthly_fee,
			})
			place.functionality_modules.map(mod => {
				services.push({
					service: mod.name,
					price: mod.place_functionality_module.price
				})
			})
			let totalPrice = this.calculateTotalPriceByServices(services);
			this.setState({
				company_name: place.company_name || '',
				company_eik: place.company_eik || '',
				company_vat_registered: place.company_vat_registered || 0,
				company_city: place.company_city || '',
				company_address: place.company_address || '',
				company_person: place.company_person || '',
				price: totalPrice || '',
				price_with_words: '',
				proform_date: moment().toDate(),
				services: services
	
			});

		}
    }
		
	calculateTotalPriceByServices = (services = null) => {
		let total = 0;

		if(!services) {
			services = this.state.services;
		}

		services.map(s => {
			total += s.price*1
		})
		this.setState({
			price: total
		})

		return total;
	}

	onRowChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...this.state.services];
        list[index][name] = value;
        this.setState({
            services: list
		})
		
		if(name == 'price') { 
			this.calculateTotalPriceByServices();
		}
    }

    addServiceRow = (e) => {

		e.preventDefault();
        let row = { service: "", price: ""}

        let services = this.state.services;
        services.push(row);

        this.setState({
            services: services
        })
    }

    removeServiceRow = (e, ind) => {
		e.preventDefault();
		
		if(this.state.services.length <= 1) return;

        let services = this.state.services;
        services.splice(ind, 1);
        this.setState({
            services: services
		})
		this.calculateTotalPriceByServices();

    }

	validate() {
		let errors = [];

		if (this.state.company_address.length === 0) {
			errors.push(this.props.translations.messages.errors.invalid_company_address);
		}
		return errors;
    }

	onSubmit = (e) => {
        e.preventDefault();
        const errors = this.validate();
        if(errors.length > 0) {
            this.props.addErrorMessages(errors);
            return;
		}
		
		const post = {
			number: this.state.number,
			placeId: this.state.placeId,
			company_name: this.state.company_name,
			company_eik: this.state.company_eik,
			company_vat_registered: this.state.company_vat_registered,
			company_city: this.state.company_city,
			company_address: this.state.company_address,
			company_person: this.state.company_person,
			price: this.state.price,
			price_with_words: this.state.price_with_words,
			proform_date: this.state.proform_date,
			services: JSON.stringify(this.state.services)
		}

		this.props.showLoading();
		this.props.addEditProform(post, this.props.history)
		.then((post) => {
            post = post.data;
            this.props.listProforms();
            this.props.history.push(ADMIN_URL + 'proforms/edit/'+post.id);
			this.props.addSuccessMessage("Changes saved");
			this.props.hideLoading();
        })
        .catch((error) => {
			this.props.hideLoading();
			this.props.addErrorMessages(error);
        })
	}


    render() {
    	return (
			<main className="main">

				{/* <!-- Breadcrumb--> */}
				<ol className="breadcrumb">
					<li className="breadcrumb-item"><NavLink to={ADMIN_URL}>{this.props.translations.common.alergens}</NavLink></li>
					{/* <li className="breadcrumb-item">Меню</li> */}
					<li className="breadcrumb-item"><NavLink to={ADMIN_URL + "proforms"}>{this.props.translations.proforms.title}</NavLink></li>
					<li className="breadcrumb-item active">{this.props.translations.proforms.add}</li>
					{/* <!-- Breadcrumb Menu--> */}
					<li className="breadcrumb-menu d-md-down-none">
                        <div className="btn-group" role="group" aria-label="Button group">
                            {/* 
                            <!--<a className="btn" href="./">
                                <i className="icon-graph"></i>  Dashboard</a>
                            <a className="btn" href="#">
                                <i className="icon-settings"></i>  Settings</a>
                            --> 
                            */}
                        </div>
                    </li>
				</ol>

        		<div className="container-fluid">
           			<div className="animated fadeIn">
              			<div className="row">
                
							{/* <!-- /.col--> */}                
							<div className="col-sm-12">
								<div className="card">

									<AddEditProformForm
                                        places={this.props.places}
										onSubmit={this.onSubmit}
										onChange={this.onChange}
										onChangeCheckbox={this.onChangeCheckbox}
										onFieldChange={this.onFieldChange}
										onChangeSelectAutocomplete={this.onChangeSelectAutocomplete}
										addServiceRow={this.addServiceRow}
										removeServiceRow={this.removeServiceRow}
										onRowChange={this.onRowChange}
										proform={this.state}
										isAdding="1"
										translations={this.props.translations}
										currLang={this.state.currLang}
										languages={this.props.languages}
									/>

								</div>
							</div>

				  		</div>
				  	</div>
				</div>

			</main>
    	)
  	}
}

const mapStateToProps = state => {
	let nextProformNumber = 1;
	
	if(state.proforms && state.proforms.proforms.length > 0) {
		const max = state.proforms.proforms.reduce(function(prev, current) {
			return (prev.number*1 > current.number*1) ? prev : current
		}) //returns object

		if(max) {
			nextProformNumber = max.number*1 + 1;
		}
	}

	return {
		places: state.places.places || [],
		proforms: state.places.places || [],
		nextProformNumber: nextProformNumber,
		translations: state.lang,
		languages: state.languages.languages || {},
		currLang: state.settings.settings['default_lang'] && state.settings.settings['default_lang'].value || "bg",
	}
};

export default connect(mapStateToProps, { addEditProform, addErrorMessages, addSuccessMessage, listProforms, getSmsesForPlacesByMonth, showLoading, hideLoading  })(AddProformPage);