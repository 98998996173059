import { LIST_QUESTIONS, LIST_ANSWERS } from '../actions/types';

const initialState = {
    questions: [],
    answers: []
}

export default function(state = initialState, action) {
    switch(action.type) {
        case LIST_QUESTIONS: {
            return {
                ...state,
                questions: action.payload,
            }
        }
        case LIST_ANSWERS: {
            return {
                ...state,
                answers: action.payload,
            }
        }
        default:
            return state;
    }
}