import { LIST_TABLE_REGIONS, LIST_TABLE_REGIONS_FOR_ORDER } from '../actions/types';

const initialState = {
    tableRegions: [],
    tableRegionsForSelectedPlaces: [],
}

export default function (state = initialState, action) {
    switch (action.type) {
        case LIST_TABLE_REGIONS: {
            return {
                ...state,
                tableRegions: action.payload,
            }
        }
        case LIST_TABLE_REGIONS_FOR_ORDER: {
            return {
                ...state,
                tableRegionsForSelectedPlaces: action.payload,
            }
        }

        default:
            return state;
    }
}