import React, { Component } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'

import moment from 'moment';
import {LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend} from 'recharts';
// const data = [
//     {name: '20.09.2020', pv: 2400},
//     {name: '19.09.2020', pv: 1398},
//     {name: '18.09.2020', pv: 9800},
//     {name: '17.09.2020', pv: 3908},
//     {name: '16.09.2020', pv: 4800},
//     {name: '15.09.2020', pv: 3800},
//     {name: '15.09.2020', pv: 4300},
// ];


class OrdersCountByDate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            
        }
    }

    componentWillMount() {
        // this.props.countPeopleSawTheMenu();
    }

	render () {

        for(let i = 0; i < this.props.ordersCountByDate.length; i++) {
            this.props.ordersCountByDate[i].totalAmount *= 1;
        }
        
        return (
            <div className="row">
                <div className="col-md-6">
                    <h5>
                        {this.props.translations.statistics.turnover_by_date} : {moment(this.props.filter_start_date).format("D/M/Y")} - {moment(this.props.filter_end_date).format("D/M/Y")}
                    </h5>
                    <LineChart width={550} height={300} data={this.props.ordersCountByDate} margin={{top: 5, right: 30, left: 20, bottom: 5}}>
                        <XAxis dataKey="date"/>
                        <YAxis/>
                        <CartesianGrid strokeDasharray="3 3"/>
                        <Tooltip formatter={(label) => "" + label } />
                        <Legend />
                        <Line type="monotone" dataKey="totalAmount" stroke="#8884d8" activeDot={{r: 4}}/>
                    </LineChart>
                </div>
                
                <div className="col-md-6">
                    <h5>
                        {this.props.translations.statistics.orders_count_by_date} : {moment(this.props.filter_start_date).format("D/M/Y")} - {moment(this.props.filter_end_date).format("D/M/Y")}
                    </h5>
                    <LineChart width={550} height={300} data={this.props.ordersCountByDate} margin={{top: 5, right: 30, left: 20, bottom: 5}}>
                        <XAxis dataKey="date"/>
                        <YAxis/>
                        <CartesianGrid strokeDasharray="3 3"/>
                        <Tooltip formatter={(label) => "" + label } />
                        <Legend />
                        <Line type="monotone" dataKey="orders" stroke="#8884d8" activeDot={{r: 4}}/>
                    </LineChart>
                </div>
            </div>
            
        );
  }
}

const mapStateToProps = (state, dispatch) => {
    return {
        translations: state.lang,
        currLang: "bg",
    }
};

const mapDispatchToProps = (dispatch, props) => {
    return {
        dispatch,
        ...bindActionCreators({  }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrdersCountByDate);
