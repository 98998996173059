import React from 'react'
import { NavLink } from 'react-router-dom';
import { ADMIN_URL } from '../../../constants.js'
import DeleteModal from './../../common/DeleteModal';

const ListGeneralPromotionRow = (props) => (
    <tr key={props.promotion.id}>
        <td>
            {/* If not added translation for the defailt language */}
            {props.promotion.name}
        </td>
        <td>
            {props.promotion.link}
        </td>
        <td>
            {/* If not added translation for the defailt language */}
            {props.promotion.content}
        </td>
        <td>
            {props.promotion.active_from}
        </td>
        <td>
            {props.promotion.active_to}
        </td>
        <td>
            {props.promotion.active ? <span className="badge badge-success">{props.translations.common.active}</span> : <span className="badge badge-secondary">{props.translations.common.inactive}</span>}
        </td>
        <td>
            <NavLink to={ADMIN_URL+'general-promotions/edit/' + props.promotion.id}>
                <button className="btn btn-pill btn-link" type="button">
                    <i className="fas fa-edit"></i>
                </button>
            </NavLink>
            
            <button className="btn btn-pill btn-link" type="button" data-toggle="modal" data-target={"#dangerModal" + props.promotion.id} >
                <i className="fas fa-trash"></i>
            </button>

            <DeleteModal
                id={props.promotion.id}
                title={props.promotion.name}
                deleteFunc={props.deleteFunc}
                translations={props.translations}
            />

        </td>
    </tr>
)

export default ListGeneralPromotionRow;