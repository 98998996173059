import React, { Component } from 'react'
import { NavLink } from 'react-router-dom';
import { ADMIN_URL, PRODUCT_IMG_URL } from '../../../constants.js'
import MessagesContainer from '../../messages/MessagesContainer';
import Utils from '../../../utils/Utils.js';
import LangDropdown from './../../common/LangDropdown';
import Select from 'react-select'
import DatePicker from "react-datepicker";

class AddEditProformForm extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const props = this.props;

        let selectedParent;
        let autocompletePlaces = [];
        autocompletePlaces.push({'value': null, 'label': "Choose place"})

        // console.log( "AUTO ", autocompleteItems, props.clients)
        props.places.map(p => {
            if (props.proform.placeId == p.id) {
                selectedParent = { 'value':p.id, 'label': p.name} ;
            }
                
            autocompletePlaces.push( {'value': p.id, 'label': p.name} );
        });

        return (
            <form>
                <MessagesContainer />
                <div className="card-body">

                    <LangDropdown
                        isDisabled={props.isAdding == 1 ? 1 : ""}
                        currLangOnChange={props.currLangOnChange}
                        currLang={props.currLang}
                        languages={props.languages}
                    />
                    
                    <div className="row">
                        <div className="form-group col-lg-12 col-md-12 col-sm-12">
                            <label htmlFor="placeId">{props.translations.common.client}</label>
                            <Select name="placeId" options={autocompletePlaces} onChange={(valueLabel) => props.onChangeSelectAutocomplete('placeId', valueLabel)} value={selectedParent} 
                                invoiceholder={"Client"}
                            />
                        </div>

                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="form-group">
                                <label htmlFor="company_name">{props.translations.proforms.company_name}</label>
                                <input className={`form-control`} id="company_name" type="text" placeholder={props.translations.proforms.company_name} name="company_name" onChange={props.onChange} value={props.proform.company_name} />
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="form-group">
                                <label htmlFor="name">{props.translations.proforms.company_eik}</label>
                                <input className={`form-control`} id="name" type="text" placeholder={props.translations.proforms.company_eik} name="company_eik" onChange={props.onChange} value={props.proform.company_eik} />
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="form-group">
                                <label htmlFor="name">{props.translations.proforms.company_city}</label>
                                <input className={`form-control`} id="name" type="text" placeholder={props.translations.proforms.company_city} name="company_city" onChange={props.onChange} value={props.proform.company_city} />
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="form-group">
                                <label htmlFor="name">{props.translations.proforms.company_address}</label>
                                <input className={`form-control`} id="name" type="text" placeholder={props.translations.proforms.company_address} name="company_address" onChange={props.onChange} value={props.proform.company_address} />
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="form-group">
                                <label htmlFor="company_person">{props.translations.proforms.company_person}</label>
                                <input className={`form-control`} id="company_person" type="text" placeholder={props.translations.proforms.company_person} name="company_person" onChange={props.onChange} value={props.proform.company_person} />
                            </div>
                        </div>

                        <div className="col-md-12">
                            <div className="row">
                                {props.proform.services && (props.proform.services).map((service, index) => (
                                    <React.Fragment  key={index}>
                                        <div className="col-md-8">
                                            <label htmlFor="">{props.translations.proforms.service_name}</label>
                                            <input type="text" name="service" value={service.service} onChange={(e) => props.onRowChange(e, index)} className="form-control" />
                                        </div>
                                        <div className="col-md-2">
                                            <label htmlFor="">{props.translations.proforms.service_price}</label>
                                            <input type="number" name="price" value={service.price} min="0" step=".01" onChange={(e) => props.onRowChange(e, index)} className="form-control" />
                                        </div>
                                        <div className="col-md-2">
                                            <label htmlFor="">{props.translations.common.action}</label>
                                            <br/>
                                            <button className="btn btn-danger" onClick={(e) => props.removeServiceRow(e, index)}>x</button>
                                            
                                            {props.proform.services.length == index+1 ?
                                                <button className="btn btn-default" onClick={props.addServiceRow}>{this.props.translations.common.add_row}</button>
                                                :
                                                null
                                            }
                                            
                                        </div>
                                    </React.Fragment>
                                    
                                ))}
                                <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                            </div>
                            

                        </div>
                        

                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="form-group">
                                <label htmlFor="price">{props.translations.proforms.price}</label>
                                <input className={`form-control`} id="price" type="text" placeholder={props.translations.proforms.price} name="price" onChange={props.onChange} value={props.proform.price} />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="form-group">
                                <label htmlFor="price_with_words">{props.translations.proforms.price_with_words}</label>
                                <input className={`form-control`} id="price_with_words" type="text" placeholder={props.translations.proforms.price_with_words} name="price_with_words" onChange={props.onChange} value={props.proform.price_with_words} />
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-12">
                            <div className="form-group">
                                <label htmlFor="price_with_words">{props.translations.proforms.date}</label>
                                <br/>
                                <DatePicker
                                    className="form-control"
                                    selected={props.proform.proform_date}
                                    onChange={date => props.onFieldChange('proform_date', date)}
                                    dateFormat="d-M-Y"
                                    style={{ display: 'block' }}
                                    // onCalendarClose={handleCalendarClose}
                                    // onCalendarOpen={handleCalendarOpen}
                                />
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4 col-sm-12">
                            <div className="form-group">
                                <label htmlFor="number">{props.translations.proforms.number}</label>
                                <input className={`form-control`} id="number" type="text" placeholder={props.translations.proforms.number} name="number" onChange={props.onChange} value={props.proform.number} />
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4 col-sm-12">
                            <div className="form-group">
                                <label htmlFor="company_vat_registered">{props.translations.proforms.company_vat_registered}</label>
                                
                                <label className="switch switch-label switch-pill switch-outline-primary-alt">
                                    <input className="switch-input" type="checkbox" name="company_vat_registered" onChange={props.onChangeCheckbox} checked={props.proform.company_vat_registered} />
                                    <span className="switch-slider" data-checked={props.translations.common.yes} data-unchecked={props.translations.common.no}></span>
                                </label>
                                {/* <input className={`form-control`} id="company_person" type="text" placeholder={props.translations.proforms.company_person} name="company_person" onChange={props.onChange} value={props.proform.company_person} /> */}
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="form-group">
                                <label htmlFor="original">{props.translations.proforms.proform_original}</label>
                                <br/>
                                <a href={Utils.getProformImagePath(props.proform.original_proform_path)} target="_blank" >
                                    <img src={Utils.getProformImagePath(props.proform.original_proform_path)} width={300}/>
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="form-group">
                                <label htmlFor="original">{props.translations.proforms.proform_copy}</label>   
                                <br/>
                                <a href={Utils.getProformImagePath(props.proform.copy_proform_path)} target="_blank" >
                                    <img src={Utils.getProformImagePath(props.proform.copy_proform_path)} width={300}/>
                                </a>                         
                            </div>
                        </div>

                    </div>

                    <hr />
                    <br />

                    <div className="form-actions">
                        {props.isAdding == '1' ? '' : <button className="btn btn-danger mright10" type="button" data-toggle="modal" data-target={"#dangerModal" + props.proform.id} >{props.translations.common.delete}</button>}

                        <NavLink to={ADMIN_URL + 'proforms'}>
                            <button className="btn btn-secondary" type="button">
                                {props.translations.common.cancel}
                            </button>
                        </NavLink>

                        <button className="fright btn btn-primary" type="submit" onClick={props.onSubmit}>
                            {props.isAdding == '1' ? props.translations.common.add : props.translations.common.save}
                            {/* {props.actionText} */}
                        </button>

                    </div>

                </div>

            </form>
        )
    }
}

export default AddEditProformForm;