import React from 'react'
import { NavLink } from 'react-router-dom';
import { ADMIN_URL } from '../../../constants.js'
import DeleteModal from './../../common/DeleteModal';
import { Link } from 'react-router-dom';
import MessagesContainer from './../../messages/MessagesContainer';
import Utils from './../../../utils/Utils';
import LangDropdown from '../../common/LangDropdown.js';

// let md5 = require('md5');

const AddEditIngredientForm = (props) => {

    return (

        // <form onSubmit={props.onSubmit}>
        <form>

            <div className="card-body">
                <MessagesContainer />

                <LangDropdown
                    isDisabled={props.isAdding == 1 ? 1 : ""}
                    currLangOnChange={props.currLangOnChange}
                    currLang={props.currLang}
                    languages={props.languages}
                />

                <div className="row">
                    <div className="col-sm-12">
                        <div className="form-group">
                            <label htmlFor="name">{props.translations.common.name}</label>
                            <input className={`form-control`} id="name" type="text" placeholder={props.translations.common.name} name="name" onChange={props.onChange} value={props.ingredient.name} />
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-12">
                        <div className="form-group">
                            <label htmlFor="name">{props.translations.common.price}</label>
                            <input className={`form-control`} id="price" type="text" placeholder={props.translations.common.price} name="price" onChange={props.onChange} value={props.ingredient.price} />
                        </div>
                    </div>
                </div>

                <div className=" row">
                    <label className="col-md-1 col-form-label">{props.translations.common.active}</label>
                    <div className="col-md-11 col-form-label">
                        <label className="switch switch-label switch-pill switch-outline-primary-alt">
                            <input className="switch-input" type="checkbox" name="active" onChange={props.onChangeCheckbox} checked={props.ingredient.active} />
                            <span className="switch-slider" data-checked={props.translations.common.yes} data-unchecked={props.translations.common.no}></span>
                        </label>
                    </div>
                </div>


                <hr />
                <br />

                <div className="form-actions">

                    {props.isAddingIngredient == '1' ? '' : <button className="btn btn-danger mright10" type="button" data-toggle="modal" data-target={"#dangerModal" + props.ingredient.id} > {props.translations.common.delete}</button>}

                    <DeleteModal
                        id={props.ingredient.id}
                        title={props.ingredient.name}
                        deleteFunc={props.deleteIngredient}
                        translations={props.translations}
                    />

                    <NavLink to={ADMIN_URL + 'ingredients'}>
                        <button className="btn btn-secondary" type="button">
                            {props.translations.common.cancel}
                        </button>
                    </NavLink>

                    <button className="fright btn btn-primary" type="submit" onClick={props.onSubmit}>
                        {props.isAddingIngredient == '1' ? props.translations.common.add : props.translations.common.save}
                    </button>

                </div>

            </div>

        </form>
    )
}

export default AddEditIngredientForm;