import React, { Component } from 'react'
import { connect } from 'react-redux';
import { addEditTableGroup, listTableGroups} from './../actions/tableGroups.actions';

import AddEditTableGroupForm from './AddEditTableGroupForm';
import { NavLink } from 'react-router-dom';
import { addErrorMessages, addSuccessMessage } from './../../messages/messages.actions';
import { withRouter } from 'react-router-dom';
import { SCAN_URL, ADMIN_URL, API_TOKEN } from './../../../constants';
import Utils from '../../../utils/Utils';
import Auth from './../../../utils/Auth';

// let md5 = require('md5');

class AddTableGroupPage extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			min_people_count: '',
			max_people_count: '',
			tables: [],
			active: 1,			
		}

	}

	onChange = (e) => {
		this.setState({ [e.target.name]: e.target.value })
	}

	onChangeCheckbox = (e) => {
		this.setState({ [e.target.name]: e.target.checked });
	}

	onChangeSelectAutocomplete = (fieldName, valueLabel) => {
		this.setState({ [fieldName]: valueLabel });
	}

	validate = () => {
		let errors = [];
		if (this.state.min_people_count.length == 0) {
			errors.push(this.props.translations.messages.errors.invalid_min_people_count);
		}
		if (this.state.max_people_count.length == 0) {
			errors.push(this.props.translations.messages.errors.invalid_max_people_count);
		}
		if(this.state.min_people_count > this.state.max_people_count) {
			errors.push(this.props.translations.messages.errors.min_people_must_be_less_than_max_people);			
		}
		if(!this.state.tables || this.state.tables.length == 0) {
			errors.push(this.props.translations.messages.errors.invalid_tables);			
		}
		return errors;
	}

	onSubmit = (e) => {
		e.preventDefault();

		const errors = this.validate();
		if (errors.length > 0) {
			this.props.addErrorMessages(errors);
			return;
		}

		let tablesIds = this.state.tables.map(t => t.value);

		const post = {
			// tableGroup_token: Utils.generateTableGroupToken(this.state.tableGroup_num), // md5(md5(this.state.tableGroup_num + (this.state.currentDate))),
			min_people_count: this.state.min_people_count,
			max_people_count: this.state.max_people_count,
			tables_ids: tablesIds
		}

		// dispatch(showLoading());

		this.props.addEditTableGroup(post)
		.then((post) => {
            post = post.data;
            this.props.listTableGroups();
			this.props.history.push("/admin/tableGroups/edit/"+post.id);                
            // dispatch(hideLoading());
        })
        .catch(function (error) {
			this.props.addErrorMessages('Error occured');
        })
	}

	render() {
		console.log('state', this.state)
		// console.log("--- ", Utils.generateTableGroupToken(this.state.tableGroup_num))
		return (
			<main className="main">

				{/* <!-- Breadcrumb--> */}
				<ol className="breadcrumb">
					<li className="breadcrumb-item"><NavLink to={ADMIN_URL}>{this.props.translations.common.dashboard}</NavLink></li>
					{/* <li className="breadcrumb-item">Меню</li> */}
					<li className="breadcrumb-item"><NavLink to={ADMIN_URL + "tableGroups"}>{this.props.translations.table_groups.title}</NavLink></li>
					<li className="breadcrumb-item active">{this.props.translations.table_groups.add}</li>
					{/* <!-- Breadcrumb Menu--> */}
					<li className="breadcrumb-menu d-md-down-none">
						<div className="btn-group" role="group" aria-label="Button group">
							{/* 
                            <!--<a className="btn" href="./">
                                <i className="icon-graph"></i>  Dashboard</a>
                            <a className="btn" href="#">
                                <i className="icon-settings"></i>  Settings</a>
                            --> 
                            */}
						</div>
					</li>
				</ol>

				<div className="container-fluid">
					<div className="animated fadeIn">
						<div className="row">

							{/* <!-- /.col--> */}
							<div className="col-sm-12">
								<div className="card">

									<AddEditTableGroupForm
										onSubmit={this.onSubmit}
										onChange={this.onChange}
										onChangeCheckbox={this.onChangeCheckbox}
										onChangeSelectAutocomplete={this.onChangeSelectAutocomplete}
										onFieldChange={this.onFieldChange}

										tableGroup={this.state}
										tables={this.props.tables}
										actionText="Добави"
										isAdding={1}
										translations={this.props.translations}
									/>

								</div>
							</div>

						</div>
					</div>
				</div>

			</main>
		)
	}
}

const mapStateToProps = state => {

	let reservationTables = [];
	if(state.tables && state.tables.tables) {
		reservationTables = Object.values(state.tables.tables).filter(t => t.is_for_reservation == 1);
	}

	return {
		tables: reservationTables,
		translations: state.lang,
	}
};

export default withRouter(connect(mapStateToProps, { listTableGroups, addEditTableGroup, addErrorMessages, addSuccessMessage })(AddTableGroupPage));