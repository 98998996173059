import React, { Component } from 'react'
import { connect } from 'react-redux';
// import Tabs from '@material-ui/core/Tabs';
// import Tab from '@material-ui/core/Tab';
import { Tabs, Tab } from 'react-bootstrap';

import AddEditProductForm from './AddEditProductForm';
import AddEditProductVariantForm from './AddEditProductVariantForm';
import AddEditProductAlergensForm from './AddEditProductAlergensForm';
import { addVariant, editVariant, editProduct, removeProduct, addRemoveAllergen, removeImage } from '../../actions/productsActions';
import { listIngredients } from './../ingredients/actions/ingredients.actions';

import { listCategories } from '../../actions/categoriesActions';
import { listSubcategories } from '../../actions/subcategoriesAction';
import { listAlergens } from '../alergens/actions/alergens.action';
import { NavLink } from 'react-router-dom';
import { ADMIN_URL, TRANSLATABLE_FIELDS } from '../../constants';
import { addErrorMessages } from './../messages/messages.actions';
import IngredientsTab from './edit/IngredientsTab';
import RelatedProductsTab from './edit/RelatedProductsTab';
import Auth from '../../utils/Auth';

class EditProductPage extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            currentTab: window.location.hash ? window.location.hash.slice(1) : "product",
            currLang: this.props.currLang,
            id: this.props.match.params.id,
            name: this.props.prod.translations[this.props.currLang] && this.props.prod.translations[this.props.currLang].name || this.props.prod.name,
            code: this.props.prod.code,
            min_count_addable_ingredients: this.props.prod.min_count_addable_ingredients,
            max_count_addable_ingredients: this.props.prod.max_count_addable_ingredients,
            hide_variants: this.props.prod.hide_variants,
            description: this.props.prod.translations[this.props.currLang] && this.props.prod.translations[this.props.currLang].description || this.props.prod.description,
            catHierarchyId: this.props.prod.catHierarchyId,
            categoryId: this.props.prod.subcategory && this.props.prod.subcategory.categoryId || 0,
            subcategoryId: this.props.prod.subcategory && this.props.prod.subcategory.id || 0,
            isLiquid: this.props.prod.isLiquid,
            active: this.props.prod.active,
            is_promo: this.props.prod.is_promo,
            hide_for_ordering: this.props.hide_for_ordering,
            discount_percent: this.props.discount_percent,
            discount_money: this.props.discount_money,
            image_scale: this.props.image_scale,
            fast_order: this.props.fast_order,
            type: this.props.type ? this.props.type : "default",
            allergens: this.props.prod.allergens,
            // images: this.props.prod.product_images,
            relevantSubcategories: (this.props.prod.subcategory && this.props.categories[this.props.prod.subcategory.categoryId])
                ? this.props.categories[this.props.prod.subcategory.categoryId].subcategories : [],
            subcategorySelectDisabled: false,
            variants: this.props.prod.variants,
            isEditingVariant: 0,
            editedVariantId: 0,
            variantSize: '',
            variantPrice: '',
            variantAdditionalPrice: '',
            variantCode: '',
            variantBookingDuration: '',
            label: this.props.prod.translations[this.props.currLang] && this.props.prod.translations[this.props.currLang].label || this.props.prod.label,
            currCategoriesHierarchy: this.props.prod.currCategoriesHierarchy || [],
            productPrintGroupId: this.props.prod.productPrintGroupId || 0,
            show_only_for_spot_browse: this.props.prod.show_only_for_spot_browse || 0,
            hide_only_for_spot_browse: this.props.prod.hide_only_for_spot_browse || 0,
            comment_placeholder: this.props.prod.comment_placeholder
        }

        if (this.props.prod.product_images) {
            this.setState({
                images: this.props.prod.product_images,
            });
        }

        // this.onChange = this.onChange.bind(this);
        // this.onChangeCheckbox = this.onChangeCheckbox.bind(this);
        // this.onSubmit = this.onSubmit.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        // if(nextProps.products && Object.values(nextProps.products).length > 0) {
        //     if(!nextProps.prod.id) {
        //         this.props.history.push('/admin/products')
        //     }
        // }
        if (nextProps.prod.product_images) {
            this.setState({
                images: nextProps.prod.product_images,
            });
        }

        if (nextProps.prod.variants) {
            this.setState({
                variants: nextProps.prod.variants,
                images: nextProps.prod.product_images,
            });
        }

        if (nextProps.prod.id) {
            this.setState({
                id: nextProps.prod.id,
                currentTab: window.location.hash ? window.location.hash.slice(1) : "product",
                name: nextProps.prod.translations[this.state.currLang] && nextProps.prod.translations[this.state.currLang].name || nextProps.prod.name,
                code: nextProps.prod.code,
                min_count_addable_ingredients: nextProps.prod.min_count_addable_ingredients,
                max_count_addable_ingredients: nextProps.prod.max_count_addable_ingredients,
                hide_variants: nextProps.prod.hide_variants,
                description: nextProps.prod.translations[this.state.currLang] && nextProps.prod.translations[this.state.currLang].description || nextProps.prod.description,
                allergens: nextProps.prod.allergens,
                // catHierarchyId: nextProps.prod.catHierarchyId,
                isLiquid: nextProps.prod.isLiquid,
                active: nextProps.prod.active,
                is_promo: nextProps.prod.is_promo,
                hide_for_ordering: nextProps.prod.hide_for_ordering,
                discount_percent: nextProps.prod.discount_percent,
                discount_money: nextProps.prod.discount_money,
                image_scale: nextProps.prod.image_scale,
                fast_order: nextProps.prod.fast_order,
                type: nextProps.prod.type ? nextProps.prod.type : "default",
                label: nextProps.prod.translations[this.state.currLang] && nextProps.prod.translations[this.state.currLang].label || nextProps.prod.label,
                variants: nextProps.prod.variants,
                productPrintGroupId: nextProps.prod.productPrintGroupId,
                show_only_for_spot_browse: nextProps.prod.show_only_for_spot_browse,
                hide_only_for_spot_browse: nextProps.prod.hide_only_for_spot_browse,
                comment_placeholder: nextProps.prod.comment_placeholder
            });

            let currCats = [];

            // sets initially selected prod cats for the select input
            // should be refactored later when a more beautiful tree view is used for the cats
            if (Object.keys(nextProps.categoriesHierarchy).length !== 0 && nextProps.prod.cat_hierarchies) {
                nextProps.prod.cat_hierarchies.map(c => {
                    let dashes = '';
                    for (let i = 0; i < c.hierarchyLevel - 1; i++) { dashes += "-"; }

                    currCats.push({
                        value: c.id, 'label': dashes + c.name + (c.code ? ' (' + c.code + ')' : '') +
                            (nextProps.categoriesHierarchy && nextProps.categoriesHierarchy[c.parentId] &&
                                nextProps.categoriesHierarchy[c.parentId].name
                                ? (" /" + nextProps.categoriesHierarchy[c.parentId].name + '/')
                                : '')
                    })
                })

                this.setState({ currCategoriesHierarchy: currCats });
            }

            // if (Object.keys(this.props.categories).length !== 0 && nextProps.prod.subcategory) {
            //     let selectedCatId = nextProps.prod.subcategory.categoryId;
            //     this.setRelevantCategoryState(selectedCatId, this.props.categories[selectedCatId].subcategories ? this.props.categories[selectedCatId].subcategories : '');
            // }
        }

        // if (nextProps.prod.id) {

        //     this.setState({
        //         // id: nextProps.match.params.id,
        //         name: nextProps.prod.translations[this.props.currLang] && nextProps.prod.translations[this.props.currLang].name || this.props.prod.name,
        //         description: nextProps.prod.translations[this.props.currLang] && nextProps.prod.translations[this.props.currLang].description || this.props.prod.description,
        //         allergens: nextProps.prod.allergens,
        //         code: nextProps.prod.code,
        //         // icon: nextProps.cat.icon ? nextProps.cat.icon  : '',
        //         // active: nextProps.cat.active? nextProps.cat.active : 0,
        //     });
        // }

    }

    componentWillMount() {
        // this.props.listCategories();
        // this.props.listSubcategories();
        this.props.listAlergens();
        this.props.listIngredients();
    }

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    onChangeCheckbox = (e) => {
        this.setState({ [e.target.name]: e.target.checked });
    }

    onCKEditorChange = (e) => {
        if (e.editor.getData()) {
            // console.log('e.editor.getData()',e.editor.getData())
        }
        this.setState({
            description: e.editor.getData()
        });
    }

    onChangeSelectAutocompleteCategory = (valueLabel) => {
        this.setState({ 'currCategoriesHierarchy': valueLabel });
        // this.setState({ 'catHierarchyId': valueLabel.value });
    }

    onChangeSelectAutocomplete = (fieldName, valueLabel) => {
        this.setState({ [fieldName]: valueLabel.value });
    }

    validate() {
        let errors = [];

        if (this.state.name.length === 0) {
            errors.push(this.props.translations.messages.errors.invalid_name);
        }
        if (!this.state.currCategoriesHierarchy || this.state.currCategoriesHierarchy.length === 0) {
            errors.push(this.props.translations.messages.errors.invalid_category);
        }

        return errors;
    }

    onSubmit = (e) => {
        e.preventDefault();

        const errors = this.validate();
        if (errors.length > 0) {
            this.props.addErrorMessages(errors);
            return;
        }

        let catHierarchyIds = this.state.currCategoriesHierarchy.map(c => c.value);

        const post = {
            id: this.state.id,
            name: this.state.name,
            code: this.state.code,
            hide_variants: this.state.hide_variants,
            // subcategory_code: this.props.subcategories[this.state.subcategoryId] ? this.props.subcategories[this.state.subcategoryId].code : '',
            description: this.state.description,
            allergens: this.state.allergens,
            // subcategoryId: this.state.subcategoryId,
            catHierarchyIds: catHierarchyIds,
            // subcategoryId: this.state.subcategoryId == 0 ? Object.values(this.props.categories[this.state.categoryId].subcategories)[0].id : this.state.subcategoryId,
            isLiquid: this.state.isLiquid,
            active: this.state.active,
            is_promo: this.state.is_promo,
            hide_for_ordering: this.state.hide_for_ordering,
            discount_percent: this.state.discount_percent,
            discount_money: this.state.discount_money,
            image_scale: this.state.image_scale,
            fast_order: this.state.fast_order,
            type: this.state.type,
            label: this.state.label,
            productPrintGroupId: this.state.productPrintGroupId,
            show_only_for_spot_browse: this.state.show_only_for_spot_browse,
            hide_only_for_spot_browse: this.state.hide_only_for_spot_browse,
            comment_placeholder: this.state.comment_placeholder
        }

        // Image append
        const files = Array.from(document.querySelector('#multi').files)
        let formData = new FormData()

        files.forEach((file, i) => {
            formData.append("file", file)
        })

        //  this.props.addProduct(post, this.props.history, formData);
        this.props.editProduct(post, this.props.history, formData, this.props.languages[this.state.currLang].id);
    }

    validateVariant() {
        let errors = [];

        if (this.state.variantSize.length === 0) {
            errors.push(this.props.translations.messages.errors.invalid_size);
        }
        if ((this.state.variantPrice.toString()).length == 0) {
            errors.push(this.props.translations.messages.errors.invalid_price);
        }
        return errors;
    }

    // In edit product only
    addSize = () => {
        const errors = this.validateVariant();
        if (errors.length > 0) {
            this.props.addErrorMessages(errors);
            return;
        }
        if (this.props.prod.id) {
            const variant = {
                price: this.state.variantPrice,
                additional_price: this.state.variantAdditionalPrice,
                size: this.state.variantSize,
                code: this.state.variantCode ? this.state.variantCode : '',
                product_code: this.state.code ? this.state.code : '',
                booking_duration: this.state.variantBookingDuration || 0,
                productId: this.props.prod.id,
            };

            this.props.addVariant(variant, this.props.history, this.props.languages[this.props.currLang].id);

            this.setInputSizePriceValues(); // Set inputs to empty
        }
    }

    // Save edited size
    saveSize = () => {
        if (this.state.variantPrice && this.state.variantSize && this.props.prod.id && this.state.editedVariantId) {
            const variant = {
                id: this.state.editedVariantId,
                price: this.state.variantPrice,
                additional_price: this.state.variantAdditionalPrice,
                // size: this.state.variantSize,
                size: this.state.variantSize,
                code: this.state.variantCode ? this.state.variantCode : "",
                product_code: this.state.code ? this.state.code : "",
                booking_duration: this.state.variantBookingDuration || 0,
                productId: this.props.prod.id,
                variantId: this.state.editedVariantId,
            };

            this.props.editVariant(variant, this.props.history, this.props.languages[this.state.currLang].id);

            this.setInputSizePriceValues();

            this.setState({ isEditingVariant: 0, editedVariantId: 0 })
        }

    }

    removeSize = (e, variantId) => {
        // e.preventDefault();
        const variant = {
            id: variantId,
            deleted: true
        };

        this.props.editVariant(variant, this.props.history);
    }


    // Set inputs
    editSize = (variantId) => {
        // let newVariantSize = this.state.variants[variantId].size;
        let newVariantSize = this.state.variants[variantId] && this.state.variants[variantId].translations
            && this.state.variants[variantId].translations[this.state.currLang]
            && this.state.variants[variantId].translations[this.state.currLang].size
            || this.state.variants[variantId].size;
        let newVariantPrice = this.state.variants[variantId].price;
        let newVariantAdditionalPrice = this.state.variants[variantId].additional_price;
        let newVariantCode = this.state.variants[variantId].code;
        let newVariantBookingDuration = this.state.variants[variantId].booking_duration;

        // this.setInputSizePriceValues(newVariantSize, newVariantPrice, newVariantCode);

        this.setState(
            {
                isEditingVariant: 1,
                editedVariantId: variantId,
                // Save also variant to state if click save directly
                variantPrice: newVariantPrice,
                variantAdditionalPrice: newVariantAdditionalPrice,
                variantSize: newVariantSize,
                variantCode: newVariantCode,
                variantBookingDuration: newVariantBookingDuration
            }
        )
    }

    cancelEditSize = () => {
        this.setInputSizePriceValues();
        this.setState({ isEditingVariant: 0, editedVariantId: 0 })
    }

    setInputSizePriceValues = (sizeValue = '', priceValue = '', codeValue = '', additionalPriceValue = '', bookingDuration = '') => {
        this.setState({
            variantSize: sizeValue,
            variantPrice: priceValue,
            variantCode: codeValue,
            variantAdditionalPrice: additionalPriceValue,
            variantBookingDuration: bookingDuration
        })
        // document.getElementById('product_size').value = sizeValue;
        // document.getElementById('product_price').value = priceValue;
    }

    deleteProduct = (e, id) => {
        e.preventDefault();
        this.props.removeProduct(id, this.props.history);
    }

    getRelevatSubcategory = (e) => {
        // When no active subcats Bugfix
        let subcatSelectHolder = document.getElementById('subcategory_id');
        if (subcatSelectHolder) {
            subcatSelectHolder.disabled = false;
        }

        this.setState({
            categoryId: e.target.value,
            subcategorySelectDisabled: false,
            relevantSubcategories: this.props.categories[e.target.value].subcategories,
            subcategoryId: this.props.categories[e.target.value].subcategories[Object.keys(this.props.categories[e.target.value].subcategories)[0]].id
        });
    }

    setRelevantCategoryState = (categoryId, relevantSubcategories) => {
        this.setState({
            categoryId: categoryId,
            subcategorySelectDisabled: false,
            relevantSubcategories: relevantSubcategories,
        });
    }

    onChangeProductAlergens = (checked, allergenId) => {
        this.props.addRemoveAllergen(!checked, this.state.id, allergenId)
    }

    currLangOnChange = (e) => {
        // let newLang = e.target.value;

        // let newNameValue = this.props.prod.translations[newLang] && this.props.prod.translations[newLang].name || "";
        // let newDescriptionValue = this.props.prod.translations[newLang] && this.props.prod.translations[newLang].description || "";

        // let newSizeValue = this.state.variants[this.state.editedVariantId]
        //     && this.state.variants[this.state.editedVariantId].translations[newLang]
        //     && this.state.variants[this.state.editedVariantId].translations[newLang].size
        //     || '';
        // this.setState({
        //     name: newNameValue,
        //     description: newDescriptionValue,
        //     currLang: e.target.value,
        //     variantSize: newSizeValue
        // })


        let newLang = e.target.value;

		let newState = {
			currLang: e.target.value
		};

		TRANSLATABLE_FIELDS.PRODUCT.map(field => {
			newState[field] = this.props.prod.translations[newLang] && this.props.prod.translations[newLang][field] ? this.props.prod.translations[newLang][field] : "";
		})

        let newSizeValue = this.state.variants[this.state.editedVariantId]
            && this.state.variants[this.state.editedVariantId].translations[newLang]
            && this.state.variants[this.state.editedVariantId].translations[newLang].size
            || '';
        
        newState['variantSize'] = newSizeValue;
		this.setState(newState)
    }

    removeImage = (e) => {
        e.preventDefault();

        this.props.removeImage(this.state.id, this.props.history);
    }

    onChangeHideVariants = () => {
        let post = {
            id: this.state.id,
            hide_variants: !this.state.hide_variants
        }

        this.props.editProduct(post);
    }

    render() {
        return (
            <main className="main">

                {/* <!-- Breadcrumb--> */}
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><NavLink to={ADMIN_URL}>{this.props.translations.common.dashboard}</NavLink></li>
                    {/* <li className="breadcrumb-item">Меню</li> */}
                    <li className="breadcrumb-item"><NavLink to={ADMIN_URL + "products"}>{this.props.translations.products.title}</NavLink></li>
                    <li className="breadcrumb-item active">{this.props.translations.products.edit}</li>
                    <li className="breadcrumb-item active">{this.state.name}</li>
                    <li className="breadcrumb-menu d-md-down-none" >
                        <div className="btn-group" role="group" aria-label="Button group">
                            <NavLink to={ADMIN_URL + `products/add`} className="btn" >
                                <i className="fas fa-plus"></i> &nbsp; {this.props.translations.products.add}
                            </NavLink>
                            <NavLink to={ADMIN_URL + `products/add-bulk`} className="btn" >
                                <i className="fas fa-plus"></i> &nbsp; {this.props.translations.products.add_bulk}
                            </NavLink>
                        </div>
                    </li>
                </ol>

                <div className="container-fluid">
                    <div className="animated fadeIn">
                        <div className="row">

                            <div className="col-sm-12">
                                <div className="card">

                                    <Tabs
                                        style={{ width: '100%' }}
                                        id="controlled-tab-example"
                                        activeKey={this.state.currentTab}
                                        onSelect={(k) => { this.setState({ currentTab: k }); window.location.hash = k; }}
                                    >
                                        <Tab eventKey="product" title={this.props.translations.products.title_single}>
                                            <div className="col-sm-12 col-md-12">
                                                <div className="card">
                                                    <AddEditProductForm
                                                        settings={this.props.settings}
                                                        onSubmit={this.onSubmit}
                                                        onChange={this.onChange}
                                                        onCKEditorChange={this.onCKEditorChange}
                                                        onChangeCheckbox={this.onChangeCheckbox}
                                                        removeImage={this.removeImage}
                                                        product={this.state}
                                                        productId={this.state.id}
                                                        productName={this.state.name}
                                                        productCode={this.state.code}
                                                        productDescription={this.state.description}
                                                        productImage={this.state.images}
                                                        productActive={this.state.active}
                                                        productisLiquid={this.state.isLiquid}
                                                        productFastOrder={this.state.fast_order}
                                                        catHierarchyId={this.state.catHierarchyId}
                                                        // categoryId={this.state.categoryId}
                                                        // subcategoryId={this.state.subcategoryId}
                                                        deleteProduct={this.deleteProduct}
                                                        actionText="Запази"
                                                        isAddingProduct="0"
                                                        allCategories={this.props.categories}
                                                        allSubcategories={this.props.subcategories}
                                                        getRelevatSubcategory={this.getRelevatSubcategory}
                                                        relevantSubcategories={this.state.relevantSubcategories}
                                                        subcategorySelectDisabled={this.state.subcategorySelectDisabled}
                                                        nameError={this.props.errors.find(error => error.path == 'name') ? this.props.errors.find(error => error.path == 'name') : ""}
                                                        translations={this.props.translations}
                                                        currLang={this.state.currLang}
                                                        currLangOnChange={this.currLangOnChange}
                                                        languages={this.props.languages}
                                                        categoriesHierarchy={this.props.categoriesHierarchy}
                                                        // noCategoriesView={this.props.noCategoriesView}
                                                        onChangeSelectAutocompleteCategory={this.onChangeSelectAutocompleteCategory}
                                                        onChangeSelectAutocomplete={this.onChangeSelectAutocomplete}
                                                        currentTab={this.state.currentTab}
                                                    />
                                                </div>
                                            </div>
                                        </Tab>
                                        <Tab eventKey="variants" title={this.props.translations.products.variants}>
                                            <div className="col-sm-12 col-md-12">
                                                <div className="card">
                                                    <AddEditProductVariantForm
                                                        onChange={this.onChange}
                                                        addSize={this.addSize}
                                                        editSize={this.editSize}
                                                        cancelEditSize={this.cancelEditSize}
                                                        saveSize={this.saveSize}
                                                        removeSize={this.removeSize}
                                                        productId={this.state.id}
                                                        variants={this.state.variants}
                                                        isEditingVariant={this.state.isEditingVariant}
                                                        editedVariantId={this.state.editedVariantId}
                                                        variantSize={this.state.variantSize}
                                                        variantPrice={this.state.variantPrice}
                                                        showVariantAdditionalPrice={this.props.showVariantAdditionalPrice}
                                                        variantAdditionalPrice={this.state.variantAdditionalPrice}
                                                        variantCode={this.state.variantCode}
                                                        variantBookingDuration={this.state.variantBookingDuration}
                                                        nameError={this.props.errors.find(error => error.path == 'name') ? this.props.errors.find(error => error.path == 'name') : ""}
                                                        translations={this.props.translations}
                                                        prod={this.state}
                                                        onChangeHideVariants={this.onChangeHideVariants}
                                                        currLang={this.state.currLang}
                                                        currLangOnChange={this.currLangOnChange}
                                                        languages={this.props.languages}
                                                    />
                                                </div>
                                            </div>
                                        </Tab>
                                        <Tab eventKey="ingredients" title={this.props.translations.products.ingredients} >
                                            <IngredientsTab
                                                editProductOnChange={this.onChange}
                                                product={this.props.prod}
                                                translations={this.props.translations}
                                            />
                                        </Tab>
                                        <Tab eventKey="allergens" title={this.props.translations.alergens.title} >
                                            <div className="col-sm-12">
                                                <div className="card">
                                                    <AddEditProductAlergensForm
                                                        allAlergens={this.props.allAlergens}
                                                        onChangeProductAlergens={this.onChangeProductAlergens}
                                                        productAllergens={this.state.allergens}
                                                        translations={this.props.translations}
                                                    />
                                                </div>
                                            </div>
                                        </Tab>

                                        <Tab eventKey="relatedProducts" title={this.props.translations.products.related_products} >
                                            <div className="col-sm-12">
                                                <div className="card">
                                                    <RelatedProductsTab
                                                        product={this.props.prod}
                                                        translations={this.props.translations}

                                                    // allAlergens={this.props.allAlergens}
                                                    // onChangeProductAlergens={this.onChangeProductAlergens}
                                                    // productAllergens={this.state.allergens}
                                                    />
                                                </div>
                                            </div>
                                        </Tab>
                                    </Tabs>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </main >
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    let prodId = ownProps.match.params.id,
        prod = Object.values(state.products.products).find(s => s.id == prodId);
    if (!prod) prod = {
        id: '',
        name: '',
        code: '',
        min_count_addable_ingredients: 0,
        max_count_addable_ingredients: 0,
        hide_variants: 0,
        description: '',
        allergens: {},
        catHierarchyId: '',
        // categoryId: '',
        // subcategoryId: '',
        isLiquid: '',
        active: 1,
        is_promo: 0,
        hide_for_ordering: 0,
        discount_percent: 0,
        discount_money: 0,
        image_scale: 100,
        fast_order: 0,
        relevantSubcategories: '',
        translations: {
            bg: {
                name: "",
                description: "",
                label: "",
            },
            en: {
                name: "",
                description: "",
                label: "",
            }
        },
        label: '',
        // subcategory: [],
        variants: [],
        ingredients: [],
        currCategoriesHierarchy: [],
        productPrintGroupId: 0,
        comment_placeholder: ''
    };

    return {
        prod: prod,
        products: state.products.products || [],
        categoriesHierarchy: state.categoriesHierarchy.categories,
        categories: [],// state.categories.categories,
        subcategories: [], // state.subcategories.subcategories,
        //relevantSubcategories: state.categories.categories[prod.subcategory.categoryId].subcategories,
        errors: state.products.errors,
        translations: state.lang,
        allAlergens: state.alergens.alergens || [],
        languages: state.languages.languages || {},
        // currLang: "bg",
        currLang: state.settings.settings['default_lang'] && state.settings.settings['default_lang'].value || "bg",

        showVariantAdditionalPrice: state.settings.settings && state.settings.settings['show_variant_additional_price'] && state.settings.settings['show_variant_additional_price'].value ? state.settings.settings['show_variant_additional_price'].value : 0,
        // noCategoriesView: state.settings.settings && state.settings.settings['no_categories_view'] && state.settings.settings['no_categories_view'].value ? state.settings.settings['no_categories_view'].value : 0,
        ingredients: state.ingredients.ingredients || [],
        settings: state.settings.settings,
    }

};

export default connect(mapStateToProps, {
    addErrorMessages, addVariant, editVariant, editProduct,
    removeProduct, addRemoveAllergen, listCategories, listSubcategories, listAlergens, listIngredients, removeImage
})(EditProductPage);