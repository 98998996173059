import React from 'react'
import { NavLink } from 'react-router-dom';
import { ADMIN_URL, IMG_PATH } from '../../../constants.js'
import DeletePlaceModal from './DeletePlaceModal';
import Utils from '../../../utils/Utils';
import PlaceSmsLogsModal from './PlaceSmsLogsModal';
import Auth from '../../../utils/Auth.js';

const ListEachPlace = (props) => (
    <tr key={props.place.id}>
        <td>
            {/* If not added translation for the defailt language */}
            {props.place.name}
            {/* {props.alergen.translations[props.currLang] && props.alergen.translations[props.currLang].name 
			? props.alergen.translations[props.currLang].name : props.alergen.name} */}
        </td>

        <td>
            {Auth.hasAccess('SUPER_ADMIN') ? props.place.monthly_fee : '-'}
        </td>
        <td>
            {Auth.hasAccess('SUPER_ADMIN') ?
                <div className="col-md-2 col-form-label">
                    <label className="switch switch-label switch-pill switch-outline-primary-alt">
                        <input className="switch-input" type="checkbox" name="is_paying" onChange={(e) => window.confirm('Are you sure you want to edit payment method for place: ' + props.place.name + ' ?') ? props.onChangeCheckbox(e) : void (0)} checked={props.place.is_paying} />
                        <span className="switch-slider" data-checked={props.translations.common.yes} data-unchecked={props.translations.common.no}></span>
                    </label>
                </div>
                :
                '-'
            }
        </td>
        <td>
            {Auth.hasAccess('SUPER_ADMIN') ?
                <div className="col-md-2 col-form-label">
                    <label className="switch switch-label switch-pill switch-outline-primary-alt">
                        <input className="switch-input" type="checkbox" name="active" onChange={(e) => window.confirm('Are you sure you want to edit active status for place: ' + props.place.name + ' ?') ? props.onChangeCheckboxActive(e) : void (0)} checked={props.place.active==true} />
                        <span className="switch-slider" data-checked={props.translations.common.yes} data-unchecked={props.translations.common.no}></span>
                    </label>
                </div>
                :
                '-'
            }
        </td>

        {/* <td>
            {props.places.image ? <img style={{ maxHeight: 100 }} src={Utils.getCategoryImagePath(props.alergen.image)} /> : ""}
        </td> */}
        <td>
            <NavLink to={ADMIN_URL + 'places/edit/' + props.place.id}>
                <button className="btn btn-pill btn-link" type="button">
                    <i className="fas fa-edit"></i>
                </button>
            </NavLink>

            <button className="btn btn-pill btn-link" type="button" data-toggle="modal" data-target={"#dangerModal" + props.place.id} >
                <i className="fas fa-trash"></i>
            </button>

            <button className="btn btn-pill btn-link" type="button" onClick={() => props.initPlaceSettings(props.place.id)} >
                <i className="fas fa-cog"></i>
            </button>

            <button className="btn btn-pill btn-link" type="button" data-toggle="modal" data-target={"#placeSmsLogsModal" + props.place.id} >
                <i className="fas fa-sms"></i>
            </button>

            <DeletePlaceModal
                id={props.place.id}
                name={props.place.name}
                deletePlace={props.deletePlace}
                translations={props.translations}
            />

            <PlaceSmsLogsModal
                id={props.place.id}
                name={props.place.name}
                smsLogsForPlace={props.placesSmsLogs[props.place.id] || []}
                translations={props.translations}
            />

            <button className="btn btn-pill btn-link" title="Set products codes from Warehouse system" type="button" onClick={() => props.setProductsCodesFromWS(props.place.id)} >
                <i className="fas fa-cog"></i> Set codes
            </button>

        </td>
    </tr>
)

export default ListEachPlace;