import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import React from 'react'

const DeleteOrderModal = (props) => (
    <>
        <Dialog
            maxWidth={'sm'}
            open={props.deleteOrderModalOpened}
            onClose={props.onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className={"mui-dialog-title-danger"}
        >
            <DialogTitle id="alert-dialog-title">
                {props.translations.common.warning}
            </DialogTitle>
            <DialogContent className={"mui-dialog-content"}>
                <DialogContentText id="alert-dialog-description">
                    {props.translations.orders.delete.confirm} '{props.deletePlaceOrderNumber}'?
                </DialogContentText>
            </DialogContent>
            <DialogActions className={"mui-dialog-actions"}>
                <Button onClick={props.onClose} color="delete" className="">
                    {props.translations.common.close}
                </Button>
                <Button onClick={() => { props.deleteOrder(); props.onClose() }} className={"mui-color-danger"} autoFocus>
                    {props.translations.common.delete}
                </Button>
            </DialogActions>
        </Dialog>
    </>
)

export default DeleteOrderModal;