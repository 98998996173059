import axios from 'axios';
import { LIST_PAYMENT_PROVIDERS } from './types';
import { API_URL, ADMIN_URL } from '../../../constants';
import { addSuccessMessage, addErrorMessages } from '../../messages/messages.actions';
import { changeLanguage } from '../../../actions/languagesActions';
import Auth from '../../../utils/Auth';

export const listPaymentProviders = (place_id = 0) => dispatch => {
    let getParam = '';
    if(place_id == 0) {
        place_id = Auth.getUserPlace();
        getParam += "?place_id=" + place_id;
    }

    axios
        .get(API_URL + 'payment-providers' + getParam)
        .then(res => {
            dispatch({
                type: LIST_PAYMENT_PROVIDERS,
                payload: res && res.data || []
            });
        })
        .catch(error => {
            throw (error);
        });
}

