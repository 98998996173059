import React, { Component } from 'react'
import { connect } from 'react-redux';
import { addEditQuestion } from './../actions/feedback.actions';
import AddEditQuestionForm from './AddEditQuestionForm';
import { NavLink } from 'react-router-dom';
import { addErrorMessages } from './../../messages/messages.actions';
import { withRouter } from 'react-router-dom';
import { ADMIN_URL, API_TOKEN} from './../../../constants';
import Utils from '../../../utils/Utils';

// let md5 = require('md5');

class AddQuestionPage extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			text: '',
			type: '',
			active: 1
		}

		// this.onChange = this.onChange.bind(this);
		// this.onChangeCheckbox = this.onChangeCheckbox.bind(this);
		// this.onSubmit = this.onSubmit.bind(this);
	}

	onChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
	}

	onChangeCheckbox = (e) => {
		this.setState({ [e.target.name]: e.target.checked });
	}

	onFieldChange = (fieldName, value) => {
        this.setState({ [fieldName]: value });
	}

	validate() {
		let errors = [];

		if (this.state.text.length === 0) {
			errors.push(this.props.translations.messages.errors.invalid_question);
		}
		return errors;
	}

	onSubmit = (e) => {
		e.preventDefault();

		const errors = this.validate();
		if (errors.length > 0) {
			this.props.addErrorMessages(errors);
			return;
		}

		const post = {
			text: this.state.text,
			type: this.state.type.value,
			active: this.state.active,
		}

		this.props.addEditQuestion(post, this.props.history, this.props.languages[this.props.currLang].id);
	}

	render() {
		return (
			<main className="main">

				{/* <!-- Breadcrumb--> */}
				<ol className="breadcrumb">
					<li className="breadcrumb-item"><NavLink to={ADMIN_URL}>{this.props.translations.common.dashboard}</NavLink></li>
					{/* <li className="breadcrumb-item">Меню</li> */}
					<li className="breadcrumb-item"><NavLink to={ADMIN_URL + "feedback/questions"}>{this.props.translations.feedback.questions.title}</NavLink></li>
					<li className="breadcrumb-item active">{this.props.translations.feedback.questions.add}</li>
					{/* <!-- Breadcrumb Menu--> */}
					<li className="breadcrumb-menu d-md-down-none">
						<div className="btn-group" role="group" aria-label="Button group">
							{/* 
                            <!--<a className="btn" href="./">
                                <i className="icon-graph"></i>  Dashboard</a>
                            <a className="btn" href="#">
                                <i className="icon-settings"></i>  Settings</a>
                            --> 
                            */}
						</div>
					</li>
				</ol>

				<div className="container-fluid">
					<div className="animated fadeIn">
						<div className="row">

							{/* <!-- /.col--> */}
							<div className="col-sm-12">
								<div className="card">

									<AddEditQuestionForm
										onSubmit={this.onSubmit}
										onChange={this.onChange}
                                        onChangeCheckbox={this.onChangeCheckbox}
                                        onFieldChange={this.onFieldChange}
										question={this.state}
										isAdding="1"
										translations={this.props.translations}
										currLang={this.state.currLang}
										languages={this.props.languages}

									/>

								</div>
							</div>

						</div>
					</div>
				</div>

			</main>
		)
	}
}

const mapStateToProps = state => ({
	translations: state.lang,
	languages: state.languages.languages || {},
	currLang: state.settings.settings['default_lang'] && state.settings.settings['default_lang'].value || 'bg',

});

// const mapDispatchToProps = (dispatch, ownProps) => {
// 	return {
// 		addQuestion: (data, imageData) => dispatch(addQuestion(ownProps.history, data, imageData)),
// 		addErrorMessages: (msgs) => dispatch(addErrorMessages(msgs))
// 	}
// }

export default withRouter(connect(mapStateToProps, { addEditQuestion, addErrorMessages })(AddQuestionPage));