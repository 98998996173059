export const LIST_TABLES = 'LIST_TABLES';
export const LIST_TABLES_ERROR = 'LIST_TABLES_ERROR';
export const DELETE_TABLE_ERROR = 'DELETE_TABLE_ERROR';
export const ADD_TABLE_ERROR = 'ADD_TABLE_ERROR';
export const EDIT_TABLE_ERROR = 'EDIT_TABLE_ERROR';
export const DELETE_TABLE_ERROR_LISTPAGE = 'DELETE_TABLE_ERROR_LISTPAGE';
export const ADD_TABLE_SUCCESS = 'ADD_TABLE_SUCCESS';
export const EDIT_TABLE_SUCCESS = 'EDIT_TABLE_SUCCESS';
export const DELETE_TABLE_SUCCESS = 'DELETE_TABLE_SUCCESS';
export const ADD_TABLE_GROUP_SUCCESS = 'ADD_TABLE_GROUP_SUCCESS';
export const ADD_TABLE_GROUP_ERROR = 'ADD_TABLE_GROUP_ERROR';
export const REMOVE_TABLE_GROUP_SUCCESS = 'REMOVE_TABLE_GROUP_SUCCESS';
export const REMOVE_TABLE_GROUP_ERROR = 'REMOVE_TABLE_GROUP_ERROR';
export const CLEAR_MESSAGES = 'CLEAR_MESSAGES';
export const ADD_ERROR_MSGS = 'ADD_ERROR_MSGS';