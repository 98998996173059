import axios from 'axios';
import { LIST_INGREDIENTS } from './types';
import { API_URL, ADMIN_URL } from './../../../constants';
import { addSuccessMessage, addErrorMessages } from './../../messages/messages.actions';
import { changeLanguage } from './../../../actions/languagesActions';
import Auth from './../../../utils/Auth';
import Utils from '../../../utils/Utils';

export const listIngredients = (place_id = 0) => dispatch => {
    let getParam = '';
    if(place_id == 0) {
        place_id = Auth.getUserPlace();
        getParam += "?place_id=" + place_id;
    }

    axios
        .get(API_URL + 'ingredients_admin' + getParam)
        .then(res => {
            dispatch({
                type: LIST_INGREDIENTS,
                payload: res && res.data || []
            });
        })
        .catch(error => {
            throw (error);
        });
}


export const addEditIngredient = (data, history, langId) => dispatch => {
    if(!data.place_id) {
        data.place_id = Auth.getUserPlace();
    }

    var postForTrans = null;
    //if we translate in another lang -> dont update the cat fields  | update translated cat fields only if we translate in the default lang
    if(Utils.getDefaultLang() && Utils.getDefaultLang().id != langId) {
        postForTrans = {...data};
        delete data.name;
    }
    console.log("LANG ", langId, postForTrans, Utils.getDefaultLang())
    
    axios
        .post(API_URL + 'ingredients', data)
        .then((post) => {
            post = post.data;

            if(!post.deleted && langId) {
                dispatch(addEditIngredientTranslation(post.id, langId, postForTrans ? postForTrans : data))
            }
            dispatch(addSuccessMessage("Changes saved"))
            dispatch(listIngredients());
            history.push(ADMIN_URL + 'ingredients');
        })
        .catch(function (error) {
            // dispatch(addErrorMessages('Error occured'));
        })
}

export const addEditIngredientTranslation = (ingredient_id, lang_id, data) => dispatch => {
    axios
        .post(API_URL + `ingredients/${ingredient_id}/translations/${lang_id}`, data)
        .then(res => {

        })
        .catch(err => {

        })
}