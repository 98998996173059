import { combineReducers } from 'redux';
import { LIST_SETTINGS, LIST_SETTINGS_CATEGORIES, LIST_ORDERTYPE_PRICES, LIST_ORDERTYPE_DISCOUNTS, LIST_DELIVERY_RADIUSES, GET_DELIVERY_SHAPE } from '../actions/types';

import ordertypesReducer from './ordertypes.reducer'
import deliveryRadiusesReducer from './deliveryRadiuses.reducer'
import deliveryShapesReducer from './deliveryShapes.reducer'

const initialState = {
    settings_categories: {},
    settings: {
        default_lang: {
            value: "bg"
        },
        translations_type: {
            value: 1            // google translations
        },
        show_variant_additional_price: {
            value: 0
        },
        order_active_statuses: {
            value: ""
        }
    },

    ordertypes: {
        ordertype_prices: [],
        ordertype_discounts: [],
    },

    delivery_radiuses: {},

    delivery_shape: {}
    
}

export default function(state = initialState, action) {
    switch(action.type) {
        case LIST_SETTINGS: {
            // window.localStorage.setItem("default_lang", action.payload.default_lang ? action.payload.default_lang.value : "en");
            return {
                ...state,
                settings: action.payload,
            }
        }
        case LIST_SETTINGS_CATEGORIES: {
            // window.localStorage.setItem("default_lang", action.payload.default_lang ? action.payload.default_lang.value : "en");
            return {
                ...state,
                settings_categories: action.payload,
            }
        }

        case LIST_ORDERTYPE_PRICES: {
            return {
                ...state,
                ordertypes: ordertypesReducer(state.ordertypes, action)
            }
        }
        case LIST_ORDERTYPE_DISCOUNTS: {
            return {
                ...state,
                ordertypes: ordertypesReducer(state.ordertypes, action)
            }
        }

        case LIST_DELIVERY_RADIUSES: {
            return {
                ...state,
                delivery_radiuses: deliveryRadiusesReducer(state.delivery_radiuses, action),
            }
        }

        
        case GET_DELIVERY_SHAPE: {
            return {
                ...state,
                delivery_shape: deliveryShapesReducer(state.delivery_shape, action),
            }
        }

        default:
            return state;
    }
}