import React, { Component } from 'react'
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { bindActionCreators } from 'redux'
import Utils from '../../../../utils/Utils';
import { ADMIN_URL } from '../../../../constants'
import moment from 'moment';

class MostOrderedProductVariants extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            
        }
    }

	render () {
        return (
            <div>
                <h5>{this.props.translations.statistics.most_ordered_product_variants} : {moment(this.props.filter_start_date).format("D/M/Y")} - {moment(this.props.filter_end_date).format("D/M/Y")}</h5>
                <div className="table-wrapper">
                    <table className="table table-responsive-sm table-striped break-content">
                        <thead>
                            <tr>
                                <th width="14%"></th>
                                <th width="40%">{this.props.translations.statistics.product_name}</th>
                                <th width="46%">{this.props.translations.statistics.times_ordered}</th>
                                {/* <th width="20%">{this.props.translations.common.action}</th> */}
                            </tr>
                        </thead>
                        <tbody>
                                {Object.values(this.props.mostOrderedProductVariants).map((row, index) => {
                                    // var duration = moment.duration(row.totalTimeSpent, 'seconds');
                                    // var formatted = duration.format("hh:mm:ss");
                                    // const formatted = moment.utc(row.totalTimeSpent*1000).format('HH:mm:ss');

                                    return (
                                        <tr key={index}>
                                            <td>
                                                {row.product_variant.product.product_images && row.product_variant.product.product_images[0] ?
                                                    <img style={{ maxHeight: 60 }} src={Utils.getProductImagePath(row.product_variant.product.product_images[0].path)} /> 
                                                : ""}
                                            </td>
                                            <td>
                                                <NavLink to={ADMIN_URL + 'products/edit/' + row.product_variant.product.id}>
                                                    {/* <button className="btn btn-pill btn-link" type="button"> */}
                                                        {row.productName} {row.variantName ? " - " + row.variantName : ""}
                                                    {/* </button> */}
                                                </NavLink>
                                                 
                                            </td>
                                            <td>{row.timesOrdered}</td>
                                        </tr>
                                    )
                                
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
           
            
        );
  }
}

const mapStateToProps = (state, dispatch) => {
    return {
        mostOrderedProductVariants: state.statistics.most_ordered_product_variants || [],
        translations: state.lang,
        currLang: "bg",
    }
};

const mapDispatchToProps = (dispatch, props) => {
    return {
        dispatch,
        ...bindActionCreators({  }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MostOrderedProductVariants);
