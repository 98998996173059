import React, { Component } from 'react'
import AddEditQuestionForm from './AddEditQuestionForm';
import { addEditQuestion, listQuestions} from './../actions/feedback.actions';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { ADMIN_URL, TRANSLATABLE_FIELDS } from './../../../constants';
import Utils from './../../../utils/Utils';

let md5 = require('md5');

class EditQuestionPage extends React.Component {
	constructor(props) {
		super(props)

        this.state = {
			id: this.props.match.params.id,
			text: this.props.question.text ? this.props.question.text  : '',
			type: this.props.question.type ? this.props.question.type : '',
			active: this.props.question.active? this.props.question.active : 0,
			currLang: this.props.currLang,
		}

	}

    componentDidMount() {
        this.props.listQuestions();

        if(this.props.question.id) {
            this.setState({
                id: this.props.match.params.id,
                // text: this.props.question.text ? this.props.question.text  : '',
				text: this.props.question.translations[this.props.currLang] && this.props.question.translations[this.props.currLang].text || this.props.question.text,
                type: this.props.question.type ? {value: this.props.question.type, label: this.props.translations.feedback.questions.question_types[this.props.question.type]}  : '',
                active: this.props.question.active? this.props.question.active : 0,
            });
        }
    }

	componentWillReceiveProps(nextProps) {
        if(nextProps.question.id) {
            this.setState({
                id: nextProps.match.params.id,
                // text: nextProps.question.text ? nextProps.question.text  : '',
				text: nextProps.question.translations[this.state.currLang] && nextProps.question.translations[this.state.currLang].text || nextProps.question.text,
                type: nextProps.question.type ? {value: nextProps.question.type, label: this.props.translations.feedback.questions.question_types[nextProps.question.type]}  : '',
                active: nextProps.question.active? nextProps.question.active : 0,
            });
        }
    }
    
	onChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
	}

	onChangeCheckbox = (e) => {
		this.setState({ [e.target.name]: e.target.checked });
	}
    
    onFieldChange = (fieldName, value) => {
        this.setState({ [fieldName]: value });
    }
    
	onSubmit = (e) => {
		e.preventDefault();

// return;
		const post = {
			id: this.state.id,
            text: this.state.text,
            type: this.state.type.value, //  md5(md5(this.state.question_num + (this.state.currentDate))),
			active: this.state.active,
		}
       
      
		this.props.addEditQuestion(post, this.props.history, this.props.languages[this.state.currLang].id);

	}
	
    deleteQuestion = (e, id) => {
        e.preventDefault();
        let data = {
            id: id,
            deleted: true
        };
        this.props.addEditQuestion(data);
    }
		
	currLangOnChange = (e) => {
        let newLang = e.target.value;

		let newState = {
			currLang: e.target.value
		};

		TRANSLATABLE_FIELDS.FEEDBACK_QUESTION.map(field => {
			newState[field] = this.props.question.translations[newLang] && this.props.question.translations[newLang][field] ? this.props.question.translations[newLang][field] : "";
		})

		this.setState(newState)
    }

    render() {  
		return (
			<main className="main">
        
				{/* <!-- Breadcrumb--> */}
				<ol className="breadcrumb">
				<li className="breadcrumb-item"><NavLink to={ADMIN_URL}>{this.props.translations.common.dashboard}</NavLink></li>
					{/* <li className="breadcrumb-item">Меню</li> */}
					<li className="breadcrumb-item"><NavLink to={ADMIN_URL + "feedback/questions"}>{this.props.translations.feedback.questions.title}</NavLink></li>
					<li className="breadcrumb-item active">{this.props.translations.feedback.questions.edit}</li>
					{/* <!-- Breadcrumb Menu--> */}
					<li className="breadcrumb-menu d-md-down-none">
						<div className="btn-group" role="group" aria-label="Button group">

						</div>
					</li>
				</ol>

        		<div className="container-fluid">
           			<div className="animated fadeIn">
              			<div className="row">
                
							{/* <!-- /.col--> */}                
							<div className="col-sm-12">
								<div className="card">
 
									<AddEditQuestionForm
										onSubmit={this.onSubmit}
										onChange={this.onChange}
                                        onChangeCheckbox={this.onChangeCheckbox}
                                        onFieldChange={this.onFieldChange}
										question={this.state}
										deleteQuestion={this.deleteQuestion}
										isAddingQuestion="0"
										translations={this.props.translations}
										currLang={this.state.currLang}
										currLangOnChange={this.currLangOnChange}
										languages={this.props.languages}

									/>
								</div>
							</div>
				  
				  		</div>
				  	</div>
				</div>

			</main>
    	)
  	}
}

const mapStateToProps = (state, ownProps) => {
  	let questionId = ownProps.match.params.id;

		let question = state.feedback.questions[questionId];
		
		if (!question) question = { 
			id: '',
            text: '',
            type: '',
			active: '',
	};
	return {
		question: question,
		// errors: state.questions.errors,
		translations: state.lang,
		languages: state.languages.languages || {},
		currLang: state.settings.settings['default_lang'] && state.settings.settings['default_lang'].value || 'bg',
	
	}


};

export default connect(mapStateToProps, { addEditQuestion, listQuestions })(EditQuestionPage);