import axios from 'axios';
import { LIST_TABLE_GROUPS } from './types';
import { API_URL, ADMIN_URL } from './../../../constants';
import { addSuccessMessage, addErrorMessages } from './../../messages/messages.actions';
import { changeLanguage } from './../../../actions/languagesActions';
import Auth from './../../../utils/Auth';
import jwtDecode from 'jwt-decode';
import { showLoading, hideLoading } from './../../common/loading/loading.actions';

export const listTableGroups = (place_id = 0) => dispatch => {
    let query = '';
    if(place_id == 0) {
        place_id = Auth.getUserPlace();
        query += "?place_id=" + place_id;
    }

    axios
        .get(API_URL + 'tableGroups' + query)
        .then(res => {
            dispatch({
                type: LIST_TABLE_GROUPS,
                payload: res.data
            });
        })
        .catch(error => {
            throw (error);
        });
}


export const addEditTableGroup = (data, history = null, langId = null) => dispatch => {
    if(!data.placeId) {
        data.placeId = Auth.getUserPlace();
    }


    return axios.post(API_URL + 'tableGroups', data)
        
}