import React from 'react'
import { NavLink } from 'react-router-dom';
import DeleteModal from '../../common/DeleteModal';
import QRCode from 'qrcode.react';
import { TABLE_QR_CODE_IMAGE_PATH, ADMIN_URL, SCAN_URL} from '../../../constants';
import { Link } from 'react-router-dom';
import MessagesContainer from '../../messages/MessagesContainer';
import Utils from '../../../utils/Utils';
import Select from 'react-select'

// let md5 = require('md5');

const AddEditTableHallForm = (props) => {

    // let tablesOptions = [];
    // console.log('object', props.categoriesHierarchy)

    // За да показва масите от еднаква зала
    // let currTablesRestaurantHallId = 0;
    // if(props.restaurantHall.tables && props.restaurantHall.tables.length > 0) {
    //     console.log("DAP", props.tables)
    //     let tableId = props.restaurantHall.tables[0].value;
    //     let table = props.tables && Object.values(props.tables).find(t => t.id == tableId);
    //     // currTablesRestaurantHallId = table && table.restaurantHallId || 0;
    // }
    // Object.values(props.tables).map(table => {
    //     if (table.active == 1) {
    //         // if(currTablesRestaurantHallId == 0 || table.restaurantHallId == currTablesRestaurantHallId) {
    //             tablesOptions.push({
    //                 'value': table.id,
    //                 'label': table.table_num 
    //             });
    //         // }
            
    //     }
    // });

    return (
        <form onSubmit={props.onSubmit} > 
            <div className="card-body">
                <MessagesContainer />

                <div className="row">
                    <div className="col-sm-12">
                        <div className="form-group">
                            <label htmlFor="name">{props.translations.common.name}</label>
                            <input className={`form-control`} id="name" type="text" title={"latin"} placeholder={props.translations.common.name} 
                                name="name" onChange={props.onChange} value={props.restaurantHall && props.restaurantHall.name} />
                        </div>
                    </div>

                    {/* <div className="form-group col-sm-12">
                        <label htmlFor="catHierarchyId">{props.translations.table_groups.tables}</label>

                        <Select
                            value={props.restaurantHall.tables}
                            isMulti
                            name="colors"
                            options={tablesOptions}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            onChange={(valueLabel) => props.onChangeSelectAutocomplete('tables', valueLabel)}
                        // onChange={(values, { action, removedValue }) => props.ingredientsOnChange(values, { action, removedValue }, 'removable')}
                        />
                    </div> */}

                    <label className="col-md-1 col-form-label">{props.translations.common.active}</label>
                    <div className="col-md-11 col-form-label">
                        <label className="switch switch-label switch-pill switch-outline-primary-alt">
                            <input className="switch-input" type="checkbox" name="active" onChange={props.onChangeCheckbox} checked={props.restaurantHall.active} />
                            <span className="switch-slider" data-checked={props.translations.common.yes} data-unchecked={props.translations.common.no}></span>
                        </label>
                    </div>
                </div>

                <hr />
                <br />

                <div className="form-actions">
                    {props.isAdding ? '' : <button className="btn btn-danger mright10" type="button" data-toggle="modal" data-target={"#dangerModal" + props.restaurantHallId} > {props.translations.common.delete}</button>}

                    <DeleteModal
                        id={props.restaurantHall.id}
                        title={'Region'}
                        deleteFunc={props.deleterestaurantHall}
                        translations={props.translations}
                    />

                    <NavLink to={ADMIN_URL + 'restaurantHalls'}>
                        <button className="btn btn-secondary" type="button">
                            {props.translations.common.cancel}
                        </button>
                    </NavLink>

                    <button className="fright btn btn-primary" type="submit">
                        {props.isAdding ? props.translations.common.add : props.translations.common.save}
                    </button>

                </div>

            </div>

        </form>
    )
}

export default AddEditTableHallForm;