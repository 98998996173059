import React from 'react'
import { NavLink } from 'react-router-dom';
import { ADMIN_URL } from '../../constants.js'
import moment from 'moment';
import Utils from '../../utils/Utils.js';
import Auth from '../../utils/Auth.js';


const ListEachBillWanted = (props) => {

    const { tableId, allBillWantedItems, translations, count, updateOrderByTableId } = props;

    let totaPriceTable = 0;

    // let updateDate;
    let visibleOrderDate = ''
    let paymentMethod = '';

    const translatedPaymentMethods = {
        "card": translations.common.card,
        "cash": translations.common.cash,
        "on_room_bill": translations.common.on_room_bill,
    }
    let tableNum = 0;
    
    allBillWantedItems.filter(allbw => allbw.tableId == tableId).map((b, count) => {
        if (b.status != 'paid') {
            // updateDate = moment(b.updatedAt).format('DD.MM.YYYY HH:mm');
            visibleOrderDate = Utils.formatDate(moment(b.createdAt).format('DD.MM.YYYY HH:mm'));

            if (b.payment_method != null) {
                paymentMethod = translatedPaymentMethods[b.payment_method];
            }
            if (!b.table || !b.table.table_num) {
                return;
            }
            tableNum = b.table.table_num;
            totaPriceTable = b.total_price;
        }
    });

    return (
        <tr key={tableId}
        >
            <td title={tableId}>
                {tableNum}
            </td>

            <td>
                <span className="badge badge-secondary" style={{ fontSize: 14 }}>{visibleOrderDate}</span>
            </td>

            <td>
                {paymentMethod}
            </td>

            <td>
                <button type="submit" class="btn btn-sm btn-success" onClick={() => { props.updateOrderByTableId({ tableId: tableId, status: "paid", placeId: Auth.getUserPlace() }) }} ><i class="fa fa-dot-circle-o"></i> {props.translations.common.paid}</button>
                <br /><br />
                {totaPriceTable} {props.translations.common.currency}
            </td>
        </tr>
    )

}

export default ListEachBillWanted;