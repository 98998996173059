import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { addEditRegion, listRegions } from '../actions/regions.actions';
import { listClients } from './../../clients/actions/clients.actions';
import { addErrorMessages } from '../../messages/messages.actions';
import { getCities } from '../../places/actions/places.actions';

import { ADMIN_URL } from '../../../constants';
import AddEditRegionForm from './AddEditRegionForm';
import { Tabs, Tab } from 'react-bootstrap';

class EditRegionPage extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			currLang: this.props.currLang,
			id: this.props.item.id,
			name: this.props.item.name,
			description: this.props.item.description,
			delivery_price: this.props.item.delivery_price,
			placeId: this.props.item.placeId,
			external_system_region_id: this.props.item.external_system_region_id,
			cityId: this.props.item.cityId,
		}
	}

	componentWillMount() {
		this.props.getCities();
	}
	
	componentWillReceiveProps(nextProps) {
		if (nextProps.item.id) {

			let regionCity = null
			if (nextProps.item.city) {
				// nextProps.table.ordertypes.map(type => {
					regionCity = { value: parseInt(nextProps.item.city.id), label: nextProps.item.city.name };
				// })
			}

			this.setState({
				id: nextProps.match.params.id,
				name: nextProps.item.name,
				description: nextProps.item.description,
				delivery_price: nextProps.item.delivery_price,
			    placeId: nextProps.item.placeId ? nextProps.item.placeId : '',
			    external_system_region_id: nextProps.item.external_system_region_id ? nextProps.item.external_system_region_id : '',
				cityId: nextProps.item.cityId ? nextProps.item.cityId : '',
			});
		}
	}

	onChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
	}

	onChangeCheckbox = (e) => {
		this.setState({ [e.target.name]: e.target.checked });
    }
      
    onChangeSelectAutocompleteItems = (valueLabel) => {
        this.setState({ 'placeId': valueLabel.value });
    }

	onFieldChange = (fieldName, value) => {
		this.setState({ [fieldName]: value });
	}

	validate() {
		let errors = [];

		if (this.state.name.length === 0) {
			errors.push(this.props.translations.messages.errors.invalid_name);
		}
		return errors;
	}

	onSubmit = (e) => {
		e.preventDefault();

		const errors = this.validate();
		if (errors.length > 0) {
			this.props.addErrorMessages(errors);
			return;
		}

		const post = {
			id: this.state.id,
			name: this.state.name,
			description: this.state.description,
			delivery_price: this.state.delivery_price,
			placeId: this.state.placeId,
			cityId: this.state.cityId || null,
			external_system_region_id: this.state.external_system_region_id
		}

		this.props.addEditRegion(post, this.props.history, this.props.languages[this.state.currLang].id);

	}

	deleteRegion = (e, id) => {
		e.preventDefault();
		this.props.addEditRegion({ id: id, deleted: true }, this.props.history);
	}

	currLangOnChange = (e) => {
		let newLang = e.target.value;
		let newNameValue = this.props.item.translations[newLang] && this.props.item.translations[newLang].name 
			? this.props.item.translations[newLang].name : "";

		this.setState({
			name: newNameValue,
			currLang: newLang
		})
	}

	render() {
		// let catItem = this.props.cat;

		return (
			<main className="main">

				{/* <!-- Breadcrumb--> */}
				<ol className="breadcrumb">
					<li className="breadcrumb-item"><NavLink to={ADMIN_URL}>{this.props.translations.common.dashboard}</NavLink></li>
					{/* <li className="breadcrumb-item">Меню</li> */}
					<li className="breadcrumb-item"><NavLink to={ADMIN_URL + "regions"}>{this.props.translations.regions.title}</NavLink></li>
					<li className="breadcrumb-item active">{this.props.translations.regions.edit}</li>
					<li className="breadcrumb-menu d-md-down-none">
                        <div className="btn-group" role="group" aria-label="Button group">
                            <NavLink to={ADMIN_URL + `regions/add`} className="btn" >
                                <i className="fas fa-plus"></i> &nbsp; {this.props.translations.regions.add}
                            </NavLink>
                        </div>
                    </li>
					{/* <!-- Breadcrumb Menu--> */}
				</ol>

				<div className="container-fluid">
					<div className="animated fadeIn">
						<div className="row">
							<div className="col-sm-12">
                                <div className="card">

									<Tabs
										style={{ width: '100%'}}
										id="controlled-tab-example"
										activeKey={this.state.currentTab}
										onSelect={(k) => this.setState({ currentTab: k })}
									>
										<Tab eventKey="edit" title={this.props.translations.regions.edit} >
											<div className="col-sm-12">
												<div className="card">

													<AddEditRegionForm
														onSubmit={this.onSubmit}
														onChange={this.onChange}
														onFieldChange={this.onFieldChange}
														onChangeSelectAutocompleteItems={this.onChangeSelectAutocompleteItems}
														// onChangeCheckbox={this.onChangeCheckbox}
														region={this.state}
														isAdding="0"
														deleteRegion={this.deleteRegion}
														translations={this.props.translations}
														currLang={this.state.currLang}
														currLangOnChange={this.currLangOnChange}
														languages={this.props.languages}
														regionCityValue={this.state.regionCityValue}
														cities={this.props.cities}
													/>

												</div>
											</div>
										</Tab>

									</Tabs>	
								</div>
							</div>
						</div>
					</div>
				</div>

			</main>
		)
	}
}

const mapStateToProps = (state, ownProps) => {
	let id = ownProps.match.params.id;

	let item = (state.regions.regions).find(i => i.id == id);
	if (!item) item = {
		id: '',
		name: '',
		description: '',
		delivery_price: '',
		placeId: '',
		translations: {}
	};
	return {
		item: item,
		errors: state.alergens.errors,
		translations: state.lang,
		languages: state.languages.languages || {},
		currLang: "bg",
		cities: state.places.cities || []
	}
};

export default connect(mapStateToProps, { addEditRegion, listRegions, addErrorMessages, getCities })(EditRegionPage);