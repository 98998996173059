import React, { Component } from 'react';
import { Stage, Layer, Rect, Text, Group } from 'react-konva';

class EachReservationTableBox extends Component {
    render() {
        const {
            table,
            state,
            isSelected,
            setActiveColorForTable,
            handleDragStart,
            handleDragEnd,
            handleItemSelected,
            getReservationDataByTable
        } = this.props;

        return (
            <Group
                id={table.id}
                x={state.isDraggingItem !== table.id ? (state.tableCoord && state.tableCoord[table.id] && state.tableCoord[table.id].x || 0) : undefined}
                y={state.isDraggingItem !== table.id ? (state.tableCoord && state.tableCoord[table.id] && state.tableCoord[table.id].y || 0) : undefined}
                draggable={state.enableDragging}
                onDragStart={e => state.enableDragging && handleDragStart(e, table.id)}
                onDragEnd={e => state.enableDragging && handleDragEnd(e, table.id)}
                onClick={() => handleItemSelected(table.id)}
                onTouchStart={() => handleItemSelected(table.id)}
            >
                <MemoizedRect
                    table={table}
                    isSelected={isSelected}
                    setActiveColorForTable={setActiveColorForTable}
                    state={state}
                />

                {/* Text components here */}
                
                <Text
                    text={`#${this.props.table.table_num}`} // Replace with the number or text you want to display
                    fontSize={14} // Adjust the font size as needed
                    fill={this.props.isSelected ? "white" : 'black'} // Color of the text
                    align="center" // Alignment of the text
                    width={parseInt(this.props.table.w)} // Adjust width if necessary
                    height={parseInt(this.props.table.h)} // Adjust height if necessary
                    verticalAlign='top'// Vertical alignment
                />

                <Text
                    // text={'Предстояща рез: 20:00 - 23:00'} // Replace with the number or text you want to display
                    text={this.props.getReservationDataByTable(this.props.table.id)} // Replace with the number or text you want to display
                    fontSize={14} // Adjust the font size as needed
                    fontStyle='bold'
                    fill={this.props.isSelected ? "white" : 'black'} // Color of the text
                    align="center" // Alignment of the text
                    width={parseInt(this.props.table.w)} // Adjust width if necessary
                    height={parseInt(this.props.table.h)} // Adjust height if necessary
                    verticalAlign='middle'// Vertical alignment
                />

                <Text
                    text={`/${this.props.table.reservation_min_people} - ${this.props.table.reservation_max_people}/`} // Replace with the number or text you want to display
                    fontSize={14} // Adjust the font size as needed
                    fill={this.props.isSelected ? "white" : 'black'} // Color of the text
                    align="center" // Alignment of the text
                    width={parseInt(this.props.table.w)} // Adjust width if necessary
                    height={parseInt(this.props.table.h)} // Adjust height if necessary
                    verticalAlign='bottom'// Vertical alignment
                // onClick={() => alert(5)} // Add onClick handler
                // style={{ zIndex: 999 }}
                />
            </Group>
        );
    }
}

// MemoizedRect component to optimize rendering
const MemoizedRect = React.memo(({ table, isSelected, setActiveColorForTable, state }) => (
    <Rect
        id={table.id}
        width={parseInt(table.w)}
        height={parseInt(table.h)}
        fill={isSelected ? 'black' : setActiveColorForTable(table.id)}
        opacity={state.enableDragging ? 0.8 : 1}
        strokeEnabled={!state.enableDragging}
        stroke="white"
        strokeWidth={2}
    />
));

export default EachReservationTableBox;
