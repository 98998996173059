import axios from 'axios';
import { LIST_ALERGENS } from './types';
import { API_URL, ADMIN_URL } from '../../../constants';
import { addErrorMessages, addSuccessMessage } from '../../messages/messages.actions';
import Utils from '../../../utils/Utils';

export const listAlergens = () => dispatch => {
    axios
        .get(API_URL + 'allergens')
        .then(res =>
            dispatch({
                type: LIST_ALERGENS,
                payload: res.data
            })
        ).catch(error => {
            // console.log('erroring ', error)
            throw (error);
        });
}

export const addEditAlergen = (postData, history, langId) => dispatch => {
    axios
        .post(API_URL + 'allergens', postData)
        .then((post) => {
            post = post.data;
            dispatch(addEditAllergenTranslation(post.id, langId, postData))
            // if (!postData.id && typeof postData.deleted === "undefined") { // edit
            // } else {
                dispatch(addSuccessMessage("Changes saved"))
                dispatch(listAlergens());
                history.push(ADMIN_URL + 'alergens');
                
            // }
        })
        .catch(function (error) {
            // dispatch(addErrorMessages('Error occured'));
        })
}

export const addEditAllergenTranslation = (allergen_id, lang_id, data) => dispatch => {
    axios
        .post(API_URL + `allergens/${allergen_id}/translations/${lang_id}`, data)
        .then(res => {

        })
        .catch(err => {

        })
}