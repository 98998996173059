import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { addEditBookingRoom, listBookingRooms } from '../../actions/bookings.actions';
import { addErrorMessages } from '../../../messages/messages.actions';

import { ADMIN_URL } from '../../../../constants';
import AddEditBookingRoomForm from './AddEditBookingRoomForm';
import { Tabs, Tab } from 'react-bootstrap';

class EditBookingRoomPage extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			currLang: this.props.currLang,
			id: this.props.item.id,
			name: this.props.item.name,
			catHierarchies: [],

		}
	}

	componentWillMount() {
		this.props.listBookingRooms();
	}
	
	// static getDerivedStateFromProps(nextProps, prevState) {
	// 	if (nextProps.item.name !== prevState.name) {
	// 		return ({
	// 			id: nextProps.match.params.id,
	// 			name: nextProps.item.name,
	// 			catHierarchyId: nextProps.item.catHierarchyId,
	// 		}) // <- this is setState equivalent
	// 	}
	// 	return null
	// }
	  

	componentWillReceiveProps(nextProps) {
		if (nextProps.item.id) {
			let currCats = [];
            if (nextProps.item.cat_hierarchies) {
                nextProps.item.cat_hierarchies.map(c => {
                    currCats.push({ value: c.id, 'label': c.name })
                })
			}
			
			this.setState({
				id: nextProps.match.params.id,
				name: nextProps.item.name,
				catHierarchies: currCats
			});

			console.log("CURR ", currCats)
		}
	}

	onChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
	}

	onChangeCheckbox = (e) => {
		this.setState({ [e.target.name]: e.target.checked });
    }
    
    onChangeSelectAutocomplete = (field, valueLabel) => {
        this.setState({ [field]: valueLabel.value });
	}
	
	onChangeSelectAutocompleteCategory = (valueLabel) => {
        this.setState({ 'catHierarchies': valueLabel });
	}
	
	validate() {
		let errors = [];

		if (this.state.name.length === 0) {
			errors.push(this.props.translations.messages.errors.invalid_name);
		}
		return errors;
	}

	onSubmit = (e) => {
		e.preventDefault();

		const errors = this.validate();
		if (errors.length > 0) {
			this.props.addErrorMessages(errors);
			return;
		}
		let catHierarchyIds = this.state.catHierarchies.map(c => c.value);

		const post = {
			id: this.state.id,
			name: this.state.name,
			catHierarchyIds: catHierarchyIds,
		}

		this.props.addEditBookingRoom(post, this.props.history, this.props.languages[this.state.currLang].id);

	}

	deleteBookingRoom = (e, id) => {
		e.preventDefault();
		this.props.addEditBookingRoom({ id: id, deleted: true }, this.props.history);
	}

	currLangOnChange = (e) => {
		let newLang = e.target.value;
		let newNameValue = this.props.item.translations[newLang] && this.props.item.translations[newLang].name 
			? this.props.item.translations[newLang].name : "";

		this.setState({
			name: newNameValue,
			currLang: newLang
		})
	}

	render() {
		// let catItem = this.props.cat;

		return (
			<main className="main">

				<ol className="breadcrumb">
					<li className="breadcrumb-item"><NavLink to={ADMIN_URL}>{this.props.translations.common.dashboard}</NavLink></li>
					<li className="breadcrumb-item"><NavLink to={ADMIN_URL + "bookings"}>{this.props.translations.bookings.title}</NavLink></li>
					<li className="breadcrumb-item"><NavLink to={ADMIN_URL + "bookings/rooms"}>{this.props.translations.bookings.rooms}</NavLink></li>
					<li className="breadcrumb-item active">{this.props.translations.bookings.edit_room}</li>
					<li className="breadcrumb-menu d-md-down-none">
                        <div className="btn-group" role="group" aria-label="Button group">
                            <NavLink to={ADMIN_URL + `bookings/rooms/add`} className="btn" >
                                <i className="fas fa-plus"></i> &nbsp; {this.props.translations.bookings.add_room}
                            </NavLink>
                        </div>
                    </li>
				</ol>

				<div className="container-fluid">
					<div className="animated fadeIn">
						<div className="row">
							<div className="col-sm-12">
                                <div className="card">

									<Tabs
										style={{ width: '100%'}}
										id="controlled-tab-example"
										activeKey={this.state.currentTab}
										onSelect={(k) => this.setState({ currentTab: k })}
									>
										<Tab eventKey="edit" title={this.props.translations.bookings.edit_room} >
											<div className="col-sm-12">
												<div className="card">

													<AddEditBookingRoomForm
														onSubmit={this.onSubmit}
														onChange={this.onChange}
														onChangeSelectAutocomplete={this.onChangeSelectAutocomplete}
														onChangeSelectAutocompleteCategory={this.onChangeSelectAutocompleteCategory}
														bookingRoom={this.state}
														isAdding="0"
														deleteBookingRoom={this.deleteBookingRoom}
														translations={this.props.translations}
														currLang={this.state.currLang}
														currLangOnChange={this.currLangOnChange}
														languages={this.props.languages}
														categories={this.props.categories}
													/>

												</div>
											</div>
										</Tab>

									</Tabs>	
								</div>
							</div>
						</div>
					</div>
				</div>

			</main>
		)
	}
}

const mapStateToProps = (state, ownProps) => {
	let id = ownProps.match.params.id;

	let item = (state.bookings.booking_rooms).find(i => i.id == id);
	if (!item) item = {
		id: '',
		name: '',
		catHierarchyId: '',
	};
	return {
		item: item,
		errors: state.alergens.errors,
		translations: state.lang,
		languages: state.languages.languages || {},
		categories: state.categoriesHierarchy.categories || [],
		currLang: "bg",
	}
};

export default connect(mapStateToProps, { addEditBookingRoom, listBookingRooms, addErrorMessages })(EditBookingRoomPage);