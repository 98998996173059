import React from 'react'

const Filter = (props) => {

    const catsAlphabeticallySorted = Object.values(props.allCategories).sort(function (a, b) {
        var nameA = a.name.toLowerCase(), nameB = b.name.toLowerCase()
        if (nameA < nameB) //sort string ascending
            return -1
        if (nameA > nameB)
            return 1
        return 0 //default return value (no sorting)
    });

    return (
        <div className="container-fluid">
            <div className="animated fadeIn">
                <div className="row">

                    <div className="form-group reservation-list-search col-md-2 col-sm-4 ">
                        <div className="form-group">
                            <label htmlFor="name">{props.translations.common.name}</label>
                            <input className={`form-control`} id="search_name" type="text" placeholder=""
                                name="search_name" onChange={props.onChange} value={props.search_name} />
                        </div>
                    </div>

                    <div className="form-group reservation-list-search col-md-2 col-sm-4 ">
                        <div className="form-group">
                            <label htmlFor="search_level">{props.translations.common.level}</label>
                            <select className="form-control" name="search_level" defaultValue={props.search_level} onChange={props.onChange}>
                                <option key={0} value="">-</option>
                                <option value={1}>1</option>
                                <option value={2}>2</option>
                                <option value={3}>3</option>
                            </select>
                            {/* <input className={`form-control`} id="search_level" type="text" placeholder="level"
                                name="search_level" onChange={props.onChange} value={props.search_level} /> */}
                        </div>
                    </div>

                    <div className="form-group reservation-list-search col-md-2 col-sm-4 ">
                        <div className="form-group">
                            <label htmlFor="name">{props.translations.common.parent_category}</label>

                            <select className="form-control" name="search_parent_category" onChange={props.onChange}>
                                <option key={0} value="">-</option>
                                {catsAlphabeticallySorted.map(s =>
                                    <option value={s.id}>{s.name} {s.code ? '(' + s.code + ')' : ''}</option>
                                )}
                            </select>
                        </div>
                    </div>

                    <div className="form-group reservation-list-search col-md-2 col-sm-4 ">
                        <div className="form-group">
                            <label htmlFor="name">{props.translations.common.status}</label>
                            <select className="form-control" name="search_active" defaultValue={props.search_active} onChange={props.onChange}>
                                <option key={0} value="">-</option>
                                <option value={1}>{props.translations.common.inactive}</option>
                                <option value={2}>{props.translations.common.active}</option>
                            </select>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Filter;