import React, { Component } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'

import { addEditProform, listProforms } from '../actions/proforms.actions';
import ListProformsRow from './ListProformsRow';
import { NavLink } from 'react-router-dom';
import { ADMIN_URL, ITEMS_PER_PAGE, ITEMS_PAGE_RANGE_DISPLAYED } from '../../../constants.js'
import Pagination from "react-js-pagination";
import { push } from 'react-router-redux';
// import Filter from './Filter';
import MessagesContainer from '../../messages/MessagesContainer';
import { translations } from '../../common/translations';
import Filter from './Filter';
import Utils from '../../../utils/Utils';
const PROFORM_STATUSES = ['new', 'sent', 'paid'];

class ListProformsPage extends React.Component {
    constructor(props) {
        super(props);
        this.activeDefaultLanguage = 'bg'; // TODO add it in db
        this.state = {
            search_proform_date: '',
            search_company_name: '',
            search_number: '',
            search_status: '',
            sort_by: ''
        }
    }

    componentWillMount() {
        this.props.listProforms();
    }

    deleteProform = (e, id) => {
        e.preventDefault();
        this.props.addEditProform({ id: id, deleted: true });
    }

    handlePageChange = (page) => {
        this.props.dispatch(push('/admin/proforms?page=' + page))
    }

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
        this.handlePageChange(1);
    }

    updateStatus = (e, proformId) => {
        let newStatus = e.target.options[e.target.options.selectedIndex].value;

        const post = {
            id: proformId,
            status: newStatus
        }

        this.props.addEditProform(post, this.props.history)
            .then((post) => {
                this.props.listProforms();
            })
            .catch((error) => {
                this.props.addErrorMessages(error);
            })
    }

    updateRemindDate = (date, proformId) => {
        const post = {
            id: proformId,
            remind_on: date
        }

        this.props.addEditProform(post, this.props.history)
            .then((post) => {
                this.props.listProforms();
            })
            .catch((error) => {
                this.props.addErrorMessages(error);
            })
    }

    updateProform = (object) => {
        const post = object;

        this.props.addEditProform(post, this.props.history)
            .then((post) => {
                this.props.listProforms();
            })
            .catch((error) => {
                this.props.addErrorMessages(error);
            })
    }

    render() {
        let proforms = this.props.items;

        if (this.state.sort_by) {
            if (this.state.sort_by == 'date') {
                proforms = proforms.sort(Utils.sortByRemindOn());
                // } else {
                //     proforms = this.props.items;
            }
        }

        if (this.state.search_company_name.length > 0
            || this.state.search_number != 0
            || this.state.search_status != ''
        ) {
            proforms = Object.values(proforms).filter(i => {
                return (
                    ((i.company_name).toLocaleLowerCase().includes((this.state.search_company_name).toLocaleLowerCase()))
                    && ((i.number).includes((this.state.search_number).toLocaleLowerCase()))
                    && (this.state.search_status != '' ? i.status == this.state.search_status : true)
                )
            })
        }

        const items_count = (proforms).length
        const current_page = this.props.page;
        const start_offset = (current_page - 1) * ITEMS_PER_PAGE;
        let start_count = 0;

        let totalSumProforms = 0
        proforms.map(i => totalSumProforms += parseFloat(i.price))

        return (
            <main className="main">

                {/* <!-- Breadcrumb--> */}
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><NavLink to={ADMIN_URL}>{this.props.translations.common.dashboard}</NavLink></li>
                    {/* <li className="breadcrumb-item">Меню</li> */}
                    <li className="breadcrumb-item active">{this.props.translations.proforms.title}</li>
                    {/* <!-- Breadcrumb Menu--> */}
                    <li className="breadcrumb-menu d-md-down-none">

                        <div className="btn-group c-pointer" role="group" aria-label="Button group" onClick={() => this.setState({ sort_by: (this.state.sort_by != 'date' ? 'date' : '') })}>
                            <span>
                                <i className="fas fa-calendar"></i> &nbsp; {this.props.translations.proforms.sort_by_date}
                            </span>
                        </div>

                        <div className="btn-group" role="group" aria-label="Button group">
                            <NavLink to={ADMIN_URL + `proforms/add`} className="btn" >
                                <i className="fas fa-plus"></i> &nbsp; {this.props.translations.proforms.add}
                            </NavLink>

                            {/* 
                            <!--<a className="btn" href="./">
                                <i className="icon-graph"></i>  Dashboard</a>
                            <a className="btn" href="#">
                                <i className="icon-settings"></i>  Settings</a>
                            --> 
                            */}
                        </div>
                    </li>


                </ol>

                <div className="container-fluid">
                    <div className="animated fadeIn">
                        <div className="row">

                            <Filter
                                translations={this.props.translations}
                                onChange={this.onChange}
                                search_proform_date={this.state.search_proform_date}
                                search_company_name={this.state.search_company_name}
                            />

                            {/* <!-- /.col--> */}
                            <div className="col-lg-12">
                                <div className="card">

                                    <div className="card-body">

                                        <MessagesContainer />

                                        <table className="table table-responsive-sm table-striped">
                                            <thead>
                                                <tr>
                                                    <th style={{ width: '10%' }}>{this.props.translations.proforms.number}</th>
                                                    <th style={{ width: '5%' }}>{this.props.translations.proforms.date}</th>
                                                    <th style={{ width: '5%' }}>{this.props.translations.proforms.company_name}</th>
                                                    <th style={{ width: '5%' }}>{this.props.translations.proforms.company_eik}</th>
                                                    <th style={{ width: '5%' }}>{this.props.translations.proforms.company_person}</th>
                                                    <th style={{ width: '10%' }}>{this.props.translations.proforms.company_email}</th>
                                                    <th style={{ width: '15%' }}>{this.props.translations.proforms.status}</th>
                                                    <th style={{ width: '10%' }} title={'Total sum filtered proforms: ' + totalSumProforms}>{this.props.translations.proforms.price}</th>
                                                    <th style={{ width: '5%' }}>{this.props.translations.common.place}</th>
                                                    {/* <th style={{ width: '10%' }}>{this.props.translations.proforms.remind_on}</th> */}
                                                    <th style={{ width: '15%' }}>{this.props.translations.proforms.partly_payment}</th>
                                                    <th style={{ width: '10%' }}>{this.props.translations.proforms.download}</th>
                                                    {/* <th>{this.props.translations.proforms.proform_original}</th>
                                                    <th>{this.props.translations.proforms.proform_copy}</th> */}
                                                    {/* <th>{this.props.translations.common.icon}</th> */}
                                                    <th style={{ width: '5%' }}>{this.props.translations.common.action}</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                {(proforms).map((proform, index) => {
                                                    if (index >= start_offset && start_count < ITEMS_PER_PAGE) {
                                                        start_count++;
                                                        return <ListProformsRow
                                                            key={proform.id}
                                                            proform={proform}
                                                            deleteProform={this.deleteProform}
                                                            updateStatus={this.updateStatus}
                                                            updateDate={this.updateRemindDate}
                                                            updateProform={this.updateProform}
                                                            // activeDefaultLanguage={this.activeDefaultLanguage}
                                                            translations={this.props.translations}
                                                            currLang={this.props.currLang}
                                                            initProformSettings={this.props.initProformSettings}
                                                            proformsSmsLogs={this.props.proformsSmsLogs}
                                                            proformStatuses={PROFORM_STATUSES}
                                                        />
                                                    }
                                                })}

                                            </tbody>
                                        </table>

                                        <Pagination
                                            className="pagination"
                                            itemClass="page-item"
                                            activePage={current_page}
                                            activeClass="active"
                                            linkClass="page-link"
                                            prevPageText="<"
                                            nextPageText=">"
                                            firstPageText="<<"
                                            lastPageText=">>"
                                            itemsCountPerPage={ITEMS_PER_PAGE}
                                            totalItemsCount={items_count}
                                            pageRangeDisplayed={ITEMS_PAGE_RANGE_DISPLAYED}
                                            onChange={this.handlePageChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            {/* <!-- /.col--> */}
                        </div>
                    </div>
                </div>
            </main>
        )
    }
}

const mapStateToProps = (state, dispatch) => {
    return {
        items: state.proforms.proforms || [],
        page: Number(state.router.location.query.page) || 1,
        translations: state.lang,
        proformsSmsLogs: state.proforms.proforms_sms_logs || [],
        currLang: "bg",
    }
};

const mapDispatchToProps = (dispatch, props) => {
    return {
        dispatch,
        ...bindActionCreators({ addEditProform, listProforms }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ListProformsPage);
