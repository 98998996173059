import React, { Component } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'

import { getAvgTimeSpentPerPage } from './../actions/statistics.actions';

import {LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend} from 'recharts';
import moment from 'moment';

class AvgTimeSpentPerPageTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            
        }
    }

    componentWillMount() {
        // this.props.getAvgTimeSpentPerPage();
    }

	render () {
        return (
            <div>
                <h3>{this.props.translations.statistics.avg_time_spent_per_page}</h3>
                <div className="table-wrapper">
                    <table className="table table-responsive-sm table-striped break-content">
                        <thead>
                            <tr>
                                <th width="40%">{this.props.translations.statistics.page}</th>
                                <th width="40%">{this.props.translations.common.category}</th>
                                <th width="40%">{this.props.translations.statistics.avg_time_spent}</th>
                                {/* <th width="20%">{this.props.translations.common.action}</th> */}
                            </tr>
                        </thead>
                        <tbody>
                                {Object.values(this.props.avgTimeSpentPerPageData).map((row, index) => {
                                    // var duration = moment.duration(row.totalTimeSpent, 'seconds');
                                    // var formatted = duration.format("hh:mm:ss");
                                    const formatted = moment.utc(row.totalTimeSpent*1000).format('HH:mm:ss');

                                    return (
                                        <tr key={index}>
                                            <td>
                                                {row.page} 
                                            </td>
                                            <td>
                                                {row.cat_hierarchy && row.cat_hierarchy.name ? row.cat_hierarchy.name : "-"} 
                                            </td>
                                            <td>{formatted}</td>
                                        </tr>
                                    )
                                
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
           
            
        );
  }
}

const mapStateToProps = (state, dispatch) => {
    return {
        avgTimeSpentPerPageData: state.statistics.avg_time_spent_per_page || [],
        translations: state.lang,
        currLang: "bg",
    }
};

const mapDispatchToProps = (dispatch, props) => {
    return {
        dispatch,
        ...bindActionCreators({ getAvgTimeSpentPerPage }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AvgTimeSpentPerPageTable);
