import axios from 'axios';
import { LIST_DELIVERY_SYSTEMS, SYNC_CATEGORIES, SYNC_PRODUCTS } from './types';
import { API_URL, ADMIN_URL } from '../../../constants';
import { addSuccessMessage, addErrorMessages } from '../../messages/messages.actions';
import { changeLanguage } from '../../../actions/languagesActions';
import Auth from '../../../utils/Auth';

export const listDeliverySystems = (place_id = 0) => dispatch => {
    let getParam = '';
    if(place_id == 0) {
        place_id = Auth.getUserPlace();
        getParam += "?place_id=" + place_id;
    }

    axios
        .get(API_URL + 'ds' + getParam)
        .then(res => {
            dispatch({
                type: LIST_DELIVERY_SYSTEMS,
                payload: res && res.data || []
            });
        })
        .catch(error => {
            throw (error);
        });
}

export const syncCitiesAndRegions = (place_id = 0) => dispatch => {
    let getParam = '';
    if(place_id == 0) {
        place_id = Auth.getUserPlace();
        getParam += "?place=" + place_id;
    }

    return axios
        .post(API_URL + 'ds/sync-cities-and-regions' + getParam)
}

export const syncProducts = (place_id = 0) => dispatch => {
    let getParam = '';
    if(place_id == 0) {
        place_id = Auth.getUserPlace();
        getParam += "?place=" + place_id;
    }

    return axios
        .post(API_URL + 'whs/sync-products' + getParam)
}


export const syncOrderStatuses = (place_id = 0) => dispatch => {
    let getParam = '';
    if(place_id == 0) {
        place_id = Auth.getUserPlace();
        getParam += "?place=" + place_id;
    }

    return axios
        .post(API_URL + 'whs/sync-order-statuses' + getParam)
}

export const syncOrderDeliveryChannels = (place_id = 0) => dispatch => {
    let getParam = '';
    if(place_id == 0) {
        place_id = Auth.getUserPlace();
        getParam += "?place=" + place_id;
    }

    return axios
        .post(API_URL + 'whs/sync-order-delivery-channels' + getParam)
}

export const syncOrderPaymentTypes = (place_id = 0) => dispatch => {
    let getParam = '';
    if(place_id == 0) {
        place_id = Auth.getUserPlace();
        getParam += "?place=" + place_id;
    }

    return axios
        .post(API_URL + 'whs/sync-order-payment-types' + getParam)
}