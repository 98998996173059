import { API_TOKEN, SETTINGS_IMG_URL, PRODUCT_THUMB_IMG_URL, PRODUCT_IMG_URL, INVOICES_PATH, PROFORMS_PATH, PLACE_IMG_URL, VIDEOS_URL } from '../constants'
// import history from './history';
import { history } from './../store';
import { translations } from '../components/common/translations';
import Auth from './Auth';
import store from './../store';

let md5 = require('md5');

class Utils {

	static generateTableToken(tableNum) {
		return md5(md5(tableNum + (+new Date)))
	}

	static cropMail(email) {
		if (email && (email.indexOf("@") !== -1)) {
			return email.split("@")[0];
		}
		return email;
	}

	static onChange(e) {
		return { [e.target.name]: (e.target.type && e.target.type === 'number') ? parseFloat(e.target.value) : e.target.value }
	}

	static objectToFormData(obj) {
		let formData = new FormData();
		for (var key in obj) {
			formData.append(key, obj[key]);
		}
		return formData;
	}

	static onChange(e) {
		return { [e.target.name]: (e.target.type && e.target.type === 'number') ? parseFloat(e.target.value) : e.target.value }
	}

	static onChangeCheckbox(e) {
		return { [e.target.name]: e.target.checked ? 1 : 0 };
	}

	static truncate(input, maxInputLength = 15, cutFirstXSymbold = 5) {
		if (input.length == 0) return '-';

		if (input.length > maxInputLength)
			return input.substring(0, cutFirstXSymbold) + '...';
		else
			return input;
	}

	static appendLeadingZeroes = (n) =>
		(n <= 9 ? "0" + n : n)

	static appendTokenToFormData = (formData) =>
		(formData.append('Authorization', API_TOKEN))


	static buildURLQuery = (obj) =>
		Object.entries(obj)
			.map(pair => pair.map(encodeURIComponent).join('='))
			.join('&');

	static changeListDateFormat = (d) => {
		let bgMonths = [];
		bgMonths[0] = 'яну';
		bgMonths[1] = 'фев';
		bgMonths[2] = 'март';
		bgMonths[3] = 'апр';
		bgMonths[4] = 'май';
		bgMonths[5] = 'юни';
		bgMonths[6] = 'юли';
		bgMonths[7] = 'авг';
		bgMonths[8] = 'сеп';
		bgMonths[9] = 'окт';
		bgMonths[10] = 'ное';
		bgMonths[11] = 'дек';

		return Utils.appendLeadingZeroes(d.getDate()) + '-' + bgMonths[d.getMonth()] + '-' + d.getFullYear() + ' ' + Utils.appendLeadingZeroes(d.getHours()) + ':' + Utils.appendLeadingZeroes(d.getMinutes())
	}

	static errorHandler(error) {
		const customErrorMsg = "Възникна грешка #";
		let errorCode = 0;

		// https://gist.github.com/fgilio/230ccd514e9381fafa51608fcf137253
		if (error.response) {
			errorCode = 1;
			console.log(error.response.data); //DO NOT REMOVE
			console.log(error.response.status); //DO NOT REMOVE
			console.log(error.response.headers); //DO NOT REMOVE
			/*
				* The request was made and the server responded with a
				* status code that falls out of the range of 2xx
			*/
		} else if (error.request) {
			errorCode = 2;
			errorCode += ' - Проблем със сървъра или слаб интернет!';
			/*
			* The request was made but no response was received, `error.request`
			* is an instance of XMLHttpRequest in the browser and an instance
			* of http.ClientRequest in Node.js
			*/
			console.log(error.request); //DO NOT REMOVE
		} else {
			errorCode = 3;
			// Something happened in setting up the request and triggered an Error
			console.log('Error', error.message); //DO NOT REMOVE
		}

		return customErrorMsg + errorCode;
	}

	static navigateTo = (url) => {
		history.push(url);
	}
	static goBack = () => {
		history.goBack();
	}

	static getSettingsValue = (settings, settingsName = '', returnDefault = false) => {
		if (settingsName && settings && settings[settingsName] && settings[settingsName].value && (settings[settingsName].value).toString().length > 0) {
			return settings[settingsName].value;
		}
		return returnDefault;
	}

	static getSetting = (name) => {
		let currState = store.getState();

		if (currState && currState.settings && currState.settings.settings && currState.settings.settings[name]) {
			return currState.settings.settings[name].value;
		}

		return null;
	}

	static getSettingsPath = (imgSrc) => {
		if (imgSrc) {
			if ((imgSrc.indexOf('http://') === 0 || imgSrc.indexOf('https://') === 0)) {
				return imgSrc;
			}
			if (window.location.hostname == 'localhost') {
				return SETTINGS_IMG_URL.replace(window.location.origin, 'https://menu.tabl.bg') + imgSrc;
			}
			return SETTINGS_IMG_URL + imgSrc;
		}
		return '';
	}


	static getProductImagePath = (imgSrc) => {
		if (imgSrc) {
			if ((imgSrc.indexOf('http://') === 0 || imgSrc.indexOf('https://') === 0)) {
				return imgSrc;
			}
			if (window.location.hostname == 'localhost') {
				return PRODUCT_THUMB_IMG_URL.replace(window.location.origin, 'https://menu.tabl.bg') + imgSrc;
			}

			if (imgSrc.includes('gif')) {
				return PRODUCT_IMG_URL + imgSrc;
			} else {
				return PRODUCT_THUMB_IMG_URL + imgSrc;
			}
		}
		return '';
	}

	static getPlaceImagePath = (imgSrc) => {
		if (imgSrc) {
			if ((imgSrc.indexOf('http://') === 0 || imgSrc.indexOf('https://') === 0)) {
				return imgSrc;
			}
			if (window.location.hostname == 'localhost') {
				return PLACE_IMG_URL.replace(window.location.origin, 'https://menu.tabl.bg') + imgSrc;
			}
			return PLACE_IMG_URL + imgSrc;
		}
		return '';
	}

	static getSubcategoryImagePath = (imgSrc) => {
		if (imgSrc) {
			if ((imgSrc.indexOf('http://') === 0 || imgSrc.indexOf('https://') === 0)) {
				return imgSrc;
			}
			if (window.location.hostname == 'localhost') {
				return PRODUCT_THUMB_IMG_URL.replace(window.location.origin, 'https://menu.tabl.bg') + imgSrc;
			}
			return PRODUCT_THUMB_IMG_URL + imgSrc;
		}
		return '';
	}


	static getCategoryImagePath = (imgSrc) => {
		if (imgSrc) {
			if ((imgSrc.indexOf('http://') === 0 || imgSrc.indexOf('https://') === 0)) {
				return imgSrc;
			}
			if (window.location.hostname == 'localhost') {
				return PRODUCT_THUMB_IMG_URL.replace(window.location.origin, 'https://menu.tabl.bg') + imgSrc;
			}
			return PRODUCT_THUMB_IMG_URL + imgSrc; //TODO this is not product_img_url
		}
		return '';
	}

	static getCategoryVideoPath = (videoName) => {
		if (videoName) {
			if ((videoName.indexOf('http://') === 0 || videoName.indexOf('https://') === 0)) {
				return videoName;
			}
			if (window.location.hostname == 'localhost') {
				return VIDEOS_URL.replace(window.location.origin, 'https://menu.tabl.bg') + videoName;
			}

			return VIDEOS_URL + "/" + videoName
		}
		return '';
	}

	// {Utils.translate(this.props.translations.common.dashboard, {count: 5, total: 8})}
	static translate(text, args = {}) {
		if (Object.keys(args).length === 0) {
			return text;
		}

		Object.entries(args).map(a => text = text.replace('{' + a[0] + '}', a[1]))
		return text;
	}


	static normalizePrice = (price) => {
		return price.toFixed(2);
	}

	
	static addIngredientsPrice = (item, productIngredients) => {
		let ingredientsPrice = 0;
		// console.log('item INGR', item)
		// console.log('productIngredients', productIngredients)
		// console.log('parseInt(item.ingredients.required)', parseInt(item.ingredients.required))
		// console.log('item.product_ingredients', item.product_ingredients)
		// console.log('productIngredients', productIngredients)
		if (item && item.ingredients && item.product_ingredients && Object.values(item.product_ingredients).length > 0 && productIngredients) {
			if (item.ingredients.required) {
				if (productIngredients && productIngredients[parseInt(item.ingredients.required)] && productIngredients[parseInt(item.ingredients.required)].price) {
					// console.log('adding: productIngredients[parseInt(item.ingredients.required)].price', productIngredients[parseInt(item.ingredients.required)].price)
					ingredientsPrice += parseFloat(productIngredients[parseInt(item.ingredients.required)].price);
				}
			}

			if (item.ingredients.addable) {
				Object.values(item.ingredients.addable).map(ingrId => {
					if (productIngredients && productIngredients[parseInt(ingrId)] && productIngredients[parseInt(ingrId)].price) {
						// console.log('adding: productIngredients[parseInt(ingrId)].price', productIngredients[parseInt(ingrId)].price)
						ingredientsPrice += parseFloat(productIngredients[parseInt(ingrId)].price);
					}
				})
			}

			// if (item.ingredients.removable) {
			//     Object.values(item.ingredients.removable).map(ingrId => {
			//         if (this.props.productIngredients && this.props.productIngredients[parseInt(ingrId)] && this.props.productIngredients[parseInt(ingrId)].price) {
			//             console.log('adding: this.props.productIngredients[parseInt(ingrId)].price', this.props.productIngredients[parseInt(ingrId)].price)
			//             ingredientsPrice += parseFloat(this.props.productIngredients[parseInt(ingrId)].price);
			//         }
			//     })
			// }

		}

		return ingredientsPrice;
	}

	
	// Each price for each product - List products
	static calculateProductPriceListingProducts = (productVariantPrice, discountPercent) => {
		let calculatedProductPrice = productVariantPrice;

		if (discountPercent) {
			calculatedProductPrice = (parseFloat(calculatedProductPrice - ((parseFloat(discountPercent) * parseFloat(calculatedProductPrice)) / 100))).toFixed(2);
		}

		// TODO - add price for default required ingredient

		return calculatedProductPrice;

	}

	static calculateProductPriceOrder = (item, settings, productIngredients, forceQuantity, calculateAdditionalPrice = true) => {
		let totalCartPrice = 0;

		// Cart price structure / Order price structure
		const price = item.price ? item.price : item.product_variant ? item.product_variant.price : 0;
		const discount_percent = item.discount_percent;
		const quantity = item.quantity;
		const additional_price = item.additional_price ? item.additional_price : item.product_variant ? item.product_variant.additional_price : 0;

		//Product discount
		let productPrice = this.calculateProductPriceListingProducts(price, discount_percent);

		// Product quantity
		let productQuantity = forceQuantity ? forceQuantity : quantity;

		//ingredients
		totalCartPrice += (parseFloat(productPrice) + parseFloat(this.addIngredientsPrice(item, productIngredients))) * 1 * productQuantity;

		// BOX
		if (calculateAdditionalPrice && (settings.show_variant_additional_price && settings.show_variant_additional_price.value && settings.show_variant_additional_price.value == 1) && (((parseFloat(additional_price)).toFixed(2)) != '0.00')) {
			totalCartPrice += parseFloat(additional_price) * 1 * productQuantity;
		}

		return parseFloat(totalCartPrice).toFixed(2);

	}

	static compareForPriority = (asc = true) => {
		return function (a, b) {
			let priorityOne = a.priority * 1;
			let priorityTwo = b.priority * 1;
			// // nulls sort after anything else
			// if (priorityOne === 0) {
			//     return 1;
			// }
			// else if (priorityTwo === 0) {
			//     return -1;
			// }
			// // otherwise, if we're ascending, lowest sorts first
			// return priorityOne < priorityTwo ? -1 : 1;

			return (priorityOne < priorityTwo) ? -1 : ((priorityOne > priorityTwo) ? 1 : 0);

		};
	}

	static sortName = (asc = true, column = 'name') => {
		return function (a, b) {
			var nameA = a[column].toUpperCase(); // ignore upper and lowercase
			var nameB = b[column].toUpperCase(); // ignore upper and lowercase
			if (nameA < nameB) {
				return -1;
			}
			if (nameA > nameB) {
				return 1;
			}

			// names must be equal
			return 0;
		}
	}

	static checkDatesInSameDay = (d1, d2) => {
		d1 = new Date(d1);
		d2 = new Date(d2)

		d1.setHours(0);
		d1.setMinutes(0);
		d1.setSeconds(0);

		d2.setHours(0);
		d2.setMinutes(0);
		d2.setSeconds(0);

		return d1.getFullYear() === d2.getFullYear() &&
			d1.getMonth() === d2.getMonth() &&
			d1.getDate() === d2.getDate();
	}

	static checkDatesInSameDayAndHour = (d1, d2) => {
		d1 = new Date(d1);
		d2 = new Date(d2)

		// d1.setHours(0);
		d1.setMinutes(0);
		d1.setSeconds(0);

		// d2.setHours(0);
		d2.setMinutes(0);
		d2.setSeconds(0);

		return d1.getFullYear() === d2.getFullYear() &&
			d1.getMonth() === d2.getMonth() &&
			d1.getDate() === d2.getDate() &&
			Math.abs(d1.getHours() - d2.getHours()) <= 1 // written,because of: if now is 17:59 and order is for 18:30 it will be future..
	}

	static sortByRemindOn = (asc = true) => {
		return function (a, b) {
			// let priorityOne = a.remind_on;
			// let priorityTwo = b.remind_on;
			// equal items sort equally
			// if (a === b) {
			//     return 0;
			// }
			// // nulls sort after anything else
			// if (priorityOne === 0) {
			// 	return 1;
			// }
			// else if (priorityTwo === 0) {
			// 	return -1;
			// }
			// // otherwise, if we're ascending, lowest sorts first
			// return priorityOne < priorityTwo ? -1 : 1;

			return new Date(a.remind_on) - new Date(b.remind_on);

			// return (priorityOne < priorityTwo) ? -1 : ((priorityOne > priorityTwo) ? 1 : 0);

		};
	}

	static getCurrClientPlacesCount(allPlaces) {
		let currPlaceId = Auth.getUserPlace();
		let currClientPlacesCount = 0;

		if (allPlaces) {
			let currPlace = allPlaces.find(p => p.id == currPlaceId);
			if (currPlace) {
				let currPlaceClientId = currPlace.clientId;
				currClientPlacesCount = allPlaces.filter(p => p.clientId == currPlaceClientId).length;
			}
		}

		return currClientPlacesCount;
	}

	static getOptionsForAutocompleteSelect(cats, returnOptions = true) {
		let categoriesHierarchyOptions = [];
		let returnCatObejct = [];

		if (cats) {
			Object.values(cats).map(cat => {

				let undeletedChildrenCount = cat.children.filter(c => c.deleted == 0).length;
				if (/*cat.active == 1 && */cat.deleted == 0 && (cat.children.length == 0 || undeletedChildrenCount == 0)) {

					if (returnOptions) {
						let dashes = '';
						for (let i = 0; i < cat.hierarchyLevel - 1; i++) {
							dashes += "-";
						}

						categoriesHierarchyOptions.push({
							'value': cat.id,
							'label': dashes + cat.name + (cat.code ? ' (' + cat.code + ')' : '') + (cats && cats[cat.parentId] && cats[cat.parentId].name ? (" /" + cats[cat.parentId].name + '/') : '')
						});
					} else {
						returnCatObejct.push(cat)
					}

				}
			});
		}
		return returnOptions ? categoriesHierarchyOptions : returnCatObejct;
	}

	static getIngredientNamesByIds = (ingredients, ingredientIds) => {
		// console.log('this.props', props)
		// console.log('checking for: ', ingredientIds)
		let ingredientsNames = '';
		if (ingredients && Object.values(ingredients).length) {
			// debugger;
			if (Array.isArray(ingredientIds)) {
				// console.log('arr')
				Object.values(ingredientIds).map((i, k) => {
					// console.log('map: ', i, ' ', k)

					if (ingredients[i] && ingredients[i].name) {
						if (k != 0) {
							ingredientsNames += ', ';
						}
						ingredientsNames += ingredients[i].name;
					}
					// console.log('ingredientsNames: ', ingredientsNames)
				})
			} else {
				// console.log('elsa')
				if (ingredients && ingredients[parseInt(ingredientIds)] && ingredients[parseInt(ingredientIds)].name) {
					ingredientsNames += ingredients[parseInt(ingredientIds)].name;
				}
				// console.log('ingredientsNames :', ingredientsNames)
			}
		}

		return ingredientsNames;
	}

	static getInvoiceImagePath = (imgSrc) => {
		if (imgSrc) {
			if ((imgSrc.indexOf('http://') === 0 || imgSrc.indexOf('https://') === 0)) {
				return imgSrc;
			}
			if (window.location.hostname == 'localhost') {
				return INVOICES_PATH.replace(window.location.origin, 'https://menu.tabl.bg') + imgSrc;
			}
			return INVOICES_PATH + imgSrc;
		}
		return '';
	}

	static getProformImagePath = (imgSrc) => {
		if (imgSrc) {
			if ((imgSrc.indexOf('http://') === 0 || imgSrc.indexOf('https://') === 0)) {
				return imgSrc;
			}
			if (window.location.hostname == 'localhost') {
				return PROFORMS_PATH.replace(window.location.origin, 'https://menu.tabl.bg') + imgSrc;
			}
			return PROFORMS_PATH + imgSrc;
		}
		return '';
	}

	static hasFunctionalityModule = (functionalityModule) => {
		let currState = store.getState();

		if (currState && currState.places && currState.places.places) {
			let currPlace = currState.places.places.find(p => p.id == Auth.getUserPlace());
			if (currPlace && currPlace.functionality_modules) {
				let functionalityModulesForPlace = currPlace.functionality_modules;

				if (functionalityModulesForPlace.find(f => f.name == functionalityModule)) {
					return true;
				}
			}
		}

		return false;
	}

	static getOrdersStyleType = () => {
		const tabl_orders_style_type = localStorage.getItem('tabl_orders_style_type');
		if (!tabl_orders_style_type) {
			return 'row';
		}
		return tabl_orders_style_type;
	}

	static changeOrdersStyleType = () => {
		localStorage.setItem('tabl_orders_style_type', this.getOrdersStyleType() == 'row' ? 'card' : 'row');
	}

	static generateArrayWithLength = (length) => {
		let arr = [];
		for (let i = 0; i < length; i++) {
			arr.push(i)
		}
		return arr;
	}

	static isWHSActive() {
		let currState = store.getState();

		if (currState && currState.places && currState.places.places) {
			let currPlace = currState.places.places.find(p => p.id == Auth.getUserPlace());
			if (currPlace && currPlace.warehouse_system_credentials && currPlace.warehouseSystemId) {
				return true
			}
		}

		return false;
	}

	static getDefaultLang() {
		let defaultLang = this.getSetting('default_lang');
		let currState = store.getState();

		if (currState.languages && currState.languages.languages[defaultLang]) {
			return currState.languages.languages[defaultLang]
		}

		return null;
	}

	static formatDate(dateString) {
        const months = ["януари", "февруари", "март", "април", "май", "юни", "юли", "август", "септември", "октомври", "ноември", "декември"];
        const daysOfWeek = ["неделя", "понеделник", "вторник", "сряда", "четвъртък", "петък", "събота"];

        // Parse the input date string
        const [datePart, timePart] = dateString.split(' ');
        const [day, month, year] = datePart.split('.').map(Number);
        const [hour, minute] = timePart.split(':').map(Number);

        const inputDate = new Date(year, month - 1, day, hour, minute);

        // Get current date
        const now = new Date();
        const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
        const yesterday = new Date(today);
        yesterday.setDate(today.getDate() - 1);

        const diffTime = today - new Date(year, month - 1, day);
        const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));

        if (inputDate >= today) {
            return `днес в ${hour}:${minute < 10 ? '0' : ''}${minute}`;
        } else if (inputDate >= yesterday && inputDate < today) {
            return `вчера в ${hour}:${minute < 10 ? '0' : ''}${minute}`;
        } else if (diffDays > 1 && diffDays <= 30) {
            return `преди ${diffDays} дни в ${hour}:${minute < 10 ? '0' : ''}${minute}`;
        } else {
            return `${day} ${months[month - 1]} ${year}, ${daysOfWeek[inputDate.getDay()]} ${hour}:${minute < 10 ? '0' : ''}${minute}`;
        }
    }

}

export default Utils;