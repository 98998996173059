import React from 'react'
import { NavLink } from 'react-router-dom';
import { ADMIN_URL } from '../../../constants.js'
import Auth from '../../../utils/Auth.js';

const ListEachPlace = (props) => (
    <tr key={props.place.id}>
        <td>
            <input type="checkbox"
                className="d-inline medium-checkbox valign-middle c-pointer w-10 m-right-7 valign-middle " style={{ width: 'auto !important' }}
                checked={props.state.checked_checkboxes_ids.includes(props.place.id)}
                onChange={() => props.toggleCheckboxValue(props.place.id)}
            />
        </td>

        <td>
            {props.place.name}
        </td>

        <td>
            {Auth.hasAccess('SUPER_ADMIN') ? props.place.monthly_fee : '-'}
        </td>
        <td>
            {Auth.hasAccess('SUPER_ADMIN') ?
                <div className="col-md-2 col-form-label">
                    <label className="switch switch-label switch-pill switch-outline-primary-alt">
                        <input disabled className="switch-input" type="checkbox" name="is_paying" checked={props.place.is_paying} />
                        <span className="switch-slider" data-checked={props.translations.common.yes} data-unchecked={props.translations.common.no}></span>
                    </label>
                </div>
                :
                '-'
            }
        </td>

        <td>
            <NavLink to={ADMIN_URL + 'places/edit/' + props.place.id}>
                <button className="btn btn-pill btn-link" type="button">
                    <i className="fas fa-edit"></i>
                </button>
            </NavLink>


        </td>
    </tr>
)

export default ListEachPlace;