import React, { Component } from 'react'
import AddEditTableGroupForm from './AddEditTableGroupForm';
import { addEditTableGroup, listTableGroups } from '../actions/tableGroups.actions';
import { addErrorMessages } from './../../messages/messages.actions';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { ADMIN_URL } from '../../../constants';
import Utils from './../../../utils/Utils';
import Auth from './../../../utils/Auth';

let md5 = require('md5');

class EditTableGroupPage extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			id: this.props.match.params.id,
			min_people_count: '',
			max_people_count: '',
			tables: [],
			active: 1,			
		}
	}

	componentWillReceiveProps(nextProps) {
		let tables = [];
		console.log("NETX ", nextProps)
		if (nextProps.tableGroup.tables.length > 0 && nextProps.tables.length > 0) {
			nextProps.tableGroup.tables.map(t => {
				let table = nextProps.tables.find(tt => tt.id == t.id);

				tables.push({ 
					value: t.id, 
					'label': table.table_num + " (от " + table.reservation_min_people + " до " + table.reservation_max_people +  " човека) " 
					+ (table.restaurantHall && ("("+table.restaurantHall.name+")") || "")
				})
				
			})

			this.setState({
				id: nextProps.match.params.id,
				min_people_count: nextProps.tableGroup.min_people_count ? nextProps.tableGroup.min_people_count : '',
				max_people_count: nextProps.tableGroup.max_people_count ? nextProps.tableGroup.max_people_count : '',
				active: nextProps.tableGroup.active ? nextProps.tableGroup.active : 0,
				tables: tables,
			});
		}
		
		
		
	}

	componentWillMount() {
		this.props.listTableGroups();

		// let tableGroupOrdertype = [];
		// if (this.props.tableGroup.ordertypes.length > 0) {
		// 	// nextProps.tableGroup.ordertypes.map(type => {
		// 	tableGroupOrdertype.push({ value: parseInt(this.props.tableGroup.ordertypes[0].id), label: this.props.tableGroup.ordertypes[0].type })
		// 	// })
		// }

		// this.setState({
		// 	tableGroupOrdertype: tableGroupOrdertype,
		// 	tableGroupOrdertypeValue: tableGroupOrdertype && tableGroupOrdertype[0] && tableGroupOrdertype[0] || ""
		// })
	}

	onChange = (e) => {
		this.setState({ [e.target.name]: e.target.value })
	}

	onChangeCheckbox = (e) => {
		this.setState({ [e.target.name]: e.target.checked });
	}

	onChangeSelectAutocomplete = (fieldName, valueLabel) => {
		this.setState({ [fieldName]: valueLabel });
	}

	validate = () => {
		let errors = [];
		if (this.state.min_people_count.length == 0) {
			errors.push(this.props.translations.messages.errors.invalid_min_people_count);
		}
		if (this.state.max_people_count.length == 0) {
			errors.push(this.props.translations.messages.errors.invalid_max_people_count);
		}
		if(this.state.min_people_count > this.state.max_people_count) {
			errors.push(this.props.translations.messages.errors.min_people_must_be_less_than_max_people);			
		}
		if(!this.state.tables || this.state.tables.length == 0) {
			errors.push(this.props.translations.messages.errors.invalid_tables);			
		}
		return errors;
	}

	onSubmit = (e) => {
		e.preventDefault();

		const errors = this.validate();
		if (errors.length > 0) {
			this.props.addErrorMessages(errors);
			return;
		}

		let tablesIds = this.state.tables.map(t => t.value);

		const post = {
			id: this.state.id,
			min_people_count: this.state.min_people_count,
			max_people_count: this.state.max_people_count,
			tables_ids: tablesIds
		}

		// dispatch(showLoading());

		this.props.addEditTableGroup(post)
		.then((post) => {
            post = post.data;
            this.props.listTableGroups();
			this.props.history.push("/admin/tableGroups/edit/"+post.id);                
            // dispatch(hideLoading());
        })
        .catch(function (error) {
			this.props.addErrorMessages('Error occured');
        })
	}

	deleteTableGroup = (e, id) => {
		e.preventDefault();
		// this.props.removeTableGroup(id, this.props.history);
	}

	

	render() {
		// console.log("STATE", this.state);
		return (
			<main className="main">

				{/* <!-- Breadcrumb--> */}
				<ol className="breadcrumb">
					<li className="breadcrumb-item"><NavLink to={ADMIN_URL}>{this.props.translations.common.dashboard}</NavLink></li>
					{/* <li className="breadcrumb-item">Меню</li> */}
					<li className="breadcrumb-item"><NavLink to={ADMIN_URL + "tableGroups"}>{this.props.translations.table_groups.title}</NavLink></li>
					<li className="breadcrumb-item active">{this.props.translations.table_groups.edit}</li>
					{/* <!-- Breadcrumb Menu--> */}
					<li className="breadcrumb-menu d-md-down-none">
						<div className="btn-group" role="group" aria-label="Button group">

						</div>
					</li>
				</ol>

				<div className="container-fluid">
					<div className="animated fadeIn">
						<div className="row">

							{/* <!-- /.col--> */}
							<div className="col-sm-12">
								<div className="card">

									<AddEditTableGroupForm
										onSubmit={this.onSubmit}
										onChange={this.onChange}
										onChangeCheckbox={this.onChangeCheckbox}
										onChangeSelectAutocomplete={this.onChangeSelectAutocomplete}
										tables={this.props.tables}
										tableGroup={this.state}
										deleteTableGroup={this.deleteTableGroup}
										actionText="Запази"
										isAdding={false}
										translations={this.props.translations}
									/>

								</div>
							</div>

						</div>
					</div>
				</div>

			</main>
		)
	}
}

const mapStateToProps = (state, ownProps) => {
	let tableGroupId = ownProps.match.params.id;

	let tables = [];

	let tableGroup = state.tableGroups.tableGroups[tableGroupId];
	if (!tableGroup) tableGroup = {
		id: '',
		min_people_count: '',
		max_people_count: '',
		active: '',
		tables: [],
	};

	let reservationTables = [];
	if(state.tables && state.tables.tables) {
		reservationTables = Object.values(state.tables.tables).filter(t => t.is_for_reservation == 1);
	}

	return {
		tableGroup: tableGroup,
		tables: reservationTables,
		translations: state.lang,
	}


};

export default connect(mapStateToProps, { listTableGroups, addEditTableGroup, addErrorMessages })(EditTableGroupPage);