import React, { Component } from 'react'
import { connect } from 'react-redux';
import { listIngredients } from './../../ingredients/actions/ingredients.actions';
import AddEditProductIngredientsForm from './AddEditProductIngredientsForm';
import { addRemoveProductIngredient, editProduct } from './../../../actions/productsActions';

class IngredientsTab extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            addableValues: [],
            removableValues: [],
            requiredValues: [],
            minCountAddableIngredients: "",
            maxCountAddableIngredients: "",
        }
    }



    componentWillReceiveProps(nextProps) {

        if (nextProps.product) {
            const addableValues = this.getProductIngredientsByTypeForSelect('addable', nextProps);
            const removableValues = this.getProductIngredientsByTypeForSelect('removable', nextProps);
            const requiredValues = this.getProductIngredientsByTypeForSelect('required', nextProps);

            this.setState({
                addableValues: addableValues,
                removableValues: removableValues,
                requiredValues: requiredValues,
                minCountAddableIngredients: nextProps.product.min_count_addable_ingredients,
                maxCountAddableIngredients: nextProps.product.max_count_addable_ingredients,
            })
        }
    }

    ingredientsOnChange = (values, { action, removedValue }, ingType) => {
        let data;
        let stateValueToChange = ingType + "Values";   //ex. addable + Values = addableValues in state

        switch (action) {
            case 'select-option':
                this.setState({
                    [stateValueToChange]: values
                });
                let ingredientIds = [values[values.length - 1].value]; // take only the newly added ingredient (i.e. the last one )
                data = {
                    type: ingType,
                    productId: this.props.product.id,
                    ingredientIds: ingredientIds
                }
                this.props.addRemoveProductIngredient(true, data);
                break;
            case 'remove-value':
                this.setState({
                    [stateValueToChange]: values
                });
                data = {
                    type: ingType,
                    productId: this.props.product.id,
                    ingredientIds: [removedValue.value]
                }
                this.props.addRemoveProductIngredient(false, data);
                break;
                // case 'pop-value':
                //   if (removedValue.isFixed) {
                //     return;
                //   }
                break;
            case 'clear':
                data = {
                    type: ingType,
                    productId: this.props.product.id,
                    ingredientIds: []
                }
                this.props.addRemoveProductIngredient(false, data);

                break;
        }

    }

    getSelectValues = () => {
        return Object.values(this.props.ingredients).map(i => {
            return { value: i.id, label: i.name + " - " + i.price }
        })
    }

    getProductIngredientsByTypeForSelect = (type = 'addable', nextProps = null) => {

        let ings = this.props.product.ingredients.filter(i => i.type == type)
        var allIngredients = this.props.ingredients;

        // used for initial seting the values in componentWillReceiveProps
        if (nextProps) {
            ings = nextProps.product.ingredients.filter(i => i.type == type)
            allIngredients = nextProps.ingredients;
        }

        let ingredientsArrByType = [];
        ings.map(ing => {
            let ingName = allIngredients && allIngredients[ing.ingredientId] && allIngredients[ing.ingredientId].name || ""
            let ingPrice = allIngredients && allIngredients[ing.ingredientId] && allIngredients[ing.ingredientId].price || ""

            if (ingName) {
                ingredientsArrByType.push({
                    value: ing.ingredientId, label: ingName + " - " + ingPrice
                })
            }
        })

        return ingredientsArrByType
    }

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    saveProduct = (column, stateValue) => {
        let post = {
            id: this.props.product.id,
            [column]: this.state[stateValue]
        }

        this.props.editProduct(post);

    }

    render() {
        this.getProductIngredientsByTypeForSelect();
        // const addableDefaultValues = this.getProductIngredientsByTypeForSelect('addable');
        // const addableDefaultValues = this.getProductIngredientsByTypeForSelect('removable');
        // const addableDefaultValues = this.getProductIngredientsByTypeForSelect('required');
        const selectValues = this.getSelectValues();
        return (

            <div className="col-sm-12 col-md-12" style={{ width: 1200 }}>
                <div className="card">

                    <AddEditProductIngredientsForm
                        product={this.props.product}
                        ingredients={this.props.ingredients}
                        selectValues={selectValues}
                        stateData={this.state}
                        ingredientsOnChange={this.ingredientsOnChange}
                        addProductIngredient={this.addProductIngredient}
                        saveProduct={this.saveProduct}
                        ingredientsTabOnChange={this.onChange}
                        translations={this.props.translations}
                    />

                </div>
            </div>
        )
    }
}




const mapStateToProps = (state, ownProps) => {

    return {
        ingredients: state.ingredients.ingredients || []

    }

};

export default connect(mapStateToProps, { listIngredients, addRemoveProductIngredient, editProduct })(IngredientsTab);