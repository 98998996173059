import React, { Component } from 'react'
import Utils from '../../utils/Utils';
import moment from 'moment';
import PAYMENT_STATUSES from './../common/enums/paymentStatuses';

export default class ComponentToPrint extends React.PureComponent {

    render() {
        let orderDateFormat = this.props.order.ordertypeId == 2 ? new Date(this.props.order.delivery_time) : new Date(this.props.order.pickup_time);
        orderDateFormat = Utils.appendLeadingZeroes(orderDateFormat.getDate()) + '.' + Utils.appendLeadingZeroes(parseInt(parseInt(orderDateFormat.getMonth()) + 1)) + ' ' + Utils.appendLeadingZeroes(orderDateFormat.getHours()) + ':' + Utils.appendLeadingZeroes(orderDateFormat.getMinutes());

        let totalSum = 0;
        if (this.props.order && this.props.order.total_price) {
            totalSum = this.props.order.total_price;
        }

        let delivery_price = 0;
        if (this.props.order && this.props.order.total_price) {
            delivery_price = this.props.order.delivery_price;
        }

        let currentRegion = null;
        if (this.props.order.regionId && this.props.regions) {
            currentRegion = this.props.regions.find(r => r.id == this.props.order.regionId)
            if (currentRegion && currentRegion.name) {
                currentRegion = currentRegion.name
            }
        }

        let orderDate = moment(this.props.order.createdAt).format('DD.MM.YYYY HH:mm');
        let updateDate = moment(this.props.order.updatedAt).format('DD.MM.YYYY HH:mm');

        let productRequiredIngredients = [];
        let productAddablengredients = [];
        let productRemovableIngredients = [];

        let products = Object.values(this.props.order.order_productvariants).map((opv, ind) => {
            productRequiredIngredients = [];
            productAddablengredients = [];
            productRemovableIngredients = [];

            const prodComment = this.props.order.order_comment ? JSON.parse(this.props.order.order_comment).find(p => p.variantId === opv.productVariantId) : undefined;

            return <p key={ind}><b>{opv.quantity}</b> x <b>{opv.product_variant.product && opv.product_variant.product.name || ''}</b>

                {prodComment && prodComment.comment ? <span> - <span style={{ fontStyle: "italic", fontWeight: 'bold', textDecoration: 'underline' }}>{prodComment.comment}</span></span> : null}
                {opv.opv_ingredients.map((ing, k) => {
                    if (ing.ingredient_type == "required") {
                        if (ing.ingredient) {
                            productRequiredIngredients.push(ing.ingredient.name);
                        }
                    }
                    if (ing.ingredient_type == "addable") {
                        if (ing.ingredient) {
                            productAddablengredients.push(ing.ingredient.name);
                        }
                    }
                    if (ing.ingredient_type == "removable") {
                        if (ing.ingredient) {
                            productRemovableIngredients.push(ing.ingredient.name);
                        }
                    }
                })}

                {
                    productRequiredIngredients.length > 0 ?
                        <div style={{ fontStyle: 'italic' }}>
                            {productRequiredIngredients.join(", ")}
                        </div>
                        :
                        null
                }
                {
                    productAddablengredients.length > 0 ?
                        <div style={{ color: '#008017', fontStyle: 'italic' }}>+
                            {productAddablengredients.join(", ")}
                        </div>
                        :
                        null
                }
                {
                    productRemovableIngredients.length > 0 ?
                        <div style={{ color: '#c50000', fontStyle: 'italic' }}>-
                            {productRemovableIngredients.join(", ")}
                        </div>
                        :
                        null
                }
            </p>
        })

        return (
            <div class={"print-container pr" + (localStorage.getItem('tabl_printer_padding_right') ? localStorage.getItem('tabl_printer_padding_right') : '0')}>

                <div>

                    <br /><br />
                    <br /><br />

                    <div class="talign-center">
                        <h1 className="tera"><b>Поръчка № {this.props.order.place_order_number ? this.props.order.place_order_number : this.props.order.id}</b></h1>
                        <br />
                        <br />
                        <h1 className="giga">
                            {this.props.order.exact_time_selected ?
                                <>
                                    {(this.props.order.ordertypeId == 2 ? 'Час на доставка' : 'Час на взимане от място')} {orderDateFormat}
                                    <br />
                                    <b><i className={"badge badge-dark"}>{this.props.order.exact_time_selected && !Utils.checkDatesInSameDayAndHour(this.props.order.delivery_time || this.props.order.pickup_time, this.props.order.createdAt) ? 'БЪДЕЩА ПОРЪЧКА' : ''}</i></b>
                                </> :
                                null
                            }
                        </h1>

                        {this.props.order.room_number && this.props.order.additional_order_type ?
                            <h2><b>Стая: {this.props.order.room_number} {this.props.order.additional_order_type == 'delivery' ? '- С ДОСТАВКА' : '- ВЗИМАНЕ ОТ МЯСТО'}</b></h2>
                            :
                            null
                        }

                    </div>

                    <br /><br />
                    <div>---</div>
                    <br /><br />
                    {/*<div className="dash"></div>
                    <br /> */}

                    <div class="talign-center mtop_5">
                        <h1 className="tera">{products}</h1>

                        <br /><br />
                        <br /><br />
                        <div>---</div>
                        <br /><br />
                        <h1 className="giga mtop_5">Час на поръчка:</h1>
                        <h1 className="giga"><b>{orderDate}</b></h1>
                    </div>
                    <br /><br />
                    <br /><br />
                    <div className="dash"></div>
                    <br /><br />
                    <br /><br />
                </div>


                {this.props.order.order_text_comment && this.props.order.order_text_comment.length > 0 ?
                    <>
                        <div class="talign-center">
                            <h1>Коментар към поръчката:</h1>
                            <h2 style={{
                                display: 'block',
                                background: '#ccc',
                                padding: 6,
                                wordBreak: 'break-word'
                            }}>
                                {this.props.order.order_text_comment}
                            </h2>
                            <br />
                            <br />
                            <div className="dash"></div>
                            <br />
                        </div>
                    </>
                    :
                    null
                }

                {/* 
                <br />
                <br />
                <hr />
                <br />
                <br />

                <div class="footer-container">
                    <img src="https://tabl.bg/img/tabl-transparent.png" class="tabl-logo" />

                    <div>
                        <h2>TABL.BG - Дигитално меню и система за поръчки</h2>
                    </div>

                </div> */}
            </div>

        );
    }
}
