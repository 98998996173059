import React from 'react'
import { NavLink } from 'react-router-dom';
import { ADMIN_URL } from '../../../constants.js'
import DeleteModal from './../../common/DeleteModal';
import Utils from '../../../utils/Utils.js';

const ImageRow = (props) => (
    <tr key={props.placeImage.id}>
        <td>
            <img src={Utils.getPlaceImagePath(props.placeImage.path)} width={100}/>
        </td>
        <td>
            {props.placeImage.approved ? <span className="badge badge-success">{props.translations.common.approved}</span> : <span className="badge badge-secondary">{props.translations.common.not_approved}</span>}
        </td>
        <td>
            {props.placeImage.user ? props.placeImage.user.email : "-"}
        </td>
        <td>
            {props.placeImage.text}
        </td>
        <td>
            <NavLink to={ADMIN_URL+'place-images/edit/' + props.placeImage.id}>
                <button className="btn btn-pill btn-link" type="button">
                    <i className="fas fa-edit"></i>
                </button>
            </NavLink>
            
            <button className="btn btn-pill btn-link" type="button" data-toggle="modal" data-target={"#dangerModal" + props.placeImage.id} >
                <i className="fas fa-trash"></i>
            </button>

            <DeleteModal
                id={props.placeImage.id}
                // title={props.placeImage.name}
                deleteFunc={props.deletePlaceImage}
                translations={props.translations}
            />

        </td>
    </tr>
)

export default ImageRow;