import React, { Component } from 'react'
import { listWarehouseSystems, syncCategories, syncProducts, syncOrderStatuses, syncOrderDeliveryChannels, syncOrderPaymentTypes } from '../actions/warehouseSystems.actions';
import { addEditPlace, listPlacesByUserRole } from './../../places/actions/places.actions';
import { addSuccessMessage, addErrorMessages } from './../../messages/messages.actions';
import { showLoading, hideLoading } from './../../common/loading/loading.actions';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { ADMIN_URL } from '../../../constants';
import Utils from '../../../utils/Utils';
import Auth from '../../../utils/Auth';
import MessagesContainer from '../../messages/MessagesContainer';
import Select from 'react-select'

class PlaceWarehouseSystemPage extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			// id: this.props.match.params.id,
			warehouseSystemId: "",
			whsCredentials: "{}"
		}
	}

	componentDidMount() {
        this.props.listWarehouseSystems();
    }

	componentWillReceiveProps(nextProps) {

		if(nextProps.currPlace.id) {
			this.setState({
				warehouseSystemId: nextProps.currPlace.warehouseSystemId,
				whsCredentials: nextProps.currPlace.warehouse_system_credentials ? JSON.parse(nextProps.currPlace.warehouse_system_credentials) : {}
			});
		}

	}
	onChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
	}

	onChangeCheckbox = (e) => {
		this.setState({ [e.target.name]: e.target.checked });
	}

	onChangeSelectAutocomplete = (field, valueLabel) => {
		this.setState({ [field]: valueLabel.value });
	}
	
	onJsonChange = (e) => {
		const { name, value } = e.target;

		this.setState((prevState) => ({
			whsCredentials: {
				...prevState.whsCredentials,
				[name]: value
			}
		}));
	};

	validate() {
		let errors = [];

		if (this.state.name.length === 0) {
			errors.push(this.props.translations.messages.errors.invalid_name);
		}
		return errors;
	}

	onSubmit = (e) => {
		e.preventDefault();

        // const errors = this.validate();
		// if (errors.length > 0) {
		// 	this.props.addErrorMessages(errors);
		// 	return;
		// }

		const post = {
			id: this.props.currPlace.id,
			warehouseSystemId: this.state.warehouseSystemId,
			warehouse_system_credentials: JSON.stringify(this.state.whsCredentials)
		}

		this.props.addEditPlace(post, this.props.history)
			.then(resp => {
				this.props.listPlacesByUserRole();
				this.props.addSuccessMessage("Successfully edited settings");
			})

	}

	syncCategories = (e) => {
		e.preventDefault()

		this.props.showLoading();
		let that = this;

		this.props.syncCategories()
			.then((resp) => {
				that.props.hideLoading();
				let data = resp.data;

				if(data.success) {
					that.props.addSuccessMessage(data.msg ? data.msg : "Successfully synced categories");
				} else {
					that.props.addErrorMessages([data.msg ? data.msg : "Error syncing categories"]);
				}
			})
	}

	syncProducts = (e) => {
		e.preventDefault()
		this.props.syncProducts()
			.then((resp) => {
				this.props.hideLoading();
				let data = resp.data;

				if(data.success) {
					this.props.addSuccessMessage(data.msg ? data.msg : "Successfully synced products");
				} else {
					this.props.addErrorMessages([data.msg ? data.msg : "Error syncing products"]);
				}
			})
	}

	callSyncOrderStatuses = (e) => {
		e.preventDefault()
		this.props.showLoading();

		this.props.syncOrderStatuses()
			.then((resp) => {
				this.props.hideLoading();
				let data = resp.data;

				if(data.success) {
					this.props.addSuccessMessage(data.msg ? data.msg : "Successfully synced orer stdatuses");
				} else {
					this.props.addErrorMessages([data.msg ? data.msg : "Error syncing order statuses"]);
				}
			})
	}

	callSyncOrderDeliveryChannels = (e) => {
		e.preventDefault()
		this.props.showLoading();

		this.props.syncOrderDeliveryChannels()
			.then((resp) => {
				this.props.hideLoading();
				let data = resp.data;

				if(data.success) {
					this.props.addSuccessMessage(data.msg ? data.msg : "Successfully synced Delivery Channels");
				} else {
					this.props.addErrorMessages([data.msg ? data.msg : "Error syncing delivery channels"]);
				}
			})
	}

	callSyncOrderPaymentTypes = (e) => {
		e.preventDefault()
		this.props.showLoading();

		this.props.syncOrderPaymentTypes()
			.then((resp) => {
				this.props.hideLoading();
				let data = resp.data;

				if(data.success) {
					this.props.addSuccessMessage(data.msg ? data.msg : "Successfully synced payment types");
				} else {
					this.props.addErrorMessages([data.msg ? data.msg : "Error syncing payment types"]);
				}
			})
	}

	render() {
		
		let selectedWHS;
		let selectedWHSCredentialFields = {};
        let autocompleteItems = [];
        autocompleteItems.push({ 'value': null, 'label': "Choose warehouse system" })

        this.props.warehouseSystems.map(whs => {
            if (this.state.warehouseSystemId == whs.id) {
                selectedWHS = { 'value': whs.id, 'label': whs.name };
				selectedWHSCredentialFields = JSON.parse(whs.credential_fields)
            }

            autocompleteItems.push({ 'value': whs.id, 'label': whs.name });
        });

		return (
			<main className="main">

				{/* <!-- Breadcrumb--> */}
				<ol className="breadcrumb">
					<li className="breadcrumb-item"><NavLink to={ADMIN_URL}>{this.props.translations.common.dashboard}</NavLink></li>
					<li className="breadcrumb-item"><NavLink to={ADMIN_URL + 'settings'}>{this.props.translations.sidemenu.settings}</NavLink></li>
					<li className="breadcrumb-item">{this.props.translations.warehouse_systems.warehouse_system}</li>
				</ol>

				<div className="container-fluid">
					<div className="animated fadeIn">
						<div className="row">
							<div className="col-sm-12">
								<div className="card">
									<form>
										<div className="card-body">
											<MessagesContainer />
											<div className="row">

												<div className="form-group col-sm-12">
													<label htmlFor="warehouseSystemId">{this.props.translations.warehouse_systems.warehouse_system}</label>
													<Select name="warehouseSystemId" options={autocompleteItems} 
														onChange={(valueLabel) => this.onChangeSelectAutocomplete('warehouseSystemId', valueLabel)} 
														value={selectedWHS}
														placeholder={this.props.translations.warehouse_systems.warehouse_system}
													/>
												</div>

												{Object.keys(selectedWHSCredentialFields).map((key) => (
													<div key={key} className='col-sm-6 form-group'>
														<label htmlFor={key}>{key}:</label>
														<input
															type="text"
															id={key}
															className='form-control'
															name={key}
															value={this.state.whsCredentials[key]}
															onChange={this.onJsonChange}
														/>
														</div>
													))
												}

												<div className="col-sm-12">
													<div className="form-group">
														<button className="btn btn-primary" type="button" onClick={this.syncCategories}>
															{this.props.translations.warehouse_systems.sync_categories}
														</button>
													</div>
												</div>
												<div className="col-sm-12">
													<div className="form-group">
														<button className="btn btn-primary" type="button" onClick={this.syncProducts}>
															{this.props.translations.warehouse_systems.sync_products}
														</button>
													</div>
												</div>
												<div className="col-sm-12">
													<div className="form-group">
														<button className="btn btn-primary" type="button" onClick={this.callSyncOrderStatuses}>
															{this.props.translations.warehouse_systems.sync_order_statuses}
														</button>
													</div>
												</div>
												<div className="col-sm-12">
													<div className="form-group">
														<button className="btn btn-primary" type="button" onClick={this.callSyncOrderDeliveryChannels}>
															{this.props.translations.warehouse_systems.sync_order_delivery_channels}
														</button>
													</div>
												</div>
												<div className="col-sm-12">
													<div className="form-group">
														<button className="btn btn-primary" type="button" onClick={this.callSyncOrderPaymentTypes}>
															{this.props.translations.warehouse_systems.sync_order_payment_types}
														</button>
													</div>
												</div>
											</div>

											<hr />
											<br />

											<div className="form-actions">

												<button className="fright btn btn-primary" type="submit" onClick={this.onSubmit}>
													{this.props.translations.common.save}
												</button>

											</div>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</main>
		)
	}
}

const mapStateToProps = (state, ownProps) => {
	let currPlaceId = Auth.getUserPlace();

	let currPlace = state.places.places.find(s => s.id == currPlaceId);

	if (!currPlace) currPlace = {
		id: '',
		name: '',
		active: 1,
		warehouseSystemId: '',
		warehouse_system_credentials: "{}"
	};
	return {
		warehouseSystems: state.warehouseSystems.warehouseSystems,
		currPlace: currPlace,
		translations: state.lang,
		languages: state.languages.languages || {},
		currLang: state.settings.settings['default_lang'] && state.settings.settings['default_lang'].value || 'bg',
	}

};

export default connect(mapStateToProps, { listWarehouseSystems, syncCategories, syncProducts, syncOrderStatuses, syncOrderDeliveryChannels, syncOrderPaymentTypes,
	addEditPlace, listPlacesByUserRole, addSuccessMessage, addErrorMessages, showLoading, hideLoading })(PlaceWarehouseSystemPage);