import React, { Component } from 'react'
import { connect } from 'react-redux';
import { listIngredients } from './../../ingredients/actions/ingredients.actions';
import AddEditProductIngredientsForm from './AddEditProductIngredientsForm';
import { addRemoveRelatedProduct } from './../../../actions/productsActions';
import MessagesContainer from './../../messages/MessagesContainer';
import Select from 'react-select';

class RelatedProductsTab extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            relatedProducts: [],
        }
    }

    componentWillReceiveProps(nextProps) {

        if(nextProps.product && nextProps.product.related_products && this.props.products) {
            let allProducts = nextProps.products;
            let relatedProducts = [];

            nextProps.product.related_products.map(id => {
                let prodName = allProducts && allProducts[id] && allProducts[id].name || ""
                relatedProducts.push({
                    value: id, label: prodName
                })
            })

            this.setState({
                relatedProducts: relatedProducts
            })
        }
    }

    prodsSelectOnChange = (values, { action, removedValue }) => {
        // console.log(values, action, removedValue)
        let data;
        let stateValueToChange = "promoProductsForCategory";   //ex. addable + Values = addableValues in state
        // return;
        switch (action) {
            case 'select-option':
                this.setState({
                    [stateValueToChange]: values
                });
                let productId = values[values.length-1].value; // take only the newly added ingredient (i.e. the last one )
                data = {
                    product_id: this.props.product.id,
                    related_product_id: productId,
                }
                this.props.addRemoveRelatedProduct(data);
                break;
            case 'remove-value':
                this.setState({
                    [stateValueToChange]: values
                });
                data = {
                    product_id: this.props.product.id,
                    related_product_id: removedValue.value,
                }
                this.props.addRemoveRelatedProduct(data);
                break;
            // case 'pop-value':
            //   if (removedValue.isFixed) {
            //     return;
            //   }
              break;
            case 'clear':
                data = {
                    category_id: this.props.cat.id,
                    product_id: 'all',
                }
                this.props.addRemovePromoCategoryProduct('remove'!='remove', data);
                
                break;
          }
    }

    getSelectValues = () => {
        let options = [];
        Object.values(this.props.products).map(i => {
            if(i.id !== this.props.product.id) {
                options.push({ value: i.id, label: i.name });
            }
        })

        return options;
    }

    render() {
        const selectValues = this.getSelectValues();
        return (
        
            <div className="" >
                <MessagesContainer />

                <div className="form-group">
                    <h3>{this.props.translations.products.related_products}</h3>
                    <label>{this.props.translations.products.related_products_info}</label>
                    <Select
                        value={this.state.relatedProducts}
                        isMulti
                        name="colors"
                        options={selectValues}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(values, { action, removedValue }) => this.prodsSelectOnChange(values, { action, removedValue })}
                    />
                </div>
            </div>
        )    
    }
}




const mapStateToProps = (state, ownProps) => {
    
    return {
        products: state.products.products || []
        
    }

};

export default connect(mapStateToProps, { addRemoveRelatedProduct })(RelatedProductsTab);