import React, { Component } from 'react'
import AddEditSubcategoryForm from './AddEditSubcategoryForm';
import { editSubcategory, removeSubcategory } from '../../actions/subcategoriesAction';
import { connect } from 'react-redux';
import { listCategories } from '../../actions/categoriesActions';
import { NavLink } from 'react-router-dom';
import { ADMIN_URL } from '../../constants';
import { addErrorMessages } from './../messages/messages.actions';

class EditSubcategoryPage extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			currLang: this.props.currLang,
			id: this.props.match.params.id,
			categoryId: this.props.subcat.categoryId,
			icon: this.props.subcat.icon,
			slug: this.props.subcat.slug,
			// name: this.props.subcat.name? this.props.subcat.name : '',
			name: this.props.subcat.translations[this.props.currLang] && this.props.subcat.translations[this.props.currLang].name 
				? this.props.subcat.translations[this.props.currLang].name : this.props.subcat.name,
			code: this.props.subcat.code ? this.props.subcat.code : '',
			active: this.props.subcat.active ? this.props.subcat.active : 0,
		}

		// if (this.props.prod.icon) {
		//     this.setState({
		//         icon: this.props.prod.icon,
		//     });
		// }

		this.onChange = this.onChange.bind(this);
		this.onChangeCheckbox = this.onChangeCheckbox.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.subcat.id) {
			this.setState({
				id: nextProps.subcat.id,
				categoryId: nextProps.subcat.categoryId,
				icon: this.props.subcat.icon,
				slug: this.props.subcat.slug,
				// name: nextProps.subcat.name? nextProps.subcat.name : '',
				name: nextProps.subcat.translations[this.props.currLang] && nextProps.subcat.translations[this.props.currLang].name 
				? nextProps.subcat.translations[this.props.currLang].name : nextProps.subcat.name,
				code: nextProps.subcat.code ? nextProps.subcat.code : '',
				active: nextProps.subcat.active ? nextProps.subcat.active : 0,
			});
		}
	}
	componentWillMount() {
		this.props.listCategories();
	}

	onChange(e) {
		this.setState({ [e.target.name]: e.target.value });
	}

	onChangeCheckbox(e) {
		this.setState({ [e.target.name]: e.target.checked });
	}

	validate() {
		let errors = [];

		if (this.state.name.length === 0) {
			errors.push(this.props.translations.messages.errors.invalid_name);
		}
		if (this.state.categoryId === 0) {
			errors.push(this.props.translations.messages.errors.invalid_category);
		}

		return errors;
	}

	onSubmit(e) {
		e.preventDefault();

		const errors = this.validate();
		if (errors.length > 0) {
			this.props.addErrorMessages(errors);
			return;
		}
		const post = {
			id: this.state.id,
			categoryId: this.state.categoryId,
			name: this.state.name,
			code: this.state.code,
			slug: this.state.slug,
			category_code: this.props.categories[this.state.categoryId] ? this.props.categories[this.state.categoryId].code : '',
			active: this.state.active,
		}

		// Image append
		const files = Array.from(document.querySelector('#multi').files)
		let formData = new FormData()

		files.forEach((file, i) => {
			formData.append("file", file)
		})

		this.props.editSubcategory(post, this.props.history, formData, this.props.languages[this.state.currLang].id);
	}

	currLangOnChange = (e) => {
		let newLang = e.target.value;
		let newNameValue = this.props.subcat.translations[newLang] && this.props.subcat.translations[newLang].name 
			? this.props.subcat.translations[newLang].name : "";

		this.setState({
			name: newNameValue,
			currLang: e.target.value
		})
	}

	deleteSubcategory = (e, id) => {
		e.preventDefault();
		this.props.removeSubcategory(id, this.props.history);
	}


	render() {
		return (
			<main className="main">

				{/* <!-- Breadcrumb--> */}
				<ol className="breadcrumb">
					<li className="breadcrumb-item"><NavLink to={ADMIN_URL}>{this.props.translations.common.dashboard}</NavLink></li>
					{/* <li className="breadcrumb-item">Меню</li> */}
					<li className="breadcrumb-item"><NavLink to={ADMIN_URL + "subcategories"}>{this.props.translations.subcategories.title}</NavLink></li>
					<li className="breadcrumb-item active">{this.props.translations.subcategories.edit}</li>
					{/* <!-- Breadcrumb Menu--> */}
				</ol>

				<div className="container-fluid">
					<div className="animated fadeIn">
						<div className="row">

							{/* <!-- /.col--> */}
							<div className="col-sm-12">
								<div className="card">

									<AddEditSubcategoryForm
										onSubmit={this.onSubmit}
										onChange={this.onChange}
										onChangeCheckbox={this.onChangeCheckbox}
										subcategoryId={this.state.id}
										categoryId={this.state.categoryId}
										subcategoryName={this.state.name}
										subcategoryCode={this.state.code}
										subcategorySlug={this.state.slug}
										subcategoryActive={this.state.active}
										deleteSubcategory={this.deleteSubcategory}
										isAddingSubcategory="0"
										icon={this.state.icon}
										allCategories={this.props.categories}
										nameError={this.props.errors.find(error => error.path == 'name') ? this.props.errors.find(error => error.path == 'name') : ""}
										translations={this.props.translations}
										currLang={this.state.currLang}
										currLangOnChange={this.currLangOnChange}
										languages={this.props.languages}
									/>

								</div>
							</div>

						</div>
					</div>
				</div>

			</main>
		)
	}
}

const mapStateToProps = (state, ownProps) => {
	let subcatId = ownProps.match.params.id;
	let subcat = state.subcategories.subcategories[subcatId];

	if (!subcat) subcat = {
		id: '',
		categoryId: '',
		name: '',
		active: '',
		translations: {}
	}

	return {
		subcat: subcat,
		categories: state.categories.categories,
		errors: state.subcategories.errors,
		translations: state.lang,
		languages: state.languages.languages || {},
		currLang: "bg"
	}

};

export default connect(mapStateToProps, { editSubcategory, removeSubcategory, listCategories, addErrorMessages })(EditSubcategoryPage);