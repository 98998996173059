import React, { Component } from 'react'
import { connect } from 'react-redux';
import { listSubcategories, removeSubcategory } from '../../actions/subcategoriesAction';
import ListEachSubcategory from './ListEachSubcategory';
import { NavLink } from 'react-router-dom';
import { ADMIN_URL, ITEMS_PER_PAGE, ITEMS_PAGE_RANGE_DISPLAYED } from '../../constants.js'
import Pagination from "react-js-pagination";
import { push } from 'react-router-redux';
import { bindActionCreators } from 'redux'
import Filter from './Filter';

// import 'bootstrap/dist/css/bootstrap.css';

class ListSubcategoriesPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            search_name: '',
            search_active: '',
        }
    }

    componentWillMount() {
        this.props.listSubcategories();
    }

    deleteSubcategory = (e, id) => {
        e.preventDefault();
        this.props.removeSubcategory(id);
    }

    handlePageChange = (page) => {
        this.props.dispatch(push('/admin/subcategories?page=' + page))
    }

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
        this.handlePageChange(1);
    }

    render() {
        let subcatItems = this.props.subcats;
        let catItems = this.props.cats;

        if (this.state.search_name.length > 0
            || this.state.search_active != 0
        ) {
            subcatItems = Object.values(subcatItems).filter(i => {
                return (
                    ((i.name).toLocaleLowerCase().includes((this.state.search_name).toLocaleLowerCase()))
                    && (this.state.search_active != 0 ? i.active == Boolean(parseInt(this.state.search_active) - 1) : true)
                )
            })
        }

        // const per_page = 20;
        const items_count = Object.keys(subcatItems).length
        // const pages = Math.ceil(this.props.subcats.length / per_page);
        const current_page = this.props.page;
        const start_offset = (current_page - 1) * ITEMS_PER_PAGE;
        let start_count = 0;

        return (
            <main className="main">

                {/* <!-- Breadcrumb--> */}
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><NavLink to={ADMIN_URL}>{this.props.translations.common.dashboard}</NavLink></li>
                    {/* <li className="breadcrumb-item">Меню</li> */}
                    <li className="breadcrumb-item active">{this.props.translations.subcategories.title}</li>
                    {/* <!-- Breadcrumb Menu--> */}
                    <li className="breadcrumb-menu d-md-down-none">
                        <div className="btn-group" role="group" aria-label="Button group">
                            <NavLink to={ADMIN_URL + `subcategories/add`} className="btn" >
                                <i className="fas fa-plus"></i> &nbsp; {this.props.translations.subcategories.add}
                            </NavLink>
                        </div>
                    </li>
                </ol>

                <div className="container-fluid">
                    <div className="animated fadeIn">
                        <div className="row">

                            <Filter
                                translations={this.props.translations}
                                onChange={this.onChange}
                            />

                            {/* <!-- /.col--> */}
                            <div className="col-lg-12">
                                <div className="card">

                                    <div className="card-body">
                                        <table className="table table-responsive-sm table-striped">
                                            <thead>
                                                <tr>
                                                    <th>{this.props.translations.common.name}</th>
                                                    <th>{this.props.translations.common.category}</th>
                                                    <th>{this.props.translations.common.icon}</th>
                                                    <th>{this.props.translations.common.status}</th>
                                                    <th>{this.props.translations.common.action}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {/* 
                                                {Object.keys(catItems).map((key, index) => {
                                                    if(index >= start_offset && start_count < per_page) {
                                                        start_count++;
                                                        return <ListEachCategory 
                                                        key={key} 
                                                        category={catItems[key]} 
                                                        deleteCategory={this.deleteCategory}
                                                        activeDefaultLanguage={this.activeDefaultLanguage}
                                                    />
                                                    } */}

                                                {/* })} */}

                                                {Object.values(subcatItems).reverse().map((subcat, index) => {
                                                    // console.log("INDEX ", index, subcat)
                                                    if (index >= start_offset && start_count < ITEMS_PER_PAGE) {
                                                        start_count++;

                                                        return <ListEachSubcategory
                                                            categories={catItems}
                                                            subcategory={subcat}
                                                            key={subcat.id}
                                                            deleteSubcategory={this.deleteSubcategory}
                                                            translations={this.props.translations}
                                                            currLang={this.props.currLang}
                                                        />
                                                    }
                                                })}

                                            </tbody>
                                        </table>

                                        <Pagination
                                            className="pagination"
                                            itemClass="page-item"
                                            activePage={current_page}
                                            activeClass="active"
                                            linkClass="page-link"
                                            prevPageText="<"
                                            nextPageText=">"
                                            firstPageText="<<"
                                            lastPageText=">>"
                                            itemsCountPerPage={ITEMS_PER_PAGE}
                                            totalItemsCount={items_count}
                                            pageRangeDisplayed={ITEMS_PAGE_RANGE_DISPLAYED}
                                            onChange={this.handlePageChange}
                                        />

                                    </div>
                                </div>
                            </div>
                            {/* <!-- /.col--> */}
                        </div>
                    </div>
                </div>
            </main>
        )
    }
}


const mapStateToProps = state => ({
    subcats: state.subcategories.subcategories,
    cats: state.categories.categories,
    page: Number(state.router.location.query.page) || 1,
    translations: state.lang,
    currLang: "bg",
});


const mapDispatchToProps = (dispatch, props) => {
    return {
        dispatch,
        ...bindActionCreators({ listSubcategories, removeSubcategory }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ListSubcategoriesPage);
