import React, { Component } from 'react'
import { connect } from 'react-redux';
import { listCategoriesHierarchy, editCategoryHierarchy, updateDiscountPercentForCatAndProducts, getSampleImages } from '../../actions/categoriesHierarchyActions';
import ListEachCategoryHierarchy from './ListEachCategoryHierarchy';
import { NavLink } from 'react-router-dom';
import { ADMIN_URL, ITEMS_PER_PAGE, ITEMS_PAGE_RANGE_DISPLAYED } from '../../constants.js'
import Pagination from "react-js-pagination";
import { push } from 'react-router-redux';
import { bindActionCreators } from 'redux'
import Filter from './Filter';
import MessagesContainer from './../messages/MessagesContainer';
import { translations } from '../common/translations';
import Auth from './../../utils/Auth';
import Utils from './../../utils/Utils';


class ListCategoriesHierarchyPage extends React.Component {
    constructor(props) {
        super(props);
        this.activeDefaultLanguage = 'bg'; // TODO add it in db
        this.state = {
            search_name: '',
            search_level: '',
            search_active: '',
            search_parent_category: '',
            catsPriority: {},
            catsDiscounts: {},
        }
    }

    componentWillReceiveProps(nextProps) {

        if (nextProps.cats) {

            let catsPriority = {};
            Object.values(nextProps.cats).map(cat => {
                catsPriority[cat.id] = cat.priority;
            })

            let catsDiscounts = {};
            Object.values(nextProps.cats).map(cat => {
                catsDiscounts[cat.id] = cat.discount_percent;
            })

            this.setState({ catsPriority: catsPriority, catsDiscounts: catsDiscounts })
        }
    }

    componentWillMount() {
        // console.log("AAAA ", Auth.getUser());
        this.props.listCategoriesHierarchy();
    }

    deleteCategory = (e, id) => {
        e.preventDefault();

        const post = {
            id: id,
            deleted: 1
        }
        this.props.editCategoryHierarchy(post, this.props.history);
    }

    handlePageChange = (page) => {
        this.props.dispatch(push('/admin/categories-hierarchy?page=' + page))
    }

    pushWithQueryParam(paramName, paramValue) {

    }

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
        this.handlePageChange(1);
    }

    updatePriority = (e, id) => {
        e.preventDefault();

        const post = {
            id: id,
            priority: this.state.catsPriority[id]
        }
        this.props.editCategoryHierarchy(post, this.props.history);
    }

    onPriorityChange = (e, catId) => {
        let priority = e.target.value;
        let catsPriority = this.state.catsPriority;

        catsPriority[catId] = priority;
        this.setState({ catsPriority: catsPriority })
    }

    onChangeCheckboxOrderAllowed = (e, id) => {
        const post = {
            id: id,
            order_allowed: e.target.checked,
        }
        this.props.editCategoryHierarchy(post, this.props.history);
    }

    onChangeCheckboxActive = (e, id) => {
        const post = {
            id: id,
            active: e.target.checked,
        }
        this.props.editCategoryHierarchy(post, this.props.history);
    }

    onDiscountChange = (e, catId) => {
        let discount = e.target.value;
        let catsDiscounts = this.state.catsDiscounts;

        catsDiscounts[catId] = discount;
        this.setState({ catsDiscounts: catsDiscounts })
    }

    updateDiscountPercent = (e, catId) => {
        e.preventDefault();

        const post = {
            category_id: catId,
            discount_percent: e.target.value
        }
        this.props.updateDiscountPercentForCatAndProducts(post, this.props.history);
    }

    render() {
        let catItems = this.props.cats;

        if (this.state.search_name.length > 0
            || this.state.search_active != 0
            || this.state.search_level.length > 0
            || this.state.search_parent_category.length > 0
        ) {
            catItems = Object.values(catItems).filter(i => {
                return (
                    ((i.name).toLocaleLowerCase().includes((this.state.search_name).toLocaleLowerCase()))
                    && (this.state.search_level.length > 0 ? (i.hierarchyLevel) == (this.state.search_level) : true)
                    && (this.state.search_parent_category.length > 0 ? i.parentId == (parseInt(this.state.search_parent_category)) : true)

                    && (this.state.search_active != 0 ? i.active == Boolean(parseInt(this.state.search_active) - 1) : true)
                )
            })
        }

        const items_count = Object.keys(catItems).length
        const current_page = this.props.page;
        const start_offset = (current_page - 1) * ITEMS_PER_PAGE;
        let start_count = 0;

        return (
            <main className="main">

                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><NavLink to={ADMIN_URL}>{this.props.translations.common.dashboard}</NavLink></li>
                    <li className="breadcrumb-item active">{this.props.translations.categories.title}</li>
                    <li className="breadcrumb-menu d-md-down-none" >
                        <div className="btn-group" role="group" aria-label="Button group">
                            <NavLink to={ADMIN_URL + `categories-hierarchy/add`} className="btn" >
                                <i className="fas fa-plus"></i> &nbsp; {this.props.translations.categories.add}
                            </NavLink>
                        </div>
                    </li>
                </ol>

                <div className="container-fluid">
                    <div className="animated fadeIn">
                        <div className="row">

                            <Filter
                                translations={this.props.translations}
                                onChange={this.onChange}
                                allCategories={this.props.cats}
                            />

                            {/* <!-- /.col--> */}
                            <div className="col-lg-12">
                                <div className="card">

                                    <div className="card-body">

                                        <MessagesContainer />

                                        <table className="table table-responsive-sm table-striped">
                                            <thead>
                                                <tr>
                                                    <th>{this.props.translations.common.name}</th>
                                                    <th>{this.props.translations.common.parent_category}</th>
                                                    <th>{this.props.translations.common.icon}</th>
                                                    <th>{this.props.translations.common.active}</th>
                                                    <th>
                                                        <span title={this.props.translations.common.priority_description}>
                                                            {this.props.translations.common.priority}
                                                            <span data-toggle="tooltip" data-placement="bottom"> 🛈</span>
                                                        </span>
                                                    </th>
                                                    <th>
                                                        <span title={this.props.translations.common.discount_percent_info}>
                                                            {this.props.translations.common.discount_percent}
                                                            <span data-toggle="tooltip" data-placement="bottom"> 🛈</span>
                                                        </span>
                                                    </th>
                                                    <th>
                                                        <span title={this.props.translations.common.order_allowed_info}>
                                                            {this.props.translations.common.order_allowed}
                                                            <span data-toggle="tooltip" data-placement="bottom"> 🛈</span>
                                                        </span>
                                                    </th>
                                                    <th>{this.props.translations.common.action}</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                {Object.values(catItems).sort(Utils.compareForPriority()).map((cat, index) => {
                                                    if (index >= start_offset && start_count < ITEMS_PER_PAGE) {
                                                        start_count++;
                                                        return <ListEachCategoryHierarchy
                                                            key={cat.id}
                                                            category={cat}
                                                            priority={this.state.catsPriority && this.state.catsPriority[cat.id] || 0}
                                                            discount={this.state.catsDiscounts && this.state.catsDiscounts[cat.id] || 0}
                                                            onPriorityChange={this.onPriorityChange}
                                                            updatePriority={this.updatePriority}

                                                            onDiscountChange={this.onDiscountChange}
                                                            updateDiscountPercent={this.updateDiscountPercent}

                                                            deleteCategory={this.deleteCategory}
                                                            onChangeCheckboxOrderAllowed={this.onChangeCheckboxOrderAllowed}
                                                            onChangeCheckboxActive={this.onChangeCheckboxActive}
                                                            currLang={this.props.currLang}
                                                            translations={this.props.translations}
                                                            allCategories={this.props.cats}
                                                            settings={this.props.settings}
                                                        />
                                                    }
                                                })}

                                            </tbody>
                                        </table>

                                        <Pagination
                                            className="pagination"
                                            itemClass="page-item"
                                            activePage={current_page}
                                            activeClass="active"
                                            linkClass="page-link"
                                            prevPageText="<"
                                            nextPageText=">"
                                            firstPageText="<<"
                                            lastPageText=">>"
                                            itemsCountPerPage={ITEMS_PER_PAGE}
                                            totalItemsCount={items_count}
                                            pageRangeDisplayed={ITEMS_PAGE_RANGE_DISPLAYED}
                                            onChange={this.handlePageChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            {/* <!-- /.col--> */}
                        </div>
                    </div>
                </div>
            </main>
        )
    }
}

const mapStateToProps = (state, dispatch) => {

    let catsPriority = {};
    if (state.categoriesHierarchy.categories) {
        Object.values(state.categoriesHierarchy.categories).map(cat => {
            catsPriority[cat.id] = cat.priority;
        })
    }

    return {
        cats: state.categoriesHierarchy.categories || [],
        catsPriority: catsPriority,
        page: Number(state.router.location.query.page) || 1,
        translations: state.lang,
        currLang: "bg",
        router: state.router,
        settings: state.settings.settings,
    }
};

const mapDispatchToProps = (dispatch, props) => {
    return {
        dispatch,
        ...bindActionCreators({ listCategoriesHierarchy, editCategoryHierarchy, updateDiscountPercentForCatAndProducts, getSampleImages }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ListCategoriesHierarchyPage);
