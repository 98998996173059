import { CURRENTLY_EDITED_RESERVATION, RESERVATION_CREATED, RESERVATION_ERROR, RESERVATION_UPDATED } from '../../../reservations/reservations/actions/types';
import { LIST_RESERVATIONS } from '../actions/types';

const initialState = {
    reservations: [],
    successMsg: '',
    errors: [],
    currentlyEditedReservation: false
}

export default function (state = initialState, action) {
    switch (action.type) {
        case LIST_RESERVATIONS: {
            return {
                ...state,
                reservations: action.payload,
            }
        }
        case RESERVATION_ERROR:
            return {
                ...state,
                errors: action.payload.message,
                successMsg: ""
            }
        case RESERVATION_CREATED:
            return {
                ...state,
                successMsg: action.payload.message,
                errors: []
            }
        case RESERVATION_UPDATED:
            return {
                ...state,
                successMsg: action.payload.message,
                errors: []
            }
        case CURRENTLY_EDITED_RESERVATION:
            return {
                ...state,
                currentlyEditedReservation: action.payload
            }
        default:
            return state;
    }
}