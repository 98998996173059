import React, { Component } from 'react'
import AddEditTableForm from './AddEditTableForm';
import { editTable, removeTable } from '../../actions/tablesActions';
import { listRestaurantHalls } from '../restaurantHalls/actions/restaurantHalls.actions';
import { listTableRegions } from '../table_regions/actions/tableRegions.actions';
import { addErrorMessages } from './../messages/messages.actions';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { ADMIN_URL, ADMIN_QR_CODE_DATA } from '../../constants';
import Utils from './../../utils/Utils';
import Auth from './../../utils/Auth';
import QRCodeStyling from "qr-code-styling";

let md5 = require('md5');

const tableQRCode = new QRCodeStyling(ADMIN_QR_CODE_DATA);

class EditTablePage extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			id: this.props.match.params.id,
			table_num: this.props.table.table_num ? this.props.table.table_num : '',
			table_token: this.props.table.table_token ? this.props.table.table_token : '',
			qr_code_image: this.props.table.qr_code_image ? this.props.table.qr_code_image : '',
			active: this.props.table.active ? this.props.table.active : 0,
			place_in_url: this.props.table.place_in_url ? this.props.table.place_in_url : 0,
			currentDate: + new Date(),
			is_for_app: this.props.table.is_for_app ? this.props.table.is_for_app : 0,
			is_kiosk_mode: this.props.table.is_kiosk_mode ? this.props.table.is_kiosk_mode : 0,
			// tableOrdertypes: [],
			tableOrdertype: '',
			tableOrdertypeValue: '',

			reservation_min_people: '',
			reservation_max_people: '',
			restaurantHallId: '',
			tableRegionId: '',
		}
	}

	componentWillReceiveProps(nextProps) {
		let tableOrdertype = [];
		if (nextProps.table.ordertypes.length > 0) {
			// nextProps.table.ordertypes.map(type => {
			tableOrdertype.push({ value: parseInt(nextProps.table.ordertypes[0].id), label: nextProps.table.ordertypes[0].type })
			// })
		}
		// console.log("RECEIVE ", tableOrdertype);
		this.setState({
			id: nextProps.match.params.id,
			table_num: nextProps.table.table_num ? nextProps.table.table_num : '',
			table_token: nextProps.table.table_token ? nextProps.table.table_token : '',
			qr_code_image: nextProps.table.qr_code_image ? nextProps.table.qr_code_image : '',
			active: nextProps.table.active ? nextProps.table.active : 0,
			is_for_app: nextProps.table.is_for_app ? nextProps.table.is_for_app : 0,
			is_kiosk_mode: nextProps.table.is_kiosk_mode ? nextProps.table.is_kiosk_mode : 0,
			place_in_url: nextProps.table.place_in_url ? nextProps.table.place_in_url : 0,
			tableOrdertype: tableOrdertype,
			tableOrdertypeValue: tableOrdertype && tableOrdertype[0] && tableOrdertype[0] || "",
			reservation_min_people: nextProps.table.reservation_min_people ? nextProps.table.reservation_min_people : '',
			reservation_max_people: nextProps.table.reservation_max_people ? nextProps.table.reservation_max_people : '',
			restaurantHallId: nextProps.table.restaurantHallId ? nextProps.table.restaurantHallId : '',
			tableRegionId: nextProps.table.tableRegionId ? nextProps.table.tableRegionId : '',

		});
	}

	componentWillMount() {
		this.props.listRestaurantHalls();
		this.props.listTableRegions();

		let tableOrdertype = [];
		if (this.props.table.ordertypes.length > 0) {
			// nextProps.table.ordertypes.map(type => {
			tableOrdertype.push({ value: parseInt(this.props.table.ordertypes[0].id), label: this.props.table.ordertypes[0].type })
			// })
		}

		this.setState({
			tableOrdertype: tableOrdertype,
			tableOrdertypeValue: tableOrdertype && tableOrdertype[0] && tableOrdertype[0] || ""
		})
	}

	onChange = (e) => {
		this.setState({ [e.target.name]: e.target.value })
	}

	onChangeCheckbox = (e) => {
		this.setState({ [e.target.name]: e.target.checked });
	}

	onChangeSelectAutocomplete = (fieldName, valueLabel) => {
		this.setState({ [fieldName]: valueLabel });
	}

	onFieldChange = (fieldName, value) => {
		this.setState({ [fieldName]: value });
	}

	validate() {
		let errors = [];

		if (this.state.table_num.length == 0) {
			errors.push(this.props.translations.messages.errors.invalid_table_num);
		}

		if (this.state.tableOrdertypeValue.length == '') {
			errors.push(this.props.translations.messages.errors.invalid_ordertype);
		}

		return errors;
	}

	onSubmit = (e) => {
		e.preventDefault();

		const { places, clients } = this.props;

		const errors = this.validate();
		if (errors.length > 0) {
			this.props.addErrorMessages(errors);
			return;
		}

		let currClient = '';
		let currPlace = '';

		if (places) {
			currPlace = places.find(p => p.id == Auth.getUserPlace());

			if (currPlace && Object.values(currPlace).length > 0 && currPlace.clientId) {
				currClient = clients.find(c => c.id == currPlace.clientId)
			}
		}

		let currClientSlug = '';
		if (currClient && Object.keys(currClient).length > 0 && currClient.slug) {
			currClientSlug = currClient.slug;
		}

		const post = {
			id: this.state.id,
			table_num: this.state.table_num,

			// table_token: this.state.table_token, //  md5(md5(this.state.table_num + (this.state.currentDate))),
			qr_code_image: this.state.qr_code_image,
			active: this.state.active,
			is_for_app: this.state.is_for_app,
			is_kiosk_mode: this.state.is_kiosk_mode,
			place_in_url: this.state.place_in_url,
			place_url: this.state.place_in_url ? '/' + currClientSlug : '',
			tableOrdertypes: [this.state.tableOrdertypeValue.value],
			reservation_min_people: this.state.reservation_min_people || 0,
			reservation_max_people: this.state.reservation_max_people || 0,
			restaurantHallId: this.state.restaurantHallId || null,
			tableRegionId: this.state.tableRegionId || null,
		}

		var canvas = document.getElementById('table-qr-code');
		let formData = new FormData()

		if (canvas) {
			var img = canvas.toDataURL("image/png");

			if (img) {
				// Image append
				formData.append("file", img)
			}
		}

		this.props.editTable(this.state.id, post, this.props.history, formData);

	}

	deleteTable = (e, id) => {
		e.preventDefault();
		this.props.removeTable(id, this.props.history);
	}

	generateNewQrCode = (e) => {
		e.preventDefault();
		let formData = new FormData()

		this.props.editTable(this.state.id, { table_token: "token_to_be_changed" }, this.props.history, formData);

		// let newToken = Utils.generateTableToken(this.state.table_num);

		// this.setState({
		// 	table_token: newToken
		// })
		// console.log("NEW", newToken)
	}

	downloadQR = () => {
		tableQRCode.download({
			extension: 'png',
			name: this.state.table_num
		});


		// const canvas = document.querySelector("#table-qr-code2 canvas");
		// const pngUrl = canvas
		// 	.toDataURL("image/png")
		// 	.replace("image/png", "image/octet-stream");
		// let downloadLink = document.createElement("a");
		// downloadLink.href = pngUrl;
		// downloadLink.download = "Table" + this.state.table_num + ".png";
		// document.body.appendChild(downloadLink);
		// downloadLink.click();
		// document.body.removeChild(downloadLink);
	};

	render() {

		// const findNextTable = () => {
		// 	if (this.props.table && this.props.tables && this.props.table.tables) {
		// 		let table = this.props.table;
		// 		let tables = this.props.tables.tables;
		// 		console.log(`table`, table)
		// 		console.log(`tables`, tables)
		// 		console.log(`table.id`, table.id)
		// 		let index = Object.values(tables.tables).findIndex(t => t.id == table.id)
		// 		console.log(`index`, index)
		// 		let nextTable = Object.values(tables.tables)[index + 1].id
		// 		console.log(`nextTable`, nextTable)
		// 		// return nextTable;
		// 	}
		// }

		return (
			<main className="main">

				{/* <!-- Breadcrumb--> */}
				<ol className="breadcrumb">
					<li className="breadcrumb-item"><NavLink to={ADMIN_URL}>{this.props.translations.common.dashboard}</NavLink></li>
					<li className="breadcrumb-item"><NavLink to={ADMIN_URL + "tables"}>{this.props.translations.tables.title}</NavLink></li>
					<li className="breadcrumb-item active">{this.props.translations.tables.edit}</li>
					{/* <li className="breadcrumb-menu d-md-down-none">
						<div className="btn-group" role="group" aria-label="Button group">
						</div>
					</li> */}
					<li className="breadcrumb-menu d-md-down-none">
						<div className="btn-group" role="group" aria-label="Button group">
							{/* <button onClick={() => Utils.navigateTo(ADMIN_URL + `tables/edit/` + findNextTable())}> */}
							{/* <button onClick={() => console.log(findNextTable())}>
								{this.props.translations.common.next}&nbsp;<i className="fas fa-arrow-right"></i>
							</button> */}
							{/* <NavLink to={ADMIN_URL + `tables/edit/` + findNextTable()} className="btn" >
								{this.props.translations.common.next}&nbsp;<i className="fas fa-arrow-right"></i>
							</NavLink> */}
						</div>
					</li>

				</ol>

				<div className="container-fluid">
					<div className="animated fadeIn">
						<div className="row">

							{/* <!-- /.col--> */}
							<div className="col-sm-12">
								<div className="card">

									<AddEditTableForm
										onSubmit={this.onSubmit}
										onChange={this.onChange}
										onChangeCheckbox={this.onChangeCheckbox}
										// onChangeSelectAutocomplete={this.onChangeSelectAutocomplete}
										onFieldChange={this.onFieldChange}
										table={this.state}
										restaurantHalls={this.props.restaurantHalls}
										tableRegions={this.props.tableRegions}

										tableId={this.state.id}
										tableNum={this.state.table_num}
										tableToken={this.state.table_token}
										qrCodeImage={this.state.qr_code_image}
										tableActive={this.state.active}
										placeInUrl={this.state.place_in_url}
										currentDate={this.state.currentDate}
										tableOrdertypeValue={this.state.tableOrdertypeValue}

										deleteTable={this.deleteTable}
										actionText="Запази"
										isAddingTable={0}
										nameError={this.props.errors.find(error => error.path === 'name') ? this.props.errors.find(error => error.path === 'name') : ""}
										translations={this.props.translations}
										generateNewQrCode={this.generateNewQrCode}
										downloadQr={this.downloadQR}
										currPlaceOrderTypes={this.props.currPlaceOrderTypes}

										tableQRCode={tableQRCode}
									/>
								</div>
							</div>

						</div>
					</div>
				</div>

			</main>
		)
	}
}

const mapStateToProps = (state, ownProps) => {
	let tableId = ownProps.match.params.id;

	let orderTypes = [];

	if (state.places.places) {
		let currPlace = state.places.places.find(p => p.id == Auth.getUserPlace());
		if (currPlace) {
			orderTypes = currPlace.ordertypes;
		}
	}

	let table = state.tables.tables[tableId];
	if (!table) table = {
		id: '',
		table_num: '',
		qr_code_image: '',
		active: '',
		place_in_url: '',
		table_token: '',
		ordertypes: [],
	};
	return {
		table: table,
		tables: state.tables,
		currPlaceOrderTypes: orderTypes,
		restaurantHalls: state.restaurantHalls.restaurantHalls || [],
		tableRegions: state.tableRegions.tableRegions || [],
		errors: state.tables.errors,
		translations: state.lang,

		clients: state.clients.clients,
		places: state.places.places,
	}


};

export default connect(mapStateToProps, { editTable, removeTable, addErrorMessages, listRestaurantHalls, listTableRegions })(EditTablePage);