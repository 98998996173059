import axios from 'axios';
import moment from 'moment';
import { LIST_ORDERS, LIST_USER_NEEDS, LOADING_ORDERS_START, LOADING_ORDERS_END } from './types';
import { API_URL, FILTER_PLACES_NAME } from './../constants'
import { addErrorMessages, addSuccessMessage } from './../components/messages/messages.actions';
import setAuthorizationToken from '../utils/setAuthorizationToken';
import Auth from './../utils/Auth';
import { showLoading, hideLoading } from '../components/common/loading/loading.actions';

export const listOrders = (placesIds = [], getAll = 0, show_loading = 0, dateFrom = null, dateTo = null) => dispatch => {
    let query = '?';

    if (placesIds.length > 0) {
        query += "places_ids=" + encodeURIComponent(placesIds);
    }
    if (getAll) {
        query += '&all=1';
    }
    if (dateFrom) {
        dateFrom = moment(dateFrom).format('YYYY-MM-DD')
        query += `&dateFrom=${dateFrom}`;
    }
    if (dateTo) {
        dateTo = moment(dateTo).format('YYYY-MM-DD') + " 23:59";
        query += `&dateTo=${encodeURIComponent(dateTo)}`;
    }

    query += '&ts=' + new Date().getTime();

    if (query == '?') {
        query = '';
    }

    if (show_loading) {
        dispatch({ type: LOADING_ORDERS_START });
    }
    axios
        .get(API_URL + 'orders' + query)
        .then(res => {
            dispatch({
                type: LIST_ORDERS,
                payload: res.data
            });
            if (show_loading) {
                dispatch({ type: LOADING_ORDERS_END });
            }
        }).catch(error => {
            if (show_loading) {
                dispatch({ type: LOADING_ORDERS_END });
            }
            // throw (error);
        });
}

export const removeOrder = (id, history) => dispatch => {
    return axios
        .put(API_URL + 'orders/' + id + "/delete", { deleted: true })
    // .then(
    //     function (res) {
    //         if (history) {
    //             // Deleting from /edit
    //             history.push(API_URL + "products")
    //         } else {
    //             // Deleting from /list
    //             let placesIds = window.localStorage.getItem(FILTER_PLACES_NAME) && JSON.parse(window.localStorage.getItem(FILTER_PLACES_NAME)) || [];
    //             dispatch(listOrders(placesIds)) //removed because of sockets
    //         }
    //     }
    // )
    // .catch(function (e) {
    // });
}

export const updateOrderById = (orderId, postData) => dispatch => {
    axios
        .put(API_URL + `orders/${orderId}/update`, postData)
        .then((post) => {
            // post = post.data;
            dispatch(addSuccessMessage(`Successfully updated order ${orderId} `))
            // dispatch(listOrders());
        }
        )
        .catch(function (error) {
            dispatch(addErrorMessages([`Cannot update order ${orderId}`]));
        });
}

export const updateOrderStatus = (orderId, postData, order) => dispatch => {
    postData.ordertypeId = order.ordertypeId;

    return axios.put(API_URL + `orders/${orderId}/status`, postData)
    // .then(
    //     function (post) {
    //         post = post.data;
    //         dispatch(addSuccessMessage(`Successfully updated order status `))
    //         let placesIds = window.localStorage.getItem(FILTER_PLACES_NAME) && JSON.parse(window.localStorage.getItem(FILTER_PLACES_NAME)) || [];
    //         dispatch(listOrders(placesIds)) //removed because of sockets
    //     }
    // )
    // .catch(function (error) {
    //     dispatch(addErrorMessages(error.response.data.error.errors));
    // });
}

export const updateOrderPrinted = (orderId, postData) => dispatch => {
    axios
        .put(API_URL + `orders/${orderId}/update`, postData)
        .then((post) => {
            // post = post.data;
            dispatch(addSuccessMessage(`Successfully printed order #${orderId} `))
            // dispatch(listOrders());
        })
        .catch(function (error) {
            dispatch(addErrorMessages([`Cannot save that order #${orderId} is printed, PLEASE REFRESH`]));
        });
}

export const updateOrderPrintedByPlace = (placeId, postData) => dispatch => {
    axios
        .put(API_URL + `orders/${placeId}/updateByPlace`, postData)
        .then((post) => {
            // post = post.data;
            dispatch(addSuccessMessage(`Successfully marked as printed orders for place #${placeId} `))
            // dispatch(listOrders());
        })
        .catch(function (error) {
            dispatch(addErrorMessages([`Cannot update that order #${placeId} is printed, PLEASE REFRESH`]));
        });
}


export const updateSeen = (tablesArr, method, placesIds) => dispatch => {
    let updateSeenPath = `orders/${method}`;
    if (method == 'user_needs') {
        updateSeenPath = 'user_needs/seen';
    }
    // dispatch(showLoading());

    return axios
        .post(API_URL + updateSeenPath, { tables: tablesArr, placesIds: placesIds })
    // .then((post) => {
    //     dispatch(listOrders(placesIds)) //removed because of sockets
    //     dispatch(getUserNeeds(placesIds)); //removed because of sockets
    //     dispatch(hideLoading());
    // })
    // .catch(function (error) {
    //     dispatch(addErrorMessages([`Cannot mark as read table ids [${tablesArr}]. Refresh this page or contact us`]));
    //     dispatch(hideLoading());
    // });
}

// export const updateOrderSeen = (tablesArr, placeId) => dispatch => {
//     dispatch(showLoading());
//     axios
//         .post(API_URL + `orders/seen_order`, { tables: tablesArr, placeId: placeId })
//         .then((post) => {
//             // post = post.data;
//             // dispatch(addSuccessMessage(`Successfully printed order ${orderId} `))
//             dispatch(listOrders());
//             dispatch(hideLoading());
//         })
//         .catch(function (error) {
//             dispatch(addErrorMessages([`Cannot mark as read tables ${tablesArr}. Refresh this page or contact us`]));
//             dispatch(hideLoading());
//         });
// }


export const updateOrderByTableId = (postData) => dispatch => {
    axios
        .put(API_URL + `orders/by_table/${postData.tableId}/status`, postData)
        .then((post) => {
            post = post.data;
            dispatch(addSuccessMessage(`Successfully updated order status `))
            let placesIds = window.localStorage.getItem(FILTER_PLACES_NAME) && JSON.parse(window.localStorage.getItem(FILTER_PLACES_NAME)) || [];
            // dispatch(listOrders(placesIds));
        })
        .catch((error) => {
            dispatch(addErrorMessages(error.response.data.error.errors));
        });
}

export const updateProductVariantStatus = (orderProductVariantId, postData/*, order*/) => dispatch => {
    return axios
        // .put(API_URL + `orders/${orderId}/productVariants/${productvariant_id}/status`, postData)
        .post(API_URL + `orders/orderProductVariants/${orderProductVariantId}/status`, postData)
    // .then(
    //     function (post) {
    //         post = post.data;

    //         let placesIds = window.localStorage.getItem(FILTER_PLACES_NAME) && JSON.parse(window.localStorage.getItem(FILTER_PLACES_NAME)) || [];
    //         dispatch(listOrders(placesIds));
    //         dispatch(addSuccessMessage(`Successfully updated order ${post.id} status `))
    //     }
    // )
    // .catch(function (error) {
    //     // dispatch(addErrorMessages(error.response.data.error.errors));
    // });
}

export const updateUserNeedsStatusById = (uId, newStatus) => dispatch => {
    const placeId = Auth.getUserPlace();

    axios
        .put(API_URL + `user_needs/${uId}`, { status: newStatus, placeId: placeId })
        .then(
            function (post) {
                post = post.data;
                dispatch(addSuccessMessage(`Successfully updated user need status `))
                dispatch(getUserNeeds(placeId));
            }
        )
        .catch(function (error) {
            // dispatch(addErrorMessages(error.response.data.error.errors));
        });
}



export const getUserNeeds = (placesIds = [], getAll = 0, show_loading = 0) => dispatch => {
    let getParam = '?';
    if (getAll) {
        getParam += 'all=1&';
    }

    if (placesIds.length > 0) {
        getParam += "places_ids=" + encodeURIComponent(placesIds);
    }

    if (show_loading) {
        dispatch({ type: LOADING_ORDERS_START });
    }

    if (getParam == '?') {
        getParam = '';
    }

    axios
        .get(API_URL + "tables/user_needs" + getParam)
        .then(res => {
            dispatch({
                type: LIST_USER_NEEDS,
                payload: res && res.data ? res.data.reverse() : []
            })
            if (show_loading) {
                dispatch({ type: LOADING_ORDERS_END });
            }
            // let tablesUserNeeds = res.data;

            // let userNeedsCount = 0;
            // if (typeof tablesUserNeeds != "undefined" && tablesUserNeeds.length > 0) {
            //     tablesUserNeeds.forEach(t => {
            //         userNeedsCount += t.table_userneeds.length
            //     });
            // }

            // this.setState({
            //     userNeeds: tablesUserNeeds,
            //     userNeedsCount: userNeedsCount
            // });
        });
}


export const updateAnonUser = (data) => dispatch => {
    return axios
        .post(API_URL + `anonymous-users`, data)
}

export const resendOrderToDeliverySystem = (orderId) => dispatch => {
    const placeId = Auth.getUserPlace();

    return axios
        .post(API_URL + `ds/resend-order?place=` + placeId, {orderId: orderId})

}