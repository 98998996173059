import React, { Component } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'

import { listRegions, addEditRegion } from '../actions/regions.actions';
import ListEachRegion from './ListEachRegion';
import { NavLink } from 'react-router-dom';
import { ADMIN_URL, ITEMS_PER_PAGE, ITEMS_PAGE_RANGE_DISPLAYED } from '../../../constants.js'
import Pagination from "react-js-pagination";
import { push } from 'react-router-redux';
// import Filter from './Filter';
import MessagesContainer from '../../messages/MessagesContainer';
import { translations } from '../../common/translations';
import Utils from '../../../utils/Utils';
import Auth from '../../../utils/Auth.js';

class ListRegionsPage extends React.Component {
    constructor(props) {
        super(props);
        this.activeDefaultLanguage = 'bg'; // TODO add it in db
        this.state = {
            search_name: '',
            search_active: '',
        }
    }

    componentWillMount() {
        this.props.listRegions();
    }

    deleteRegion = (e, id) => {
        e.preventDefault();
        this.props.addEditRegion({ id: id, deleted: true });
    }

    handlePageChange = (page) => {
        this.props.dispatch(push('/admin/regions?page=' + page))
    }

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
        this.handlePageChange(1);
    }

    onChangeCheckboxIsActive = (e, id) => {
        const post = {
            id: id,
            active: e.target.checked,
        }
        this.props.addEditRegion(post);
    }

    render() {
        let regions = this.props.items;

        // if (this.state.search_name.length > 0
        //     || this.state.search_active != 0
        // ) {
        //     catItems = Object.values(catItems).filter(i => {
        //         return (
        //             ((i.name).toLocaleLowerCase().includes((this.state.search_name).toLocaleLowerCase()))
        //             && (this.state.search_active != 0 ? i.active == Boolean(parseInt(this.state.search_active) - 1) : true)
        //         )
        //     })
        // }

        const items_count = (regions).length
        const current_page = this.props.page;
        const start_offset = (current_page - 1) * ITEMS_PER_PAGE;
        let start_count = 0;

        return (
            <main className="main">

                {/* <!-- Breadcrumb--> */}
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><NavLink to={ADMIN_URL}>{this.props.translations.common.dashboard}</NavLink></li>
                    <li className="breadcrumb-item"><NavLink to={ADMIN_URL + 'settings'}>{this.props.translations.sidemenu.settings}</NavLink></li>
                    <li className="breadcrumb-item"><NavLink to={ADMIN_URL + 'settings/category/6'}>{this.props.translations.sidemenu.orders}</NavLink></li>
                    {/* <li className="breadcrumb-item">Меню</li> */}
                    <li className="breadcrumb-item active">{this.props.translations.regions.title}</li>
                    {/* <!-- Breadcrumb Menu--> */}
                    <li className="breadcrumb-menu d-md-down-none">
                        <div className="btn-group" role="group" aria-label="Button group">
                            <NavLink to={ADMIN_URL + `regions/add`} className="btn" >
                                <i className="fas fa-plus"></i> &nbsp; {this.props.translations.regions.add}
                            </NavLink>
                            {/* 
                            <!--<a className="btn" href="./">
                                <i className="icon-graph"></i>  Dashboard</a>
                            <a className="btn" href="#">
                                <i className="icon-settings"></i>  Settings</a>
                            --> 
                            */}
                        </div>
                    </li>
                </ol>

                <div className="container-fluid">
                    <div className="animated fadeIn">
                        <div className="row">

                            {/* <Filter
                                translations={this.props.translations}
                                onChange={this.onChange}
                            /> */}

                            {/* <!-- /.col--> */}
                            <div className="col-lg-12">
                                <div className="card">

                                    <div className="card-body">

                                        <MessagesContainer />

                                        <table className="table table-responsive-sm table-striped">
                                            <thead>
                                                <tr>
                                                    <th>{this.props.translations.common.name}</th>
                                                    <th>{this.props.translations.common.description}</th>
                                                    <th>{this.props.translations.common.delivery_price}</th>
                                                    <th>{this.props.translations.common.active}</th>
                                                    {(Auth.hasAccess("SUPER_ADMIN") || Auth.hasAccess('CLIENT_ADMIN') || Auth.hasAccess('PLACE_ADMIN')) && this.props.currPlace && this.props.currPlace.deliverySystemId ?
                                                        <th>{this.props.translations.common.external_system_region_id}</th>
                                                        :
                                                        null
                                                    }
        
                                                    <th>{this.props.translations.common.action}</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                {(regions).sort(Utils.sortName()).map((region, index) => {
                                                    if (index >= start_offset && start_count < ITEMS_PER_PAGE) {
                                                        start_count++;
                                                        return <ListEachRegion
                                                            key={region.id}
                                                            region={region}
                                                            deleteRegion={this.deleteRegion}
                                                            onChangeCheckboxIsActive={this.onChangeCheckboxIsActive}

                                                            // activeDefaultLanguage={this.activeDefaultLanguage}
                                                            translations={this.props.translations}
                                                            currLang={this.props.currLang}
                                                            currPlace={this.props.currPlace}
                                                        />
                                                    }
                                                })}

                                            </tbody>
                                        </table>

                                        <Pagination
                                            className="pagination"
                                            itemClass="page-item"
                                            activePage={current_page}
                                            activeClass="active"
                                            linkClass="page-link"
                                            prevPageText="<"
                                            nextPageText=">"
                                            firstPageText="<<"
                                            lastPageText=">>"
                                            itemsCountPerPage={ITEMS_PER_PAGE}
                                            totalItemsCount={items_count}
                                            pageRangeDisplayed={ITEMS_PAGE_RANGE_DISPLAYED}
                                            onChange={this.handlePageChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            {/* <!-- /.col--> */}
                        </div>
                    </div>
                </div>
            </main>
        )
    }
}

const mapStateToProps = (state, dispatch) => {
    let currPlace = state.places.places.find(p => p.id == Auth.getUserPlace());

    return {
        items: state.regions.regions || [],
        page: Number(state.router.location.query.page) || 1,
        translations: state.lang,
        currLang: "bg",
        currPlace: currPlace || null
    }
};

const mapDispatchToProps = (dispatch, props) => {
    return {
        dispatch,
        ...bindActionCreators({ listRegions, addEditRegion }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ListRegionsPage);
