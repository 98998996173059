import axios from 'axios';
import { LIST_GENERAL_PROMOTIONS } from './types';
import { API_URL, ADMIN_URL } from './../../../constants';
import { addSuccessMessage, addErrorMessages } from './../../messages/messages.actions';
import { changeLanguage } from './../../../actions/languagesActions';
import Auth from './../../../utils/Auth';
import jwtDecode from 'jwt-decode';

export const listGeneralPromotions = () => dispatch => {

    axios
        .get(API_URL + `general-promotions-admin`)
        .then(res => {
            dispatch({
                type: LIST_GENERAL_PROMOTIONS,
                payload: res.data
            });
        })
        .catch(error => {
            throw (error);
        });
}

export const addEditGeneralPromotion = (postData, history, langId) => dispatch => {
    if(!postData.placeId) {
        postData.placeId = Auth.getUserPlace();
    }
    axios
        .post(API_URL + 'general-promotions', postData)
        .then((post) => {
            post = post.data;
            // dispatch(addEditAllergenTranslation(post.id, langId, postData))
            // if (!postData.id && typeof postData.deleted === "undefined") { // edit
            // } else {
                dispatch(listGeneralPromotions());
                history.push(ADMIN_URL + 'general-promotions');
                dispatch(addSuccessMessage("Changes saved"))
                
            // }
        })
        .catch(function (error) {
            // dispatch(addErrorMessages('Error occured'));
        })
}
