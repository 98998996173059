import React, { Component } from 'react'
import AddEditImageForm from './AddEditImageForm';
import { addEditPlaceImage, listPlaceImages } from './../actions/place_images.actions';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { ADMIN_URL } from './../../../constants';
import Utils from './../../../utils/Utils';
import Auth from '../../../utils/Auth';

let md5 = require('md5');

class EditPlaceImagePage extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			id: this.props.match.params.id,
			path: this.props.placeImage.path ? this.props.placeImage.path : '',
			approved: this.props.placeImage.approved ? this.props.placeImage.approved : 0,
			text: this.props.placeImage.text ? this.props.placeImage.text : '',
		}

		this.onChange = this.onChange.bind(this);
		this.onChangeCheckbox = this.onChangeCheckbox.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
	}

	componentWillMount() {
        this.props.listPlaceImages();
    }

	componentWillReceiveProps(nextProps) {
		this.setState({
			id: nextProps.match.params.id,
			path: nextProps.placeImage.path ? nextProps.placeImage.path : '',
			approved: nextProps.placeImage.approved ? nextProps.placeImage.approved : 0,
			text: nextProps.placeImage.text ? nextProps.placeImage.text : '',
		});
	}
	onChange(e) {
		this.setState({ [e.target.name]: e.target.value });
	}

	onChangeCheckbox(e) {
		this.setState({ [e.target.name]: e.target.checked });
	}

	onSubmit(e) {
		e.preventDefault();

		// return;
		const post = {
			id: this.state.id,
			text: this.state.text,
			approved: this.state.approved,
			placeId: Auth.getUserPlace()
		}


		this.props.addEditPlaceImage(post, this.props.history);

	}

	render() {

		return (
			<main className="main">

				{/* <!-- Breadcrumb--> */}
				<ol className="breadcrumb">
					<li className="breadcrumb-item"><NavLink to={ADMIN_URL}>{this.props.translations.common.dashboard}</NavLink></li>
					{/* <li className="breadcrumb-item">Меню</li> */}
					<li className="breadcrumb-item"><NavLink to={ADMIN_URL + "place-images"}>{this.props.translations.place_images.title}</NavLink></li>
					<li className="breadcrumb-item active">{this.props.translations.place_images.edit}</li>
					{/* <!-- Breadcrumb Menu--> */}
					<li className="breadcrumb-menu d-md-down-none">
						<div className="btn-group" role="group" aria-label="Button group">

						</div>
					</li>
				</ol>

				<div className="container-fluid">
					<div className="animated fadeIn">
						<div className="row">

							{/* <!-- /.col--> */}
							<div className="col-sm-12">
								<div className="card">

									<AddEditImageForm
										onSubmit={this.onSubmit}
										onChange={this.onChange}
										onChangeCheckbox={this.onChangeCheckbox}
										placeImage={this.state}
										translations={this.props.translations}
										isAdding={0}
									/>
								</div>
							</div>

						</div>
					</div>
				</div>

			</main>
		)
	}
}

const mapStateToProps = (state, ownProps) => {
	let placeImageId = ownProps.match.params.id;

	let placeImage = state.placeImages.placeImages.find(i => i.id == placeImageId);

	if (!placeImage) placeImage = {
		id: '',
		path: '',
		approved: 0,
		text: '',
	};

	return {
		placeImage: placeImage,
		// errors: state.ingredients.errors,
		translations: state.lang,
		languages: state.languages.languages || {},
		currLang: state.settings.settings['default_lang'] && state.settings.settings['default_lang'].value || 'bg',

	}


};

export default connect(mapStateToProps, { addEditPlaceImage, listPlaceImages })(EditPlaceImagePage);