import React, { Component } from 'react'
import { connect } from 'react-redux';
import { listWarehouseSystems } from '../actions/warehouseSystems.actions';
import ListWarehouseSystemRow from './ListWarehouseSystemRow';
import Pagination from "react-js-pagination";
import { NavLink } from 'react-router-dom';
import { push } from 'react-router-redux';
import { ADMIN_URL, ITEMS_PER_PAGE, ITEMS_PAGE_RANGE_DISPLAYED } from '../../../constants.js'
import { bindActionCreators } from 'redux'
import Auth from '../../../utils/Auth';

class ListWarehouseSystemsPage extends React.Component {
    constructor(props){
        super(props);
        // this.activeDefaultLanguage = 'bg'; // TODO add it in db
    }
  
    componentDidMount() {
        this.props.listWarehouseSystems();
    }

    updateActive = (e, id) => {
        const data = {
            id: id,
            // isLiquid: this.state.isLiquid,
            active: e.target.checked,
        }

        this.props.addEditDeliveryShape(data);
    }

    // handlePageChange = (page) => {
    //     this.props.dispatch(push('/admin/delivery-shapes?page='+page))
    // }

    render() {
        let warehouseSystems = this.props.warehouseSystems;

        const items_count = Object.keys(warehouseSystems).length
        const current_page = this.props.page;
        const start_offset = (current_page - 1) * ITEMS_PER_PAGE;
        let start_count = 0; 

        let currPlace = this.props.places.find(p => p.id == Auth.getUserPlace());

        console.log("PLACE ", currPlace)

        return (
            <main className="main">
                    
                {/* <!-- Breadcrumb--> */}
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><NavLink to={ADMIN_URL}>{this.props.translations.common.dashboard}</NavLink></li>
                    <li className="breadcrumb-item"><NavLink to={ADMIN_URL + 'settings'}>{this.props.translations.sidemenu.settings}</NavLink></li>
					<li className="breadcrumb-item"><NavLink to={ADMIN_URL + 'settings/category/6'}>{this.props.translations.sidemenu.orders}</NavLink></li>
                    <li className="breadcrumb-item active">{this.props.translations.delivery_shapes.title}</li>
                    <li className="breadcrumb-menu d-md-down-none">
                        <div className="btn-group" role="group" aria-label="Button group">
                            <NavLink to={ADMIN_URL+`delivery-shapes/add`} className="btn" >
                                <i className="fas fa-plus"></i> &nbsp; {this.props.translations.delivery_shapes.add}
                            </NavLink>
                        </div>
                    </li>
                </ol>


                <div className="container-fluid">
                    <div className="animated fadeIn">
                        <div className="row">
                            
                            {/* <!-- /.col--> */}
                            <div className="col-lg-12">
                                <div className="card">
                                    
                                    <div className="card-body">
                                        <table className="table table-responsive-sm table-striped">
                                            <thead>
                                                <tr>
                                                    <th>{this.props.translations.common.name}</th>
                                                    <th>{this.props.translations.common.active}</th>
                                                    <th>{this.props.translations.common.action}</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                {Object.values(warehouseSystems).map((whs, index) => {
                                                    if(index >= start_offset && start_count < ITEMS_PER_PAGE) {
                                                        start_count++;

                                                        return <ListWarehouseSystemRow 
                                                            key={whs.id} 
                                                            whs={whs} 
                                                            updateActive={this.updateActive}
                                                            // deleteDeliveryShape={this.deleteDeliveryShape}
                                                            translations={this.props.translations}
                                                            // activeDefaultLanguage={this.activeDefaultLanguage}
                                                        />
                                                    }
                                                })}
                                                
                                            </tbody>
                                        </table>
                                    
                                        <Pagination
                                            className="pagination"
                                            itemClass="page-item"
                                            activePage={current_page}
                                            activeClass="active"
                                            linkClass="page-link"
                                            prevPageText="<"
                                            nextPageText=">"
                                            firstPageText="<<"
                                            lastPageText=">>"
                                            itemsCountPerPage={ITEMS_PER_PAGE}
                                            totalItemsCount={items_count}
                                            pageRangeDisplayed={ITEMS_PAGE_RANGE_DISPLAYED}
                                            onChange={this.handlePageChange}
                                            />

                                    </div>
                                </div>
                            </div>
                            {/* <!-- /.col--> */}
                        </div>
                    </div>
                </div>
            </main>
        )
    }
}

const mapStateToProps = state => ({
    warehouseSystems: state.warehouseSystems.warehouseSystems,
    page: Number(state.router.location.query.page) || 1,
    translations: state.lang,
    places: state.places.places || [],
});

const mapDispatchToProps = (dispatch, props) => {
    
    return {
        dispatch,
        ...bindActionCreators({ listWarehouseSystems }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ListWarehouseSystemsPage);
