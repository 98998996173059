import React, { Component } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'

import { getAvgTimeSpentPerPage } from './../actions/statistics.actions';

// import {LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend} from 'recharts';
// import { PieChart, Pie, Legend, Tooltip, ResponsiveContainer, Cell, Sector} from 'recharts';
// import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from 'recharts';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import moment from 'moment';


class AvgTimeSpentPerPagePieChart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            
        }
    }

    componentWillMount() {
        ChartJS.register(ArcElement, Tooltip, Legend);

    }

	  render () {

        var data1 = this.props.avgTimeSpentPerPageData.slice(0, 5).map(s => ({ name: s.page, value: parseInt(s.totalTimeSpent) }));
        var labels = this.props.avgTimeSpentPerPageData.slice(0, 5).map(s => s.cat_hierarchy ? s.cat_hierarchy.name : "-");
        var values = this.props.avgTimeSpentPerPageData.slice(0, 5).map(s => parseInt(s.totalTimeSpent));
        
        const RADIAN = Math.PI / 180;

        // const data = [
        //     { name: 'Group A', value: 400 },
        //     { name: 'Group B', value: 300 },
        //     { name: 'Group C', value: 300 },
        //     { name: 'Group D', value: 200 },
        //   ];
          
          const COLORS = ['#0088FE', '#ff0000', '#00C49F', '#FFBB28', '#FF8042'];
          const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
            const radius = 80 + innerRadius + (outerRadius - innerRadius) * 0.5;
            const x = cx + radius * Math.cos(-midAngle * RADIAN);
            const y = cy + radius * Math.sin(-midAngle * RADIAN);
            // const formatted = moment.utc(row.totalTimeSpent*1000).format('HH:mm:ss');
            console.log(cx, cy, midAngle, innerRadius, outerRadius, percent, index  )
            return (
              <text x={x} y={y} fill="black" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                  {data1[index].name} ({`${(percent * 100).toFixed(0)}%`})
              </text>
            );
          };       
          

          // const renderActiveShape = (props) => {
          //   const RADIAN = Math.PI / 180;
          //   console.log("PROPS", props)
          //   const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props;
          //   const sin = Math.sin(-RADIAN * midAngle);
          //   const cos = Math.cos(-RADIAN * midAngle);
          //   const sx = cx + (outerRadius + 10) * cos;
          //   const sy = cy + (outerRadius + 10) * sin;
          //   const mx = cx + (outerRadius + 30) * cos;
          //   const my = cy + (outerRadius + 30) * sin;
          //   const ex = mx + (cos >= 0 ? 1 : -1) * 22;
          //   const ey = my;
          //   const textAnchor = cos >= 0 ? 'start' : 'end';
          
          //   return (
          //     <g>
          //       <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill} style={{overflowWrap: "break-word"}}>
          //         {payload.name}
          //       </text>
          //       <Sector
          //         cx={cx}
          //         cy={cy}
          //         innerRadius={innerRadius}
          //         outerRadius={outerRadius}
          //         startAngle={startAngle}
          //         endAngle={endAngle}
          //         fill={fill}
          //       />
          //       <Sector
          //         cx={cx}
          //         cy={cy}
          //         startAngle={startAngle}
          //         endAngle={endAngle}
          //         innerRadius={outerRadius + 6}
          //         outerRadius={outerRadius + 10}
          //         fill={fill}
          //       />
          //       <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
          //       <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
          //       {/* <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333"> {`PV ${value}`}</text> */}
          //       <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
          //           {payload.name} {`(${(percent * 100).toFixed(2)}%)`}
          //       </text>
          //     </g>
          //   );
          // };

          const onPieEnter = (_, index) => {
            this.setState({
              activeIndex: index,
            });
          };


          const data = {
            labels: labels, //['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
            datasets: [
              {
                label: '# of Votes',
                data: values, //[12, 19, 3, 5, 2, 3],
                backgroundColor: [
                  'rgba(255, 99, 132, 0.2)',
                  'rgba(54, 162, 235, 0.2)',
                  'rgba(255, 206, 86, 0.2)',
                  'rgba(75, 192, 192, 0.2)',
                  'rgba(153, 102, 255, 0.2)',
                  'rgba(255, 159, 64, 0.2)',
                ],
                borderColor: [
                  'rgba(255, 99, 132, 1)',
                  'rgba(54, 162, 235, 1)',
                  'rgba(255, 206, 86, 1)',
                  'rgba(75, 192, 192, 1)',
                  'rgba(153, 102, 255, 1)',
                  'rgba(255, 159, 64, 1)',
                ],
                borderWidth: 1,
              },
            ],
          };

          const options = {
            maintainAspectRatio: false,	// Don't maintain w/h ratio
            plugins: {
                tooltip: {
                  callbacks: {
                    title: function (data) {
                        console.log("DATA ", data[0].parsed)
                        const formatted = moment.utc(data[0].parsed*1000).format('HH:mm:ss');
                      return formatted + "ч.";
                    }
                  }
                },
                // legend: { display: false },
                // title: {
                //   display: true,
                //   text: "Test chart",
                //   position: "top"
                // }
              },
          }

        return (
            <div>
                 <h3>{this.props.translations.statistics.top_visited_pages}</h3>
                 
                 <div style={{height: "60vh"}}>
                    <Pie data={data} options={options}/>
                 </div>
                 
                 {/* <PieChart width={600} height={400}>
                    <Pie
                        dataKey="value"
                        isAnimationActive={true}
                        data={data1}
                        cx="50%"
                        cy="50%"
                        outerRadius={100}
                        fill="#8884d8"
                        label={renderCustomizedLabel}
                    >
                        {data1.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} >
                            </Cell>
                        ))}
                    </Pie>
                    <Tooltip />
                    </PieChart> */}

{/* <ResponsiveContainer width="99%" aspect={3}>
                    <PieChart width={600} height={400}>
                        <Pie
                            activeIndex={this.state.activeIndex}
                            activeShape={renderActiveShape}
                            data={data1}
                            cx="50%"
                            cy="50%"
                            innerRadius={90}
                            outerRadius={140}
                            fill="#8884d8"
                            dataKey="value"
                            onMouseEnter={onPieEnter}
                        >
                            {data1.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} >
                            </Cell>
                        ))}
                        </Pie>
                    </PieChart>
</ResponsiveContainer> */}
            </div>
           
            
        );
  }
}

const mapStateToProps = (state, dispatch) => {
    return {
        avgTimeSpentPerPageData: state.statistics.avg_time_spent_per_page || [],
        translations: state.lang,
        currLang: "bg",
    }
};

const mapDispatchToProps = (dispatch, props) => {
    return {
        dispatch,
        ...bindActionCreators({ getAvgTimeSpentPerPage }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AvgTimeSpentPerPagePieChart);
