import React from 'react'

const Filter = (props) => {

    return (
        <div className="container-fluid">
            <div className="animated fadeIn">
                <div className="row">

                    <div className="form-group reservation-list-search col-md-2 col-sm-4 ">
                        <div className="form-group">
                            <label htmlFor="name">{props.translations.common.is_paying}</label>
                            <select className="form-control" name="search_is_paying" defaultValue={props.state.search_is_paying} onChange={props.onChange}>
                                <option key={0} value="">-</option>
                                <option value={'1'}>{props.translations.common.yes}</option>
                                <option value={'0'}>{props.translations.common.no}</option>
                            </select>
                        </div>
                    </div>
                    <div className="form-group reservation-list-search col-md-2 col-sm-4 ">
                        <div className="form-group">
                            <label style={{ display: 'block' }} >&nbsp;</label>
                            <button className="btn btn-secondary" type="button" data-dismiss="modal">{props.translations.common.create}</button>
                        </div>
                    </div>



                </div>
            </div>
        </div>
    )
}

export default Filter;