import React, { Component } from 'react';
import AddEditAlergenForm from './AddEditAlergenForm';
import { addEditAlergen, listAlergens } from '../actions/alergens.action';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { ADMIN_URL } from '../../../constants';
import { addErrorMessages } from '../../messages/messages.actions';

class EditCategoryPage extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			currLang: this.props.currLang,
			id: this.props.item.id,
			name: this.props.item.translations[this.props.currLang] && this.props.item.translations[this.props.currLang].name 
				? this.props.item.translations[this.props.currLang].name : this.props.item.name,
			image: this.props.item.image ? this.props.item.image : '',
		}

		this.onChange = this.onChange.bind(this);
		this.onChangeCheckbox = this.onChangeCheckbox.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
	}

	componentWillMount() {
        this.props.listAlergens();
	}
	
	componentWillReceiveProps(nextProps) {
		if (nextProps.item.id) {

			this.setState({
				id: nextProps.match.params.id,
				name: nextProps.item.translations[this.props.currLang] && nextProps.item.translations[this.props.currLang].name 
					? nextProps.item.translations[this.props.currLang].name : nextProps.item.name,
				image: nextProps.item.image ? nextProps.item.image : '',
			});
		}
	}

	onChange(e) {
		this.setState({ [e.target.name]: e.target.value });
	}

	onChangeCheckbox(e) {
		this.setState({ [e.target.name]: e.target.checked });
	}

	validate() {
		let errors = [];

		if (this.state.name.length === 0) {
			errors.push(this.props.translations.messages.errors.invalid_name);
		}
		return errors;
	}

	onSubmit(e) {
		e.preventDefault();

		const errors = this.validate();
		if (errors.length > 0) {
			this.props.addErrorMessages(errors);
			return;
		}

		const post = {
			id: this.state.id,
			name: this.state.name,
			image: this.state.image,
		}

		this.props.addEditAlergen(post, this.props.history, this.props.languages[this.state.currLang].id);
	}

	deleteAlergen = (e, id) => {
		e.preventDefault();
		this.props.addEditAlergen({ id: id, deleted: true }, this.props.history);
	}

	currLangOnChange = (e) => {
		let newLang = e.target.value;
		let newNameValue = this.props.item.translations[newLang] && this.props.item.translations[newLang].name 
			? this.props.item.translations[newLang].name : "";

		this.setState({
			name: newNameValue,
			currLang: newLang
		})
	}

	render() {
		// let catItem = this.props.cat;

		return (
			<main className="main">

				{/* <!-- Breadcrumb--> */}
				<ol className="breadcrumb">
					<li className="breadcrumb-item"><NavLink to={ADMIN_URL}>{this.props.translations.common.dashboard}</NavLink></li>
					{/* <li className="breadcrumb-item">Меню</li> */}
					<li className="breadcrumb-item"><NavLink to={ADMIN_URL + "alergens"}>{this.props.translations.alergens.title}</NavLink></li>
					<li className="breadcrumb-item active">{this.props.translations.alergens.edit}</li>
					{/* <!-- Breadcrumb Menu--> */}
				</ol>

				<div className="container-fluid">
					<div className="animated fadeIn">
						<div className="row">

							{/* <!-- /.col--> */}
							<div className="col-sm-12">
								<div className="card">

									<AddEditAlergenForm
										onSubmit={this.onSubmit}
										onChange={this.onChange}
										// onChangeCheckbox={this.onChangeCheckbox}
										alergenId={this.state.id}
										alergenName={this.state.name}
										alergenImage={this.state.image}
										isAdding="0"
										deleteAlergen={this.deleteAlergen}
										nameError={this.props.errors.find(error => error.path == 'name') ? this.props.errors.find(error => error.path == 'name') : ""}
										translations={this.props.translations}
										currLang={this.state.currLang}
										currLangOnChange={this.currLangOnChange}
										languages={this.props.languages}
									/>

								</div>
							</div>

						</div>
					</div>
				</div>

			</main>
		)
	}
}

const mapStateToProps = (state, ownProps) => {
	let id = ownProps.match.params.id;

	let item = Object.values(state.alergens.alergens).find(i => i.id == id);
	if (!item) item = {
		id: '',
		name: '',
		image: '',
		translations: {}
	};
	return {
		item: item,
		errors: state.alergens.errors,
		translations: state.lang,
		languages: state.languages.languages || {},
		currLang: "bg",
	}
};

export default connect(mapStateToProps, { addEditAlergen, listAlergens, addErrorMessages })(EditCategoryPage);