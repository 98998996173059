import { LIST_BOOKINGS, LIST_BOOKING_ROOMS } from '../actions/types';

const initialState = {
    bookings: [],
    booking_rooms: []
}

export default function(state = initialState, action) {
    switch(action.type) {
        case LIST_BOOKINGS: {
            return {
                ...state,
                bookings: action.payload,
            }
        }
        case LIST_BOOKING_ROOMS: {
            return {
                ...state,
                booking_rooms: action.payload,
            }
        }
        default:
            return state;
    }
}