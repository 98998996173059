import React from 'react'
import Utils from '../../../utils/Utils';
import BulkFieldUpdateModal from '../list/BulkFieldUpdateModal';
const Filter = (props) => {
    let cats = Utils.getOptionsForAutocompleteSelect(props.categoriesHierarchy, false);
    // let cats = Object.values(props.categoriesHierarchy);
    // cats = cats.filter(c => c.children.length == 0);

    const productFilter = props.productFilter;

    let search_category = productFilter && Object.keys(productFilter).length > 0 && productFilter.search_category ? productFilter.search_category : '';
    let search_name = productFilter && Object.keys(productFilter).length > 0 && productFilter.search_name ? productFilter.search_name : '';
    let search_isLiquid = productFilter && Object.keys(productFilter).length > 0 && productFilter.search_isLiquid ? productFilter.search_isLiquid : '';
    let search_active = productFilter && Object.keys(productFilter).length > 0 && productFilter.search_active ? productFilter.search_active : '';
    let search_ws_code = productFilter && Object.keys(productFilter).length > 0 && productFilter.search_ws_code ? productFilter.search_ws_code : '';

    return (
        <div className="container-fluid">
            <div className="animated fadeIn">
                <div className="row">

                    <div className="form-group reservation-list-search col-md-2 col-sm-4 ">
                        <div className="form-group">
                            <label htmlFor="name">{props.translations.products.title}</label>
                            <input className={`form-control`} id="search_name" type="text" placeholder=""
                                name="search_name" onChange={props.onChange} value={search_name} />
                        </div>
                    </div>

                    <div className="form-group reservation-list-search col-md-2 col-sm-4 ">
                        <div className="form-group">
                            <label htmlFor="name">{props.translations.common.category}</label>
                            <select className="form-control" name="search_category" onChange={props.onChange}>
                                <option key={0} value="">-</option>
                                {(cats).map(s =>
                                    <option key={s.id} value={s.id} selected={s.id == search_category} style={{ color: s.active != 1 ? '#aaa' : 'initial' }} title={s.active != 1 ? 'Inactive/Неактивна' : ''}>{s.name} {s.code ? '(' + s.code + ')' : ''}</option>
                                )}
                            </select>
                        </div>
                    </div>

                    <div className="form-group reservation-list-search col-md-2 col-sm-4 ">
                        <div className="form-group">
                            <label htmlFor="name">{props.translations.common.is_drink}</label>
                            <select className="form-control" name="search_isLiquid" value={search_isLiquid} onChange={props.onChange}>
                                <option key={0} value="">-</option>
                                <option value={1} >{props.translations.common.no}</option>
                                <option value={2} >{props.translations.common.yes}</option>
                            </select>
                        </div>
                    </div>

                    <div className="form-group reservation-list-search col-md-2 col-sm-4 ">
                        <div className="form-group">
                            <label htmlFor="name">{props.translations.common.status}</label>
                            <select className="form-control" name="search_active" value={search_active} onChange={props.onChange}>
                                <option key={0} value="">-</option>
                                <option value={1}>{props.translations.common.inactive}</option>
                                <option value={2}>{props.translations.common.active}</option>
                            </select>
                        </div>
                    </div>

                    <div className="form-group reservation-list-search col-md-2 col-sm-4 ">
                        <div className="form-group">
                            <label htmlFor="name">{props.translations.common.ws_code}</label>
                            <select className="form-control" name="search_ws_code" value={search_ws_code} onChange={props.onChange}>
                                <option key={0} value="">-</option>
                                <option value={1}>{props.translations.common.with_ws_code}</option>
                                <option value={2}>{props.translations.common.without_ws_code}</option>
                            </select>
                        </div>
                    </div>

                    <div className="form-group reservation-list-search col-md-2 col-sm-4 ">
                        <div className="form-group">
                            <label>&nbsp;</label>
                            <BulkFieldUpdateModal
                                translations={props.translations}
                                productIdsToUpdate={Object.values(props.prodItems).map(p => p.id)}
                            />
                        </div>
                    </div>

                    {search_category || search_name || search_isLiquid || search_active ?
                        <div className="c-pointer" onClick={props.clearFilter}>
                            <i className="nav-icon icon-close" style={{
                                color: '#db0000',
                                'font-size': 20
                            }}></i>
                        </div>
                        :
                        null
                    }


                </div>
            </div>
        </div >
    )
}

export default Filter;