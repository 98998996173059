import { LIST_ORDERS, LIST_USER_NEEDS, LOADING_ORDERS_START, LOADING_ORDERS_END } from '../actions/types';

const initialState = {
    orders: [],
    user_needs: [],
    loading_orders: false,
}

export default function (state = initialState, action) {
    switch (action.type) {
        case LIST_ORDERS:
            return {
                ...state,
                orders: action.payload
            }
        case LIST_USER_NEEDS:
            return {
                ...state,
                user_needs: action.payload
            }

        case LOADING_ORDERS_START:
            return {
                ...state,
                loading_orders: true
            }

        case LOADING_ORDERS_END:
            return {
                ...state,
                loading_orders: false
            }

        default:
            return state;
    }
}