import axios from 'axios';
import { LIST_REGISTERED_USER_DISCOUNT } from './types';
import { API_URL, ADMIN_URL } from './../../../constants';
import { addSuccessMessage, addErrorMessages } from './../../messages/messages.actions';
import Auth from './../../../utils/Auth';
import { showLoading, hideLoading } from './../../common/loading/loading.actions';

export const getRegisteredUserDiscount = (place_id = 0) => dispatch => {
    let getParam = '';
    if(place_id == 0) {
        place_id = Auth.getUserPlace();
        getParam += "?place_id=" + place_id;
    }

    axios
        .get(API_URL + 'registered-user-discount' + getParam)
        .then(res => {
            dispatch({
                type: LIST_REGISTERED_USER_DISCOUNT,
                payload: res.data
            });
        })
        .catch(error => {
            dispatch(addErrorMessages(error))
        });
}


export const addEditRegisteredUserDiscount = (data) => dispatch => {
    if(!data.placeId) {
        data.placeId = Auth.getUserPlace();
    }

    dispatch(showLoading());

    axios
        .post(API_URL + 'registered-user-discount', data)
        .then((post) => {
            post = post.data;
            
            dispatch(addSuccessMessage("Changes saved"))
            dispatch(getRegisteredUserDiscount());
            // if(post.deleted) {
            //     history.push("/admin/regions");
            //     dispatch(addSuccessMessage("Successfully deleted row"))
            //     dispatch(hideLoading());
            // } else {
            //     history.push("/admin/regions/edit/"+post.id);                
                dispatch(hideLoading());
            // }


            // }
        })
        .catch(function (error) {
                dispatch(hideLoading());
                // dispatch(addErrorMessages('Error occured'));
        })
}