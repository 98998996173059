import React, { Component } from 'react'
import AddEditIngredientForm from './AddEditIngredientForm';
import { addEditIngredient } from './../actions/ingredients.actions';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { ADMIN_URL, TRANSLATABLE_FIELDS } from './../../../constants';
import Utils from './../../../utils/Utils';

let md5 = require('md5');

class EditIngredientPage extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			id: this.props.match.params.id,
			// name: this.props.ingredient.name ? this.props.ingredient.name : '',
			name: this.props.ingredient.translations[this.props.currLang] && this.props.ingredient.translations[this.props.currLang].name || this.props.ingredient.name,
			price: this.props.ingredient.price ? this.props.ingredient.price : '',
			active: this.props.ingredient.active ? this.props.ingredient.active : 0,
			currLang: this.props.currLang,
		}

		this.onChange = this.onChange.bind(this);
		this.onChangeCheckbox = this.onChangeCheckbox.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.ingredient.id) {
			this.setState({
				id: nextProps.match.params.id,
				// name: nextProps.ingredient.name ? nextProps.ingredient.name : '',
				name: nextProps.ingredient.translations[this.state.currLang] && nextProps.ingredient.translations[this.state.currLang].name || nextProps.ingredient.name,
				price: nextProps.ingredient.price ? nextProps.ingredient.price : '',
				active: nextProps.ingredient.active ? nextProps.ingredient.active : 0,
			});
		}
	}

	onChange(e) {
		this.setState({ [e.target.name]: e.target.value });
	}

	onChangeCheckbox(e) {
		this.setState({ [e.target.name]: e.target.checked });
	}

	onSubmit(e) {
		e.preventDefault();

		// return;
		const post = {
			id: this.state.id,
			name: this.state.name,
			price: this.state.price, //  md5(md5(this.state.ingredient_num + (this.state.currentDate))),
			active: this.state.active,
		}

		this.props.addEditIngredient(post, this.props.history, this.props.languages[this.state.currLang].id);
	}

	deleteIngredient = (e, id) => {
		e.preventDefault();
		let data = {
			id: id,
			deleted: true
		};
		this.props.addEditIngredient(data);
		Utils.navigateTo(ADMIN_URL + 'ingredients')
	}

	currLangOnChange = (e) => {
		
        let newLang = e.target.value;

		let newState = {
			currLang: e.target.value
		};

		TRANSLATABLE_FIELDS.INGREDIENT.map(field => {
			newState[field] = this.props.ingredient.translations[newLang] && this.props.ingredient.translations[newLang][field] ? this.props.ingredient.translations[newLang][field] : "";
		})

		this.setState(newState)
    }

	render() {

		return (
			<main className="main">

				{/* <!-- Breadcrumb--> */}
				<ol className="breadcrumb">
					<li className="breadcrumb-item"><NavLink to={ADMIN_URL}>{this.props.translations.common.dashboard}</NavLink></li>
					{/* <li className="breadcrumb-item">Меню</li> */}
					<li className="breadcrumb-item"><NavLink to={ADMIN_URL + "ingredients"}>{this.props.translations.ingredients.title}</NavLink></li>
					<li className="breadcrumb-item active">{this.props.translations.ingredients.edit}</li>
					{/* <!-- Breadcrumb Menu--> */}
					<li className="breadcrumb-menu d-md-down-none">
						<div className="btn-group" role="group" aria-label="Button group">

						</div>
					</li>
				</ol>

				<div className="container-fluid">
					<div className="animated fadeIn">
						<div className="row">

							{/* <!-- /.col--> */}
							<div className="col-sm-12">
								<div className="card">

									<AddEditIngredientForm
										onSubmit={this.onSubmit}
										onChange={this.onChange}
										onChangeCheckbox={this.onChangeCheckbox}
										ingredient={this.state}
										deleteIngredient={this.deleteIngredient}
										actionText="Запази"
										isAdding="0"
										translations={this.props.translations}
										currLangOnChange={this.currLangOnChange}
										currLang={this.state.currLang}
										languages={this.props.languages}
									/>
								</div>
							</div>

						</div>
					</div>
				</div>

			</main>
		)
	}
}

const mapStateToProps = (state, ownProps) => {
	let ingredientId = ownProps.match.params.id;

	let ingredient = state.ingredients.ingredients[ingredientId];

	if (!ingredient) ingredient = {
		id: '',
		name: '',
		price: '',
		active: '',
		translations: {
            bg: {
                name: "",
			},
            en: {
                name: "",
            }
        },
	};
	return {
		ingredient: ingredient,
		// errors: state.ingredients.errors,
		translations: state.lang,
		languages: state.languages.languages || {},
		currLang: state.settings.settings['default_lang'] && state.settings.settings['default_lang'].value || 'bg',

	}


};

export default connect(mapStateToProps, { addEditIngredient })(EditIngredientPage);