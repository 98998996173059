import React, { Component } from 'react'
import { connect } from 'react-redux';
import { addCategoryHierarchy } from '../../actions/categoriesHierarchyActions';
import AddEditCategoryHierarchyForm from './AddEditCategoryHierarchyForm';
import { NavLink } from 'react-router-dom';
import { ADMIN_URL } from '../../constants';
import { addErrorMessages } from './../messages/messages.actions';
import Auth from './../../utils/Auth';
import Utils from '../../utils/Utils';
import moment from 'moment';

class AddCategoryHierarchyPage extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			currLang: this.props.currLang,
			name: '',
			description: '',
			icon: '',
			background_image: '',
			parentId: null,
			type: 'default',
			links: '',
			code: '',
			open_call_waiter: 0,
			active: 1,
			activeDaysOfWeek: { "1": 1, "2": 1, "3": 1, "4": 1, "5": 1, "6": 1, "7": 1 },
			activeStartTime: "00:00:00",
			activeEndTime: "23:59:59",
			currPlaces: [],
		}

		// this.onChange = this.onChange.bind(this);
		// this.onChangeCheckbox = this.onChangeCheckbox.bind(this);
		// this.onSubmit = this.onSubmit.bind(this);
	}

	componentDidMount() {
		if (this.props.places.length > 0) {
			let currPlaces = [];
			let currPlace = this.props.places.find(p => p.id == Auth.getUserPlace());
			let currPlaceName = currPlace ? currPlace.name : "";
			currPlaces.push({ value: parseInt(Auth.getUserPlace()), label: currPlaceName })

			this.setState({ currPlaces: currPlaces })
		}
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.places.length > 0) {
			let currPlaces = [];
			let currPlace = nextProps.places.find(p => p.id == Auth.getUserPlace());
			let currPlaceName = currPlace ? currPlace.name : "";
			currPlaces.push({ value: parseInt(Auth.getUserPlace()), label: currPlaceName })

			this.setState({ currPlaces: currPlaces })
		}

		this.setState({
			name: '',
			description: '',
			icon: '',
			background_image: '',
			parentId: '',
			type: 'default',
			links: '',
			active: 1,
			open_call_waiter: 0,
		});
	}

	onCKEditorChange = (e) => {
		this.setState({
			description: e.editor.getData()
		});
	}

	onChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
	}

	removeLink = () => {
		let stateLinks = this.state.links && Object.values(this.state.links).length > 0 ? JSON.parse(this.state.links) : [];

		if (stateLinks && Object.values(stateLinks).length > 0 && stateLinks[Object.values(stateLinks).length - 1]) {
			stateLinks.splice(Object.values(stateLinks).length - 1, 1);
			this.setState({ links: JSON.stringify(stateLinks) })
		}
	}

	onChangeCheckbox = (e) => {
		this.setState({ [e.target.name]: e.target.checked });
	}

	onChangeLink = (e, i) => {
		let target = e.target;
		/*d*/
		this.setState(state => {
			let stateLinks = state.links && Object.values(state.links).length > 0 ? JSON.parse(state.links) : [];

			if (!stateLinks[i]) {
				stateLinks[i] = {}
			}

			stateLinks[i][target.name] = target.value;
			return ({ links: JSON.stringify(stateLinks) })
		});
	}

	onChangeCheckboxActiveDaysOfWeek = (e) => {
		let currActiveDaysOfWeek = this.state.activeDaysOfWeek;
		currActiveDaysOfWeek[e.target.name] = +e.target.checked;
		this.setState({ currActiveDaysOfWeek });
	}

	onChangeSelectAutocompleteCategory = (valueLabel) => {
		this.setState({ 'parentId': valueLabel.value });
	}

	onChangeSelectAutocompleteType = (valueLabel) => {
		this.setState({ 'type': valueLabel.value });
	}

	onChangeSelectAutocompletePlaces = (valueLabel) => {
		this.setState({ 'currPlaces': valueLabel });
	}

	onFieldChange = (fieldName, value) => {
		this.setState({ [fieldName]: value });
	}

	validate() {
		let errors = [];

		if (this.state.name.length === 0) {
			errors.push(this.props.translations.messages.errors.invalid_name);
		}
		if (this.state.currPlaces.length === 0) {
			errors.push(this.props.translations.messages.errors.invalid_place);
		}
		return errors;
	}

	onSubmit = (e) => {
		e.preventDefault();

		const errors = this.validate();
		// console.log(errors);
		if (errors.length > 0) {
			this.props.addErrorMessages(errors);
			return;
		}

		let placesIds = this.state.currPlaces.map(c => c.value);

		const post = {
			name: this.state.name,
			description: this.state.description,
			code: this.state.code,
			icon: this.state.icon,
			background_image: this.state.background_image,
			active: this.state.active,
			open_call_waiter: this.state.open_call_waiter,
			parentId: this.state.parentId,
			type: this.state.type,
			links: this.state.links,
			activeDaysOfWeek: this.state.activeDaysOfWeek,
			activeStartTime: this.state.activeStartTime,
			activeEndTime: this.state.activeEndTime,
			placesIds: placesIds,
			rotation_start_date: moment(this.state.rotation_start_date).format('YYYY-MM-DD'),
			rotation_days_count: this.state.rotation_days_count,
			show_cat_only_for_spot_orders: this.state.show_cat_only_for_spot_orders,
			hide_cat_only_for_spot_orders: this.state.hide_cat_only_for_spot_orders,
			hide_cat_only_for_delivery_orders: this.state.hide_cat_only_for_delivery_orders,
			show_cat_only_for_delivery_orders: this.state.show_cat_only_for_delivery_orders,
		}

		// Image append
		const files = Array.from(document.querySelector('#multi').files);
		let formData = new FormData()

		files.forEach((file, i) => {
			formData.append("file", file);
		})

		if(Auth.hasAccess("SUPER_ADMIN")) {
			// Image append
			const files1 = Array.from(document.querySelector('#multi1').files)

			files1.forEach((file, i) => {
				formData.append("file_bckg", file)
			})

			if (files.length == 0 && files1.length == 0) {
				formData = null;
			}
		} else {
			if (files.length == 0) {
				formData = null;
			}
		}

		this.props.addCategoryHierarchy(post, this.props.history, formData, this.props.languages[this.state.currLang].id);
	}

	render() {
		return (
			<main className="main">

				{/* <!-- Breadcrumb--> */}
				<ol className="breadcrumb">
					<li className="breadcrumb-item"><NavLink to={ADMIN_URL}>{this.props.translations.common.dashboard}</NavLink></li>
					{/* <li className="breadcrumb-item">Меню</li> */}
					<li className="breadcrumb-item"><NavLink to={ADMIN_URL + "categories-hierarchy"}>{this.props.translations.categories.title}</NavLink></li>
					<li className="breadcrumb-item active">{this.props.translations.categories.add}</li>
					{/* <!-- Breadcrumb Menu--> */}
					<li className="breadcrumb-menu d-md-down-none">
						<div className="btn-group" role="group" aria-label="Button group">
							{/* 
                            <!--<a className="btn" href="./">
                                <i className="icon-graph"></i>  Dashboard</a>
                            <a className="btn" href="#">
                                <i className="icon-settings"></i>  Settings</a>
                            --> 
                            */}
						</div>
					</li>
				</ol>

				<div className="container-fluid">
					<div className="animated fadeIn">
						<div className="row">

							{/* <!-- /.col--> */}
							<div className="col-sm-12">
								<div className="card">

									<AddEditCategoryHierarchyForm
										onSubmit={this.onSubmit}
										onChange={this.onChange}
										onChangeLink={this.onChangeLink}
										onCKEditorChange={this.onCKEditorChange}
										onChangeCheckbox={this.onChangeCheckbox}
										onChangeSelectAutocompleteCategory={this.onChangeSelectAutocompleteCategory}
										onChangeSelectAutocompleteType={this.onChangeSelectAutocompleteType}
										onChangeCheckboxActiveDaysOfWeek={this.onChangeCheckboxActiveDaysOfWeek}
										onChangeSelectAutocompletePlaces={this.onChangeSelectAutocompletePlaces}
										onFieldChange={this.onFieldChange}
										category={this.state}
										type={this.state.type}
										links={this.state.links}
										isAddingCategory="1"
										translations={this.props.translations}
										currLang={this.state.currLang}
										languages={this.props.languages}
										categoriesHierarchy={this.props.categoriesHierarchy}
										places={this.props.places}
									/>

								</div>
							</div>

						</div>
					</div>
				</div>

			</main>
		)
	}
}

const mapStateToProps = state => {
	return {
		categoriesHierarchy: state.categoriesHierarchy.categories || [],
		errors: state.categoriesHierarchy.errors,
		translations: state.lang,
		languages: state.languages.languages || {},
		currLang: state.settings.settings['default_lang'] && state.settings.settings['default_lang'].value || "bg",
		places: state.places.places || [],
	}
};

export default connect(mapStateToProps, { addCategoryHierarchy, addErrorMessages })(AddCategoryHierarchyPage);