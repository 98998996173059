import React from 'react'
import { NavLink } from 'react-router-dom';
import { ADMIN_URL, IMG_PATH } from '../../constants.js'
import DeleteCategoryModal from './DeleteCategoryModal.js';
import Utils from './../../utils/Utils';
import Auth from '../../utils/Auth.js';

const ListEachCategory = (props) => (
    <tr key={props.category.id}>
        <td>
            {/* If not added translation for the defailt language */}
            {props.category.translations[props.currLang] && props.category.translations[props.currLang].name
                ? props.category.translations[props.currLang].name : props.category.name}
            {/* {props.category.name} */}
        </td>
        <td>
            {props.category.icon ? <img style={{ maxHeight: 100 }} src={Utils.getCategoryImagePath(props.category.icon)} /> : ""}
            {/* {props.category.icon} */}
        </td>
        <td>
            {props.category.active ? <span className="badge badge-success">{props.translations.common.active}</span> : <span className="badge badge-secondary">{props.translations.common.inactive}</span>}
        </td>
        <td>
            <NavLink to={ADMIN_URL + 'categories/edit/' + props.category.id}>
                <button className="btn btn-pill btn-link" type="button">
                    <i className="fas fa-edit"></i>
                </button>
            </NavLink>

            <button className="btn btn-pill btn-link" type="button" data-toggle="modal" data-target={"#dangerModal" + props.category.id} >
                <i className="fas fa-trash"></i>
            </button>

            {window.location.host == 'bulgarche.tabl.bg' || window.location.host == 'localhost:3000' || Auth.getUserPlace == 10 ?
                <NavLink to={ADMIN_URL + 'generate-shareable-image/category/' + props.category.id}>
                    <button className="btn btn-pill btn-link" type="button">
                        <i className="fa fa-facebook"></i>
                    </button>
                </NavLink>
                :
                null
            }

            <DeleteCategoryModal
                categoryId={props.category.id}
                categoryName={props.category.name}
                deleteCategory={props.deleteCategory}
                translations={props.translations}
            />

        </td>
    </tr>
)

export default ListEachCategory;