import { combineReducers } from 'redux';
import { GET_DELIVERY_SHAPE } from '../actions/types';

const initialState = {}

export default function(state = initialState, action) {
    switch(action.type) {
        case GET_DELIVERY_SHAPE: {
            return action.payload
        }
        default:
            return state;
    }
}