import axios from 'axios';
import { LIST_TABLES, LIST_TABLES_ERROR, DELETE_TABLE_ERROR, ADD_TABLE_ERROR, EDIT_TABLE_ERROR, DELETE_TABLE_ERROR_LISTPAGE, 
    ADD_TABLE_SUCCESS, EDIT_TABLE_SUCCESS, DELETE_TABLE_SUCCESS,
    ADD_TABLE_GROUP_SUCCESS, ADD_TABLE_GROUP_ERROR, REMOVE_TABLE_GROUP_SUCCESS, REMOVE_TABLE_GROUP_ERROR,
    CLEAR_MESSAGES, ADD_ERROR_MSGS} from './types';
import { RESERVATIONS_API_URL, ADMIN_URL, API_TOKEN } from '../../../constants';

import { push } from 'connected-react-router'

const url_group = 'table/';
const headers = { headers: { Authorization: API_TOKEN } };

const listingUrl = 'reservations-tables/';
const customErrorMsg = "Възникна грешка #";

const errorHandler = (error) => {
    let errorCode = 0;
            
    // https://gist.github.com/fgilio/230ccd514e9381fafa51608fcf137253
    if (error.response) {
        errorCode = 1;
        console.log(error.response.data); //DO NOT REMOVE
        console.log(error.response.status); //DO NOT REMOVE
        console.log(error.response.headers); //DO NOT REMOVE
        /*
         * The request was made and the server responded with a
         * status code that falls out of the range of 2xx
        */
    } else if (error.request) {
        errorCode = 2;
         /*
         * The request was made but no response was received, `error.request`
         * is an instance of XMLHttpRequest in the browser and an instance
         * of http.ClientRequest in Node.js
         */
        console.log(error.request); //DO NOT REMOVE
    } else {
        errorCode = 3;
        // Something happened in setting up the request and triggered an Error
        console.log('Error', error.message); //DO NOT REMOVE
    }

    return customErrorMsg + errorCode;
}

export const listReservationTables = () => dispatch => {
    axios
    .get(RESERVATIONS_API_URL + url_group + 'read?admin=1', headers)
    .then(res => {
        console.log("TABLE ACTION SUCCESS", res.data)
        dispatch({
            type: LIST_TABLES,
            payload: res.data
        })
    }).catch(error => {
        console.log("TABLE ACTION ERROR", error)    
        
        dispatch({
            type: LIST_TABLES_ERROR,
            payload: (error.response && error.response.data) ? error.response.data : {message: errorHandler(error)}
        })
    });
}

export const addTable = (postData) => dispatch => {
    axios
    .post(RESERVATIONS_API_URL + url_group + 'create', postData)
    .then(function (res) {
        // console.log('res', res)
        dispatch({
            type: ADD_TABLE_SUCCESS,
            payload: res.data
        })
        dispatch(push(ADMIN_URL + listingUrl))
    })
    .catch(function (error) {
        // console.log('error', error)
        dispatch({
            type: ADD_TABLE_ERROR,
            payload: (error.response && error.response.data) ? error.response.data : {message: errorHandler(error)}
        })
    });
}

//TODO add headers
export const removeTable = (data) => dispatch => {
    axios
    .post(RESERVATIONS_API_URL + url_group + 'delete', data)
    .then(function(res) {
        // console.log('res', res)
        dispatch({
            type: DELETE_TABLE_SUCCESS,
            payload: res.data
        })
        dispatch(listReservationTables());

        dispatch(push(ADMIN_URL + listingUrl))
    })
    .catch(error => {
        // console.log('error', error)
        dispatch(push(ADMIN_URL + listingUrl))

        // if (history) {
        //     dispatch({
        //         type: DELETE_TABLE_ERROR,
        //         payload: (error.response && error.response.data) ? error.response.data : {message: errorHandler(error)}
        //     })    
        // } else { 
        //     dispatch({
        //         type: DELETE_TABLE_ERROR_LISTPAGE,
        //         payload: (error.response && error.response.data) ? error.response.data : {message: errorHandler(error)}
        //     })            
        // }
    });
}

//TODO add headers
export const editTable = (postData) => dispatch => {
    axios
    .post(RESERVATIONS_API_URL + url_group + 'update', postData)
    .then(function (res) {
        dispatch(listReservationTables());

        dispatch({
            type: EDIT_TABLE_SUCCESS,
            payload: res.data
        })
        // dispatch(push(ADMIN_URL + listingUrl))
    })
    .catch(function (error) {
        console.log("DISPATCH EDIT TABLE ERR")
        dispatch({
            type: EDIT_TABLE_ERROR,
            payload: (error.response && error.response.data) ? error.response.data : {message: errorHandler(error)}
        })
    });

}

export const addTableGroup = (data) => dispatch => {
    axios
    .post(RESERVATIONS_API_URL + url_group + 'add_table_group', data)
    .then(function (res) {
        dispatch(listReservationTables());

        dispatch({
            type: ADD_TABLE_GROUP_SUCCESS,
            payload: res.data
        })
        // dispatch(push(ADMIN_URL + listingUrl))
    })
    .catch(function (error) {
        dispatch(clearMessages());
        
        dispatch({
            type: ADD_TABLE_GROUP_ERROR,
            payload: (error.response && error.response.data) ? error.response.data : {message: errorHandler(error)}
        })
    });
}

export const removeTableGroup = (data) => dispatch => {
    axios
    .post(RESERVATIONS_API_URL + url_group + 'remove_table_group', data)
    .then(function (res) {
        dispatch(listReservationTables());

        dispatch({
            type: REMOVE_TABLE_GROUP_SUCCESS,
            payload: res.data
        })
        // dispatch(push(ADMIN_URL + listingUrl))
    })
    .catch(function (error) {
        dispatch({
            type: REMOVE_TABLE_GROUP_ERROR,
            payload: (error.response && error.response.data) ? error.response.data : {message: errorHandler(error)}
        })
    });
}

export const clearMessages = () => dispatch => {
    dispatch({
        type: CLEAR_MESSAGES
    })
}

export const addErrorMessages = (msgs) => dispatch => {
    dispatch({
        type: ADD_ERROR_MSGS,
        payload: msgs
    })
}
