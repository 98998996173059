import React from 'react'
import { NavLink } from 'react-router-dom';
import { ADMIN_URL, IMG_PATH } from '../../constants.js'
import DeleteCategoryHierarchyModal from './DeleteCategoryHierarchyModal.js';
import Utils from './../../utils/Utils';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

const ListEachCategoryHierarchy = (props) => (
    <tr key={props.category.id}>
        <td>
            {/* If not added translation for the defailt language */}
            {props.category.translations[props.currLang] && props.category.translations[props.currLang].name
                ? props.category.translations[props.currLang].name : props.category.name} {props.category.code ? <i>({props.category.code})</i> : ''}
            {/* {props.category.name} */}
        </td>
        <td>
            {props.category.parentId && props.allCategories[props.category.parentId] ? props.allCategories[props.category.parentId].name : "-"} <i>{props.allCategories[props.category.parentId] && props.allCategories[props.category.parentId].code ? '(' + props.allCategories[props.category.parentId].code + ')' : ''}</i>
        </td>
        <td>
            {props.category.icon ? <img style={{ maxHeight: 100 }} src={Utils.getCategoryImagePath(props.category.icon)} /> : ""}
            {/* {props.category.icon} */}
        </td>
        <td>
            {/* {props.category.active ? <span className="badge badge-success">{props.translations.common.active}</span> : <span className="badge badge-secondary">{props.translations.common.inactive}</span>} */}
            <div className="col-md-2 col-form-label">
                <label className="switch switch-label switch-pill switch-outline-primary-alt">
                    <input className="switch-input" type="checkbox" name="active" onChange={(e) => props.onChangeCheckboxActive(e, props.category.id)} checked={props.category.active} />
                    <span className="switch-slider" data-checked={props.translations.common.yes} data-unchecked={props.translations.common.no}></span>
                </label>
            </div>
        </td>
        <td>
            <input type='text'
                onChange={(ev) => props.onPriorityChange(ev, props.category.id)}
                onBlur={(ev) => props.updatePriority(ev, props.category.id)}
                value={props.priority} className="form-control"
                style={{ maxWidth: 60 }}
            />
        </td>
        <td>
            <div className="row">
                <div className="col">
                    <input type='text'
                        onChange={(ev) => props.onDiscountChange(ev, props.category.id)}
                        onBlur={(ev) => props.updateDiscountPercent(ev, props.category.id)}
                        value={props.discount}
                        className="form-control"
                        style={{ maxWidth: 60 }}
                    />
                </div>
                <div className="col">
                    %
                </div>
            </div>

        </td>
        <td>
            {/* onChangeCheckboxOrderAllowed */}
            <div className="col-md-2 col-form-label">
                <label className="switch switch-label switch-pill switch-outline-primary-alt">
                    <input className="switch-input" type="checkbox" name="order_allowed" onChange={(e) => props.onChangeCheckboxOrderAllowed(e, props.category.id)} checked={props.category.order_allowed} />
                    <span className="switch-slider" data-checked={props.translations.common.yes} data-unchecked={props.translations.common.no}></span>
                </label>
            </div>
        </td>
        <td>
            <NavLink to={ADMIN_URL + 'categories-hierarchy/edit/' + props.category.id}>
                <button className="btn btn-pill btn-link" type="button">
                    <i className="fas fa-edit"></i>
                </button>
            </NavLink>

            <button className="btn btn-pill btn-link" type="button" data-toggle="modal" data-target={"#dangerModal" + props.category.id} >
                <i className="fas fa-trash"></i>
            </button>

            {Utils.hasFunctionalityModule('lunch_menu') ?
                <NavLink to={ADMIN_URL + 'generate-shareable-image/category/' + props.category.id}>
                    <button className="btn btn-pill btn-link" type="button">
                        <i className="fa fa-facebook"></i>
                    </button>
                </NavLink>
                :
                null
            }

            <DeleteCategoryHierarchyModal
                categoryId={props.category.id}
                categoryName={props.category.name}
                deleteCategory={props.deleteCategory}
                translations={props.translations}
            />

        </td>
    </tr>
)

export default ListEachCategoryHierarchy;