import axios from 'axios';
import { LIST_SUBCATEGORIES, ADD_EDIT_SUBCATEGORY_ERROR, ADD_EDIT_SUBCATEGORY_TRANSLATION_ERROR } from './types';
import { API_URL, ADMIN_URL } from './../constants'
import { addErrorMessages } from './../components/messages/messages.actions';

export const listSubcategories = () => dispatch => {
    axios
        .get(API_URL + 'subcategoriesWithProducts')
        .then(res =>
            dispatch({
                type: LIST_SUBCATEGORIES,
                payload: res.data
            })
        ).catch(error => {
            throw (error);
        });
}

export const addSubcategory = (postData, history, imageData, langId) => dispatch => {
    axios.post(API_URL + 'subcategories', postData)
        .then(
            function (post) {
                post = post.data;
                dispatch(addEditSubcatTranslation(post.id, langId, postData));

                dispatch(addImage(imageData, post.id, history));
            }
        )
        .catch(function (error) {
            dispatch(addErrorMessages(error.response.data.error.errors));
        });
}


export const addEditSubcatTranslation = (subcat_id, lang_id, data) => dispatch => {
    axios
        .post(API_URL + `subcategories/${subcat_id}/translations/${lang_id}`, data)
        .then(res => {

        })
        .catch(err => {

        })
}

// export const addSubcategoryTranslation = (subcategoryId, languageId, postData, history) => dispatch => {
//     //Add variant translation
//     axios.post(API_URL + 'subcategories/translations/' + subcategoryId + '/' + languageId, postData)
//         .then(
//             function (post) {
//                 if (post.error) {
//                     dispatch({
//                         type: ADD_EDIT_SUBCATEGORY_TRANSLATION_ERROR,
//                         payload: post.error.errors
//                     })
//                 } else {
//                     history.push(ADMIN_URL + 'subcategories');
//                 }
//             }
//         ).catch(function (e) {
//         });
// }


export const addImage = (file, subcatId, history) => dispatch => {
    if (file == null) {
        history.push(ADMIN_URL + 'subcategories');
        return;
    }

    axios
        .post(API_URL + `subcategories/${subcatId}/upload`, file)
        .then(function (res) {
            if (res.error) {
                //TODO image not uploaded Toastr
            }
            else {
                // history.push(ADMIN_URL + 'products_admin');
            }
            history.push(ADMIN_URL + 'subcategories');
        })
        .catch(e =>
            history.push(ADMIN_URL + 'subcategories')
        )
}

export const editSubcategory = (postData, history, imageData, langId) => dispatch => {
    axios
        .put(API_URL + 'subcategories/' + postData.id, postData)
        .then(
            function (post) {
                post = post.data;
                dispatch(addEditSubcatTranslation(post.id, langId, postData));

                dispatch(addImage(imageData, postData.id, history));
            }
        )
        .catch(function (error) {
            dispatch(addErrorMessages(error.response.data.error.errors));
        });
}

export const removeSubcategory = (id, history) => dispatch => {
    axios.put(API_URL + 'subcategories/' + id, { deleted: true })
        .then(
            function (res) {
                if (history) {
                    // Deleting from /edit
                    history.push(ADMIN_URL + "subcategories")
                } else {
                    // Deleting from /list
                    dispatch(listSubcategories())
                }
            }
        )
        .catch(function (e) {
        });
}
