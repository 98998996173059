import React from 'react'
import { NavLink } from 'react-router-dom';
import { ADMIN_URL } from '../../../constants.js'
import DeleteModal from './../../common/DeleteModal';
import { Link } from 'react-router-dom';
import MessagesContainer from './../../messages/MessagesContainer';
import Utils from './../../../utils/Utils';
import Select from 'react-select'
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
// let md5 = require('md5');
import CKEditorComponent from "react-ckeditor-component";

const AddEditGeneralPromotionForm = (props) => {
    console.log("oooooooooo", props.promotion)

    return (

    // <form onSubmit={props.onSubmit}>
    <form>
						
        <div className="card-body">
            <MessagesContainer/>	

            <div className="row">
                <div className="col-sm-12">
                    <div className="form-group">
                        <label htmlFor="name">{props.translations.general_promotions.name}</label>
                        <input className={`form-control`} id="name" type="text" placeholder={props.translations.general_promotions.name} name="name" onChange={props.onChange} value={props.promotion.name}/>
                    </div>
                </div>
             
                <div className="col-sm-12">
                    <div className="form-group">
                        <label htmlFor="link">{props.translations.general_promotions.link}</label>
                        <input className={`form-control`} id="link" type="text" placeholder={props.translations.general_promotions.link} name="link" onChange={props.onChange} value={props.promotion.link}/>
                    </div>
                </div>

                <div className="col-sm-12">
                    <div className="form-group">
                        <label htmlFor="name">{props.translations.general_promotions.name}</label>

                        <CKEditorComponent
                            content={props.promotion.content} 
                            events={{
                                // "blur": this.onBlur,
                                // "afterPaste": this.afterPaste,
                                "change": (e) => props.onCKEditorChange('content', e)
                            }}
                            config={{
                                extraAllowedContent: 'div(*)',
                                allowedContent: true
                            }}
                        />
                    </div>
                </div>

                <div className="col-md-6 vertical-center" style={{ marginBottom: 13 }}>
                    <label className="col-form-label" >
                        {props.translations.general_promotions.valid_from}
                    </label>
                    <DatePicker
                        className="form-control vertical-center"
                        value={props.promotion.active_from ? moment(props.promotion.active_from).format('DD-MM-YYYY') : ''}
                        onChange={date => props.onFieldChange('active_from', date)}
                        dateFormat="d-M-Y"
                        style={{ display: 'block' }}
                    />
                </div>

                <div className="col-md-6 vertical-center" style={{ marginBottom: 13 }}>
                    <label className="col-form-label" >
                        {props.translations.general_promotions.active_to}
                    </label>
                    <DatePicker
                        className="form-control vertical-center"
                        value={props.promotion.active_to ? (new Date(props.promotion.active_to).getDate() + '-' + (new Date(props.promotion.active_to).getMonth() + 1) + '-' + new Date(props.promotion.active_to).getFullYear()) : ''}
                        onChange={date => props.onFieldChange('active_to', date)}
                        dateFormat="d-M-Y"
                        style={{ display: 'block' }}
                    />
                </div>

                <label className="col-md-1 col-form-label">{props.translations.common.active}</label>
                <div className="col-md-11 col-form-label">
                    <label className="switch switch-label switch-pill switch-outline-primary-alt">
                        <input className="switch-input" type="checkbox"  name="active" onChange={props.onChangeCheckbox} checked={props.promotion.active}/>
                        <span className="switch-slider" data-checked={props.translations.common.yes} data-unchecked={props.translations.common.no}></span>
                    </label>
                </div>
            </div>
        
            
            <hr/>
            <br/>

            <div className="form-actions">
                {props.isAdding == 1 ? '' : <button className="btn btn-danger mright10" type="button" data-toggle="modal" data-target={"#dangerModal" + props.promotion.id} > {props.translations.common.delete}</button>}
            
                <DeleteModal
                    id={props.promotion.id}
                    title={props.promotion.name}
                    deleteFunc={props.deleteFunc}
                    translations={props.translations}
                />

                <NavLink to={ADMIN_URL+'general-promotions'}>
                    <button className="btn btn-secondary" type="button">
                        {props.translations.common.cancel}
                    </button>
                </NavLink>

                <button className="fright btn btn-primary" type="submit" onClick={props.onSubmit}>
                    {props.isAdding == 1 ? props.translations.common.add : props.translations.common.save}
                </button>

            </div>

        </div>

    </form>
)}

export default AddEditGeneralPromotionForm;