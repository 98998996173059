import React from 'react'
import { NavLink } from 'react-router-dom';
import { ADMIN_URL } from '../../constants.js'
import Auth from '../../utils/Auth.js';
import Utils from '../../utils/Utils';
import DeleteModal from './../common/DeleteModal';

const ListEachTable = (props) => {

    let promocodePlaceName = '';
    if (props.places && props.promocode.placeId) {
        promocodePlaceName = props.places.find(p => p.id == props.promocode.placeId);
        if (promocodePlaceName && promocodePlaceName.name) {
            promocodePlaceName = promocodePlaceName.name;
        }
    }

    return (
        <tr key={props.promocode.id}>
            <td>
                {props.promocode.code}
            </td>
            <td>
                {props.promocode.discount_value} {props.promocode.discount_type == 'percent' ? '%' : props.translations.common.currency}
            </td>
            <td>
                <b>{props.translations.common.from}</b>: {Utils.changeListDateFormat(new Date(props.promocode.valid_from))}<br />
                <b>{props.translations.common.to}</b>: {Utils.changeListDateFormat(new Date(props.promocode.valid_to))}<br />
            </td>
            <td>
                {promocodePlaceName}
            </td>
            <td>
                {props.promocode.used ? <span className="badge badge-danger">{props.translations.common.used}</span> : <span className="badge badge-success">{props.translations.common.not_used}</span>}
            </td>
            <td>
                {props.promocode.never_become_used ? <span className="badge badge-success">{props.translations.common.yes}</span> : <span className="badge badge-danger">{props.translations.common.no}</span>}
            </td>
            <td>
                {props.promocode.active ? <span className="badge badge-success">{props.translations.common.active}</span> : <span className="badge badge-secondary">{props.translations.common.inactive}</span>}
            </td>
            <td>
                {props.promocode.ignore_other_discounts ? <span className="badge badge-secondary">{props.translations.common.yes}</span> : <span className="badge badge-success">{props.translations.common.no}</span>}
            </td>
            <td>
                {props.promocode.ordertypes ? props.promocode.ordertypes.map(t => <li>{t.type}</li>) : null}
            </td>
            {/* <td>
            {props.promocode.ordertypes && props.promocode.ordertypes[0] && props.promocode.ordertypes[0] && props.translations.ordertypes[props.promocode.ordertypes[0].type] || "-"}
        </td> */}

            {/* {Utils.hasFunctionalityModule("reservations") ? 
            <td>
                <div className="col-md-2 col-form-label">
                    <label className="switch switch-label switch-pill switch-outline-primary-alt">
                        <input className="switch-input" type="checkbox" name="is_for_reservation" onChange={e => props.onChangeCheckboxIsForReservation(e, props.promocode.id)} checked={props.promocode.is_for_reservation} />
                        <span className="switch-slider" data-checked={props.translations.common.yes} data-unchecked={props.translations.common.no}></span>
                    </label>
                </div>
            </td>
        :
            null
        } */}

            <td>
                <NavLink to={ADMIN_URL + 'promocodes/edit/' + props.promocode.id}>
                    <button className="btn btn-pill btn-link" type="button">
                        <i className="fas fa-edit"></i>
                    </button>
                </NavLink>

                <button className="btn btn-pill btn-link" type="button" data-toggle="modal" data-target={"#dangerModal" + props.promocode.id} >
                    <i className="fas fa-trash"></i>
                </button>

                <DeleteModal
                    id={props.promocode.id}
                    title={props.promocode.code}
                    deleteFunc={props.deleteFunc}
                // translations={props.translations}
                />

            </td>
        </tr >
    )
}

export default ListEachTable;