import axios from 'axios';
import { LIST_QUESTIONS, LIST_ANSWERS } from './types';
import { API_URL, ADMIN_URL } from './../../../constants';
import { addSuccessMessage, addErrorMessages } from './../../messages/messages.actions';
import { changeLanguage } from './../../../actions/languagesActions';
import Auth from './../../../utils/Auth';
import jwtDecode from 'jwt-decode';
import Utils from '../../../utils/Utils';

export const listQuestions = () => dispatch => {
    let currUser = jwtDecode(Auth.getToken());

    let place_id = Auth.getUserPlace();
    let getParam = "?place_id=" + place_id;

    axios
        .get(API_URL + `feedbackquestionsAdmin` + getParam)
        .then(res => {
            dispatch({
                type: LIST_QUESTIONS,
                payload: res.data
            });
        })
        .catch(error => {
            throw (error);
        });
}

export const addEditQuestion = (postData, history, langId) => dispatch => {
    if(!postData.placeId) {
        postData.placeId = Auth.getUserPlace();
    }

    var postForTrans = null;

    if(Utils.getDefaultLang() && Utils.getDefaultLang().id != langId) {
        postForTrans = {...postData};
        delete postData.text;
    }

    axios
        .post(API_URL + 'feedbackquestions', postData)
        .then((post) => {
            post = post.data;
            // dispatch(addEditAllergenTranslation(post.id, langId, postData))
            // if (!postData.id && typeof postData.deleted === "undefined") { // edit
            // } else {

            if (!post.deleted) {
                dispatch(addEditFeedbackQuestionTranslation(post.id, langId, postForTrans ? postForTrans : postData));
            }

            dispatch(listQuestions());
            history.push(ADMIN_URL + 'feedback/questions');
            dispatch(addSuccessMessage("Changes saved"))
                
            // }
        })
        .catch(function (error) {
            // dispatch(addErrorMessages('Error occured'));
        })
}

export const addEditFeedbackQuestionTranslation = (question_id, lang_id, data) => dispatch => {
    axios
        .post(API_URL + `feedbackquestions/${question_id}/translations/${lang_id}`, data)
        .then(res => {
            // dispatch(listQuestions())
        })
        .catch(err => { })
}

export const listAnswers = () => dispatch => {
    let place_id = Auth.getUserPlace();
    let getParam = "?place_id=" + place_id;

    axios
        .get(API_URL + `feedbackanswersAdmin` + getParam)
        .then(res => {
            dispatch({
                type: LIST_ANSWERS,
                payload: res.data || []
            });
        })
        .catch(error => {
            throw (error);
        });
}

export const addEditAnswer = (postData) => dispatch => {
    if(!postData.placeId) {
        postData.placeId = Auth.getUserPlace();
    }
    axios
        .post(API_URL + 'feedbackanswers/update', postData)
        .then((post) => {
            post = post.data;
            dispatch(listAnswers());
            dispatch(addSuccessMessage("Changes saved"))
                
        })
        .catch(function (error) {
            // dispatch(addErrorMessages('Error occured'));
        })
}