import React, { Component, createRef } from 'react'
import { NavLink } from 'react-router-dom';
import { ADMIN_URL } from '../../constants.js'
import DeleteTableModal from './DeleteTableModal.js';
import QRCode from 'qrcode.react';
import { TABLE_QR_CODE_IMAGE_PATH } from '../../constants';
import { Link } from 'react-router-dom';
import MessagesContainer from './../messages/MessagesContainer';
import { SCAN_URL } from './../../constants';
import Utils from './../../utils/Utils';
import Select from 'react-select'
// import QRCodeStyling from "qr-code-styling";
// import tablLogo from './../../images/tabl-transparent.png';
// import tablLogoWhite from './../../images/tabl-transparent-white.png';

// let md5 = require('md5');

// const qrCd = new QRCodeStyling({
//     width: 350,
//     height: 350,
//     // data: 'https://tabl.bg/img/tabl-transparent.png',
//     // image: tablLogo,
//     image: tablLogoWhite,
//     cornersDotOptions: {
//         color: "#f66201"
//     },
//     dotsOptions: {
//         //color: "#4267b2",
//         type: "square"
//         //'rounded' 'dots' 'classy' 'classy-rounded' 'square' 'extra-rounded'
//     },
//     imageOptions: {
//         crossOrigin: "anonymous",
//         // margin: 20
//     }
// });

class AddEditTableForm extends React.Component {
    constructor(props) {
        super(props)
        this.ref = React.createRef();

    }

    componentDidMount() {
        if (this.props.isAddingTable == 0) {
            this.props.tableQRCode.append(this.ref.current);
        }
    }

    componentWillReceiveProps(){
        if (this.props.isAddingTable == 0) {
            this.props.tableQRCode.append(this.ref.current);
        }
    }

    render() {
        const props = this.props;
        //     const downloadQR = () => { // will implement soon}
        // <div>

        //   <a onClick={downloadQR}> Download QR </a>
        // </div>
        // console.log(md5('message'));

        // let tableToken = md5(md5(props.tableNum));
        let tableToken;
        if (props.tableNum != '' && props.isAddingTable == 1) {
            tableToken = SCAN_URL + Utils.generateTableToken(props.tableNum); // md5(md5(props.tableNum + (props.currentDate)));
        } else {
            tableToken = SCAN_URL + props.tableToken;
        }
        // qrCd.data = tableToken;
        if (this.props.isAddingTable == 0) {
            this.props.tableQRCode.update({
                data: tableToken
            });
        }

        let generateQRbtn = props.isAddingTable == 0 ?
            (
                <button className="btn btn-default" onClick={props.generateNewQrCode}>{props.translations.tables.generate_qr}</button>
            )
            : null

        let ordertypeOptions = [];
        Object.values(props.currPlaceOrderTypes).map(ordertype => {
            if (ordertype.active == 1) {
                ordertypeOptions.push({ 'value': ordertype.id, 'label': ordertype.type });
            }
        });

        let restaurantHallsOptions = [];
        let selectedRestaurantHal = null;
        Object.values(props.restaurantHalls).map(hall => {
            if (props.table.restaurantHallId == hall.id) {
                selectedRestaurantHal = { 'value': hall.id, 'label': hall.name }
            }
            if (hall.active == 1) {
                restaurantHallsOptions.push({ 'value': hall.id, 'label': hall.name });
            }
        });

        let tableRegionsOptions = [];
        let selectedTableRegion = null;

        Object.values(props.tableRegions).map(region => {
            if (props.table.tableRegionId == region.id) {
                selectedTableRegion = { 'value': region.id, 'label': region.name }
            }
            if (region.active == 1) {
                tableRegionsOptions.push({ 'value': region.id, 'label': region.name });
            }
        });


        return (

            // <form onSubmit={props.onSubmit}>
            <form>
                <div className="card-body">
                    <MessagesContainer />

                    {/* <div ref={ref} /> */}

                    {/* <qrCd /> */}

                    <div className="row">
                        <div className="col-sm-12">
                            <div className="form-group">
                                <label htmlFor="name">{props.translations.common.number}</label>
                                <input className={`form-control ${props.nameError.message ? "is-invalid" : ""}`} id="name" type="text" title={"latin"} placeholder={props.translations.common.number} name="table_num" onChange={props.onChange} value={props.tableNum} />
                            </div>
                        </div>
                    </div>
                    {(props.tableNum != '') ?
                        <>
                            <div
                                id="table-qr-code2"
                                ref={this.ref}
                            />
                            {/* <QRCode
                                id="table-qr-code"
                                value={tableToken}
                                size={250}
                                level={"H"}
                                includeMargin={true}
                            // style={{display: 'none'}}
                            /> */}
                        </>
                        :
                        null
                    }


                    {generateQRbtn}


                    {(props.tableNum != '' && props.isAddingTable == 0) ?
                        <a onClick={props.downloadQr} className="btn btn-default"> Download QR </a>
                        // <button onClick={e => onDownloadClick(e)}>Download</button>
                        :
                        ''
                    }

                    <div className="row">
                        <div className="form-group col-sm-12">
                            <label htmlFor="">{props.translations.common.order_type}</label>
                            <Select
                                value={props.tableOrdertypeValue ? props.tableOrdertypeValue : props.table.tableOrdertype}
                                // isMulti
                                name="colors"
                                options={ordertypeOptions}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                onChange={(valueLabel) => props.onFieldChange('tableOrdertypeValue', valueLabel)}
                            // onChange={(values, { action, removedValue }) => props.ingredientsOnChange(values, { action, removedValue }, 'removable')}
                            />
                        </div>

                        <label className="col-md-1 col-form-label">{props.translations.common.active}</label>
                        <div className="col-md-11 col-form-label">
                            <label className="switch switch-label switch-pill switch-outline-primary-alt">
                                <input className="switch-input" type="checkbox" name="active" onChange={props.onChangeCheckbox} checked={props.tableActive} />
                                <span className="switch-slider" data-checked={props.translations.common.yes} data-unchecked={props.translations.common.no}></span>
                            </label>
                        </div>

                        <label className="col-md-2 col-form-label">{props.translations.common.place_in_url}</label>
                        <div className="col-md-4 col-form-label">
                            <label className="switch switch-label switch-pill switch-outline-primary-alt">
                                <input className="switch-input" type="checkbox" name="place_in_url" onChange={props.onChangeCheckbox} checked={props.placeInUrl} disabled={props.isAddingTable == 0} />
                                < span className="switch-slider" data-checked={props.translations.common.yes} data-unchecked={props.translations.common.no}></span>
                            </label>
                        </div>

                        <label className="col-md-2 col-form-label">{props.translations.tables.is_for_app}</label>
                        <div className="col-md-4 col-form-label">
                            <label className="switch switch-label switch-pill switch-outline-primary-alt">
                                <input className="switch-input" type="checkbox" name="is_for_app" onChange={props.onChangeCheckbox} checked={props.table.is_for_app} />
                                <span className="switch-slider" data-checked={props.translations.common.yes} data-unchecked={props.translations.common.no}></span>
                            </label>
                        </div>

                        <label className="col-md-2 col-form-label">{props.translations.tables.is_kiosk_mode}</label>
                        <div className="col-md-4 col-form-label">
                            <label className="switch switch-label switch-pill switch-outline-primary-alt">
                                <input className="switch-input" type="checkbox" name="is_kiosk_mode" onChange={props.onChangeCheckbox} checked={props.table.is_kiosk_mode} />
                                <span className="switch-slider" data-checked={props.translations.common.yes} data-unchecked={props.translations.common.no}></span>
                            </label>
                        </div>

                        <div className="col-md-12">
                            <div className="form-group">
                                <label htmlFor="tableRegionId">{props.translations.tables.table_region}</label>
                                <Select
                                    value={selectedTableRegion}
                                    // isMulti
                                    name="tableRegionId"
                                    options={tableRegionsOptions}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    onChange={(valueLabel) => props.onFieldChange('tableRegionId', valueLabel.value)}
                                />
                                {/* <input className={`form-control`} id="name" type="text" title={"latin"} name="restaurantHallId" onChange={props.onChange} value={props.table.restaurantHallId} /> */}
                            </div>
                        </div>

                        {Utils.hasFunctionalityModule('reservations') ?
                            <React.Fragment>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="reservation_min_people">{props.translations.tables.reservation_min_people}</label>
                                        <input className={`form-control`} id="reservation_min_people" type="text" title={"latin"} name="reservation_min_people" onChange={props.onChange} value={props.table.reservation_min_people} />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="reservation_max_people">{props.translations.tables.reservation_max_people}</label>
                                        <input className={`form-control`} id="reservation_max_people" type="text" title={"latin"} name="reservation_max_people" onChange={props.onChange} value={props.table.reservation_max_people} />
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label htmlFor="restaurantHallId">{props.translations.tables.restaurant_hall}</label>
                                        <Select
                                            value={selectedRestaurantHal}
                                            // isMulti
                                            name="restaurantHallId"
                                            options={restaurantHallsOptions}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            onChange={(valueLabel) => props.onFieldChange('restaurantHallId', valueLabel.value)}
                                        />
                                        {/* <input className={`form-control`} id="name" type="text" title={"latin"} name="restaurantHallId" onChange={props.onChange} value={props.table.restaurantHallId} /> */}
                                    </div>
                                </div>
                            </React.Fragment>
                            :
                            null
                        }
                    </div>


                    <hr />
                    <br />

                    <div className="form-actions">
                        {props.isAddingTable == 1 ? '' : <button className="btn btn-danger mright10" type="button" data-toggle="modal" data-target={"#dangerModal" + props.tableId} > {props.translations.common.delete}</button>}

                        <DeleteTableModal
                            tableId={props.tableId}
                            tableNum={props.tableNum}
                            deleteTable={props.deleteTable}
                            translations={props.translations}
                        />

                        <NavLink to={ADMIN_URL + 'tables'}>
                            <button className="btn btn-secondary" type="button">
                                {props.translations.common.cancel}
                            </button>
                        </NavLink>

                        <button className="fright btn btn-primary" type="submit" onClick={props.onSubmit}>
                            {props.isAddingTable == 1 ? props.translations.common.add : props.translations.common.save}
                        </button>

                    </div>

                </div>

            </form>
        )
    }
}

export default AddEditTableForm;