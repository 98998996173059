import React, { Component } from 'react'
import { connect } from 'react-redux';
import { uploadVideo } from './../../../actions/categoriesHierarchyActions';
import MessagesContainer from './../../messages/MessagesContainer';
import { addErrorMessages } from './../../messages/messages.actions';
import moment from 'moment';
import Select from 'react-select';
import Utils from '../../../utils/Utils';

class CategoryVideoTab extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            video: '',
            video_name: '',
            blob: ''
        }
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.category.id) {
            this.setState({
                video_name: nextProps.category.video_name,
            })
        }
    }

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleFileChange = async (e) => {
        const file = e.target.files[0];

        // const stream = fs.createReadStream(file);

        const url = URL.createObjectURL(file);

        var reader = new FileReader();
        reader.readAsArrayBuffer(file);
        console.log(reader.result)
        //   document.getElementById("display").src = reader.result;
          // image editing
          // ...
        //   var blob = window.dataURLtoBlob(file);
        //   console.log(blob, new File([blob], "image.png", {
        //     type: "image/png"
        //   }));

        console.log(" BASE 64", await this.toBase64(file))
        console.log(url, file);
        this.setState({ video: file,
            blob: url
            //  stream: stream 
            });




        // setSource(url);
    };

    toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
    });

    save = () => {

        let formData = new FormData();
        // formData.append('my-video', this.state.video);
        formData.append('my-video', this.state.blob);
        formData.append('catId', this.props.category.id);
        // formData.append('my-video', this.state.stream, { filepath: path, filename: basename(path) });

        // the image field name should be similar to your api endpoint field name
        // in my case here the field name is customFile

        console.log("SAVING");
        for (const value of formData.values()) {
            console.log(value);
          }

        this.props.uploadVideo(formData);
    }

    render() {
        console.log(this.state.video_name)
        return (
            <div className="">
                <MessagesContainer />

                <div className="row">
                    {/* <form type="POST" enctype='multipart/form-data'> */}

                        <div className="form-group col-sm-12">
                            <label htmlFor="booking_start_work_time">{this.props.translations.categories.video}</label>
                            <input className={`form-control`} type="file" placeholder={this.props.translations.categories.video} name="video"
                            onChange={this.handleFileChange}  />
                        </div>

                        {this.state.video_name ? 
                            <video
                                className="VideoInput_video"
                                width="100%"
                                height={"300px"}
                                controls
                                src={Utils.getCategoryVideoPath(this.state.video_name)}
                            />
                        : 
                            null
                        }
                        


                        <div className="form-group col-sm-12">
                            <button className="fright btn btn-primary" onClick={this.save}>{this.props.translations.common.save}</button>
                        </div> 
                    {/* </form>                    */}
                </div>

                
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        translations: state.lang,
    }
};

export default connect(mapStateToProps, { uploadVideo })(CategoryVideoTab);