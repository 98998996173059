import axios from 'axios';
import { LIST_PROMOCODES } from './types';
import { API_URL, ADMIN_URL } from './../../../constants'
import { addErrorMessages, addSuccessMessage } from './../../messages/messages.actions';
// import { history } from './../store';
import Auth from './../../../utils/Auth';

export const listPromocodes = (filter) => dispatch => {
    axios
        .get(API_URL + 'promocode?' + new URLSearchParams(filter).toString())
        .then(res =>
            dispatch({
                type: LIST_PROMOCODES,
                payload: res && res.data || []
            })
        ).catch(error => {
            console.log('erroring ', error)
            // throw(error);
        });
}


export const addEditPromocode = (postData, history) => dispatch => {
    axios
        .post(API_URL + 'promocode', postData)
        .then((post) => {
            post = post.data;
            // dispatch(addEditAllergenTranslation(post.id, langId, postData))
            // if (!postData.id && typeof postData.deleted === "undefined") { // edit
            // } else {
            dispatch(addSuccessMessage("Changes saved"))
            dispatch(listPromocodes({'search_place_id': Auth.getUserPlace() }));
            history.push(ADMIN_URL + 'promocodes');

            // }
        })
        .catch(function (error) {
            // dispatch(addErrorMessages('Error occured'));
        })
}


export const addBulkPromocode = (postData, history) => dispatch => {
    console.log('postData', postData)
    axios
        .post(API_URL + 'promocode/bulk', postData)
        .then((post) => {
            post = post.data;
            // dispatch(addEditAllergenTranslation(post.id, langId, postData))
            // if (!postData.id && typeof postData.deleted === "undefined") { // edit
            // } else {
            dispatch(addSuccessMessage("Changes saved"))
            dispatch(listPromocodes());
            // history.push(ADMIN_URL + 'promocodes');

            // }
        })
        .catch(function (error) {
            // dispatch(addErrorMessages('Error occured'));
        })
}