import axios from 'axios';
import { LIST_TABLES, ADD_EDIT_TABLE_ERROR, LIST_TABLES_FOR_ORDERS, } from './types';
import { API_URL, ADMIN_URL, FILTER_PLACES_NAME } from './../constants'
import { addErrorMessages, addSuccessMessage } from './../components/messages/messages.actions';
import { history } from './../store';
import Auth from './../utils/Auth';
import { showLoading, hideLoading } from './../components/common/loading/loading.actions';


export const listTablesForOrders = (place_id = 0) => dispatch => {
    let getParam = '';
    
    if (place_id == 0) {
        place_id = (!window.localStorage.getItem(FILTER_PLACES_NAME) || window.localStorage.getItem(FILTER_PLACES_NAME) == '[]') ? Auth.getUserPlace() : (window.localStorage.getItem(FILTER_PLACES_NAME).slice(1, -1))
        getParam += "?place_id=" + place_id;
    }

    axios
        .get(API_URL + 'tablesForOrders' + getParam)
        .then(res =>
            dispatch({
                type: LIST_TABLES_FOR_ORDERS,
                payload: res && res.data || []
            })
        ).catch(error => {
            console.log('erroring ', error)
            // throw(error);
        });
}



export const listTables = (place_id = 0) => dispatch => {
    let getParam = '';
    if (place_id == 0) {
        place_id = Auth.getUserPlace();
        getParam += "?place_id=" + place_id;
    }

    axios
        .get(API_URL + 'tables' + getParam)
        .then(res =>
            dispatch({
                type: LIST_TABLES,
                payload: res && res.data || []
            })
        ).catch(error => {
            console.log('erroring ', error)
            // throw(error);
        });
}

export const addTable = (historyy, postData, imageData) => dispatch => {
    if (!postData.place_id) {
        postData.place_id = Auth.getUserPlace();
    }
    axios
        .post(API_URL + `tables?host=${window.location.origin}`, postData)
        .then(post => {
            post = post.data;
            dispatch(addImage(imageData, post.id));

            dispatch(addSuccessMessage())
        }
        )
        .catch(function (error) {
            dispatch(addErrorMessages(error.response.data.error.errors));
        });



    // fetch(API_URL + 'tables', {
    //     method: 'POST',
    //     headers: {
    //         'content-type': 'application/json'
    //     },
    //     body: JSON.stringify(postData)
    // })
    // .then(res => res.json())
    // .then(function(post) {
    //     // if (post.error) {
    //     //     dispatch({
    //     //         type: ADD_EDIT_TABLE_ERROR,
    //     //         payload: post.error.errors
    //     //     })
    //     // } else {

    //         // history.push(ADMIN_URL + 'tables');
    //     // }
    // })
    // .catch(function(e) {
    //     dispatch(addErrorMessages(e.response.data.error.errors));
    // });
}

export const bulkAddTables = (history, data) => dispatch => {

    dispatch(showLoading());

    axios
        .post(API_URL + `tables/bulk_create?host=${window.location.origin}`, data, { 'Content-Type': 'multipart/form-data' })
        .then(
            function (post) {
                dispatch(listTables());
                dispatch(hideLoading());

                history.push(ADMIN_URL + 'tables');
            }
        )
        .catch(function (error) {
            dispatch(addErrorMessages(error));
        });

}

export const editTable = (id, postData, history = null, imageData = null) => dispatch => {
    if (!postData.place_id) {
        postData.place_id = Auth.getUserPlace();
    }

    console.log("EDIT ", postData)
    axios
        .put(API_URL + 'tables/' + id, postData)
        .then(post => {
            post = post.data;

            if(imageData) {
                dispatch(addImage(imageData, post.id));
            }

            // dispatch(addSuccessMessage())
            dispatch(listTables());
        })
        .catch(function (error) {
            dispatch(addErrorMessages(error.response.data.error.errors));
        });
}

// api/tables/:id/upload

export const addImage = (file, tableId) => dispatch => {
    axios
        .post(API_URL + `tables/${tableId}/upload`, file)
        .then(res => {

            // dispatch(addSuccessMessage())
            history.push(ADMIN_URL + 'tables');
        }
        )
        .catch(function (error) {
            dispatch(addErrorMessages(error.response.data.error.errors));
        });


    // fetch(API_URL + `tables/${tableId}/upload`, {
    //     method: 'POST',
    //     body: file //{"files": file, "productId": productId}
    // })
    // .then(res => res.json())
    // .then(function(res) {
    //     // history.push(ADMIN_URL + 'products');
    //     history.push(ADMIN_URL + 'tables');                


    //     // history.push(ADMIN_URL + 'products');
    //     //TODO toastr: Msg za uspeshno/neuspeshno kachvane na snimka
    // });

}


export const removeTable = (id, history) => dispatch => {
    axios
        .put(API_URL + 'tables/' + id, { deleted: true })
        .then(res => {
            if (history) {
                // Deleting from /edit
                history.push(ADMIN_URL + "tables")
            } else {
                // Deleting from /list
                dispatch(listTables())
                dispatch(addSuccessMessage('Success deleting table'))
            }
        })
        .catch(function (error) {
            dispatch(addErrorMessages(error.response.data.error.errors));
        });



    // fetch(API_URL + 'tables/'+id, {
    //     method: 'PUT',
    //     headers: {
    //         'content-type': 'application/json'
    //     },
    //     body: JSON.stringify({deleted:true})
    // })
    // .then(res => res.json())
    // .then(
    //     function(res) {
    //         if (history) {
    //             // Deleting from /edit
    //             history.push(ADMIN_URL + "tables")
    //         } else { 
    //             // Deleting from /list
    //             dispatch(listTables())
    //         }
    //     }
    // )
    // .catch(function(e) {
    // });
}
