import React, { Component } from 'react';
import './App.css';
import { WonderPush } from 'react-wonderpush';
// import 'bootstrap/dist/css/bootstrap.min.css';

import MyRouter from "./MyRouter";
import Header from './components/Header';
import SideBar from './components/SideBar';
import Footer from './components/Footer';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faEnvelope, faKey } from '@fortawesome/free-solid-svg-icons';
import Auth from './utils/Auth';
import { logout } from './actions/authActions'

library.add(faEnvelope, faKey);

class App extends React.Component {

  render() {

    let sideBar = Auth.isUserAuthenticated() ? <SideBar /> : "";

    return (
      <WonderPush options={{ webKey: "89fa838b08bb61dc526a3e92ae11746810d655f6f48df2968615e61b2bf4060c" }}>
        <Header logoutAction={logout} />
        <div className="app-body">
          {sideBar}
          <MyRouter />
        </div>
        <Footer />
      </WonderPush>
    );
  }
}

export default App;
