import React, { Component } from 'react'
import { listPaymentProviders } from '../actions/paymentProviders.actions';
import { addEditPlace, listPlacesByUserRole } from './../../places/actions/places.actions';
import { addSuccessMessage, addErrorMessages } from './../../messages/messages.actions';
import { showLoading, hideLoading } from './../../common/loading/loading.actions';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { ADMIN_URL } from '../../../constants';
import Utils from '../../../utils/Utils';
import Auth from '../../../utils/Auth';
import MessagesContainer from '../../messages/MessagesContainer';
import Select from 'react-select'

class PaymentProvidersPage extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			// id: this.props.match.params.id,
			paymentProviderId: "",
			credentials: "{}",
			readonlyFields: {}
		}
	}

	
	componentWillMount() {
        this.props.listPaymentProviders();
    }

	componentDidMount() {
        this.props.listPaymentProviders();
    }

	componentWillReceiveProps(nextProps) {

		if(nextProps.currPlace.id) {
			this.setState({
				paymentProviderId: nextProps.currPlace.paymentProviderId,
				credentials: nextProps.currPlace.payment_provider_credentials ? JSON.parse(nextProps.currPlace.payment_provider_credentials) : {}
			});
		}

	}
	onChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
	}

	onChangeCheckbox = (e) => {
		this.setState({ [e.target.name]: e.target.checked });
	}

	onChangeSelectAutocomplete = (field, valueLabel) => {
		this.setState({ [field]: valueLabel.value });
	}
	
	onJsonChange = (e) => {
		const { name, value } = e.target;

		this.setState((prevState) => ({
			credentials: {
				...prevState.credentials,
				[name]: value
			}
		}));
	};

	validate() {
		let errors = [];

		if (this.state.name.length === 0) {
			errors.push(this.props.translations.messages.errors.invalid_name);
		}
		return errors;
	}

	onSubmit = (e) => {
		e.preventDefault();

        // const errors = this.validate();
		// if (errors.length > 0) {
		// 	this.props.addErrorMessages(errors);
		// 	return;
		// }

		const post = {
			id: this.props.currPlace.id,
			paymentProviderId: this.state.paymentProviderId,
			payment_provider_credentials: JSON.stringify(this.state.credentials)
		}

		this.props.addEditPlace(post, this.props.history)
			.then(resp => {
				this.props.listPlacesByUserRole();
				this.props.addSuccessMessage("Successfully edited settings");
			})

	}

	render() {
		
		let selectedPP;
		let selectedPPCredentialFields = {};
		let selectedPPReadonlyFields = {};
        let autocompleteItems = [];
        autocompleteItems.push({ 'value': null, 'label': this.props.translations.payment_providers.choose_payment_provider })

        this.props.paymentProviders.map(pp => {
            if (this.state.paymentProviderId == pp.id) {
                selectedPP = { 'value': pp.id, 'label': pp.name };
				selectedPPCredentialFields = JSON.parse(pp.credential_fields)
				selectedPPReadonlyFields = JSON.parse(pp.readonly_fields)
            }

            autocompleteItems.push({ 'value': pp.id, 'label': pp.name });
        });

        console.log("currPlace", this.props.currPlace)
		return (
			<main className="main">

				{/* <!-- Breadcrumb--> */}
				<ol className="breadcrumb">
					<li className="breadcrumb-item"><NavLink to={ADMIN_URL}>{this.props.translations.common.dashboard}</NavLink></li>
					<li className="breadcrumb-item"><NavLink to={ADMIN_URL + 'settings'}>{this.props.translations.sidemenu.settings}</NavLink></li>
					<li className="breadcrumb-item">{this.props.translations.delivery_systems.delivery_system}</li>
				</ol>

				<div className="container-fluid">
					<div className="animated fadeIn">
						<div className="row">
							<div className="col-sm-12">
								<div className="card">
									<form>
										<div className="card-body">
											<MessagesContainer />
											<div className="row">

												<div className="form-group col-sm-12">
													<label htmlFor="paymentProviderId">{this.props.translations.payment_providers.payment_provider}</label>
													<Select name="paymentProviderId" options={autocompleteItems} 
														onChange={(valueLabel) => this.onChangeSelectAutocomplete('paymentProviderId', valueLabel)} 
														value={selectedPP}
														placeholder={this.props.translations.delivery_systems.delivery_system}
													/>
												</div>

												{Object.keys(selectedPPCredentialFields).map((key) => (
													<div key={key} className='col-sm-6 form-group'>
														<label htmlFor={key}>{key}:</label>
														<input
															type="text"
															id={key}
															className='form-control'
															name={key}
															value={this.state.credentials[key]}
															onChange={this.onJsonChange}
														/>
														</div>
													))
												}

												<div className='col-md-12'>
													<h4>Readonly fields</h4>
												</div>
												{Object.keys(selectedPPReadonlyFields).map((key) => (
													<div key={key} className='col-sm-6 form-group'>
														<label htmlFor={key}>{key}:</label>

														{key == 'success_url' ? 
															<input
																type="text" id={key} className='form-control' name={key} readOnly
																defaultValue={"https://"+ window.location.host + "/payment/success/" + this.props.currPlace.uuid}
															/>
														: key == 'fail_url' ?
															<input
																type="text" id={key} className='form-control' name={key} readOnly
																defaultValue={"https://"+ window.location.host + "/payment/fail/" + this.props.currPlace.uuid}
															/>
														: key == 'webhook_success_url' ?
															<input
																type="text" id={key} className='form-control' name={key} readOnly
																defaultValue={"https://"+ window.location.host + "/payment/webhook/" + this.props.currPlace.uuid}
															/>
														: key == 'webhook_fail_url' ?
															<input
																type="text" id={key} className='form-control' name={key} readOnly
																defaultValue={"https://"+ window.location.host + "/payment/webhook/transaction-failed/" + this.props.currPlace.uuid}
															/>
														:
														null
														}
													</div>
													))
												}

											</div>

											<hr />

											<div className="form-actions">

												<button className="fright btn btn-primary mb-3" type="submit" onClick={this.onSubmit}>
													{this.props.translations.common.save}
												</button>

											</div>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</main>
		)
	}
}

const mapStateToProps = (state, ownProps) => {
	let currPlaceId = Auth.getUserPlace();

	let currPlace = state.places.places.find(s => s.id == currPlaceId);

	if (!currPlace) currPlace = {
		id: '',
		name: '',
		active: 1,
		paymentProviderId: '',
		payment_provider_credentials: "{}",
	};
	return {
		paymentProviders: state.paymentProviders.paymentProviders,
		currPlace: currPlace,
		translations: state.lang,
		languages: state.languages.languages || {},
		currLang: state.settings.settings['default_lang'] && state.settings.settings['default_lang'].value || 'bg',
	}

};

export default connect(mapStateToProps, { listPaymentProviders,
	addEditPlace, listPlacesByUserRole, addSuccessMessage, addErrorMessages, showLoading, hideLoading })(PaymentProvidersPage);