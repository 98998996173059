import React, { Component } from 'react'
import { connect } from 'react-redux';
import { bulkAddTables } from '../../actions/tablesActions';
import AddEditPromocodeForm from './AddEditPromocodeForm';
import { NavLink } from 'react-router-dom';
import { ADMIN_URL, API_TOKEN } from '../../constants';
import { addErrorMessages } from '../messages/messages.actions';
import { withRouter } from 'react-router-dom';
import { SCAN_URL } from '../../constants';
import Utils from '../../utils/Utils';
import Auth from '../../utils/Auth';
import MessagesContainer from '../messages/MessagesContainer';
import Select from 'react-select'
import DatePicker from 'react-datepicker';

import { addBulkPromocode, addEditPromocode } from './actions/promocode.action';

// let md5 = require('md5');

class AddBulkPromocodesPage extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            count_promo_codes: '',
            placeId: '',
            valid_from: '',
            valid_to: '',
            discount_type: '',
            discount_value: '',
            ordertypes: '',
        }

    }

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    onChangeCheckbox = (e) => {
        this.setState({ [e.target.name]: e.target.checked });
    }

    onChangeSelectAutocomplete = (fieldName, valueLabel) => {
        this.setState({ [fieldName]: valueLabel });
    }

    onFieldChange = (fieldName, value) => {
        this.setState({ [fieldName]: value });
    }

    validate() {
        let errors = [];

        if (this.state.placeId.length == '') {
            errors.push(this.props.translations.messages.errors.invalid_place);
        }

        return errors;
    }


    onSubmit = (e) => {
        e.preventDefault();
        const errors = this.validate();
        if (errors.length > 0) {
            this.props.addErrorMessages(errors);
            return;
        }

        let ordertypes = this.state.ordertypes.map(c => c.value);

        const post = {
            count_promo_codes: this.state.count_promo_codes,
            placeId: this.state.placeId,
            valid_from: this.state.valid_from,
            // valid_to: this.state.valid_to,
            valid_to: (new Date(this.state.valid_to).setHours(23, 59, 59)), //this.state.valid_to,
            discount_type: this.state.discount_type,
            discount_value: this.state.discount_value,
            ordertypes: ordertypes,
        }

        this.props.addBulkPromocode(post, this.props.history);

    }


    onChangeSelect = (name, valueLabel) => {
        let newState = [];
        newState[name] = valueLabel.value;
        this.setState(newState);
    }

    onChangeSelectAutocomplete = (fieldName, valueLabel) => {
        this.setState({ [fieldName]: valueLabel });
    }


    render() {

        let placesOptions = [];

        Object.values(this.props.places).map(place => {
            if (place.active == 1) {
                placesOptions.push({ 'value': place.id, 'label': place.name });
            }
        });

        let currPlaceOrderTypes = [];
        Object.values(this.props.currPlaceOrderTypes).map(t => {
            currPlaceOrderTypes.push({ 'value': t.id, 'label': t.type });
        });

        let discountTypesOptions = [];
        discountTypesOptions.push({ 'value': 'money', 'label': 'Money' });
        discountTypesOptions.push({ 'value': 'percent', 'label': 'Percent' });

        return (
            <main className="main">

                {/* <!-- Breadcrumb--> */}
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><NavLink to={ADMIN_URL}>{this.props.translations.common.dashboard}</NavLink></li>
                    {/* <li className="breadcrumb-item">Меню</li> */}
                    <li className="breadcrumb-item"><NavLink to={ADMIN_URL + "promocodes"}>{this.props.translations.promocodes.title}</NavLink></li>
                    <li className="breadcrumb-item active">{this.props.translations.promocodes.add}</li>
                    {/* <!-- Breadcrumb Menu--> */}
                    <li className="breadcrumb-menu d-md-down-none">
                        <div className="btn-group" role="group" aria-label="Button group">
                            {/* 
                            <!--<a className="btn" href="./">
                                <i className="icon-graph"></i>  Dashboard</a>
                            <a className="btn" href="#">
                                <i className="icon-settings"></i>  Settings</a>
                            --> 
                            */}
                        </div>
                    </li>
                </ol>

                <div className="container-fluid">
                    <div className="animated fadeIn">
                        <div className="row">

                            {/* <!-- /.col--> */}
                            <div className="col-sm-12">
                                <div className="card">

                                    <div className="card-body">
                                        <MessagesContainer />

                                        <div className="row">
                                            <div className="col-sm-12 col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="name">{this.props.translations.common.count_promo_codes}</label>
                                                    <input className={`form-control `} id="count_promo_codes" type="text" placeholder={this.props.translations.common.count_promo_codes} name="count_promo_codes" onChange={this.onChange} value={this.state.count_promo_codes} />
                                                    {/* <div className="invalid-feedback">{this.props.errors.find(error => error.path == 'table_num').message ? this.props.errors.find(error => error.path == 'table_num').message : this.props.errors.find(error => error.path == 'table_num')}</div> */}
                                                </div>
                                            </div>


                                            <div className="col-sm-12 col-md-6">
                                                <div className="form-group">
                                                    <div className="form-group">
                                                        <label htmlFor="discount_value">{this.props.translations.common.discount_value}</label>
                                                        <input className={`form-control`} id="name" type="text" title={"latin"} placeholder={this.props.translations.common.discount_value} name="discount_value" onChange={this.onChange} value={this.state.discount_value} />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-sm-12 col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="catHierarchyId">{this.props.translations.common.discount_type}</label>

                                                    <Select
                                                        value={this.state.discount_type ? discountTypesOptions[discountTypesOptions.findIndex(p => p.value == this.state.discount_type)] : (this.state.discount_type == 0 ? { 'value': 'money', 'label': 'Money' } : '')}
                                                        name="discount_type"
                                                        options={discountTypesOptions}
                                                        className="basic-multi-select"
                                                        classNamePrefix="select"
                                                        placeholder=''
                                                        onChange={v => this.onChangeSelect('discount_type', v)}
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-sm-12 col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="catHierarchyId">{this.props.translations.common.place}</label>

                                                    <Select
                                                        value={this.state.placeId ? placesOptions[placesOptions.findIndex(p => p.value == this.state.placeId)] : ''}
                                                        name="placeId"
                                                        options={placesOptions}
                                                        className="basic-multi-select"
                                                        classNamePrefix="select"
                                                        placeholder=''
                                                        onChange={v => this.onChangeSelect('placeId', v)}
                                                    />
                                                </div>
                                            </div>


                                            <div className="col-sm-12 col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="catHierarchyId">{this.props.translations.common.ordertype}</label>

                                                    <Select
                                                        isMulti
                                                        value={this.state.ordertypes}
                                                        name="ordertypes"
                                                        options={currPlaceOrderTypes}
                                                        className="basic-multi-select"
                                                        classNamePrefix="select"
                                                        placeholder=''
                                                        onChange={v => this.onChangeSelectAutocomplete('ordertypes', v)}
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-sm-12 col-md-6">
                                                <div className="form-group" style={{ marginBottom: 13 }}>
                                                    <label className="col-form-label" >
                                                        {this.props.translations.common.valid_from}
                                                    </label>
                                                    <div style={{ width: '100%' }}>
                                                        <DatePicker
                                                            className="form-control vertical-center"
                                                            value={this.state.valid_from ? (new Date(this.state.valid_from).getDate() + '-' + (new Date(this.state.valid_from).getMonth() + 1) + '-' + new Date(this.state.valid_from).getFullYear()) : ''}
                                                            onChange={date => this.onFieldChange('valid_from', date)}
                                                            dateFormat="d-M-Y"
                                                            style={{ display: 'block', width: '100%' }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-sm-12 col-md-6">
                                                <div className="form-group" style={{ marginBottom: 13 }}>
                                                    <label className="col-form-label" >
                                                        {this.props.translations.common.valid_to}
                                                    </label>
                                                    <div style={{ width: '100%' }}>
                                                        <DatePicker
                                                            className="form-control vertical-center"
                                                            value={this.state.valid_to ? (new Date(this.state.valid_to).getDate() + '-' + (new Date(this.state.valid_to).getMonth() + 1) + '-' + new Date(this.state.valid_to).getFullYear()) : ''}
                                                            onChange={date => this.onFieldChange('valid_to', date)}
                                                            dateFormat="d-M-Y"
                                                            style={{ display: 'block', width: '100%' }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>


                                            <hr />
                                            <br />

                                            <div className="form-actions">

                                                <NavLink to={ADMIN_URL + 'promocodes'}>
                                                    <button className="btn btn-secondary" type="button">
                                                        {this.props.translations.common.cancel}
                                                    </button>
                                                </NavLink>

                                                <button className="fright btn btn-primary" type="submit" onClick={this.onSubmit}>
                                                    {this.props.translations.common.add}
                                                </button>

                                            </div>

                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </main >
        )
    }
}

const mapStateToProps = state => {

    let orderTypes = [];

    if (state.places.places) {
        let currPlace = state.places.places.find(p => p.id == Auth.getUserPlace());
        if (currPlace) {
            orderTypes = currPlace.ordertypes;
        }
    }
    console.log(`orderTypes`, orderTypes)
    return {
        currPlaceOrderTypes: orderTypes,
        errors: state.tables.errors,
        translations: state.lang,
        clients: state.clients.clients,
        places: state.places.places,
    }
};

// const mapDispatchToProps = (dispatch, ownProps) => {
// 	return {
// 		addTable: (data, imageData) => dispatch(addTable(ownProps.history, data, imageData)),
// 		addErrorMessages: (msgs) => dispatch(addErrorMessages(msgs))
// 	}
// }

export default withRouter(connect(mapStateToProps, { addBulkPromocode, addErrorMessages })(AddBulkPromocodesPage));