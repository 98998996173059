import React, { Component } from 'react';
import AddEditCategoryForm from './AddEditCategoryForm';
import { editCategory, removeCategory } from '../../actions/categoriesActions';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { ADMIN_URL } from '../../constants';
import { addErrorMessages } from './../messages/messages.actions';

class EditCategoryPage extends React.Component {
	constructor(props) {
		super(props)

		this.state = {	
			currLang: this.props.currLang,
			id: this.props.match.params.id,
			name: this.props.cat.translations[this.props.currLang] && this.props.cat.translations[this.props.currLang].name 
				? this.props.cat.translations[this.props.currLang].name : this.props.cat.name,
			code: this.props.cat.code ? this.props.cat.code : '',
			icon: this.props.cat.icon ? this.props.cat.icon : '',
			active: this.props.cat.active ? this.props.cat.active : 0,
		}

		this.onChange = this.onChange.bind(this);
		this.onChangeCheckbox = this.onChangeCheckbox.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.cat.id) {
			this.setState({
				id: nextProps.match.params.id,
				// name: nextProps.cat.name,
				name: nextProps.cat.translations[this.state.currLang] && nextProps.cat.translations[this.state.currLang].name 
					? nextProps.cat.translations[this.state.currLang].name : nextProps.cat.name,

				icon: nextProps.cat.icon ? nextProps.cat.icon : '',
				code: nextProps.cat.code ? nextProps.cat.code : '',
				active: nextProps.cat.active ? nextProps.cat.active : 0,
			});
		}
	}

	onChange(e) {
		this.setState({ [e.target.name]: e.target.value });
	}

	onChangeCheckbox(e) {
		this.setState({ [e.target.name]: e.target.checked });
	}

	validate() {
		let errors = [];

		if (this.state.name.length === 0) {
			errors.push(this.props.translations.messages.errors.invalid_name);
		}
		return errors;
	}

	onSubmit(e) {
		e.preventDefault();

		const errors = this.validate();
		if (errors.length > 0) {
			this.props.addErrorMessages(errors);
			return;
		}
		const post = {
			id: this.state.id,
			name: this.state.name,
			code: this.state.code,
			icon: this.state.icon,
			active: this.state.active,
		}

		// Image append
		const files = Array.from(document.querySelector('#multi').files)
		let formData = new FormData()

		files.forEach((file, i) => {
			formData.append("file", file)
		})

		this.props.editCategory(post, this.props.history, formData, this.props.languages[this.state.currLang].id);

	}

	deleteCategory = (e, id) => {
		e.preventDefault();
		this.props.removeCategory(id, this.props.history);
	}

	currLangOnChange = (e) => {
		let newLang = e.target.value;
		let newNameValue = this.props.cat.translations[newLang] && this.props.cat.translations[newLang].name 
			? this.props.cat.translations[newLang].name : "";

		this.setState({
			name: newNameValue,
			currLang: e.target.value
		})
	}

	render() {
		// let catItem = this.props.cat;

		return (
			<main className="main">

				{/* <!-- Breadcrumb--> */}
				<ol className="breadcrumb">
					<li className="breadcrumb-item"><NavLink to={ADMIN_URL}>{this.props.translations.common.dashboard}</NavLink></li>
					{/* <li className="breadcrumb-item">Меню</li> */}
					<li className="breadcrumb-item"><NavLink to={ADMIN_URL + "categories"}>{this.props.translations.categories.title}</NavLink></li>
					<li className="breadcrumb-item active">{this.props.translations.categories.edit}</li>
					{/* <!-- Breadcrumb Menu--> */}
				</ol>

				<div className="container-fluid">
					<div className="animated fadeIn">
						<div className="row">

							{/* <!-- /.col--> */}
							<div className="col-sm-12">
								<div className="card">

									<AddEditCategoryForm
										onSubmit={this.onSubmit}
										onChange={this.onChange}
										onChangeCheckbox={this.onChangeCheckbox}
										categoryId={this.state.id}
										categoryName={this.state.name}
										categoryCode={this.state.code}
										icon={this.state.icon}
										categoryActive={this.state.active}
										deleteCategory={this.deleteCategory}
										isAddingCategory="0"
										nameError={this.props.errors.find(error => error.path == 'name') ? this.props.errors.find(error => error.path == 'name') : ""}
										translations={this.props.translations}
										currLang={this.state.currLang}
										currLangOnChange={this.currLangOnChange}
										languages={this.props.languages}
									/>

								</div>
							</div>

						</div>
					</div>
				</div>

			</main>
		)
	}
}

const mapStateToProps = (state, ownProps) => {
	let catId = ownProps.match.params.id;

	let cat = state.categories.categories[catId];
	if (!cat) cat = {
		id: '',
		name: '',
		icon: '',
		active: '',
		translations: {}
	};
	return {
		cat: cat,
		errors: state.categories.errors,
		translations: state.lang,
		languages: state.languages.languages || {},
		currLang: "bg",
		translationsType: state.settings.settings['translations_type'].value
	}
};

export default connect(mapStateToProps, { editCategory, removeCategory, addErrorMessages })(EditCategoryPage);