import axios from 'axios';
import { LIST_BOOKINGS, LIST_BOOKING_ROOMS } from './types';
import { API_URL, ADMIN_URL } from './../../../constants';
import { addSuccessMessage, addErrorMessages } from './../../messages/messages.actions';
import { changeLanguage } from './../../../actions/languagesActions';
import Auth from './../../../utils/Auth';
import jwtDecode from 'jwt-decode';
import { showLoading, hideLoading } from './../../common/loading/loading.actions';
import moment from 'moment';

export const listBookings = (start_time=null, end_time=null, status=null, place_id = 0) => dispatch => {
    let query = '';
    // if(place_id == 0) {
        place_id = Auth.getUserPlace();
        query += "?place_id=" + place_id;
    // }

    if(start_time) {
        start_time = moment(start_time).format('YYYY-MM-DD')
        query += `&start_time=${start_time}`;
    }
    if(end_time) {
        end_time = moment(end_time).format('YYYY-MM-DD') + " 23:59";
        query += `&end_time=${encodeURIComponent(end_time)}`;
    }
    if(status) {
        query += `&status=${status}`
    }

    axios
        .get(API_URL + 'bookings' + query)
        .then(res => {
            dispatch({
                type: LIST_BOOKINGS,
                payload: res.data
            });
        })
        .catch(error => {
            throw (error);
        });
}

export const editBooking = (data) => dispatch => {
    
    axios
        .post(API_URL + 'bookings/edit', data)
        .then(res => {
            dispatch(listBookings());
        })
        .catch(error => {
            throw (error);
        });
}

export const listBookingRooms = (place_id = 0) => dispatch => {
    let getParam = '';
    if(place_id == 0) {
        place_id = Auth.getUserPlace();
        getParam += "?place_id=" + place_id;
    }

    axios
        .get(API_URL + 'bookingRooms' + getParam)
        .then(res => {
            dispatch({
                type: LIST_BOOKING_ROOMS,
                payload: res.data
            });
        })
        .catch(error => {
            throw (error);
        });
}


export const addEditBookingRoom = (data, history, langId) => dispatch => {
    if(!data.placeId) {
        data.placeId = Auth.getUserPlace();
    }

    dispatch(showLoading());

    axios
        .post(API_URL + 'bookingRooms', data)
        .then((post) => {
            post = post.data;
            dispatch(addSuccessMessage("Changes saved"))
            dispatch(listBookingRooms());
            if(post.deleted) {
                history.push("/admin/bookings/rooms");
                dispatch(addSuccessMessage("Successfully deleted row"))
                dispatch(hideLoading());
            } else {
                history.push("/admin/bookings/rooms/edit/"+post.id);   
                // dispatch(addSuccessMessage("Successful"))
                             
                dispatch(hideLoading());
            }
        })
        .catch(function (error) {
                dispatch(hideLoading());
                dispatch(addErrorMessages(error));
        })
}
