import axios from 'axios';
import { LIST_DELIVERY_SHAPES } from './types';
import { API_URL, ADMIN_URL } from '../../../constants';
import { addSuccessMessage, addErrorMessages } from '../../messages/messages.actions';
import { changeLanguage } from '../../../actions/languagesActions';
import Auth from '../../../utils/Auth';

export const listDeliveryShapes = (place_id = 0) => dispatch => {
    let getParam = '';
    if(place_id == 0) {
        place_id = Auth.getUserPlace();
        getParam += "?place_id=" + place_id;
    }

    axios
        .get(API_URL + 'delivery-shapes' + getParam)
        .then(res => {
            dispatch({
                type: LIST_DELIVERY_SHAPES,
                payload: res && res.data || []
            });
        })
        .catch(error => {
            throw (error);
        });
}


export const addEditDeliveryShape = (data, history) => dispatch => {
    if(!data.place_id) {
        data.placeId = Auth.getUserPlace();
    }
    
    axios
        .post(API_URL + 'delivery-shapes', data)
        .then((post) => {
            post = post.data;
                // dispatch(addSuccessMessage("Changes saved"))
                dispatch(listDeliveryShapes());
                history.push(ADMIN_URL + 'delivery-shapes');
        })
        .catch(function (error) {
            // dispatch(addErrorMessages('Error occured'));
        })
}
