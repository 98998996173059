// import MultiLanguage from "react-redux-multilang";
import { roTranslations } from './translations/ro';
import { enTranslations } from './translations/en';
import { bgTranslations } from './translations/bg';
import { esTranslations } from './translations/es';
export const translations =
//new MultiLanguage(
{
    en: enTranslations,
    bg: bgTranslations,
    ro: roTranslations,
    es: esTranslations
}
// )

