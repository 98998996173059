import React from 'react'
import { NavLink } from 'react-router-dom';
import { ADMIN_URL } from '../../../constants.js'
import Auth from '../../../utils/Auth.js';
import DeleteModal from '../../common/DeleteModal.js';
import Utils from '../../../utils/Utils.js';

const ListEachTableHall = (props) => {

    return (
        <tr key={props.hall.id}>
            <td>
                {props.hall.name}
            </td>
            <td>
                {props.hall.active ? <span className="badge badge-success">{props.translations.common.active}</span> : <span className="badge badge-secondary">{props.translations.common.inactive}</span>}
            </td>

            <td>
                <NavLink to={ADMIN_URL + 'restaurantHalls/edit/' + props.hall.id}>
                    <button className="btn btn-pill btn-link" type="button">
                        <i className="fas fa-edit"></i>
                    </button>
                </NavLink>

                <button className="btn btn-pill btn-link" type="button" data-toggle="modal" data-target={"#dangerModal" + props.hall.id} >
                    <i className="fas fa-trash"></i>
                </button>

                <DeleteModal
                    id={props.hall.id}
                    title={props.hall.name}
                    deleteFunc={props.deleteHall}
                    translations={props.translations}
                />

            </td>
        </tr >
    )
}

export default ListEachTableHall;