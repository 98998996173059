import React, { Component } from 'react'
import { connect } from 'react-redux';
import { addTable } from '../../actions/tablesActions';
import { listRestaurantHalls } from '../restaurantHalls/actions/restaurantHalls.actions';
import { listTableRegions } from '../table_regions/actions/tableRegions.actions';
import AddEditTableForm from './AddEditTableForm';
import { NavLink } from 'react-router-dom';
import { ADMIN_URL, API_TOKEN } from '../../constants';
import { addErrorMessages } from './../messages/messages.actions';
import { withRouter } from 'react-router-dom';
import { SCAN_URL } from './../../constants';
import Utils from '../../utils/Utils';
import Auth from './../../utils/Auth';

// let md5 = require('md5');

class AddTablePage extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			table_num: '',
			active: 1,
			place_in_url: 0,
			currentDate: + new Date(),
			// tableOrdertypes: [],
			is_for_app: 0,
			is_kiosk_mode: 0,

			tableOrdertype: '',
			tableOrdertypeValue: '',

			reservation_min_people: '',
			reservation_max_people: '',
			restaurantHallId: '',
			tableRegionId: '',
		}

		this.onChange = this.onChange.bind(this);
		this.onChangeCheckbox = this.onChangeCheckbox.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
	}

	componentWillMount() {
		this.props.listRestaurantHalls();
		this.props.listTableRegions();
	}

	onChange(e) {
		this.setState({ [e.target.name]: e.target.value })
	}

	onChangeCheckbox(e) {
		this.setState({ [e.target.name]: e.target.checked });
	}

	onChangeSelectAutocomplete = (fieldName, valueLabel) => {
		this.setState({ [fieldName]: valueLabel });
	}

	onFieldChange = (fieldName, value) => {
		this.setState({ [fieldName]: value });
	}

	validate() {
		let errors = [];

		if (this.state.table_num.length == 0) {
			errors.push(this.props.translations.messages.errors.invalid_table_num);
		}

		if (this.state.tableOrdertypeValue.length == '') {
			errors.push(this.props.translations.messages.errors.invalid_ordertype);
		}

		return errors;
	}

	onSubmit(e) {
		e.preventDefault();

		const { places, clients } = this.props;

		const errors = this.validate();
		if (errors.length > 0) {
			this.props.addErrorMessages(errors);
			return;
		}

		let currClient = '';
		let currPlace = '';

		if (places) {
			currPlace = places.find(p => p.id == Auth.getUserPlace());

			if (currPlace && Object.values(currPlace).length > 0 && currPlace.clientId) {
				currClient = clients.find(c => c.id == currPlace.clientId)
			}
		}

		let currClientSlug = '';
		if (currClient && Object.keys(currClient).length > 0 && currClient.slug) {
			currClientSlug = currClient.slug;
		}

		// let ordertypeIds = this.state.tableOrdertypes.map(c => c.value);

		const post = {
			// table_token: Utils.generateTableToken(this.state.table_num), // md5(md5(this.state.table_num + (this.state.currentDate))),
			table_num: this.state.table_num,
			active: this.state.active,
			is_for_app: this.state.is_for_app,
			is_kiosk_mode: this.state.is_kiosk_mode,
			place_in_url: this.state.place_in_url,
			place_url: this.state.place_in_url ? '/' + currClientSlug : '',
			tableOrdertypes: [this.state.tableOrdertypeValue.value],
			reservation_min_people: this.state.reservation_min_people || 0,
			reservation_max_people: this.state.reservation_max_people || 0,
			restaurantHallId: this.state.restaurantHallId || null,
			tableRegionId: this.state.tableRegionId || null

		}

		var canvas = document.getElementById('table-qr-code');
		let formData = new FormData()

		if (canvas) {
			var img = canvas.toDataURL("image/png");

			if (img) {
				// Image append
				formData.append("file", img)
			}
		}
		this.props.addTable(this.props.history, post, formData);
	}

	render() {
		return (
			<main className="main">

				{/* <!-- Breadcrumb--> */}
				<ol className="breadcrumb">
					<li className="breadcrumb-item"><NavLink to={ADMIN_URL}>{this.props.translations.common.dashboard}</NavLink></li>
					{/* <li className="breadcrumb-item">Меню</li> */}
					<li className="breadcrumb-item"><NavLink to={ADMIN_URL + "tables"}>{this.props.translations.tables.title}</NavLink></li>
					<li className="breadcrumb-item active">{this.props.translations.tables.add}</li>
					{/* <!-- Breadcrumb Menu--> */}
					<li className="breadcrumb-menu d-md-down-none">
						<div className="btn-group" role="group" aria-label="Button group">
							{/* 
                            <!--<a className="btn" href="./">
                                <i className="icon-graph"></i>  Dashboard</a>
                            <a className="btn" href="#">
                                <i className="icon-settings"></i>  Settings</a>
                            --> 
                            */}
						</div>
					</li>
				</ol>

				<div className="container-fluid">
					<div className="animated fadeIn">
						<div className="row">

							{/* <!-- /.col--> */}
							<div className="col-sm-12">
								<div className="card">

									<AddEditTableForm
										onSubmit={this.onSubmit}
										onChange={this.onChange}
										onChangeCheckbox={this.onChangeCheckbox}
										// onChangeSelectAutocomplete={this.onChangeSelectAutocomplete}
										onFieldChange={this.onFieldChange}
										table={this.state}
										restaurantHalls={this.props.restaurantHalls}
										tableRegions={this.props.tableRegions}

										tableNum={this.state.table_num}
										tableActive={this.state.active}
										placeInUrl={this.state.place_in_url}
										currentDate={this.state.currentDate}
										actionText="Добави"
										isAddingTable={1}
										nameError={this.props.errors.find(error => error.path == 'table_num') ? this.props.errors.find(error => error.path == 'table_num') : ""}
										translations={this.props.translations}
										currPlaceOrderTypes={this.props.currPlaceOrderTypes}
										tableOrdertypeValue={this.state.tableOrdertypeValue}

									/>

								</div>
							</div>

						</div>
					</div>
				</div>

			</main>
		)
	}
}

const mapStateToProps = state => {


	let orderTypes = [];

	if (state.places.places) {
		let currPlace = state.places.places.find(p => p.id == Auth.getUserPlace());
		if (currPlace) {
			orderTypes = currPlace.ordertypes;
		}
	}

	return {
		currPlaceOrderTypes: orderTypes,
		errors: state.tables.errors,
		restaurantHalls: state.restaurantHalls.restaurantHalls || [],
		tableRegions: state.tableRegions.tableRegions || [],
		translations: state.lang,
		clients: state.clients.clients,
		places: state.places.places,
	}
};

// const mapDispatchToProps = (dispatch, ownProps) => {
// 	return {
// 		addTable: (data, imageData) => dispatch(addTable(ownProps.history, data, imageData)),
// 		addErrorMessages: (msgs) => dispatch(addErrorMessages(msgs))
// 	}
// }

export default withRouter(connect(mapStateToProps, { addTable, addErrorMessages, listRestaurantHalls, listTableRegions })(AddTablePage));