import React from 'react'
import { NavLink } from 'react-router-dom';
import { ADMIN_URL } from '../../constants.js'
import moment from 'moment';
import Utils from '../../utils/Utils.js';
import Auth from '../../utils/Auth.js';


const ListEachBillWanted = (props) => {

    const { tableId, allBillWantedItems, translations, count, updateOrderByTableId } = props;

    let totaPriceTable = 0;

    let updateDate;
    let paymentMethod = '';

    // let orderDateDay = '';
    // let orderDateHour = '';

    let visibleOrderDate = ''

    const translatedPaymentMethods = {
        "card": translations.common.card,
        "cash": translations.common.cash,
        "on_room_bill": translations.common.on_room_bill,
    }
    let tableNum = 0;

    allBillWantedItems.filter(allbw => allbw.tableId == tableId).map((b, count) => {
        if (b.status != 'paid') {
            tableNum = b.table.table_num;
            updateDate = moment(b.createdAt).format('DD.MM.YYYY HH:mm');

            // orderDateDay = moment(b.createdAt).format('DD.MM.YYYY');
            // orderDateHour = moment(b.createdAt).format('HH:mm');

            visibleOrderDate = Utils.formatDate(moment(b.createdAt).format('DD.MM.YYYY HH:mm'));

            if (b.payment_method != null) {
                paymentMethod = translatedPaymentMethods[b.payment_method];
            }
            totaPriceTable = b.total_price;
        }
    });

    return (
        <div className="col-sm-6 col-md-4 col-lg-3">
            <div className="card">
                <div className="card-body p-0" >

                    <div style={{ display: 'grid', gridTemplateColumns: '50% 50%', textAlign: 'center' }} >
                        <div>
                            {props.translations.common.table}
                            <br />
                            {tableNum}
                        </div>
                        <div>
                            {/* <span style={{ color: 'rgb(83, 83, 83)' }}>
                            {props.translations.orders.order_date_short} #{props.userNeeds.id}</span>
                        <br /> */}
                            <b>{visibleOrderDate}</b>
                            {/* {orderDate} */}
                            {/* {updateDate} */}
                        </div>
                    </div>


                    <br />


                    <div className="text-value" style={{ textAlign: 'center', color: 'black' }}>
                        {paymentMethod} <button type="submit" class="btn btn-sm btn-success" onClick={() => { props.updateOrderByTableId({ tableId: tableId, status: "paid", placeId: Auth.getUserPlace() }) }} ><i class="fa fa-dot-circle-o"></i> {props.translations.common.paid}</button>
                        <br /><br />
                        {totaPriceTable} {props.translations.common.currency}
                        <br />
                        <br />
                    </div>

                </div>
            </div>
        </div>

        // <tr key={tableId}
        // >
        //     <td>
        //         {tableId}
        //     </td>

        //     <td>
        //         {updateDate}
        //     </td>

        //     <td>
        //         {paymentMethod}
        //     </td>

        //     <td>
        //         <button type="submit" class="btn btn-sm btn-success" onClick={() => { props.updateOrderByTableId({ tableId: tableId, status: "paid" }) }} ><i class="fa fa-dot-circle-o"></i> {props.translations.common.paid}</button>
        //         <br /><br />
        //         {Utils.normalizePrice(totaPriceTable)} {props.translations.common.currency}
        //     </td>
        // </tr>
    )

}

export default ListEachBillWanted;