import { NavLink } from 'react-router-dom';
import { ADMIN_URL, IMG_PATH } from '../../constants.js'
import DeleteSubcategoryModal from './DeleteSubcategoryModal';

import React, { Component } from 'react'
import Utils from '../../utils/Utils.js';

const ListEachSubcategory = (props) => {
    // let catName = props.categories[props.subcategory.categoryId] ? props.categories[props.subcategory.categoryId].name : "";
    let catName = typeof props.categories[props.subcategory.categoryId].translations[props.currLang] !== "undefined"
                ? props.categories[props.subcategory.categoryId].translations[props.currLang].name 
                : props.categories[props.subcategory.categoryId].name;

    if (catName.length > 0 && (!props.categories[props.subcategory.categoryId].active || props.categories[props.subcategory.categoryId].deleted)) {
        catName = (<span className="propery-not-found-when-listing">{catName}</span>);
    }
    if (!catName) {
        catName = (<span className="propery-not-found-when-listing">---</span>);
    }

    return (
        <tr key={props.subcategory.id}>
            <td>
                {/* {props.subcategory.name} */}
                {props.subcategory.translations[props.currLang] && props.subcategory.translations[props.currLang].name 
			        ? props.subcategory.translations[props.currLang].name : props.subcategory.name}
            </td>
            <td>
                {catName}
            </td>
            <td>
                {props.subcategory.icon ? <img style={{ maxHeight: 100 }} src={Utils.getSubcategoryImagePath(props.subcategory.icon)} /> : ""}
            </td>
            <td>
                {props.subcategory.active ? <span className="badge badge-success">{props.translations.common.active}</span> : <span className="badge badge-secondary">{props.translations.common.inactive}</span>}
            </td>
            <td>
                <NavLink to={ADMIN_URL + 'subcategories/edit/' + props.subcategory.id}>
                    <button className="btn btn-pill btn-link" type="button">
                        <i className="fas fa-edit"></i>
                    </button>
                </NavLink>

                <button className="btn btn-pill btn-link" type="button" data-toggle="modal" data-target={"#dangerModal" + props.subcategory.id}>
                    <i className="fas fa-trash"></i>
                </button>

                <DeleteSubcategoryModal
                    subcategoryId={props.subcategory.id}
                    subcategoryName={props.subcategory.name}
                    deleteSubcategory={props.deleteSubcategory}
                    translations={props.translations}
                />

            </td>
        </tr>
    )
}

export default ListEachSubcategory;