import React from 'react';
import { NavLink } from 'react-router-dom';
import { ADMIN_URL } from '../../../constants.js';
// import DeleteProductVariantModal from './DeleteProductVariantModal';
import MessagesContainer from './../../messages/MessagesContainer';
import Select from 'react-select';

const AddEditProductIngredientsForm = (props) => {

    // console.log("DEFAULT " , props.addableDefaultValues);

    // console.log("SELECT ", props.getSelectValues())


    return (
        <React.Fragment>

            {/* {Object.keys(props.variants).map((key, index) => {
                return <DeleteProductVariantModal
                    key={key}
                    variantId={props.variants[key].id}
                    variantName={props.variants[key].size}
                    deleteVariant={props.removeSize}
                    translations={props.translations}
                />
            })} */}
            <form onSubmit={props.addProductIngredient}>

                <MessagesContainer />
                <div className="card-body">

                    <div className="form-group">
                        <label>{props.translations.ingredients.required}</label>
                        <Select
                            value={props.stateData.requiredValues}
                            isMulti
                            name="colors"
                            options={props.selectValues}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            onChange={(values, { action, removedValue }) => props.ingredientsOnChange(values, { action, removedValue }, 'required')}
                        />
                    </div>


                    <div className="row">
                        <div className="col-md-8">
                            <div className="form-group">
                                <label>{props.translations.ingredients.addable}</label>
                                <div>
                                    <Select
                                        value={props.stateData.addableValues}
                                        isMulti
                                        name="colors"
                                        options={props.selectValues}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        onChange={(values, { action, removedValue }) => props.ingredientsOnChange(values, { action, removedValue }, 'addable')}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <label>{props.translations.common.min_count_addable_ingredients}</label>
                            <div class="input-group">
                                <input type="text" className="form-control" title={props.translations.common.min_count_addable_ingredients}
                                    placeholder={props.translations.common.min_count_addable_ingredients}
                                    name="minCountAddableIngredients" value={props.stateData.minCountAddableIngredients}
                                    onChange={props.ingredientsTabOnChange}
                                />
                                <span class="input-group-append" style={{ zIndex: 0 }}>
                                    <button type="button" class="btn btn-primary" name="minCountAddableIngredients" onClick={e => props.saveProduct('min_count_addable_ingredients', e.target.name)}>
                                        Запази
                                    </button>
                                </span>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <label>{props.translations.common.max_count_addable_ingredients}</label>
                            <div class="input-group">
                                <input type="text" className="form-control" title={props.translations.common.max_count_addable_ingredients}
                                    placeholder={props.translations.common.max_count_addable_ingredients}
                                    name="maxCountAddableIngredients" value={props.stateData.maxCountAddableIngredients}
                                    onChange={props.ingredientsTabOnChange}
                                />
                                <span class="input-group-append" style={{ zIndex: 0 }}>
                                    <button type="button" class="btn btn-primary" name="maxCountAddableIngredients" onClick={e => props.saveProduct('max_count_addable_ingredients', e.target.name)}>
                                        Запази
                                    </button>
                                </span>
                            </div>
                        </div>

                    </div>


                    <div className="form-group">
                        <label>{props.translations.ingredients.removable}</label>
                        <Select
                            value={props.stateData.removableValues}
                            isMulti
                            name="colors"
                            options={props.selectValues}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            onChange={(values, { action, removedValue }) => props.ingredientsOnChange(values, { action, removedValue }, 'removable')}
                        />
                    </div>


                </div>

            </form>
        </React.Fragment>
    )
}

export default AddEditProductIngredientsForm;