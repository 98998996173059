import axios from 'axios';
import { LIST_RESERVATIONS, LIST_RESERVATIONS_ERROR, RESERVATION_CREATED, RESERVATION_ERROR, RESERVATION_UPDATED, CLEAR_MESSAGES, CURRENTLY_EDITED_RESERVATION } from './types';
import { RESERVATIONS_API_URL, ADMIN_URL, API_TOKEN } from '../../../constants';
// const url_group = 'denis-errors/';
// import history from './../../../history';
import { push } from 'connected-react-router'
import Auth from '../../../utils/Auth';

const url_group = 'reservations/';
const customErrorMsg = "Възникна грешка #";
const listingUrl = 'reservations/';

const errorHandler = (error) => {
    let errorCode = 0;
    // https://gist.github.com/fgilio/230ccd514e9381fafa51608fcf137253
    if (error.response) {
        errorCode = 1;
        console.log(error.response.data); //DO NOT REMOVE
        console.log(error.response.status); //DO NOT REMOVE
        console.log(error.response.headers); //DO NOT REMOVE
        /*
         * The request was made and the server responded with a
         * status code that falls out of the range of 2xx
        */
    } else if (error.request) {
        errorCode = 2;
        /*
        * The request was made but no response was received, `error.request`
        * is an instance of XMLHttpRequest in the browser and an instance
        * of http.ClientRequest in Node.js
        */
        console.log(error.request); //DO NOT REMOVE
    } else {
        errorCode = 3;
        // Something happened in setting up the request and triggered an Error
        console.log('Error', error.message); //DO NOT REMOVE
    }

    return customErrorMsg + errorCode;
}

export const clearMessages = () => dispatch => {
    dispatch({
        type: CLEAR_MESSAGES
    })
}

export const setCurrentlyEditedReservation = (param) => dispatch => {
    dispatch({
        type: CURRENTLY_EDITED_RESERVATION,
        payload: param
    })
}

export const listReservations = (schedule = 1) => dispatch => {
    // let get = schedule ? '?exclude_cancelled=1' : '';
    let getParam = '?';
    getParam += 'place_id=' + Auth.getUserPlace()
    // getParam+='&a=b'

    axios
        .get(RESERVATIONS_API_URL + url_group + 'read' + getParam, { headers: { Authorization: API_TOKEN } })
        .then(res => {
            dispatch({
                type: LIST_RESERVATIONS,
                payload: res.data
            })
            dispatch(clearMessages())
            // dispatch(push(ADMIN_URL + listingUrl))
        }).catch(error => {
            dispatch({
                type: LIST_RESERVATIONS_ERROR,
                payload: (error.response && error.response.data) ? error.response.data : { message: errorHandler(error) }
            })
        });
}

export const addReservation = (data) => dispatch => {
    const headers = {
        'Content-Type': 'application/json',
        'Cookie': `place=${data.place}` // Adding the cookie to the headers
    };

    axios
        .post(RESERVATIONS_API_URL + url_group + 'admin', data/*, { headers }*/)
        .then(res => {
            dispatch(listReservations());
            dispatch({
                type: res.data.success ? RESERVATION_CREATED : RESERVATION_ERROR,
                payload: res.data.payload
            })
            dispatch(setCurrentlyEditedReservation(true))
            if (res.data.success) {
                dispatch(push(ADMIN_URL + listingUrl))
            }
        }
        ).catch(error => {
            dispatch({
                type: RESERVATION_ERROR,
                payload: (error.response && error.response.data) ? error.response.data : { message: errorHandler(error) }
            })
        });
}

export const editReservation = (data) => dispatch => {
    console.log('data EDIT RESERVATIONS SAVE', data);
    axios
        .post(RESERVATIONS_API_URL + url_group + 'edit', data)
        .then(res => {
            dispatch(listReservations());
            dispatch({
                type: res.data.success ? RESERVATION_UPDATED : RESERVATION_ERROR,
                payload: res.data.payload
            })
            dispatch(setCurrentlyEditedReservation(true))
            if (res.data.success) {
                dispatch(push(ADMIN_URL + listingUrl))
            }


            // dispatch(push(ADMIN_URL + "reservations-reservations/edit/" + res.data.reservation_id))
        }).catch(error => {
            dispatch({
                type: RESERVATION_ERROR,
                payload: (error.response && error.response.data) ? error.response.data : { message: errorHandler(error) }
            })
        });
}