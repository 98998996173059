import { LIST_ALERGENS } from '../actions/types';

const initialState = {
    alergens: [],
    errors: [],
}

export default function(state = initialState, action) {
    switch(action.type) {
        case LIST_ALERGENS:
            return {
                ...state,
                alergens: action.payload,
                errors: [] // Reset add/edit categories error
            }
        // case ADD_CATEGORY:
        //     return {
        //         ...state,
        //         item: action.payload
        //     }
        // case ADD_EDIT_CATEGORY_ERROR:
        //     return {
        //         ...state,
        //         errors: action.payload
        //     }
        // case EDIT_CATEGORY:
        //     return {
        //         ...state,
        //         item: action.payload
        //     }
        default:
            return state;
    }
}