import React from 'react'
import { NavLink } from 'react-router-dom';
import { ADMIN_URL } from '../../../constants.js'
import DeleteModal from '../../common/DeleteModal.js';

const ListDeliveryShapeRow = (props) => (
    <tr key={props.whs.id}>
        <td>
            {props.whs.name}
        </td>
        <td>
            -
        </td>
        <td>
            <NavLink to={ADMIN_URL+'warehouse-systems/edit/' + props.whs.id}>
                <button className="btn btn-pill btn-link" type="button">
                    <i className="fas fa-edit"></i>
                </button>
            </NavLink>
        </td>
    </tr>
)

export default ListDeliveryShapeRow;