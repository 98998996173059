import React, { Component } from 'react'
import { connect } from 'react-redux';
import { listQuestions, addEditQuestion } from './../actions/feedback.actions';
import ListQuestionRow from './ListQuestionRow';
import Pagination from "react-js-pagination";
import { NavLink } from 'react-router-dom';
import { push } from 'react-router-redux';
import { ADMIN_URL, ITEMS_PER_PAGE, ITEMS_PAGE_RANGE_DISPLAYED } from '../../../constants.js'
import { bindActionCreators } from 'redux'

class ListQuestionsPage extends React.Component {
    constructor(props){
        super(props);
        // this.activeDefaultLanguage = 'bg'; // TODO add it in db
    }
  
    componentWillMount() {
        this.props.listQuestions();
    }

    deleteQuestion = (e, id) => {
        e.preventDefault();
        let data = {
            id: id,
            deleted: true
        };
        this.props.addEditQuestion(data);
    }
    
    handlePageChange = (page) => {
        this.props.dispatch(push('/admin/feedback/questions?page='+page))
    }

    render() {
        let questionsList = this.props.feedbackQuestions;

        const items_count = Object.keys(questionsList).length
        const current_page = this.props.page;
        const start_offset = (current_page - 1) * ITEMS_PER_PAGE;
        let start_count = 0; 

        return (
            <main className="main">
                    
                {/* <!-- Breadcrumb--> */}
                <ol className="breadcrumb">
                <li className="breadcrumb-item"><NavLink to={ADMIN_URL}>{this.props.translations.common.dashboard}</NavLink></li>
					{/* <li className="breadcrumb-item">Меню</li> */}
                    <li className="breadcrumb-item active">{this.props.translations.feedback.questions.title}</li>
                    {/* <!-- Breadcrumb Menu--> */}
                    <li className="breadcrumb-menu d-md-down-none">
                        <div className="btn-group" role="group" aria-label="Button group">
                            <NavLink to={ADMIN_URL+`feedback/questions/add`} className="btn" >
                                <i className="fas fa-plus"></i> &nbsp; {this.props.translations.feedback.questions.add}
                            </NavLink>
                            {/* 
                            <!--<a className="btn" href="./">
                                <i className="icon-graph"></i>  Dashboard</a>
                            <a className="btn" href="#">
                                <i className="icon-settings"></i>  Settings</a>
                            --> 
                            */}
                        </div>
                    </li>
                </ol>


                <div className="container-fluid">
                    <div className="animated fadeIn">
                        <div className="row">
                    
                            {/* <!-- /.col--> */}
                            <div className="col-lg-12">
                                <div className="card">
                                    
                                    <div className="card-body">
                                        <table className="table table-responsive-sm table-striped">
                                            <thead>
                                                <tr>
                                                    <th>{this.props.translations.feedback.questions.question}</th>
                                                    <th>{this.props.translations.feedback.questions.question_type}</th>
                                                    <th>{this.props.translations.common.active}</th>
                                                    <th>{this.props.translations.common.action}</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                {Object.values(questionsList).map((question, index) => {
                                                    if(index >= start_offset && start_count < ITEMS_PER_PAGE) {
                                                        start_count++;

                                                        return <ListQuestionRow 
                                                            key={question.id} 
                                                            question={question} 
                                                            deleteFunc={this.deleteQuestion}
                                                            translations={this.props.translations}
                                                            // activeDefaultLanguage={this.activeDefaultLanguage}
                                                        />
                                                    }
                                                })}
                                                
                                            </tbody>
                                        </table>
                                    
                                        <Pagination
                                            className="pagination"
                                            itemClass="page-item"
                                            activePage={current_page}
                                            activeClass="active"
                                            linkClass="page-link"
                                            prevPageText="<"
                                            nextPageText=">"
                                            firstPageText="<<"
                                            lastPageText=">>"
                                            itemsCountPerPage={ITEMS_PER_PAGE}
                                            totalItemsCount={items_count}
                                            pageRangeDisplayed={ITEMS_PAGE_RANGE_DISPLAYED}
                                            onChange={this.handlePageChange}
                                            />

                                        {/* <ul className="pagination">
                                            <li className="page-item">
                                                <a className="page-link" href="#">Prev</a>
                                            </li>
                                            <li className="page-item active">
                                                <a className="page-link" href="#">1</a>
                                            </li>
                                            <li className="page-item">
                                                <a className="page-link" href="#">2</a>
                                            </li>
                                            <li className="page-item">
                                                <a className="page-link" href="#">3</a>
                                            </li>
                                            <li className="page-item">
                                                <a className="page-link" href="#">4</a>
                                            </li>
                                            <li className="page-item">
                                                <a className="page-link" href="#">Next</a>
                                            </li>
                                        </ul> */}
                                    </div>
                                </div>
                            </div>
                            {/* <!-- /.col--> */}
                        </div>
                    </div>
                </div>
            </main>
        )
    }
}

const mapStateToProps = state => ({
    feedbackQuestions: state.feedback.questions || [],
    page: Number(state.router.location.query.page) || 1,
    translations: state.lang
});

const mapDispatchToProps = (dispatch, props) => {
    return {
        dispatch,
        ...bindActionCreators({ listQuestions, addEditQuestion }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ListQuestionsPage);
