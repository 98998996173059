import React, { Component } from 'react'
import SimpleLineIcon from 'react-simple-line-icons'; /* DO NOT REMOVE, IT IS USED */
import { connect } from 'react-redux';
import Utils from '../../utils/Utils';
import { NavLink } from 'react-router-dom';
import { ADMIN_URL } from '../../constants'
// import htmlToImage from 'html-to-image';
import * as htmlToImage from 'html-to-image';
import html2canvas from 'html2canvas';

import Cookies from 'universal-cookie';
import Auth from '../../utils/Auth';
const cookies = new Cookies();

/*
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
*/

class GenerateShareableImagePage extends React.Component {
    constructor(props) {
        super(props);
        this.targetRef = React.createRef();

        this.state = {
            image: null,
            title: localStorage.getItem('tabl_admin_lunch_menu') ? JSON.parse(localStorage.getItem('tabl_admin_lunch_menu')).title : 'ОБЕДНО МЕНЮ',
            description: localStorage.getItem('tabl_admin_lunch_menu') ? JSON.parse(localStorage.getItem('tabl_admin_lunch_menu')).description : `по трандиционни, български рецепти, 
с био продукти, за здравословен живот`,
            date: this.getCurrentDate(),
            image: '',
            // background: 'rgba(255, 201, 121, 0.3)',
            background: localStorage.getItem('tabl_admin_lunch_menu') ? JSON.parse(localStorage.getItem('tabl_admin_lunch_menu')).background : "#ffc979",
            color: localStorage.getItem('tabl_admin_lunch_menu') ? JSON.parse(localStorage.getItem('tabl_admin_lunch_menu')).color : "#000000",
            opacity: localStorage.getItem('tabl_admin_lunch_menu') ? JSON.parse(localStorage.getItem('tabl_admin_lunch_menu')).opacity : '0.3',
            productPaddingTop: localStorage.getItem('tabl_admin_lunch_menu') ? JSON.parse(localStorage.getItem('tabl_admin_lunch_menu')).productPaddingTop : 0,
            coloredContainerWidth: localStorage.getItem('tabl_admin_lunch_menu') ? JSON.parse(localStorage.getItem('tabl_admin_lunch_menu')).coloredContainerWidth : 'fit-content',
            fontSizeProductContainer: localStorage.getItem('tabl_admin_lunch_menu') ? JSON.parse(localStorage.getItem('tabl_admin_lunch_menu')).fontSizeProductContainer : 16,
            fontFamilyProductContainer: localStorage.getItem('tabl_admin_lunch_menu') ? JSON.parse(localStorage.getItem('tabl_admin_lunch_menu')).fontFamilyProductContainer : '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";',
            fontWeightProductContainer: localStorage.getItem('tabl_admin_lunch_menu') ? JSON.parse(localStorage.getItem('tabl_admin_lunch_menu')).fontWeightProductContainer : 400,
            includeProductDescriptions: localStorage.getItem('tabl_admin_lunch_menu') ? JSON.parse(localStorage.getItem('tabl_admin_lunch_menu')).includeProductDescriptions : false,
            showDots: localStorage.getItem('tabl_admin_lunch_menu') ? JSON.parse(localStorage.getItem('tabl_admin_lunch_menu')).showDots : false,
            hide_category_name: localStorage.getItem('tabl_admin_lunch_menu') ? JSON.parse(localStorage.getItem('tabl_admin_lunch_menu')).hide_category_name : false,
            show_allergens: localStorage.getItem('tabl_admin_lunch_menu') ? JSON.parse(localStorage.getItem('tabl_admin_lunch_menu')).show_allergens : false,
            boldPrices: localStorage.getItem('tabl_admin_lunch_menu') ? JSON.parse(localStorage.getItem('tabl_admin_lunch_menu')).boldPrices : true,
            boldTitle: localStorage.getItem('tabl_admin_lunch_menu') ? JSON.parse(localStorage.getItem('tabl_admin_lunch_menu')).boldTitle : true,
            hideFooter: localStorage.getItem('tabl_admin_lunch_menu') ? JSON.parse(localStorage.getItem('tabl_admin_lunch_menu')).hideFooter : false,
            marginTop: localStorage.getItem('tabl_admin_lunch_menu') ? JSON.parse(localStorage.getItem('tabl_admin_lunch_menu')).marginTop : 0,
        }
    }

    componentDidMount() {
        let gtrans = document.getElementById('gTanslateElem');
        if (gtrans) {
            gtrans.style.display = 'block';
        }
    }

    componentWillUnmount() {
        let gtrans = document.getElementById('gTanslateElem');
        if (gtrans) {
            gtrans.style.display = 'none';
        }

        if (cookies.get('googtrans')) {
            cookies.remove('googtrans', { path: '/' });
            cookies.remove('googtrans', { domain: '.tabl.bg', path: '/' });
            window.location.reload(false);
        }
    }
    getCurrentDate = () => {
        const now = new Date();
        var weekday = new Array(7);
        weekday[0] = "Неделя";
        weekday[1] = "Понеделник";
        weekday[2] = "Вторник";
        weekday[3] = "Сряда";
        weekday[4] = "Четвъртък";
        weekday[5] = "Петък";
        weekday[6] = "Събота";

        let day = now.getDate();
        let month = Utils.appendLeadingZeroes(parseInt(parseInt(now.getMonth()) + 1))
        var week_day_name = weekday[now.getDay()];

        return day + '.' + month + '-' + week_day_name;
    }

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    onFileChange = (e) => {
        this.setState({ image: URL.createObjectURL(e.target.files[0]) })
    }

    generateImage = () => {
        if (this.targetRef.current) {
            html2canvas(this.targetRef.current).then((canvas) => {
                const image = canvas.toDataURL('image/png');

                const today = this.getCurrentDate();

                // Create a download link for the image
                const link = document.createElement('a');
                link.href = image;
                link.download = 'Дневно-меню-' + today + '.png';
                link.click();
            });
        }
    };


    generateImage2 = () => {
        const today = this.getCurrentDate();
        htmlToImage.toPng(document.getElementById('my-node'))
            .then(function (dataUrl) {
                var img = new Image();
                img.src = dataUrl;

                var link = document.createElement('a');
                link.download = 'Дневно-меню-' + today + '.jpeg';
                link.href = dataUrl;
                link.click();

                // var link = document.createElement('a');
                // link.download = 'my-image-name.jpeg';
                // link.href = dataUrl;
                // link.click();

                // document.body.appendChild(img);
                // this.setState({ image: img })
            })
            .catch(function (error) {
                console.error('oops, something went wrong!', error);
            });

        // htmlToImage.toPng(document.getElementById('my-node'))
        //     .then(function (dataUrl) {
        //         download(dataUrl, 'my-node.png');
        //     });

        // htmlToImage.toJpeg(document.getElementById('my-node'), { quality: 1 })
        //     .then(function (dataUrl) {
        //         var link = document.createElement('a');
        //         link.download = 'my-image-name.jpeg';
        //         link.href = dataUrl;
        //         link.click();
        //     });

        // htmlToImage.toBlob(document.getElementById('my-node'))
        //     .then(function (blob) {
        //         window.saveAs(blob, 'my-node.png');
        //     });

        // htmlToImage.toPng(document.getElementById('my-node'))
        //     .then(function (dataUrl) {
        //         var img = new Image();
        //         img.src = dataUrl;
        //         document.body.appendChild(img);
        //     })
        //     .catch(function (error) {
        //         console.error('oops, something went wrong!', error);
        //     });

    }

    checkIfCurrentProductInAnotherCatsNotChildsOfSelected = (currProdCatId) => {
        const { cats, currentCatId } = this.props;

        let res = false;

        // if (currProdCatId == currentCatId) {
        //     return true;
        // }
        if (cats) {
            let selectedCat = Object.values(cats).find(c => c.id == currentCatId);
            if (selectedCat) {
                if (selectedCat.children && Object.values(selectedCat.children).length > 0) {
                    res = selectedCat.children.find(cc => cc.id == currProdCatId) || false
                } else {
                    res = selectedCat;
                }
            }
        }

        return res;
    }

    removeTags = (str) => {
        if ((str === null) || (str === ''))
            return false;
        else
            str = str.toString();

        // Regular expression to identify HTML tags in
        // the input string. Replacing the identified
        // HTML tag with a null string.
        str = str.replace(/(<([^>]+)>)/ig, '');
        str = str.replace(/&nbsp;/g, '');
        return str
    }

    saveSettingsToLocalStorage = () => {
        console.log('save ', this.state);
        localStorage.setItem('tabl_admin_lunch_menu',
            JSON.stringify(this.state)
        )
    }



    render() {
        const { places, clients } = this.props;
        let currPlace = '';
        let currClient = '';

        if (places) {
            currPlace = places.find(p => p.id == Auth.getUserPlace());
            if (currPlace && Object.values(currPlace).length > 0 && currPlace.clientId) {
                currClient = clients.find(c => c.id == currPlace.clientId)
            }
        }

        let currClientSlug = '';
        if (currClient && Object.keys(currClient).length > 0 && currClient.slug) {
            currClientSlug = currClient.slug;
        }

        let footerTexts = <div dangerouslySetInnerHTML={{
            __html: Utils.getSettingsValue(this.props.settings, 'lunch_menu_footer_text') || ((window.location.hostname == 'localhost' ? 'localhost:3000' : window.location.origin) + '/' + currClientSlug)
        }}></div>

        let productsWithCat = [];
        let catsDatas = [];


        this.props.prods.sort(Utils.compareForPriority()).map(p => {
            if (p.active == false) {
                return false; //skip
            }

            // For each product cat
            for (let i = 0; i < p.cat_hierarchies.length; i++) {

                let ccCat = this.checkIfCurrentProductInAnotherCatsNotChildsOfSelected(p.cat_hierarchies[i].id);
                if (!ccCat) {
                    continue; //skip
                }

                if (typeof productsWithCat[p.cat_hierarchies[i].name] === "undefined") {
                    productsWithCat[p.cat_hierarchies[i].name] = [];
                }

                if (!catsDatas.find(cd => cd.id == p.cat_hierarchies[i].id)) {
                    catsDatas.push(ccCat) //curent cat with priority data
                }

                // For each product variant
                Object.values(p.variants).map(v => {
                    // console.log('p', p);

                    const allergens = p.allergens && Object.values(p.allergens).length > 0 ? Object.values(p.allergens).map(allergen => allergen.translations.bg.name) : null;
                    // console.log('allergens', allergens);

                    // console.log('v.size', v.size)
                    productsWithCat[p.cat_hierarchies[i].name].push(
                        <>
                            {this.state.showDots ?
                                <li><span style={{ fontWeight: this.state.boldTitle ? 'bold' : 'unset' }}>{p.name}</span> {p.description && this.state.includeProductDescriptions ? <div>(<span style={{ /*fontStyle: 'italic'*/ 'font-size': (parseInt(this.state.fontSizeProductContainer) - 2) + 'px' }}>{this.removeTags(p.description)}</span>)</div> : null}  {this.state.show_allergens && allergens ? <div style={{ fontStyle: 'italic', 'font-size': (parseInt(this.state.fontSizeProductContainer) - 2) + 'px' }}>Алергени: {allergens.join(',')}</div> : null} <span style={{ display: this.state.includeProductDescriptions ? 'block' : 'inline' }}>
                                    <div>{v.size && v.size != ' ' ? <> {/*this.state.includeProductDescriptions ? null : <>-</>*/} <i>{v.size}</i></> : null}{this.state.boldPrices ? <b> - {v.price} лв.</b> : <span> - {v.price} лв.</span>}</div>
                                    </span></li>
                                :
                                <span><span style={{ fontWeight: this.state.boldTitle ? 'bold' : 'unset' }}>{p.name}</span> {p.description && this.state.includeProductDescriptions ? <div>(<span style={{ /*fontStyle: 'italic'*/ 'font-size': (parseInt(this.state.fontSizeProductContainer) - 2) + 'px' }}>{this.removeTags(p.description)}</span>)</div> : null} {this.state.show_allergens && allergens ? <div style={{ fontStyle: 'italic', 'font-size': (parseInt(this.state.fontSizeProductContainer) - 2) + 'px' }}>Алергени: {allergens.join(',')}</div> : null}  <span style={{ display: this.state.includeProductDescriptions ? 'block' : 'inline' }}>
                                    <div>{v.size && v.size != ' ' ? <> {/*this.state.includeProductDescriptions ? null : <>-</>*/} <i>{v.size}</i></> : null}{this.state.boldPrices ? <b> - {v.price} лв.</b> : <span> - {v.price} лв.</span>}</div>
                                    </span></span>
                            }
                        </>
                    )

                })

            }

        })

        let sortedCats = []
        if (catsDatas) {
            sortedCats = (catsDatas).sort(Utils.compareForPriority())
        }

        return Utils.hasFunctionalityModule('lunch_menu') ? (
            <main className="main">
                {/* <!-- Breadcrumb--> */}
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">{this.props.translations.common.dashboard}</li>
                    {/* <!--<li className="breadcrumb-item">
                        <a href="#">Admin</a>
                    </li>
                    <li className="breadcrumb-item active">Dashboard</li>
                    --> */}
                    {/* <!-- Breadcrumb Menu--> */}
                    <li className="breadcrumb-menu d-md-down-none">
                        <div className="btn-group" role="group" aria-label="Button group">
                            {/* <!--<a className="btn" href="https://test.tabl.site" target="_blank">
                                <i className="icon-screen-tablet"></i> Клиентска система
                            </a>--> */}
                            {/* <!--<a className="btn" href="./">
                                <i className="icon-graph"></i>  Dashboard</a>
                                <a className="btn" href="#">
                                <i className="icon-settings"></i>  Settings</a>
                            --> */}
                        </div>
                    </li>
                </ol>
                <div className="container-fluid">
                    <div className="animated fadeIn">

                        <div className="row">

                            <div className="form-group reservation-list-search col-md-3 col-sm-6 " >
                                <div className="form-group">
                                    <label htmlFor="title">{this.props.translations.common.title}</label>
                                    <input className={`form-control`} id="title" type="text" placeholder=""
                                        name="title" onChange={(e) => this.onChange(e)} value={this.state.title} />
                                </div>
                            </div>

                            <div className="form-group reservation-list-search col-md-3 col-sm-6 ">
                                <div className="form-group">
                                    <label htmlFor="description">{this.props.translations.common.description}</label>
                                    <textarea id="textarea-input" name="description" rows="2" class="form-control" onChange={(e) => this.onChange(e)}>{this.state.description}</textarea>
                                </div>
                            </div>

                            <div className="form-group reservation-list-search col-md-2 col-sm-6 ">
                                <div className="form-group">
                                    <label htmlFor="date">{this.props.translations.common.date}</label>
                                    <input className={`form-control`} id="date" type="text" placeholder=""
                                        name="date" onChange={(e) => this.onChange(e)} value={this.state.date} />
                                </div>
                            </div>

                            <div className="form-group reservation-list-search col-md-2 col-sm-6 ">
                                <div className="form-group">
                                    <label htmlFor="image">{this.props.translations.common.image}</label>
                                    <input id="image" type="file" placeholder=""
                                        name="image" onChange={(e) => this.onFileChange(e)} />
                                </div>
                            </div>

                            <div className="form-group reservation-list-search col-md-2 col-sm-6 ">
                                <div className="form-group">
                                    <label htmlFor="background">{this.props.translations.common.background}/{this.props.translations.common.color}</label>
                                    <br />
                                    <input id="background" type="color" name="background" onChange={(e) => this.onChange(e)} value={this.state.background} />
                                    <input id="color" type="color" name="color" onChange={(e) => this.onChange(e)} value={this.state.color} />
                                    <br />
                                    <br />
                                    Opacity: <input type="range" min="0" max="1" step="0.1" name="opacity" onChange={(e) => this.onChange(e)} value={this.state.opacity} />
                                    <br />
                                    <br />
                                </div>
                            </div>

                        </div>
                        <div className="row">
                            <div className="form-group reservation-list-search col-md-1 col-sm-6 ">
                                <div className="form-group">
                                    Разстояние от горната част: <input className={`form-control`} id="marginTop" type="number" placeholder="" step={5}
                                        name="marginTop" onChange={(e) => this.onChange(e)} value={this.state.marginTop} />
                                </div>
                            </div>

                            <div className="form-group reservation-list-search col-md-1 col-sm-6 ">
                                <div className="form-group">
                                    Разстояние между редовете: <input className={`form-control`} id="productPaddingTop" type="number" placeholder=""
                                        name="productPaddingTop" onChange={(e) => this.onChange(e)} value={this.state.productPaddingTop} />
                                </div>
                            </div>




                            <div className="form-group reservation-list-search col-md-2 col-sm-6 ">
                                <div className="form-group">
                                    Ширина на вътрешния контейнер за продуктите: <input type="range" min="400" max="1000" step="10" name="coloredContainerWidth" onChange={(e) => this.onChange(e)} value={this.state.coloredContainerWidth} />
                                    <input type="checkbox" name="opacity" onChange={(e) => this.setState({ coloredContainerWidth: 'fit-content' })} checked={this.state.coloredContainerWidth == 'fit-content'} />
                                </div>
                            </div>



                            <div className="form-group reservation-list-search col-md-2 col-sm-6 ">
                                <div className="form-group">
                                    Размер на шрифта: <input type="number" min="16" max="25" step="1" name="fontSizeProductContainer" onChange={(e) => this.onChange(e)} value={this.state.fontSizeProductContainer} />
                                </div>
                            </div>

                            <div className="form-group reservation-list-search col-md-1 col-sm-6 ">
                                <div className="form-group">
                                    Шрифт:
                                    <select name="fontFamilyProductContainer" onChange={(e) => this.onChange(e)} value={this.state.fontFamilyProductContainer} style={{ width: '100%' }}>

                                        <option value='"Gill Sans Extrabold", sans-serif'>"Gill Sans Extrabold", sans-serif</option>
                                        <option value='"Goudy Bookletter 1911", sans-serif'>"Goudy Bookletter 1911", sans-serif</option>

                                        <option value="serif">serif</option>
                                        <option value="sans-serif">sans-serif</option>
                                        <option value="monospace">monospace</option>
                                        <option value="cursive">cursive</option>
                                        <option value="fantasy">fantasy</option>
                                        <option value="system-ui">system-ui</option>
                                        <option value="ui-serif">ui-serif</option>
                                        <option value="ui-sans-serif">ui-sans-serif</option>
                                        <option value="ui-monospace">ui-monospace</option>
                                        <option value="ui-rounded">ui-rounded</option>
                                        <option value="emoji">emoji</option>
                                        <option value="math">math</option>
                                        <option value="fangsong">fangsong</option>

                                        <option value="inherit">inherit</option>
                                        <option value="initial">initial</option>
                                        <option value="revert">revert</option>
                                        <option value="revert-layer">revert-layer</option>
                                        <option value="unset">unset</option>



                                        <option value="Helvetica">Helvetica</option>
                                        <option value="Arial">Arial</option>
                                        <option value="Arial Black">Arial Black</option>
                                        <option value="Verdana">Verdana</option>
                                        <option value="Tahoma">Tahoma</option>
                                        <option value="Trebuchet MS">Trebuchet MS</option>
                                        <option value="Impact">Impact</option>
                                        <option value="Gill Sans">Gill Sans</option>
                                        <option value="Times New Roman">Times New Roman</option>
                                        <option value="Georgia">Georgia</option>
                                        <option value="Palatino">Palatino</option>
                                        <option value="Baskerville">Baskerville</option>
                                        <option value="Andalé Mono">Andalé Mono</option>
                                        <option value="Courier">Courier</option>
                                        <option value="Lucida">Lucida</option>
                                        <option value="Monaco">Monaco</option>
                                        <option value="Bradley Hand">Bradley Hand</option>
                                        <option value="Brush Script MT">Brush Script MT</option>
                                        <option value="Luminari">Luminari</option>
                                        <option value="Comic Sans MS">Comic Sans MS</option>


                                    </select>

                                </div>
                            </div>

                            <div className="form-group reservation-list-search col-md-1 col-sm-6 ">
                                <div className="form-group">
                                    Дебелина:
                                    <select name="fontWeightProductContainer" onChange={(e) => this.onChange(e)} value={this.state.fontWeightProductContainer}>
                                        <option value='100'>100</option>
                                        <option value='200'>200</option>
                                        <option value='300'>300</option>
                                        <option value='400'>400</option>
                                        <option value='500'>500</option>
                                        <option value='600'>600</option>
                                        <option value='700'>700</option>
                                        <option value='800'>800</option>
                                        <option value='900'>900</option>
                                    </select>

                                </div>
                            </div>


                            <div className="form-group reservation-list-search col-md-1 col-sm-6 ">
                                <div className="form-group">
                                    Скрии Footer <input type="checkbox" name="hideFooter" onChange={(e) => this.setState({ hideFooter: !this.state.hideFooter })} checked={this.state.hideFooter == true} />
                                </div>
                            </div>

                            <div className="form-group reservation-list-search col-md-1 col-sm-6 ">
                                <div className="form-group">
                                    Удебелени заглавие <input type="checkbox" name="boldTitle" onChange={(e) => this.setState({ boldTitle: !this.state.boldTitle })} checked={this.state.boldTitle == true} />
                                </div>
                            </div>

                            <div className="form-group reservation-list-search col-md-1 col-sm-6 ">
                                <div className="form-group">
                                    Удебелени цени <input type="checkbox" name="boldPrices" onChange={(e) => this.setState({ boldPrices: !this.state.boldPrices })} checked={this.state.boldPrices == true} />
                                </div>
                            </div>


                            <div className="form-group reservation-list-search col-md-1 col-sm-6 ">
                                <div className="form-group">
                                    Покажи и описанията на продуктите: <input type="checkbox" name="includeProductDescriptions" onChange={(e) => this.setState({ includeProductDescriptions: !this.state.includeProductDescriptions })} checked={this.state.includeProductDescriptions == true} />
                                </div>
                            </div>

                            <div className="form-group reservation-list-search col-md-1 col-sm-6 ">
                                <div className="form-group">
                                    Покажи точките на началния ред: <input type="checkbox" name="showDots" onChange={(e) => this.setState({ showDots: !this.state.showDots })} checked={this.state.showDots == true} />
                                </div>
                            </div>

                            <div className="form-group reservation-list-search col-md-1 col-sm-6 ">
                                <div className="form-group">
                                    Скрий изписването на името на категорията: <input type="checkbox" name="hide_category_name" onChange={(e) => this.setState({ hide_category_name: !this.state.hide_category_name })} checked={this.state.hide_category_name == true} />
                                </div>
                            </div>

                            <div className="form-group reservation-list-search col-md-1 col-sm-6 ">
                                <div className="form-group">
                                    Покажи алергени: <input type="checkbox" name="show_allergens" onChange={(e) => this.setState({ show_allergens: !this.state.show_allergens })} checked={this.state.show_allergens == true} />
                                </div>
                            </div>



                        </div>

                        <button className="btn btn-pill btn-link" type="button" onClick={() => this.generateImage()}>
                            Download
                        </button>

                        <button className="btn btn-pill btn-link" type="button" onClick={() => this.saveSettingsToLocalStorage()}>
                            Запази настройките
                        </button>

                        <div id="my-node" ref={this.targetRef} className="generate_shareable_image_image-container" style={{ backgroundImage: 'url(' + this.state.image + ')' }}>

                            <div className="generate_shareable_image_header"
                                style={{
                                    color: this.state.color,
                                    background: 'rgba(' + parseInt(this.state.background.slice(-6, -4), 16) + ',' + parseInt(this.state.background.slice(-4, -2), 16) + ',' + parseInt(this.state.background.slice(-2), 16) + ',' + this.state.opacity + ')',
                                    width: (this.state.coloredContainerWidth == 'fit-content' ? this.state.coloredContainerWidth : (this.state.coloredContainerWidth + 'px')),
                                    'font-size': this.state.fontSizeProductContainer + 'px',
                                    'font-family': this.state.fontFamilyProductContainer,
                                    'font-weight': this.state.fontWeightProductContainer,
                                    marginTop: this.state.marginTop + 'px'
                                }}>

                                {this.state.title ?
                                    <div className="generate_shareable_image_title"
                                        style={{ 'font-size': (parseInt(this.state.fontSizeProductContainer) + 4) + 'px' }}
                                    >
                                        {this.state.title}</div>
                                    :
                                    null
                                }
                                {this.state.description ? <div className="generate_shareable_image_description">{this.state.description}</div> : null}
                                {this.state.date ? <div className="generate_shareable_image_date">{this.state.date}</div> : null}

                                {/* This filter gets unique records */}
                                {sortedCats.filter((value, index, self) => self.indexOf(value) === index).map(c => (
                                    <div style={{ marginTop: parseInt(this.state.productPaddingTop) }}>
                                        {this.state.hide_category_name ? null :
                                            <div className="generate_shareable_image_category">
                                                {c && c.name ? c.name : ''}
                                            </div>
                                        }
                                        {productsWithCat[c && c.name].map(pp =>
                                            <div className="each-product" style={{ marginTop: parseInt(this.state.productPaddingTop) }}>
                                                {pp}
                                            </div>
                                        )}
                                    </div>

                                ))}

                            </div>

                            {this.state.hideFooter ?
                                null :
                                <>
                                    <div className="generate_shareable_image_subfooter"
                                        style={{
                                            'font-size': (parseInt(this.state.fontSizeProductContainer) + 1),
                                            bottom: (40 + (parseInt(this.state.fontSizeProductContainer) - 16 + 5))
                                        }}
                                    >{footerTexts}</div>
                                    <div className="generate_shareable_image_footer"
                                        style={{ height: (40 + (parseInt(this.state.fontSizeProductContainer) - 16 + 5)) }}
                                    >
                                        <img className="generate_shareable_tabl_logo" src={'https://menu.tabl.bg/files/custom/tabl/tabl-lunch-menu-logo.png'} /> {/* https://tabl.bg/img/tabl-transparent.png */}
                                        <img className="generate_shareable_restaurant_logo" src={Utils.getSettingsPath(Utils.getSettingsValue(this.props.settings, 'logo'))} />
                                    </div>
                                </>
                            }

                        </div>

                    </div>
                </div>
            </main>
        )
            :
            null
            ;
    }
}

const mapStateToProps = (state, ownProps) => {
    let id = ownProps.match.params.id;

    let cats = state.categoriesHierarchy.categories || [];
    let ids = [];
    if (cats[id]) {
        if (cats[id].children) {
            // console.log(`cats[id].children`, cats[id].children)
            Object.values(cats[id].children).map(c => c.active == 1 && c.deleted == 0 ?
                ids.push(c.id)
                : void (0))
        }
    }

    let prods = []

    if (ids.length > 0) {
        prods = id ? Object.values(state.products.products).filter(p => p.cat_hierarchies.filter(c => ids.includes(c.id)).length > 0) : [];
    } else {
        prods = id ? Object.values(state.products.products).filter(p => p.cat_hierarchies.filter(c => c.id == id).length > 0) : [];
    }
    // let usedCats = [];
    // Object.values(prods).map(p => {
    //     p.cat_hierarchies.map(cc => {
    //         usedCats.push(cc.id);
    //     })
    // })
    return {
        prods: prods,
        currentCatId: id,
        // usedCats: usedCats,
        ids: ids,
        cats: state.categoriesHierarchy.categories || [],
        translations: state.lang,
        settings: state.settings.settings,
        places: state.places.places,
        clients: state.clients.clients,
    }
};

export default connect(mapStateToProps, {})(GenerateShareableImagePage);
