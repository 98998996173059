import React, { Component } from 'react'
import { connect } from 'react-redux';
import EditIcon from '@material-ui/icons/Edit';

class ListEachProductSize extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            editMode: false,
        }
    }


    render() {

        if (this.props.productVariantEditMode) { // used because it tries to focus itm when it is not still visible
            document.getElementById("pv" + this.props.productSize.id).style.display = "block";
            document.getElementById("pv" + this.props.productSize.id).focus();
        }

        return (
            <React.Fragment key={this.props.productSize.id}>
                {/* {this.props.enablePriceEdit ? */}
                {/* <div style={{ minWidth: this.props.currentEdit == 'price' ? 80 : 120 }}> */}
                {/* <EditIcon
                        style={{
                            display: 'inline',
                            width: 20,
                            color: '#ccc',
                        }}
                    /> */}
                <input
                    id={"pv" + this.props.productSize.id}
                    // onClick={() => this.props.toggleProductVariantEditMode(false)}
                    onBlur={e => {
                        if(this.props.currentEdit == 'ws_code') {
                            this.props.updateProductVariantWSCode(e, this.props.productSize.id, e.target.value)
                        } else {
                            this.props.updateProductVariantPrice(e, this.props.productSize.id, this.props.currentEdit == 'price' ? e.target.value : this.props.productSize.price, this.props.currentEdit == 'size' ? e.target.value : this.props.productSize.size)
                        }
                    }}
                    type="text"
                    defaultValue={this.props.productSize[this.props.currentEdit]}
                    style={{
                        border: 'none', background: 'transparent',
                        fontWeight: 'bold',
                        width: this.props.currentEdit == 'price' ? 60 : 100,
                        display: 'block'
                    }}
                    title={this.props.productSize[this.props.currentEdit]}
                // onBlur={(ev) => props.updateWsCode(ev, props.product.id)}
                />
                {/* </div> */}
                {/* //     :
                //     null
                // } */}

                {/* <b onClick={() => this.props.enablePriceEdit ?
                    this.props.toggleProductVariantEditMode(this.props.productSize.id)
                    :
                    void (0)
                }
                    style={{ display: this.props.productVariantEditMode ? 'none' : 'block' }}
                >
                    {this.props.productSizeValue}
                </b> */}

                
                {this.props.currentEdit != "ws_code" && typeof this.props.productAdditionalPrice !== "undefined" && parseFloat(this.props.productAdditionalPrice).toFixed(2) != '0.00' ?
                    <><div title="Цена на кутия">/{this.props.productAdditionalPrice}/</div></>
                    :
                    null
                }
                {/* <br /> */}
            </React.Fragment >
        )
    }
}


const mapStateToProps = state => ({
});

export default connect(mapStateToProps, {})(ListEachProductSize);