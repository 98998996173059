import React, { Component } from 'react'
import { NavLink } from 'react-router-dom';
import { ADMIN_URL, PRODUCT_IMG_URL } from '../../constants.js'
import DeleteCategoryModal from './DeleteCategoryModal.js';
import MessagesContainer from './../messages/MessagesContainer';
import LangDropdown from './../common/LangDropdown';
import Utils from '../../utils/Utils.js';

class AddEditCategoryForm extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const props = this.props;

        let editImage;
        if (props.isAddingCategory == '0' && (props.icon && props.icon.length > 0) && (props.icon)) {
            editImage = <img src={Utils.getCategoryImagePath(props.icon)} className="editPagesImage" />
        }

        return (
            <form onSubmit={props.onSubmit}>
                <MessagesContainer />
                <div className="card-body">

                    <LangDropdown
                        isDisabled={props.isAddingCategory == 1 ? 1 : ""}
                        currLangOnChange={props.currLangOnChange}
                        currLang={props.currLang}
                        languages={props.languages}
                    />

                    {/* <div className="row">
                        <div className="col-sm-12">
                            <div className="form-group">
                                <select className="form-control" id="currLang" type="text"  name="currLang"
                                    disabled={props.isAddingCategory == 1 ? 1 : ""}
                                    onChange={props.currLangOnChange} value={props.currLang}>
                                    <option value="en">en</option>
                                    <option value="bg">bg</option>
                                </select>
                            </div>
                        </div>
                    </div> */}

                    <div className="row">
                        <div className="col-sm-12">
                            <div className="form-group">
                                <label htmlFor="name">{props.translations.common.category}</label>
                                <input className={`form-control ${props.nameError.message ? "is-invalid" : ""}`} id="name" type="text" placeholder={props.translations.common.name} name="name" onChange={props.onChange} value={props.categoryName} />
                                <div className="invalid-feedback">{props.nameError.message ? props.nameError.message : props.nameError}</div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-12">
                            <div className="form-group">
                                <label htmlFor="code">{props.translations.common.code}</label>
                                <input className={`form-control`} id="code" type="text" placeholder={props.translations.common.code} name="code" onChange={props.onChange} value={props.categoryCode} />
                                {/* <div className="invalid-feedback">{props.nameError.message ? props.nameError.message : props.nameError }</div>  */}
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-12">
                            <div className="form-group">
                                <label htmlFor="icon">{props.translations.common.icon}</label>
                                {/* <input className={`form-control`} id="icon" type="text" placeholder={props.translations.common.icon} name="icon" onChange={props.onChange} value={props.categoryIcon}/> */}
                                {/* <div className="invalid-feedback">{props.nameError.message ? props.nameError.message : props.nameError }</div>  */}
                                <input id="image" type='file' name='file' id='multi' multiple ref="image" />
                                {editImage}
                            </div>
                        </div>
                    </div>

                    <div className=" row">
                        <label className="col-md-1 col-form-label">{props.translations.common.active}</label>
                        <div className="col-md-11 col-form-label">
                            <label className="switch switch-label switch-pill switch-outline-primary-alt">
                                <input className="switch-input" type="checkbox" name="active" onChange={props.onChangeCheckbox} checked={props.categoryActive} />
                                <span className="switch-slider" data-checked={props.translations.common.yes} data-unchecked={props.translations.common.no}></span>
                            </label>
                        </div>
                    </div>

                    <hr />
                    <br />

                    <div className="form-actions">
                        {props.isAddingCategory == '1' ? '' : <button className="btn btn-danger mright10" type="button" data-toggle="modal" data-target={"#dangerModal" + props.categoryId} >{props.translations.common.delete}</button>}

                        <DeleteCategoryModal
                            categoryId={props.categoryId}
                            categoryName={props.categoryName}
                            deleteCategory={props.deleteCategory}
                            translations={props.translations}
                        />

                        {/* <NavLink to={ADMIN_URL+'categories'}> */}
                        <button className="btn btn-secondary" type="button" onClick={() => Utils.goBack()}>
                            {props.translations.common.cancel}
                        </button>
                        {/* </NavLink> */}

                        <button className="fright btn btn-primary" type="submit">
                            {props.isAddingCategory == '1' ? props.translations.common.add : props.translations.common.save}
                            {/* {props.actionText} */}
                        </button>

                    </div>

                </div>

            </form>
        )
    }
}

export default AddEditCategoryForm;