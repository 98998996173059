import React from 'react'
import { NavLink } from 'react-router-dom';
import { ADMIN_URL, PRODUCT_IMG_URL } from '../../constants.js'
import DeleteProductModal from './common/DeleteProductModal.js';
import MessagesContainer from './../messages/MessagesContainer';
import Utils from '../../utils/Utils.js';
import LangDropdown from './../common/LangDropdown';
import Select from 'react-select'
import CKEditorComponent from "react-ckeditor-component";

class AddEditProductForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            uploadedImgName: ''
        }
    }

    componentDidMount() {
        let props = this.props;
    }

    addUploadedFileText = e => {
        if (e && e.target && e.target.files && e.target.files[0] && e.target.files[0].name) {
            this.setState({ uploadedImgName: e.target.files[0].name })
        }
    }

    render() {
        let props = this.props;
        let selectedCategory, selectedSubcategory;


        let categoriesOptions = Object.values(props.allCategories).map((cat) => {
            if (props.categoryId == cat.id) {
                selectedCategory = cat.id;
            }

            if ((cat.active == 1 && cat.deleted == 0) && (Object.values(cat.subcategories).length > 0)) {
                return <option key={cat.id} value={cat.id}>{cat.translations && cat.translations[props.currLang] && cat.translations[props.currLang].name ? cat.translations[props.currLang].name : (cat.name + (cat.code ? '(' + cat.code + ')' : ''))}</option>
            }
        });
        // console.log('categoriesOptions', categoriesOptions)
        let selectedType = { 'value': (props.product.type ? props.product.type : 'default'), 'label': props.translations.common.types[props.product.type] };

        let subcategoriesOptions = '';

        // If category deleted or not active !
        if (props.isAddingProduct == '0') {

            // console.log('props.allCategories[props.categoryId]', props.allCategories[props.categoryId])
            let subcatSelectHolder = document.getElementById('subcategory_id');
            if (subcatSelectHolder) {
                subcatSelectHolder.disabled = false;
            }

            if (typeof props.allCategories[props.categoryId] === 'undefined' || (props.allCategories[props.categoryId] && (!props.allCategories[props.categoryId].active || props.allCategories[props.categoryId].deleted))) {
                let catSelectHolder = document.getElementById('category_id');
                if (catSelectHolder) {
                    let optionHolder = document.createElement('option');
                    optionHolder.value = '-';
                    optionHolder.innerHTML = '-';
                    optionHolder.hidden = true;
                    optionHolder.selected = true;
                    catSelectHolder.prepend(optionHolder);
                }
                // let subcatSelectHolder = document.getElementById('subcategory_id');
                if (subcatSelectHolder) {
                    subcatSelectHolder.value = '';
                    subcatSelectHolder.disabled = true;
                    subcategoriesOptions = [];
                }
            }

            if (typeof props.allSubcategories[props.subcategoryId] === 'undefined' || (props.allSubcategories[props.subcategoryId] && (!props.allSubcategories[props.subcategoryId].active || props.allSubcategories[props.subcategoryId].deleted))) {
                // let subcatSelectHolder = document.getElementById('subcategory_id');
                if (subcatSelectHolder) {
                    subcatSelectHolder.value = '';
                    subcatSelectHolder.disabled = true;

                    subcategoriesOptions = [];
                }
            }
        }

        let editImage;
        if (props.isAddingProduct == '0' && (props.productImage && props.productImage.length > 0) && (props.productImage[0].path)) {
            editImage = <img src={Utils.getProductImagePath(props.productImage[0].path)} className="editPagesImage" alt={props.productImage[0].path} />
        }


        // CAT HIERARCHIES
        let selectedCatHierarchy = '';
        let categoriesHierarchyOptions = Utils.getOptionsForAutocompleteSelect(props.categoriesHierarchy);

        return (
            <React.Fragment>
                <form onSubmit={props.onSubmit} encType="multipart/form-data">

                    <MessagesContainer />
                    <div className="card-body">

                        <LangDropdown
                            isDisabled={props.isAddingProduct == 1 ? 1 : ""}
                            currLangOnChange={props.currLangOnChange}
                            currLang={props.currLang}
                            languages={props.languages}
                        />

                        <div className="row">
                            <div className="col-sm-12">
                                <div className="form-group">
                                    <label htmlFor="name">{props.translations.common.name}</label>
                                    <input className={`form-control ${props.nameError.message ? "is-invalid" : ""}`} id="name" type="text" placeholder={props.translations.common.product_name} name="name" value={props.productName} onChange={props.onChange} />
                                    <div className="invalid-feedback">{props.nameError.message ? props.nameError.message : props.nameError}</div>
                                </div>
                            </div>

                            <div className="col-sm-12">
                                <div className="form-group">
                                    <label htmlFor="description">{props.translations.common.description}</label>
                                    {/* <input className={`form-control`} id="description" type="text" placeholder={props.translations.common.description} name="description" value={props.productDescription} onChange={props.onChange} /> */}
                                    {/* <textarea className={`form-control`} id="description" name="description" onChange={props.onChange} value={props.productDescription}> */}
                                    {/* {props.productDescription} */}
                                    {/* </textarea> */}


                                    {props.currentTab == "product" || props.isAddingProduct == 1 ?
                                        <CKEditorComponent
                                            content={props.productDescription}
                                            events={{
                                                // "blur": this.onBlur,
                                                // "afterPaste": this.afterPaste,
                                                "change": props.onCKEditorChange
                                            }}
                                            config={{
                                                extraAllowedContent: 'div(*)',
                                                allowedContent: true
                                            }}
                                        />
                                        :
                                        null}
                                    {/* 

                                    {props.productDescription ?
                                        <CKEditor
                                            id="description"
                                            name="description"
                                            initData={props.productDescription}
                                            onChange={props.onCKEditorChange}
                                        />
                                        :
                                        <CKEditor
                                            id="description"
                                            name="description"
                                            initData=""
                                            onChange={props.onCKEditorChange}
                                        />
                                    } */}


                                    {/* <div className="invalid-feedback">{props.nameError.message ? props.nameError.message : props.nameError }</div>  */}
                                </div>
                            </div>

                            <div className="form-group col-sm-12">
                                <label htmlFor="catHierarchyId">{props.translations.common.category}</label>

                                <Select
                                    value={props.product.currCategoriesHierarchy}
                                    isMulti
                                    name="colors"
                                    options={categoriesHierarchyOptions}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    onChange={props.onChangeSelectAutocompleteCategory}
                                // onChange={(values, { action, removedValue }) => props.ingredientsOnChange(values, { action, removedValue }, 'removable')}
                                />

                                {/* <Select name="catHierarchyId" options={categoriesHierarchyOptions} 
                                onChange={props.onChangeSelectAutocompleteCategory} value={selectedCatHierarchy} /> */}

                                {/* <select className="form-control" id="catHierarchyId" name="catHierarchyId" onChange={props.onChange} value={selectedCatHierarchy}>
                                {categoriesHierarchyOptions}
                            </select> */}
                            </div>

                            <div className="col-sm-4">
                                <div className="form-group">
                                    <label htmlFor="code">{props.translations.common.code}</label>
                                    <input className={`form-control ${props.nameError.message ? "is-invalid" : ""}`} id="code" type="text" placeholder={props.translations.common.code} name="code" value={props.productCode} onChange={props.onChange} />
                                    <div className="invalid-feedback">{props.nameError.message ? props.nameError.message : props.nameError}</div>
                                </div>
                            </div>

                            <div className="col-sm-8">
                                <div className="form-group">
                                    <label htmlFor="comment_placeholder">{props.translations.products.comment_placeholder}</label>
                                    <input className={`form-control ${props.nameError.message ? "is-invalid" : ""}`} id="comment_placeholder" type="text" name="comment_placeholder" value={props.product.comment_placeholder} onChange={props.onChange} />
                                    <div className="invalid-feedback">{props.nameError.message ? props.nameError.message : props.nameError}</div>
                                </div>
                            </div>

                            {Utils.hasFunctionalityModule('booking') ?
                                <div className="form-group col-sm-12">
                                    <label htmlFor="type">{props.translations.common.type}</label>
                                    <Select name="type" options={[{ value: 'default', label: props.translations.common.types.default }, { value: 'booking', label: props.translations.common.types.booking }]}
                                        onChange={(valueLabel) => props.onChangeSelectAutocomplete("type", valueLabel)} value={selectedType}
                                        placeholder={props.translations.common.types.default}
                                    />
                                </div>
                                :
                                null
                            }

                        </div>

                        <div className="row">
                            <label className="col-md-1 col-form-label vertical-center" htmlFor="file-multiple-input">{props.translations.common.image}</label>
                            <div className="col-md-5 col-form-label vertical-center" style={{ justifyContent: 'center' }}>
                                {/* <input id="file-multiple-input" type="file" name="file-multiple-inpuproductisLiquidt" multiple=""/> */}
                                <input type='file' name='file' id='multi' multiple ref="image" style={{ display: 'none' }} onChange={this.addUploadedFileText} />
                                <label for="multi" type="button" className="btn btn-secondary" style={{ borderBottomLeftRadius: 0, borderBottomRightRadius: 0, margin: 0 }}>{!props.isAdding && props.productImage && props.productImage.length > 0 ? props.translations.products.replace_image : props.translations.products.upload_image}</label>
                                {editImage}
                                {this.state.uploadedImgName}

                                {!props.isAdding && props.productImage && props.productImage.length > 0 ?
                                    <button type="button" className="btn btn-danger" onClick={props.removeImage} style={{ borderTopRightRadius: 0, borderTopLeftRadius: 0 }}>{props.translations.products.delete_image}</button>
                                    :
                                    null
                                }
                            </div>

                            <div className="col-md-2 vertical-center">
                                <div className="form-group">
                                    <label htmlFor="name">{props.translations.common.discount_percent}</label>
                                    {/* <input className={`form-control`} id="discount_percent" type="number" max={100} placeholder={props.translations.common.discount_percent} name="discount_percent" value={props.product.discount_percent} onChange={props.onChange} /> */}
                                    <div className="input-group">
                                        <input className={`form-control`} id="discount_percent" type="number" min={0} max={100} name="discount_percent" value={props.product.discount_percent} onChange={props.onChange} />
                                        <span className="input-group-append">
                                            <span className="input-group-text">%</span>
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-2 vertical-center">
                                <div className="form-group">
                                    <label htmlFor="name">{props.translations.common.discount_money}</label>
                                    <div className="input-group">
                                        <input className={`form-control`} id="discount_money" min={0} max={100} name="discount_money" value={props.product.discount_money} onChange={props.onChange} />
                                        <span className="input-group-append">
                                            <span className="input-group-text">лв.</span>
                                        </span>
                                    </div>
                                </div>
                            </div>


                            <div className="col-md-2 vertical-center">
                                <div className="form-group">
                                    <label htmlFor="name">{props.translations.common.image_scale}</label>
                                    <div className="input-group">
                                        <input className={`form-control`} id="image_scale" type="number" min={0} placeholder={props.translations.common.image_scale} name="image_scale" value={props.product.image_scale} onChange={props.onChange} />
                                        <span className="input-group-append">
                                            <span className="input-group-text">%</span>
                                        </span>
                                    </div>
                                </div>
                            </div>

                        </div>


                        <div className="row">

                            <label className="col-md-1 col-form-label vertical-center">{props.translations.common.active}</label>
                            <div className="col-md-2 col-form-label vertical-center">
                                <label className="switch switch-label switch-pill switch-outline-primary-alt">
                                    <input className="switch-input" type="checkbox" name="active" onChange={props.onChangeCheckbox} checked={props.productActive} />
                                    <span className="switch-slider" data-checked={props.translations.common.yes} data-unchecked={props.translations.common.no}></span>
                                </label>
                            </div>

                            <label className="col-md-1 col-form-label vertical-center">{props.translations.common.is_drink}</label>
                            <div className="col-md-2 col-form-label vertical-center">
                                <label className="switch switch-label switch-pill switch-outline-primary-alt">
                                    <input className="switch-input" type="checkbox" name="isLiquid" onChange={props.onChangeCheckbox} checked={props.productisLiquid} />
                                    <span className="switch-slider" data-checked={props.translations.common.yes} data-unchecked={props.translations.common.no}></span>
                                </label>
                            </div>


                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label htmlFor="name">{props.translations.common.label}</label>
                                    <input className={`form-control`} id="label" type="text" placeholder={props.translations.common.label} name="label" value={props.product.label} onChange={props.onChange} />
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <label className="col-md-1 col-form-label vertical-center" title={this.props.translations.common.is_promo_product_info}>
                                <span>
                                    {props.translations.common.is_promo_product}
                                    <span data-toggle="tooltip" data-placement="bottom"> 🛈</span>
                                </span>
                            </label>
                            <div className="col-md-2 col-form-label vertical-center">
                                <label className="switch switch-label switch-pill switch-outline-primary-alt">
                                    <input className="switch-input" type="checkbox" name="is_promo" onChange={props.onChangeCheckbox} checked={props.product.is_promo} />
                                    <span className="switch-slider" data-checked={props.translations.common.yes} data-unchecked={props.translations.common.no}></span>
                                </label>
                            </div>

                            <label className="col-md-1 col-form-label vertical-center" title={this.props.translations.common.hide_for_ordering_info}>
                                <span>
                                    {props.translations.common.hide_for_ordering}
                                    <span data-toggle="tooltip" data-placement="bottom"> 🛈</span>
                                </span>
                            </label>

                            <div className="col-md-2 col-form-label vertical-center">
                                <label className="switch switch-label switch-pill switch-outline-primary-alt">
                                    <input className="switch-input" type="checkbox" name="hide_for_ordering" onChange={props.onChangeCheckbox} checked={props.product.hide_for_ordering} />
                                    <span className="switch-slider" data-checked={props.translations.common.yes} data-unchecked={props.translations.common.no}></span>
                                </label>
                            </div>


                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label htmlFor="productPrintGroupId">{props.translations.common.productPrintGroupId}</label>
                                    <span data-toggle="tooltip" data-placement="bottom" title="Задава се, в случай че съответния продукт е нужно да се разпечата само в определена група (когато има повече от 2 принтера и разделение на Топла и Студена кухня например)"> 🛈</span>

                                    <input className={`form-control`} id="productPrintGroupId" type="text" name="productPrintGroupId" value={props.product.productPrintGroupId} onChange={props.onChange} />
                                </div>
                            </div>

                            <label className="col-md-1 col-form-label vertical-center">{props.translations.products.show_only_for_spot_browse}</label>
                            <div className="col-md-2 col-form-label vertical-center">
                                <label className="switch switch-label switch-pill switch-outline-primary-alt">
                                    <input className="switch-input" type="checkbox" name="show_only_for_spot_browse" onChange={props.onChangeCheckbox} checked={props.product.show_only_for_spot_browse} />
                                    <span className="switch-slider" data-checked={props.translations.common.yes} data-unchecked={props.translations.common.no}></span>
                                </label>
                            </div>

                            <label className="col-md-1 col-form-label vertical-center">{props.translations.products.hide_only_for_spot_browse}</label>
                            <div className="col-md-2 col-form-label vertical-center">
                                <label className="switch switch-label switch-pill switch-outline-primary-alt">
                                    <input className="switch-input" type="checkbox" name="hide_only_for_spot_browse" onChange={props.onChangeCheckbox} checked={props.product.hide_only_for_spot_browse} />
                                    <span className="switch-slider" data-checked={props.translations.common.yes} data-unchecked={props.translations.common.no}></span>
                                </label>
                            </div>

                            {Utils.getSettingsValue(this.props.settings, 'show_initial_banner_fast_order') == 1 ?
                                <React.Fragment>
                                    <label className="col-sm-2 col-form-label vertical-center" title={this.props.translations.common.fast_order_info}>
                                        <span>
                                            {props.translations.products.fast_order}
                                            <span data-toggle="tooltip" data-placement="bottom"> 🛈</span>
                                        </span>
                                    </label>
                                    <div className="col-sm-1 col-form-label vertical-center">
                                        <label className="switch switch-label switch-pill switch-outline-primary-alt">
                                            <input className="switch-input" type="checkbox" name="fast_order" onChange={props.onChangeCheckbox} checked={props.productFastOrder} />
                                            <span className="switch-slider" data-checked={props.translations.common.yes} data-unchecked={props.translations.common.no}></span>
                                        </label>
                                    </div>
                                </React.Fragment>
                                :
                                null
                            }

                        </div>


                        <hr />
                        <br />

                        <div className="form-actions">
                            {props.isAddingProduct == '1' ? '' : <button className="btn btn-danger mright10" type="button" data-toggle="modal" data-target={"#dangerModal" + props.productId} >{props.translations.common.delete}</button>}

                            <DeleteProductModal
                                productId={props.productId}
                                productName={props.productName}
                                deleteProduct={props.deleteProduct}
                                translations={props.translations}
                            />

                            {/* <NavLink to={ADMIN_URL + 'products'}> */}
                            <button className="btn btn-secondary" type="button" onClick={() => Utils.goBack()}>
                                {props.translations.common.cancel}
                            </button>
                            {/* </NavLink> */}

                            <button className="fright btn btn-primary" type="submit">
                                {props.isAddingProduct == '1' ? props.translations.common.add : props.translations.common.save}
                                {/* {props.actionText} */}
                            </button>
                        </div>

                    </div>

                </form>
            </React.Fragment >

        )
    }

}


export default AddEditProductForm;