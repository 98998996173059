import React, { Component } from 'react'
import { connect } from 'react-redux';
import { addEditBookingRoom } from '../../actions/bookings.actions';
// import { listPlaces } from './../../places/actions/places.actions';
import AddEditBookingRoomForm from './AddEditBookingRoomForm';
import { NavLink } from 'react-router-dom';
import { ADMIN_URL } from '../../../../constants';
import { addErrorMessages } from '../../../messages/messages.actions';

class AddRegionPage extends React.Component {
	constructor(props) {
		super(props)

		this.state = { 
			currLang: this.props.currLang,
			name: '',
			catHierarchies: [],
		}
	  
	}

    componentWillMount() {
        // this.props.listPlaces();
    }

	onChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
    }
    
    onChangeSelectAutocomplete = (field, valueLabel) => {
        this.setState({ [field]: valueLabel.value });
    }
		
    onChangeSelectAutocompleteCategory = (valueLabel) => {
        this.setState({ 'catHierarchies': valueLabel });
    }

	validate() {
		let errors = [];

		if (this.state.name.length === 0) {
			errors.push(this.props.translations.messages.errors.invalid_name);
		}
		return errors;
    }

	onSubmit = (e) => {
        e.preventDefault();

        const errors = this.validate();
        if(errors.length > 0) {
            this.props.addErrorMessages(errors);
            return;
		}
		let catHierarchyIds = this.state.catHierarchies.map(c => c.value);

		const post = {
			name: this.state.name,
			catHierarchyIds: catHierarchyIds,
		}

		this.props.addEditBookingRoom(post, this.props.history, this.props.languages[this.state.currLang].id);
	}

	// componentWillReceiveProps(nextProps) {
	// 	this.setState({
	// 		name: '',
	// 		categoryId: '',
	// 	});
	// }

    render() {
    	return (
			<main className="main">

				{/* <!-- Breadcrumb--> */}
				<ol className="breadcrumb">
					<li className="breadcrumb-item"><NavLink to={ADMIN_URL}>{this.props.translations.common.dashboard}</NavLink></li>
					<li className="breadcrumb-item"><NavLink to={ADMIN_URL + "bookings"}>{this.props.translations.bookings.title}</NavLink></li>
					<li className="breadcrumb-item"><NavLink to={ADMIN_URL + "bookings/rooms"}>{this.props.translations.bookings.rooms}</NavLink></li>
					<li className="breadcrumb-item active">{this.props.translations.bookings.add_room}</li>
					{/* <!-- Breadcrumb Menu--> */}
					<li className="breadcrumb-menu d-md-down-none">
                        <div className="btn-group" role="group" aria-label="Button group">
                            {/* 
                            <!--<a className="btn" href="./">
                                <i className="icon-graph"></i>  Dashboard</a>
                            <a className="btn" href="#">
                                <i className="icon-settings"></i>  Settings</a>
                            --> 
                            */}
                        </div>
                    </li>
				</ol>

        		<div className="container-fluid">
           			<div className="animated fadeIn">
              			<div className="row">
                
							{/* <!-- /.col--> */}                
							<div className="col-sm-12">
								<div className="card">

									<AddEditBookingRoomForm
                                        // bookingRooms={this.props.bookingRooms}
										onSubmit={this.onSubmit}
                                        onChange={this.onChange}
										onChangeSelectMultiAutocomplete={this.onChangeSelectMultiAutocomplete}
										onChangeSelectAutocompleteCategory={this.onChangeSelectAutocompleteCategory}
										bookingRoom={this.state}
										isAdding="1"
										translations={this.props.translations}
										currLang={this.state.currLang}
										languages={this.props.languages}
										categories={this.props.categories}
									/>

								</div>
							</div>

				  		</div>
				  	</div>
				</div>

			</main>
    	)
  	}
}

const mapStateToProps = state => ({
    // errors: state.categories.errors,
    // places: state.places.places || [],
	translations: state.lang,
	languages: state.languages.languages || {},
	categories: state.categoriesHierarchy.categories || [],
	currLang: state.settings.settings['default_lang'] && state.settings.settings['default_lang'].value || "bg",
});

export default connect(mapStateToProps, { addEditBookingRoom, addErrorMessages })(AddRegionPage);