import React, { Component } from 'react'
import { importDeliveryShape, getDeliveryShape, deleteDeliveryShape } from './../../actions/deliveryShapes.actions';

import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { bindActionCreators } from 'redux'
import Auth from '../../../../utils/Auth';

class ImportShapeMapPointsModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            coords : ""
        };
    }

    componentDidMount() {
        this.props.getDeliveryShape();
    }

    onChangeFieldType = (e) => {
        // let type = UPDATEABLE_FIELDS.find(f => f.name == e.target.value).type;
        // this.setState({ updateFieldType: type, updateField: e.target.value })
    }

    onChange = (e) => {
        this.setState({ coords: e.target.value });
    }

    import = () => {
        this.setState({ error: "" })

        if (this.state.coords.length == 0) {

            this.setState({ error: "Invalid coords 1" })
            return;
        }

        let coords = this.state.coords
        coords = coords.replace(" ", '');
        coords = coords.split('\n')

        console.log(coords)
        //|| coords[0]*1 != coords[coords.length - 1]
        if(coords.length < 3 ) {
            this.setState({ error: "Invalid coords 2" })
            return;
        }

        let coordsToSend = [];
        coords.map(c => {

            let cc = c.split(",");
            coordsToSend.push({
                lat: cc[0]*1,
                lng: cc[1]*1,
                placeId: Auth.getUserPlace()
            })
        })
           
        console.log(coordsToSend);

        let data = {
            coords: coordsToSend,
        }
        this.props.importDeliveryShape(data)
        // document.getElementById("dismisModalBtn").click();
    }

    deleteShapeMap = () => {
        this.props.deleteDeliveryShape()
    }
    
    render() {

        console.log(this.props.shape)
        return (
            <React.Fragment>

                <div>
                    <button id="toggleModalBtn" type="button" data-toggle="modal" className="btn btn-primary" 
                        data-target={"#importShapeMapPointsModal"} >
                        {this.props.translations.delivery_radiuses.import_shape_map}
                    </button>
                </div>
                <div className="modal fade" id={"importShapeMapPointsModal"} tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-danger" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">{this.props.translations.delivery_radiuses.import_shape_map}</h4>
                                <button className="close" type="button" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <h6 className="modal-title" style={{ padding: 10 }}>{this.props.translations.delivery_radiuses.import_shape_map_info}</h6>
                            <div className="modal-body">
                                {this.state.error ?
                                    <div className="alert alert-danger fade show" role="alert">
                                        {this.state.error}
                                    </div>
                                    :
                                    null
                                }

                                {Object.values(this.props.shape).length > 0 ? 
                                    <div className="alert alert-success fade show" role="alert">
                                        {this.props.translations.delivery_radiuses.shape_map_imported_text}
                                    </div>
                                    : 
                                    <div className="alert alert-info fade show" role="alert">
                                        {this.props.translations.delivery_radiuses.shape_map_not_imported_text}
                                    </div>
                                }

                                <button className="btn btn-danger" type="button" onClick={this.deleteShapeMap} style={{ float: 'right' }}>{this.props.translations.delivery_radiuses.delete_shape_map}</button>
                                <br/>
                                <textarea rows="20" cols="70" onChange={this.onChange}>

                                </textarea>

                            
                            </div>
                            {/* <div className="modal-footer"> */}
                            <div className="modal-body">
                                <button className="btn btn-secondary" id="dismisModalBtn" type="button" data-dismiss="modal">{this.props.translations.common.close}</button>
                                <button className="btn btn-success" type="button" onClick={this.import} style={{ float: 'right' }}>{this.props.translations.common.save}</button>
                                {/* <button className="btn btn-danger fright" type="button" data-dismiss="modal" onClick={(e) => { this.props.deleteVariant(e, this.props.variantId) }}>{this.props.translations.common.delete}</button> */}
                            </div>
                        </div>
                    </div>
                </div>

            </React.Fragment>

        )
    }

}


const mapStateToProps = state => {

    return (
        {
            shape: state.settings.delivery_shape || []
        }
    )
}


const mapDispatchToProps = (dispatch, props) => {
    return {
        dispatch,
        ...bindActionCreators({ importDeliveryShape, getDeliveryShape, deleteDeliveryShape }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ImportShapeMapPointsModal);