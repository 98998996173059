import React, { Component } from 'react'
import { connect } from 'react-redux';
import { addEditTableRegion, listTableRegions} from './../actions/tableRegions.actions';
import { listTables } from '../../../actions/tablesActions';
 
import AddEditTableRegionForm from './AddEditTableRegionForm';
import { NavLink } from 'react-router-dom';
import { addErrorMessages, addSuccessMessage } from '../../messages/messages.actions';
import { withRouter } from 'react-router-dom';
import { SCAN_URL, ADMIN_URL, API_TOKEN } from '../../../constants';
import Utils from '../../../utils/Utils';
import Auth from '../../../utils/Auth';

// let md5 = require('md5');

class AddTableRegionPage extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			name: "",
			active: 1,			
		}

	}

	componentWillMount() {
        this.props.listTables();
    }

	onChange = (e) => {
		this.setState({ [e.target.name]: e.target.value })
	}

	onChangeCheckbox = (e) => {
		this.setState({ [e.target.name]: e.target.checked });
	}

	onChangeSelectAutocomplete = (fieldName, valueLabel) => {
		this.setState({ [fieldName]: valueLabel });
	}

	validate = () => {
		let errors = [];
	
		// if(!this.state.tables || this.state.tables.length == 0) {
		// 	errors.push(this.props.translations.messages.errors.invalid_tables);			
		// }
		return errors;
	}

	onSubmit = (e) => {
		e.preventDefault();

		const errors = this.validate();
		if (errors.length > 0) {
			this.props.addErrorMessages(errors);
			return;
		}

		// let tablesIds = this.state.tables.map(t => t.value);

		const post = {
			// tableRegion_token: Utils.generateTableRegionToken(this.state.tableRegion_num), // md5(md5(this.state.tableRegion_num + (this.state.currentDate))),
			name: this.state.name,
			active: this.state.active
			// tables_ids: tablesIds
		}

		// dispatch(showLoading());

		this.props.addEditTableRegion(post)
		.then((post) => {
            post = post.data;
            this.props.listTableRegions();
			this.props.history.push("/admin/tableRegions/edit/"+post.id);                
            // dispatch(hideLoading());
        })
        .catch((error) => {
			this.props.addErrorMessages('Error occured');
        })
	}

	render() {
		console.log('state', this.props.tables)
		// console.log("--- ", Utils.generateTableRegionToken(this.state.tableRegion_num))
		return (
			<main className="main">

				{/* <!-- Breadcrumb--> */}
				<ol className="breadcrumb">
					<li className="breadcrumb-item"><NavLink to={ADMIN_URL}>{this.props.translations.common.dashboard}</NavLink></li>
					{/* <li className="breadcrumb-item">Меню</li> */}
					<li className="breadcrumb-item"><NavLink to={ADMIN_URL + "tableRegions"}>{this.props.translations.table_regions.title}</NavLink></li>
					<li className="breadcrumb-item active">{this.props.translations.table_regions.add}</li>
					{/* <!-- Breadcrumb Menu--> */}
					<li className="breadcrumb-menu d-md-down-none">
						<div className="btn-group" role="group" aria-label="Button group">
							{/* 
                            <!--<a className="btn" href="./">
                                <i className="icon-graph"></i>  Dashboard</a>
                            <a className="btn" href="#">
                                <i className="icon-settings"></i>  Settings</a>
                            --> 
                            */}
						</div>
					</li>
				</ol>

				<div className="container-fluid">
					<div className="animated fadeIn">
						<div className="row">

							{/* <!-- /.col--> */}
							<div className="col-sm-12">
								<div className="card">

									<AddEditTableRegionForm
										onSubmit={this.onSubmit}
										onChange={this.onChange}
										onChangeCheckbox={this.onChangeCheckbox}
										onChangeSelectAutocomplete={this.onChangeSelectAutocomplete}
										onFieldChange={this.onFieldChange}

										tableRegion={this.state}
										tables={this.props.tables}
										actionText="Добави"
										isAdding={1}
										translations={this.props.translations}
									/>

								</div>
							</div>

						</div>
					</div>
				</div>

			</main>
		)
	}
}

const mapStateToProps = state => {

	return {
		tables: state.tables.tables,
		translations: state.lang,
	}
};

export default withRouter(connect(mapStateToProps, { listTableRegions, addEditTableRegion, addErrorMessages, addSuccessMessage, listTables})(AddTableRegionPage));