import React from 'react'
import { NavLink } from 'react-router-dom';
import { ADMIN_URL, IMG_PATH } from '../../../constants.js'
import DeleteModal from './../../common/DeleteModal';
import Utils from '../../../utils/Utils';
import moment from 'moment';
import Select from 'react-select'

const ListReservationsItem = (props) => {

    const formatTime = (minutes) => {
        const absoluteMinutes = Math.abs(minutes);
        const isPast = minutes < 0;

        // Define time units in Bulgarian
        const timeUnits = [
            { unit: "минута", plural: "минути", count: 1 },
            { unit: "час", plural: "часа", count: 60 },
            { unit: "ден", plural: "дни", count: 60 * 24 },
            { unit: "седмица", plural: "седмици", count: 60 * 24 * 7 },
            { unit: "месец", plural: "месеца", count: 60 * 24 * 30 },
            { unit: "година", plural: "години", count: 60 * 24 * 365 }
        ];

        // Find the appropriate time unit
        let timeUnit;
        for (let i = timeUnits.length - 1; i >= 0; i--) {
            if (absoluteMinutes >= timeUnits[i].count) {
                timeUnit = timeUnits[i];
                break;
            }
        }

        if (!timeUnit) return null

        // Calculate the time value
        const value = Math.round(absoluteMinutes / timeUnit.count);

        // Determine the correct time unit string based on the value
        const unitString = value === 1 ? timeUnit.unit : timeUnit.plural;

        // Format the result
        let result;
        if (isPast) {
            result = `преди ${value} ${unitString}`;
        } else {
            result = `след ${value} ${unitString}`;
        }

        return result;
    }



    // Convert string to Date object
    let startDate = new Date(props.reservation.start_time);
    let endDate = new Date(props.reservation.end_time);

    startDate.setSeconds(0);
    startDate.setMilliseconds(0);
    endDate.setSeconds(0);
    endDate.setMilliseconds(0);


    // Get current date
    // const currentDate = new Date();
    // 

    // Get date from reservationsForDate
    const date = new Date(props.reservationsForDate).getDate();
    const month = new Date(props.reservationsForDate).getMonth();
    const year = new Date(props.reservationsForDate).getFullYear();

    // Get time from reservationsForTime
    const hours = new Date(props.reservationsForTime).getHours();
    const minutes = new Date(props.reservationsForTime).getMinutes();
    const seconds = 0;

    // Create a new Date object with merged date and time
    const mergedDateTime = new Date(year, month, date, hours, minutes, seconds);
    //console.log('mergedDateTime', mergedDateTime);
    mergedDateTime.setSeconds(0);
    mergedDateTime.setMilliseconds(0);
    // 
    let currentDate = mergedDateTime;

    // Calculate the difference in milliseconds
    const differenceInMs = startDate - currentDate;

    // Convert milliseconds to minutes
    const minutesDifference = Math.floor(differenceInMs / (1000 * 60));

    console.log('---');

    // console.log('minutesDifferece', minutesDifference);
    // console.log('props.reservation.status', props.reservation.status);
    let reservationActiveColor;
    if (minutesDifference > 0 && minutesDifference <= 120) {
        reservationActiveColor = '#ffff00' // yellow
    } else {
        if (minutesDifference >= -30 && minutesDifference <= 0 && props.reservation.status == 'unconfirmed') {
            reservationActiveColor = '#b1b1ff'; // blue
        } else {
            // console.log('currentDate',currentDate);
            // console.log('startDate',startDate);
            // console.log('endDate',endDate);
            // console.log('currentDate >= startDate', currentDate >= startDate);
            // console.log('currentDate <= endDate', currentDate <= endDate);
            // Check if the current date is between the start and end dates
            if (currentDate >= startDate && currentDate <= endDate && props.reservation.status == 'confirmed') {
                reservationActiveColor = '#ff2626' // red
            }
        }
    }
    // console.log('props.reservation.id', props.reservation.id);
    // console.log('reservationActiveColor', reservationActiveColor);
    //     return '#ffff00'; // yellow
    // return '#b1b1ff'; // blue
    // return '#ff2626'; // red
    // console.log('---');
    return (
        <tr key={props.reservation.id} style={{ background: reservationActiveColor }}>
            {/* <td>
                {props.reservation.id}
            </td> */}
            <td title={props.reservation.id}>
                {props.reservation.client_name}
                <br />
                <a href={'tel:' + props.reservation.client_phone}> {props.reservation.client_phone}</a>
                <br />
                {props.reservation.online_paid ?
                    <span className="badge badge-success fsize-13">
                        {'Онлайн платено ' + props.reservation.paid_ammount + ' лв.'}
                    </span>
                    :
                    props.reservation.paid_ammount && props.reservation.paid_ammount.length > 0 && props.reservation.paid_ammount != 0 ?
                        <span className="badge badge-success fsize-13">
                            {'Платено ' + props.reservation.paid_ammount + ' лв.'}
                        </span>
                        :
                        null
                }
                {/* <a href={'mailto:' + props.reservation.client_email}> {props.reservation.client_email}</a> */}
            </td>
            <td>
                {formatTime(minutesDifference)}
                <br />
                {moment(props.reservation.start_time).format('DD/MM/YYYY')} {moment(props.reservation.start_time).format('HH:mm')} - {moment(props.reservation.end_time).format('HH:mm')}
            </td>
            <td>
                {props.reservation.people_count}
            </td>
            <td>
                {props.reservation.restaurant_hall && props.reservation.restaurant_hall.name || "-"}
            </td>
            <td>
                {props.reservation.tables && props.reservation.tables.length > 0 ?
                    <div>
                        {props.reservation.tables.map(t => {
                            return <>
                                {t.table_num + " (" + t.reservation_min_people + "-" + t.reservation_max_people + " " + props.translations.reservations.people + ")"}<br />
                            </>
                        })}
                    </div>
                    :
                    "-"}
            </td>
            <td style={{ width: 180 }}>
                <Select name="status" options={props.autocompleteStatuses}
                    onChange={(newValue) => props.onChangeSelectAutocompleteStatus(props.reservation.id, newValue)}
                    value={{ value: props.reservation.status, label: props.translations.reservations.statuses[props.reservation.status] }}
                />
            </td>
            <td>
                <NavLink to={ADMIN_URL + 'reservations/edit/' + props.reservation.id}>
                    <button className="btn btn-pill btn-link" type="button">
                        <i className="fas fa-edit"></i>
                    </button>
                </NavLink>
            </td>
        </tr>
    )
}

export default ListReservationsItem;