import React from 'react'
import { NavLink } from 'react-router-dom';
import { ADMIN_URL, IMG_PATH } from '../../../constants.js'
import DeleteModal from './../../common/DeleteModal';
import Utils from '../../../utils/Utils';
import moment from 'moment';
import Select from 'react-select'

const ListBookingsItem = (props) => (
    <tr key={props.booking.id}>
        <td>
            {props.booking.client_name} - 
            <a href={'tel:'+props.booking.client_phone}> {props.booking.client_phone}</a> -
            <a href={'mailto:'+props.booking.client_email}> {props.booking.client_email}</a> 
        </td>
        <td>
            {moment(props.booking.start_time).format('DD/MM HH:mm')} - {moment(props.booking.end_time).format('DD/MM HH:mm')}
        </td>
        <td>
            {props.booking.product_variant && props.booking.product_variant.product.name || ''} - {props.booking.product_variant && props.booking.product_variant.booking_duration || ''} мин.
        </td>
        <td>
            {props.booking.product_variant && props.booking.product_variant.price || ''} {props.translations.common.currency}
        </td>
        <td style={{width: 180}}>
            <Select name="status" options={props.autocompleteStatuses} 
                onChange={(newValue) => props.onChangeSelectAutocompleteStatus(props.booking.id, newValue)} 
                value={{value: props.booking.status, label: props.translations.bookings.statuses[props.booking.status]}} 
            />
        </td>
        <td>
            -
        </td>
    </tr>
)

export default ListBookingsItem;