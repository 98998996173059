import { LIST_INGREDIENTS } from '../actions/types';

const initialState = {
    ingredients: []
    
}

export default function(state = initialState, action) {
    switch(action.type) {
        case LIST_INGREDIENTS: {
            return {
                ...state,
                ingredients: action.payload,
            }
        }
           
        default:
            return state;
    }
}