import React from 'react'
import { NavLink } from 'react-router-dom';
import { Multiselect } from 'multiselect-react-dropdown';
import Auth from './../../../utils/Auth';
import Select from 'react-select'

import DatePicker from "react-datepicker";

const Filter = (props) => {
    // // const [startDate, setStartDate] = useState(new Date());
    let statusOptions = [];
    // let currPlace = props.places && props.places.find(p => p.id == Auth.getUserPlace()) || null;
    // let currClientId = 0;
    // if(currPlace) {
    //     currClientId = currPlace.clientId;
    // }

    // if(Auth.hasAccess("SUPER_ADMIN")) {
    //     placesOptions.push({ 'value': 'all',  'label': 'All' });
    // }

    Object.values(props.statuses).map(s => {
        statusOptions.push({ 'value': s, 'label': props.translations.bookings.statuses[s] });
    });

    return (
        // <div className="container-fluid p-0">
        // <div className="animated fadeIn">
        <div className="row">
            <div className="col-md-2">
                {/* <label>{props.translations.bookings.start_time}</label>
                        <br/> */}
                <DatePicker
                    className="form-control"
                    selected={props.search_start_time}
                    onChange={date => props.onDateChange('search_start_time', date)}
                    dateFormat="d-M-Y"
                    style={{ display: 'block' }}
                    placeholderText={props.translations.bookings.start_time}
                // placeholder={props.translations.bookings.start_time }
                // onCalendarClose={handleCalendarClose}
                // onCalendarOpen={handleCalendarOpen}
                />
            </div>

            <div className="col-md-2">
                {/* <label>{props.translations.bookings.end_time}</label>
                        <br/>                         */}
                <DatePicker
                    className="form-control"
                    selected={props.search_end_time}
                    onChange={date => props.onDateChange('search_end_time', date)}
                    dateFormat="d-M-Y"
                    placeholderText={props.translations.bookings.end_time}
                // onCalendarClose={handleCalendarClose}
                // onCalendarOpen={handleCalendarOpen}
                />
            </div>
            <div className="col-md-2">
                {/* <label htmlFor="place">{props.translations.common.status}</label> */}
                <Select name="search_status" options={statusOptions}
                    placeholder={props.translations.common.status}
                    onChange={(valueLabel) => props.onChangeSelectAutocomplete('search_status', valueLabel)}
                />
            </div>
            {/* 
                    {Auth.hasAccess("CLIENT_ADMIN") ?
                        <div className="col-md-3">
                            <label htmlFor="place">{props.translations.common.place}</label>
                            <Select name="place" options={placesOptions} placeholder={""}
                                onChange={(valueLabel) => props.onChangeSelectAutocomplete('filter_place', valueLabel)} 
                            />
                        </div>
                    :
                        null
                    } */}

            <div className="col-md-2">
                <button className="btn btn-primary" onClick={props.callFilter}>{props.translations.common.filter}</button>
            </div>

            <hr />
        </div>
        // </div>
        // </div>
    )
}

export default Filter;