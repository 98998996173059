import React, { Component } from 'react'
import { listDeliverySystems, syncCitiesAndRegions } from '../actions/deliverySystems.actions';
import { addEditPlace, listPlacesByUserRole } from './../../places/actions/places.actions';
import { addSuccessMessage, addErrorMessages } from './../../messages/messages.actions';
import { showLoading, hideLoading } from './../../common/loading/loading.actions';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { ADMIN_URL } from '../../../constants';
import Utils from '../../../utils/Utils';
import Auth from '../../../utils/Auth';
import MessagesContainer from '../../messages/MessagesContainer';
import Select from 'react-select'

class PlaceDeliverySystemPage extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			// id: this.props.match.params.id,
			deliverySystemId: "",
			dsCredentials: "{}"
		}
	}

	
	componentWillMount() {
        this.props.listDeliverySystems();
    }

	componentDidMount() {
        this.props.listDeliverySystems();
    }

	componentWillReceiveProps(nextProps) {

		if(nextProps.currPlace.id) {
			this.setState({
				deliverySystemId: nextProps.currPlace.deliverySystemId,
				dsCredentials: nextProps.currPlace.delivery_system_credentials ? JSON.parse(nextProps.currPlace.delivery_system_credentials) : {}
			});
		}

	}
	onChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
	}

	onChangeCheckbox = (e) => {
		this.setState({ [e.target.name]: e.target.checked });
	}

	onChangeSelectAutocomplete = (field, valueLabel) => {
		this.setState({ [field]: valueLabel.value });
	}
	
	onJsonChange = (e) => {
		const { name, value } = e.target;

		this.setState((prevState) => ({
			dsCredentials: {
				...prevState.dsCredentials,
				[name]: value
			}
		}));
	};

	validate() {
		let errors = [];

		if (this.state.name.length === 0) {
			errors.push(this.props.translations.messages.errors.invalid_name);
		}
		return errors;
	}

	onSubmit = (e) => {
		e.preventDefault();

        // const errors = this.validate();
		// if (errors.length > 0) {
		// 	this.props.addErrorMessages(errors);
		// 	return;
		// }

		const post = {
			id: this.props.currPlace.id,
			deliverySystemId: this.state.deliverySystemId,
			delivery_system_credentials: JSON.stringify(this.state.dsCredentials)
		}

		this.props.addEditPlace(post, this.props.history)
			.then(resp => {
				this.props.listPlacesByUserRole();
				this.props.addSuccessMessage("Successfully edited settings");
			})

	}

	syncCitiesAndRegions = (e) => {
		e.preventDefault()

		this.props.showLoading();
		let that = this;

		this.props.syncCitiesAndRegions()
			.then((resp) => {
				that.props.hideLoading();
				let data = resp.data;

				if(data.success) {
					that.props.addSuccessMessage(data.msg ? data.msg : "Successfully synced cities and regions");
				} else {
					that.props.addErrorMessages([data.msg ? data.msg : "Error syncing cities and regions"]);
				}
			})
	}


	render() {
		
		let selectedDS;
		let selectedDSCredentialFields = {};
        let autocompleteItems = [];
        autocompleteItems.push({ 'value': null, 'label': "Choose delivery system" })

        this.props.deliverySystems.map(ds => {
            if (this.state.deliverySystemId == ds.id) {
                selectedDS = { 'value': ds.id, 'label': ds.name };
				selectedDSCredentialFields = JSON.parse(ds.credential_fields)
            }

            autocompleteItems.push({ 'value': ds.id, 'label': ds.name });
        });

		return (
			<main className="main">

				{/* <!-- Breadcrumb--> */}
				<ol className="breadcrumb">
					<li className="breadcrumb-item"><NavLink to={ADMIN_URL}>{this.props.translations.common.dashboard}</NavLink></li>
					<li className="breadcrumb-item"><NavLink to={ADMIN_URL + 'settings'}>{this.props.translations.sidemenu.settings}</NavLink></li>
					<li className="breadcrumb-item">{this.props.translations.delivery_systems.delivery_system}</li>
				</ol>

				<div className="container-fluid">
					<div className="animated fadeIn">
						<div className="row">
							<div className="col-sm-12">
								<div className="card">
									<form>
										<div className="card-body">
											<MessagesContainer />
											<div className="row">

												<div className="form-group col-sm-12">
													<label htmlFor="deliverySystemId">{this.props.translations.delivery_systems.delivery_system}</label>
													<Select name="deliverySystemId" options={autocompleteItems} 
														onChange={(valueLabel) => this.onChangeSelectAutocomplete('deliverySystemId', valueLabel)} 
														value={selectedDS}
														placeholder={this.props.translations.delivery_systems.delivery_system}
													/>
												</div>

												{Object.keys(selectedDSCredentialFields).map((key) => (
													<div key={key} className='col-sm-6 form-group'>
														<label htmlFor={key}>{key}:</label>
														<input
															type="text"
															id={key}
															className='form-control'
															name={key}
															value={this.state.dsCredentials[key]}
															onChange={this.onJsonChange}
														/>
														</div>
													))
												}

												<div className="col-sm-12">
													<div className="form-group">
														<button className="btn btn-primary" type="button" onClick={this.syncCitiesAndRegions}>
															{this.props.translations.delivery_systems.sync_cities_and_regions}
														</button>
													</div>
												</div>
												
											</div>

											<hr />
											<br />

											<div className="form-actions">

												<button className="fright btn btn-primary" type="submit" onClick={this.onSubmit}>
													{this.props.translations.common.save}
												</button>

											</div>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</main>
		)
	}
}

const mapStateToProps = (state, ownProps) => {
	let currPlaceId = Auth.getUserPlace();

	let currPlace = state.places.places.find(s => s.id == currPlaceId);

	if (!currPlace) currPlace = {
		id: '',
		name: '',
		active: 1,
		deliverySystemId: '',
		delivery_system_credentials: "{}"
	};
	return {
		deliverySystems: state.deliverySystems.deliverySystems,
		currPlace: currPlace,
		translations: state.lang,
		languages: state.languages.languages || {},
		currLang: state.settings.settings['default_lang'] && state.settings.settings['default_lang'].value || 'bg',
	}

};

export default connect(mapStateToProps, { listDeliverySystems, syncCitiesAndRegions,
	addEditPlace, listPlacesByUserRole, addSuccessMessage, addErrorMessages, showLoading, hideLoading })(PlaceDeliverySystemPage);