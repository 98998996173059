import { LIST_PROMOCODES } from '../actions/types';

const initialState = {
    promocodes: [],
}

export default function (state = initialState, action) {
    switch (action.type) {
        case LIST_PROMOCODES: {
            return {
                ...state,
                promocodes: action.payload,
            }
        }
        default:
            return state;
    }
}