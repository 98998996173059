import React, { Component } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { NavLink } from 'react-router-dom';
import Pagination from "react-js-pagination";
import { push } from 'react-router-redux';

import { ADMIN_URL, ITEMS_PER_PAGE, ITEMS_PAGE_RANGE_DISPLAYED } from '../../../../constants';
import { listDeliveryRadiuses, setDeliveryRadiuses } from '../../actions/deliveryRadiuses.actions';
import { addErrorMessages } from '../../../messages/messages.actions';
import ImportShapeMapPointsModal from './ImportShapeMapPointsModal';
import MessagesContainer from '../../../messages/MessagesContainer';
import queryString from 'query-string'
import Auth from '../../../../utils/Auth';

class DeliveryRadiusesPage extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            deliveryRadiuses: [
                {
                    radius_start: "",
                    radius_end: "",
                    min_order_price: "",
                    delivery_price: "",
                    required_order_price_for_free_delivery: "",
                }
            ]
        }
    }

    componentWillMount() {
        this.props.listDeliveryRadiuses();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.deliveryRadiuses) {

            // console.log("NEXT" , nextProps)
            let deliveryRadiuses = [];

            nextProps.deliveryRadiuses.sort((a, b) => (a.radius_start - b.radius_start)).map(t => {
                deliveryRadiuses.push({
                    radius_start: t.radius_start,
                    radius_end: t.radius_end,
                    min_order_price: t.min_order_price,
                    delivery_price: t.delivery_price,
                    required_order_price_for_free_delivery: t.required_order_price_for_free_delivery,
                    placeId: t.placeId,
                });
            })

            this.setState({
                deliveryRadiuses: deliveryRadiuses
            })
        }
    }

    onRowChange = (e, index) => {
        let { name, value } = e.target;

       

        const list = [...this.state.deliveryRadiuses];
        list[index][name] = value;
        this.setState({
            deliveryRadiuses: list
        })
    }

    onChangeCheckboxActiveDaysOfWeek = (e, index) => {
        const list = [...this.state.ordertypeDiscounts];
        // console.log(list)

		let currActiveDaysOfWeek = list[index]['activeDaysOfWeek'] ;//this.state.activeDaysOfWeek;
        currActiveDaysOfWeek[e.target.name] = +e.target.checked;
        list[index]['activeDaysOfWeek'] = currActiveDaysOfWeek;
        
        this.setState({
            ordertypeDiscounts: list
        })
    }

    addRow = () => {
        let row = {
            radius_start: "",
            radius_end: "",
            min_order_price: "",
            delivery_price: "",
            required_order_price_for_free_delivery: "",
        }

        let deliveryRadiuses = this.state.deliveryRadiuses;
        deliveryRadiuses.push(row);

        this.setState({
            deliveryRadiuses: deliveryRadiuses
        })
    }


    removeRow = (ind) => {
        let deliveryRadiuses = this.state.deliveryRadiuses;
        deliveryRadiuses.splice(ind, 1);
        this.setState({
            deliveryRadiuses: deliveryRadiuses
        })
    }

    validate = () => {
        let errors = {};

        // console.log(this.state.deliveryRadiuses)
        this.state.deliveryRadiuses.map((t, ind) => {
            // if (t.radius_start >= t.radius_end) {
            //     errors['radius_start_'+ind] = this.props.translations.messages.errors.radius_end_must_be_bigger_than_radius_start + " - row " + (ind*1+1) ;
            // }

            if(t.radius_start === "") {
                errors['radius_start'+ind] = this.props.translations.messages.errors.radius_start_required + " - row " + ((ind*1)+1) ;
            }

            if(t.radius_end === "") {
                errors['radius_end'+ind] = this.props.translations.messages.errors.radius_end_required + " - row " + ((ind*1)+1);
            }
        });

        
        return Object.values(errors);
    }

    save = () => {
        let postData = [];

        const errors = this.validate();
        // console.log(errors)
        if (errors.length > 0) {
            this.props.addErrorMessages(errors);
            return;
        }
        let data = [];

        this.state.deliveryRadiuses.map((radius, index) => {
            radius.placeId = Auth.getUserPlace();

            data.push(radius);
        })


        this.props.setDeliveryRadiuses({ placeId: Auth.getUserPlace(), deliveryRadiuses: data });

    }

    render() {
        // console.log(this.state.deliveryRadiuses)

        return (
            <React.Fragment>
                <main className="main">

                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><NavLink to={ADMIN_URL}>{this.props.translations.common.dashboard}</NavLink></li>
                        <li className="breadcrumb-item"><NavLink to={ADMIN_URL + 'settings'}>{this.props.translations.sidemenu.settings}</NavLink></li>
                        <li className="breadcrumb-item"><NavLink to={ADMIN_URL + 'settings/category/6'}>{this.props.translations.sidemenu.orders}</NavLink></li>
                        <li className="breadcrumb-item">{this.props.translations.settings.delivery_radiuses}</li>

                        {/* <li className="breadcrumb-item ">{this.props.translations.settings_categories && this.props.translations.settings_categories[(this.props.settingsCategoryName)] ? this.props.translations.settings_categories[(this.props.settingsCategoryName)] : this.props.settingsCategoryName}</li> */}
                    </ol>

                    <div className="container-fluid">
                        <div className="animated fadeIn">
                            <div className="row">

                                <div className="col-lg-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <MessagesContainer />

                                            <table className="table table-responsive-sm table-striped">
                                                <thead>
                                                    <tr>
                                                        <th width="10%">{this.props.translations.delivery_radiuses.radius_start}</th>
                                                        <th width="10%">{this.props.translations.delivery_radiuses.radius_end}</th>
                                                        <th width="10%">{this.props.translations.delivery_radiuses.min_order_price}</th>
                                                        <th width="10%">{this.props.translations.delivery_radiuses.delivery_price}</th>
                                                        <th width="40%">{this.props.translations.delivery_radiuses.required_order_price_for_free_delivery}</th>
                                                        <th width="10%">{this.props.translations.common.action}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    {Object.values(this.state.deliveryRadiuses).map((radius, index) => (
                                                        <tr key={index}>
                                                            <td>
                                                                <input type="number" min="0" name="radius_start" value={radius.radius_start} onChange={(e) => this.onRowChange(e, index)} className="form-control" />
                                                            </td>
                                                            <td>
                                                                <input type="number" min="0" name="radius_end" value={radius.radius_end} onChange={(e) => this.onRowChange(e, index)} className="form-control" />
                                                            </td>
                                                            <td>
                                                                <input type="number" min="0" name="min_order_price" value={radius.min_order_price} onChange={(e) => this.onRowChange(e, index)} className="form-control" />
                                                            </td>
                                                            <td>
                                                                <input type="number" min="0" name="delivery_price" value={radius.delivery_price} onChange={(e) => this.onRowChange(e, index)} className="form-control" />
                                                            </td>
                                                            <td>
                                                                <input type="number" min="0" name="required_order_price_for_free_delivery" value={radius.required_order_price_for_free_delivery} onChange={(e) => this.onRowChange(e, index)} className="form-control" />
                                                            </td>
                                                            <td>
                                                                <button className="btn btn-danger" onClick={() => this.removeRow(index)}>x</button>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>

                                        </div>
                                        <button className="btn btn-default" onClick={this.addRow}>{this.props.translations.common.add_row}</button>
                                        <button className="btn btn-primary" onClick={this.save} style={{ float: 'right' }}>{this.props.translations.common.save}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>

            </React.Fragment>

        )
    }
}

const mapStateToProps = (state, ownProps, dispatch) => {
    const query = queryString.parse(state.router.location.search)

    return {
        deliveryRadiuses: Object.values(state.settings.delivery_radiuses || []),//.sort((a, b) => (a.price_over - b.price_over)) || [],
        page: Number(state.router.location.query.page) || 1,
        translations: state.lang,
    }
};

const mapDispatchToProps = (dispatch, props) => {
    return {
        dispatch,
        ...bindActionCreators({ listDeliveryRadiuses, setDeliveryRadiuses, addErrorMessages }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryRadiusesPage);
