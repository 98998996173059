import React, { Component } from 'react'
import { connect } from 'react-redux';
import { addEditPlaceImage } from './../actions/place_images.actions';
import AddEditImageForm from './AddEditImageForm';
import { NavLink } from 'react-router-dom';
import { addErrorMessages } from './../../messages/messages.actions';
import { withRouter } from 'react-router-dom';
import { ADMIN_URL, API_TOKEN} from './../../../constants';
import Utils from '../../../utils/Utils';
import Auth from '../../../utils/Auth';

// let md5 = require('md5');

class AddPlaceImagePage extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			text: '',
			approved: 1,
			uploadedImgName: '',
		}
	}

	onChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
	}

	onChangeCheckbox = (e) => {
		this.setState({ [e.target.name]: e.target.checked });
	}

    addUploadedFileText = e => {
        if (e && e.target && e.target.files && e.target.files[0] && e.target.files[0].name) {
            this.setState({ uploadedImgName: e.target.files[0].name })
        }
    }

	validate() {
		let errors = [];

		if (this.state.name.length === 0) {
			errors.push(this.props.translations.messages.errors.invalid_name);
		}
		return errors;
	}

	onSubmit = (e) => {
		e.preventDefault();

		const files = Array.from(document.querySelector('#placeImage').files)
        let formData = new FormData()

        files.forEach((file, i) => {
            formData.append("file", file)
        })
        if (files.length == 0) {
			this.props.addErrorMessages(["Image is required"]);
			return;
        }

		formData.append("text", this.state.text)
		formData.append("placeId", Auth.getUserPlace())
		formData.append("approved", 1)

		this.props.addEditPlaceImage(formData, this.props.history);
	}

	render() {
		return (
			<main className="main">

				{/* <!-- Breadcrumb--> */}
				<ol className="breadcrumb">
					<li className="breadcrumb-item"><NavLink to={ADMIN_URL}>{this.props.translations.common.dashboard}</NavLink></li>
					{/* <li className="breadcrumb-item">Меню</li> */}
					<li className="breadcrumb-item"><NavLink to={ADMIN_URL + "ingredients"}>{this.props.translations.ingredients.title}</NavLink></li>
					<li className="breadcrumb-item active">{this.props.translations.ingredients.add}</li>
					{/* <!-- Breadcrumb Menu--> */}
					<li className="breadcrumb-menu d-md-down-none">
						<div className="btn-group" role="group" aria-label="Button group">
							{/* 
                            <!--<a className="btn" href="./">
                                <i className="icon-graph"></i>  Dashboard</a>
                            <a className="btn" href="#">
                                <i className="icon-settings"></i>  Settings</a>
                            --> 
                            */}
						</div>
					</li>
				</ol>

				<div className="container-fluid">
					<div className="animated fadeIn">
						<div className="row">

							{/* <!-- /.col--> */}
							<div className="col-sm-12">
								<div className="card">

									<AddEditImageForm
										onSubmit={this.onSubmit}
										onChange={this.onChange}
										onChangeCheckbox={this.onChangeCheckbox}
										addUploadedFileText={this.addUploadedFileText}
										placeImage={this.state}
										// actionText="Добави"
										isAdding={1}
										translations={this.props.translations}
									/>

								</div>
							</div>

						</div>
					</div>
				</div>

			</main>
		)
	}
}

const mapStateToProps = state => ({
	errors: state.ingredients.errors,
	translations: state.lang,
	languages: state.languages.languages || {},
	currLang: state.settings.settings['default_lang'] && state.settings.settings['default_lang'].value || 'bg',

});

// const mapDispatchToProps = (dispatch, ownProps) => {
// 	return {
// 		addIngredient: (data, imageData) => dispatch(addIngredient(ownProps.history, data, imageData)),
// 		addErrorMessages: (msgs) => dispatch(addErrorMessages(msgs))
// 	}
// }

export default withRouter(connect(mapStateToProps, { addEditPlaceImage, addErrorMessages })(AddPlaceImagePage));