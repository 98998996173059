import React, { useRef } from 'react'
import { NavLink } from 'react-router-dom';
import { ADMIN_URL } from '../../constants.js'
import moment from 'moment';
import Utils from '../../utils/Utils.js';
import ReactToPrint from 'react-to-print';
import ComponentToPrint from './ComponentToPrint';
import PAYMENT_STATUSES from './../common/enums/paymentStatuses';
import Select from 'react-select'
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import DeleteModal from './../common/DeleteModal';

const ListEachOrder = (props) => {
    const componentRef = useRef();
    const getBoxBackground = (order, orderStatus = null, key = 1) => {
        let status, bill_wanted;
        if (orderStatus) {
            status = orderStatus;
        } else {
            status = order.status;
            bill_wanted = order.bill_wanted;
        }

        if (status == "rejected") {
            return 'repeating-linear-gradient(  45deg,  #ddd,  #cfcfcf 50px,  #ddd 50px,  #cfcfcf 50px)';
        }

        if (status == 'cooked_spot' || status == 'cooked_delivery' || status == 'cooked_preorder' || status == 'cooked_room') {
            return '#fff4cc';
        }

        if (status == 'served_spot' || status == 'on_the_way_delivery') {
            return '#ccfffd';
        }

        if (status == 'paid') {
            return '#cbffdd'
        }

        if (bill_wanted) {
            return '#ff6a6a';
        }

        return key % 2 != 0 ? 'white' : '#f2f2f2';

    }

    let orderStatuses = props.statuses;

    let countAdditionalPriceCalculated = 0;
    //Calculate total sum
    for (let i = 0; i < Object.keys(props.order.order_productvariants).length; i++) {

        //Add additional price calculations - Only for pickup and delivery
        if ((props.order.ordertypeId == 2 || props.order.ordertypeId == 3) && (props.settings && props.settings.show_variant_additional_price && props.settings.show_variant_additional_price.value && props.settings.show_variant_additional_price.value == 1) && (props.order.order_productvariants[i].product_variant && ((parseFloat(props.order.order_productvariants[i].product_variant.additional_price)).toFixed(2)) != '0.00')) {
            countAdditionalPriceCalculated += 1 * props.order.order_productvariants[i].quantity;
        }

    }

    const large_print_button = window.localStorage.getItem('large_print_button');

    let orderDate = moment(props.order.createdAt).format('DD.MM HH:mm');
    let updateDate = moment(props.order.updatedAt).format('DD.MM HH:mm');
    let deliveryOrPickupDate = null;
    if ((props.order.ordertype.type == "delivery" || props.order.ordertype.type == "preorder") && props.order.time_until_order_ready) {
        deliveryOrPickupDate = moment(props.order.createdAt).add(props.order.time_until_order_ready, 'minutes').format('DD.MM HH:mm');
    }
    let productRequiredIngredients = [];
    let productAddablengredients = [];
    let productRemovableIngredients = [];

    let products = Object.values(props.order.order_productvariants).map((opv, ind) => {
        let productsById = props.all_products;
        // let variant = opv.product_variant || null;
        // let productSize = variant && variant.size || null;
        // let productQuantity = opv.quantity ? opv.quantity : '';
        opv.discount_percent = opv.product_variant && opv.product_variant.productId && productsById[opv.product_variant.productId] && productsById[opv.product_variant.productId].discount_percent || 0;

        const prodComment = props.order.order_comment ? JSON.parse(props.order.order_comment).find(p => p.variantId === opv.productVariantId) : undefined;
        productRequiredIngredients = [];
        productAddablengredients = [];
        productRemovableIngredients = [];

        let newStatus;

        const onChangeProductVariantCheckbox = () => {
            if (Utils.getSettingsValue(props.settings, 'raspberry_display') == '1' && localStorage.getItem('tabl_raspberry') == '1') {
                Utils.navigateTo('/admin/products/edit/' + opv.product_variant.productId)
            } else if (props.order.status == 'ordered' || props.order.status == 'cooked_spot') {
                props.updateVariantStatus(opv.id, newStatus)
            }
        }

        let checkboxChecked = false

        if (props.order.status == 'ordered') {
            checkboxChecked = opv.status == 'done'
            if (checkboxChecked) {
                newStatus = 'cooking'
            } else {
                newStatus = 'done'
            }
        } else if (props.order.status == 'cooked_spot') {
            checkboxChecked = opv.status == 'delivered';
            if (checkboxChecked) {
                newStatus = 'done'
            } else {
                newStatus = 'delivered'
            }
        } else if (props.order.status == 'served_spot') {

        }


        // console.log('props.order.order_productvariants', props.order.order_productvariants)
        // console.log('props.order', props.order)
        // console.log('object', object)

        // if (props.all_products && props.all_products[opv.product_variant.productId]) {
        // } else {
        //     props.listProducts();
        //     props.listIngredients();
        // } 

        return <div>
            <p key={ind} onClick={onChangeProductVariantCheckbox} className={((props.order.status == 'ordered' || props.order.status == 'cooked_spot') ? "c-pointer " : "") + (checkboxChecked ? " tdecoration-linethrough" : "")} title={(props.order.status == 'ordered' || props.order.status == 'cooked_spot') ? 'Задраскай (Не оказва влияние по никои от другите екрани)' : ''} >
                {(props.order.status == 'ordered' || props.order.status == 'cooked_spot') ?
                    <input type="checkbox" onChange={onChangeProductVariantCheckbox} checked={checkboxChecked} className="d-inline medium-checkbox valign-middle c-pointer w-10 m-right-7 valign-middle " style={{ width: 'auto !important' }} />
                    :
                    null
                }
                {opv.quantity} x <b>{opv.product_variant && opv.product_variant.product && opv.product_variant.product.name || ''}</b> - <i>{opv.product_variant && opv.product_variant.size}</i> /{Utils.calculateProductPriceOrder(opv, props.settings, opv.opv_ingredients, 1, (props.order.ordertypeId == '2' || props.order.ordertypeId == '3'))}{/*opv.product_variant && opv.product_variant.price*/} {(props.settings.default_currency_suffix && props.settings.default_currency_suffix.value ? props.settings.default_currency_suffix.value : 'лв.')}/
                -&nbsp;
                <span style={{ 'text-decoration': 'none', background: '#e8e8e8', 'border-radius': 4, padding: '1px 5px' }} >
                    ({opv.cat_hierarchy && opv.cat_hierarchy.name || ''})
                </span>
                {((props.order.ordertypeId == 2 || props.order.ordertypeId == 3) && ((props.settings && props.settings.show_variant_additional_price && props.settings.show_variant_additional_price.value && props.settings.show_variant_additional_price.value == 1) && opv.product_variant && ((parseFloat(opv.product_variant.additional_price)).toFixed(2) != '0.00')) ? (' + ' + (props.translations && props.translations.common && props.translations.common.box ? props.translations.common.box : '') + ' ' + opv.product_variant.additional_price + ' ' + (props.settings.default_currency_suffix && props.settings.default_currency_suffix.value ? props.settings.default_currency_suffix.value : 'лв.')) : '')}
                {prodComment && prodComment.comment ? <> - <span className="badge badge-secondary" style={{ fontSize: 13 }}>{prodComment.comment}</span></> : null}
                {opv.opv_ingredients.map((ing, k) => {
                    if (ing.ingredient_type == "required") {
                        if (ing.ingredient) {
                            productRequiredIngredients.push(ing.ingredient.name);
                        }
                    }
                    if (ing.ingredient_type == "addable") {
                        if (ing.ingredient) {
                            productAddablengredients.push(ing.ingredient.name);
                        }
                    }
                    if (ing.ingredient_type == "removable") {
                        if (ing.ingredient) {
                            productRemovableIngredients.push(ing.ingredient.name);
                        }
                    }
                })}

                {
                    productRequiredIngredients.length > 0 ?
                        <div style={{ fontStyle: 'italic' }}>
                            {productRequiredIngredients.join(", ")}
                        </div>
                        :
                        null
                }

                {
                    productAddablengredients.length > 0 ?
                        <div style={{ color: '#008017', fontStyle: 'italic' }}>+
                            {productAddablengredients.join(", ")}
                        </div>
                        :
                        null
                }

                {
                    productRemovableIngredients.length > 0 ?
                        <div style={{ color: '#c50000', fontStyle: 'italic' }}>-
                            {productRemovableIngredients.join(", ")}
                        </div>
                        :
                        null
                }

            </p>
        </div >
    })

    // let currentRegion = null;
    // if (props.order.regionId && props.regions) {
    //     currentRegion = props.regions.find(r => r.id == props.order.regionId)
    //     if (currentRegion && currentRegion.name) {
    //         currentRegion = currentRegion.name
    //     }
    // }

    const payment_methods_for_order_types = Utils.getSettingsValue(props.settings, 'payment_methods_for_order_types') || {};

    return (
        <tr key={props.order.place_order_number} className="card-body p-0"
            style={{
                background: getBoxBackground(props.order, null, props.index),
            }}>

            <td title={props.order.id} className={
                (props.order.seen_order == 0 && props.order.status == 'ordered' &&
                    (Utils.getSettingsValue(props.settings, 'raspberry_display') == '1'
                        ? true
                        : localStorage.getItem('tabl_raspberry') == '1'
                            ? false
                            : true
                    )
                    ? (props.index % 2 != 0 ? "unseen_order_odd" : "unseen_order_even")
                    : ""
                )
                + ' no-padding talign-center'
            }
                style={{
                    position: 'relative'
                }}>
                {props.order.place_order_number ? parseInt(props.order.place_order_number) * 1 : 'ID' + props.order.id}
                <span title={'Order ID in WHS'}><br />{props.order.whs_order_id ? props.order.whs_order_id : null}</span>
                {props.order.status == "rejected" ?
                    <p style={{
                        position: 'absolute',
                        top: 0,
                        background: 'red',
                        color: 'white',
                        padding: 5,
                        fontSize: 11,
                    }}>ОТКАЗАНА</p>
                    :
                    null
                }
            </td>
            <td className="no-padding">
                {props.ordertype == 'all' ?
                    <div style={{ textAlign: 'center' }}>
                        {props.order.ordertype && props.order.ordertype.type == "delivery" ? <span className="badge badge-warning fsize-13">{props.translations.orders.types.delivery}</span> : ""}
                        {props.order.ordertype && props.order.ordertype.type == "preorder" ? <span className="badge badge-dark fsize-13">{props.translations.orders.types.preorder}</span> : ""}
                        {props.order.ordertype && props.order.ordertype.type == "spot" ? <span className="badge badge-info fsize-13">{props.translations.orders.types.spot}</span> : ""}
                        {props.order.ordertype && props.order.ordertype.type == "room" ? <span className="badge badge-success fsize-13">{props.translations.orders.types.room} {props.order.additional_order_type ? props.order.additional_order_type == 'delivery' ? '- С ДОСТАВКА' : '- ВЗИМАНЕ ОТ МЯСТО' : null}</span> : ""}
                        {props.order.ordertype && props.order.ordertype.type == "served_on_spot" ? <span className="badge badge-success fsize-13">{props.translations.orders.types.served_on_spot} </span> : ""}
                    </div>
                    :
                    null
                }
                <br />
                {Object.keys(orderStatuses).map((s, kk) => {
                    if (localStorage.getItem('tabl_raspberry') == 1 && Utils.getSettingsValue(props.settings, 'raspberry_display') == '1' && s == 'ordered') return null;
                    // return <button key={ind} value={s} style={{ display: 'block', width: '100%' }}>{orderStatuses[s]}</button>
                    return <button key={kk}
                        style={{ width: '100%', display: 'block', maxWidth: 125, background: getBoxBackground(null, s, props.index), border: '1px solid #ccc', padding: (localStorage.getItem('tabl_raspberry') == 1 && Utils.getSettingsValue(props.settings, 'raspberry_display') == '1' ? '15px 2px' : '10px 2px'), 'borderCollapse': 'collapse', fontWeight: props.order.status == s ? 'bold' : 'normal' }}
                        // onClick={() => props.updateStatus(props.order.id, s, props.order)}
                        onClick={(ev) => props.updateStatus(props.order.id, s, props.order)}
                    >
                        {props.statuses[s]}
                    </button>


                })}

                {/* <select className="form-control" defaultValue={props.order.status} onChange={}>
                    {orderStatusOptions}
                </select> */}
            </td>
            <td>
                {props.order.client_name ? <React.Fragment>{props.order.client_name} </React.Fragment> : null}
                {props.order.client_phone ? <React.Fragment> / <a href={"tel:" + ((props.order.client_phone).split(' ').join(''))} target="_blank">{props.order.client_phone}</a><br /></React.Fragment> : ''}
                {props.order.isClientNew ? <span><i className={"badge badge-dark"} style={{ fontSize: 13 }}>Нов клиент</i>{props.order.client_email ? <> - </> : <br />}</span> : null}
                {props.order.client_email ? <React.Fragment><a href={"mailto:" + ((props.order.client_email).split(' ').join(''))} target="_blank">{props.order.client_email}</a> <br /></React.Fragment> : null}
                {props.order.client_address && props.order.ordertype.type == 'delivery' ? <span span className="badge badge-secondary" style={{ fontSize: 14, backgroundColor: 'rgb(232, 232, 232)' }}><a href={"https://www.google.com/maps/dir/?api=1&destination=" +
                    props.order.client_address.split("/")[0]
                    // (props.order && props.order.client_address && props.order.client_address.indexOf('/') !== -1 && props.order.client_address.split("/")[0] ? props.order.client_address.split("/")[0] : '')
                } target="_blank" title={"Google Карта с навигация до адреса"} style={{ color: '#23282c' }}>{props.order.client_address} / {props.order.street_number ? ' № ' + props.order.street_number : null} {(props.order.ap_number ? ' Ап. ' + props.order.ap_number : '')} {(props.order.floor_number ? ' Ет. ' + props.order.floor_number : '')} {(props.order.bell_number ? ' Зв. ' + props.order.bell_number : '')}</a></span> : null}

                {props.order.ordertypeId != 1 && props.deliveryTime ?
                    <React.Fragment>
                        <br />
                        {props.translations.orders.client_delivery_time}<b>{props.order.exact_time_selected ?
                            (Utils.getSettingsValue(props.settings, 'enable_showing_diapasone_in_times_for_delivery_or_pickup_in_order_modal') == 1 ? props.deliveryTime + ' - ' + props.addMinutesToTime(props.deliveryTime, Utils.getSettingsValue(props.settings, 'minutes_margin_working_hours_choose_date_for_pickup_or_delivery_in_order_modal')) : props.deliveryTime)
                            : 'възможно най-скоро'}</b>

                        {props.order.exact_time_selected && !Utils.checkDatesInSameDayAndHour(props.order.delivery_time, props.order.createdAt) ?
                            <><br />
                                <span className="badge badge-warning fsize-13"><i>БЪДЕЩА ПОРЪЧКА</i></span>
                            </>
                            :
                            null
                        }

                    </React.Fragment>
                    :
                    null
                }

                {props.pickupTime && props.order.ordertype && (props.order.ordertype.type != "room" || (props.order.ordertype.type == "room" && Utils.getSettingsValue(props.settings, 'hide_order_room_date') != '1')) ?
                    <React.Fragment>
                        <br />
                        {props.order.ordertype.type == "room" ? props.translations.orders.serve_time : props.translations.orders.client_pickup_time}<b>{props.order.exact_time_selected ?
                            (Utils.getSettingsValue(props.settings, 'enable_showing_diapasone_in_times_for_delivery_or_pickup_in_order_modal') == 1 ? props.pickupTime + ' - ' + props.addMinutesToTime(props.pickupTime, Utils.getSettingsValue(props.settings, 'minutes_margin_working_hours_choose_date_for_pickup_or_delivery_in_order_modal')) : props.pickupTime)
                            : 'възможно най-скоро'}</b>

                        {props.order.exact_time_selected && !Utils.checkDatesInSameDayAndHour(props.order.pickup_time, props.order.createdAt) ?
                            <><br />
                                <span className="badge badge-warning fsize-13"><i>БЪДЕЩА ПОРЪЧКА</i></span>
                            </>
                            :
                            null
                        }
                    </React.Fragment>
                    :
                    null
                }

                {props.order.delivery_resp_data && props.order.deliveryStatus ?
                    <React.Fragment >
                        {props.order.deliveryStatus.status ?
                            <i className="fas fa-shipping-fast" style={{ color: "green", cursor: "pointer", fontSize: 20, marginLeft: 5 }} data-toggle="tooltip" data-placement="top" title={"Status: " + props.order.deliveryStatus.status + " | Tracking num: " + props.order.deliveryStatus.trackingNumber}></i>
                            :
                            <React.Fragment>
                                <i className="fas fa-shipping-fast" style={{ color: "red", cursor: "pointer", fontSize: 20, marginLeft: 5 }} data-toggle="tooltip" data-placement="top" title={"Error code: " + props.order.deliveryStatus.errorCode + " | Error: " + props.order.deliveryStatus.errorDescription}></i>
                                {props.currPlace && props.currPlace.delivery_system && props.currPlace.delivery_system.name == 'glovo' ?
                                    <button className="btn btn-warning ml-2"
                                        onClick={() => props.openConfirmModal("Сигурни ли сте, че искате да изпратите повторно поръчката към системата за доставки?",
                                            () => props.resendOrderToDeliverySystem(props.order.id),
                                            () => void (0))}>
                                        {props.translations.delivery_systems.resend_order}
                                    </button>
                                    :
                                    null}
                            </React.Fragment>
                        }
                    </React.Fragment>
                    :
                    null
                }

                {props.order.ordertype && props.order.ordertype.type == "spot" && props.order.table && props.order.table.table_num ?
                    <React.Fragment>
                        {props.translations.common.table}: <b>{props.order.table.table_num}</b>
                    </React.Fragment>
                    :
                    null
                }

                {props.order.ordertype && props.order.ordertype.type == "room" && props.order.room_number ?
                    <React.Fragment>
                        <br />{props.translations.common.room}: <b>{props.order.room_number}</b>
                    </React.Fragment>
                    :
                    null
                }

                {props.order.free_product ?
                    <React.Fragment>
                        <br />Подарък: <b style={{ "color": "red" }}>{props.order.free_product}</b>
                    </React.Fragment>
                    :
                    null}

            </td>


            <td>
                {props.order.order_text_comment && props.order.order_text_comment.length > 0 ?
                    <div>
                        <span style={{ fontWeight: 'bold' }}>Коментар към поръчката:</span>
                        <span style={{
                            display: 'block',
                            background: '#ccc',
                            padding: 6,
                        }}>
                            {props.order.order_text_comment}
                        </span>
                        <br /><br />
                    </div>
                    :
                    null
                }

                {products}
            </td>

            {props.allPlaces && props.allPlaces.length > 1 ?
                <td>
                    <b>{props.allPlaces && props.allPlaces.find(p => p.id == props.order.placeId) ? props.allPlaces.find(p => p.id == props.order.placeId).name : '-'}</b>
                </td>
                :
                null}

            <td className="no-padding">
                <br />

                {Utils.hasFunctionalityModule("payments") ?
                    props.order.payment_status == PAYMENT_STATUSES.PAID ?
                        <span><b className={"badge badge-success"} style={{ fontSize: 13 }}>{props.order.payment_method == "epay" ? 'Платена с EPAY' : props.order.payment_method == "online" ? "Платена онлайн" : "-"}</b></span>
                        :
                        null
                    :
                    null
                }

                {payment_methods_for_order_types && (payment_methods_for_order_types[props.order.ordertype.type] && payment_methods_for_order_types[props.order.ordertype.type].length > 0) ?
                    < span > <b className={props.order.payment_method == 'cash' ? "badge badge-success" : "badge badge-info"} style={{ fontSize: 13 }}>{props.order.payment_method == 'cash' ? 'В брой' : (props.order.payment_method == 'card' ? 'С карта' : (props.order.payment_method == 'on_room_bill' ? 'На сметката на стаята' : null))}</b></span>
                    :
                    null
                }

                <br />

                {(Utils.getSettingsValue(props.settings, 'show_cutlery_option_on_making_delivery_order') == '1' && props.order.ordertype.type == "delivery") ||
                    (Utils.getSettingsValue(props.settings, 'show_cutlery_option_on_making_preorder_order') == '1' && props.order.ordertype.type == "preorder") ||
                    (Utils.getSettingsValue(props.settings, 'show_cutlery_option_on_making_room_order') == '1' && props.order.ordertype.type == "room") ?
                    <span>
                        <b
                            className={props.order.cutlery == '1' || ((props.order.ordertype.type == "delivery" || props.order.ordertype.type == "preorder") && props.order.count_cutlery && parseInt(props.order.count_cutlery) > 0) ? "badge badge-warning" : "badge badge-danger"}
                            style={{ fontSize: 13 }}>
                            {props.order.cutlery == '1'
                                ||
                                (((props.order.ordertype.type == "delivery" || props.order.ordertype.type == "preorder")) && ((props.order.ordertype.type == "delivery" || props.order.ordertype.type == "preorder") && props.order.count_cutlery && parseInt(props.order.count_cutlery) > 0))
                                ?
                                (
                                    'С прибори ' + (props.order.count_cutlery > 0
                                        ? parseFloat(props.order.count_cutlery) + ' бр. - ' + (Utils.getSettingsValue(props.settings, 'additional_price_each_cutlery') && Utils.getSettingsValue(props.settings, 'additional_price_each_cutlery') != '0' ? (parseFloat(Utils.getSettingsValue(props.settings, 'additional_price_each_cutlery')) * parseFloat(props.order.count_cutlery)).toFixed(2) + ' лв.' : '')
                                        : '')
                                )
                                : (props.order.cutlery == '0' ? 'Без прибори' : null)
                            }
                        </b>
                    </span>
                    :
                    null
                }

                <br />
                <br />

                {/* --- */}
                {/* Not needed - the admin users are getting confused if its already paid
                {props.order.status == 'served_spot' ?
                    <>
                        <button type="submit" class="btn btn-sm btn-success"
                            onClick={() => { props.updateStatus(props.order.id, "paid", props.order) }}
                        >
                            <i class="fa fa-dot-circle-o"></i>
                            &nbsp;
                            {props.translations.common.paid}
                        </button>
                        <br /><br />
                    </>
                    :
                    null
                } */}

                {Utils.getSettingsValue(props.settings, 'times_until_order_ready') ?
                    <>
                        <FormControl fullWidth>
                            <InputLabel variant="standard" htmlFor="uncontrolled-native">
                                {props.translations.common.time_until_order_ready}
                            </InputLabel>
                            <NativeSelect
                                defaultValue={props.order.time_until_order_ready}
                                inputProps={{
                                    name: '',
                                    id: 'uncontrolled-native',
                                }}
                                onChange={(e) => props.changeTimeUntilOrderReady(e, props.order.id)}
                            >
                                <option>-</option>
                                {Object.values(Utils.getSettingsValue(props.settings, 'times_until_order_ready')).map(time => {
                                    return <option value={time}>{time} мин.</option>
                                })}
                            </NativeSelect>
                            <br />
                        </FormControl>
                    </>
                    :
                    null
                }

                <p style={{ fontSize: 15, border: '1px solid #9c9c9c', padding: 2, 'border-radius': 5, width: 'fit-content', margin: 0 }}><b>{props.order.total_price ? 'Общо: ' + props.order.total_price : null}&nbsp;{props.settings.default_currency_suffix && props.settings.default_currency_suffix.value || props.translations.common.currency}</b></p>

                {countAdditionalPriceCalculated > 0 ?
                    <React.Fragment>
                        /{(props.translations && props.translations.common && props.translations.common.included ? props.translations.common.included : '')} {countAdditionalPriceCalculated} {(props.translations && props.translations.common ? (countAdditionalPriceCalculated == 1 && props.translations.common.box ? props.translations.common.box.toLowerCase() : (props.translations.common.boxes ? props.translations.common.boxes.toLowerCase() : '')) : '')}/
                        <br /><br />
                    </React.Fragment>
                    :
                    ''
                }

                {(props.order.discount_price && props.order.discount_price != 0) ||
                    (props.order.promocode && props.order.promocode.discount_value && props.order.promocode.discount_type) ||
                    (props.order.user_discount_value && props.order.user_discount_type && props.order.user_discount_price) ?
                    <p>
                        {props.order.discount_price && props.order.discount_price != 0 ?
                            <div style={{ textDecoration: props.order.promocode && props.order.promocode.ignore_other_discounts ? 'line-through' : 'none' }}>
                                <b>Отстъпка:</b> {props.order.discount_price} {props.settings.default_currency_suffix && props.settings.default_currency_suffix.value || props.translations.common.currency} {props.order.discount_type == 'percent' && props.order.discount_value ? <span>({props.order.discount_value} %)</span> : null}
                            </div>
                            :
                            null
                        }

                        {props.order.promocode && props.order.promocode.discount_value && props.order.promocode.discount_type ?
                            <div >
                                <span class="badge badge-danger" style={{ 'fontSize': 13 }}><b>Промокод:</b> {props.order.promocode.discount_value > 0 ? props.order.promocode.discount_value : ''}
                                    {props.order.promocode.discount_value > 0 && props.order.promocode.discount_type == 'percent' ? '%' : props.order.promocode.discount_value > 0 && props.settings.default_currency_suffix && props.settings.default_currency_suffix.value || (props.order.promocode.discount_value > 0 && props.translations.common.currency) || ''} {props.order.promocode && props.order.promocode.description || ""}</span>
                            </div>
                            :
                            null
                        }

                        {props.order.user_discount_value && props.order.user_discount_type && props.order.user_discount_price ?
                            <div title={"Отстъпка регистриран потребител"}>
                                <b>Лична отстъпка:</b> {props.order.user_discount_value} {props.order.user_discount_type == 'percent' ? '%' : props.settings.default_currency_suffix && props.settings.default_currency_suffix.value || props.translations.common.currency} <span>({props.order.user_discount_price} {props.settings.default_currency_suffix && props.settings.default_currency_suffix.value || props.translations.common.currency})</span>
                            </div>
                            :
                            null
                        }

                    </p>
                    :
                    null
                }






                {props.order.delivery_price && props.order.delivery_price != 0 ?
                    <p><b>Доставка:</b> {props.order.delivery_price} {props.settings.default_currency_suffix && props.settings.default_currency_suffix.value || props.translations.common.currency}</p>
                    :
                    null
                }

                {/* --- */}

                <b>{props.translations.orders.order_date_short}: </b>
                {orderDate}

                <br />

                {deliveryOrPickupDate && !props.order.exact_time_selected ?
                    <>
                        <b>{props.order.ordertype.type == "delivery" ? props.translations.orders.delivery_date_short : props.order.ordertype.type == "preorder" ? props.translations.orders.pickup_date_short : null}: </b>
                        {deliveryOrPickupDate}
                        <br />
                    </>
                    :
                    null
                }



                <b>{props.translations.orders.update_date_short}: </b>
                {updateDate}

                <br />
                <br />
                {props.order.anonymous_user ?
                    props.order.anonymous_user.banned == true ?
                        <button className='btn btn-danger' onClick={() => props.banUnbanAnonUser(props.order.anonymous_user.id, 0)} style={{ paddingTop: 0, paddingBottom: 0 }}>Device {props.order.anonymousUserId} - banned</button>
                        :
                        <button className='btn btn-info' onClick={() => props.banUnbanAnonUser(props.order.anonymous_user.id, 1)} style={{ paddingTop: 0, paddingBottom: 0 }}>Device {props.order.anonymousUserId} - not banned</button>
                    :
                    null
                }

                {/* --- */}
                <br /><br />

                {Utils.getSettingsValue(props.settings, 'allow_auto_print_orders') == 1 && props.order.printed == 1 ?
                    <span className="badge badge-secondary">Отпечатана</span>
                    :
                    null
                }

                {Utils.getSettingsValue(props.settings, 'allow_auto_print_orders') == 1 ?
                    <>
                        <ReactToPrint
                            trigger={() =>
                                <button className="btn btn-pill btn-link" type="button" >
                                    <i className={"fas fa-print " + (large_print_button != null ? 'large-print-button' : '')}></i>
                                </button>
                            }
                            onBeforePrint={() => {
                                props.updatePrinted(props.order.id);
                                // console.log('(C) printing orderId:', props.order.id); // pls do not remove this :)
                            }}
                            content={() => componentRef.current}
                        />
                        <div style={{ display: 'none' }}>
                            <ComponentToPrint
                                ref={componentRef}
                                settings={props.settings}
                                order={props.order}
                                all_products={props.all_products}
                                regions={props.regions}
                                ingredients={props.ingredients}
                                prodPrintGroups={props.prodPrintGroups}
                                addMinutesToTime={props.addMinutesToTime}
                            />
                        </div>
                    </>
                    :
                    null
                }

                <button className="btn btn-pill btn-link" type="button" onClick={() => props.openDeleteOrderModal(props.order.id, props.order.place_order_number)}>
                    <i className="fas fa-trash"></i>
                </button>

                <button className="btn btn-pill btn-link" type="button"
                    onClick={() => props.openRejectOrderModal(props.order)}
                    style={{ color: props.order.status == "rejected" ? "red" : "#20a8d8" }}>
                    <i className="fas fa-power-off" data-toggle="tooltip" data-placement="top" title={props.translations && props.translations.orders.statuses.rejected}></i>
                </button>

            </td>


        </tr >
    )
}

export default ListEachOrder;