import axios from 'axios';
import { LIST_ORDERTYPE_PRICES, LIST_ORDERTYPE_DISCOUNTS } from './types';
import { API_URL, ADMIN_URL } from './../../../constants';
import { addSuccessMessage, addErrorMessages} from './../../messages/messages.actions';
import { changeLanguage } from './../../../actions/languagesActions';
import Auth from './../../../utils/Auth';

export const listOrdertypePrices = () => dispatch => {
    let getParam = '';
    getParam += "?place_id=" + Auth.getUserPlace();

    axios
        .get(API_URL + 'ordertypes/prices' + getParam)
        .then(res => {
            dispatch({
                type: LIST_ORDERTYPE_PRICES,
                payload: res.data
            });
        })
        .catch(error => {
            throw (error);
        });
}

export const addOrdertypePrices = (data) => dispatch => {
    axios
    .post(API_URL + 'ordertypes/set_ordertype_prices', data)
    .then(res => {
        dispatch(listOrdertypePrices());
        dispatch(addSuccessMessage("Successfully changed"))

    })
    .catch(error => {
        throw (error);
    });
}


export const listOrdertypeDiscounts = (ordertype) => dispatch => {
    let getParam = '';
    getParam += "?place_id=" + Auth.getUserPlace();

    if(ordertype) {
        getParam += "&ordertype="+ordertype
    }

    axios
        .get(API_URL + 'ordertypes/discounts' + getParam)
        .then(res => {
            dispatch({
                type: LIST_ORDERTYPE_DISCOUNTS,
                payload: res.data
            });
        })
        .catch(error => {
            throw (error);
        });
}

export const addOrdertypeDiscounts = (data, ordertype = null) => dispatch => {
    axios
    .post(API_URL + 'ordertypes/set_ordertype_discounts', data)
    .then(res => {
        dispatch(addSuccessMessage("Successfully changed"))

        if(ordertype) {
            dispatch(listOrdertypeDiscounts(ordertype));
        }
        
    })
    .catch(error => {
        throw (error);
    });
}