import axios from 'axios';
import { LIST_REGIONS } from './types';
import { API_URL, ADMIN_URL } from './../../../constants';
import { addSuccessMessage, addErrorMessages } from './../../messages/messages.actions';
import { changeLanguage } from './../../../actions/languagesActions';
import Auth from './../../../utils/Auth';
import jwtDecode from 'jwt-decode';
import { showLoading, hideLoading } from './../../common/loading/loading.actions';

export const listRegions = (place_id = 0) => dispatch => {
    let getParam = '';
    if(place_id == 0) {
        place_id = Auth.getUserPlace();
        getParam += "?place_id=" + place_id;
    }

    axios
        .get(API_URL + 'regions' + getParam)
        .then(res => {
            dispatch({
                type: LIST_REGIONS,
                payload: res.data
            });
        })
        .catch(error => {
            throw (error);
        });
}


export const addEditRegion = (data, history, langId) => dispatch => {
    if(!data.placeId) {
        data.placeId = Auth.getUserPlace();
    }

    dispatch(showLoading());

    axios
        .post(API_URL + 'regions', data)
        .then((post) => {
            post = post.data;
            // dispatch(addEditIngredientTranslation(post.id, langId, data))
            // if (!postData.id && typeof postData.deleted === "undefined") { // edit
            // } else {
            dispatch(addSuccessMessage("Changes saved"))
            dispatch(listRegions());
            if(post.deleted) {
                history.push("/admin/regions");
                dispatch(addSuccessMessage("Successfully deleted row"))
                dispatch(hideLoading());
            } else {
                history.push("/admin/regions/edit/"+post.id);                
                dispatch(hideLoading());
            }


            // }
        })
        .catch(function (error) {
                dispatch(hideLoading());
                // dispatch(addErrorMessages('Error occured'));
        })
}