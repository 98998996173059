import React, { Component } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { listTableRegions, addEditTableRegion } from './../actions/tableRegions.actions';
import ListEachTableRegion from './ListEachTableRegion';
import Pagination from "react-js-pagination";
import { NavLink } from 'react-router-dom';
import { push } from 'react-router-redux';
import { ADMIN_URL, ITEMS_PER_PAGE, ITEMS_PAGE_RANGE_DISPLAYED } from '../../../constants.js'
import MessageContainer from '../../messages/MessagesContainer';
import Utils from '../../../utils/Utils';
import { WonderPush } from 'react-wonderpush';
import Auth from '../../../utils/Auth.js';

class ListTableRegionsPage extends React.Component {
    constructor(props) {
        super(props);
        // this.activeDefaultLanguage = 'bg'; // TODO add it in db
    }

    componentWillMount() {
        this.props.listTableRegions();
    }

    deleteTableRegion = (e, id) => {
        e.preventDefault();
        // this.props.removeTableRegion(id);
    }

    handlePageChange = (page) => {
        this.props.dispatch(push('/admin/tableRegions?page=' + page))
    }

    onChangeCheckboxIsForReservation = (e, id) => {
        const post = {
            id: id,
            is_for_reservation: e.target.checked,
        }
        this.props.editTableRegion(id, post);
    }

    removeAllTagsPushNotifications = () => {
        window.WonderPush = window.WonderPush || [];
        window.WonderPush.removeAllTags();
    }


    subscribeToPushNotifications = () => {
        window.WonderPush = window.WonderPush || [];
        window.WonderPush.push(['setUserId', Auth.getDecodedUser().email]);
        window.WonderPush.addTag(Auth.getUserPlace());
    }

    render() {
        let tableRegionsList = this.props.tableRegions;

        const items_count = Object.keys(tableRegionsList).length
        const current_page = this.props.page;
        const start_offset = (current_page - 1) * ITEMS_PER_PAGE;
        let start_count = 0;

        // console.log('exportDataSet', exportDataSet)
        return (
            <main className="main">

                {/* <!-- Breadcrumb--> */}
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><NavLink to={ADMIN_URL}>{this.props.translations.common.dashboard}</NavLink></li>
                    <li className="breadcrumb-item active">{this.props.translations.table_regions.title}</li>
                    <li className="breadcrumb-menu d-md-down-none">
                        <div className="btn-group" role="group" aria-label="Button group">
                            <NavLink to={ADMIN_URL + `tableRegions/add`} className="btn" >
                                <i className="fas fa-plus"></i> &nbsp; {this.props.translations.table_regions.add}
                            </NavLink>
                        </div>
                    </li>
                </ol>


                <div className="container-fluid">
                    <div className="animated fadeIn">
                        <div className="row">



                            <div className="container-fluid">
                                <div className="animated fadeIn">
                                    <div className="row">

                                        <div className="form-group reservation-list-search col-md-3 col-sm-4 ">
                                            <div className="form-group">
                                                <span className="badge badge-danger" style={{ padding: 10, display: 'inline', marginRight: 10, fontSize: 13, cursor: 'pointer' }}
                                                    onClick={() => {
                                                        this.removeAllTagsPushNotifications()
                                                        alert('Успешно са премахнати всички PUSH нотификации за текущия обект на текущото устройство. За да се Впишете пак, използвайте бутона включи по-долу.')
                                                    }}>
                                                    Премахни PUSH нотификации за всички региони
                                                </span>

                                                {/* <span className="badge badge-info" style={{ padding: 10, display: 'inline', marginRight: 10, fontSize: 13, cursor: 'pointer' }} title="Натисни и при нова поръчка ще получиш известие"
                                                    onClick={() => { this.subscribeToPushNotifications(); alert('Успешно е добавено устройството за PUSH нотификации от текущия обект. За да се Отпишете, използвайте камбанката долу вдясно.') }}>
                                                    Включи PUSH нотификации за поръчки от текущия обект /доставка, взимане от място/
                                                </span> */}
                                            </div>
                                        </div>
                                        <div className="form-group reservation-list-search col-md-4 col-sm-4 ">
                                            <span className="badge badge-info" style={{ padding: 10, display: 'inline', marginRight: 10, fontSize: 13, cursor: 'pointer' }} title="Натисни и при нова поръчка ще получиш известие"
                                                onClick={() => { this.subscribeToPushNotifications(); alert('Успешно е добавено устройството за PUSH нотификации от текущия обект. За да се Отпишете, използвайте камбанката долу вдясно.') }}>
                                                Включи PUSH нотификации за поръчки от текущия обект /доставка, взимане от място/
                                            </span>
                                        </div>

                                    </div>
                                </div>
                            </div>





                            {/* <!-- /.col--> */}
                            <div className="col-lg-12">
                                <div className="card">

                                    <MessageContainer />

                                    <div className="card-body">
                                        <table className="table table-responsive-sm table-striped">
                                            <thead>
                                                <tr>
                                                    {/* <th>{this.props.translations.common.id}</th> */}
                                                    <th>{this.props.translations.common.name}</th>
                                                    <th>{this.props.translations.table_regions.tables}</th>
                                                    <th>{this.props.translations.common.notifications}</th>
                                                    <th>{this.props.translations.common.active}</th>
                                                    <th>{this.props.translations.common.action}</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                {Object.values(tableRegionsList).map((tableRegion, index) => {
                                                    if (index >= start_offset && start_count < ITEMS_PER_PAGE) {
                                                        start_count++;

                                                        return <ListEachTableRegion
                                                            key={tableRegion.id}
                                                            tableRegion={tableRegion}
                                                            deleteTableRegion={this.deleteTableRegion}
                                                            translations={this.props.translations}
                                                        // activeDefaultLanguage={this.activeDefaultLanguage}
                                                        />
                                                    }
                                                })}

                                            </tbody>
                                        </table>

                                        <Pagination
                                            className="pagination"
                                            itemClass="page-item"
                                            activePage={current_page}
                                            activeClass="active"
                                            linkClass="page-link"
                                            prevPageText="<"
                                            nextPageText=">"
                                            firstPageText="<<"
                                            lastPageText=">>"
                                            itemsCountPerPage={ITEMS_PER_PAGE}
                                            totalItemsCount={items_count}
                                            pageRangeDisplayed={ITEMS_PAGE_RANGE_DISPLAYED}
                                            onChange={this.handlePageChange}
                                        />

                                        {/* <ul className="pagination">
                                            <li className="page-item">
                                                <a className="page-link" href="#">Prev</a>
                                            </li>
                                            <li className="page-item active">
                                                <a className="page-link" href="#">1</a>
                                            </li>
                                            <li className="page-item">
                                                <a className="page-link" href="#">2</a>
                                            </li>
                                            <li className="page-item">
                                                <a className="page-link" href="#">3</a>
                                            </li>
                                            <li className="page-item">
                                                <a className="page-link" href="#">4</a>
                                            </li>
                                            <li className="page-item">
                                                <a className="page-link" href="#">Next</a>
                                            </li>
                                        </ul> */}
                                    </div>
                                </div>
                            </div>
                            {/* <!-- /.col--> */}
                        </div>
                    </div>
                </div>
            </main>
        )
    }
}

const mapStateToProps = state => ({
    tableRegions: state.tableRegions.tableRegions || [],
    page: Number(state.router.location.query.page) || 1,
    translations: state.lang
});

const mapDispatchToProps = (dispatch, props) => {
    return {
        dispatch,
        ...bindActionCreators({ listTableRegions, addEditTableRegion }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ListTableRegionsPage);
