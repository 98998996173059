import React, { Component } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { listTableGroups, addEditTableGroup } from './../actions/tableGroups.actions';
import ListEachTableGroup from './ListEachTableGroup';
import Pagination from "react-js-pagination";
import { NavLink } from 'react-router-dom';
import { push } from 'react-router-redux';
import { ADMIN_URL, ITEMS_PER_PAGE, ITEMS_PAGE_RANGE_DISPLAYED } from '../../../constants.js'
import MessageContainer from './../../messages/MessagesContainer';
import Utils from './../../../utils/Utils';

class ListTableGroupsPage extends React.Component {
    constructor(props) {
        super(props);
        // this.activeDefaultLanguage = 'bg'; // TODO add it in db
    }

    componentWillMount() {
        this.props.listTableGroups();
    }

    deleteTableGroup = (e, id) => {
        e.preventDefault();
        // this.props.removeTableGroup(id);
    }

    handlePageChange = (page) => {
        this.props.dispatch(push('/admin/tableGroups?page=' + page))
    }

    onChangeCheckboxIsForReservation = (e, id) => {
        const post = {
            id: id,
            is_for_reservation: e.target.checked,
        }
        this.props.editTableGroup(id, post);
    }

    render() {
        let tableGroupsList = this.props.tableGroups;

        const items_count = Object.keys(tableGroupsList).length
        const current_page = this.props.page;
        const start_offset = (current_page - 1) * ITEMS_PER_PAGE;
        let start_count = 0;

        // console.log('exportDataSet', exportDataSet)
        return (
            <main className="main">

                {/* <!-- Breadcrumb--> */}
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><NavLink to={ADMIN_URL}>{this.props.translations.common.dashboard}</NavLink></li>
                    <li className="breadcrumb-item active">{this.props.translations.table_groups.title}</li>
                    <li className="breadcrumb-menu d-md-down-none">
                        <div className="btn-group" role="group" aria-label="Button group">
                            <NavLink to={ADMIN_URL + `tableGroups/add`} className="btn" >
                                <i className="fas fa-plus"></i> &nbsp; {this.props.translations.table_groups.add}
                            </NavLink>
                            <NavLink to={ADMIN_URL + `tableGroups/add-bulk`} className="btn" >
                                <i className="fas fa-plus"></i> &nbsp; {this.props.translations.common.add_bulk}
                            </NavLink>
                        </div>
                    </li>
                </ol>


                <div className="container-fluid">
                    <div className="animated fadeIn">
                        <div className="row">

                            {/* <!-- /.col--> */}
                            <div className="col-lg-12">
                                <div className="card">

                                    <MessageContainer />

                                    <div className="card-body">
                                        <table className="table table-responsive-sm table-striped">
                                            <thead>
                                                <tr>
                                                    <th>{this.props.translations.common.id}</th>
                                                    <th>{this.props.translations.table_groups.min_people_count}</th>
                                                    <th>{this.props.translations.table_groups.max_people_count}</th>
                                                    <th>{this.props.translations.table_groups.tables}</th>
                                                    <th>{this.props.translations.common.active}</th>
                                                    <th>{this.props.translations.common.action}</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                {Object.values(tableGroupsList).map((tableGroup, index) => {
                                                    if (index >= start_offset && start_count < ITEMS_PER_PAGE) {
                                                        start_count++;

                                                        return <ListEachTableGroup
                                                            key={tableGroup.id}
                                                            tableGroup={tableGroup}
                                                            deleteTableGroup={this.deleteTableGroup}
                                                            translations={this.props.translations}
                                                            onChangeCheckboxIsForReservation={this.onChangeCheckboxIsForReservation}
                                                        // activeDefaultLanguage={this.activeDefaultLanguage}
                                                        />
                                                    }
                                                })}

                                            </tbody>
                                        </table>

                                        <Pagination
                                            className="pagination"
                                            itemClass="page-item"
                                            activePage={current_page}
                                            activeClass="active"
                                            linkClass="page-link"
                                            prevPageText="<"
                                            nextPageText=">"
                                            firstPageText="<<"
                                            lastPageText=">>"
                                            itemsCountPerPage={ITEMS_PER_PAGE}
                                            totalItemsCount={items_count}
                                            pageRangeDisplayed={ITEMS_PAGE_RANGE_DISPLAYED}
                                            onChange={this.handlePageChange}
                                        />

                                        {/* <ul className="pagination">
                                            <li className="page-item">
                                                <a className="page-link" href="#">Prev</a>
                                            </li>
                                            <li className="page-item active">
                                                <a className="page-link" href="#">1</a>
                                            </li>
                                            <li className="page-item">
                                                <a className="page-link" href="#">2</a>
                                            </li>
                                            <li className="page-item">
                                                <a className="page-link" href="#">3</a>
                                            </li>
                                            <li className="page-item">
                                                <a className="page-link" href="#">4</a>
                                            </li>
                                            <li className="page-item">
                                                <a className="page-link" href="#">Next</a>
                                            </li>
                                        </ul> */}
                                    </div>
                                </div>
                            </div>
                            {/* <!-- /.col--> */}
                        </div>
                    </div>
                </div>
            </main>
        )
    }
}

const mapStateToProps = state => ({
    tableGroups: state.tableGroups.tableGroups || [],
    page: Number(state.router.location.query.page) || 1,
    translations: state.lang
});

const mapDispatchToProps = (dispatch, props) => {
    return {
        dispatch,
        ...bindActionCreators({ listTableGroups, addEditTableGroup }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ListTableGroupsPage);
