import React, { Component } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { NavLink } from 'react-router-dom';
import { ADMIN_URL, SETTINGS_IMG_URL } from './../../../constants';
import { editSetting, editSettingsImage } from './../actions/settings.actions';
import { listSettingsCategories } from './../actions/settings.actions';
import MessagesContainer from './../../messages/MessagesContainer'
import { addErrorMessages, clearMessages } from './../../messages/messages.actions';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Utils from '../../../utils/Utils';
import CKEditorComponent from "react-ckeditor-component";

import Auth from '../../../utils/Auth';
const emptySymbol = '';

class EditSettingsPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            id: this.props.setting.id,
            value: this.props.setting.value,
        }
    }

    componentWillReceiveProps(nextProps) {
        this.props.listSettingsCategories()

        if (nextProps.setting.id) {
            this.setState({
                id: nextProps.match.params.id,
                value: nextProps.setting.value,
                hoursValue: (nextProps.setting.is_time == 1 && nextProps.setting.value.indexOf(':') !== -1) ? parseInt(nextProps.setting.value.split(':')[0]) : 0,
                minutesValue: (nextProps.setting.is_time == 1 && nextProps.setting.value.indexOf(':') !== -1) ? parseInt(nextProps.setting.value.split(':')[1]) : 0,
            });

        }
    }

    onChange = (e) => {
        console.log(e.target.name, e.target.value)
        this.setState({ [e.target.name]: e.target.value });
    }

    onChangeJson = (e) => {

    }

    onChangeSetNameAndValue = (name, value) => {
        this.setState({ [name]: value });
    }

    onChangeWorkTime = (day, value, startOrEndTime) => {
        let currentTime = this.props.setting.value;

        let updateValueFirst = startOrEndTime == 1 ? value : currentTime[day].split('-')[0]
        let updateValueSecond = startOrEndTime == 2 ? value : currentTime[day].split('-')[1]
        currentTime[day] = updateValueFirst + '-' + updateValueSecond;

        this.setState({ value: currentTime })
    }


    setWorkTimeForWholeWeek = (value) => {
        let currentTime = this.props.setting.value;

        for (let i = 1; i <= 7; i++) {
            currentTime[i] = value;
        }

        this.setState({ value: currentTime })
    }

    removeWorkTimeForCurrentDay = (d) => {
        let currentTime = this.props.setting.value;
        currentTime[d] = '-';
        this.setState({ value: currentTime })
    }

    onCKEditorChange = (e) => {
        console.log('e', e)
        if (e.editor.getData()) {
            console.log('e.editor.getData()', e.editor.getData())
        }
        this.setState({
            value: e.editor.getData()
        });
    }

    validate = () => {
        const errors = [];

        if (this.state.value.length <= 0) {
            errors.push("Невалидна стойност");
        }

        return errors;
    }

    onSubmit = (e) => {
        e.preventDefault();

        if (this.props.setting.is_image) {
            const files = Array.from(document.querySelector('#file').files)

            if (files.length == 0) {
                this.props.addErrorMessages(["You should choose an image"]);
                return;
            }

            let formData = new FormData()

            files.forEach((file, i) => {
                formData.append("file", file);
                formData.append("name", this.props.setting.name);
            })

            this.props.editSettingsImage(formData, this.props.history);
        } else {
            // const errors = this.validate();
            // if (errors.length > 0) {
            //     this.props.clearMessages()
            //     this.props.addErrorMessages(errors);
            //     return;
            // }

            let post = {
                id: this.state.id,
                name: this.props.setting.name,
                value: this.state.value,
            }

            // Json strigify json arr...
            if (this.props.setting.name == 'working_hours') {
                post['value'] = JSON.stringify(post['value'])
            }

            // let formData = new FormData();
            // for (var key in post) {
            //     formData.append(key, post[key]);
            // }

            this.props.editSetting(post);
        }
    }

    // onSubmit(e) {
    // 	e.preventDefault();

    //     // Image append
    //     const files = Array.from(document.querySelector('#logo').files)
    //     let formData = new FormData()

    //     files.forEach((file, i) => {
    //         formData.append("file", file);
    //         formData.append("name", "logo");
    //     })

    // 	this.props.editSettingsImage(formData, this.props.history);
    // }

    render() {

        let editSettings = <textarea className={`form-control`} id="name" type="text" rows="5"
            placeholder="Value" name="value" onChange={this.onChange}
            value={this.props.setting.json == 1 ? JSON.stringify(this.state.value) : this.state.value}
        ></textarea>

        // Settings type - CKEDITOR
        if (this.props.setting.ckeditor == 1) {
            console.log('this.state.value', this.state.value)
            // editSettings = <CKEditor
            // name="value"
            // initData={this.state.value}
            // onChange={this.onCKEditorChange}
            // />

            
            editSettings =     <CKEditorComponent
            content={this.state.value} 
            events={{
                // "blur": this.onBlur,
                // "afterPaste": this.afterPaste,
                "change": this.onCKEditorChange
            }}
            config={{
                extraAllowedContent: 'div(*)',
                allowedContent: true
            }}
        />


        }


        // Settings type - IMAGE
        if (this.props.setting.is_image == 1) {
            editSettings = <div className="card-body">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="form-group">
                            <label htmlFor="name">{this.props.translations.common.image}</label>
                            <br />
                            <input id="file" type="file" name="file" onChange={this.onChange} />
                            {this.props && this.props.value ? <img src={Utils.getSettingsPath(this.props.value)} className="editPagesImage" /> : ''}
                        </div>
                    </div>
                </div>
            </div>
        }

        // Settings type - JSON
        // if (this.props.setting.json == 1) {
        //     // let sett = JSON.parse(this.props.setting.value)
        //     console.log("SETT ", this.props.setting.value)
        //     editSettings = Object.keys(this.props.setting.value).map(key => {
        //             return (<div>
        //                     {key} 
        //                     <br/>
        //                     <textarea name="value" onChange={this.onChange}>
        //                         {JSON.stringify(this.props.setting.value[key])}
        //                     </textarea>
        //                 </div>)
        //         })
        //         console.log("SEEE ", editSettings)
        // }

        // Settings type - Color Picker
        if (this.props.setting.colorpicker == 1) {
            let colors = []
            colors[this.props.setting.name] = this.props.setting.style_class

            let colorPickerValue = this.state.value.match(/#[0-9a-f]{3,6}/i)

            if (colorPickerValue && colorPickerValue[0]) {
                colorPickerValue = colorPickerValue[0];
            } else {
                colorPickerValue = this.state.value
            }

            editSettings = <div className="card-body">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="form-group">
                            <label htmlFor="name">{this.props.translations.common.color}</label>
                            <br />
                            <br />
                            <input id="color" type="color" name="value" onChange={(e) => this.onChangeSetNameAndValue(e.target.name, colors[this.props.setting.name].replace(/{{color}}/g, e.target.value))} value={colorPickerValue} />
                            <br />
                            <br />
                            <i className="nav-icon icon-close" onClick={() => this.onChangeSetNameAndValue('value', '')} style={{
                                color: '#db0000',
                                'font-size': 20,
                                marginLeft: 10,
                                cursor: 'pointer'
                            }}></i>
                            <br />
                            <br />
                            {(Auth.hasAccess('SALES_ADMIN')) ?
                                <textarea className={`form-control`} id="name" type="text" rows="3" cols="5"
                                    placeholder="Value" name="value" onChange={this.onChange}
                                    value={this.state.value}
                                ></textarea>
                                :
                                null
                            }

                            {/* <input id="file" type="file" name="file" onChange={this.onChange} /> */}
                            {/* {this.props && this.props.value ? <img src={Utils.getSettingsPath(this.props.value)} className="editPagesImage" /> : ''} */}
                        </div>
                    </div>
                </div>
            </div>
        }



        // Custom settings
        if (this.props.setting.name == 'default_lang') {
            editSettings = <select name="value" className="form-control" onChange={this.onChange} defaultValue={this.props.setting.value}>
                <option value="en">{this.props.translations.settings.default_lang.en}</option>
                <option value="bg">{this.props.translations.settings.default_lang.bg}</option>
                <option value="ro">{this.props.translations.settings.default_lang.ro}</option>
                <option value="es">{this.props.translations.settings.default_lang.es}</option>
            </select>
        }

        if (this.props.setting.name == 'first_init_lang') {
            editSettings = <select name="value" className="form-control" onChange={this.onChange} defaultValue={this.props.setting.value}>
                <option value="">{this.props.translations.settings.first_init_lang.without_initial_translate}</option>
                <option value="en">{this.props.translations.settings.first_init_lang.en}</option>
                <option value="bg">{this.props.translations.settings.first_init_lang.bg}</option>
                <option value="ro">{this.props.translations.settings.first_init_lang.ro}</option>
            </select>
        }

        if (this.props.setting.name == 'translations_type') {
            editSettings = <select name="value" className="form-control" onChange={this.onChange} defaultValue={this.props.setting.value}>
                <option value="1">{this.props.translations.settings.translations_type[1]}</option>
                <option value="2">{this.props.translations.settings.translations_type[2]}</option>
            </select>
        }


        if (this.props.setting.name == 'view_product_page') {
            editSettings = <select name="value" className="form-control" onChange={this.onChange} defaultValue={this.props.setting.value}>
                <option value="0">{this.props.translations.settings.view_product_page[0]}</option>
                <option value="1">{this.props.translations.settings.view_product_page[1]}</option>
                <option value="2">{this.props.translations.settings.view_product_page[2]}</option>
            </select>
        }

        if (this.props.setting.name == 'order_button_on_product_variant') {
            editSettings = <select name="value" className="form-control" onChange={this.onChange} defaultValue={this.props.setting.value}>
                <option value="0">{this.props.translations.settings.order_button_on_product_variant[0]}</option>
                <option value="1">{this.props.translations.settings.order_button_on_product_variant[1]}</option>
                <option value="2">{this.props.translations.settings.order_button_on_product_variant[2]}</option>
            </select>
        }

        if (this.props.setting.name == 'show_liquid_on_non_liquid_view_product_page') {
            editSettings = <select name="value" className="form-control" onChange={this.onChange} defaultValue={this.props.setting.value}>
                <option value="0">{this.props.translations.settings.show_liquid_on_non_liquid_view_product_page[0]}</option>
                <option value="1">{this.props.translations.settings.show_liquid_on_non_liquid_view_product_page[1]}</option>
            </select>
        }

        if (this.props.setting.name == 'subcategories_design') {
            editSettings = <select name="value" className="form-control" onChange={this.onChange} defaultValue={this.props.setting.value}>
                <option value="1">{this.props.translations.settings.subcategories_design[1]}</option>
                <option value="2">{this.props.translations.settings.subcategories_design[2]}</option>
                <option value="3">{this.props.translations.settings.subcategories_design[3]}</option>
            </select>
        }

        if (this.props.setting.name == 'split_header_category_subcategory_on_two_rows') {
            editSettings = <select name="value" className="form-control" onChange={this.onChange} defaultValue={this.props.setting.value}>
                <option value="0">{this.props.translations.settings.split_header_category_subcategory_on_two_rows[0]}</option>
                <option value="1">{this.props.translations.settings.split_header_category_subcategory_on_two_rows[1]}</option>
            </select>
        }

        if (this.props.setting.name == 'required_delivery_pickup_times') {
            editSettings = <select name="value" className="form-control" onChange={this.onChange} defaultValue={this.props.setting.value}>
                <option value="0">{this.props.translations.settings.required_delivery_pickup_times[0]}</option>
                <option value="1">{this.props.translations.settings.required_delivery_pickup_times[1]}</option>
            </select>
        }

        if (this.props.setting.name == 'split_header_category_subcategory_on_two_rows') {
            editSettings = <select name="value" className="form-control" onChange={this.onChange} defaultValue={this.props.setting.value}>
                <option value="0">{this.props.translations.settings.split_header_category_subcategory_on_two_rows[0]}</option>
                <option value="1">{this.props.translations.settings.split_header_category_subcategory_on_two_rows[1]}</option>
            </select>
        }
        // console.log('this.props.setting.value', this.props.setting.value)
        if (this.props.setting.name == 'product_theme') {
            editSettings = <select name="value" className="form-control" onChange={this.onChange} defaultValue={'{"designTheme":' + this.props.setting.value.designTheme + ',"designSubTheme":' + this.props.setting.value.designSubTheme + '}'}>
                <option value='{"designTheme":1,"designSubTheme":1}'>{this.props.translations.settings.product_theme[0]}</option>
                <option value='{"designTheme":1,"designSubTheme":2}'>{this.props.translations.settings.product_theme[1]}</option>
                <option value='{"designTheme":2,"designSubTheme":1}'>{this.props.translations.settings.product_theme[2]}</option>
                <option value='{"designTheme":2,"designSubTheme":2}'>{this.props.translations.settings.product_theme[3]}</option>
                <option value='{"designTheme":3,"designSubTheme":1}'>{this.props.translations.settings.product_theme[4]}</option>
                <option value='{"designTheme":3,"designSubTheme":2}'>{this.props.translations.settings.product_theme[5]}</option>
                <option value='{"designTheme":3,"designSubTheme":3}'>{this.props.translations.settings.product_theme[6]}</option>
            </select>
        }

        if (this.props.setting.name == 'admin_notify_on_new_order') {
            editSettings = <select name="value" className="form-control" onChange={this.onChange} defaultValue={this.props.setting.value}>
                <option value="1">{this.props.translations.settings.admin_notify_on_new_order[1]}</option>
                <option value="2">{this.props.translations.settings.admin_notify_on_new_order[2]}</option>
            </select>
        }


        // if (this.props.setting.name == 'order_type') {
        //     editSettings = <select name="value" className="form-control" onChange={this.onChange} defaultValue={this.props.setting.value}>
        //         <option value="1">{this.props.translations.common.restaurant_order}</option>
        //         <option value="2">{this.props.translations.common.delivery}</option>
        //         <option value="3">{this.props.translations.common.preorder}</option>
        //         <option value="4">{this.props.translations.common.delivery_and_preorder}</option>
        //         <option value="5">{this.props.translations.common.restaurant_and_delivery}</option>
        //         <option value="6">{this.props.translations.common.restaurant_and_preorder}</option>
        //     </select>
        // }


        // Header / Footer - Options
        if (
            this.props.setting.name == 'change_language_position'
            || this.props.setting.name == 'user_needs_position'
        ) {
            editSettings = <select name="value" className="form-control" onChange={this.onChange} defaultValue={this.props.setting.value}>
                <option value="">-</option>
                <option value="header">{this.props.translations.settings.change_language_position.header}</option>
                <option value="footer">{this.props.translations.settings.change_language_position.footer}</option>
            </select>
        }

        // Yes / No - Options
        if (
            this.props.setting.name == 'promocode_visible'
            || this.props.setting.name == 'order_email_visible'
            || this.props.setting.name == 'disable_overtime_orders'
            || this.props.setting.name == 'hide_products_for_ordering_with_token'
            || this.props.setting.name == 'ask_for_bill_hidden'
            || this.props.setting.name == 'close_order_modal_after_order'
            || this.props.setting.name == 'show_delivery_price_not_included_text'
            || this.props.setting.name == 'visible_about_page_delivery_prices_text_in_cart'
            || this.props.setting.name == 'show_cutlery_option_on_making_delivery_order'
            || this.props.setting.name == 'show_cutlery_option_on_making_preorder_order'
            || this.props.setting.name == 'show_cutlery_option_on_making_room_order'
            || this.props.setting.name == 'preorder_percent_discount'
            || this.props.setting.name == 'clickable_product_variants'
            || this.props.setting.name == 'variant_modal_with_comment'
            || this.props.setting.name == 'hide_product_images_without_img'
            || this.props.setting.name == 'hide_order_delivery_pickup_date'
            || this.props.setting.name == 'hide_order_room_date'
            || this.props.setting.name == 'show_variant_additional_price'
            || this.props.setting.name == 'about_restaurant_page_title_in_left'
        ) {
            editSettings = <select name="value" className="form-control" onChange={this.onChange} defaultValue={this.props.setting.value.length > 0 ? this.props.setting.value : 0}>
                <option value="1">{this.props.translations.common.yes}</option>
                <option value="0">{this.props.translations.common.no}</option>
            </select >
        }

        // Header / Footer - Options
        if (this.props.setting.name == 'SMS_CLIENT_API_KEY') {
            editSettings = <select name="value" className="form-control" onChange={this.onChange} defaultValue={this.props.setting.value}>
                <option value="q8ppKOM_Sy-gydxpYqKXvw==">{this.props.translations.settings.SMS_CLIENT_API_KEY[0]}</option>
                <option value="OXCQWa2cRrGHi0JKi_-SWA==">{this.props.translations.settings.SMS_CLIENT_API_KEY[1]}</option>
                <option value="BhOHhBm2RkG7FPmfeaHcrA==">{this.props.translations.settings.SMS_CLIENT_API_KEY[2]}</option>
            </select>
        }


        if (this.props.setting.name == 'working_hours') {
            editSettings = <React.Fragment>
                {[1, 2, 3, 4, 5, 6, 7].map(d =>
                    <div style={{ marginBottom: 10 }}>
                        <span style={{ width: 100, display: 'inline-block' }}>{this.props.translations.common['day_' + d]}</span>
                        <input className="form-control" id="monday_work_time" type="text" name="monday_work_time" onChange={(e) => this.onChangeWorkTime(d, e.target.value, 1)} value={this.state.value[d].split('-')[0]} style={{ width: 70, display: 'inline-block' }} />
                        &nbsp;-&nbsp;
                        <input className="form-control" id="monday_work_time" type="text" name="monday_work_time" onChange={(e) => this.onChangeWorkTime(d, e.target.value, 2)} value={this.state.value[d].split('-')[1]} style={{ width: 70, display: 'inline-block' }} />
                        &nbsp;&nbsp;
                        <button type="button" class="btn btn-outline-danger" title={this.props.translations.common.remove_current_worktime} onClick={() => this.removeWorkTimeForCurrentDay(d)}>
                            <i class="icon-close icons"></i>
                        </button>
                        &nbsp;&nbsp;
                        <button type="button" class="btn btn-outline-warning" title={this.props.translations.common.save_current_worktime_for_whole_week} onClick={() => this.setWorkTimeForWholeWeek(this.props.setting.value[d])}>
                            <i class="icon-clock icons"></i>
                        </button>
                        &nbsp;&nbsp;
                    </div>
                )}

            </React.Fragment>
        }



        if (this.props.setting.name == 'sound_notification_new_order') {
            editSettings = <select name="value" className="form-control" onChange={this.onChange} defaultValue={this.props.setting.value}>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
                <option value="9">9</option>
                <option value="10">10</option>
                <option value="11">11</option>
                <option value="12">12</option>
                <option value="13">13</option>
                <option value="14">14</option>
                <option value="15">15</option>
                <option value="16">16</option>
                <option value="17">17</option>
                <option value="18">18</option>
                <option value="19">19</option>
                <option value="20">20</option>
            </select>
        }



        return (
            <React.Fragment>
                <main className="main">

                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><NavLink to={ADMIN_URL}>{this.props.translations.common.dashboard}</NavLink></li>
                        <li className="breadcrumb-item"><NavLink to={ADMIN_URL + 'settings'}>{this.props.translations.sidemenu.settings}</NavLink></li>
                        <li className="breadcrumb-item"><NavLink to={ADMIN_URL + 'settings/category/' + this.props.settingsCategoryId}>{this.props.translations.settings_categories && this.props.translations.settings_categories[(this.props.settingsCategoryName)] ? this.props.translations.settings_categories[(this.props.settingsCategoryName)] : this.props.settingsCategoryName}</NavLink></li>
                        {/* <li className="breadcrumb-item active">#{this.props.setting.id}</li> */}
                        <li className="breadcrumb-item active">{this.props.translations.settings && this.props.translations.settings[(this.props.setting.name)] && this.props.translations.settings[(this.props.setting.name)].default ? Utils.truncate(this.props.translations.settings[(this.props.setting.name)].default, 150, 100) : (this.props.setting.comment ? Utils.truncate(this.props.setting.comment, 150, 100) : emptySymbol)}</li>
                    </ol>

                    <div className="container-fluid">
                        <div className="animated fadeIn">
                            <div className="row">

                                <div className="col-lg-12">
                                    <div className="card">
                                        <div className="card-body">

                                            <MessagesContainer />

                                            <form onSubmit={this.onSubmit} >
                                                <div className="row">
                                                    <div className="col-md-8">
                                                        <div className="form-group">
                                                            <label htmlFor="name">{this.props.translations.settings && this.props.translations.settings[(this.props.setting.name)] && this.props.translations.settings[(this.props.setting.name)].default ? this.props.translations.settings[(this.props.setting.name)].default : (this.props.setting.comment ? this.props.setting.comment : emptySymbol)}</label>

                                                            {editSettings}

                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div dangerouslySetInnerHTML={{
                                                            __html: (this.props.translations.settings && this.props.translations.settings[(this.props.setting.name)] && this.props.translations.settings[(this.props.setting.name)].visible_description ? (this.props.translations.settings[(this.props.setting.name)].visible_description).split('menu.tabl.bg').join(window.location.host) : '')
                                                        }}>
                                                        </div>
                                                        <div dangerouslySetInnerHTML={{
                                                            __html: this.props.setting && this.props.setting.visible_description ? this.props.setting.visible_description.split('menu.tabl.bg').join(window.location.host) : ''
                                                        }}>
                                                        </div>
                                                    </div>
                                                </div>

                                                <br />
                                                <br />

                                                <NavLink to={ADMIN_URL + 'settings/category/' + this.props.settingsCategoryId}>
                                                    <button className="btn btn-secondary" type="button">
                                                        {this.props.translations.common.back}
                                                    </button>
                                                </NavLink>
                                                <button className="fright btn btn-primary" type="submit">{this.props.translations.common.save}</button>

                                            </form>

                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </main>

            </React.Fragment >

        )
    }
}

const mapStateToProps = (state, ownProps) => {
    let id = ownProps.match.params.id;
    const catId = ownProps.match.params.catId;
    let setting;
    let settingsCategoryName = Object.values(state.settings.settings_categories).filter(s => s.id == catId);

    if (state.settings.settings) {
        setting = Object.values(state.settings.settings).find(set => set.id == id);
    }
    // 
    // console.log('settingsCategoryName', settingsCategoryName)

    if (!setting) setting = {
        id: '',
        value: ''
    };
    return {
        setting: setting,
        settings: state.settings.settings || [],
        translations: state.lang,
        settingsCategoryName: settingsCategoryName && settingsCategoryName[0] && settingsCategoryName[0].name ? settingsCategoryName[0].name : '',
        settingsCategoryId: catId,

        // errors: state.reservationSettings.errors || [],
        // success: state.reservationSettings.success || [],
    }
};

const mapDispatchToProps = (dispatch, props) => {
    return {
        dispatch,
        ...bindActionCreators({ editSetting, clearMessages, addErrorMessages, editSettingsImage, listSettingsCategories }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditSettingsPage);
