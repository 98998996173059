import React, { Component } from 'react'
import { bulkUpdateProductsField } from './../../../actions/productsActions';

import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { bindActionCreators } from 'redux'
const UPDATEABLE_FIELDS = [
    { name: 'additional_price', type: 'input', info: '' },
    { name: 'active', type: 'checkbox', info: '' },
    { name: 'priority', type: 'input', info: '' },
    { name: 'isLiquid', type: 'checkbox', info: '' },
    { name: 'discount_percent', type: 'input', info: '' },
    { name: 'productPrintGroupId', type: 'input', info: '' },
    { name: 'deleted', type: 'checkbox', info: 'Warning! Products will be deleted' },
]

class BulkFieldUpdateModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            updateField: '',
            updateFieldValue: '',

            updateFieldType: 'input',
            error: ''
        };
    }


    onChangeFieldType = (e) => {
        let type = UPDATEABLE_FIELDS.find(f => f.name == e.target.value).type;
        this.setState({ updateFieldType: type, updateField: e.target.value })
    }

    onChangeFieldValue = (e) => {
        if (this.state.updateFieldType == "checkbox") {
            this.setState({ updateFieldValue: e.target.checked });
        } else {
            this.setState({ updateFieldValue: e.target.value })
        }
    }

    bulkUpdateProducts = () => {
        if (this.state.updateField.length == 0) {
            this.setState({ error: "Please choose a filed to update" })
            return;
        }

        if (this.state.updateFieldValue.length == 0 && this.state.updateFieldType == 'input') {
            this.setState({ error: "Invalid value" })
            return;
        }

        let data = {
            fieldName: this.state.updateField,
            fieldValue: this.state.updateFieldValue,
            productIds: this.props.productIdsToUpdate
        }
        this.props.bulkUpdateProductsField(data)
        document.getElementById("dismisModalBtn").click();
    }

    render() {

        return (
            <React.Fragment>

                <div>
                    <button className="btn btn-pill btn-link" id="toggleModalBtn" type="button" data-toggle="modal" className="btn btn-primary" style={{ minWidth: '100%' }}
                        data-target={"#bulkFieldUpdateModal"} >
                        {this.props.translations.products.bulk_update_products_field}
                    </button>
                </div>
                <div className="modal fade" id={"bulkFieldUpdateModal"} tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-danger" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">{this.props.translations.products.bulk_update_products_field}</h4>
                                <button className="close" type="button" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <h6 className="modal-title" style={{ padding: 10 }}>{this.props.translations.products.bulk_update_products_field_info}</h6>
                            <div className="modal-body">
                                {this.state.error ?
                                    <div className="alert alert-danger fade show" role="alert">
                                        {this.state.error}
                                    </div>
                                    :
                                    null
                                }

                                <select name="field" onChange={this.onChangeFieldType} className="form-control">
                                    <option value="">-</option>
                                    {UPDATEABLE_FIELDS.map(f => {
                                        return <option value={f.name} style={f.info.length > 0 ? { color: 'red' } : {}}>
                                            {this.props.translations.common[f.name]}  {f.info}
                                        </option>
                                    })}
                                </select>
                                <br />
                                {this.state.updateFieldType == "checkbox" ?
                                    <label className="switch switch-label switch-pill switch-outline-primary-alt">
                                        <input className="switch-input" type="checkbox" name="active"
                                            value={this.state.updateFieldValue}
                                            onChange={this.onChangeFieldValue}
                                        />
                                        <span className="switch-slider" data-checked={this.props.translations.common.yes} data-unchecked={this.props.translations.common.no}></span>
                                    </label>
                                    :
                                    <input type={this.state.updateFieldType} name="updateField" className="form-control"
                                        value={this.state.updateFieldValue}
                                        onChange={this.onChangeFieldValue} />
                                }

                            </div>
                            {/* <div className="modal-footer"> */}
                            <div className="modal-body">
                                <button className="btn btn-secondary" id="dismisModalBtn" type="button" data-dismiss="modal">{this.props.translations.common.close}</button>
                                <button className="btn btn-success" type="button" onClick={this.bulkUpdateProducts} style={{ float: 'right' }}>{this.props.translations.common.save}</button>
                                {/* <button className="btn btn-danger fright" type="button" data-dismiss="modal" onClick={(e) => { this.props.deleteVariant(e, this.props.variantId) }}>{this.props.translations.common.delete}</button> */}
                            </div>
                        </div>
                    </div>
                </div>

            </React.Fragment>

        )
    }

}


const mapStateToProps = state => {

    return (
        {

        }
    )
}


const mapDispatchToProps = (dispatch, props) => {
    return {
        dispatch,
        ...bindActionCreators({ bulkUpdateProductsField }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BulkFieldUpdateModal);