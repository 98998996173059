import { LIST_REGIONS } from '../actions/types';

const initialState = {
    regions: [],
}

export default function(state = initialState, action) {
    switch(action.type) {
        case LIST_REGIONS: {
            return {
                ...state,
                regions: action.payload,
            }
        }
       
        default:
            return state;
    }
}