import { LIST_GENERAL_PROMOTIONS } from '../actions/types';

const initialState = {
    generalPromotions: [],
}

export default function(state = initialState, action) {
    switch(action.type) {
        case LIST_GENERAL_PROMOTIONS: {
            return {
                ...state,
                generalPromotions: action.payload,
            }
        }
        default:
            return state;
    }
}