import React, { Component } from 'react'
import { connect } from 'react-redux';
import { listPromocodes } from './actions/promocode.action';
import { removeTable, editTable } from '../../actions/tablesActions';
import ListEachPromocode from './ListEachPromocode';
import Pagination from "react-js-pagination";
import { NavLink } from 'react-router-dom';
import { push } from 'react-router-redux';
import { ADMIN_URL, ITEMS_PER_PAGE, ITEMS_PAGE_RANGE_DISPLAYED } from '../../constants.js'
import { bindActionCreators } from 'redux'
import MessageContainer from '../messages/MessagesContainer';
import ReactExport from "react-export-excel";
import Utils from '../../utils/Utils';
import Filter from './Filter';
import { addEditPromocode } from './actions/promocode.action';
import Auth from '../../utils/Auth';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class ListPromocodesPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            search_code: '',
            search_dicount_value: '',
            search_dicount_type: '',
            search_active: '',
            search_deleted: '',
            search_used: '',
            search_place_id: Auth.getUserPlace(),
            search_valid_from: '',
            search_valid_to: '',
        }
    }

    componentWillMount() {
        this.props.listPromocodes(this.state);
    }

    deleteTable = (e, id) => {
        e.preventDefault();
        this.props.addEditPromocode({ id: id, deleted: true });
    }

    handlePageChange = (page) => {
        this.props.dispatch(push('/admin/promocodes?page=' + page))
    }


    callFilter = () => {
        this.props.listPromocodes(this.state);
        this.props.dispatch(push('/admin/promocodes'))
    }

    clearFilter = () => {
        let setStateObject = {
            search_code: '',
            search_dicount_value: '',
            search_dicount_type: '',
            search_active: '',
            search_deleted: '',
            search_used: '',
            search_place_id: Auth.hasAccess("SUPER_ADMIN") ? '' : Auth.getUserPlace(),
            search_valid_from: '',
            search_valid_to: '',
        }
        this.setState(setStateObject);
        this.props.listPromocodes(setStateObject);
    }


    onFieldChange = (fieldName, val) => {
        if ((fieldName == 'search_valid_from' || fieldName == 'search_valid_to') && isNaN(val)) {
            return
        }

        this.setState({ [fieldName]: val });

        // if (fieldName == 'search_valid_from') {
        //     this.props.listOrders(this.state.filterPlaces, this.state.getDeletedOrders, 0, val, this.state.search_date_to)
        // }
        // if (fieldName == 'search_valid_to') {
        //     this.props.listOrders(this.state.filterPlaces, this.state.getDeletedOrders, 0, this.state.search_date_from, val)
        // }
    }

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }


    deleteFunc = (e, id) => {
        e.preventDefault();
        // this.props.removeProduct(id);
        this.props.addEditPromocode({ id: id, deleted: true }, this.props.history);
    }

    render() {
        let promocodesList = this.props.promocodes;

        const items_count = Object.keys(promocodesList).length
        const current_page = this.props.page;
        const start_offset = (current_page - 1) * ITEMS_PER_PAGE;
        let start_count = 0;

        let exportDataSet = []
        Object.values(this.props.promocodes).map(p =>
            exportDataSet.push({ code: p.code, valid_from: p.valid_from, valid_to: p.valid_to, used: p.used, active: p.active, place: p.placeId })
        )

        // console.log('exportDataSet', exportDataSet)
        return (
            <main className="main">

                {/* <!-- Breadcrumb--> */}
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><NavLink to={ADMIN_URL}>{this.props.translations.common.dashboard}</NavLink></li>
                    <li className="breadcrumb-item active">{this.props.translations.promocodes.title}</li>
                    <li className="breadcrumb-menu d-md-down-none">
                        <div className="btn-group" role="group" aria-label="Button group">
                            <NavLink to={ADMIN_URL + `promocodes/add`} className="btn" >
                                <i className="fas fa-plus"></i> &nbsp; {this.props.translations.promocodes.add}
                            </NavLink>
                            <NavLink to={ADMIN_URL + `promocodes/add-bulk`} className="btn" >
                                <i className="fas fa-plus"></i> &nbsp; {this.props.translations.common.add_bulk}
                            </NavLink>

                            {/* 
                            <!--<a className="btn" href="./">
                                <i className="icon-graph"></i>  Dashboard</a>
                            <a className="btn" href="#">
                                <i className="icon-settings"></i>  Settings</a>
                            --> 
                            */}

                            <ExcelFile element={<button type="button" className="btn btn-primary" style={{ color: 'black' }}>Export</button>}>
                                <ExcelSheet data={exportDataSet} name="Data">
                                    <ExcelColumn label="Promocode" value="code" />
                                    <ExcelColumn label="Valid from" value="valid_from" />
                                    <ExcelColumn label="Valid to" value="valid_to" />
                                    <ExcelColumn label="Used" value="used" />
                                    <ExcelColumn label="Active" value="active" />
                                    <ExcelColumn label="Place" value="place" />
                                </ExcelSheet>
                            </ExcelFile>

                        </div>
                    </li>
                </ol>


                <div className="container-fluid">
                    <div className="animated fadeIn">
                        <div className="row">

                            <Filter
                                data={this.state}
                                translations={this.props.translations}
                                onChange={this.onChange}
                                // clearFilter={this.props.clearProductFilter}
                                callFilter={this.callFilter}
                                clearFilter={this.clearFilter}
                                onFieldChange={this.onFieldChange}
                                places={this.props.places}
                            // currPlaceOrderTypes={this.props.currPlaceOrderTypes}
                            />


                            {/* <!-- /.col--> */}
                            <div className="col-lg-12">
                                <div className="card">

                                    <MessageContainer />

                                    <div className="card-body">
                                        <table className="table table-responsive-sm table-striped">
                                            <thead>
                                                <tr>
                                                    <th>{this.props.translations.common.code}</th>
                                                    <th>{this.props.translations.common.discount}</th>
                                                    <th>{this.props.translations.common.valid}</th>
                                                    <th>{this.props.translations.common.place}</th>
                                                    <th>{this.props.translations.common.used}</th>
                                                    <th>{this.props.translations.promocode.never_become_used_short}</th>
                                                    <th>{this.props.translations.common.active}</th>
                                                    <th>{this.props.translations.promocode.ignore_other_discounts}</th>
                                                    <th>{this.props.translations.common.ordertype}</th>
                                                    <th>{this.props.translations.common.action}</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                {Object.values(promocodesList).map((promocode, index) => {
                                                    if (index >= start_offset && start_count < ITEMS_PER_PAGE) {
                                                        start_count++;

                                                        return <ListEachPromocode
                                                            key={promocode.id}
                                                            promocode={promocode}
                                                            deleteFunc={this.deleteFunc}
                                                            translations={this.props.translations}
                                                            places={this.props.places}
                                                        // currPlaceOrderTypes={this.props.currPlaceOrderTypes}
                                                        // activeDefaultLanguage={this.activeDefaultLanguage}
                                                        />
                                                    }
                                                })}

                                            </tbody>
                                        </table>

                                        <Pagination
                                            className="pagination"
                                            itemClass="page-item"
                                            activePage={current_page}
                                            activeClass="active"
                                            linkClass="page-link"
                                            prevPageText="<"
                                            nextPageText=">"
                                            firstPageText="<<"
                                            lastPageText=">>"
                                            itemsCountPerPage={ITEMS_PER_PAGE}
                                            totalItemsCount={items_count}
                                            pageRangeDisplayed={ITEMS_PAGE_RANGE_DISPLAYED}
                                            onChange={this.handlePageChange}
                                        />

                                        {/* <ul className="pagination">
                                            <li className="page-item">
                                                <a className="page-link" href="#">Prev</a>
                                            </li>
                                            <li className="page-item active">
                                                <a className="page-link" href="#">1</a>
                                            </li>
                                            <li className="page-item">
                                                <a className="page-link" href="#">2</a>
                                            </li>
                                            <li className="page-item">
                                                <a className="page-link" href="#">3</a>
                                            </li>
                                            <li className="page-item">
                                                <a className="page-link" href="#">4</a>
                                            </li>
                                            <li className="page-item">
                                                <a className="page-link" href="#">Next</a>
                                            </li>
                                        </ul> */}
                                    </div>
                                </div>
                            </div>
                            {/* <!-- /.col--> */}
                        </div>
                    </div>
                </div>
            </main>
        )
    }
}

const mapStateToProps = state => {

    let orderTypes = [];
    if (state.places.places) {
        let currPlace = state.places.places.find(p => p.id == Auth.getUserPlace());
        if (currPlace) {
            orderTypes = currPlace.ordertypes;
        }
    }

    return {
        promocodes: state.promocodes.promocodes,
        page: Number(state.router.location.query.page) || 1,
        translations: state.lang,
        places: state.places.places,
        currPlaceOrderTypes: orderTypes,
    }
};

const mapDispatchToProps = (dispatch, props) => {
    return {
        dispatch,
        ...bindActionCreators({ listPromocodes, removeTable, editTable, addEditPromocode }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ListPromocodesPage);
