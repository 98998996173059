import React, { Component } from 'react';
import { connect } from 'react-redux';
import { addSubcategory } from '../../actions/subcategoriesAction';
import AddEditSubcategoryForm from './AddEditSubcategoryForm';
import { listCategories } from '../../actions/categoriesActions';
import { NavLink } from 'react-router-dom';
import { ADMIN_URL } from '../../constants';
import { addErrorMessages } from './../messages/messages.actions';

class AddSubcategoryPage extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            currLang: this.props.currLang,
            name: '',
            code: '',
            slug: '',
            categoryId: 0,
            active: 1
        }

        this.onChange = this.onChange.bind(this);
        this.onChangeCheckbox = this.onChangeCheckbox.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    componentWillMount() {
        this.props.listCategories();
    }

    onChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    onChangeCheckbox(e) {
        this.setState({ [e.target.name]: e.target.checked });
    }

    validate(post) {
        let errors = [];
        const { name, categoryId } = post;

        if (name.length === 0) {
            errors.push(this.props.translations.messages.errors.invalid_name);
        }
        if (categoryId === 0) {
            errors.push(this.props.translations.messages.errors.invalid_category);
        }
        return errors;
    }

    onSubmit(e) {
        e.preventDefault();

        let firstActiveCat = Object.values(this.props.categories).filter(c => c.active == true);
        if (firstActiveCat && firstActiveCat[0] && firstActiveCat[0].id) {
            firstActiveCat = firstActiveCat[0].id;
        }

        let categoryId = this.state.categoryId;
        if (categoryId == 0) {
            categoryId= firstActiveCat;
        }

        const post = {
            name: this.state.name,
            code: this.state.code,
            slug: this.state.slug,
            category_code: this.props.categories[this.state.categoryId] ? this.props.categories[this.state.categoryId].code : '',
            categoryId: categoryId,
            active: this.state.active,
        }

        const errors = this.validate(post);
        if (errors.length > 0) {
            this.props.addErrorMessages(errors);
            return;
        }
 
        // Image append
        const files = Array.from(document.querySelector('#multi').files)
        let formData = new FormData()

        files.forEach((file, i) => {
            formData.append("file", file)
        })
        if (files.length == 0) {
            formData = null;
        }


        this.props.addSubcategory(post, this.props.history, formData, this.props.languages[this.state.currLang].id);
    }


    componentWillReceiveProps(nextProps) {
        this.setState({
            name: '',
            code: '',
            slug: '',
            categoryId: 0,
            active: 1
        });

    }

    render() {

        return (
            <main className="main">

                {/* <!-- Breadcrumb--> */}
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><NavLink to={ADMIN_URL}>{this.props.translations.common.dashboard}</NavLink></li>
                    {/* <li className="breadcrumb-item">Меню</li> */}
                    <li className="breadcrumb-item"><NavLink to={ADMIN_URL + "subcategories"}>{this.props.translations.subcategories.title}</NavLink></li>
                    <li className="breadcrumb-item active">{this.props.translations.subcategories.add}</li>
                    {/* <!-- Breadcrumb Menu--> */}
                </ol>

                <div className="container-fluid">
                    <div className="animated fadeIn">
                        <div className="row">

                            {/* <!-- /.col--> */}
                            <div className="col-sm-12">
                                <div className="card">

                                    <AddEditSubcategoryForm
                                        onSubmit={this.onSubmit}
                                        onChange={this.onChange}
                                        onChangeCheckbox={this.onChangeCheckbox}
                                        subcategoryName={this.state.name}
                                        subcategoryCode={this.state.code}
                                        subcategoryActive={this.state.active}
                                        isAddingSubcategory="1"
                                        allCategories={this.props.categories}
                                        nameError={this.props.errors.find(error => error.path == 'name') ? this.props.errors.find(error => error.path == 'name') : ""}
                                        translations={this.props.translations}
                                        currLang={this.state.currLang}
										languages={this.props.languages}
                                    />

                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </main>
        )
    }
}

const mapStateToProps = state => ({
    errors: state.subcategories.errors,
    categories: state.categories.categories,
    translations: state.lang,
    languages: state.languages.languages || {},
	currLang: "bg"
});

export default connect(mapStateToProps, { addSubcategory, listCategories, addErrorMessages })(AddSubcategoryPage);