import axios from 'axios';
import { LIST_SETTINGS, LIST_SETTINGS_CATEGORIES } from './types';
import { API_URL, ADMIN_URL } from './../../../constants';
import { addSuccessMessage, addErrorMessages } from './../../messages/messages.actions';
import { changeLanguage } from './../../../actions/languagesActions';
import Auth from './../../../utils/Auth';

export const listSettingsCategories = () => dispatch => {

    axios
        .get(API_URL + 'settings/categories')
        .then(res => {
            dispatch({
                type: LIST_SETTINGS_CATEGORIES,
                payload: res.data
            });
        })
        .catch(error => {
            throw (error);
        });
}

export const listSettings = (place_id = 0) => dispatch => {
    if (place_id == 0) {
        place_id = Auth.getUserPlace();
    }
    axios
        .get(API_URL + 'settings_admin?place_id=' + place_id)
        .then(res => {
            if (res && res.data) {
                dispatch({
                    type: LIST_SETTINGS,
                    payload: res.data
                });
            }
        })
        .catch(error => {
            throw (error);
        });
}

export const editSetting = (post) => dispatch => {
    if (!post.place_id) {
        post.place_id = Auth.getUserPlace();
    }

    post.edit_by = Auth.getToken() ? Auth.getDecodedUser().email : '';

    axios
        .post(API_URL + 'settings_place', post)
        .then(res => {
            if (res.data && res.data.name && res.data.name == "default_lang") {
                dispatch(changeLanguage(res.data.value));
                window.localStorage.setItem("default_lang", res.data.value);
            }
            dispatch(addSuccessMessage("Successfully edited setting"))
            dispatch(listSettings());
        })
        .catch(err => {
            dispatch(addErrorMessages(err.response.data.error.errors));
        })
}

export const editSettingsImage = (formData, history) => dispatch => {
    // if(!post.place_id) {
    //     post.place_id = Auth.getUserPlace();

    // }
    formData.append("place_id", Auth.getUserPlace());

    axios
        .post(API_URL + 'settings/image', formData)
        .then(res => {
            dispatch(addSuccessMessage("Successfully edited setting"))
            dispatch(listSettings());
        })
        .catch(err => {
            dispatch(addErrorMessages(err.response.data.error.errors));
        })
}
