import React, { Component } from 'react'
import { connect } from 'react-redux';
import { addTable } from '../../actions/tablesActions';
import { listRestaurantHalls } from '../restaurantHalls/actions/restaurantHalls.actions';
import AddEditPromocodeForm from './AddEditPromocodeForm';
import { NavLink } from 'react-router-dom';
import { ADMIN_URL, API_TOKEN } from '../../constants';
import { addErrorMessages } from '../messages/messages.actions';
import { withRouter } from 'react-router-dom';
import { SCAN_URL } from '../../constants';
import Utils from '../../utils/Utils';
import Auth from '../../utils/Auth';
import { listPromocodes, addEditPromocode } from './actions/promocode.action';

// let md5 = require('md5');

class AddPromocodePage extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			id: '',
			discount_value: '',
			discount_type: '',
			valid_from: null,
			valid_to: null,
			used: '',
			active: '',
			createdAt: '',
			updatedAt: '',
			placeId: '',
			ordertypes: '',
			code: '',
			ignore_other_discounts: '',
			never_become_used: '',
			description: ''
		}

		this.onChange = this.onChange.bind(this);
		this.onChangeCheckbox = this.onChangeCheckbox.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
	}

	onChange(e) {
		this.setState({ [e.target.name]: e.target.value.replace(/[а-я]/i, "") })
	}

	onChangeCheckbox(e) {
		this.setState({ [e.target.name]: e.target.checked });
	}

	onChangeSelectAutocomplete = (fieldName, valueLabel) => {
		this.setState({ [fieldName]: valueLabel });
	}

	onFieldChange = (fieldName, value) => {
		this.setState({ [fieldName]: value });
	}

	validate() {
		let errors = [];

		if (this.state.code.length == 0) {
			errors.push(this.props.translations.messages.errors.invalid_table_num);
		}

		if (this.state.placeId.length == '') {
			errors.push(this.props.translations.messages.errors.invalid_place);
		}

		return errors;
	}

	onSubmit(e) {
		e.preventDefault();

		const errors = this.validate();
		if (errors.length > 0) {
			this.props.addErrorMessages(errors);
			return;
		}

		let ordertypes = this.state.ordertypes.map(c => c.value);

		const post = {
			discount_value: this.state.discount_value,
			discount_type: this.state.discount_type,
			valid_from: this.state.valid_from,
			valid_to: (new Date(this.state.valid_to).setHours(23, 59, 59)), //this.state.valid_to,
			used: this.state.used,
			active: this.state.active,
			createdAt: this.state.createdAt,
			updatedAt: this.state.updatedAt,
			placeId: this.state.placeId,
			ordertypes: ordertypes,
			code: this.state.code,
			ignore_other_discounts: this.state.ignore_other_discounts,
			never_become_used: this.state.never_become_used,
			description: this.state.description
		}

		this.props.addEditPromocode(post, this.props.history);
	}

	onChangeSelect = (name, valueLabel) => {
		let newState = [];
		newState[name] = valueLabel.value;
		this.setState(newState);
	}

	render() {
		return (
			<main className="main">

				{/* <!-- Breadcrumb--> */}
				<ol className="breadcrumb">
					<li className="breadcrumb-item"><NavLink to={ADMIN_URL}>{this.props.translations.common.dashboard}</NavLink></li>
					{/* <li className="breadcrumb-item">Меню</li> */}
					<li className="breadcrumb-item"><NavLink to={ADMIN_URL + "promocodes"}>{this.props.translations.promocodes.title}</NavLink></li>
					<li className="breadcrumb-item active">{this.props.translations.promocodes.add}</li>
					{/* <!-- Breadcrumb Menu--> */}
					<li className="breadcrumb-menu d-md-down-none">
						<div className="btn-group" role="group" aria-label="Button group">
							{/* 
                            <!--<a className="btn" href="./">
                                <i className="icon-graph"></i>  Dashboard</a>
                            <a className="btn" href="#">
                                <i className="icon-settings"></i>  Settings</a>
                            --> 
                            */}
						</div>
					</li>
				</ol>

				<div className="container-fluid">
					<div className="animated fadeIn">
						<div className="row">

							{/* <!-- /.col--> */}
							<div className="col-sm-12">
								<div className="card">

									<AddEditPromocodeForm
										onSubmit={this.onSubmit}
										onChange={this.onChange}
										onChangeCheckbox={this.onChangeCheckbox}
										onChangeSelect={this.onChangeSelect}
										onChangeSelectAutocomplete={this.onChangeSelectAutocomplete}
										onFieldChange={this.onFieldChange}
										places={this.props.places}
										currPlaceOrderTypes={this.props.currPlaceOrderTypes}
										promocode={this.state}
										actionText="Добави"
										isAdding="1"
										nameError={this.props.errors.find(error => error.path == 'table_num') ? this.props.errors.find(error => error.path == 'table_num') : ""}
										translations={this.props.translations}
									/>

								</div>
							</div>

						</div>
					</div>
				</div>

			</main>
		)
	}
}

const mapStateToProps = state => {


	let orderTypes = [];

	if (state.places.places) {
		let currPlace = state.places.places.find(p => p.id == Auth.getUserPlace());
		if (currPlace) {
			orderTypes = currPlace.ordertypes;
		}
	}

	return {
		currPlaceOrderTypes: orderTypes,
		errors: state.tables.errors,
		restaurantHalls: state.restaurantHalls.restaurantHalls || [],
		translations: state.lang,
		clients: state.clients.clients,
		places: state.places.places,
	}
};

// const mapDispatchToProps = (dispatch, ownProps) => {
// 	return {
// 		addTable: (data, imageData) => dispatch(addTable(ownProps.history, data, imageData)),
// 		addErrorMessages: (msgs) => dispatch(addErrorMessages(msgs))
// 	}
// }

export default withRouter(connect(mapStateToProps, { addEditPromocode, addTable, addErrorMessages, listRestaurantHalls })(AddPromocodePage));