import React, { Component } from 'react';
import { addProduct } from '../../actions/productsActions';
import { connect } from 'react-redux';
import AddEditProductForm from './AddEditProductForm';
import { NavLink } from 'react-router-dom';
import { ADMIN_URL } from '../../constants';
import { addErrorMessages } from './../messages/messages.actions';

class AddProductPage extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            currLang: this.props.currLang,
            name: '',
            code: '',
            description: '',
            catHierarchyId: '',
            categoryId: 0,
            subcategoryId: 0,
            isLiquid: 1,
            active: 1,
            is_promo: 0,
            hide_for_ordering: 0,
            discount_percent: 0,
            discount_money: 0,
            image_scale: 100,
            fast_order: 0,
            type: "default",
            relevantSubcategories: {},
            subcategorySelectDisabled: true,
            currCategoriesHierarchy: [],
            label: '',
            productPrintGroupId: 0,

        }

        // this.onChange = this.onChange.bind(this);
        // this.onChangeCheckbox = this.onChangeCheckbox.bind(this);
        // this.onSubmit = this.onSubmit.bind(this);
    }

    componentWillMount() {
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            name: this.state.name || '',
            code: this.state.code || '',
            description: this.state.description || '',
            catHierarchyId: this.state.catHierarchyId || '',
            categoryId: this.state.categoryId || 0,
            subcategoryId: this.state.subcategoryId || 0,
            isLiquid: this.state.isLiquid,
            active: this.state.active,
            is_promo: this.state.is_promo || 0,
            hide_for_ordering: this.state.hide_for_ordering || 0,
            discount_percent: this.state.discount_percent || 0,
            discount_money: this.state.discount_money || 0,
            image_scale: this.state.image_scale || 100,
            fast_order: this.state.fast_order || 0,
            type: this.state.type || "default",
            relevantSubcategories: this.state.relevantSubcategories || {},
            subcategorySelectDisabled: this.state.subcategorySelectDisabled,
            label: this.state.label || '',
            productPrintGroupId: this.state.productPrintGroupId || 0,
        });

        // if (Object.keys(nextProps.categories).length !== 0) {
        //     let firstCatId = nextProps.categories[Object.keys(nextProps.categories)[0]].id;
        //     this.setRelevantCategoryState(firstCatId, nextProps.categories[firstCatId].subcategories);
        // }
    }

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    onChangeCheckbox = (e) => {
        //TODO active or not /
        this.setState({ [e.target.name]: e.target.checked });
    }

    onCKEditorChange = (e) => {
        this.setState({
            description: e.editor.getData()
        });
    }

    onChangeSelectAutocompleteCategory = (valueLabel) => {
        this.setState({ 'currCategoriesHierarchy': valueLabel });
    }

    onChangeSelectAutocomplete = (fieldName, valueLabel) => {
        this.setState({ [fieldName]: valueLabel.value });
    }

    validate(post) {
        let errors = [];

        if (post.name.length === 0) {
            errors.push(this.props.translations.messages.errors.invalid_name);
        }

        if (post.catHierarchyIds.length === 0) {
            errors.push(this.props.translations.messages.errors.invalid_category);
        }

        return errors;
    }

    onSubmit = (e) => {
        e.preventDefault();

        let catHierarchyIds = this.state.currCategoriesHierarchy.map(c => c.value);

        const post = {
            name: this.state.name,
            code: this.state.code,
            subcategory_code: this.props.subcategories[this.state.subcategoryId] ? this.props.subcategories[this.state.subcategoryId].code : '',
            description: this.state.description,
            // subcategoryId: this.state.subcategoryId,
            // subcategoryId: (this.props.categories[this.state.categoryId] && Object.values(this.props.categories[this.state.categoryId].subcategories).length == 1) ? Object.values(this.props.categories[this.state.categoryId].subcategories)[0].id : this.state.subcategoryId,
            isLiquid: this.state.isLiquid,
            active: this.state.active,
            is_promo: this.state.is_promo,
            hide_for_ordering: this.state.hide_for_ordering,
            discount_percent: this.state.discount_percent,
            discount_money: this.state.discount_money,
            image_scale: this.state.image_scale,
            fast_order: this.state.fast_order,
            type: this.state.type,
            // catHierarchyId: this.state.catHierarchyId
            catHierarchyIds: catHierarchyIds,
            label: this.state.label,
            productPrintGroupId: this.state.productPrintGroupId,
        }

        const errors = this.validate(post);
        if (errors.length > 0) {
            this.props.addErrorMessages(errors);
            return;
        }

        // Image append
        const files = Array.from(document.querySelector('#multi').files)
        let formData = new FormData()

        files.forEach((file, i) => {
            formData.append("file", file)
        })
        if (files.length == 0) {
            formData = null;
        }
        this.props.addProduct(post, this.props.history, formData, this.props.languages[this.state.currLang].id);
    }

    setRelevantCategoryState = (categoryId, relevantSubcategories) => {
        this.setState({
            categoryId: categoryId,
            subcategorySelectDisabled: false,
            relevantSubcategories: relevantSubcategories,
        });
    }

    getRelevatSubcategory = (e) => {
        this.setState({
            categoryId: e.target.value,
            subcategorySelectDisabled: false,
            relevantSubcategories: this.props.categories[e.target.value].subcategories,
            subcategoryId: this.props.categories[e.target.value].subcategories[Object.keys(this.props.categories[e.target.value].subcategories)[0]].id
        });
    }


    render() {
        return (

            <main className="main">

                {/* <!-- Breadcrumb--> */}
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><NavLink to={ADMIN_URL}>{this.props.translations.common.dashboard}</NavLink></li>
                    {/* <li className="breadcrumb-item">Меню</li> */}
                    <li className="breadcrumb-item"><NavLink to={ADMIN_URL + "products"}>{this.props.translations.products.title}</NavLink></li>
                    <li className="breadcrumb-item active">{this.props.translations.products.add}</li>
                    {/* <!-- Breadcrumb Menu--> */}
                    <li className="breadcrumb-menu d-md-down-none">
                        <div className="btn-group" role="group" aria-label="Button group">
                            {/* <!--<a className="btn" href="/" target="_blank">
                        <i className="fa fa-plus fa-lg"></i> &nbsp; Добавяне на продукт
                        </a>
                        <a className="btn" href="./">
                        <i className="icon-graph"></i>  Dashboard</a>
                        <a className="btn" href="#">
                        <i className="icon-settings"></i>  Settings</a>
                        --> */}
                        </div>
                    </li>
                </ol>

                <div className="container-fluid">
                    <div className="animated fadeIn">
                        <div className="row">


                            <div className="col-sm-12">
                                <div className="card">

                                    <AddEditProductForm
                                        settings={this.props.settings}
                                        onSubmit={this.onSubmit}
                                        onChange={this.onChange}
                                        onCKEditorChange={this.onCKEditorChange}
                                        onChangeCheckbox={this.onChangeCheckbox}
                                        product={this.state}
                                        productName={this.state.name}
                                        productCode={this.state.code}
                                        productDescription={this.state.description}
                                        productActive={this.state.active}
                                        productisLiquid={this.state.isLiquid}
                                        productPrintGroupId={this.state.productPrintGroupId}
                                        productFastOrder={this.state.fast_order}
                                        catHierarchyId={this.state.catHierarchyId}

                                        actionText="Добави"
                                        isAddingProduct="1"
                                        allCategories={this.props.categories}
                                        allSubcategories={this.props.subcategories}
                                        getRelevatSubcategory={this.getRelevatSubcategory}
                                        relevantSubcategories={this.state.relevantSubcategories}
                                        subcategorySelectDisabled={this.state.subcategorySelectDisabled}
                                        nameError={this.props.errors.find(error => error.path == 'name') ? this.props.errors.find(error => error.path == 'name') : ""}
                                        translations={this.props.translations}
                                        currLang={this.state.currLang}
                                        languages={this.props.languages}
                                        categoriesHierarchy={this.props.categoriesHierarchy}
                                        // noCategoriesView={this.props.noCategoriesView}
                                        onChangeSelectAutocompleteCategory={this.onChangeSelectAutocompleteCategory}
                                        onChangeSelectAutocomplete={this.onChangeSelectAutocomplete}
                                    />

                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </main>
        )
    }
}

const mapStateToProps = state => ({
    categoriesHierarchy: state.categoriesHierarchy.categories,
    errors: state.products.errors,
    categories: [], //state.categories.categories,
    subcategories: [], //state.subcategories.subcategories,
    translations: state.lang,
    languages: state.languages.languages || {},
    currLang: "bg",
    settings: state.settings.settings,
    // noCategoriesView: state.settings.settings && state.settings.settings['no_categories_view'] && state.settings.settings['no_categories_view'].value ? state.settings.settings['no_categories_view'].value : 0,  

});

export default connect(mapStateToProps, { addProduct, addErrorMessages })(AddProductPage);