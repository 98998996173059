import React, { Component } from 'react'
import { NavLink } from 'react-router-dom';
import { ADMIN_URL, PRODUCT_IMG_URL } from '../../constants.js'
import DeleteSubcategoryModal from './DeleteSubcategoryModal.js';
import MessagesContainer from './../messages/MessagesContainer';
import Utils from '../../utils/Utils.js';
import LangDropdown from './../common/LangDropdown';

class AddEditSubcategoryForm extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const props = this.props;
        let selectedCategory;
        let categoriesOptions = Object.values(props.allCategories).map((cat) => {
            if (props.categoryId == cat.id) {
                selectedCategory = cat.id;
            }
            if (cat.active == 1 && cat.deleted == 0) {
                return <option key={cat.id} value={cat.id}>{cat.translations && cat.translations[props.currLang] ? cat.translations[props.currLang].name : cat.name}</option>
            }
        });

        if ((props.isAddingSubcategory != '1')
            && ((typeof props.allCategories[props.categoryId] == 'undefined')
                || ((props.allCategories[props.categoryId]) && (props.allCategories[props.categoryId].active == false))
            )) {
            let catSelectHolder = document.getElementById('category_id');
            if (catSelectHolder) {
                let optionHolder = document.createElement('option');
                optionHolder.value = '-';
                optionHolder.innerHTML = '-';
                optionHolder.hidden = true;
                optionHolder.selected = true;
                catSelectHolder.prepend(optionHolder);
            }

        }

        let editImage;
        if (props.isAddingSubcategory == '0' && (props.icon && props.icon.length > 0) && (props.icon)) {
            editImage = <img src={Utils.getSubcategoryImagePath(props.icon)} className="editPagesImage" />
        }

        return (
            <form onSubmit={props.onSubmit}>
                <MessagesContainer />
                <div className="card-body">
                    <LangDropdown
                        isDisabled={props.isAddingSubcategory == 1 ? 1 : ""}
                        currLangOnChange={props.currLangOnChange}
                        currLang={props.currLang}
                        languages={props.languages}
                    />

                    <div className="row">
                        <div className="col-sm-12">
                            <div className="form-group">
                                <label htmlFor="name">{props.translations.common.subcategory}</label>
                                <input className={`form-control ${props.nameError.message ? "is-invalid" : ""}`} id="name" type="text" placeholder={props.translations.common.name} name="name" onChange={props.onChange} value={props.subcategoryName} />
                                <div className="invalid-feedback">{props.nameError.message ? props.nameError.message : props.nameError}</div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-12">
                            <div className="form-group">
                                <label htmlFor="slug">{props.translations.common.slug} {props.isAddingSubcategory == '1' ? "(Automaticaly generated on create)" : ""} </label>
                                <input className={`form-control`} id="slug" type="text"
                                    placeholder={props.translations.common.slug} name="slug" onChange={props.onChange} value={props.subcategorySlug}
                                    disabled={props.isAddingSubcategory == '1' ? true : false}
                                />
                                {/* <div className="invalid-feedback">{props.nameError.message ? props.nameError.message : props.nameError}</div> */}
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-12">
                            <div className="form-group">
                                <label htmlFor="name">{props.translations.common.code}</label>
                                <input className={`form-control ${props.nameError.message ? "is-invalid" : ""}`} id="name" type="text" placeholder={props.translations.common.code} name="code" onChange={props.onChange} value={props.subcategoryCode} />
                                <div className="invalid-feedback">{props.nameError.message ? props.nameError.message : props.nameError}</div>
                            </div>
                        </div>
                    </div>

                    {/* <!-- /.row--> */}
                    <div className="row">
                        <div className="form-group col-sm-12">
                            <label htmlFor="category_id">{props.translations.common.category}</label>
                            <select className="form-control" id="category_id" name="categoryId" onChange={props.onChange} value={selectedCategory}>
                                {categoriesOptions}
                            </select>
                        </div>
                    </div>

                    <div className=" row">
                        <label className="col-md-1 col-form-label" htmlFor="file-multiple-input">{props.translations.common.image}</label>
                        <div className="col-md-5 col-form-label">
                            {/* <input id="file-multiple-input" type="file" name="file-multiple-inpuproductisLiquidt" multiple=""/> */}
                            <input id="image" type='file' name='file' id='multi' multiple ref="image" />
                            {editImage}
                        </div>
                    </div>


                    <div className=" row">
                        <label className="col-md-1 col-form-label">{props.translations.common.active}</label>
                        <div className="col-md-11 col-form-label">
                            <label className="switch switch-label switch-pill switch-outline-primary-alt">
                                <input className="switch-input" type="checkbox" name="active" onChange={props.onChangeCheckbox} checked={props.subcategoryActive} />
                                <span className="switch-slider" data-checked={props.translations.common.yes} data-unchecked={props.translations.common.no}></span>
                            </label>
                        </div>
                    </div>

                    <hr />
                    <br />

                    <div className="form-actions">
                        {props.isAddingSubcategory == '1' ? '' : <button className="btn btn-danger mright10" type="button" data-toggle="modal" data-target={"#dangerModal" + props.subcategoryId} >{props.translations.common.delete}</button>}

                        <DeleteSubcategoryModal
                            subcategoryId={props.subcategoryId}
                            subcategoryName={props.subcategoryName}
                            deleteSubcategory={props.deleteSubcategory}
                            translations={props.translations}
                        />

                        <NavLink to={ADMIN_URL + 'subcategories'}>
                            <button className="btn btn-secondary" type="button">
                                {props.translations.common.cancel}
                            </button>
                        </NavLink>

                        <button className="fright btn btn-primary" type="submit">
                            {props.isAddingSubcategory == '1' ? props.translations.common.add : props.translations.common.save}
                        </button>

                    </div>

                </div>

            </form>
        )
    }

}

export default AddEditSubcategoryForm;