import { LIST_PLACE_IMAGES } from '../actions/types';

const initialState = {
    placeImages: []
}

export default function(state = initialState, action) {
    switch(action.type) {
        case LIST_PLACE_IMAGES: {
            return {
                ...state,
                placeImages: action.payload,
            }
        }
           
        default:
            return state;
    }
}