import React, { Component } from 'react'
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { ADMIN_URL } from '../constants.js'
import Auth from './../utils/Auth';

import Cookies from 'universal-cookie';
import Utils from '../utils/Utils';

const cookies = new Cookies();



class SideBar extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		const { settings } = this.props;

		let currPlace = this.props.places.find(p => p.id == Auth.getUserPlace());
		let currPlaceName = currPlace ? currPlace.name : "-";

		const currPlaceOrderTypes = currPlace ? currPlace.ordertypes : []
		// console.log('currPlaceOrderTypes', currPlaceOrderTypes)
		// if(cookies.get('tabl_place') != placeId) { // on refresh - if place is different - clear cart

		// console.log('currPlace', currPlace)
		return (
			<div className="sidebar">
				<nav className="sidebar-nav">
					<ul className="nav">
						<li className="nav-item">
							<NavLink to={ADMIN_URL} className="nav-link">
								<i className="nav-icon icon-speedometer"></i> {this.props.translations.sidemenu.dashboard}
								{/* <span className="badge badge-secondary">new</span> */}

								{/* <!--<span className="badge badge-primary">NEW</span>--> */}
							</NavLink>
						</li>

						<li className="divider"></li>

						{/*  */}

						{Auth.hasAccess("WAITER") ?

							<React.Fragment>
								{currPlaceOrderTypes.length > 0 ?
									<React.Fragment>
										{Auth.hasAccess("SUPER_ADMIN") ?
											<li className="nav-title">
												<NavLink to={ADMIN_URL + `list-orders/system`} style={{ color: 'white' }}>
													{this.props.translations.sidemenu.orders}
												</NavLink>
											</li>
											:
											<li className="nav-title">{this.props.translations.sidemenu.orders}</li>
										}

										<li className="nav-item">
											<NavLink to={ADMIN_URL + `list-orders/all`} className="nav-link">
												<i className="nav-icon icon-notebook"></i>
												{this.props.translations.sidemenu.all_orders}
												{/* <span className="badge badge-secondary">In progress</span> */}
											</NavLink>
										</li>
									</React.Fragment>
									:
									null
								}

								{currPlaceOrderTypes.findIndex(p => p.type == "spot") > -1 ?
									< li className="nav-item">
										<NavLink to={ADMIN_URL + `list-orders/spot`} className="nav-link">
											<i className="nav-icon icon-notebook"></i>
											{this.props.translations.sidemenu.orders_from_spot}
											{/* <span className="badge badge-secondary">In progress</span> */}
										</NavLink>
									</li>
									:
									null
								}

								{currPlaceOrderTypes.findIndex(p => p.type == "delivery") > -1 ?
									<li className="nav-item">
										<NavLink to={ADMIN_URL + `list-orders/delivery`} className="nav-link">
											<i className="nav-icon icon-notebook"></i>
											{this.props.translations.sidemenu.orders_delivery}
											{/* <span className="badge badge-secondary">In progress</span> */}
										</NavLink>
									</li>
									:
									null
								}

								{currPlaceOrderTypes.findIndex(p => p.type == "room") > -1 ?
									<li className="nav-item">
										<NavLink to={ADMIN_URL + `list-orders/room`} className="nav-link">
											<i className="nav-icon icon-notebook"></i>
											{this.props.translations.sidemenu.orders_room}
											{/* <span className="badge badge-secondary">In progress</span> */}
										</NavLink>
									</li>
									:
									null
								}

								{currPlaceOrderTypes.findIndex(p => p.type == "preorder") > -1 ?
									<li className="nav-item">
										<NavLink to={ADMIN_URL + `list-orders/preorder`} className="nav-link">
											<i className="nav-icon icon-notebook"></i>
											{this.props.translations.sidemenu.orders_preorder}
											{/* <span className="badge badge-secondary">In progress</span> */}
										</NavLink>
									</li>
									:
									null
								}

							</React.Fragment>
							:
							null
						}

						{Auth.hasAccess("WAITER") && Utils.hasFunctionalityModule('booking') ?
							<li className="nav-item">
								<NavLink to={ADMIN_URL + `bookings`} className="nav-link">
									<i className="nav-icon icon-notebook"></i>
									{this.props.translations.sidemenu.bookings}
									{/* <span className="badge badge-secondary">In progress</span> */}
								</NavLink>
							</li>
							:
							null
						}

						{Auth.hasAccess("WAITER") && Utils.hasFunctionalityModule('reservations') ?
							<li className="nav-item">
								<NavLink to={ADMIN_URL + `reservations`} className="nav-link">
									<i className="nav-icon icon-notebook"></i>
									{this.props.translations.sidemenu.reservations}
									{/* <span className="badge badge-secondary">In progress</span> */}
								</NavLink>
							</li>
							:
							null
						}
						{/* --- */}
						{/*  */}
						{/* {currPlaceOrderTypes.length > 0 ?
							<React.Fragment>
								<li className="nav-title">{this.props.translations.sidemenu.orders}</li>

								<li className="nav-item">
									<NavLink to={ADMIN_URL + `orders`} className="nav-link">
										<i className="nav-icon icon-notebook"></i>
										{this.props.translations.sidemenu.all_orders}
									</NavLink>
								</li>
							</React.Fragment>
							:
							null
						}

						{currPlaceOrderTypes.findIndex(p => p.type == "spot") > -1 ?
							< li className="nav-item">
								<NavLink to={ADMIN_URL + `spot-orders`} className="nav-link">
									<i className="nav-icon icon-notebook"></i>
									{this.props.translations.sidemenu.orders_from_spot}
								</NavLink>
							</li>
							:
							null
						}

						{currPlaceOrderTypes.findIndex(p => p.type == "delivery") > -1 ?
							<li className="nav-item">
								<NavLink to={ADMIN_URL + `delivery-orders`} className="nav-link">
									<i className="nav-icon icon-notebook"></i>
									{this.props.translations.sidemenu.orders_delivery}
								</NavLink>
							</li>
							:
							null
						}

						{currPlaceOrderTypes.findIndex(p => p.type == "preorder") > -1 ?
							<li className="nav-item">
								<NavLink to={ADMIN_URL + `preorder-orders`} className="nav-link">
									<i className="nav-icon icon-notebook"></i>
									{this.props.translations.sidemenu.orders_preorder}
								</NavLink>
							</li>
							:
							null
						} */}

						{Auth.hasAccessCustom(['PDF_MENU_ADMIN', 'RASPBERRY_ADMIN', 'PLACE_ADMIN', 'CLIENT_ADMIN', 'SALES_ADMIN', 'SUPER_ADMIN', 'WAITER']) ?
							<React.Fragment>
								<li className="divider"></li>
								<li className="nav-title">{this.props.translations.sidemenu.menu}</li>
								{Auth.hasPermission('show_categories_list') && Auth.hasAccess("WAITER") ?
									<li className="nav-item">
										<NavLink to={ADMIN_URL + `categories-hierarchy`} className="nav-link">
											<i className="nav-icon icon-list"></i> {this.props.translations.sidemenu.categoriesHierarchy}
										</NavLink>
									</li>
									:
									null
								}
							</React.Fragment>
							:
							null
						}

						{Auth.hasPermission('show_products_list') && Auth.hasAccess("WAITER") ?
							<li className="nav-item">
								<NavLink to={ADMIN_URL + `products`} className="nav-link">
									<i className="nav-icon icon-cup"></i> {this.props.translations.sidemenu.products}</NavLink>
							</li>
							:
							null
						}

						{Auth.hasAccess("PLACE_ADMIN") ?
							<React.Fragment>

								{/* <li className="nav-item">
									<NavLink to={ADMIN_URL + `tables`} className="nav-link">
										<i className="nav-icon icon-screen-tablet"></i> {this.props.translations.sidemenu.tables}
									</NavLink>
								</li> */}

								<li className="nav-item nav-dropdown" >
									<a className="nav-link nav-dropdown-toggle" href="#"><i className="nav-icon fa fa-window-maximize icon-with-dropdown"></i> {this.props.translations.sidemenu.tables}</a>
									<ul className="nav-dropdown-items">
										<li className="nav-item">
											<NavLink to={ADMIN_URL + `tables`} className="nav-link" style={{ paddingLeft: 30 }}>
												<i className="nav-icon fa fa-window-maximize"></i>  {this.props.translations.sidemenu.tables}
											</NavLink>
										</li>
										<li className="nav-item">
											<NavLink to={ADMIN_URL + `tableRegions`} className="nav-link" style={{ paddingLeft: 30 }}>
												<i className="nav-icon fa fa-square-o"></i>  {this.props.translations.table_regions.title}

											</NavLink>
										</li>
										{Utils.hasFunctionalityModule('reservations') ?
											<>
												<li className="nav-item">
													<NavLink to={ADMIN_URL + `tableGroups`} className="nav-link" style={{ paddingLeft: 30 }}>
														<i className="nav-icon fa fa-plus-square-o"></i>  {this.props.translations.table_groups.title}

													</NavLink>
												</li>
												<li className="nav-item">
													<NavLink to={ADMIN_URL + `restaurantHalls`} className="nav-link" style={{ paddingLeft: 30 }}>
														<i className="nav-icon fa fa-dot-circle-o"></i>  {this.props.translations.restaurant_halls.title}

													</NavLink>
												</li>
											</>
											:
											null
										}
									</ul>
								</li>


								<li className="nav-item">
									<NavLink to={ADMIN_URL + `promocodes`} className="nav-link">
										<i className="nav-icon icon-puzzle"></i> {this.props.translations.sidemenu.promocodes}
									</NavLink>
								</li>

								<li className="nav-item">
									<NavLink to={ADMIN_URL + `ingredients`} className="nav-link">
										<i className="nav-icon icon-magic-wand"></i> {this.props.translations.sidemenu.ingredients}</NavLink>
								</li>

								<li className="nav-item nav-dropdown" >
									<a className="nav-link nav-dropdown-toggle" href="#"><i className="nav-icon fa fa-comments-o icon-with-dropdown"></i> {this.props.translations.sidemenu.feedback}</a>
									<ul className="nav-dropdown-items">
										<li className="nav-item">
											<NavLink to={ADMIN_URL + `feedback/questions`} className="nav-link" style={{ paddingLeft: 30 }}>
												<i className="nav-icon fa fa-comments-o"></i>  {this.props.translations.sidemenu.feedback_questions}
											</NavLink>
										</li>
										<li className="nav-item">
											<NavLink to={ADMIN_URL + `feedback/answers`} className="nav-link" style={{ paddingLeft: 30 }}>
												<i className="nav-icon fa fa-comments-o"></i>  {this.props.translations.sidemenu.feedback_answers}
											</NavLink>
										</li>
									</ul>
								</li>

								<li className="nav-item">
									<NavLink to={ADMIN_URL + `place-images`} className="nav-link">
										<i className="nav-icon icon-magic-wand"></i> {this.props.translations.sidemenu.place_images}</NavLink>
								</li>

								<li className="divider"></li>
								<li className="nav-title">
									{this.props.translations.sidemenu.system}
								</li>

								<li className="nav-item">
									<NavLink to={ADMIN_URL + `settings`} className="nav-link">
										<i className="nav-icon icon-settings"></i>  {this.props.translations.sidemenu.settings}
									</NavLink>
								</li>
							</React.Fragment>
							:
							null
						}

						{Auth.hasAccess("CLIENT_ADMIN") && this.props.places.length > 1 ?
							<li className="nav-item">
								<NavLink to={ADMIN_URL + `places/current`} className="nav-link">
									<i className="nav-icon icon-check"></i>  {this.props.translations.sidemenu.current_place} <span className="badge badge-secondary current-place-sidebar">{currPlaceName}</span>
								</NavLink>
							</li>
							:
							null
						}

						{Auth.hasAccessCustom(['PDF_MENU_ADMIN', 'PLACE_ADMIN', 'CLIENT_ADMIN', 'SALES_ADMIN', 'SUPER_ADMIN']) ?
							<li className="nav-item nav-dropdown" >
								<a className="nav-link nav-dropdown-toggle" href="#"><i className="nav-icon icon-star icon-with-dropdown"></i> {this.props.translations.sidemenu.statistics}</a>
								<ul className="nav-dropdown-items">
									<li className="nav-item">
										<NavLink to={ADMIN_URL + `statistics/orders`} className="nav-link" style={{ paddingLeft: 30 }}>
											<i className="nav-icon icon-chart"></i>  {this.props.translations.sidemenu.statistics_orders}
										</NavLink>
									</li>
									<li className="nav-item">
										<NavLink to={ADMIN_URL + `statistics/users`} className="nav-link" style={{ paddingLeft: 30 }}>
											<i className="nav-icon icon-chart"></i>  {this.props.translations.sidemenu.statistics_users}
										</NavLink>
									</li>
								</ul>
							</li>
							:
							null
						}

						{Auth.hasAccess("PLACE_ADMIN") ?
							<li className="nav-item nav-dropdown" >
								<a className="nav-link nav-dropdown-toggle" href="#"><i className="nav-icon icon-user icon-with-dropdown"></i> {this.props.translations.sidemenu.users}</a>
								<ul className="nav-dropdown-items">
									<li className="nav-item">
										<NavLink to={ADMIN_URL + `users`} className="nav-link" style={{ paddingLeft: 30 }}>
											<i className="nav-icon icon-user"></i>  {this.props.translations.sidemenu.work_users}
										</NavLink>
									</li>
									<li className="nav-item">
										<NavLink to={ADMIN_URL + `users/registered`} className="nav-link" style={{ paddingLeft: 30 }}>
											<i className="nav-icon icon-user"></i>  {this.props.translations.sidemenu.registered_users}
										</NavLink>
									</li>
								</ul>
							</li>
							:
							null
							// <li className="nav-item">
							// 	<NavLink to={ADMIN_URL + `users`} className="nav-link">
							// 		<i className="nav-icon icon-user"></i>  {this.props.translations.sidemenu.users}
							// 	</NavLink>
							// </li>
							// :
							// null
						}


						{Auth.hasAccess("SALES_ADMIN") ?
							<React.Fragment>
								<li className="divider"></li>
								<li className="nav-title">
									{this.props.translations.sidemenu.super_admin}
								</li>
								<li className="nav-item">
									<NavLink to={ADMIN_URL + `places`} className="nav-link">
										<i className="nav-icon icon-directions"></i>  {this.props.translations.sidemenu.places}
									</NavLink>
								</li>

								<li className="nav-item">
									<NavLink to={ADMIN_URL + `clients`} className="nav-link">
										<i className="nav-icon icon-people"></i>  {this.props.translations.sidemenu.clients}
									</NavLink>
								</li>

								<li className="nav-item">
									<NavLink to={ADMIN_URL + `general-promotions`} className="nav-link">
										<i className="nav-icon fas fa-ad"></i>  {this.props.translations.sidemenu.general_promotions}
									</NavLink>
								</li>
							</React.Fragment>
							:
							null
						}

						{Auth.hasAccess("SUPER_ADMIN") ?
							<React.Fragment>
								<li className="nav-item">
									<NavLink to={ADMIN_URL + `invoices`} className="nav-link">
										<i className="nav-icon icon-docs"></i>  {this.props.translations.sidemenu.invoices}
									</NavLink>
								</li>

								<li className="nav-item">
									<NavLink to={ADMIN_URL + `auto-invoices`} className="nav-link">
										<i className="nav-icon icon-docs"></i>  {this.props.translations.sidemenu.auto_invoices}
									</NavLink>
								</li>
							</React.Fragment>
							:
							null
						}

						{Auth.hasAccess("SUPER_ADMIN") ?
							<React.Fragment>
								<li className="nav-item">
									<NavLink to={ADMIN_URL + `proforms`} className="nav-link">
										<i className="nav-icon icon-docs"></i>  {this.props.translations.sidemenu.proforms}
									</NavLink>
								</li>
							</React.Fragment>
							:
							null
						}

						{/* <li className="nav-item nav-dropdown">
								<a className="nav-link nav-dropdown-toggle" href="settings.html">
									<i className="nav-icon icon-wrench"></i> Настройки</a>
								<ul className="nav-dropdown-items">
									<li className="nav-item">
									<NavLink to={ADMIN_URL+`settings/languages`} className="nav-link">
									<i className="nav-icon icon-globe"></i> Езици
									</NavLink>
									</li>
									<li className="nav-item">
									<a className="nav-link" href="settings/currency.html" target="_top">
										<i className="nav-icon icon-flag"></i> Смяна на валута</a>
									</li>
									<li className="nav-item">
									<NavLink to={ADMIN_URL+`settings`} className="nav-link">
										<i className="nav-icon icon-settings"></i> Още настройки
									</NavLink>
									</li>
								</ul>
								</li> */}

					</ul>
				</nav>
				<button className="sidebar-minimizer brand-minimizer" type="button"></button>
			</div >
		)
	}
}

const mapStateToProps = state => {

	return {
		translations: state.lang,
		userRole: state.auth.user.role || "",
		settings: state.settings.settings,
		places: state.places.places || []
	}
};


export default connect(mapStateToProps)(SideBar);