import React from 'react'

const Filter = (props) => {
    let now = new Date();
    let currYear = now.getFullYear();
    let currMonth = now.getMonth() + 1;

    let firstYear = 2021;
    let firstMonth = 3;

    let allDates = [];
    for (let i = 0; i < currYear - firstYear + 1; i++) {
        let relevantYearMonths = [];
        for (let k = (i == 0 ? firstMonth : 1); k <= 12; k++) {
            relevantYearMonths.push(k);
            if (firstYear + i == currYear && k == currMonth) {
                break;
            }
        }
        allDates.push({ year: firstYear + i, months: relevantYearMonths });
    }

    return (
        <div className="container-fluid">
            <div className="animated fadeIn">
                <div className="row">

                    {/* TODO: filter by date / or filter by month - don't know which will be more usefull (maybe by month) */}
                    {/* <div className="form-group reservation-list-search col-md-2 col-sm-4 ">
                        <div className="form-group">
                            <label htmlFor="name">{props.translations.invoices.date}</label>
                            <input className={`form-control`} id="search_date" type="text" placeholder=""
                                name="search_invoice_date" onChange={props.onChange} value={props.search_invoice_date} />
                        </div>
                    </div> */}

                    <div className="form-group reservation-list-search col-md-2 col-sm-4 ">
                        <div className="form-group">
                            <label htmlFor="search_company_name">{props.translations.invoices.number}</label>
                            <input className={`form-control`} id="search_number" type="text" placeholder=""
                                name="search_number" onChange={props.onChange} value={props.state.search_number} />
                        </div>
                    </div>
                    <div className="form-group reservation-list-search col-md-2 col-sm-4 ">
                        <div className="form-group">
                            <label htmlFor="search_company_name">{props.translations.invoices.company_name}</label>
                            <input className={`form-control`} id="search_company_name" type="text" placeholder=""
                                name="search_company_name" onChange={props.onChange} value={props.state.search_company_name} />
                        </div>
                    </div>
                    <div className="form-group reservation-list-search col-md-2 col-sm-4 ">
                        <div className="form-group">
                            <label htmlFor="search_company_name">{props.translations.invoices.year}</label>
                            <select className="form-control" name="search_year" defaultValue={props.state.search_year} onChange={props.onChange}>
                                <option key={0} value="">-</option>
                                {allDates.map(d =>
                                    <option value={d.year}>{d.year}</option>
                                )}
                            </select>
                        </div>
                    </div>
                    <div className="form-group reservation-list-search col-md-2 col-sm-4 ">
                        <div className="form-group">
                            <label htmlFor="search_company_name">{props.translations.invoices.month}</label>
                            <select className="form-control" name="search_month" defaultValue={props.state.search_month} onChange={props.onChange}>
                                <option key={0} value="">-</option>
                                {props.state.search_year && allDates.filter(d => d.year == props.state.search_year)[0].months.map(m =>
                                    <option value={m}>{m}</option>
                                )}
                                {/* <option value={'new'}>{props.translations.invoices.new}</option>
                                <option value={'sent'}>{props.translations.invoices.sent}</option>
                                <option value={'paid'}>{props.translations.invoices.paid}</option> */}
                            </select>
                        </div>
                    </div>
                    <div className="form-group reservation-list-search col-md-2 col-sm-4 ">
                        <div className="form-group">
                            <label htmlFor="search_company_name">{props.translations.invoices.place}</label>
                            <select className="form-control" name="search_place" defaultValue={props.state.search_place} onChange={props.onChange}>
                                <option key={0} value="">-</option>
                                {props.places.map(p =>
                                    <option value={p.id}>{p.name}</option>
                                )}
                            </select>
                        </div>
                    </div>
                    <div className="form-group reservation-list-search col-md-2 col-sm-4 ">
                        <div className="form-group">
                            <label htmlFor="name">{props.translations.common.status}</label>
                            <select className="form-control" name="search_status" defaultValue={props.state.search_status} onChange={props.onChange}>
                                <option key={0} value="">-</option>
                                <option value={'new'}>{props.translations.invoices.new}</option>
                                <option value={'sent'}>{props.translations.invoices.sent}</option>
                                <option value={'paid'}>{props.translations.invoices.paid}</option>
                                <option value={'canceled'}>{props.translations.invoices.canceled}</option>
                            </select>
                        </div>
                    </div>

                    <div className="form-group reservation-list-search col-md-2 col-sm-4 ">
                        <div className="form-group">
                            <label htmlFor="name">Без анулирани</label>
                            <select className="form-control" name="search_without_cancelled" defaultValue={props.state.search_without_cancelled} onChange={props.onChange}>
                                <option key={0} value="">-</option>
                                <option value={'1'}>Да</option>
                            </select>
                        </div>
                    </div>

                    <div className="form-group reservation-list-search col-md-2 col-sm-4 ">
                        <div className="form-group">
                            <label style={{ display: 'block' }} >&nbsp;</label>
                            <button className="btn btn-secondary" type="button" data-dismiss="modal">Изпрати напомняне</button>
                        </div>
                    </div>

                    <div className="form-group reservation-list-search col-md-2 col-sm-4 ">
                        <div className="form-group">
                            <label style={{ display: 'block' }} >&nbsp;</label>
                            <button className="btn btn-secondary" type="button" data-dismiss="modal">Изпрати</button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Filter;