import React from 'react'
import { NavLink } from 'react-router-dom';
import { ADMIN_URL, IMG_PATH } from '../../../constants.js'
// import DeleteInvoiceModal from './DeleteInvoiceModal';
import Utils from '../../../utils/Utils';
import moment from 'moment';
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';

const ListInvoicesRow = (props) => {
    let monthlySubscriptionMailTo = props.invoice.place.company_email + "?subject=TabL - Фактура за Дигитално меню - м. "
        + (parseInt(new Date().getMonth()) + 1) + "." + new Date().getFullYear()
        + "г.&body=Здравейте " + props.invoice.company_person + ", %0D%0A%0D%0AНа адрес: https://menu.tabl.bg/files/invoices/" + props.invoice.original_invoice_path + " ще откриете фактурата за услугата Дигитално меню на " + props.invoice.company_name + " за м. " + (parseInt(new Date().getMonth()) + 1) + "." + new Date().getFullYear() + "г. %0D%0A %0D%0AБлагодарим Ви, че сте наш клиент!"
        + "&bcc=info@tabl.bg"

    let reminderMonthlySubscriptionMailTo = props.invoice.place.company_email + "?subject=TabL - Напомняне за Фактура за Дигитално меню - м. "
        + (parseInt(new Date().getMonth()) + 1) + "." + new Date().getFullYear()
        + "г.&body=Здравейте " + props.invoice.company_person + ", %0D%0A%0D%0AНапомняме Ви, че все още не са постъпили приходи по сметката относно използваните от Вас услуги: https://menu.tabl.bg/files/invoices/" + props.invoice.original_invoice_path + " %0D%0A %0D%0AИзвиняваме се, ако междувременно сте извършили превода.  %0D%0A %0D%0AБлагодарим Ви, че сте наш клиент!"
        + "&bcc=info@tabl.bg"


    const getInvoiceStyle = (status) => {
        let invoiceStyle = {};

        if (status == 'sent') {
            invoiceStyle = { background: '#ffeb0f82' };
        } else if (status == 'paid') {
            invoiceStyle = { background: '#67ff8f6b' };
        } else if (status == 'canceled') {
            invoiceStyle = { background: '#ff00005e' };
        }

        return invoiceStyle;
    }

    return (
        <tr key={props.invoice.id} style={getInvoiceStyle(props.invoice.status)}>
            <td>
                {props.invoice.number}
            </td>
            <td>
                {moment(props.invoice.invoice_date).format("DD.MM.YY")}
            </td>
            <td>
                {props.invoice.company_name}
            </td>
            <td>
                {props.invoice.company_eik}
            </td>
            <td>
                {props.invoice.company_person}
            </td>
            <td style={{ wordBreak: 'break-all' }}>
                <a href={"mailto:" + monthlySubscriptionMailTo}>{props.invoice.place.company_email}</a>
            </td>
            <td>
                <select name='status' onChange={(ev) => props.updateStatus(ev, props.invoice.id)} className="form-control">
                    {(props.invoiceStatuses).map((status, ind) => {
                        return <option key={ind} selected={props.invoice.status == status ? "selected" : ""} style={getInvoiceStyle(status)}>
                            {status}
                        </option>
                    })}
                </select>
            </td>
            <td>
                {props.invoice.price}
            </td>
            <td>
                {props.invoice.place.name}
            </td>
            {/* <td>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                        // style={{ display: 'table', margin: '0 auto' }}
                        autoOk
                        // label={props.translations.invoices.remind_on}
                        // clearable
                        // disableFuture
                        name="remind_on"
                        value={props.invoice.remind_on}
                        onChange={e => props.updateDate(new Date(e).getMonth() + 1 + '/' + new Date(e).getDate() + '/' + new Date(e).getFullYear() + ' 10:00:00', props.invoice.id)} //10:00:00 because of timezone changes, DO NOT set it to 00:00:00
                        format="dd-MM-yyyy"
                    />
                </MuiPickersUtilsProvider>
            </td> */}
            <td>
                <div className="form-group">
                    <input className={`form-control`} type="text" placeholder="" value={props.invoice.partly_payment}
                        onChange={e => props.updateInvoice({ id: props.invoice.id, partly_payment: parseInt(e.target.value, 10) })}
                    />
                </div>

            </td>
            <td>
                <a href={Utils.getInvoiceImagePath(props.invoice.original_invoice_path)} target="_blank" >Оригинал</a>
                <br />
                <a href={Utils.getInvoiceImagePath(props.invoice.copy_invoice_path)} target="_blank" >Копие</a>
            </td>
            {/* <td>
            {props.invoices.image ? <img style={{ maxHeight: 100 }} src={Utils.getCategoryImagePath(props.alergen.image)} /> : ""}
        </td> */}
            <td>
                <NavLink to={ADMIN_URL + 'invoices/edit/' + props.invoice.id}>
                    <button className="btn btn-pill btn-link" type="button">
                        <i className="fas fa-edit"></i>
                    </button>
                </NavLink>

                <a href={"mailto:" + reminderMonthlySubscriptionMailTo} className="btn btn-pill btn-link">
                    <i className="fas fa-bell"></i>
                </a>

                {/* <button className="btn btn-pill btn-link" type="button" data-toggle="modal" data-target={"#dangerModal" + props.invoice.id} >
                <i className="fas fa-trash"></i>
            </button> */}
                {/* 
            <button className="btn btn-pill btn-link" type="button" onClick={() => props.initInvoiceSettings(props.invoice.id)} >
                <i className="fas fa-cog"></i>
            </button>
            
            <button className="btn btn-pill btn-link" type="button" data-toggle="modal" data-target={"#invoiceSmsLogsModal" + props.invoice.id} >
                <i className="fas fa-sms"></i>
            </button> */}

                {/* <DeleteInvoiceModal
                id={props.invoice.id}
                name={props.invoice.name}
                deleteInvoice={props.deleteInvoice}
                translations={props.translations}
            /> */}

            </td>
            <td>
                <input type="checkbox"
                    className="d-inline medium-checkbox valign-middle c-pointer w-10 m-right-7 valign-middle " style={{ width: 'auto !important' }}
                    checked={props.state.checked_checkboxes_ids.includes(props.invoice.id)}
                    onChange={() => props.toggleCheckboxValue(props.invoice.id)}
                />
            </td>
        </tr >)
}

export default ListInvoicesRow;