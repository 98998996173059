import React from 'react'
import DatePicker from "react-datepicker";
import Auth from '../../utils/Auth';

const Filter = (props) => {

    return (

        <div className="container-fluid">
            <div className="animated fadeIn">

                <div className="row">

                    <div className="form-group reservation-list-search col-md-2 col-sm-4 ">
                        <div className="form-group">
                            <label htmlFor="search_code">{props.translations.common.promocode}</label>
                            <input className={`form-control`} id="search_code" type="text" placeholder=""
                                name="search_code" onChange={props.onChange} value={props.data.search_code} />
                        </div>
                    </div>

                    <div className="form-group reservation-list-search col-md-2 col-sm-4 ">
                        <div className="form-group">
                            <label htmlFor="search_discount_value">{props.translations.common.discount_value}</label>
                            <input className={`form-control`} id="search_discount_value" type="text" placeholder=""
                                name="search_discount_value" onChange={props.onChange} value={props.data.search_discount_value} />
                        </div>
                    </div>

                    <div className="form-group reservation-list-search col-md-2 col-sm-4 ">
                        <div className="form-group">
                            <label htmlFor="name">{props.translations.common.discount_type}</label>
                            <select className="form-control" name="search_discount_type" value={props.data.search_discount_type} onChange={props.onChange}>
                                <option key={''} value="">{props.translations.common.all}</option>
                                <option value={'money'} >Money</option>
                                <option value={'percent'} >percent</option>
                            </select>
                        </div>
                    </div>

                    <div className="form-group reservation-list-search col-md-2 col-sm-4 ">
                        <div className="form-group">
                            <label htmlFor="name">{props.translations.common.active}</label>
                            <select className="form-control" name="search_active" value={props.data.search_active} onChange={props.onChange}>
                                <option key={0} value="">-</option>
                                <option value={2} >{props.translations.common.yes}</option>
                                <option value={1} >{props.translations.common.no}</option>
                            </select>
                        </div>
                    </div>

                    <div className="form-group reservation-list-search col-md-2 col-sm-4 ">
                        <div className="form-group">
                            <label htmlFor="name">{props.translations.common.used}</label>
                            <select className="form-control" name="search_used" value={props.data.search_used} onChange={props.onChange}>
                                <option key={0} value="">-</option>
                                <option value={2} >{props.translations.common.yes}</option>
                                <option value={1} >{props.translations.common.no}</option>
                            </select>
                        </div>
                    </div>

                    <div className="form-group reservation-list-search col-md-2 col-sm-4 ">
                        <div className="form-group">
                            <label htmlFor="name">{props.translations.common.deleted}</label>
                            <select className="form-control" name="search_deleted" value={props.data.search_deleted} onChange={props.onChange}>
                                <option key={0} value="">-</option>
                                <option value={2} >{props.translations.common.yes}</option>
                                <option value={1} >{props.translations.common.no}</option>
                            </select>
                        </div>
                    </div>


                    {Auth.hasAccess("SALES_ADMIN") ?
                        <div className="form-group reservation-list-search col-md-2 col-sm-4 ">
                            <div className="form-group">
                                <label htmlFor="name">{props.translations.common.place}</label>
                                <select className="form-control" name="search_place_id" onChange={props.onChange}>
                                    <option key={0} value="">-</option>
                                    {(props.places).map(s =>
                                        <option key={s.id} value={s.id} selected={s.id == props.data.search_place_id}>{s.name}</option>
                                    )}
                                </select>
                            </div>
                        </div>
                        :
                        null
                    }

                    <div className="form-group reservation-list-search col-md-2 col-sm-4">
                        <label>{props.translations.common.valid_from}</label>
                        <br />
                        <DatePicker
                            className="form-control"
                            selected={props.data.search_valid_from}
                            onChange={date => props.onFieldChange('search_valid_from', date)}
                        // dateFormat="d-M-Y"
                        // style={{ display: 'block' }}
                        // onCalendarClose={handleCalendarClose}
                        // onCalendarOpen={handleCalendarOpen}
                        />
                        <div className="c-pointer d-inline mleft10 valign-middle" onClick={() => props.onFieldChange('search_valid_from', '')}>
                            <i className="nav-icon icon-close" style={{
                                color: '#db0000',
                                'font-size': 20
                            }}></i>
                        </div>
                    </div>

                    <div className="form-group reservation-list-search col-md-2 col-sm-4">
                        <label>{props.translations.common.valid_to}</label>
                        <br />
                        <DatePicker
                            className="form-control"
                            selected={props.data.search_valid_to}
                            onChange={date => props.onFieldChange('search_valid_to', date)}
                        // dateFormat="d-M-Y"
                        // style={{ display: 'block' }}
                        // onCalendarClose={handleCalendarClose}
                        // onCalendarOpen={handleCalendarOpen}
                        />
                        <div className="c-pointer d-inline mleft10 valign-middle" onClick={() => props.onFieldChange('search_valid_to', '')}>
                            <i className="nav-icon icon-close" style={{
                                color: '#db0000',
                                'font-size': 20
                            }}></i>
                        </div>
                    </div>


                    <div className="form-group reservation-list-search col-sm-12 col-md-6 col-lg-1">
                        <button onClick={props.callFilter} className="btn btn-info" style={{ width: '100%' }}>Филтър</button>
                    </div>

                    <div className="form-group reservation-list-search col-sm-12 col-md-6 col-lg-1">
                        <button onClick={props.clearFilter} className="btn btn-warning" style={{ width: '100%' }}>Изчисти</button>
                    </div>

                </div>
            </div>
        </div>

    )
}

export default Filter;