import React from 'react'
import { NavLink } from 'react-router-dom';
import { ADMIN_URL, SETTINGS_IMG_URL } from '../../../constants'
// import DeleteModal from '../../common/DeleteModal';
// import ForbidModal from './../../forbidden/components/ForbidModal';
import Utils from './../../../utils/Utils';

const emptySymbol = '';
const yesOption = <span className="badge badge-success">Да</span>
const noOption = <span className="badge badge-secondary">Не</span>;

const ListSettingCategoryBox = (props) => {
    return (

        <div key={props.cat.id} className="col-lg-2 col-md-3 col-sm-6">
            <div className="card" style={{ textAlign: 'center' }}>
                <NavLink to={ADMIN_URL + 'settings/category/' + props.cat.id}>
                    <div className="card-body">
                        <i className={props.cat.icon} style={{ fontSize: 40 }}></i>
                        <br />
                        {props.translations.settings_categories && props.translations.settings_categories[(props.cat.name)] ? props.translations.settings_categories[(props.cat.name)] : props.cat.name}
                    </div>
                </NavLink>
            </div>
        </div>

        // <tr key={props.cat.id}>
        //     {/* <td>
        //         {props.setting.comment ? props.setting.comment : emptySymbol}
        //     </td> */}
        //     <td>
        //         <NavLink to={ADMIN_URL + 'settings/category/' + props.cat.id}>
        //             <button className="btn btn-pill btn-link" type="button">
        //                 <i className="fas fa-edit"></i>&nbsp;{props.translations.settings_categories && props.translations.settings_categories[(props.cat.name)] ? props.translations.settings_categories[(props.cat.name)] : props.cat.name}
        //             </button>
        //         </NavLink>
        //     </td>

        // </tr>
    )
}

export default ListSettingCategoryBox;