import React, { useState, useEffect } from 'react'

import axios from "axios";
import { API_URL } from '../../../constants';
import './css/CategoryVideoChunked.css';
import Utils from '../../../utils/Utils';
import { listCategoriesHierarchy, editCategoryHierarchy } from './../../../actions/categoriesHierarchyActions';
import store from './../../../store';
import LangDropdown from '../../common/LangDropdown';

const chunkSize = 300 * 1024;

function CategoryVideoTabChunked(props) {

  const [dropzoneActive, setDropzoneActive] = useState(false);
  const [files, setFiles] = useState([]);
  const [currentFileIndex, setCurrentFileIndex] = useState(null);
  const [lastUploadedFileIndex, setLastUploadedFileIndex] = useState(null);
  const [currentChunkIndex, setCurrentChunkIndex] = useState(null);
  const [videoTitle, setVideoTitle] = useState(props.category.video_title);
  const [videoDescription, setVideoDescription] = useState(props.category.video_description);

  function handleDrop(e) {
    e.preventDefault();
    setFiles([...files, ...e.dataTransfer.files]);
  }

  function readAndUploadCurrentChunk() {
    const reader = new FileReader();
    const file = files[currentFileIndex];
    if (!file) {
      return;
    }
    const from = currentChunkIndex * chunkSize;
    const to = from + chunkSize;
    const blob = file.slice(from, to);
    reader.onload = e => uploadChunk(e);
    reader.readAsDataURL(blob);
  }

  function uploadChunk(readerEvent) {
    const file = files[currentFileIndex];
    const data = readerEvent.target.result;
    const params = new URLSearchParams();
    params.set('catId', props.category.id);
    params.set('name', file.name);
    params.set('size', file.size);
    params.set('currentChunkIndex', currentChunkIndex);
    params.set('totalChunks', Math.ceil(file.size / chunkSize));
    const headers = {'Content-Type': 'application/octet-stream'};
    const url = API_URL + 'categoriesHierarchy/upload-video-chunked?'+params.toString();
    axios.post(url, data, {headers})
      .then(response => {
        const file = files[currentFileIndex];
        const filesize = files[currentFileIndex].size;
        const chunks = Math.ceil(filesize / chunkSize) - 1;
        const isLastChunk = currentChunkIndex === chunks;
        if (isLastChunk) {
          file.finalFilename = response.data.finalFilename;
          setLastUploadedFileIndex(currentFileIndex);
          setCurrentChunkIndex(null);
          store.dispatch(listCategoriesHierarchy());
        } else {
          setCurrentChunkIndex(currentChunkIndex + 1);
        }
      });
  }

  function saveTitleAndDescription(e) {

    e.preventDefault();

		const post = {
			id: props.category.id,
      video_title: videoTitle,
      video_description: videoDescription
		}

		// this.props.editCategoryHierarchy(post, this.props.history);
    store.dispatch(editCategoryHierarchy(post, null, null, props.languages[props.currLang].id));

  }

  useEffect(() => {
    if (lastUploadedFileIndex === null) {
      return;
    }
    const isLastFile = lastUploadedFileIndex === files.length - 1;
    const nextFileIndex = isLastFile ? null : currentFileIndex + 1;
    setCurrentFileIndex(nextFileIndex);
  }, [lastUploadedFileIndex]);

  useEffect(() => {
    if (files.length > 0) {
      if (currentFileIndex === null) {
        setCurrentFileIndex(
          lastUploadedFileIndex === null ? 0 : lastUploadedFileIndex + 1
        );
      }
    }
  }, [files.length]);

  useEffect(() => {
    if (currentFileIndex !== null) {
      setCurrentChunkIndex(0);
    }
  }, [currentFileIndex]);

  useEffect(() => {
    if (currentChunkIndex !== null) {
      readAndUploadCurrentChunk();
    }
  }, [currentChunkIndex]);

  useEffect( () => {
    setVideoTitle(props.category.video_title)
    setVideoDescription(props.category.video_description)

  }, [props.category])


  return (
    <React.Fragment>

      <div className="categoryVideoContainer">
        <div
          onDragOver={e => {setDropzoneActive(true); e.preventDefault();}}
          onDragLeave={e => {setDropzoneActive(false); e.preventDefault();}}
          onDrop={e => handleDrop(e)}
          className={"dropzone" + (dropzoneActive ? " active" : "")}>
          Drop your files here
        </div>
        <div className="files">
          {files.map((file,fileIndex) => {
            let progress = 0;
            if (file.finalFilename) {
              progress = 100;
            } else {
              const uploading = fileIndex === currentFileIndex;
              const chunks = Math.ceil(file.size / chunkSize);
              if (uploading) {
                progress = Math.round(currentChunkIndex / chunks * 100);
              } else {
                progress = 0;
              }
            }
            return (
              <a className="file" target="_blank"
                href={API_URL + 'categoriesHierarchy/upload-video-chunked/'+file.finalFilename}>
                <div className="name">{file.name}</div>
                <br/>
                <div className={"progress " + (progress === 100 ? 'done' : '')}
                    style={{width:progress+'%'}}>{progress}%
                </div>
              </a>
            );
          })}
        </div>
      </div>

      <br/>
      {props.category.video_name ? 
        <video
            className="VideoInput_video"
            width="100%"
            height={"300px"}
            controls
            src={Utils.getCategoryVideoPath(props.category.video_name)}
        />
      : 
        null
      }

      <LangDropdown
          isDisabled={0}
          currLangOnChange={props.currLangOnChange}
          currLang={props.currLang}
          languages={props.languages}
      />

      <div className="row">
        <div className="form-group col-sm-12">
            <label htmlFor="name">{props.translations.common.name}</label>
            <input className={`form-control`} id="video_title" type="text" placeholder={props.translations.common.category_name} name="video_title" onChange={e => props.onFieldChange('video_title', e.target.value)} value={props.category.video_title} />
            {/* <div className="invalid-feedback">{props.nameError.message ? props.nameError.message : props.nameError }</div>  */}
        </div>

        <div className="form-group col-sm-12">
            <label htmlFor="description">{props.translations.common.description}</label>
            <input className={`form-control`} id="video_description" type="text" placeholder={props.translations.common.description} name="video_description" onChange={e => props.onFieldChange('video_description', e.target.value)} value={props.category.video_description} />
            {/* <CKEditor name="description" data={props.category.description} onChange={props.onCKEditorChange} /> */}
            {/* <div className="invalid-feedback">{props.nameError.message ? props.nameError.message : props.nameError }</div>  */}
        </div>

        <div className="form-group col-sm-12">
          <button className="fright btn btn-primary" type="submit" onClick={saveTitleAndDescription}>
              {props.translations.common.save}
          </button>
        </div>

    </div>

    </React.Fragment>
  );
}

export default CategoryVideoTabChunked;