import React, { Component } from 'react'
import { NavLink } from 'react-router-dom';
import { ADMIN_URL, PRODUCT_IMG_URL } from '../../../../constants.js'
import DeleteModal from './../../../common/DeleteModal';
import MessagesContainer from '../../../messages/MessagesContainer';
import Utils from '../../../../utils/Utils.js';
import LangDropdown from './../../../common/LangDropdown';
import Select from 'react-select'

class AddEditRegionForm extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const props = this.props;

        // let selectedParent;
        // let autocompleteItems = [];
        // autocompleteItems.push({'value': null, 'label': "Choose region"})

        // // console.log( "AUTO ", autocompleteItems, props.clients)
        // props.regions.map(cl => {
        //     if (props.region.clientId == cl.id) {
        //         selectedParent = { 'value':cl.id, 'label': cl.name} ;
        //     }
            
        //     // if(cat.active == 1) {
                
        //         autocompleteItems.push( {'value': cl.id, 'label': cl.name} );
        //     // } 
        // });

        let categoriesHierarchyOptions = [];

        Object.values(props.categories).map(c => {
            if (c.active == 1) {
                categoriesHierarchyOptions.push({ 'value': c.id, 'label': c.name });
            }
        });

        // Object.values(props.categories).map(cat => {
        //     let undeletedChildrenCount = cat.children.filter(c => c.deleted == 0).length;
        //     if (cat.active == 1 && (cat.children.length == 0 || undeletedChildrenCount == 0)) {
        //         let dashes = '';
        //         for (let i = 0; i < cat.hierarchyLevel - 1; i++) {
        //             dashes += "-";
        //         }

        //         categoriesHierarchyOptions.push({
        //             'value': cat.id,
        //             'label': dashes + cat.name +
        //                 (props.categories && props.categories[cat.parentId] &&
        //                     props.categories[cat.parentId].name
        //                     ? (" /" + props.categories[cat.parentId].name + '/')
        //                     : '')
        //         });
        //     }
        // });
        return (
            <form onSubmit={props.onSubmit}>
                <MessagesContainer />
                <div className="card-body">

                    <LangDropdown
                        isDisabled={props.isAdding == 1 ? 1 : ""}
                        currLangOnChange={props.currLangOnChange}
                        currLang={props.currLang}
                        languages={props.languages}
                    />

                    <div className="row">
                        <div className="col-sm-12">
                            <div className="form-group">
                                <label htmlFor="name">{props.translations.common.name}</label>
                                <input className={`form-control`} id="name" type="text" placeholder={props.translations.common.name} name="name" onChange={props.onChange} value={props.bookingRoom.name} />
                            </div>
                        </div>
                    </div>

                    <div className="row">
                            <div className="form-group col-sm-12">
                                <label htmlFor="catHierarchyId">{props.translations.bookings.categories_for_room}</label>
                                <Select
                                    value={props.bookingRoom.catHierarchies}
                                    isMulti
                                    name="colors"
                                    options={categoriesHierarchyOptions}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    onChange={props.onChangeSelectAutocompleteCategory}
                                // onChange={(values, { action, removedValue }) => props.ingredientsOnChange(values, { action, removedValue }, 'removable')}
                                />
                            </div>
                        </div>

                    {/* <div className="row">
                        <div className="form-group col-sm-12">
                            <label htmlFor="catHierarchyId">{props.translations.common.category}</label>

                            <Select
                                value={props.bookingRoom.catHierarchyId ? {value: props.bookingRoom.catHierarchyId, label: props.categories[props.bookingRoom.catHierarchyId] && props.categories[props.bookingRoom.catHierarchyId].name || '' } : {value: '', label: ''}}
                                // isMulti
                                name="colors"
                                options={categoryOptions}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                onChange={(valueLabel) => props.onChangeSelectAutocomplete('catHierarchyId', valueLabel)}
                            // onChange={(values, { action, removedValue }) => props.ingredientsOnChange(values, { action, removedValue }, 'removable')}
                            />
                        </div>
                    </div> */}


                    <hr />
                    <br />

                    <div className="form-actions">
                        {props.isAdding == '1' ? '' : <button className="btn btn-danger mright10" type="button" data-toggle="modal" data-target={"#dangerModal" + props.bookingRoom.id} >{props.translations.common.delete}</button>}

                        <DeleteModal
                            id={props.bookingRoom.id}
                            title={props.bookingRoom.name}
                            deleteFunc={props.deleteBookingRoom}
                            translations={props.translations}
                        />

                        <NavLink to={ADMIN_URL + 'bookings/rooms'}>
                            <button className="btn btn-secondary" type="button">
                                {props.translations.common.cancel}
                            </button>
                        </NavLink>

                        <button className="fright btn btn-primary" type="submit">
                            {props.isAdding == '1' ? props.translations.common.add : props.translations.common.save}
                            {/* {props.actionText} */}
                        </button>

                    </div>

                </div>

            </form>
        )
    }
}

export default AddEditRegionForm;