import browserSupport from './utils/browsersSupport';

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import store, { history } from './store';
import { ConnectedRouter } from 'connected-react-router'

import { BrowserRouter } from 'react-router-dom'
import { listLanguages } from './actions/languagesActions';
import { listProducts } from './actions/productsActions';
import { listTables } from './actions/tablesActions';
import { listSettings } from './components/settings/actions/settings.actions';
import { listIngredients } from './components/ingredients/actions/ingredients.actions';
import { listRegions } from './components/regions/actions/regions.actions';
import { listVersions } from './components/versions/actions/versions.action';
// import { listRooms } from './reservations/rooms/actions/rooms.actions';
// import { listPlaces } from './reservations/places/actions/places.actions';
// import { listReservationTables } from './reservations/tables/actions/tables.actions';
// import { listReservationSettings } from './reservations/settings/actions/settings.actions';
import { listPlacesByUserRole } from './components/places/actions/places.actions';
import { listClients } from './components/clients/actions/clients.actions';

import { clearMessages } from './components/messages/messages.actions';
import { setCurrentUser, logout } from './actions/authActions';
import jwt from 'jsonwebtoken';
import jwtDecode from 'jwt-decode';

import Auth from './utils/Auth';
import setAuthorizationToken from './utils/setAuthorizationToken';
import axios from 'axios';
import { listCategoriesHierarchy } from './actions/categoriesHierarchyActions';
import setCustomAdminHeader from './utils/setCustomAdminHeader';
import setTablHeader from './utils/setTablHeader';
// import {syncWithStore, setLanguage} from 'react-redux-multilang';
// Store to state languages

setCustomAdminHeader();
setTablHeader();

if (Auth.getToken()) {
    try {
        // jwtDecode(Auth.getToken());
        let currUser = jwtDecode(Auth.getToken());
        store.dispatch(setCurrentUser(currUser));
        setAuthorizationToken(Auth.getToken());

        store.dispatch(listPlacesByUserRole());
        // if(currUser.role == "CLIENT_ADMIN") {
        //     // GET PLACES FOR CLIENT
        //     if(currUser.client_id) {
        //         store.dispatch(listPlaces(currUser.client_id));
        //     }
        // } else if(currUser.role == "PLACE_ADMIN") {
        //     if(currUser.client_id) {
        //         store.dispatch(listPlaces(null, currUser.place_id));
        //     }
        // } else {
        //     Auth.setUserPlace(currUser.place_id)
        // }

        store.dispatch(listCategoriesHierarchy());
        store.dispatch(listClients());
        store.dispatch(listVersions());

        if (currUser.role != "PDF_MENU_ADMIN") {
            store.dispatch(listLanguages());
            store.dispatch(listProducts());
            store.dispatch(listSettings());
            store.dispatch(listIngredients());
            store.dispatch(listRegions());
        }
        if (Auth.hasAccess('PLACE_ADMIN')) {
            store.dispatch(listTables());

        }

    } catch (e) {
        // console.log(e);
    }
}

// Add a response interceptor
axios.interceptors.response.use(function (response) {
    // Do something with response data
    return response;
}, function (error) {
    // Do something with response error
    // console.log(error.response);
    if (error.response && error.response.status && error.response.status == 401) {
        store.dispatch(logout());
        return;
    } else {
        // if(error.response.data.error.errors) {
        // store.dispatch(addErrorMessages(error.response.data.error.errors));
        // TODO: Tuk moje napravo da vikam addErrorMsgs()
        // }
    }
    return Promise.reject(error);
});



// let jwtToken = Auth.getToken();
// const secret = process.env.REACT_APP_JWT_SECRET;
// jwt.verify(jwtToken, secret, function(err, decoded) {
//     if(err) {
//         Auth.removeToken();
//         jwtToken = null;
//     } else {

//         store.dispatch(setCurrentUser(decoded));
//         console.log("DECODED ", decoded)
//     }
// })



// if (jwt.decode(localStorage.getItem('jwtToken')) && jwt.decode(localStorage.getItem('jwtToken')) != undefined){
// store.dispatch(setCurrentUser(jwt.decode(localStorage.getItem('jwtToken'))));
// }

// Reservations
// store.dispatch(listRooms());
// store.dispatch(listPlaces());
// store.dispatch(listReservationTables());
// store.dispatch(listReservationSettings());

// syncWithStore(store); // step 2: sync language with store
// setLanguage("bg");

const onlisten = history.listen((location, action) => {
    store.dispatch(clearMessages());
})

const app =
    <Provider store={store}>
        <ConnectedRouter history={history}>
            {/* <BrowserRouter> */}
            <App />
            {/* </BrowserRouter> */}
        </ConnectedRouter>
    </Provider>

ReactDOM.render(app, document.getElementById('root'));

