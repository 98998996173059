import { LIST_CATEGORIES_HIERARCHY, ADD_EDIT_CATEGORY_HIERARCHY_ERROR, GET_CATEGORY_SAMPLE_IMAGES } from '../actions/types';

const initialState = {
    categories: [],
    errors: [],
}

export default function (state = initialState, action) {
    switch (action.type) {
        case LIST_CATEGORIES_HIERARCHY:
            return {
                ...state,
                categories: action.payload,
                errors: [] // Reset add/edit categories error
            }
        case GET_CATEGORY_SAMPLE_IMAGES:
            return {
                ...state,
                sampleImages: action.payload,
                // errors: [] // Reset add/edit categories error
            }
        // case ADD_CATEGORY:
        //     return {
        //         ...state,
        //         item: action.payload
        //     }
        case ADD_EDIT_CATEGORY_HIERARCHY_ERROR:
            return {
                ...state,
                errors: action.payload
            }
        // case EDIT_CATEGORY:
        //     return {
        //         ...state,
        //         item: action.payload
        //     }
        default:
            return state;
    }
}