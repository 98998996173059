import React, { Component } from 'react'
import AddEditGeneralPromotionForm from './AddEditGeneralPromotionForm';
import { addEditGeneralPromotion, listGeneralPromotions } from './../actions/generalPromotions.actions';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { ADMIN_URL } from './../../../constants';
import Utils from './../../../utils/Utils';

let md5 = require('md5');

class EditGeneralPromotionPage extends React.Component {
	constructor(props) {
		super(props)

        this.state = {
			id: this.props.match.params.id,
			name: this.props.promotion.name ? this.props.promotion.name  : '',
			link: this.props.promotion.link ? this.props.promotion.link  : '',
			content: this.props.promotion.content ? this.props.promotion.content  : '',
			active_from: this.props.promotion.active_from ? this.props.promotion.active_from  : '',
			active_to: this.props.promotion.active_to ? this.props.promotion.active_to  : '',
			active: this.props.promotion.active? this.props.promotion.active : 0,
		}

	}

    componentDidMount() {
        this.props.listGeneralPromotions();

        if(this.props.promotion.id) {
            this.setState({
                id: this.props.match.params.id,
                name: this.props.promotion.name ? this.props.promotion.name  : '',
                link: this.props.promotion.link ? this.props.promotion.link  : '',
                content: this.props.promotion.content ? this.props.promotion.content  : '',
				active_from: this.props.promotion.active_from ? this.props.promotion.active_from  : '',
				active_to: this.props.promotion.active_to ? this.props.promotion.active_to  : '',
				active: this.props.promotion.active? this.props.promotion.active : 0,
            });
        }
    }

	componentWillReceiveProps(nextProps) {
        if(nextProps.promotion.id) {
            this.setState({
                id: nextProps.match.params.id,
                name: nextProps.promotion.name ? nextProps.promotion.name  : '',
                link: nextProps.promotion.link ? nextProps.promotion.link  : '',
                content: nextProps.promotion.content ? nextProps.promotion.content  : '',
				active_from: nextProps.promotion.active_from ? nextProps.promotion.active_from  : '',
				active_to: nextProps.promotion.active_to ? nextProps.promotion.active_to  : '',
                active: nextProps.promotion.active ? nextProps.promotion.active : 0,
            });
        }
    }
    
	onChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
	}

	onChangeCheckbox = (e) => {
		this.setState({ [e.target.name]: e.target.checked });
	}
    
    onFieldChange = (fieldName, value) => {
        this.setState({ [fieldName]: value });
    }
    
	onCKEditorChange = (fieldName, e) => {
        this.setState({
            [fieldName]: e.editor.getData()
        });
    }

	onSubmit = (e) => {
		e.preventDefault();

// return;
		const post = {
			id: this.state.id,
            name: this.state.name,
            link: this.state.link,
            content: this.state.content,
			active_from: this.state.active_from,
			active_to: this.state.active_to,
			active: this.state.active,
		}
       
      
		this.props.addEditGeneralPromotion(post, this.props.history);

	}
	
    delete = (e, id) => {
        e.preventDefault();
        let data = {
            id: id,
            deleted: true
        };
        this.props.addEditGeneralPromotion(data);
    }
		

    render() {  
		return (
			<main className="main">
        
				{/* <!-- Breadcrumb--> */}
				<ol className="breadcrumb">
				<li className="breadcrumb-item"><NavLink to={ADMIN_URL}>{this.props.translations.common.dashboard}</NavLink></li>
					{/* <li className="breadcrumb-item">Меню</li> */}
					<li className="breadcrumb-item"><NavLink to={ADMIN_URL + "general-promotions"}>{this.props.translations.general_promotions.title}</NavLink></li>
					<li className="breadcrumb-item active">{this.props.translations.general_promotions.edit}</li>
					{/* <!-- Breadcrumb Menu--> */}
					<li className="breadcrumb-menu d-md-down-none">
						<div className="btn-group" role="group" aria-label="Button group">

						</div>
					</li>
				</ol>

        		<div className="container-fluid">
           			<div className="animated fadeIn">
              			<div className="row">
                
							{/* <!-- /.col--> */}                
							<div className="col-sm-12">
								<div className="card">

									<AddEditGeneralPromotionForm
										onSubmit={this.onSubmit}
										onChange={this.onChange}
                                        onChangeCheckbox={this.onChangeCheckbox}
                                        onFieldChange={this.onFieldChange}
										onCKEditorChange={this.onCKEditorChange}
										promotion={this.state}
										deleteFunc={this.delete}
										isAdding={false}
										translations={this.props.translations}
									/>
								</div>
							</div>
				  
				  		</div>
				  	</div>
				</div>

			</main>
    	)
  	}
}

const mapStateToProps = (state, ownProps) => {
  	let promotionId = ownProps.match.params.id;

	let promotion = state.generalPromotions.generalPromotions[promotionId];
	
	if (!promotion) promotion = { 
		id: '',
		name: '',
		link: '',
		content: '',
		active: '',
	};
	return {
		promotion: promotion,
		
		translations: state.lang,
		languages: state.languages.languages || {},
		currLang: state.settings.settings['default_lang'] && state.settings.settings['default_lang'].value || 'bg',
	
	}


};

export default connect(mapStateToProps, { addEditGeneralPromotion, listGeneralPromotions })(EditGeneralPromotionPage);