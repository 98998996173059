import axios from 'axios';
import { VIEW_VERSION_OPENED, LIST_VERSIONS } from './types';
import { API_URL } from './../../../constants';
// import { addSuccessMessage, addErrorMessages } from './../../messages/messages.actions';
// import { changeLanguage } from './../../../actions/languagesActions';
// import Auth from './../../../utils/Auth';
// import jwtDecode from 'jwt-decode';
// import { showLoading, hideLoading } from './../../common/loading/loading.actions';
// import moment from 'moment';

export const listVersions = () => dispatch => {
    axios
        .get(API_URL + 'versions')
        .then(res => {
            dispatch({
                type: LIST_VERSIONS,
                payload: res.data
            });
        })
        .catch(error => {
            throw (error);
        });
}

export const updateVersion = (data) => dispatch => {
    axios
        .post(API_URL + 'versions', data)
        .then(res => {
            dispatch(listVersions());
        })
        .catch(error => {
            throw (error);
        });
}

// addVersion TODO

export const viewVersionOpened = (data) => dispatch => {
    dispatch({
        type: VIEW_VERSION_OPENED,
        payload: data
    });
}