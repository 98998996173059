import { LIST_WAREHOUSE_SYSTEMS } from '../actions/types';

const initialState = {
    warehouseSystems: []
}

export default function(state = initialState, action) {
    switch(action.type) {
        case LIST_WAREHOUSE_SYSTEMS: {
            return {
                ...state,
                warehouseSystems: action.payload,
            }
        }
           
        default:
            return state;
    }
}