

import axios from 'axios';
import { LIST_RESTAURANT_HALLS } from './types';
import { API_URL, ADMIN_URL } from '../../../constants';
import { addSuccessMessage, addErrorMessages } from '../../messages/messages.actions';
// import { changeLanguage } from './../../../actions/languagesActions';
import Auth from '../../../utils/Auth';
// import jwtDecode from 'jwt-decode';
// import { showLoading, hideLoading } from './../../common/loading/loading.actions';
// import moment from 'moment';

export const listRestaurantHalls = () => dispatch => {
    let query = '';
    query += "?place_id=" + Auth.getUserPlace();

    axios
        .get(API_URL + 'restaurantHalls' + query)
        .then(res => {
            dispatch({
                type: LIST_RESTAURANT_HALLS,
                payload: res.data
            });
        })
        .catch(error => {
            dispatch(addErrorMessages(error));
        });
}

export const addEditRestaurantHalls = (data) => dispatch => {
    return axios
        .post(API_URL + 'restaurantHalls', data)
        // .then(res => {
        //     dispatch(listRestaurantHalls());
        // })
        // .catch(error => {
        //     dispatch(addErrorMessages(error));
        // });
}
