import { LIST_TABLES, LIST_TABLES_FOR_ORDERS, ADD_EDIT_TABLE_ERROR } from '../actions/types';

const initialState = {
    tables: [],
    tables_for_orders: [],
    errors: [],
}

export default function (state = initialState, action) {
    switch (action.type) {
        case LIST_TABLES:
            return {
                ...state,
                tables: action.payload,
                errors: [] // Reset add/edit table error
            }
        case LIST_TABLES_FOR_ORDERS:
            return {
                ...state,
                tables_for_orders: action.payload,
                errors: [] // Reset add/edit table error
            }
        case ADD_EDIT_TABLE_ERROR:
            return {
                ...state,
                errors: action.payload
            }
        default:
            return state;
    }
}