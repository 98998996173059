import React, { Component } from 'react';
import AddEditCategoryHierarchyForm from './AddEditCategoryHierarchyForm';
import PromoCategoryProductsTab from './edit/PromoCategoryProductsTab';
import ImagesCategoryTab from './edit/ImagesCategoryTab';
import { editCategoryHierarchy, removeCategoryHierarchy, listCategoriesHierarchy, addEditCatHierarchyTranslation } from '../../actions/categoriesHierarchyActions';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { ADMIN_URL, TRANSLATABLE_FIELDS } from '../../constants';
import { addErrorMessages } from './../messages/messages.actions';
import { Tabs, Tab } from 'react-bootstrap';
import moment from 'moment';
import Utils from '../../utils/Utils';
import BookingTab from './edit/BookingTab';
import CategoryVideoTab from './edit/CategoryVideoTab';
import CategoryVideoTabChunked from './edit/CategoryVideoTabChunked';
import Auth from '../../utils/Auth';

class EditCategoryHierarchyPage extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			currLang: this.props.currLang,
			id: this.props.match.params.id,
			name: this.props.cat.translations[this.props.currLang] && this.props.cat.translations[this.props.currLang].name
				? this.props.cat.translations[this.props.currLang].name : this.props.cat.name,
			description: this.props.cat.description ? this.props.cat.description : '',
			code: this.props.cat.code ? this.props.cat.code : '',
			icon: this.props.cat.icon ? this.props.cat.icon : '',
			background_image: this.props.cat.background_image ? this.props.cat.background_image : '',
			active: this.props.cat.active ? this.props.cat.active : 0,
			open_call_waiter: this.props.cat.open_call_waiter ? this.props.cat.open_call_waiter : 0,
			parentId: this.props.cat.parentId ? this.props.cat.parentId : 0,
			type: this.props.cat.type ? this.props.cat.type : 'default',
			links: this.props.cat.links ? this.props.cat.links : '',
			activeDaysOfWeek: this.props.cat.activeDaysOfWeek ? this.props.cat.activeDaysOfWeek : { "1": 1, "2": 1, "3": 1, "4": 1, "5": 1, "6": 1, "7": 1 },
			activeStartTime: this.props.cat.activeStartTime ? this.props.cat.activeStartTime : "00:00:00",
			activeEndTime: this.props.cat.activeEndTime ? this.props.cat.activeEndTime : "23:59:59",
			currPlaces: [],
			rotation_start_date: "",
			rotation_days_count: "",
			show_cat_only_for_spot_orders: 0,
			hide_cat_only_for_spot_orders: 0,
			hide_cat_only_for_delivery_orders: 0,
			show_cat_only_for_delivery_orders: 0,
			show_only_for_spot_browse: 0,
			hide_only_for_spot_browse: 0,
			promo_title: this.props.cat.translations[this.props.currLang] && this.props.cat.translations[this.props.currLang].promo_title
				? this.props.cat.translations[this.props.currLang].promo_title : this.props.cat.promo_title,
			video_title: this.props.cat.translations[this.props.currLang] && this.props.cat.translations[this.props.currLang].video_title
				? this.props.cat.translations[this.props.currLang].video_title : this.props.cat.video_title,
			video_description: this.props.cat.translations[this.props.currLang] && this.props.cat.translations[this.props.currLang].video_description
				? this.props.cat.translations[this.props.currLang].video_description : this.props.cat.video_description,

		}

	}

	componentWillMount() {
		this.props.listCategoriesHierarchy();
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.cat.id) {
			let currPlaces = [];
			if (nextProps.cat.places.length > 0) {
				nextProps.cat.places.map(pId => {
					let currPlace = nextProps.places.find(p => p.id == pId);
					let currPlaceName = currPlace ? currPlace.name : "";
					currPlaces.push({ value: parseInt(pId), label: currPlaceName })
				})
			}

			this.setState({
				id: nextProps.match.params.id,
				// name: nextProps.cat.name,
				name: nextProps.cat.translations[this.state.currLang] && nextProps.cat.translations[this.state.currLang].name
					? nextProps.cat.translations[this.state.currLang].name : nextProps.cat.name,
				description: nextProps.cat.description ? nextProps.cat.description : '',
				icon: nextProps.cat.icon ? nextProps.cat.icon : '',
				background_image: nextProps.cat.background_image ? nextProps.cat.background_image : '',
				code: nextProps.cat.code ? nextProps.cat.code : '',
				active: nextProps.cat.active ? nextProps.cat.active : 0,
				open_call_waiter: nextProps.cat.open_call_waiter ? nextProps.cat.open_call_waiter : 0,
				parentId: nextProps.cat.parentId ? nextProps.cat.parentId : 0,
				type: nextProps.cat.type ? nextProps.cat.type : 'default',
				links: nextProps.cat.links ? nextProps.cat.links : '',
				activeDaysOfWeek: nextProps.cat.activeDaysOfWeek ? nextProps.cat.activeDaysOfWeek : { "1": 1, "2": 1, "3": 1, "4": 1, "5": 1, "6": 1, "7": 1 },
				activeStartTime: nextProps.cat.activeStartTime ? nextProps.cat.activeStartTime : "00:00:00",
				activeEndTime: nextProps.cat.activeEndTime ? nextProps.cat.activeEndTime : "23:59:59",
				currPlaces: currPlaces,
				rotation_start_date: nextProps.cat.rotation_start_date && moment(nextProps.cat.rotation_start_date).toDate() || "",
				rotation_days_count: nextProps.cat.rotation_days_count || "",
				show_cat_only_for_spot_orders: nextProps.cat.show_cat_only_for_spot_orders || 0,
				hide_cat_only_for_spot_orders: nextProps.cat.hide_cat_only_for_spot_orders || 0,
				hide_cat_only_for_delivery_orders: nextProps.cat.hide_cat_only_for_delivery_orders || 0,
				show_cat_only_for_delivery_orders: nextProps.cat.show_cat_only_for_delivery_orders || 0,
				show_only_for_spot_browse: nextProps.cat.show_only_for_spot_browse || 0,
				hide_only_for_spot_browse: nextProps.cat.hide_only_for_spot_browse || 0,
				promo_title: nextProps.cat.translations[this.state.currLang] && nextProps.cat.translations[this.state.currLang].promo_title
					? nextProps.cat.translations[this.state.currLang].promo_title : '',
				video_title: nextProps.cat.translations[this.state.currLang] && nextProps.cat.translations[this.state.currLang].video_title
					? nextProps.cat.translations[this.state.currLang].video_title : nextProps.cat.video_title,
				video_description: nextProps.cat.translations[this.state.currLang] && nextProps.cat.translations[this.state.currLang].video_description
					? nextProps.cat.translations[this.state.currLang].video_description : nextProps.cat.video_description,

			});
		}

	}

	onChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
	}


	// this.setState(function (previousState, currentProps) {
	// 	return {
	// 		value: previousState.value + 1
	// 	};
	// });



	onChangeLink = (e, i) => {
		let target = e.target;
		/*d*/
		this.setState(state => {
			let stateLinks = state.links && Object.values(state.links).length > 0 ? JSON.parse(state.links) : [];

			if (!stateLinks[i]) {
				stateLinks[i] = {}
			}

			stateLinks[i][target.name] = target.value;
			console.log("LINKS", stateLinks)
			return ({ links: JSON.stringify(stateLinks) })
		});
	}

	removeLink = () => {
		let stateLinks = this.state.links && Object.values(this.state.links).length > 0 ? JSON.parse(this.state.links) : [];

		if (stateLinks && Object.values(stateLinks).length > 0 && stateLinks[Object.values(stateLinks).length - 1]) {
			stateLinks.splice(Object.values(stateLinks).length - 1, 1);
			this.setState({ links: JSON.stringify(stateLinks) })
		}
	}

	// this.setState(state => ({
	// 	currPage: state.currPage - 1
	// }))


	onChangeCheckbox = (e) => {
		this.setState({ [e.target.name]: e.target.checked });
	}

	onChangeSelectAutocompleteCategory = (valueLabel) => {
		this.setState({ 'parentId': valueLabel.value });
	}

	onChangeSelectAutocompleteType = (valueLabel) => {
		this.setState({ 'type': valueLabel.value });
	}

	onChangeCheckboxActiveDaysOfWeek = (e) => {
		let currActiveDaysOfWeek = this.state.activeDaysOfWeek;
		currActiveDaysOfWeek[e.target.name] = +e.target.checked;
		this.setState({ currActiveDaysOfWeek });
	}

	onChangeSelectAutocompletePlaces = (valueLabel) => {
		this.setState({ 'currPlaces': valueLabel });
	}

	onFieldChange = (fieldName, value) => {
		this.setState({ [fieldName]: value });
	}

	onCKEditorChange = (e) => {
		this.setState({
			description: e.editor.getData()
		});
	}


	validate = () => {
		let errors = [];

		if (this.state.name.length === 0) {
			errors.push(this.props.translations.messages.errors.invalid_name);
		}
		if (!this.state.currPlaces || this.state.currPlaces.length === 0) {
			errors.push(this.props.translations.messages.errors.you_must_select_place);
		}

		return errors;
	}

	onSubmit = (e) => {
		e.preventDefault();

		const errors = this.validate();
		if (errors.length > 0) {
			this.props.addErrorMessages(errors);
			return;
		}

		let placesIds = this.state.currPlaces.map(c => c.value);

		const post = {
			id: this.state.id,
			name: this.state.name,
			description: this.state.description,
			code: this.state.code,
			icon: this.state.icon,
			background_image: this.state.background_image,
			active: this.state.active,
			open_call_waiter: this.state.open_call_waiter,
			parentId: this.state.parentId,
			type: this.state.type,
			links: this.state.links,
			activeDaysOfWeek: this.state.activeDaysOfWeek,
			activeStartTime: this.state.activeStartTime,
			activeEndTime: this.state.activeEndTime,
			placesIds: placesIds,
			rotation_start_date: moment(this.state.rotation_start_date).format('YYYY-MM-DD'),
			rotation_days_count: this.state.rotation_days_count,
			show_cat_only_for_spot_orders: this.state.show_cat_only_for_spot_orders,
			hide_cat_only_for_spot_orders: this.state.hide_cat_only_for_spot_orders,
			hide_cat_only_for_delivery_orders: this.state.hide_cat_only_for_delivery_orders,
			show_cat_only_for_delivery_orders: this.state.show_cat_only_for_delivery_orders,
			show_only_for_spot_browse: this.state.show_only_for_spot_browse,
			hide_only_for_spot_browse: this.state.hide_only_for_spot_browse,
		}
		// Image append
		const files = Array.from(document.querySelector('#multi').files)
		let formData = new FormData()

		files.forEach((file, i) => {
			formData.append("file", file)
		})

		if(Auth.hasAccess("SUPER_ADMIN")) {
			// Image append
			const files1 = Array.from(document.querySelector('#multi1').files)

			files1.forEach((file, i) => {
				formData.append("file_bckg", file)
			})

			if (files.length == 0 && files1.length == 0) {
				formData = null;
			}
		} else {
			if (files.length == 0) {
				formData = null;
			}
		}

		this.props.editCategoryHierarchy(post, this.props.history, formData, this.props.languages[this.state.currLang].id);

	}

	deleteCategory = (e, id) => {
		e.preventDefault();

		const post = {
			id: id,
			deleted: 1
		}
		this.props.editCategoryHierarchy(post, this.props.history);

		// this.props.removeCategory(id, this.props.history);
	}

	currLangOnChange = (e) => {
		let newLang = e.target.value;

		let newState = {
			currLang: e.target.value
		};

		TRANSLATABLE_FIELDS.CATEGORY.map(field => {
			newState[field] = this.props.cat.translations[newLang] && this.props.cat.translations[newLang][field] ? this.props.cat.translations[newLang][field] : "";
		})

		this.setState(newState)
	}

	render() {
		// let catItem = this.props.cat;

		return (
			<main className="main">

				{/* <!-- Breadcrumb--> */}
				<ol className="breadcrumb">
					<li className="breadcrumb-item"><NavLink to={ADMIN_URL}>{this.props.translations.common.dashboard}</NavLink></li>
					{/* <li className="breadcrumb-item">Меню</li> */}
					<li className="breadcrumb-item"><NavLink to={ADMIN_URL + "categories-hierarchy"}>{this.props.translations.categories.title}</NavLink></li>
					<li className="breadcrumb-item active">{this.props.translations.categories.edit}</li>
					<li className="breadcrumb-menu d-md-down-none">
						<div className="btn-group" role="group" aria-label="Button group">
							<NavLink to={ADMIN_URL + `categories-hierarchy/add`} className="btn" >
								<i className="fas fa-plus"></i> &nbsp; {this.props.translations.categories.add}
							</NavLink>
						</div>
					</li>
				</ol>

				<div className="container-fluid">
					<div className="animated fadeIn">
						<div className="row">

							{/* <!-- /.col--> */}
							<div className="col-sm-12">
								<div className="card">

									<Tabs
										style={{ width: '100%' }}
										id="controlled-tab-example"
										activeKey={this.state.currentTab}
										onSelect={(k) => this.setState({ currentTab: k })}
									>
										<Tab eventKey="edit" title={this.props.translations.categories.tabs.edit}>
											<AddEditCategoryHierarchyForm
												onSubmit={this.onSubmit}
												onChange={this.onChange}
												onChangeLink={this.onChangeLink}
												removeLink={this.removeLink}
												onCKEditorChange={this.onCKEditorChange}
												onChangeCheckbox={this.onChangeCheckbox}
												onChangeSelectAutocompleteCategory={this.onChangeSelectAutocompleteCategory}
												onChangeSelectAutocompleteType={this.onChangeSelectAutocompleteType}
												onChangeCheckboxActiveDaysOfWeek={this.onChangeCheckboxActiveDaysOfWeek}
												onChangeSelectAutocompletePlaces={this.onChangeSelectAutocompletePlaces}
												onFieldChange={this.onFieldChange}
												category={this.state}
												type={this.state.type}
												links={this.state.links}
												icon={this.state.icon}
												background_image={this.state.background_image}
												categoryId={this.state.id}
												deleteCategory={this.deleteCategory}
												isAddingCategory="0"
												translations={this.props.translations}
												currLang={this.state.currLang}
												currLangOnChange={this.currLangOnChange}
												languages={this.props.languages}
												categoriesHierarchy={this.props.categoriesHierarchy}
												places={this.props.places}
											/>
										</Tab>

										<Tab eventKey="promo" title={this.props.translations.categories.tabs.promo_products_for_category}>
											<PromoCategoryProductsTab
												cat={this.props.cat}
												currLangOnChange={this.currLangOnChange}
												currLang={this.state.currLang}
												languages={this.props.languages}
												categoryState={this.state}
												onFieldChange={this.onFieldChange}
											/>
										</Tab>
										{this.state.type == 'image' ?
											<Tab eventKey="images_category" title={this.props.translations.categories.tabs.images_category}>
												<ImagesCategoryTab
													cat={this.props.cat}
													history={this.props.history}
												/>
											</Tab>
											:
											null
										}
										{Utils.hasFunctionalityModule('booking') ?
											<Tab eventKey="booking" title={this.props.translations.categories.tabs.booking}>
												<BookingTab
													category={this.props.cat}
												/>
											</Tab>
											:
											null
										}

										<Tab eventKey="video" title={this.props.translations.categories.tabs.video}>
											{/* <CategoryVideoTab
													category={this.props.cat}
												/> */}

											<CategoryVideoTabChunked
												category={this.state}
												translations={this.props.translations}
												currLangOnChange={this.currLangOnChange}
												currLang={this.state.currLang}
												languages={this.props.languages}
												onFieldChange={this.onFieldChange}
											/>
										</Tab>

									</Tabs>



								</div>
							</div>

						</div>
					</div>
				</div>

			</main>
		)
	}
}

const mapStateToProps = (state, ownProps) => {
	let catId = ownProps.match.params.id;
	let cat = state.categoriesHierarchy.categories[catId];
	if (!cat) cat = {
		id: '',
		name: '',
		description: '',
		icon: '',
		background_image: '',
		active: '',
		open_call_waiter: 0,
		translations: {},
		parentId: '',
		type: 'default',
		links: '',
		activeDaysOfWeek: { "1": 1, "2": 1, "3": 1, "4": 1, "5": 1, "6": 1, "7": 1 },
		activeStartTime: "00:00:00",
		activeEndTime: "23:59:59",
		imageCategoryImages: [],
		places: [],
		rotation_start_date: '',
		rotation_days_count: '',
		show_cat_only_for_spot_orders: 0,
		hide_cat_only_for_spot_orders: 0,
		hide_cat_only_for_delivery_orders: 0,
		show_cat_only_for_delivery_orders: 0,
		show_only_for_spot_browse: 0,
		hide_only_for_spot_browse: 0
	};

	return {
		categoriesHierarchy: state.categoriesHierarchy.categories || [],
		cat: cat,
		// errors: state.categories.errors,
		translations: state.lang,
		languages: state.languages.languages || {},
		currLang: state.settings.settings['default_lang'] && state.settings.settings['default_lang'].value || "bg",
		translationsType: state.settings.settings['translations_type'] && state.settings.settings['translations_type'].value || "",
		places: state.places.places || []
	}
};

export default connect(mapStateToProps, { editCategoryHierarchy, removeCategoryHierarchy, addErrorMessages, listCategoriesHierarchy, addEditCatHierarchyTranslation })(EditCategoryHierarchyPage);