import React, { Component } from 'react';
import { addProduct, bulkAddProducts } from '../../actions/productsActions';
import { connect } from 'react-redux';
import AddEditProductForm from './AddEditProductForm';
import { NavLink } from 'react-router-dom';
import { ADMIN_URL } from '../../constants';
import { addErrorMessages } from './../messages/messages.actions';
import Select from 'react-select'
import Utils from './../../utils/Utils';
import MessagesContainer from './../messages/MessagesContainer';

class AddBulkProductsPage extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            productsToAdd: [
                {
                    name: "",
                    description: "",
                    category: "",
                    size: "",
                    price: "",
                    image: ""
                }
            ]
        }

    }

    componentWillMount() {
    }

    validate = () => {
        let errors = {};

        this.state.productsToAdd.map(prod => {
            if (prod.name.length === 0) {
                errors['name'] = this.props.translations.messages.errors.invalid_name;
            }
            if (prod.category.length === 0) {
                errors['category'] = this.props.translations.messages.errors.invalid_category;
            }
            if (prod.size.length === 0) {
                errors['size'] = this.props.translations.messages.errors.invalid_size;
            }
            if (prod.price.length === 0) {
                errors['price'] = this.props.translations.messages.errors.invalid_price;
            }
        });

        errors = Object.values(errors);

        if (this.state.productsToAdd.length == 0) {
            errors.push(this.props.translations.messages.errors.no_data_to_insert)
        }
        return errors;
    }

    onRowChange = (e, index) => {
        const { name, value } = e.target;

        const list = [...this.state.productsToAdd];
        list[index][name] = value;
        this.setState({
            productsToAdd: list
        })
    }

    onChangeSelectAutocompleteCategory = (index, valueLabel) => {
        const list = [...this.state.productsToAdd];
        list[index]["category"] = valueLabel;
        this.setState({
            productsToAdd: list
        })
    }

    addRow = () => {

        if (this.state.productsToAdd.length >= 10) {
            this.props.addErrorMessages([this.props.translations.messages.errors.reached_max_rows_save_to_proceed]);
            return;
        }

        let prod = {
            name: "",
            description: "",
            category: "",
            size: "",
            price: "",
            image: ""
        }

        let prodsToAdd = this.state.productsToAdd;
        prodsToAdd.push(prod);

        this.setState({
            productsToAdd: prodsToAdd
        })
    }

    removeRow = (ind) => {
        let productsToAdd = this.state.productsToAdd;

        productsToAdd.splice(ind, 1);

        this.setState({
            productsToAdd: productsToAdd
        })
    }

    save = () => {
        let postData = [];

        const errors = this.validate();
        if (errors.length > 0) {
            this.props.addErrorMessages(errors);
            return;
        }
        let dataToSend = new FormData()
        let productsData = [];
        this.state.productsToAdd.map((prod, index) => {
            // let formData = new FormData()

            const images = Array.from(document.querySelector('#image_' + index).files)
            images.forEach((image, i) => {
                dataToSend.append("image_" + index, image)
            })

            // formData.append("name", prod.name);
            // formData.append("description", prod.description);
            // formData.append("size", prod.size);
            // formData.append("price", prod.price);
            // formData.append("catHierarchyIds", prod.category.map(c => c.value));
            prod.catHierarchyIds = prod.category.map(c => c.value);

            // console.log("PRAZNA "+ formData.entries())
            // prod.catHierarchyIds = prod.category.map(c => c.value);
            // dataToSend.append("prod"+index,  (formData));
            dataToSend.append("product_" + index, JSON.stringify(prod));

            // productsData.push(prod);
        })
        // Image append


        // files.forEach((file, i) => {
        //     formData.append("file", file)
        // })
        // if (files.length == 0) {
        //     formData = null;
        // }
        this.props.bulkAddProducts(dataToSend, this.props.history);

    }

    applyValuesFromRecordToRecord(from, to, name, value) {
        for (from; from < to; from++) {
            if (name == 'category') {
                this.onChangeSelectAutocompleteCategory(from, value);
            } else {
                this.onRowChange({ target: { name: name, value: value } }, from);
            }
        }
    }


    render() {
        return (

            <main className="main">

                {/* <!-- Breadcrumb--> */}
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><NavLink to={ADMIN_URL}>{this.props.translations.common.dashboard}</NavLink></li>
                    {/* <li className="breadcrumb-item">Меню</li> */}
                    <li className="breadcrumb-item"><NavLink to={ADMIN_URL + "products"}>{this.props.translations.products.title}</NavLink></li>
                    <li className="breadcrumb-item active">{this.props.translations.products.add_bulk}</li>
                    {/* <!-- Breadcrumb Menu--> */}
                    <li className="breadcrumb-menu d-md-down-none">
                        <div className="btn-group" role="group" aria-label="Button group">
                        </div>
                    </li>
                </ol>

                <div className="container-fluid">
                    <div className="animated fadeIn">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="card">
                                    <h3>{this.props.translations.products.add_bulk}</h3>
                                    <br />
                                    <MessagesContainer />

                                    <table className="table table-responsive-sm table-striped">
                                        <thead>
                                            <tr>
                                                <th style={{ width: "15%" }}>{this.props.translations.common.name}</th>
                                                <th style={{ width: "20%" }}>{this.props.translations.common.description}</th>
                                                <th style={{ width: "20%" }}>{this.props.translations.common.category} &nbsp; <input style={{ verticalAlign: 'middle' }} type="checkbox" title="Копирай 1вия ред за всички останали" onChange={(e) => e.target.checked ? this.applyValuesFromRecordToRecord(1, Object.keys(this.state.productsToAdd).length, 'category', this.state.productsToAdd[0].category) : void (0)} /> </th>
                                                <th style={{ width: "10%" }}>{this.props.translations.common.size} &nbsp; <input style={{ verticalAlign: 'middle' }} type="checkbox" title="Копирай 1вия ред за всички останали" onChange={(e) => e.target.checked ? this.applyValuesFromRecordToRecord(1, Object.keys(this.state.productsToAdd).length, 'size', this.state.productsToAdd[0].size) : void (0)} /> </th>
                                                <th style={{ width: "10%" }}>{this.props.translations.common.price} &nbsp; <input style={{ verticalAlign: 'middle' }} type="checkbox" title="Копирай 1вия ред за всички останали" onChange={(e) => e.target.checked ? this.applyValuesFromRecordToRecord(1, Object.keys(this.state.productsToAdd).length, 'price', this.state.productsToAdd[0].price) : void (0)} /> </th>
                                                <th style={{ width: "10%" }}>{this.props.translations.common.additional_price} &nbsp; <input style={{ verticalAlign: 'middle' }} type="checkbox" title="Копирай 1вия ред за всички останали" onChange={(e) => e.target.checked ? this.applyValuesFromRecordToRecord(1, Object.keys(this.state.productsToAdd).length, 'additional_price', this.state.productsToAdd[0].additional_price) : void (0)} /> </th>
                                                <th style={{ width: "10%" }}>{this.props.translations.common.image}</th>
                                                <th style={{ width: "5%" }}></th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {(this.state.productsToAdd).map((prod, index) => (
                                                <tr key={index}>
                                                    <td>
                                                        <textarea className={`form-control`} type="text" name="name" onChange={(e) => this.onRowChange(e, index)}>
                                                            {prod.name}
                                                        </textarea>
                                                        {/* <input type="text" name="name" value={prod.name} onChange={(e) => this.onRowChange(e, index)} className="form-control" /> */}
                                                    </td>
                                                    <td>
                                                        <textarea className={`form-control`} type="text" name="description" onChange={(e) => this.onRowChange(e, index)}>
                                                            {prod.description}
                                                        </textarea>
                                                        {/* <input type="text" name="description" value={prod.description} onChange={(e) => this.onRowChange(e, index)} className="form-control" /> */}
                                                    </td>
                                                    <td>
                                                        <Select
                                                            value={prod.category}
                                                            isMulti
                                                            // name="colors"
                                                            options={this.props.categoriesHierarchyOptions}
                                                            className="basic-multi-select"
                                                            classNamePrefix="select"
                                                            onChange={(valueLabel) => this.onChangeSelectAutocompleteCategory(index, valueLabel)}
                                                        />
                                                    </td>
                                                    <td>
                                                        <input type="text" name="size" value={prod.size} onChange={(e) => this.onRowChange(e, index)}
                                                            className="form-control" style={{ width: 100 }} />
                                                    </td>
                                                    <td>
                                                        <input type="number" name="price" value={prod.price} onChange={(e) => this.onRowChange(e, index)}
                                                            className="form-control" style={{ width: 70 }} />
                                                    </td>
                                                    <td>
                                                        <input type="number" name="additional_price" value={prod.additional_price} onChange={(e) => this.onRowChange(e, index)}
                                                            className="form-control" style={{ width: 70 }} />
                                                    </td>
                                                    <td>
                                                        <input type="file" name="image" id={"image_" + index} style={{ width: 150 }} />
                                                    </td>
                                                    <td>
                                                        <button className="btn btn-danger" onClick={() => this.removeRow(index)}>x</button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>

                                </div>

                                <MessagesContainer />

                                <button className="btn btn-default" onClick={this.addRow}>{this.props.translations.common.add_row}</button>
                                <button className="btn btn-primary" onClick={this.save} style={{ float: 'right' }}>{this.props.translations.common.save}</button>

                            </div>

                        </div>
                    </div>
                </div>

            </main>
        )
    }
}

const mapStateToProps = state => {
    let categoriesHierarchyOptions = Utils.getOptionsForAutocompleteSelect(state.categoriesHierarchy.categories);

    return {
        categoriesHierarchy: state.categoriesHierarchy.categories,
        categoriesHierarchyOptions: categoriesHierarchyOptions,
        errors: state.products.errors,
        translations: state.lang,
        languages: state.languages.languages || {},
        currLang: "bg",
    }
};

export default connect(mapStateToProps, { bulkAddProducts, addErrorMessages })(AddBulkProductsPage);