import { LIST_PROFORMS } from '../actions/types';

const initialState = {
    proforms: [],
}

export default function(state = initialState, action) {
    switch(action.type) {
        case LIST_PROFORMS: {
            return {
                ...state,
                proforms: action.payload,
            }
        }
        default:
            return state;
    }
}