import React, { Component } from 'react'
import { NavLink } from 'react-router-dom';
import { ADMIN_URL, PRODUCT_IMG_URL } from '../../../constants.js'
import DeleteAlergenModal from './DeleteAlergenModal';
import MessagesContainer from '../../messages/MessagesContainer';
import Utils from '../../../utils/Utils.js';
import LangDropdown from './../../common/LangDropdown';

class AddEditCategoryForm extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const props = this.props;

        return (
            <form onSubmit={props.onSubmit}>
                <MessagesContainer />
                <div className="card-body">

                    <LangDropdown
                        isDisabled={props.isAdding == 1 ? 1 : ""}
                        currLangOnChange={props.currLangOnChange}
                        currLang={props.currLang}
                        languages={props.languages}
                    />

                    <div className="row">
                        <div className="col-sm-12">
                            <div className="form-group">
                                <label htmlFor="name">{props.translations.common.alergens}</label>
                                <input className={`form-control ${props.nameError.message ? "is-invalid" : ""}`} id="name" type="text" placeholder={props.translations.common.name} name="name" onChange={props.onChange} value={props.alergenName} />
                                <div className="invalid-feedback">{props.nameError.message ? props.nameError.message : props.nameError}</div>
                            </div>
                        </div>
                    </div>

                    {/* <div className="row">
                        <div className="col-sm-12">
                            <div className="form-group">
                                <label htmlFor="code">{props.translations.common.code}</label>
                                <input className={`form-control`} id="code" type="text" placeholder={props.translations.common.code} name="code" onChange={props.onChange} value={props.categoryCode} />
                            </div>
                        </div>
                    </div> */}

                    <div className="row">
                        <div className="col-sm-12">
                            <div className="form-group">
                                <label htmlFor="icon">{props.translations.common.icon}</label>
                                <input className={`form-control`} id="icon" type="text" placeholder={props.translations.common.image} name="image" onChange={props.onChange} value={props.alergenImage} />
                                {/* <div className="invalid-feedback">{props.nameError.message ? props.nameError.message : props.nameError }</div>  */}
                                {/* <input id="image" type='file' name='file' id='multi' multiple ref="image"/>{editImage} */}
                            </div>
                        </div>
                    </div>

                    <hr />
                    <br />

                    <div className="form-actions">
                        {props.isAdding == '1' ? '' : <button className="btn btn-danger mright10" type="button" data-toggle="modal" data-target={"#dangerModal" + props.alergenId} >{props.translations.common.delete}</button>}


                        <DeleteAlergenModal
                            alergenId={props.alergenId}
                            alergenName={props.alergenName}
                            deleteAlergen={props.deleteAlergen}
                            translations={props.translations}
                        />

                        <NavLink to={ADMIN_URL + 'alergens'}>
                            <button className="btn btn-secondary" type="button">
                                {props.translations.common.cancel}
                            </button>
                        </NavLink>

                        <button className="fright btn btn-primary" type="submit">
                            {props.isAdding == '1' ? props.translations.common.add : props.translations.common.save}
                            {/* {props.actionText} */}
                        </button>

                    </div>

                </div>

            </form>
        )
    }
}

export default AddEditCategoryForm;