import React, { Component } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { NavLink } from 'react-router-dom';
import Pagination from "react-js-pagination";
import { push } from 'react-router-redux';

import { ADMIN_URL, ITEMS_PER_PAGE, ITEMS_PAGE_RANGE_DISPLAYED } from '../../../../constants';
import { listSettings, listSettingsCategories } from './../../actions/settings.actions';
import { listOrdertypeDiscounts, addOrdertypeDiscounts } from './../../actions/ordertypes.actions';
import { listOrderTypes } from './../../../places/actions/places.actions';
import { addErrorMessages } from './../../../messages/messages.actions';

import MessagesContainer from './../../../messages/MessagesContainer';
import queryString from 'query-string'
import Auth from '../../../../utils/Auth';

class OrdertypePricesPage extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            ordertypeDiscounts: [
                {
                    price_over: "",
                    value: "",
                    value_type: "",
                    active_start_time: "",
                    active_end_time: "",
                    activeDaysOfWeek: { "1": 1, "2": 1, "3": 1, "4": 1, "5": 1, "6": 1, "7": 1 },
                }
            ]
        }
    }

    componentWillMount() {
        this.props.listOrdertypeDiscounts(this.props.orderType);
        this.props.listOrderTypes();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.ordertypeDiscounts) {
            let ordertypeDiscounts = [];
            nextProps.ordertypeDiscounts.sort((a, b) => (a.price_over - b.price_over)).map(t => {
                ordertypeDiscounts.push({
                    price_over: t.price_over,
                    value: t.value,
                    value_type: t.value_type,
                    placeId: t.placeId,
                    ordertypeId: t.ordertypeId,
                    active_start_time: t.active_start_time,
                    active_end_time: t.active_end_time,
                    activeDaysOfWeek: t.activeDaysOfWeek
                });
            })

            this.setState({
                ordertypeDiscounts: ordertypeDiscounts
            })
        }
    }

    onRowChange = (e, index) => {
        let { name, value } = e.target;

        if (name == 'active_end_time' && value == '23:59') {
            value = '23:59:59';
        }

        const list = [...this.state.ordertypeDiscounts];
        list[index][name] = value;
        this.setState({
            ordertypeDiscounts: list
        })
    }

    onChangeCheckboxActiveDaysOfWeek = (e, index) => {
        const list = [...this.state.ordertypeDiscounts];
        console.log(list)

		let currActiveDaysOfWeek = list[index]['activeDaysOfWeek'] ;//this.state.activeDaysOfWeek;
        currActiveDaysOfWeek[e.target.name] = +e.target.checked;
        list[index]['activeDaysOfWeek'] = currActiveDaysOfWeek;
        
        this.setState({
            ordertypeDiscounts: list
        })
    }

    addRow = () => {
        let row = {
            price_over: "",
            value: "",
            value_type: "money",
            active_start_time: "00:00:00",
            active_end_time: "23:59:59",
            activeDaysOfWeek: { "1": 1, "2": 1, "3": 1, "4": 1, "5": 1, "6": 1, "7": 1 },
        }

        let ordertypeDiscounts = this.state.ordertypeDiscounts;
        ordertypeDiscounts.push(row);

        this.setState({
            ordertypeDiscounts: ordertypeDiscounts
        })
    }


    removeRow = (ind) => {
        let ordertypeDiscounts = this.state.ordertypeDiscounts;
        ordertypeDiscounts.splice(ind, 1);
        this.setState({
            ordertypeDiscounts: ordertypeDiscounts
        })
    }

    validate = () => {
        let errors = {};

        this.state.ordertypeDiscounts.map(t => {
            if (t.price_over.length == 0) {
                errors['price_over'] = this.props.translations.messages.errors.invalid_price_over;
            }
            if (t.value.length == 0) {
                errors['value'] = this.props.translations.messages.errors.invalid_price;
            }
        });

        return Object.values(errors);
    }

    save = () => {
        let postData = [];

        const errors = this.validate();
        if (errors.length > 0) {
            this.props.addErrorMessages(errors);
            return;
        }
        let data = [];

        this.state.ordertypeDiscounts.map((otPrice, index) => {
            otPrice.placeId = Auth.getUserPlace();
            otPrice.ordertypeId = this.props.orderTypeId;
            otPrice.activeDaysOfWeek = JSON.stringify(otPrice.activeDaysOfWeek);

            data.push(otPrice);
        })


        this.props.addOrdertypeDiscounts({ placeId: Auth.getUserPlace(), ordertypeId: this.props.orderTypeId, ordertypeDiscounts: data }, this.props.orderType);

    }

    render() {
        console.log(this.state.ordertypeDiscounts)

        return (
            <React.Fragment>
                <main className="main">

                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><NavLink to={ADMIN_URL}>{this.props.translations.common.dashboard}</NavLink></li>
                        <li className="breadcrumb-item"><NavLink to={ADMIN_URL + 'settings'}>{this.props.translations.sidemenu.settings}</NavLink></li>
                        <li className="breadcrumb-item"><NavLink to={ADMIN_URL + 'settings/category/6'}>{this.props.translations.sidemenu.orders}</NavLink></li>
                        <li className="breadcrumb-item">{this.props.translations.settings.ordertype_discounts[this.props.orderType]}</li>

                        {/* <li className="breadcrumb-item ">{this.props.translations.settings_categories && this.props.translations.settings_categories[(this.props.settingsCategoryName)] ? this.props.translations.settings_categories[(this.props.settingsCategoryName)] : this.props.settingsCategoryName}</li> */}
                    </ol>

                    <div className="container-fluid">
                        <div className="animated fadeIn">
                            <div className="row">

                                <div className="col-lg-12">
                                    <div className="card">
                                        <div className="card-body">

                                            <MessagesContainer />

                                            <table className="table table-responsive-sm table-striped">
                                                <thead>
                                                    <tr>
                                                        <th width="10%">
                                                            <span title={this.props.translations.common.price_over_info}>
                                                                {this.props.translations.common.price_over}
                                                                <span data-toggle="tooltip" data-placement="bottom"> 🛈</span>
                                                            </span>
                                                        </th>
                                                        <th width="10%">{this.props.translations.common.value}</th>
                                                        <th width="10%">{this.props.translations.common.value_type}</th>
                                                        <th width="10%">{this.props.translations.common.active_start_time}</th>
                                                        <th width="10%">{this.props.translations.common.active_end_time}</th>
                                                        <th width="40%">{this.props.translations.common.active_datys_of_week}</th>
                                                        <th width="10%">{this.props.translations.common.action}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    {(this.state.ordertypeDiscounts).map((otPrice, index) => (
                                                        <tr key={index}>
                                                            <td>
                                                                <input type="number" min="0" name="price_over" value={otPrice.price_over} onChange={(e) => this.onRowChange(e, index)} className="form-control" />
                                                            </td>
                                                            <td>
                                                                <input type="number" min="0" name="value" value={otPrice.value} onChange={(e) => this.onRowChange(e, index)} className="form-control" />
                                                            </td>
                                                            <td>
                                                                <select name="value_type" value={otPrice.value_type} onChange={(e) => this.onRowChange(e, index)} className="form-control">
                                                                    <option value="money">лв.</option>
                                                                    <option value="percent">%</option>
                                                                </select>
                                                                {/* <input type="number" name="price" value={otPrice.price} onChange={(e) => this.onRowChange(e, index)} className="form-control"/> */}
                                                            </td>
                                                            <td>
                                                                <label className="switch switch-label switch-pill switch-outline-primary-alt" style={{ width: '100%' }}>
                                                                    <input className={`form-control`} type="time" name="active_start_time" onChange={(e) => this.onRowChange(e, index)} value={otPrice.active_start_time} style={{ width: '100%' }} />
                                                                </label>                                                            
                                                            </td>
                                                            <td>
                                                                <label className="switch switch-label switch-pill switch-outline-primary-alt" style={{ width: '100%' }}>
                                                                    <input className={`form-control`} type="time" name="active_end_time" onChange={(e) => this.onRowChange(e, index)} value={otPrice.active_end_time} style={{ width: '100%' }} />
                                                                </label>                                                            
                                                            </td>
                                                            <td>
                                                            <div className="row">
                                                                
                                                                {[1,2,3,4,5,6,7].map(i => {
                                                                    let inpName = `activeDaysOfWeek.${i}`
                                                                    return (
                                                                        <React.Fragment>
                                                                            <div className="col-md-2 col-form-label">
                                                                                <label className=" " style={{ 'white-space': 'nowrap' }}>{this.props.translations.common.activeDaysOfWeek[i]}</label>
                                                                            
                                                                                <label className="switch switch-label switch-pill switch-outline-primary-alt">
                                                                                    <input className="switch-input" type="checkbox" name={i} onChange={(e) => this.onChangeCheckboxActiveDaysOfWeek(e, index)} checked={otPrice.activeDaysOfWeek[i]} /> {/*checked={JSON.parse(otPrice.activeDaysOfWeek)[i]}*/}
                                                                                    <span className="switch-slider" data-checked={this.props.translations.common.yes} data-unchecked={this.props.translations.common.no}></span>
                                                                                </label>
                                                                            </div>
                                                                        </React.Fragment>
                                                                    )
                                                                })}
                                                                        </div>
                                                                
                                                            </td>
                                                            <td>
                                                                <button className="btn btn-danger" onClick={() => this.removeRow(index)}>x</button>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>

                                        </div>
                                        <button className="btn btn-default" onClick={this.addRow}>{this.props.translations.common.add_row}</button>
                                        <button className="btn btn-primary" onClick={this.save} style={{ float: 'right' }}>{this.props.translations.common.save}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>

            </React.Fragment>

        )
    }
}

const mapStateToProps = (state, ownProps, dispatch) => {
    const query = queryString.parse(state.router.location.search)

    const orderType = ownProps.match.params.orderType;
    let orderTypeId = 0;
    if (state.places.ordertypes) {
        orderTypeId = Object.values(state.places.ordertypes).find(t => t.type == orderType);
        if (orderTypeId) {
            orderTypeId = orderTypeId.id;
        }
    }
    // console.log("orderTypeId ", orderTypeId)
    return {
        orderType: orderType,
        orderTypeId: orderTypeId,
        ordertypeDiscounts: Object.values(state.settings.ordertypes.ordertype_discounts).sort((a, b) => (a.price_over - b.price_over)) || [],

        page: Number(state.router.location.query.page) || 1,
        translations: state.lang,
        userRole: state.auth.user.role || "",
    }
};

const mapDispatchToProps = (dispatch, props) => {
    return {
        dispatch,
        ...bindActionCreators({ listOrdertypeDiscounts, addOrdertypeDiscounts, listOrderTypes, addErrorMessages }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrdertypePricesPage);
