import React from 'react'

const Filter = (props) => {

    return (
        <div className="container-fluid">
            <div className="animated fadeIn">
                <div className="row">

                    {/* TODO: filter by date / or filter by month - don't know which will be more usefull (maybe by month) */}
                    {/* <div className="form-group reservation-list-search col-md-2 col-sm-4 ">
                        <div className="form-group">
                            <label htmlFor="name">{props.translations.proforms.date}</label>
                            <input className={`form-control`} id="search_date" type="text" placeholder=""
                                name="search_proform_date" onChange={props.onChange} value={props.search_proform_date} />
                        </div>
                    </div> */}

                    <div className="form-group reservation-list-search col-md-2 col-sm-4 ">
                        <div className="form-group">
                            <label htmlFor="search_company_name">{props.translations.proforms.number}</label>
                            <input className={`form-control`} id="search_number" type="text" placeholder=""
                                name="search_number" onChange={props.onChange} value={props.search_number} />
                        </div>
                    </div>
                    <div className="form-group reservation-list-search col-md-2 col-sm-4 ">
                        <div className="form-group">
                            <label htmlFor="search_company_name">{props.translations.proforms.company_name}</label>
                            <input className={`form-control`} id="search_company_name" type="text" placeholder=""
                                name="search_company_name" onChange={props.onChange} value={props.search_company_name} />
                        </div>
                    </div>
                    <div className="form-group reservation-list-search col-md-2 col-sm-4 ">
                        <div className="form-group">
                            <label htmlFor="name">{props.translations.common.status}</label>
                            <select className="form-control" name="search_status" defaultValue={props.search_status} onChange={props.onChange}>
                                <option key={0} value="">-</option>
                                <option value={'new'}>{props.translations.proforms.new}</option>
                                <option value={'sent'}>{props.translations.proforms.sent}</option>
                                <option value={'paid'}>{props.translations.proforms.paid}</option>
                            </select>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Filter;