import React, { Component } from 'react'
import { connect } from 'react-redux';
import { listProducts } from './../../../actions/productsActions';
import { imageCategoryUpload, editImageCategoryImage, deleteImageCategoryImage } from './../../../actions/categoriesHierarchyActions';
import MessagesContainer from './../../messages/MessagesContainer';
import Select from 'react-select';
import { IMAGE_CAT_IMAGES_PATH } from './../../../constants';
import Utils from '../../../utils/Utils';
import ImageCropperInput from './../../common/ImageCropperInput';
import { addErrorMessages } from './../../messages/messages.actions';

class ImagesCategoryTab extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            priorities: {},
            croppedImgData: ''
        }
    }

    componentDidMount() {
        // this.props.listProducts();
    }

    componentWillReceiveProps(nextProps) {
        
        if(nextProps.cat) {

            let priorities = {};
            Object.values(nextProps.cat.imageCategoryImages).map(img => {
                priorities[img.id] = img.priority;
            })

            this.setState({ priorities: priorities })
        }
    }

    // uploadImage = () => {
    //     const files = Array.from(document.querySelector('#imageCategoryInput').files)
	// 	let formData = new FormData()

	// 	files.forEach((file, i) => {
	// 		formData.append("file", file)
    //     })
    //     formData.append("category_id", this.props.cat.id);

	// 	this.props.imageCategoryUpload(formData, this.props.cat.id, this.props.history);
    // }

    convertBase64ToBlob = (base64Image) => {
        // Split into two parts
        const parts = base64Image.split(';base64,');
      
        // Hold the content type
        const imageType = parts[0].split(':')[1];
      
        // Decode Base64 string
        const decodedData = window.atob(parts[1]);
      
        // Create UNIT8ARRAY of size same as row data length
        const uInt8Array = new Uint8Array(decodedData.length);
      
        // Insert all character code into uInt8Array
        for (let i = 0; i < decodedData.length; ++i) {
          uInt8Array[i] = decodedData.charCodeAt(i);
        }
        // Return BLOB image after conversion
        return new Blob([uInt8Array], { type: imageType });
      }

    
      uploadImage = () => {
        let formData = new FormData()
        if(this.state.croppedImgData.length == 0) {
            this.props.addErrorMessages([this.props.translations.messages.errors.invalid_crop_image]);
            return;
        }

        formData.append("file", this.convertBase64ToBlob(this.state.croppedImgData));
        formData.append("category_id", this.props.cat.id);

		this.props.imageCategoryUpload(formData, this.props.cat.id, this.props.history);
    }

    onPriorityChange = (e, imgId) => {
        let priority = e.target.value;
        let priorities = this.state.priorities;

        priorities[imgId] = priority;
        this.setState({ priorities: priorities })
    }

    updatePriority = (e, id) => {
        e.preventDefault();
		const post = {
			imgCatId: id,
			priority: this.state.priorities[id]
		}
		this.props.editImageCategoryImage(post, this.props.history);
    }

    setCroppedImgData = (data) => {
        this.setState({ croppedImgData: data })
    }

    render() {
        return (
        
            <div className="">
                <MessagesContainer />

                <div className="form-group">
                    <label>{this.props.translations.categories.tabs.images_category}</label>
                
                    <ImageCropperInput
                        setCroppedImgData={this.setCroppedImgData}
                    />

                    <br/>
                    <button type="button" className="btn btn-primary" onClick={this.uploadImage}>{this.props.translations.common.save}</button>
                    
                    {/* <input id="imageCategoryInput" type='file' name='file' ref="image"/> */}


                    <br/><hr/>  

                    {this.props.cat && this.props.cat.imageCategoryImages.sort(Utils.compareForPriority()).map(img => {
                        return (
                            <div className="row">
                                <div className="col-md-4">
                                    <img width={200} src={IMAGE_CAT_IMAGES_PATH + img.image} />
                                </div>
                                <div className="col-md-4">
                                    <input type='text' placeholder="priority"
                                        onChange={(ev) => this.onPriorityChange(ev, img.id)}
                                        onBlur={(ev) => this.updatePriority(ev, img.id)}
                                        value={this.state.priorities[img.id]} className="form-control" />
                                    
                                </div>
                                <div className="col-md-4">
                                    <button className="btn btn-danger" onClick={() => this.props.deleteImageCategoryImage({imgCatId: img.id})}>
                                        {this.props.translations.common.delete}
                                    </button>
                                </div>
                            </div>
                        )
                    })}

                </div>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        products: state.products.products || [],
        translations: state.lang,
    }
};

export default connect(mapStateToProps, { imageCategoryUpload, editImageCategoryImage, deleteImageCategoryImage, addErrorMessages })(ImagesCategoryTab);