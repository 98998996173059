import React, { useState, useRef, useCallback, useEffect } from "react";
import ReactDOM from "react-dom";
import { LoadScript, GoogleMap, Polygon, Marker } from "@react-google-maps/api";
import { GOOGLE_MAPS_API_KEY } from "../../../constants";

import "./../styles/styles.css";

// This example presents a way to handle editing a Polygon
// The objective is to get the new path on every editing event :
// - on dragging the whole Polygon
// - on moving one of the existing points (vertex)
// - on adding a new point by dragging an edge point (midway between two vertices)

// We achieve it by defining refs for the google maps API Polygon instances and listeners with `useRef`
// Then we bind those refs to the currents instances with the help of `onLoad`
// Then we get the new path value with the `onEdit` `useCallback` and pass it to `setPath`
// Finally we clean up the refs with `onUnmount`

function PolygonMap(props) {
  // Store Polygon path in state
  
  const [path, setPath] = useState([
    { lat:  42.72416730789189, lng: 23.32099199295044 },
    { lat: 42.69313714683006, lng: 23.358757495880127 },
    { lat: 42.684304591241336, lng: 23.281166553497314 }
  ]);
  const [center, setCenter] = useState({lat: 42.69313714683006, lng: 23.358757495880127});
  const [isCenterSet, setIsCenterSet] = useState(false);
  const [markerPosition, setMarkerPosition] = useState({});
  const [addressCheck, setaddressCheck] = useState("");
  const [addressCheckMsg, setaddressCheckMsg] = useState("");

  // Define refs for Polygon instance and listeners
  const polygonRef = useRef(null);
  const listenersRef = useRef([]);

  // Call setPath with new edited path
  const onEdit = useCallback(() => {
    if (polygonRef.current) {
      var nextCoords = "";
      var polygonLength = polygonRef.current.getPath().getArray().length;
      const nextPath = polygonRef.current
        .getPath()
        .getArray()
        .map((latLng, i) => {
          nextCoords += latLng.lat() + ", " + latLng.lng()
          
          if (polygonLength !== i + 1) {
            nextCoords += "\n"; 
          }
          
          return { lat: latLng.lat(), lng: latLng.lng() };
        });
      setPath(nextPath);
      props.onChangeField('coords', nextCoords);
      
    }
  }, [setPath]);

  // Bind refs to current Polygon and listeners
  const onLoad = useCallback(
    polygon => {
      polygonRef.current = polygon;
      const path = polygon.getPath();
      listenersRef.current.push(
        path.addListener("set_at", onEdit),
        path.addListener("insert_at", onEdit),
        path.addListener("remove_at", onEdit)
      );
    },
    [onEdit]
  );

  // Clean up refs
  const onUnmount = useCallback(() => {
    listenersRef.current.forEach(lis => lis.remove());
    polygonRef.current = null;
  }, []);


  
  useEffect( () => {
    var coordsArr = [];

    if(typeof props.coords == 'string' && props.coords.length > 0) {
        var arr = props.coords.split(/\r?\n/)

        arr.map(latLng => {
            var latLngObj = latLng.split(",");

            if(latLngObj.length == 2) {
                coordsArr.push({
                    lat: parseFloat(latLngObj[0]),
                    lng: parseFloat(latLngObj[1])
                })
            }
        })

        setPath(coordsArr)

        if(!isCenterSet) {
          setCenter(coordsArr[0]);
          setIsCenterSet(1);
        }

    }
  }, [props.coords])

  const geocodeAddress = useCallback(() => {

    if(addressCheck.length > 3) {
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ address: addressCheck }, (results, status) => {
        console.log("RESULT ", results, status)
        if (status === 'OK' && results[0]) {
          const { location } = results[0].geometry;
  
          var pos = {lat: location.lat(), lng: location.lng()};
          setMarkerPosition(pos);
          setaddressCheckMsg('')
        } else {
          setaddressCheckMsg('Geocode was not successful for the following reason: ' + status)
          // console.error('Geocode was not successful for the following reason: ' + status);
        }
      });
    }
    
  });
  

  return (
    <React.Fragment>
      <div className="mapApp" style={{
        fontFamily: "sans-serif",
        textAlign: "center",
        height: "70vh"  
      }}>
        <label>{props.translations.delivery_shapes.map}</label>
        <LoadScript
          id="script-loader"
          googleMapsApiKey={GOOGLE_MAPS_API_KEY}
          language="en"
          region="bg"
        >
          <GoogleMap
            mapContainerClassName="shapes-map"
            // center={path[0] ? path[0] : { lat: 42.6975479, lng: 23.3154494 }}
            // center={{lat: 42.69313714683006, lng: 23.358757495880127}}
            center={center}
            zoom={11}
            version="weekly"
            // onZoomChanged={(e) => handleZoomChanged(e)}

          >
            <Polygon
              // Make the Polygon editable / draggable
              editable
              draggable
              path={path}
              // Event used when manipulating and adding points
              onMouseUp={onEdit}
              // Event used when dragging the whole Polygon
              onDragEnd={onEdit}
              onLoad={onLoad}
              onUnmount={onUnmount}
            >
            </Polygon>

            {markerPosition && markerPosition.lat && <Marker position={markerPosition} />}

          </GoogleMap>
        </LoadScript>
      </div>
      <div className="row pt-5">
        <div className="col-md-3">
          <label>{props.translations.delivery_shapes.check_if_address_in_polygon}</label>
          <div>{addressCheckMsg}</div>
        </div>
        <div className="col-md-3">
          <input type="text" name="checkAddress" className="form-control" onChange={(e) => setaddressCheck(e.target.value)}/>
        </div>
        <div className="col-md-3">
          <button type="button" className="btn btn-success" onClick={geocodeAddress}>{props.translations.delivery_shapes.check_address}</button>
        </div>
      </div> 
    </React.Fragment>
  );
}




export default PolygonMap;