import React, { Component } from 'react'
import { NavLink } from 'react-router-dom';
import { ADMIN_URL, PRODUCT_IMG_URL } from '../../../constants.js'
import DeleteModal from './../../common/DeleteModal';
import MessagesContainer from '../../messages/MessagesContainer';
import Utils from '../../../utils/Utils.js';
import LangDropdown from './../../common/LangDropdown';
import Select from 'react-select'

class AddEditRegionForm extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const props = this.props;

        // let selectedParent;
        // let autocompleteItems = [];
        // autocompleteItems.push({'value': null, 'label': "Choose region"})

        // // console.log( "AUTO ", autocompleteItems, props.clients)
        // props.regions.map(cl => {
        //     if (props.region.clientId == cl.id) {
        //         selectedParent = { 'value':cl.id, 'label': cl.name} ;
        //     }
            
        //     // if(cat.active == 1) {
                
        //         autocompleteItems.push( {'value': cl.id, 'label': cl.name} );
        //     // } 
        // });

        let cityOptions = [];
        let selectedCity = null;
        Object.values(props.cities).map(city => {
            cityOptions.push({ 'value': city.id, 'label': city.name });
            if (props.region.cityId == city.id) {
                selectedCity = { 'value': city.id, 'label': city.name }
            }
        });

        return (
            <form onSubmit={props.onSubmit}>
                <MessagesContainer />
                <div className="card-body">

                    <LangDropdown
                        isDisabled={props.isAdding == 1 ? 1 : ""}
                        currLangOnChange={props.currLangOnChange}
                        currLang={props.currLang}
                        languages={props.languages}
                    />

                    <div className="row">
                        <div className="col-sm-12">
                            <div className="form-group">
                                <label htmlFor="name">{props.translations.common.name}</label>
                                <input className={`form-control`} id="name" type="text" regionholder={props.translations.common.name} name="name" onChange={props.onChange} value={props.region.name} />
                            </div>
                        </div>

                        <div className="col-sm-12">
                            <div className="form-group">
                                <label htmlFor="name">{props.translations.common.description}</label>
                                <input className={`form-control`} id="name" type="text" regionholder={props.translations.common.description} name="description" onChange={props.onChange} value={props.region.description} />
                            </div>
                        </div>

                        <div className="col-sm-12">
                            <div className="form-group">
                                <label htmlFor="name">{props.translations.common.delivery_price}</label>
                                <input className={`form-control`} id="name" type="number" regionholder={props.translations.common.delivery_price} name="delivery_price" onChange={props.onChange} value={props.region.delivery_price} />
                            </div>
                        </div>
                        
                        <div className="form-group col-sm-12">
                            <label htmlFor="">{props.translations.common.city}</label>
                            <Select
                                value={selectedCity}
                                // isMulti
                                name="colors"
                                options={cityOptions}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                onChange={(valueLabel) => props.onFieldChange('cityId', valueLabel.value)}
                            // onChange={(values, { action, removedValue }) => props.ingredientsOnChange(values, { action, removedValue }, 'removable')}
                            />
                        </div>

                        <div className="col-sm-12">
                            <div className="form-group">
                                <label htmlFor="external_system_region_id">{props.translations.common.external_system_region_id}</label>
                                <input className={`form-control`} id="external_system_region_id" type="text" regionholder={props.translations.common.external_system_region_id} name="external_system_region_id" onChange={props.onChange} value={props.region.external_system_region_id} />
                            </div>
                        </div>
                    </div>
{/* 
                    <div className="row">
                        <div className="form-group col-sm-12">
                            <label htmlFor="parentId">{props.translations.common.region}</label>
                            <Select name="parentId" options={autocompleteItems} onChange={props.onChangeSelectAutocompleteItems} value={selectedParent} 
                                regionholder={"Client"}
                            />
                        </div>
                    </div> */}

                    <hr />
                    <br />

                    <div className="form-actions">
                        {props.isAdding == '1' ? '' : <button className="btn btn-danger mright10" type="button" data-toggle="modal" data-target={"#dangerModal" + props.region.id} >{props.translations.common.delete}</button>}


                        <DeleteModal
                            id={props.region.id}
                            title={props.region.name}
                            deleteFunc={props.deleteRegion}
                            translations={props.translations}
                        />

                        <NavLink to={ADMIN_URL + 'regions'}>
                            <button className="btn btn-secondary" type="button">
                                {props.translations.common.cancel}
                            </button>
                        </NavLink>

                        <button className="fright btn btn-primary" type="submit">
                            {props.isAdding == '1' ? props.translations.common.add : props.translations.common.save}
                            {/* {props.actionText} */}
                        </button>

                    </div>

                </div>

            </form>
        )
    }
}

export default AddEditRegionForm;