import React, { Component } from 'react';
import { IMG_PATH, SETTINGS_IMG_URL, ADMIN_URL } from '../constants.js'
import Auth from '../utils/Auth';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { setLanguage } from 'react-redux-multilang'
import history from './../history';
import { changeLanguage } from './../actions/languagesActions';
import LoadingScreen from './common/loading/LoadingScreen';
import { editSetting } from './../components/settings/actions/settings.actions';
import Utils from '../utils/Utils.js';
import { listVersions } from './../components/versions/actions/versions.action';
import SnackbarNotifications from './common/SnackbarNotifications';
import { setSnackbarText, openConfirmModal } from './../actions/adminActions'
import { Button, IconButton, Slide, Snackbar } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import ConfirmModal from './common/ConfirmModal.js';
import { WonderPush } from 'react-wonderpush';

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rerender: false,
      timerCheckForceRefresh: '',
      timeoutRefreshPage: '',
    }
  }

  componentDidMount() {

    if (Auth.getToken()) {

      let timerCheckForceRefresh = setInterval(() => {
        this.props.listVersions();

        let tabl_last_version_checked = window.localStorage.getItem('tabl_last_version_checked'); // Set last checked version (not seen)
        if (!tabl_last_version_checked) {
          window.localStorage.setItem('tabl_last_version_checked', this.props.versions[this.props.versions.length - 1].id);
          tabl_last_version_checked = window.localStorage.getItem('tabl_last_version_checked');
        }

        let unseenVersions = this.props.versions && window.localStorage.getItem('tabl_last_version_seen') && Object.values(this.props.versions).filter(v => v.id > parseInt(window.localStorage.getItem('tabl_last_version_seen')));

        if (!window.localStorage.getItem('tabl_last_version_seen')) {
          unseenVersions = this.props.versions;
        }

        if (unseenVersions) {
          let forseRefreshNeeded = unseenVersions && Object.values(unseenVersions).length > 0 ? unseenVersions.filter(f => f.force_refresh == '1') : false;

          if (forseRefreshNeeded) {
            forseRefreshNeeded.map(f => {
              if (f.id > tabl_last_version_checked) { // If the new version is not checkced (this if-check is needed to prevent unlimited reloads)

                this.props.setSnackbarText(
                  {
                    text: 'Страницата ще бъде презаредена автоматично до 5 минути, съхранете незапазени промени ако има такива. Ако желаете да избегнете автоматичното презареждане, моля презаредете страницата с refresh бутона на браузъра или клавиша F5.',
                    actionText: 'REFRESH',
                    callback: () => { window.location.reload() }
                  }
                );

                window.localStorage.setItem('tabl_last_version_checked', this.props.versions[this.props.versions.length - 1].id);

                let timeoutRefreshPage = setTimeout(() => {
                  clearInterval(this.state.timeoutRefreshPage);
                  clearInterval(this.state.timeoutRefreshPage);
                  window.location.reload()
                }, 300000) // 5 mins
                this.setState({ timeoutRefreshPage });

              }
            })
          }
        }

      }, 900000) // 15 mins
      this.setState({ timerCheckForceRefresh });
    }


  }

  componentWillUnmount() {
    clearInterval(this.state.timeoutRefreshPage);
    clearInterval(this.state.timerCheckForceRefresh);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.settings) {
      // Logo
      if (nextProps.settings.logo && nextProps.settings.logo.value) {
        this.logoSrc = nextProps.settings.logo.value
        if (this.logoSrc.indexOf("http") !== -1) {
          this.logoSrc = this.logoSrc;
        } else {
          this.logoSrc = SETTINGS_IMG_URL + this.logoSrc;
        }
      } else if (nextProps.settings.tabl_logo) {
        this.logoSrc = nextProps.settings.tabl_logo.value
      }

      if (nextProps.settings.tabl_short_logo && nextProps.settings.tabl_short_logo.value) {
        // Short logo
        this.shortLogoSrc = nextProps.settings.tabl_short_logo.value;
        if (this.shortLogoSrc.indexOf("http") !== -1) {
          this.shortLogoSrc = this.shortLogoSrc;
        } else {
          this.shortLogoSrc = SETTINGS_IMG_URL + this.shortLogoSrc;
        }
      } else if (nextProps.settings.tabl_logo) {
        this.shortLogoSrc = nextProps.settings.tabl_logo.value
      }

      // Set favicon
      let favicon = document.querySelector('link.favicon');
      if (favicon) {
        favicon.setAttribute('href', this.shortLogoSrc);
      }
    }
  }

  mySetLang = (l) => {
    this.props.setLang(l);
    // this.setState({ });
    // this.forceUpdate();


    // store.dispatch({
    //     type: 'SET_REDUX_LANGUAGE',
    //     code: 'ru'
    // })
    // this.props.setLanguage(l);
  }

  reqFullScreen() {
    var docElm = document.documentElement;
    if (docElm) {
      if (docElm.requestFullscreen) {
        docElm.requestFullscreen();
      } else if (docElm.msRequestFullscreen) {
        docElm.msRequestFullscreen();
      } else if (docElm.mozRequestFullScreen) {
        docElm.mozRequestFullScreen();
      } else if (docElm.webkitRequestFullScreen) {
        docElm.webkitRequestFullScreen();
      }
    }
  }

  // Used for raspberry with display
  stopFullScreen() {
    if (document.fullscreenElement) {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
      else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
      else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      }
      else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }


  togglePrintButtonSize() {
    if (window.localStorage.getItem('large_print_button') == null) {
      window.localStorage.setItem('large_print_button', true)
    } else {
      window.localStorage.removeItem('large_print_button');
    }
    window.location.reload();
  }

  setAdminFontSize(newVal) {
    if (newVal < 10 || newVal > 20) return;
    localStorage.setItem('tabl_admin_font_size', newVal);
    window.location.reload();
  }

  getAdminFontSize() {
    return parseInt(localStorage.getItem('tabl_admin_font_size')) || 14; //14 default
  }

  editSettings = (setting, newValue) => {
    let post = {
      id: setting.id,
      name: setting.name,
      value: newValue,
      // send_mail: true, //Send notify email
    }

    this.props.editSetting(post);

  }

  render() {
    const { places, clients } = this.props;

    let currPlace = '';
    let currClient = '';
    let currPlaceName = '';
    if (places) {
      currPlace = places.find(p => p.id == Auth.getUserPlace());
      currPlaceName = currPlace ? currPlace.name : "-";

      if (currPlace && Object.values(currPlace).length > 0 && currPlace.clientId) {
        currClient = clients.find(c => c.id == currPlace.clientId)
      }
    }

    let currClientSlug = '';
    if (currClient && Object.keys(currClient).length > 0 && currClient.slug) {
      currClientSlug = currClient.slug;
    }

    let unseenVersions = this.props.versions && window.localStorage.getItem('tabl_last_version_seen') && Object.values(this.props.versions).filter(v => v.id > parseInt(window.localStorage.getItem('tabl_last_version_seen')));

    if (!window.localStorage.getItem('tabl_last_version_seen')) {
      if (this.props.versions) {
        unseenVersions = this.props.versions.length
      }
    } else if (unseenVersions) {
      unseenVersions = unseenVersions.length;
    }

    // <li className="nav-item dropdown">
    //   <a className="nav-link dropdown-toggle" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">
    //     <img src="img/avatars/6.jpg" className="img-avatar" alt="admin@bootstrapmaster.com">
    //   </a>
    //   <div className="dropdown-menu dropdown-menu-right">

    //     <div className="dropdown-header text-center">
    //       <strong>Account</strong>
    //     </div>

    //     <a className="dropdown-item" href="#"><i className="fa fa-bell-o"></i> Updates<span className="badge badge-info">42</span></a>
    //     <a className="dropdown-item" href="#"><i className="fa fa-envelope-o"></i> Messages<span className="badge badge-success">42</span></a>
    //     <a className="dropdown-item" href="#"><i className="fa fa-tasks"></i> Tasks<span className="badge badge-danger">42</span></a>
    //     <a className="dropdown-item" href="#"><i className="fa fa-comments"></i> Comment<span className="badge badge-warning">42</span></a>

    //     <div className="dropdown-header text-center">
    //       <strong>Settings</strong>
    //     </div>

    //     <a className="dropdown-item" href="#"><i className="fa fa-user"></i> Profile</a>
    //     <a className="dropdown-item" href="#"><i className="fa fa-wrench"></i> Setting</a>
    //     <a className="dropdown-item" href="#"><i className="fa fa-usd"></i> Payments<span className="badge badge-secondary">42</span></a>
    //     <a className="dropdown-item" href="#"><i className="fa fa-file"></i> Projects<span className="badge badge-primary">42</span></a>
    //     <div className="divider"></div>
    //     <a className="dropdown-item" href="#"><i className="fa fa-shield"></i> Lock account</a>
    //     <a className="dropdown-item" href="#"><i className="fa fa-lock"></i> Logout</a>
    //   </div>
    // </li>

    const transitionComponent = (props) => <Slide {...props} direction="down" />;


    const subscribeToPushNotifications = () => {
      window.WonderPush = window.WonderPush || [];
      console.log('WonderPush', WonderPush);
      window.WonderPush.push(['setUserId', Auth.getDecodedUser().email]);
      window.WonderPush.addTag(Auth.getUserPlace());

      // window.WonderPush.push(function () {
      //     window.WonderPush.addTag(Auth.getUserPlace());
      //     console.log('In adding tag');
      //     // WonderPush.addTag("economics", "sport", "politics");
      // });
      // console.log('subscribeToPushNotifications');

      // window.WonderPush.push(function () {
      //     window.WonderPush.getUserId().then(console.log);
      // });

    }



    // let loggedUser = Auth.getToken() ?
    //   <a className="nav-link" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">
    //     <img className="img-avatar" src={ADMIN_URL + "img/avatars/admin.png"} alt="user" />
    //     {/* <img className="img-avatar" src={this.logoSrc} alt="admin@bootstrapmaster.com"/> */}
    //   </a>
    //   : '';
    return (
      <header className="app-header navbar">
        <LoadingScreen />
        <ConfirmModal />

        <button className="navbar-toggler sidebar-toggler d-lg-none mr-auto" type="button" data-toggle="sidebar-show">
          <span className="navbar-toggler-icon"></span>
        </button>


        <a className="navbar-brand logo-holder " href="/admin">
          {/* <img className="navbar-brand-full admin-logo" src={IMG_PATH+"img/brand/logo.svg"} width="89" height="25" alt="CoreUI Logo"/>
        <img className="navbar-brand-minimized" src={IMG_PATH+"img/brand/sygnet.svg"} width="30" height="30" alt="CoreUI Logo"/> */}

          <img className="navbar-brand-full admin-logo" src={'https://tabl.bg/img/tabl-transparent.png'} alt="TabL Logo" />
          {/* <img className="navbar-brand-full admin-logo" src={this.logoSrc} alt="TabL Logo"/> */}
          {/* <img className="navbar-brand-minimized admin-logo" src={'https://tabl.bg/img/tabl-transparent.png'}  alt="TabL Logo" /> */}
        </a>

        {/* <!--
      <button className="navbar-toggler sidebar-toggler d-md-down-none" type="button" data-toggle="sidebar-lg-show">
        <span className="navbar-toggler-icon"></span>
      </button>
      
      <ul className="nav navbar-nav d-md-down-none">
        <li className="nav-item px-3">
          <a className="nav-link" href="#">Dashboard</a>
        </li>
        <li className="nav-item px-3">
          <a className="nav-link" href="#">Users</a>
        </li>
        <li className="nav-item px-3">
          <a className="nav-link" href="#">Settings</a>
        </li>
      </ul>
      --> */}

        <SnackbarNotifications
          snackbarText={this.props.snackbarText}
          setSnackbarText={this.props.setSnackbarText}
          snackbarActionCallback={this.props.snackbarActionCallback}
          snackbarActionText={this.props.snackbarActionText}
        />

        < ul className="nav navbar-nav ml-auto" >

          {/* <!--<li className="nav-item d-md-down-none">
          <a className="nav-link" href="#">
            <i className="icon-bell"></i>
            <span className="badge badge-pill badge-danger">5</span>
          </a>
        </li>
        <li className="nav-item d-md-down-none">
          <a className="nav-link" href="#">
            <i className="icon-list"></i>
          </a>
        </li>
        <li className="nav-item d-md-down-none">
          <a className="nav-link" href="#">
            <i className="icon-location-pin"></i>
          </a>
        </li>
        --> */}



          {Auth.hasAccess("SALES_ADMIN") && this.props.places.length > 1 ?
            <li className="nav-item place-header">
              <NavLink to={ADMIN_URL + `places/current`} className="nav-link">
                <span className="badge badge-secondary fsize-13" style={{ position: 'initial' }}>{currPlaceName}</span>
              </NavLink>
            </li>
            :
            null
          }

          &nbsp;

          {
            (Auth.getToken() && ((Auth.getUserPlace() == 47 && (Auth.getDecodedUser().email == 'floraadmin@tabl.bg' || Auth.getDecodedUser().email == 'demo@tabl.bg')) || (Auth.getUserPlace() != 47))) ?
              <>
                {this.props.settings && this.props.settings.orders_allowed && this.props.settings.orders_allowed.value == '1' ?
                  <button type="button" className="btn btn-outline-success active"
                    onClick={() => this.props.openConfirmModal('Поръчките са ВКЛЮЧЕНИ, сигурни ли сте, че искате да спрете приемането на поръчки?', () => this.editSettings(this.props.settings.orders_allowed, 0), () => void (0))}
                    title={"Поръчки - ВКЛЮЧЕНИ, натисни за да изключиш"} style={{ padding: '0 5px 0 5px', lineHeight: 'normal' }}>Поръчки<br />ВКЛЮЧЕНИ</button>
                  :
                  <button type="button" className="btn btn-outline-danger active"
                    onClick={() => this.props.openConfirmModal('Поръчките са ИЗКЛЮЧЕНИ, сигурни ли сте, че искате да включите приемането на поръчки?', () => this.editSettings(this.props.settings.orders_allowed, 1), () => void (0))}
                    title={"Поръчки - ИЗКЛЮЧЕНИ, натисни за да включиш"} style={{ padding: '0 5px 0 5px', lineHeight: 'normal' }}>Поръчки<br />ИЗКЛЮЧЕНИ</button>
                }
              </>
              :
              null
          }

          &nbsp;



          {/* 
          <li className="nav-item d-md-down-none">
            <a className="nav-link" href="#">
              <i className="icon-location-pin"></i>
            </a>
          </li> 
          */}

          <li >
            {/* <button className="btn" style={localStorage.getItem('default_lang') == 'ro' ? { background: '#d8d8d8' } : { marginRight: 5 }} onClick={() => this.props.changeLanguage('ro')}>RO</button> */}
            <button className="btn" style={localStorage.getItem('default_lang') == 'en' ? { marginRight: 5, background: '#d8d8d8' } : { marginRight: 5 }} onClick={() => this.props.changeLanguage('en')}>EN</button>
            <button className="btn" style={!localStorage.getItem('default_lang') || localStorage.getItem('default_lang') == 'bg' ? { marginRight: 5, background: '#d8d8d8' } : { marginRight: 5 }} onClick={() => this.props.changeLanguage('bg')}>BG</button>
            <button className="btn" 
            style={!localStorage.getItem('default_lang') || localStorage.getItem('default_lang') == 'es' ? { background: '#d8d8d8' } : { marginRight: 5 }} onClick={() => this.props.changeLanguage('es')}>ES</button>
          </li>


          {
            Auth.getToken() ?
              <li className="nav-item dropdown show dropdownicons-black" style={{ paddingRight: 10 }}>
                <a className="nav-link dropdown-toggle" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">
                  <img
                    // src="img/avatars/6.jpg"
                    src={ADMIN_URL + "img/avatars/admin.png"}
                    className="img-avatar" alt="user" />
                  {unseenVersions && unseenVersions != '0' ? <span className="badge badge-pill badge-warning">{unseenVersions}</span> : ''}
                </a>

                <div className="dropdown-menu dropdown-menu-right " style={{ maxHeight: '90vh', overflowY: 'scroll' }}>

                  <div className="dropdown-header text-center">
                    <strong>{Auth.getToken() ? Auth.getDecodedUser().email : null} - {Auth.getToken() ? this.props.translations.roles[Auth.getDecodedUser().role] : null} - <i className="fas fa-lock c-pointer" title={'Изход'} onClick={this.props.logoutAction}></i>
                    </strong>
                  </div>

                  <button className="dropdown-item c-pointer" title="Отвори меню - клиентска част" onClick={() => window.open(((window.location.hostname == 'localhost' ? 'localhost:3000' : window.location.origin) + '/' + currClientSlug), "_blank")} >
                    <i className="icon-screen-tablet"></i>Отвори меню - клиентска част
                  </button>

                  <button className="dropdown-item c-pointer" title="Обнови страницата и свали нови ъпдейти" onClick={() => window.location.href = window.location.origin + '/admin/list-orders/all'}>
                    <i className="icon-arrow-down-circle"></i>Обнови страницата и свали нови ъпдейти
                  </button>

                  {unseenVersions && unseenVersions != '0' ?
                    <div style={{
                      display: 'grid',
                      'gridTemplateColumns': '70% 30%'
                    }}>
                      <button className="dropdown-item c-pointer" title="Виж новите функционалности" onClick={() => Utils.navigateTo(ADMIN_URL)}>
                        <i className="fa fa-bell-o"></i>Нови функционалности
                      </button>
                      <button className="dropdown-item c-pointer" title="Обнови известията" onClick={(e) => this.setState({ rerender: !this.state.rerender })} style={{
                        'borderBottom': '1px solid #c8ced3',
                        'textAlign': 'center',
                        width: '100%',
                        'minWidth': 50
                      }}>
                        <i className="icon-refresh"></i><span className="badge badge-warning" style={{ position: 'initial' }}>{unseenVersions}</span>
                      </button>
                    </div>
                    :
                    <button className="dropdown-item c-pointer" title="Виж новите функционалности" onClick={() => Utils.navigateTo(ADMIN_URL)}>
                      <i className="fa fa-bell-o"></i>Нови функционалности
                    </button>
                  }

                  <div style={{
                    display: 'grid',
                    'gridTemplateColumns': '70% 30%'
                  }}>
                    <button className="dropdown-item c-pointer" title="Позволи известия" onClick={() => Utils.navigateTo(ADMIN_URL)}>
                      <i className="fa fa-bell-o"></i>Позволени PUSH нотификации
                    </button>
                    <div className="switch-container">
                      <div id="wonderpush-subscription-switch" data-on="YES" data-off="NO"></div>
                    </div>
                  </div>

                  <button className="dropdown-item c-pointer" title="Натисни и при нова поръчка ще получиш известие" onClick={() => { subscribeToPushNotifications(); alert('Успешно е добавено устройството за PUSH нотификации от текущия обект. За да се Отпишете, използвайте камбанката долу вдясно.') }}>
                    <i className="fa fa-bell-o"></i>Включи PUSH нотификации за поръчки<br />от текущия обект /доставка, взимане от място/
                  </button>

                  <div className="dropdown-header text-center">
                    <strong>Бързи настройки</strong>
                  </div>

                  <button className="dropdown-item c-pointer" title="Смяна на опцията за 'Възможно е забавяне на поръчките' поради натоварване" onClick={() => this.props.openConfirmModal('В момента ' + (Utils.getSettingsValue(this.props.settings, 'orders_may_be_late') == '1' ? 'Е' : 'НЕ Е') + ' възможно забавяне на поръчките. Сигурни ли сте, че искате да смените това?', () => this.editSettings(this.props.settings.orders_may_be_late, Utils.getSettingsValue(this.props.settings, 'orders_may_be_late') == '1' ? 0 : 1), () => void (0))}>
                    <i className="icon-fire"></i>Възможно е забавяне на поръчките - {Utils.getSettingsValue(this.props.settings, 'orders_may_be_late') == '1' ? 'ДА' : 'НЕ'}
                  </button>

                  <button className="dropdown-item c-pointer" title={"Увеличи шрифт | Текущ: " + this.getAdminFontSize() + ', Default: 14'} onClick={() => this.setAdminFontSize(this.getAdminFontSize() + 1)}>
                    <i className="icon-magnifier-add"></i>Увеличи шрифт | Текущ: {this.getAdminFontSize()} , Default: 14
                  </button>

                  <button className="dropdown-item c-pointer" title={"Намали шрифт | Текущ: " + this.getAdminFontSize() + ', Default: 14'} onClick={() => this.setAdminFontSize(this.getAdminFontSize() - 1)}>
                    <i className="icon-magnifier-remove"></i>Намали шрифт | Текущ: {this.getAdminFontSize()} , Default: 14
                  </button>

                  <button className="dropdown-item c-pointer" title="Отвори на цял екран" onClick={() => this.reqFullScreen()}>
                    <i className="icon-size-fullscreen"></i>Отвори на цял екран
                  </button>

                  <button className="dropdown-item c-pointer" title="Изход от режим цял екран" onClick={() => this.stopFullScreen()}>
                    <i className="icon-size-actual"></i>Изход от режим цял екран
                  </button>

                  <button className="dropdown-item c-pointer" title="Изход от режим цял екран" onClick={() => this.togglePrintButtonSize()}>
                    <i className="icon-size-actual"></i>По-голям/малък бутона за печат на поръчки
                  </button>

                  <div className="dropdown-header text-center">
                    <strong>Профил</strong>
                  </div>

                  {Auth.hasAccess("PLACE_ADMIN") ?
                    <>
                      <button className="dropdown-item c-pointer" title={this.props.translations.sidemenu.settings} onClick={() => Utils.navigateTo(ADMIN_URL + `settings`)}>
                        <i className="nav-icon icon-settings"></i>{this.props.translations.sidemenu.settings}
                      </button>

                      <button className="dropdown-item c-pointer" title={this.props.translations.sidemenu.users} onClick={() => Utils.navigateTo(ADMIN_URL + `users`)}>
                        <i className="nav-icon icon-user"></i>{this.props.translations.sidemenu.users}
                      </button>
                    </>
                    :
                    null
                  }

                  {/* <div className="dropdown-divider"></div> */}
                  {/* <a className="dropdown-item" href="/lock.html">
              <i className="fas fa-shield"></i> Заключване</a> */}
                  <button className="dropdown-item c-pointer" onClick={this.props.logoutAction}>
                    <i className="fas fa-lock"></i> Изход
                  </button>

                  {/* <a className="dropdown-item" href="#"><i className="fa fa-user"></i> Profile</a> */}
                  {/* <a className="dropdown-item" href="#"><i className="fa fa-wrench"></i> Setting</a>
              <a className="dropdown-item" href="#"><i className="fa fa-usd"></i> Payments<span className="badge badge-secondary">42</span></a>
              <a className="dropdown-item" href="#"><i className="fa fa-file"></i> Projects<span className="badge badge-primary">42</span></a>
              <div className="divider"></div>
              <a className="dropdown-item" href="#"><i className="fa fa-shield"></i> Lock account</a>
              <a className="dropdown-item" href="#"><i className="fa fa-lock"></i> Logout</a> */}
                </div>
              </li>
              :
              null
          }

        </ul >
        {/* <!--
      <button className="navbar-toggler aside-menu-toggler d-md-down-none" type="button" data-toggle="aside-menu-lg-show">
        <span className="navbar-toggler-icon"></span>
      </button>
      <button className="navbar-toggler aside-menu-toggler d-lg-none" type="button" data-toggle="aside-menu-show">
        <span className="navbar-toggler-icon"></span>
      </button>
    --> */}
      </header >
    )
  }
}


const mapStateToProps = state => {

  return {
    settings: state.settings.settings,
    clients: state.clients.clients,
    places: state.places.places,
    translations: state.lang,
    versions: state.versions.versions,
    snackbarText: state.admin.snackbarText,
    snackbarActionCallback: state.admin.snackbarActionCallback,
    snackbarActionText: state.admin.snackbarActionText,
  }
};

// const mapDispatchToProps = dispatch => {
//   return {
//     setLang: l => {
//       setLanguage(l)
//       // history.push('/admin/');
//       // dispatch({
//       //   type: 'SET_REDUX_LANGUAGE',
//       //   code: l
//       // })

//     }
//   }
// }

export default connect(mapStateToProps, { changeLanguage, editSetting, listVersions, setSnackbarText, openConfirmModal })(Header);