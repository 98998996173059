import React, { Component } from 'react'
import AddEditTableRegionForm from './AddEditTableRegionForm';
import { addEditTableRegion, listTableRegions } from '../actions/tableRegions.actions';
import { addErrorMessages } from '../../messages/messages.actions';
import { listTables } from '../../../actions/tablesActions';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { ADMIN_URL } from '../../../constants';
import Utils from '../../../utils/Utils';
import Auth from '../../../utils/Auth';

let md5 = require('md5');

class EditTableRegionPage extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			id: this.props.match.params.id,
			name: '',
			// tables: [],
			active: 1,			
		}
	}

	componentWillMount() {
        this.props.listTables();
    }

	componentWillReceiveProps(nextProps) {
		let tables = [];

		if (nextProps.tableRegion.id) {

			// nextProps.tableRegion.tables.map(t => {
			// 	let table = Object.values(nextProps.tables).find(tt => tt.id == t.id);

			// 	tables.push({ 
			// 		value: t.id, 
			// 		'label': table.table_num
			// 	})
				
			// })

			this.setState({
				id: nextProps.match.params.id,
				name: nextProps.tableRegion.name ? nextProps.tableRegion.name : 0,
				active: nextProps.tableRegion.active ? nextProps.tableRegion.active : 0,
				// tables: tables,
			});
		}
		
		
		
	}

	componentWillMount() {
		this.props.listTableRegions();

		// let tableRegionOrdertype = [];
		// if (this.props.tableRegion.ordertypes.length > 0) {
		// 	// nextProps.tableRegion.ordertypes.map(type => {
		// 	tableRegionOrdertype.push({ value: parseInt(this.props.tableRegion.ordertypes[0].id), label: this.props.tableRegion.ordertypes[0].type })
		// 	// })
		// }

		// this.setState({
		// 	tableRegionOrdertype: tableRegionOrdertype,
		// 	tableRegionOrdertypeValue: tableRegionOrdertype && tableRegionOrdertype[0] && tableRegionOrdertype[0] || ""
		// })
	}

	onChange = (e) => {
		this.setState({ [e.target.name]: e.target.value })
	}

	onChangeCheckbox = (e) => {
		this.setState({ [e.target.name]: e.target.checked });
	}

	onChangeSelectAutocomplete = (fieldName, valueLabel) => {
		this.setState({ [fieldName]: valueLabel });
	}

	validate = () => {
		let errors = [];
	
		// if(!this.state.tables || this.state.tables.length == 0) {
		// 	errors.push(this.props.translations.messages.errors.invalid_tables);			
		// }
		return errors;
	}

	onSubmit = (e) => {
		e.preventDefault();

		const errors = this.validate();
		if (errors.length > 0) {
			this.props.addErrorMessages(errors);
			return;
		}

		// let tablesIds = this.state.tables.map(t => t.value);

		const post = {
			id: this.state.id,
			name: this.state.name,
			active: this.state.active,
			// tables_ids: tablesIds
		}

		// dispatch(showLoading());

		this.props.addEditTableRegion(post)
		.then((post) => {
            post = post.data;
            this.props.listTableRegions();
			// this.props.history.push("/admin/tableRegions/edit/"+post.id);                
            // dispatch(hideLoading());
        })
        .catch(function (error) {
			this.props.addErrorMessages('Error occured');
        })
	}

	deleteTableRegion = (e, id) => {
		e.preventDefault();
		// this.props.removeTableRegion(id, this.props.history);
	}

	

	render() {
		// console.log("STATE", this.state);
		return (
			<main className="main">

				{/* <!-- Breadcrumb--> */}
				<ol className="breadcrumb">
					<li className="breadcrumb-item"><NavLink to={ADMIN_URL}>{this.props.translations.common.dashboard}</NavLink></li>
					{/* <li className="breadcrumb-item">Меню</li> */}
					<li className="breadcrumb-item"><NavLink to={ADMIN_URL + "tableRegions"}>{this.props.translations.table_regions.title}</NavLink></li>
					<li className="breadcrumb-item active">{this.props.translations.table_regions.edit}</li>
					{/* <!-- Breadcrumb Menu--> */}
					<li className="breadcrumb-menu d-md-down-none">
						<div className="btn-group" role="group" aria-label="Button group">

						</div>
					</li>
				</ol>

				<div className="container-fluid">
					<div className="animated fadeIn">
						<div className="row">

							{/* <!-- /.col--> */}
							<div className="col-sm-12">
								<div className="card">

									<AddEditTableRegionForm
										onSubmit={this.onSubmit}
										onChange={this.onChange}
										onChangeCheckbox={this.onChangeCheckbox}
										onChangeSelectAutocomplete={this.onChangeSelectAutocomplete}
										tables={this.props.tables}
										tableRegion={this.state}
										deleteTableRegion={this.deleteTableRegion}
										actionText="Запази"
										isAdding={false}
										translations={this.props.translations}
									/>

								</div>
							</div>

						</div>
					</div>
				</div>

			</main>
		)
	}
}

const mapStateToProps = (state, ownProps) => {
	let tableRegionId = ownProps.match.params.id;

	let tables = [];

	let tableRegion = state.tableRegions.tableRegions[tableRegionId];
	if (!tableRegion) tableRegion = {
		id: '',
		name: '',
		active: '',
		tables: [],
	};

	return {
		tableRegion: tableRegion,
		tables: state.tables.tables,
		translations: state.lang,
	}


};

export default connect(mapStateToProps, { listTableRegions, addEditTableRegion, addErrorMessages, listTables})(EditTableRegionPage);