import React from 'react'
import { NavLink } from 'react-router-dom';
import { ADMIN_URL } from '../../constants.js'
import MessagesContainer from '../messages/MessagesContainer';
import Select from 'react-select'
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import DeleteModal from '../common/DeleteModal.js';

const AddEditPromocodeForm = (props) => {

    let placesOptions = [];

    Object.values(props.places).map(place => {
        if (place.active == 1) {
            placesOptions.push({ 'value': place.id, 'label': place.name });
        }
    });


    let discountTypesOptions = [];
    discountTypesOptions.push({ 'value': 'money', 'label': 'Money' });
    discountTypesOptions.push({ 'value': 'percent', 'label': 'Percent' });

    let currPlaceOrderTypes = [];
    Object.values(props.currPlaceOrderTypes).map(t => {
        currPlaceOrderTypes.push({ 'value': t.id, 'label': t.type });
    });
    return (

        // <form onSubmit={props.onSubmit}>
        <form>
            <div className="card-body">
                <MessagesContainer />

                <div className="row">
                    <div className="col-sm-12">
                        <div className="form-group">
                            <label htmlFor="code">{props.translations.common.code}</label>
                            <input className={`form-control ${props.nameError.message ? "is-invalid" : ""}`} id="name" type="text" title={"latin"} placeholder={props.translations.common.promocode} name="code" onChange={props.onChange} value={props.promocode.code} />
                        </div>
                    </div>

                    <div className="col-sm-12">
                        <div className="form-group">
                            <label htmlFor="discount_value">{props.translations.common.discount_value}</label>
                            <input className={`form-control`} id="name" type="text" title={"latin"} placeholder={props.translations.common.discount_value} name="discount_value" onChange={props.onChange} value={props.promocode.discount_value} />
                        </div>
                    </div>
                </div>

                <div className="form-group">
                    <label htmlFor="catHierarchyId">{props.translations.common.discount_type}</label>

                    <Select
                        value={props.promocode.discount_type ? discountTypesOptions[discountTypesOptions.findIndex(p => p.value == props.promocode.discount_type)] : (props.promocode.discount_type == 0 ? { 'value': 'money', 'label': 'Money' } : '')}
                        name="discount_type"
                        options={discountTypesOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        placeholder=''
                        onChange={v => props.onChangeSelect('discount_type', v)}
                    />
                </div>

                <div className="form-group ">
                    <label htmlFor="catHierarchyId">{props.translations.common.place}</label>

                    <Select
                        value={props.promocode.placeId ? placesOptions[placesOptions.findIndex(p => p.value == props.promocode.placeId)] : ''}
                        name="placeId"
                        options={placesOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        placeholder=''
                        onChange={v => props.onChangeSelect('placeId', v)}
                    />
                </div>

                <div className="form-group ">
                    <label htmlFor="catHierarchyId">{props.translations.common.ordertype}</label>

                    {/* <Select
                        value={props.category.currPlaces}
                        isMulti
                        name="colors"
                        options={placesOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={props.onChangeSelectAutocompletePlaces}
                    // onChange={(values, { action, removedValue }) => props.ingredientsOnChange(values, { action, removedValue }, 'removable')}
                    /> */}

                    <Select
                        isMulti
                        value={props.promocode.ordertypes}
                        name="ordertypes"
                        options={currPlaceOrderTypes}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        placeholder=''
                        onChange={v => props.onChangeSelectAutocomplete('ordertypes', v)}
                    />
                </div>

                <div className="row">

                    <div className="col-md-12 vertical-center" style={{ marginBottom: 13 }}>
                        <label className="col-form-label" >
                            {props.translations.common.valid_from}
                        </label>
                        <DatePicker
                            className="form-control vertical-center"
                            value={props.promocode.valid_from ? (new Date(props.promocode.valid_from).getDate() + '-' + (new Date(props.promocode.valid_from).getMonth() + 1) + '-' + new Date(props.promocode.valid_from).getFullYear()) : ''}
                            onChange={date => props.onFieldChange('valid_from', date)}
                            dateFormat="d-M-Y"
                            style={{ display: 'block' }}
                        />
                    </div>

                    <div className="col-md-12 vertical-center" style={{ marginBottom: 13 }}>
                        <label className="col-form-label" >
                            {props.translations.common.valid_to}
                        </label>
                        <DatePicker
                            className="form-control vertical-center"
                            value={props.promocode.valid_to ? (new Date(props.promocode.valid_to).getDate() + '-' + (new Date(props.promocode.valid_to).getMonth() + 1) + '-' + new Date(props.promocode.valid_to).getFullYear()) : ''}
                            onChange={date => props.onFieldChange('valid_to', date)}
                            dateFormat="d-M-Y"
                            style={{ display: 'block' }}
                        />
                    </div>

                    <div className="col-sm-12">
                        <div className="form-group">
                            <label htmlFor="code">{props.translations.common.description}</label>
                            <input className={`form-control ${props.nameError.message ? "is-invalid" : ""}`} id="description" type="text" title={"latin"} placeholder={props.translations.common.description} name="description" onChange={props.onChange} value={props.promocode.description} />
                        </div>
                    </div>

                    <label className="col-md-2 col-form-label">{props.translations.common.used}</label>
                    <div className="col-md-10 col-form-label">
                        <label className="switch switch-label switch-pill switch-outline-primary-alt">
                            <input className="switch-input" type="checkbox" name="used" onChange={props.onChangeCheckbox} checked={props.promocode.used} />
                            <span className="switch-slider" data-checked={props.translations.common.yes} data-unchecked={props.translations.common.no}></span>
                        </label>
                    </div>

                    <label className="col-md-2 col-form-label">{props.translations.common.active}</label>
                    <div className="col-md-10 col-form-label">
                        <label className="switch switch-label switch-pill switch-outline-primary-alt">
                            <input className="switch-input" type="checkbox" name="active" onChange={props.onChangeCheckbox} checked={props.promocode.active} />
                            <span className="switch-slider" data-checked={props.translations.common.yes} data-unchecked={props.translations.common.no}></span>
                        </label>
                    </div>

                    <label className="col-md-2 col-form-label">{props.translations.promocode.ignore_other_discounts}</label>
                    <div className="col-md-10 col-form-label">
                        <label className="switch switch-label switch-pill switch-outline-primary-alt">
                            <input className="switch-input" type="checkbox" name="ignore_other_discounts" onChange={props.onChangeCheckbox} checked={props.promocode.ignore_other_discounts} />
                            <span className="switch-slider" data-checked={props.translations.common.yes} data-unchecked={props.translations.common.no}></span>
                        </label>
                    </div>

                    <label className="col-md-2 col-form-label">{props.translations.promocode.never_become_used}</label>
                    <div className="col-md-10 col-form-label">
                        <label className="switch switch-label switch-pill switch-outline-primary-alt">
                            <input className="switch-input" type="checkbox" name="never_become_used" onChange={props.onChangeCheckbox} checked={props.promocode.never_become_used} />
                            <span className="switch-slider" data-checked={props.translations.common.yes} data-unchecked={props.translations.common.no}></span>
                        </label>
                    </div>

                </div>


                <hr />
                <br />

                <div className="form-actions">
                    {props.isAdding == '1' ? '' : <button className="btn btn-danger mright10" type="button" data-toggle="modal" data-target={"#dangerModal" + props.promocode.id} > {props.translations.common.delete}</button>}

                    <DeleteModal
                        id={props.promocode.id}
                        title={props.promocode.code}
                        deleteFunc={props.deleteFunc}
                        translations={props.translations}
                    />

                    <NavLink to={ADMIN_URL + 'promocodes'}>
                        <button className="btn btn-secondary" type="button">
                            {props.translations.common.cancel}
                        </button>
                    </NavLink>

                    <button className="fright btn btn-primary" type="submit" onClick={props.onSubmit}>
                        {props.isAdding == '1' ? props.translations.common.add : props.translations.common.save}
                    </button>

                </div>

            </div>

        </form >
    )
}

export default AddEditPromocodeForm;