import React from 'react'
import { NavLink } from 'react-router-dom';
import { ADMIN_URL, SETTINGS_IMG_URL } from '../../../constants'
// import DeleteModal from '../../common/DeleteModal';
// import ForbidModal from './../../forbidden/components/ForbidModal';
import Utils from './../../../utils/Utils';
import Auth from '../../../utils/Auth';

const emptySymbol = '';
const yesOption = <span className="badge badge-success">Да</span>
const noOption = <span className="badge badge-secondary">Не</span>;

const ListSettingRow = (props) => {
    let val = props.setting.is_image == 1
        ? <img style={{ maxHeight: 100 }} src={Utils.getSettingsPath(props.setting.value)} />
        :
        (props.setting.json == 1 ? "-"
            :

            (
                props.translations.settings && props.translations.settings[(props.setting.name)] && props.translations.settings[(props.setting.name)][(props.setting.value)] ?
                    props.translations.settings[(props.setting.name)][(props.setting.value)]
                    :
                    Utils.truncate(props.setting.value, 200, 200)
            )
        )

    return (
        <tr key={props.setting.id}>
            {/* <td>
                {props.setting.comment ? props.setting.comment : emptySymbol}
            </td> */}
            <td>
                {props.translations.settings && props.translations.settings[(props.setting.name)] && props.translations.settings[(props.setting.name)].default ? props.translations.settings[(props.setting.name)].default : (props.setting.comment ? props.setting.comment : emptySymbol)}
            </td>
            <td>
                {props.setting.value == 0 ?
                    <span className="badge badge-secondary">{val}</span>
                    :
                    props.setting.value == 1 ?
                        <span className="badge badge-success">{val}</span>
                        :
                        props.setting.colorpicker == 1 ?
                            <input type="color" disabled value={props.setting.value.match(/#[0-9a-f]{3,6}/i) && props.setting.value.match(/#[0-9a-f]{3,6}/i)[0] ? props.setting.value.match(/#[0-9a-f]{3,6}/i)[0] : null} />
                            :
                            props.setting.ckeditor == 1 ?
                                val.replace(/(<([^>]+)>)/gi, "")
                                :
                                val
                }
                {/* {props.setting.value ? Utils.truncate(props.setting.value, 200, 200) : emptySymbol} */}
            </td>

            <td>
                {props.setting.disabled == 0 || (Auth.hasAccess('SALES_ADMIN')) ?
                    <NavLink to={ADMIN_URL + `settings/category/${props.setting.settingsCategoryId}/setting/${props.setting.id}`}>
                        <button className="btn btn-pill btn-link" type="button" title={props.setting.disabled == 1 ? 'This setting is disabled by default' : ''}>
                            <i className="fas fa-edit" style={{ color: props.setting.disabled == 1 ? '#e20000' : 'inherit' }}></i>
                        </button>
                    </NavLink>
                    :
                    null
                }

                {/* <button className="btn btn-pill btn-link" type="button" data-toggle="modal" data-target={"#dangerModal" + props.setting.id} >
                    <i className="fas fa-trash"></i>
                </button> */}
            </td>
        </tr>
    )
}

export default ListSettingRow;