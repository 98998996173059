import React from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';

const SoundActivatedDialog = (props) => (

    <Dialog
        open={!props.soundActivated}
        onClose={() => props.activateSound(true)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={"mui-dialog-title-info"}
    >
        <DialogTitle id="alert-dialog-title">
            {props.translations.common.info}
        </DialogTitle>
        <DialogContent className={"mui-dialog-content"}>
            <DialogContentText id="alert-dialog-description">
                {props.translations.orders.activete_sound_dialog_title}
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={() => props.activateSound(true)} className={"mui-color-secondary"} autoFocus>
                {props.translations.common.close}
            </Button>
        </DialogActions>
    </Dialog>
)

export default SoundActivatedDialog;


