import React, { Component } from 'react'
import { connect } from 'react-redux';
import { addRemoveFunctionalityModule, listFunctionalityModules } from './../../actions/places.actions';
import MessagesContainer from '../../../messages/MessagesContainer';

class FunctionalityModulesTab extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            modulesPrices: []
        }
    }

    
	componentWillMount() {
        this.props.listFunctionalityModules();

    }

    componentDidMount() {
        if (this.props.place && this.props.place.functionality_modules) {

            let modulesPrices = {};
            (this.props.place.functionality_modules).map(mod => {
                modulesPrices[mod.id] = mod.place_functionality_module.price;
            })

            this.setState({ modulesPrices: modulesPrices })
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.place && nextProps.place.functionality_modules) {

            let modulesPrices = {};
            (nextProps.place.functionality_modules).map(mod => {
                modulesPrices[mod.id] = mod.place_functionality_module.price;
            })

            this.setState({ modulesPrices: modulesPrices })
        }
    }

    onChangeFunctionalityModule = (checked, moduleId) => {
        this.props.addRemoveFunctionalityModule(!checked, this.props.place.id, moduleId)
    }

    // onChangeFunctionalityModulePrice = (moduleId, price) => {
    //     this.props.addRemoveFunctionalityModule(true, this.props.place.id, moduleId, price)
    // }

    updatePrice = (e, moduleId) => {
        e.preventDefault();

        let price = this.state.modulesPrices[moduleId]
        this.props.addRemoveFunctionalityModule(true, this.props.place.id, moduleId, price)
        
    }

    onPriceChange = (e, moduleId) => {
        let price = e.target.value;
        let modulesPrices = this.state.modulesPrices;

        modulesPrices[moduleId] = price;
        this.setState({ modulesPrices: modulesPrices })
    }

    render() {
        return (
        
            <div className="col-sm-12 col-md-12">
                <div className="card">
                
                    <form>
                        <MessagesContainer />
                        <div className="card-body">

                            <div className=" row">
                                {/* <label className="col-md-12 col-form-label">{this.props.translations.places.functionality_modules}</label> */}

                                {Object.values(this.props.functionalityModules).map(functionalityModule => {
                                    let checked = this.props.place && this.props.place.functionality_modules && 
                                        this.props.place.functionality_modules.filter(m => m.id == functionalityModule.id).length > 0 ? true : false
                                    let placeModule = this.props.place && this.props.place.functionality_modules && this.props.place.functionality_modules.find(m => m.id == functionalityModule.id)
                                    let price = 0;
                                    if(placeModule) {
                                        price = this.state.modulesPrices[functionalityModule.id] 
                                    }

                                    return (
                                        <div className="col-md-12 col-sm-12 col-12 col col-form-label centered">
                                            <div className="row">

                                                <div className="col-md-2">
                                                    {functionalityModule.name}
                                                </div>
                                                <div className="col-md-2">
                                                    <label className="switch switch-label switch-pill switch-outline-primary-alt">
                                                        <input className="switch-input" type="checkbox" name="active"
                                                            checked={checked}
                                                            onChange={() => this.onChangeFunctionalityModule(checked, functionalityModule.id)}
                                                        />
                                                        <span className="switch-slider" data-checked={this.props.translations.common.yes} data-unchecked={this.props.translations.common.no}></span>
                                                    </label>
                                                </div>

                                                {checked ? 
                                                    <React.Fragment>
                                                        <div className="col-md-2">
                                                            Price
                                                        </div>
                                                        <div className="col-md-4">
                                                            <input type="text" name="price" className="form-control" value={price} 
                                                                onChange={(ev) => this.onPriceChange(ev, functionalityModule.id)}
                                                                onBlur={(ev) => this.updatePrice(ev, functionalityModule.id)}
                                                            />
                                                        </div>
                                                    </React.Fragment>
                                                :
                                                null}
                                            </div>
                                        </div>
                                    )
                                })
                                }

                            </div>

                            <br />

                        </div>

            </form>

                </div>
            </div>
        )    
        }
}




const mapStateToProps = (state, ownProps) => {

    return {
        functionalityModules: state.places.functionality_modules || []
        
    }

};

export default connect(mapStateToProps, { addRemoveFunctionalityModule, listFunctionalityModules })(FunctionalityModulesTab);