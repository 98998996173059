import React from 'react'
import { NavLink } from 'react-router-dom';
import { ADMIN_URL } from '../../../constants.js'
import DeleteModal from './../../common/DeleteModal';
import { Link } from 'react-router-dom';
import MessagesContainer from './../../messages/MessagesContainer';
import Utils from './../../../utils/Utils';
import PolygonMap from './PolygonMap.js';

// let md5 = require('md5');

const AddEditDeliveryShapeForm = (props) => {

    return (

        // <form onSubmit={props.onSubmit}>
        <form>

            <div className="card-body">
                <MessagesContainer />

                <div className="row">
                    <div className="col-sm-12">
                        <div className="form-group">
                            <label htmlFor="name">{props.translations.common.name}</label>
                            <input className={`form-control`} id="name" type="text" placeholder={props.translations.common.name} name="name" onChange={props.onChange} value={props.deliveryShape.name} />
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-12">
                        <div className="form-group">
                            <label htmlFor="name">{props.translations.common.priority}</label>
                            <input className={`form-control`} id="priority" type="text" placeholder={props.translations.common.priority} name="priority" onChange={props.onChange} value={props.deliveryShape.priority} />
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-12">
                        <div className="form-group">
                            <label htmlFor="name">{props.translations.delivery_shapes.min_order_price}</label>
                            <input className={`form-control`} id="min_order_price" type="text" placeholder={props.translations.delivery_shapes.min_order_price} name="min_order_price" onChange={props.onChange} value={props.deliveryShape.min_order_price} />
                        </div>
                    </div>
                </div>
                
                <div className="row">
                    <div className="col-sm-12">
                        <div className="form-group">
                            <label htmlFor="name">{props.translations.delivery_shapes.delivery_price}</label>
                            <input className={`form-control`} id="delivery_price" type="text" placeholder={props.translations.delivery_shapes.delivery_price} name="delivery_price" onChange={props.onChange} value={props.deliveryShape.delivery_price} />
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-12">
                        <div className="form-group">
                            <label htmlFor="name">{props.translations.delivery_shapes.required_order_price_for_free_delivery}</label>
                            <input className={`form-control`} id="required_order_price_for_free_delivery" type="text" placeholder={props.translations.delivery_shapes.required_order_price_for_free_delivery} name="required_order_price_for_free_delivery" onChange={props.onChange} value={props.deliveryShape.required_order_price_for_free_delivery} />
                        </div>
                    </div>
                </div>
                
                <div className="row">
                    <div className="col-lg-3 col-sm-12">
                        <div className="form-group">
                            <label htmlFor="name">{props.translations.delivery_shapes.coords}</label>
                            <textarea className={`form-control`} id="coords" type="text" rows={15}
                                placeholder={props.translations.delivery_shapes.coords} name="coords" onChange={props.onChange} 
                                value={props.deliveryShape.coords}
                            >
                            </textarea>
                        </div>
                    </div>

                    <div className="col-lg-9 col-sm-12">
                        <PolygonMap
                            coords={props.deliveryShape.coords}
                            onChangeField={props.onChangeField} 
                            translations={props.translations}
                        />
                    </div>

                </div>

                {/* <div className=" row">
                    <label className="col-md-1 col-form-label">{props.translations.common.active}</label>
                    <div className="col-md-11 col-form-label">
                        <label className="switch switch-label switch-pill switch-outline-primary-alt">
                            <input className="switch-input" type="checkbox" name="active" onChange={props.onChangeCheckbox} checked={props.deliveryShape.active} />
                            <span className="switch-slider" data-checked={props.translations.common.yes} data-unchecked={props.translations.common.no}></span>
                        </label>
                    </div>
                </div> */}


                <hr />
                <br />

                <div className="form-actions pt-4">

                    {props.isAddingDeliveryShape == '1' ? '' : <button className="btn btn-danger mright10" type="button" data-toggle="modal" data-target={"#dangerModal" + props.deliveryShape.id} > {props.translations.common.delete}</button>}

                    <DeleteModal
                        id={props.deliveryShape.id}
                        title={props.deliveryShape.name}
                        deleteFunc={props.deleteDeliveryShape}
                        translations={props.translations}
                    />

                    <NavLink to={ADMIN_URL + 'deliveryShapes'}>
                        <button className="btn btn-secondary" type="button">
                            {props.translations.common.cancel}
                        </button>
                    </NavLink>

                    <button className="fright btn btn-primary" type="submit" onClick={props.onSubmit}>
                        {props.isAddingDeliveryShape == '1' ? props.translations.common.add : props.translations.common.save}
                    </button>

                </div>

            </div>

        </form>
    )
}

export default AddEditDeliveryShapeForm;