import React, { Component } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'

import { listBookings, editBooking } from '../actions/bookings.actions';
import ListBookingsItem from './ListBookingsItem';
import ListBookingsItemBox from './ListBookingsItemBox';
import { NavLink } from 'react-router-dom';
import { ADMIN_URL, ITEMS_PER_PAGE, ITEMS_PAGE_RANGE_DISPLAYED } from '../../../constants.js'
import Pagination from "react-js-pagination";
import { push } from 'react-router-redux';
// import Filter from './Filter';
import enum_admin_notify_on_new_order from './../../common/enums/admin_notify_on_new_order.json';
import MessagesContainer from '../../messages/MessagesContainer';
import { translations } from '../../common/translations';
import Filter from './Filter';
import soundfileBooking from './../../orders/sounds/booking/1.wav'
import Sound from 'react-sound';
import { setSoundActivation, changeOrdersStyleType } from '../../../actions/adminActions';
import SoundActivatedDialog from './../../common/SoundActivatedDialog';
import Auth from '../../../utils/Auth';
import Utils from '../../../utils/Utils';

class ListBookingsPage extends React.Component {
    constructor(props) {
        super(props);
        this.activeDefaultLanguage = 'bg'; // TODO add it in db
        this.state = {
            search_start_time: '',
            search_end_time: '',
            search_status: '',
            timer: '',
            activeTab: 1,
            visibleFilter: false,
        };
        this.statuses = [
            'unconfirmed', 'confirmed', 'canceled'
        ]
    }

    componentWillMount() {
        this.props.listBookings();
    }

    componentDidMount() {
        const setNotificationTimeout = () => {
            this.props.listBookings(this.state.search_start_time, this.state.search_end_time, this.state.search_status);
        }
        let timer = setInterval(setNotificationTimeout, 10000);
        this.setState({ timer });
    }

    componentWillUnmount() {
        clearInterval(this.state.timer);
    }

    handlePageChange = (page) => {
        this.props.dispatch(push('/admin/bookings?page=' + page))
    }

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
        this.handlePageChange(1);
    }

    onChangeSelectAutocompleteStatus = (id, newValue) => {
        let post = {
            id: id,
            status: newValue.value
        }

        // if (Utils.getSettingsValue(settings, 'admin_notify_on_new_order', '1') == enum_admin_notify_on_new_order.ORDERED_ORDERS) {
        //     if (order.ordertypeId == 1 && ((order.seen_order == 0 && status != "paid" && status != "ordered") || (order.seen_bill_wanted == 0 && status == "paid"))) {
        //         this.updateSeen([order.tableId], status == "paid" ? 'seen_bill_wanted' : 'seen_order')
        //         this.props.hideLoading();
        //     } else {
        //         this.props.updateOrderById(orderId, { seen_order: 1 })
        //         this.props.hideLoading();
        //     }
        // }
        this.props.editBooking(post);
    }

    onChangeSelectAutocomplete = (fieldName, newValue) => {
        this.setState({ [fieldName]: newValue.value })
    }

    onDateChange = (fieldName, date) => {
        this.setState({ [fieldName]: date });
    }

    callFilter = () => {
        this.props.listBookings(this.state.search_start_time, this.state.search_end_time, this.state.search_status);
    }


    setActiveTab = (tabNum) => {
        const { settings } = this.props;

        let bookings = this.props.items;
        // console.log('bookings', bookings)
        if (this.state.activeTab == 2) {
            bookings = bookings.filter(o => (o.status == "unconfirmed"));
        }

        this.setState({ activeTab: tabNum });

        if (tabNum == 2) {
            if (bookings && bookings.length > 0) {
                // if (Utils.getSettingsValue(settings, 'admin_notify_on_new_order', '1') == enum_admin_notify_on_new_order.UNREAD_ORDERS) {
                this.props.editBooking({ seen: true, placeId: Auth.getUserPlace() })
                // this.updateSeen(this.getSelectedTableIds(), 'seen_order', unreadOrderedItems);
                // }
            }
        }
    }

    render() {
        let allBookings = this.props.items

        let bookings = allBookings;
        let unseenBookingItems = 0;
        unseenBookingItems = bookings.filter(o => o.seen == 0).length;
        let notContirmedBookingItems = bookings.filter(o => (o.status == "unconfirmed"));
        let confirmedBookingItems = bookings.filter(o => (o.status == "confirmed"));
        let canceledBookingItems = bookings.filter(o => (o.status == "canceled"));

        if (this.state.activeTab == 2) {
            bookings = notContirmedBookingItems
        } else if (this.state.activeTab == 3) {
            bookings = confirmedBookingItems
        } else if (this.state.activeTab == 4) {
            bookings = canceledBookingItems
        }

        let autocompleteStatuses = []
        this.statuses.map(status => {
            autocompleteStatuses.push({ 'value': status, 'label': this.props.translations.bookings.statuses[status] });
        });
        // if (this.state.search_name.length > 0
        //     || this.state.search_active != 0
        // ) {
        //     catItems = Object.values(catItems).filter(i => {
        //         return (
        //             ((i.name).toLocaleLowerCase().includes((this.state.search_name).toLocaleLowerCase()))
        //             && (this.state.search_active != 0 ? i.active == Boolean(parseInt(this.state.search_active) - 1) : true)
        //         )
        //     })
        // }

        const items_count = (bookings).length
        const current_page = this.props.page;
        const start_offset = (current_page - 1) * ITEMS_PER_PAGE;
        let start_count = 0;

        return (
            <main className="main">

                {/* <!-- Breadcrumb--> */}
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><NavLink to={ADMIN_URL}>{this.props.translations.common.dashboard}</NavLink></li>
                    {/* <li className="breadcrumb-item"><NavLink to={ADMIN_URL + 'bookings'}>{this.props.translations.sidemenu.orders}</NavLink></li> */}
                    <li className="breadcrumb-item active">{this.props.translations.bookings.title}</li>
                    <li className="breadcrumb-menu d-md-down-none">
                        <div className="btn-group" role="group" aria-label="Button group">
                            <NavLink to={ADMIN_URL + `bookings/rooms`} className="btn" >
                                <i className="nav-icon icon-settings"></i> &nbsp; {this.props.translations.bookings.rooms}
                            </NavLink>
                        </div>

                        &nbsp;
                            <div className="btn-group" role="group" aria-label="Button group">
                            <button
                                className="btn btn-warning btn-ladda ladda-button"
                                style={{ padding: 5, color: 'white' }}
                                onClick={() => this.setState({ visibleFilter: !this.state.visibleFilter })}
                            >
                                {this.props.translations.common.filter}
                            </button>
                        </div>

                        &nbsp;
                            <i className={"nav-icon hoverable " + (Utils.getOrdersStyleType() == 'row' ? 'icon-grid' : 'icon-list')} onClick={this.props.changeOrdersStyleType} style={{
                            fontSize: 20,
                            background: 'white',
                            'borderRadius': '50%',
                            'verticalAlign': 'middle',
                            border: '1px solid #ccc',
                            padding: 9
                        }}></i>
                    </li>

                </ol>

                <SoundActivatedDialog
                    soundActivated={this.props.soundActivated}
                    activateSound={() => this.props.setSoundActivation(true)}
                    translations={this.props.translations}
                />

                {
                    this.props.soundActivated ?
                        <React.Fragment>
                            {unseenBookingItems > 0 ?
                                <Sound
                                    url={soundfileBooking}
                                    playStatus={Sound.status.PLAYING}
                                />
                                :
                                null
                            }
                        </React.Fragment>
                        :
                        null
                }

                <div className="container-fluid">
                    <div className="animated fadeIn">
                        <div className="row">

                            {/* <!-- /.col--> */}
                            <div className="col-lg-12 p-0">
                                {this.state.visibleFilter ?
                                    <Filter
                                        search_start_time={this.state.search_start_time}
                                        search_end_time={this.state.search_end_time}
                                        search_status={this.state.search_status}
                                        statuses={this.statuses}
                                        translations={this.props.translations}
                                        onDateChange={this.onDateChange}
                                        onChangeSelectAutocomplete={this.onChangeSelectAutocomplete}
                                        callFilter={this.callFilter}
                                    />
                                    :
                                    null
                                }
                                <MessagesContainer />



                                <ul className="nav nav-tabs" role="tablist" style={{ display: 'flex' }}>
                                    {/* Vsichki */}
                                    <li className="nav-item">
                                        <a className={"nav-link border-left-0 " + (this.state.activeTab == 1 ? "active" : "")} data-toggle="tab" onClick={() => this.setState({ activeTab: 1 })} role="tab" aria-controls="home">
                                            <b>{allBookings.length}</b> {this.props.translations.bookings.all}
                                        </a>
                                    </li>

                                    {/* Nepotvurdeni */}
                                    <li className="nav-item">
                                        <a className={"nav-link " + (this.state.activeTab == 2 ? "active" : "")} data-toggle="tab" onClick={() => this.setActiveTab(2, unseenBookingItems)} role="tab" aria-controls="home">
                                            {notContirmedBookingItems.length} {this.props.translations.bookings.new} {unseenBookingItems > 0 ? <span className="badge badge-pill badge-danger">{unseenBookingItems}</span> : null}
                                        </a>
                                    </li>

                                    {/* Potvurdeni */}
                                    <li className="nav-item">
                                        <a className={"nav-link " + (this.state.activeTab == 3 ? "active" : "")} data-toggle="tab" onClick={() => this.setActiveTab(3, unseenBookingItems)} role="tab" aria-controls="home">
                                            {confirmedBookingItems.length} {this.props.translations.bookings.confirmed}
                                        </a>
                                    </li>

                                    {/* Anulirani */}
                                    <li className="nav-item">
                                        <a className={"nav-link " + (this.state.activeTab == 4 ? "active" : "")} data-toggle="tab" onClick={() => this.setActiveTab(4, unseenBookingItems)} role="tab" aria-controls="home">
                                            {canceledBookingItems.length} {this.props.translations.bookings.calceled}
                                        </a>
                                    </li>

                                </ul>

                                {Utils.getOrdersStyleType() == 'row' ?

                                    <div className="card">

                                        <div className="card-body">

                                            <table className="table table-responsive-sm table-striped">
                                                <thead>
                                                    <tr>
                                                        <th>{this.props.translations.common.client}</th>
                                                        <th>{this.props.translations.bookings.duration}</th>
                                                        <th>{this.props.translations.bookings.service}</th>
                                                        <th>{this.props.translations.common.price}</th>
                                                        <th>{this.props.translations.common.status}</th>
                                                        <th>{this.props.translations.common.user}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    {(bookings).map((booking, index) => {
                                                        if (index >= start_offset && start_count < ITEMS_PER_PAGE) {
                                                            start_count++;
                                                            return <ListBookingsItem
                                                                key={booking.id}
                                                                booking={booking}
                                                                // deleteBooking={this.deleteBooking}
                                                                // activeDefaultLanguage={this.activeDefaultLanguage}
                                                                translations={this.props.translations}
                                                                currLang={this.props.currLang}
                                                                autocompleteStatuses={autocompleteStatuses}
                                                                onChangeSelectAutocompleteStatus={this.onChangeSelectAutocompleteStatus}
                                                            />
                                                        }
                                                    })}

                                                </tbody>
                                            </table>

                                            <Pagination
                                                className="pagination"
                                                itemClass="page-item"
                                                activePage={current_page}
                                                activeClass="active"
                                                linkClass="page-link"
                                                prevPageText="<"
                                                nextPageText=">"
                                                firstPageText="<<"
                                                lastPageText=">>"
                                                itemsCountPerPage={ITEMS_PER_PAGE}
                                                totalItemsCount={items_count}
                                                pageRangeDisplayed={ITEMS_PAGE_RANGE_DISPLAYED}
                                                onChange={this.handlePageChange}
                                            />
                                        </div>
                                    </div>

                                    :
                                    <div className="row" >

                                        {(bookings).map((booking, index) => {
                                            if (index >= start_offset && start_count < ITEMS_PER_PAGE) {
                                                start_count++;
                                                return <ListBookingsItemBox
                                                    key={booking.id}
                                                    booking={booking}
                                                    // deleteBooking={this.deleteBooking}
                                                    // activeDefaultLanguage={this.activeDefaultLanguage}
                                                    translations={this.props.translations}
                                                    currLang={this.props.currLang}
                                                    autocompleteStatuses={autocompleteStatuses}
                                                    onChangeSelectAutocompleteStatus={this.onChangeSelectAutocompleteStatus}
                                                />
                                            }
                                        })}
                                    </div>

                                }

                                {/* <!-- /.col--> */}
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        )
    }
}

const mapStateToProps = (state, dispatch) => {
    return {
        items: state.bookings.bookings || [],
        page: Number(state.router.location.query.page) || 1,
        translations: state.lang,
        currLang: "bg",
        soundActivated: state.admin.soundActivated,
        // orderStyleType: state.admin.orderStyleType,
        settings: state.settings.settings,
    }
};

const mapDispatchToProps = (dispatch, props) => {
    return {
        dispatch,
        ...bindActionCreators({ listBookings, editBooking, setSoundActivation, changeOrdersStyleType }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ListBookingsPage);
