import React, { Component } from 'react'
import { connect } from 'react-redux';
import { addRemovePromoCategoryProduct, editCategoryHierarchy } from './../../../actions/categoriesHierarchyActions';
import MessagesContainer from './../../messages/MessagesContainer';
import { addErrorMessages } from './../../messages/messages.actions';
import moment from 'moment';
import Select from 'react-select';

class BookingTab extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            booking_start_work_time: '',
            booking_end_work_time: '',
        }
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.category.id) {
            this.setState({
                booking_start_work_time: nextProps.category.booking_start_work_time,
                booking_end_work_time: nextProps.category.booking_end_work_time,
            })
        }
    }

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    validate = () => {
        // check if time is valid (date is just a random)
        if(!(moment("2021-12-13 " + this.state.booking_start_work_time,"YYYY-MM-DD HH:mm", true).isValid()) )
        {
            this.props.addErrorMessages([this.props.translations.messages.errors.invalid_start_work_time]);
            return false;
        }
        if(!(moment("2021-12-13 " + this.state.booking_end_work_time,"YYYY-MM-DD HH:mm", true).isValid()) )
        {
            this.props.addErrorMessages([this.props.translations.messages.errors.invalid_end_work_time]);
            return false;
        }

        return true;        
    }

    save = () => {
        if(!this.validate()) return;

        let post = {
            id: this.props.category.id,
            booking_start_work_time: this.state.booking_start_work_time,
            booking_end_work_time: this.state.booking_end_work_time,
        }

        this.props.editCategoryHierarchy(post);
    }

    render() {
        return (
            <div className="">
                <MessagesContainer />

                <div className="row">
                    <div className="form-group col-sm-12">
                        <label htmlFor="booking_start_work_time">{this.props.translations.categories.booking_start_work_time}</label>
                        <input className={`form-control`} type="text" placeholder={this.props.translations.categories.booking_start_work_time} name="booking_start_work_time" onChange={this.onChange} value={this.state.booking_start_work_time} />
                    </div>
                    <div className="form-group col-sm-12">
                        <label htmlFor="name">{this.props.translations.categories.booking_end_work_time}</label>
                        <input className={`form-control`} type="text" placeholder='' name="booking_end_work_time" onChange={this.onChange} value={this.state.booking_end_work_time} />
                    </div>

                    <div className="form-group col-sm-12">
                        <button className="fright btn btn-primary" onClick={this.save}>{this.props.translations.common.save}</button>
                    </div>                    
                </div>

                
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        translations: state.lang,
    }
};

export default connect(mapStateToProps, { editCategoryHierarchy, addErrorMessages })(BookingTab);