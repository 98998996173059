import { LIST_TAGS } from '../actions/types';

const initialState = {
    tags: [],
}

export default function (state = initialState, action) {
    switch (action.type) {
        case LIST_TAGS: {
            return {
                ...state,
                tags: action.payload,
            }
        }
        default:
            return state;
    }
}