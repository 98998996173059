import React, { Component } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { NavLink } from 'react-router-dom';
import Pagination from "react-js-pagination";
import { push } from 'react-router-redux';
import { listSettings, listSettingsCategories } from './../actions/settings.actions';
import ListSettingRow from './ListSettingRow';
import { ADMIN_URL, ITEMS_PER_PAGE, ITEMS_PAGE_RANGE_DISPLAYED } from '../../../constants';
import MessagesContainer from './../../messages/MessagesContainer';
import queryString from 'query-string'

class ListSettingsPage extends React.Component {
    constructor(props) {
        super(props);
    }

    componentWillMount() {
        this.props.listSettingsCategories()
        this.props.listSettings()
    }

    handlePageChange = (page) => {
        this.props.dispatch(push(`/admin/settings/category/${this.props.settingCatId}?page=${page}`))
    }

    render() {
        let settings = this.props.settings;
        // console.log('this.props.settingsCategories', this.props.settingsCategories);
        // console.log('this.props.settingsCategoryName', this.props.settingsCategoryName)
        // Pagination variables
        const items_count = Object.keys(settings).length
        const current_page = this.props.page;
        const start_offset = (current_page - 1) * ITEMS_PER_PAGE;
        let start_count = 0;


        return (
            <React.Fragment>
                <main className="main">

                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><NavLink to={ADMIN_URL}>{this.props.translations.common.dashboard}</NavLink></li>
                        <li className="breadcrumb-item"><NavLink to={ADMIN_URL + 'settings'}>{this.props.translations.sidemenu.settings}</NavLink></li>
                        <li className="breadcrumb-item ">{this.props.translations.settings_categories && this.props.translations.settings_categories[(this.props.settingsCategoryName)] ? this.props.translations.settings_categories[(this.props.settingsCategoryName)] : this.props.settingsCategoryName}</li>
                    </ol>

                    <div className="container-fluid">
                        <div className="animated fadeIn">
                            <div className="row">

                                <div className="col-lg-12">
                                    <div className="card">

                                        <div className="card-body">

                                            <MessagesContainer />

                                            {this.props.settingCatId == 6 ?
                                                <React.Fragment>
                                                    <div className="row">
                                                        <div className="col-md-2 col-sm-6">
                                                            <div className="card" style={{ textAlign: 'center' }}>
                                                                <NavLink to="/admin/settings/ordertype-prices/delivery">
                                                                    <div className="card-body">
                                                                        <i className="fas fa-truck" style={{ fontSize: 40 }}></i>
                                                                        <br />
                                                                        {this.props.translations.settings.ordertype_prices.delivery}
                                                                    </div>
                                                                </NavLink>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-2 col-sm-6">
                                                            <div className="card" style={{ textAlign: 'center' }}>
                                                                <NavLink to="/admin/delivery-shapes">
                                                                    <div className="card-body">
                                                                        <i className="fas fa-map-marker" style={{ fontSize: 40 }}></i>
                                                                        <br />
                                                                        {this.props.translations.settings.delivery_shapes}
                                                                    </div>
                                                                </NavLink>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-2 col-sm-6">
                                                            <div className="card" style={{ textAlign: 'center' }}>
                                                                <NavLink to="/admin/settings/delivery-radiuses">
                                                                    <div className="card-body">
                                                                        <i className="fas fa-map-marker" style={{ fontSize: 40 }}></i>
                                                                        <br />
                                                                        {this.props.translations.settings.delivery_radiuses}
                                                                    </div>
                                                                </NavLink>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-2 col-sm-6">
                                                            <div className="card" style={{ textAlign: 'center' }}>
                                                                <NavLink to="/admin/regions">
                                                                    <div className="card-body">
                                                                        <i className="fas fa-map" style={{ fontSize: 40 }}></i>
                                                                        <br />
                                                                        {this.props.translations.sidemenu.delivery_regions}
                                                                    </div>
                                                                </NavLink>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-1
                                                         col-sm-6">
                                                            <div className="card" style={{ textAlign: 'center' }}>
                                                                <NavLink to="/admin/settings/ordertype-discounts/delivery">
                                                                    <div className="card-body">
                                                                        <i className="fas fa-percent" style={{ fontSize: 40 }}></i>
                                                                        <br />
                                                                        {this.props.translations.settings.ordertype_discounts.delivery}
                                                                    </div>
                                                                </NavLink>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-1 col-sm-6">
                                                            <div className="card" style={{ textAlign: 'center' }}>
                                                                <NavLink to="/admin/settings/ordertype-discounts/preorder">
                                                                    <div className="card-body">
                                                                        <i className="fas fa-percent" style={{ fontSize: 40 }}></i>
                                                                        <br />
                                                                        {this.props.translations.settings.ordertype_discounts.pickup}
                                                                    </div>
                                                                </NavLink>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-2 col-sm-6">
                                                            <div className="card" style={{ textAlign: 'center' }}>
                                                                <NavLink to="/admin/registered-user-discount">
                                                                    <div className="card-body">
                                                                        <i className="fas fa-percent" style={{ fontSize: 40 }}></i>
                                                                        <br />
                                                                        {this.props.translations.registered_user_discount.title}
                                                                    </div>
                                                                </NavLink>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </React.Fragment>
                                                :
                                                null
                                            }

                                            <table className="table table-responsive-sm table-striped break-content">
                                                <thead>
                                                    <tr>
                                                        {/* <th width="40%">Коментар</th> */}
                                                        <th width="40%">{this.props.translations.common.name}</th>
                                                        <th width="40%">{this.props.translations.common.value}</th>
                                                        <th width="20%">{this.props.translations.common.action}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {items_count > 0 ?
                                                        Object.values(settings).map((setting, index) => {

                                                            if (index >= start_offset && start_count < ITEMS_PER_PAGE) {
                                                                start_count++;
                                                                return <ListSettingRow
                                                                    key={setting.id}
                                                                    setting={setting}
                                                                    translations={this.props.translations}
                                                                    userRole={this.props.userRole}
                                                                // removeSetting={this.removeSetting}
                                                                />
                                                            }

                                                        })
                                                        :
                                                        <tr><td colSpan="100%">No settings found</td></tr>
                                                    }

                                                </tbody>
                                            </table>

                                            {items_count > ITEMS_PER_PAGE ?
                                                <Pagination
                                                    className="pagination"
                                                    itemClass="page-item"
                                                    activePage={current_page}
                                                    activeClass="active"
                                                    linkClass="page-link"
                                                    prevPageText="<"
                                                    nextPageText=">"
                                                    firstPageText="<<"
                                                    lastPageText=">>"
                                                    itemsCountPerPage={ITEMS_PER_PAGE}
                                                    totalItemsCount={items_count}
                                                    pageRangeDisplayed={ITEMS_PAGE_RANGE_DISPLAYED}
                                                    onChange={this.handlePageChange}
                                                />
                                                :
                                                ''
                                            }

                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </main>

            </React.Fragment>

        )
    }
}

const mapStateToProps = (state, ownProps, dispatch) => {
    const query = queryString.parse(state.router.location.search)
    state.router.location.query = query;
    const catId = ownProps.match.params.catId;
    let settingsCategoryName = Object.values(state.settings.settings_categories).filter(s => s.id == catId);

    let settings = [];
    if (catId) {
        settings = Object.values(state.settings.settings).filter(s => s.settingsCategoryId == catId);
    }

    return {
        settingCatId: catId,
        settings: settings || [],
        settingsCategoryName: settingsCategoryName && settingsCategoryName[0] && settingsCategoryName[0].name ? settingsCategoryName[0].name : '',
        settingsCategories: state.settings.settings_categories || [],
        page: Number(state.router.location.query.page) || 1,
        translations: state.lang,
        userRole: state.auth.user.role || "",
    }
};

const mapDispatchToProps = (dispatch, props) => {
    return {
        dispatch,
        ...bindActionCreators({ listSettings, listSettingsCategories }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ListSettingsPage);
