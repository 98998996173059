import React from 'react'
import { NavLink } from 'react-router-dom';
import { ADMIN_URL, IMG_PATH } from '../../../../constants.js'
import DeleteModal from './../../../common/DeleteModal';
import Utils from '../../../../utils/Utils';

const ListEachBookingRoom = (props) => (
    <tr key={props.bookingRoom.id}>
        <td>
            {/* If not added translation for the defailt language */}
            {props.bookingRoom.name}
            {/* {props.alergen.translations[props.currLang] && props.alergen.translations[props.currLang].name 
			? props.alergen.translations[props.currLang].name : props.alergen.name} */}
        </td>
        <td>
            {props.bookingRoom.cat_hierarchies && props.bookingRoom.cat_hierarchies.map((c, ind) => (
                <div>{c.name}  <br/></div>
            ))}
            {/* {props.categories && props.categories[props.bookingRoom.catHierarchyId] && props.categories[props.bookingRoom.catHierarchyId].name || '-'} */}
        </td>
        <td>
            <NavLink to={ADMIN_URL + 'bookings/rooms/edit/' + props.bookingRoom.id}>
                <button className="btn btn-pill btn-link" type="button">
                    <i className="fas fa-edit"></i>
                </button>
            </NavLink>

            <button className="btn btn-pill btn-link" type="button" data-toggle="modal" data-target={"#dangerModal" + props.bookingRoom.id} >
                <i className="fas fa-trash"></i>
            </button>

            <DeleteModal
                id={props.bookingRoom.id}
                title={props.bookingRoom.name}
                deleteFunc={props.deleteBookingRoom}
                translations={props.translations}
            />

        </td>
    </tr>
)

export default ListEachBookingRoom;