import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import categoriesReducer from './categoriesReducer';
import categoriesHierarchyReducer from './categoriesHierarchyReducer';
import subcategoriesReducer from './subcategoriesReducer';
import productsReducer from './productsReducer';
import settingsLanguagesReducer from './settingsLanguagesReducer';
import settingsReducer from './../components/settings/reducers/settings.reducer';
import tablesReducer from './tablesReducer';
import usersReducer from './usersReducer';
import ordersReducer from './ordersReducer';
import messagesReducer from './../components/messages/messages.reducer';
// import { langReducer } from 'react-redux-multilang'
import languageReducer from './languageReducer';
import { connectRouter } from 'connected-react-router'
import alergensReducer from '../components/alergens/reducers/alergen.reducer'
import authReducer from '../reducers/authReducer';
import ingridientsReducer from './../components/ingredients/reducers/ingredients.reducer';
import placesReducer from './../components/places/reducers/places.reducer';
import clientsReducer from './../components/clients/reducers/clients.reducer';
import statisticsReducer from './../components/statistics/reducers/statistics.reducer';
import regionsReducer from './../components/regions/reducers/regions.reducer';
import loadingReducer from './../components/common/loading/loading.reducer';
import adminReducer from './adminReducer';
import feedbackReducer from './../components/feedback/reducers/feedback.reducer';
import invoicesReducer from './../components/invoices/reducers/invoices.reducer';
import proformsReducer from './../components/proforms/reducers/proforms.reducer';
import bookingsReducer from './../components/bookings/reducers/bookings.reducer';
import tableGroupsReducer from './../components/table_groups/reducers/tableGroups.reducer';
import tableRegionsReducer from './../components/table_regions/reducers/tableRegions.reducer';
import versionsReducer from '../components/versions/reducers/versions.reducer';
import reservationsReducer from '../components/reservations/reducers/reservations.reducer';
import restaurantHallsReducer from '../components/restaurantHalls/reducers/restaurantHalls.reducer';
import promocodeReducer from '../components/promocodes/reducers/promocode.reducer';
import tagsReducer from '../components/tags/reducers/tags.reducer';
import generalPromotionsReducer from '../components/generalPromotions/reducers/generalPromotions.reducer';
import registeredUserDiscountReducer from '../components/registered_user_discount/reducers/registered_user_discount.reducer';
import deliveryShapesReducer from '../components/delivery_shapes/reducers/deliveryShapes.reducer';
import productPrintGroupReducer from '../components/productPrintGroup/reducers/productPrintGroup.reducer';
import placeImagesReducer from '../components/place_images/reducers/place_images.reducer';
import warehouseSystemsReducer from '../components/warehouse_systems/reducers/warehouseSystems.reducer';
import deliverySystemsReducer from '../components/delivery_systems/reducers/deliverySystems.reducer';
import paymentProvidersReducer from '../components/payment_providers/reducers/paymentProviders.reducer';

const createRootReducer = (history) => combineReducers({
  // categories: categoriesReducer,
  categoriesHierarchy: categoriesHierarchyReducer,
  // subcategories: subcategoriesReducer,
  products: productsReducer, 
  languages: settingsLanguagesReducer,
  tables: tablesReducer,
  tableGroups: tableGroupsReducer,
  tableRegions: tableRegionsReducer,
  users: usersReducer,
  orders: ordersReducer,
  settings: settingsReducer,
  messages: messagesReducer,
  lang: languageReducer,
  auth: authReducer,
  ingredients: ingridientsReducer,
  places: placesReducer,
  clients: clientsReducer,
  statistics: statisticsReducer,
  regions: regionsReducer,
  loading: loadingReducer,
  alergens: alergensReducer,
  router: connectRouter(history),
  routing: routerReducer,
  admin: adminReducer,
  feedback: feedbackReducer,
  invoices: invoicesReducer,
  proforms: proformsReducer,
  bookings: bookingsReducer,
  reservations: reservationsReducer,
  restaurantHalls: restaurantHallsReducer,
  versions: versionsReducer,
  promocodes: promocodeReducer,
  tags: tagsReducer,
  generalPromotions: generalPromotionsReducer,
  registeredUserDiscount: registeredUserDiscountReducer,
  deliveryShapes: deliveryShapesReducer,
  productPrintGroups: productPrintGroupReducer,
  placeImages: placeImagesReducer,
  warehouseSystems: warehouseSystemsReducer,
  deliverySystems: deliverySystemsReducer,
  paymentProviders: paymentProvidersReducer
})

export default createRootReducer


// export default combineReducers({
//     routing: routerReducer,
//     categories: categoriesReducer,
//     subcategories: subcategoriesReducer,
//     products: productsReducer,
//     languages: settingsLanguagesReducer,
//     tables: tablesReducer,
//     users: usersReducer,
//     orders: ordersReducer,
//     settings: settingsReducer,
//     //Reservations
//     places: placecesReducer,
//     rooms: roomsReducer,
//     reservations: reservationsReducer,
//     reservationTables: reservationTablesReducer
//  });