import React from 'react'
import { NavLink } from 'react-router-dom';
import { ADMIN_URL } from '../../constants.js'
import moment from 'moment';
import Auth from '../../utils/Auth.js';
import Utils from '../../utils/Utils.js';

const ListEachUserNeeds = (props) => {
    let orderDate = moment(props.userNeeds.createdAt).format('DD.MM.YYYY HH:mm');
    let updateDate = moment(props.userNeeds.createdAt).format('DD.MM.YYYY HH:mm');

    let orderDateDay = moment(props.userNeeds.createdAt).format('DD.MM');
    let orderDateHour = moment(props.userNeeds.createdAt).format('HH:mm');

    // const unTranslated = {
    //     "Clean table": props.translations.orders.user_needs_list.clean_table,
    //     "Ice": props.translations.orders.user_needs_list.ice,
    //     "Napkins": props.translations.orders.user_needs_list.napkins,
    //     "New cutlery": props.translations.orders.user_needs_list.new_cutlery,
    //     "Change ashtray": props.translations.orders.user_needs_list.change_ashtray,
    //     "Other": props.translations.orders.user_needs_list.other,
    // }

    let needs = Object.values(props.userNeeds.table_userneeds).map((un, ind) => {
        // const checkboxChecked = un.status == 'pending';
        let orderDate = Utils.formatDate(moment(un.createdAt).format('DD.MM.YYYY HH:mm'));

        return un && un.userneed && un.userneed.need ?
            <React.Fragment>
                <button type="submit" class="btn btn-sm btn-success" onClick={() => props.updateUserNeedsStatusById(un.id, 'completed')} ><i class="fa fa-dot-circle-o"></i> {props.translations.common.done}</button>
                &nbsp;
                <p key={ind} className={"d-inline fsize-17 p-10 c-pointer c-black"}>{un.userneed.need}{un.text.length > 0 ? <React.Fragment>&nbsp;<span className="badge badge-secondary">{un.text}</span></React.Fragment> : null}</p>
                <br />
                <span className="badge badge-secondary" style={{ fontSize: 14 }}>{orderDate}</span>

            </React.Fragment>
            :
            null
    })

    return (
        <div className="col-sm-6 col-md-4 col-lg-3">
            <div className="card">
                <div className="card-body p-0" >

                    {/* <div style={{ display: 'grid', gridTemplateColumns: '50% 50%', textAlign: 'center' }} > */}
                    <div style={{ textAlign: 'center' }} >
                        {props.translations.common.table}
                        <br />
                        <b>{props.userNeeds.table_num}</b>
                    </div>
                    {/* <div>
                            <span style={{ color: 'rgb(83, 83, 83)' }}>{props.translations.orders.order_date_short} #{props.userNeeds.id}</span>
                            <br />
                            {orderDateDay} / <b>{orderDateHour}</b> */}
                    {/* {orderDate} */}
                    {/* {updateDate} */}
                    {/* </div> */}
                    {/* </div> */}

                    <br />

                    <div className="text-value" style={{ textAlign: 'center', color: 'white' }}>
                        {needs}
                    </div>
                    <br />

                    <br />


                </div>
            </div>
        </div>



        // <tr key={props.userNeeds.id}>
        //     <td>
        //         #{props.userNeeds.id}
        //     </td>
        //     <td>
        //         {needs}
        //     </td>
        //     <td>
        //         {props.userNeeds.table_num}
        //     </td>
        //     <td>
        //         <b>{props.translations.orders.order_date}</b>
        //         <br />
        //         {orderDate}

        //         <br />
        //         <br />

        //         <b>{props.translations.orders.update_date}</b>
        //         <br />
        //         {updateDate}
        //     </td>
        // </tr>
    )
}

export default ListEachUserNeeds;