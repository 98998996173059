import React from 'react'

const Filter = (props) => {

    // const catsAlphabeticallySorted = Object.values(props.allCategories).sort(function (a, b) {
    //     var nameA = a.name.toLowerCase(), nameB = b.name.toLowerCase()
    //     if (nameA < nameB) //sort string ascending
    //         return -1
    //     if (nameA > nameB)
    //         return 1
    //     return 0 //default return value (no sorting)
    // });

    return (
        <div className="container-fluid">
            <div className="animated fadeIn">
                <div className="row">

                    <div className="form-group reservation-list-search col-md-2 col-sm-4 ">
                        <div className="form-group">
                            <label htmlFor="name">{props.translations.common.name}</label>
                            <input className={`form-control`} id="search_name" type="text" placeholder=""
                                name="search_name" onChange={props.onChange} value={props.search_name} />
                        </div>
                    </div>

                    <div className="form-group reservation-list-search col-md-2 col-sm-4 ">
                        <div className="form-group">
                            <label htmlFor="name">{props.translations.common.ws_code}</label>
                            <select className="form-control" name="search_ws_code" value={props.search_ws_code} onChange={props.onChange}>
                                <option key={0} value="">-</option>
                                <option value={1}>{props.translations.common.with_ws_code}</option>
                                <option value={2}>{props.translations.common.without_ws_code}</option>
                            </select>
                        </div>
                    </div>
                    

                </div>
            </div>
        </div>
    )
}

export default Filter;