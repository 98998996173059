import React from 'react'
import { NavLink } from 'react-router-dom';
import { ADMIN_URL } from '../../constants.js'
import Auth from '../../utils/Auth.js';
import DeleteTableModal from './DeleteTableModal.js';
import Utils from '../../utils/Utils';
import Select from 'react-select'

function copy(tableId) {
    var copyText = document.querySelector("#table_" + tableId);
    copyText.select();
    document.execCommand("copy");
}

function fallbackCopyTextToClipboard(text) {
    var textArea = document.createElement("textarea");
    textArea.value = window.location.origin + '/t/' + text;

    // Avoid scrolling to bottom
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    document.execCommand('copy');
    document.body.removeChild(textArea);
}

const ListEachTable = (props) => {

    console.log("props.currPlace", props.currPlace)
    return (
        <tr key={props.table.id}>
            <td>
                {/* If not added translation for the defailt language */}
                {props.table.table_num}
                {Auth.hasAccess("SALES_ADMIN") ?
                    <React.Fragment>
                        <br />
                        <input type="text" id={"table_" + props.table.id} value={props.table.table_token} onClick={() => copy(props.table.id)} title="Copy token" readOnly/>

                        <button className="btn btn-pill btn-link" type="button" onClick={() => fallbackCopyTextToClipboard(props.table.table_token)} title="Copy full link" style={{
                            border: '1px solid',
                            'marginLeft': 13,
                            'verticalAlign': 'initial',
                            'borderRadius': 3,
                        }}>
                            <i className="fas fa-copy"></i>
                        </button>

                    </React.Fragment>
                    :
                    null
                }
            </td>
            <td>
                {props.table.active ? <span className="badge badge-success">{props.translations.common.active}</span> : <span className="badge badge-secondary">{props.translations.common.inactive}</span>}
            </td>
            <td>
                {props.table.ordertypes && props.table.ordertypes[0] && props.table.ordertypes[0] && props.translations.ordertypes[props.table.ordertypes[0].type] || "-"}
            </td>

            <td>
                <Select
                    value={{'value': props.table.tableRegion && props.table.tableRegion.id, 'label': props.table.tableRegion && props.table.tableRegion.name}}
                    // isMulti
                    name="tableRegionId"
                    options={props.tableRegionsOptions}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={(valueLabel) => props.onChangeTableRegionSelect(props.table.id, valueLabel.value)}
                />
            </td>
      
            <td>
                <Select
                    value={{'value': props.table.restaurantHall && props.table.restaurantHall.id, 'label': props.table.restaurantHall && props.table.restaurantHall.name}}
                    // isMulti
                    name="restaurantHallId"
                    options={props.restaurantHallsOptions}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={(valueLabel) => props.onChangeTableRestaurantHallSelect(props.table.id, valueLabel.value)}
                />
            </td>
            
            {Utils.hasFunctionalityModule("reservations") ? 
                <td>
                    <div className="col-md-2 col-form-label">
                        <label className="switch switch-label switch-pill switch-outline-primary-alt">
                            <input className="switch-input" type="checkbox" name="is_for_reservation" onChange={e => props.onChangeCheckboxIsForReservation(e, props.table.id)} checked={props.table.is_for_reservation} />
                            <span className="switch-slider" data-checked={props.translations.common.yes} data-unchecked={props.translations.common.no}></span>
                        </label>
                    </div>
                </td>
            :
                null
            }

            {(Auth.hasAccess("SUPER_ADMIN") || Auth.hasAccess('CLIENT_ADMIN') || Auth.hasAccess('PLACE_ADMIN')) && props.currPlace && props.currPlace.warehouseSystemId ?
                <td>
                    <input type='text'
                        onChange={(ev) => props.onWsCodeChange(ev, props.table.id)}
                        onBlur={(ev) => props.updateWsCode(ev, props.table.id)}
                        value={props.wsCode} className="form-control"
                        style={{ maxWidth: 80, minWidth: 65 }}
                    />
                    {/* {props.product.ws_code || "-"} */}
                </td>
                :
                null
            }

            <td>
                <NavLink to={ADMIN_URL + 'tables/edit/' + props.table.id}>
                    <button className="btn btn-pill btn-link" type="button">
                        <i className="fas fa-edit"></i>
                    </button>
                </NavLink>

                <button className="btn btn-pill btn-link" type="button" data-toggle="modal" data-target={"#dangerModal" + props.table.id} >
                    <i className="fas fa-trash"></i>
                </button>

                <DeleteTableModal
                    tableId={props.table.id}
                    tableNum={props.table.table_num}
                    deleteTable={props.deleteTable}
                    translations={props.translations}
                />

            </td>
        </tr >
    )
}
export default ListEachTable;