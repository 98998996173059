import React from 'react'
import { NavLink } from 'react-router-dom';
import { ADMIN_URL } from '../../../constants.js'
import Auth from '../../../utils/Auth.js';
import DeleteModal from './../../common/DeleteModal';
import Utils from '../../../utils/Utils';


const ListEachTableGroup = (props) => (
    <tr key={props.tableGroup.id}>
        <td>
            {/* If not added translation for the defailt language */}
            {props.tableGroup.id}
        </td>
        <td>
            {props.tableGroup.min_people_count}
        </td>
        <td>
            {props.tableGroup.max_people_count}
        </td>
        <td>
            {props.tableGroup.tables.map(t => {
                return <div>
                    {t.table_num} <br/>
                </div>
            })}
        </td>
        <td>
            {props.tableGroup.active ? <span className="badge badge-success">{props.translations.common.active}</span> : <span className="badge badge-secondary">{props.translations.common.inactive}</span>}
        </td>
        
        <td>
            <NavLink to={ADMIN_URL + 'tableGroups/edit/' + props.tableGroup.id}>
                <button className="btn btn-pill btn-link" type="button">
                    <i className="fas fa-edit"></i>
                </button>
            </NavLink>

            <button className="btn btn-pill btn-link" type="button" data-toggle="modal" data-target={"#dangerModal" + props.tableGroup.id} >
                <i className="fas fa-trash"></i>
            </button>

            <DeleteModal
                id={props.tableGroup.id}
                title={'Group'}
                deleteFunc={props.deleteTableGroup}
                translations={props.translations}
            />

        </td>
    </tr >
)

export default ListEachTableGroup;