import React from 'react'
import { NavLink } from 'react-router-dom';
import { ADMIN_URL, IMG_PATH } from '../../../constants.js'
import DeleteModal from './../../common/DeleteModal';
import Utils from '../../../utils/Utils';
import Auth from '../../../utils/Auth.js';

const ListEachRegion = (props) => (
    <tr key={props.region.id}>
        <td>
            {/* If not added translation for the defailt language */}
            {props.region.name}
            {/* {props.alergen.translations[props.currLang] && props.alergen.translations[props.currLang].name 
			? props.alergen.translations[props.currLang].name : props.alergen.name} */}
        </td>
        <td>
            {props.region.description}
        </td>
        <td>
            {props.region.delivery_price}
        </td>
            <td>
                <div className="col-md-2 col-form-label">
                    <label className="switch switch-label switch-pill switch-outline-primary-alt">
                        <input className="switch-input" type="checkbox" name="active" onChange={e => props.onChangeCheckboxIsActive(e, props.region.id)} checked={props.region.active} />
                        <span className="switch-slider" data-checked={props.translations.common.yes} data-unchecked={props.translations.common.no}></span>
                    </label>
                </div>
            </td>   
          
        {(Auth.hasAccess("SUPER_ADMIN") || Auth.hasAccess('CLIENT_ADMIN') || Auth.hasAccess('PLACE_ADMIN')) && props.currPlace && props.currPlace.deliverySystemId ?
            <td>
                {props.region.external_system_region_id}
            </td>
            :
            null
        }
        <td>
            <NavLink to={ADMIN_URL + 'regions/edit/' + props.region.id}>
                <button className="btn btn-pill btn-link" type="button">
                    <i className="fas fa-edit"></i>
                </button>
            </NavLink>

            <button className="btn btn-pill btn-link" type="button" data-toggle="modal" data-target={"#dangerModal" + props.region.id} >
                <i className="fas fa-trash"></i>
            </button>

            <DeleteModal
                id={props.region.id}
                title={props.region.name}
                deleteFunc={props.deleteRegion}
                translations={props.translations}
            />

        </td>
    </tr>
)

export default ListEachRegion;