import React from 'react';
import { NavLink } from 'react-router-dom';
import { ADMIN_URL } from '../../constants.js';
import DeleteProductVariantModal from './DeleteProductVariantModal';
import MessagesContainer from './../messages/MessagesContainer';
import Utils from '../../utils/Utils';
import LangDropdown from '../common/LangDropdown.js';

const AddEditProductVariantForm = (props) => {
    return (
        <React.Fragment>

            {Object.keys(props.variants).map((key, index) => {
                // console.log("PPPP ", props.variants[key])
                return <DeleteProductVariantModal
                    key={key}
                    variantId={props.variants[key].id}
                    variantName={props.variants[key].size}
                    deleteVariant={props.removeSize}
                    translations={props.translations}
                />
            })}
            <form onSubmit={props.addSize}>
                
                <MessagesContainer />
                <div className="card-body">

                    <LangDropdown
                        isDisabled={""}
                        currLangOnChange={props.currLangOnChange}
                        currLang={props.currLang}
                        languages={props.languages}
                    />

                    <div className="row">
                        <label className="form-group col-md-4 col-form-label" style={{ display: 'inline-block' }}>{props.translations.common.hide_variants}</label>
                        <div className="form-group col-md-2 col-form-label" style={{ display: 'inline-block', textAlign: 'center' }}>
                            <label className="switch switch-label switch-pill switch-outline-primary-alt">
                                <input className="switch-input" type="checkbox" name="hide_variants" onChange={props.onChangeHideVariants} checked={props.prod.hide_variants} />
                                <span className="switch-slider" data-checked={props.translations.common.yes} data-unchecked={props.translations.common.no}></span>
                            </label>
                        </div>
                        <div className="form-group col-md-6 col-form-label" style={{ display: 'inline-block' }}></div>
                    </div>

                    <div className="row">
                        <div className={"form-group col-sm-" + (props.showVariantAdditionalPrice == 1 ? 3 : 3)}>
                            <label htmlFor="product_size">{props.translations.common.size}</label>
                            <input className="form-control" id="product_size" type="text" name="variantSize" value={props.variantSize} onChange={props.onChange} placeholder={props.translations.common.size} />
                        </div>

                        <div className={"form-group col-sm-" + (props.showVariantAdditionalPrice == 1 ? 2 : 2)}>
                            <label htmlFor="product_price">{props.translations.common.price}</label>
                            <input className="form-control" id="product_price" type="number" step="0.01" name="variantPrice" value={props.variantPrice} onChange={props.onChange} placeholder={props.translations.common.price} />
                        </div>

                        <div className={"form-group col-sm-" + (props.showVariantAdditionalPrice == 1 ? 2 : 2)}>
                            <label htmlFor="product_price">{props.translations.common.code}</label>
                            <input className="form-control" id="variantCode" type="text" name="variantCode" value={props.variantCode} onChange={props.onChange} placeholder={props.translations.common.code} />
                        </div>

                        {props.showVariantAdditionalPrice == 1 ?
                            <div className="form-group col-sm-2">
                                <label htmlFor="product_price">{props.translations.common.additional_price}</label>
                                <input className="form-control" id="variantCode" type="text" name="variantAdditionalPrice" value={props.variantAdditionalPrice} onChange={props.onChange} placeholder={props.translations.common.additional_price} />
                            </div>
                            :
                            null
                        }

                        {Utils.hasFunctionalityModule('booking') && Utils.getSetting("booking_interval") ?
                            <div className="form-group col-sm-2">
                                <label htmlFor="product_price">{props.translations.common.booking_duration}</label>
                                <select className="form-control" id="booking_duration" type="text" name="variantBookingDuration" value={props.variantBookingDuration} onChange={props.onChange}>
                                    {[0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24].map(n => {
                                        if(n*Utils.getSetting("booking_interval") <= 120) {
                                            return (
                                                <option value={n*Utils.getSetting("booking_interval")}>{n*Utils.getSetting("booking_interval")}</option>
                                            )
                                        } else {
                                            return null
                                        }
                                    })}
                                </select>
                                {/* <input className="form-control" id="booking_duration" type="text" name="variantBookingDuration" value={props.variantBookingDuration} onChange={props.onChange} placeholder={props.translations.common.booking_duration} /> */}
                            </div>
                            :
                            null
                        }

                        {props.isEditingVariant == 0 ?
                            // Add variant
                            <div className={"form-group col-sm-" + (props.showVariantAdditionalPrice == 1 ? 2 : 4) + " text-center"}>
                                <button className="btn btn-pill btn-light mtop_30" type="button" onClick={props.addSize} style={{ width: '100%' }}><i className="fas fa-plus fa-lg"></i> &nbsp; {props.translations.products.add_variant}</button>
                            </div>
                            :
                            // Edit variant
                            <div className={"form-group col-sm-" + (props.showVariantAdditionalPrice == 1 ? 2 : 4) + " text-center"}>
                                <button className="btn btn-pill btn-light mtop_30" type="button" onClick={props.saveSize} style={{ width: '100%' }}><i className="fas fa-check fa-lg"></i> &nbsp; {props.translations.products.save_variant}</button>
                                <button className="btn btn-pill btn-light mtop_10" type="button" onClick={props.cancelEditSize} style={{ width: '100%' }}>{props.translations.common.cancel}</button>
                            </div>
                        }
                    </div>

                    <br />

                    <div className="row">
                        <div className="form-group col-sm-12">

                            <table className="table table-responsive-sm">
                                <thead>
                                    <tr>
                                        <th>{props.translations.common.size}</th>
                                        <th>{props.translations.common.price}</th>
                                        <th>{props.translations.common.code}</th>
                                        {props.showVariantAdditionalPrice == 1 ?
                                            <th>{props.translations.common.additional_price}</th>
                                            :
                                            null
                                        }
                                        {Utils.hasFunctionalityModule('booking') ?
                                            <th>{props.translations.common.booking_duration}</th>
                                            :
                                            null
                                        }   
                                        <th className="right pright70">{props.translations.common.action}</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {Object.keys(props.variants).map((key, index) => {
                                        // console.log('-----', props.variants[key])
                                        return <tr key={key}>
                                            <td>
                                                {/* {props.variants[key].size} */}
                                                {props.variants[key].translations && props.variants[key].translations[props.currLang]
                                                    && props.variants[key].translations[props.currLang].size
                                                    || props.variants[key].size}

                                                {/* `- no ${props.currLang} translation -` */}
                                            </td>
                                            <td>
                                                {props.variants[key].price}
                                            </td>
                                            <td>
                                                {props.variants[key].code}
                                            </td>

                                            {props.showVariantAdditionalPrice == 1 ?
                                                <td>
                                                    {props.variants[key].additional_price}
                                                </td>
                                                :
                                                null
                                            }

                                            {Utils.hasFunctionalityModule('booking') ?
                                                <td>
                                                    {props.variants[key].booking_duration}
                                                </td>
                                                :
                                                null
                                            }
                                            <td className="right">
                                                <button className="btn btn-pill btn-light" type="button" onClick={() => props.editSize(props.variants[key].id)}><i className="fas fa-edit"></i> &nbsp; {props.translations.common.edit}</button>
                                                &nbsp;
                                            <button className="btn btn-pill btn-danger" type="button" data-toggle="modal" data-target={"#dangerProductVariantModal" + props.variants[key].id}><i className="fas fa-remove"></i></button>

                                                <DeleteProductVariantModal
                                                    variantId={props.variants[key].id}
                                                    variantName={props.variants[key].size}
                                                    deleteVariant={props.removeSize}
                                                    translations={props.translations}
                                                />
                                            </td>
                                        </tr>
                                    })}
                                </tbody>
                            </table>

                        </div>
                    </div>

                </div>

            </form>
        </React.Fragment>
    )
}

export default AddEditProductVariantForm;