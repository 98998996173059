import React from 'react'
import { NavLink } from 'react-router-dom';
import { ADMIN_URL } from '../../../constants.js'
import Auth from '../../../utils/Auth.js';
import DeleteModal from '../../common/DeleteModal';
import Utils from '../../../utils/Utils';
import { WonderPush } from 'react-wonderpush';


const ListEachTableRegion = (props) => {


    const subscribeToPushNotifications = (tag) => {
        window.WonderPush = window.WonderPush || [];
        window.WonderPush.push(['setUserId', Auth.getDecodedUser().email]);
        window.WonderPush.addTag(tag);
    }

    return (
        <tr key={props.tableRegion.id}>
            <td>
                {props.tableRegion.name}
            </td>
            <td>
                {props.tableRegion.tables.map(t => {
                    return <div>
                        {t.table_num} <br />
                    </div>
                })}
            </td>
            <td>
                <span className="badge badge-info" title="Натисни и при нова поръчка ще получиш известие" onClick={() => { subscribeToPushNotifications(Auth.getUserPlace() + '-' + props.tableRegion.id); alert('Успешно е добавено устройството за PUSH нотификации от текущия обект. За да се Отпишете, използвайте камбанката долу вдясно.') }}>ВКЛЮЧИ</span>
            </td>
            <td>
                {props.tableRegion.active ? <span className="badge badge-success">{props.translations.common.active}</span> : <span className="badge badge-secondary">{props.translations.common.inactive}</span>}
            </td>

            <td>
                <NavLink to={ADMIN_URL + 'tableRegions/edit/' + props.tableRegion.id}>
                    <button className="btn btn-pill btn-link" type="button">
                        <i className="fas fa-edit"></i>
                    </button>
                </NavLink>

                <button className="btn btn-pill btn-link" type="button" data-toggle="modal" data-target={"#dangerModal" + props.tableRegion.id} >
                    <i className="fas fa-trash"></i>
                </button>

                <DeleteModal
                    id={props.tableRegion.id}
                    title={'Region'}
                    deleteFunc={props.deleteTableRegion}
                    translations={props.translations}
                />

            </td>
        </tr >
    )
}

export default ListEachTableRegion;