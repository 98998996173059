import React, { Component } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { NavLink } from 'react-router-dom';
import Pagination from "react-js-pagination";
import { push } from 'react-router-redux';
import { listSettings, listSettingsCategories } from './../actions/settings.actions';
import ListSettingCategoryBox from './ListSettingCategoryBox';
import { ADMIN_URL, ITEMS_PER_PAGE, ITEMS_PAGE_RANGE_DISPLAYED } from '../../../constants';
import MessagesContainer from './../../messages/MessagesContainer';
import queryString from 'query-string'
import ListSettingRow from './ListSettingRow';
import ListSearchedSettings from './ListSearchedSettings';

class ListSettingsCategoriesPage extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            searchedSettings: [],
            search: ''
        }
    }

    componentWillMount() {
        this.props.listSettingsCategories();
        this.props.listSettings()
    }
    
    handlePageChange = (page) => {
        this.props.dispatch(push('/admin/settings?page='+page))
    }

    onSearch = (e) => {
        console.log(e.target.value);
        let q = e.target.value.toLowerCase();

        console.log(q, q.length)
        let foundSettings = [];

        if(q.length > 0) {
            foundSettings = [];
            Object.values(this.props.settings).map(s => {
                if(s.name.toLowerCase().includes(q) || (s.comment && s.comment.toLowerCase().includes(q)) || (s.value && JSON.stringify(s.value).toLowerCase().includes(q)) || (s.description && s.description.toLowerCase().includes(q))) {
                    foundSettings.push(s)    
                }
                
            });
        } else {
            foundSettings = [];
        }
      

        this.setState({ searchedSettings: foundSettings, search: q})

    }

    render() {
        let settings = this.props.settings;

        // Pagination variables
        const items_count = Object.keys(this.props.settingsCategories).length
        const current_page = this.props.page;
        const start_offset = (current_page - 1) * ITEMS_PER_PAGE;
        let start_count = 0;
        // 
        // console.log('this.props.translations', this.props.translations)
        return (
            <React.Fragment>
                <main className="main">
                    
                    <ol className="breadcrumb">
                    <li className="breadcrumb-item"><NavLink to={ADMIN_URL}>{this.props.translations.common.dashboard}</NavLink></li>
                        <li className="breadcrumb-item active">{this.props.translations.sidemenu.settings}</li>
                    </ol>
    
                    <div className="container-fluid">
                        <div className="animated fadeIn">
                            <div className="row">
                        
                                <div className="col-lg-12">
                                    <div className="card">
                                        
                                        <div className="card-body">
                                            
                                            <MessagesContainer/>

                                            <div className="row">
                                                <div className="col-md-4">                                            
                                                    <input type="text" className="form-control" placeholder="Search settings" onChange={this.onSearch}/>
                                                    <br/>
                                                </div>
                                            </div>

                                            <div className="row">
                                                {this.state.searchedSettings.length == 0 && this.state.search.length == 0 ?
                                                    <>
                                                         {Object.values(this.props.settingsCategories).map((cat, index) => {
                                                            return <ListSettingCategoryBox 
                                                                key={cat.id} 
                                                                cat={cat}
                                                                translations={this.props.translations}
                                                            />
                                                        })}

                                                        
                                                        <div key={990} className="col-lg-2 col-md-3 col-sm-6">
                                                            <div className="card" style={{ textAlign: 'center' }}>
                                                                <NavLink to={ADMIN_URL + 'warehouse-systems/select'}>
                                                                    <div className="card-body">
                                                                        <i className={"fas fa-warehouse"} style={{ fontSize: 40 }}></i>
                                                                        <br />
                                                                        {this.props.translations.settings_categories.current_warehouse_system}
                                                                        {/* Current warehouse system */}
                                                                    </div>
                                                                </NavLink>
                                                            </div>
                                                        </div>

                                                        <div key={991} className="col-lg-2 col-md-3 col-sm-6">
                                                            <div className="card" style={{ textAlign: 'center' }}>
                                                                <NavLink to={ADMIN_URL + 'delivery-systems/select'}>
                                                                    <div className="card-body">
                                                                        <i className={"fas fa-truck"} style={{ fontSize: 40 }}></i>
                                                                        <br />
                                                                        {this.props.translations.settings_categories.current_delivery_system}

                                                                        {/* Current delivery system */}
                                                                    </div>
                                                                </NavLink>
                                                            </div>
                                                        </div>

                                                        <div key={992} className="col-lg-2 col-md-3 col-sm-6">
                                                            <div className="card" style={{ textAlign: 'center' }}>
                                                                <NavLink to={ADMIN_URL + 'payment-providers/select'}>
                                                                    <div className="card-body">
                                                                        <i className={"fas fa-credit-card"} style={{ fontSize: 40 }}></i>
                                                                        <br />
                                                                        {this.props.translations.settings_categories.current_payment_provider}

                                                                        {/* Current payment provider */}
                                                                    </div>
                                                                </NavLink>
                                                            </div>
                                                        </div>
                                                    </>
                                                :
                                                    <ListSearchedSettings
                                                        settings={this.state.searchedSettings}
                                                        translations={this.props.translations}
                                                        userRole={this.props.userRole}
                                                    />
                                                }

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>

            </React.Fragment>

        )
    }
}

const mapStateToProps = (state, dispatch) => {
    const query = queryString.parse(state.router.location.search)
    state.router.location.query = query;

    return {
        // settings: state.settings.settings || [],
        settingsCategories: state.settings.settings_categories || [],
        settings: state.settings.settings || [],

        page: Number(state.router.location.query.page) || 1,
        translations: state.lang,
        userRole: state.auth.user.role || "",

    }
};

const mapDispatchToProps = (dispatch, props) => {
    return {
        dispatch,
        ...bindActionCreators({ listSettings, listSettingsCategories }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps )(ListSettingsCategoriesPage);
