export const roTranslations = {
    sidemenu: {
        alergens: "Alergens",
        dashboard: "Dashboard",
        menu: "Meniu",
        categories: "Categorii",
        subcategories: "Subcategorii",
        products: "Produse",
        administration: "Administrare",
        tables: "Mese",
        orders: "Comenzi",
        all_orders: "All orders", // Not translated
        orders_from_spot: "From the place", // Not translated
        orders_delivery: "With delivery", // Not translated
        orders_preorder: "With preorder", // Not translated
        deleted_orders: "Orders /deleted/", // Not translated
        charts: "Grafice",
        reservations: "Reservations",
        locations: "Locatii",
        rooms: "Camere",
        reservation_tables: "Mese Rezervate",
        schedule: "Program",
        settings: "Setari",
        users: "Utilizatori",
        system: "Sistem",
    },
    common: {
        included: 'included',
        box: "Box",
        done: "Done",  // Not translated
        background: "Background",
        color: "Color",
        file: "file",
        title: "Title", // Not translated
        paid: "Paid", // Not translated
        waitings: "Waiting", // Not translated
        order: "Order",
        alergens: "Alergens",
        id: "ID",
        dashboard: "Dashboard",
        name: "Denumire",
        icon: "Icon",
        status: "Status",
        action: "Actiuni",
        category: "Categorie",
        client: "Client",
        subcategory: "Subcategorie",
        size: "Marime",
        price: "Pret",
        is_drink: "Bautura ?",
        number: "Numar",
        order_number: "Numar Comanda",
        order_type: "Order type", // Not translated
        date: "Data",
        turnover: "Vanzari",
        code: "Cod",
        active_text: "Active",
        active: "Activ",
        inactive: "Inactiv",
        description: "Descriere",
        image: "Imagine",
        yes: "Da",
        no: "Nu",
        add: "Adauga",
        cancel: "Cancel",
        save: "Save",
        delete: "Delete",
        close: "Close",
        edit: "Edit",
        price_of_variant: "Pretul Variantei",
        table: "Masa",
        bill_wanted: "Nota Ceruta",
        bill: "Nota",
        currency: "lei",
        download: "Download",
        value: "valoare",
        images: "imagini",
        rows: "rânduri",
        restaurant_order: "Restaurant order", //not translated
        delivery: "Delivery", //not translated
        preorder: "Pre-Order", //not translated
        delivery_and_preorder: "Delivery and Pre-Order", //not translated
        restaurant_and_delivery: "Restaurant and Delivery", //not translated
        restaurant_and_preorder: "Restaurant and Pre-Order", //not translated
        others: "Others", //not translated
        role: "Role",
        additional_price: "Extra price",
        slug: "Slug"
    },
    alergens: {
        title: "Alergens",
        add: "Add alergen",
        edit: "Edit alergen",
        delete: {
            title: "Delete alergen",
            confirm: "Are you sure you want to delete "
        }
    },
    categories: {
        title: "Categorii",
        add: "Adauga categoria",
        edit: "Editeaza categoria",
        delete: {
            title: "Sterge categoria",
            confirm: "Esti sigur ca vrei sa stergi categoria "
        }
    },
    subcategories: {
        title: "Subcategorii",
        add: "Adauga subcategoria",
        edit: "Editeaza subcategoria",
        delete: {
            title: "Sterge subcategoria",
            confirm: "Esti sigur ca vrei sa stergi subcategoria "
        }
    },
    products: {
        title: "Produse",
        add: "Adauga produs",
        edit: "Editeaza produs",
        add_variant: "Adauga varianta",
        save_variant: "Salveaza varianta",
        delete: {
            title: "Stergere produs",
            confirm: "Esti sigur ca vrei sa stergi produsul "
        }
    },
    product_variants: {
        delete: {
            title: "Sterge varianta",
            confirm: "Esti sigur ca vrei sa stergi varianta "
        }
    },
    orders: {
        select_tables: "Select tables",//not translated
        no_data: "No data in the selected tab",//not translated
        client_name: "Nume client",
        phone: "Telefon",
        client_phone: "Telefon contact",
        client_address: "Adresa client",
        products: "Produse",
        order_date: "Timp de comandă",
        update_date: "Timpul de actualizare",
        type: "Order type",
        user_needs: "User wishes",

        types: {
            delivery: "Delivery",
            preorder: "With preorder",
            spot: "From place"
        },

        statuses: {
            ordered: "Comandat", //Not sure 
            cooked_spot: 'Gătit', //Not sure
            served_spot: 'Servit', //Not sure
            cooked_delivery: 'Gătit', //Not sure
            on_the_way_delivery: 'Livrarea', //Not sure
            cooked_preorder: 'Gătit', //Not sure
            paid: "Platit", //Not sure
        },

        all: "All",
        new: "New",
        ordered: "Ordered",
        cooked: "Cooked",
        served: "Served",
        for_delivering: "For delivering",
        delivering: "Delivering",
        waiting_for_pickup: "Waiting pickup",
        paid: "Paid",
        delivered: "Delivered",

        delete: { // Not translated
            title: "Delete order", // Not translated
            confirm: "Are you sure you want to delete order # " // Not translated
        }
    },
    messages: {
        errors: {
            invalid_name: "Nume gresit",
            invalid_category: "Categorie invalida",
            invalid_subcategory: "Subcategorie invalida",
            invalid_size: "Dimensiune invalida",
            invalid_price: "Pret gresit",
            invalid_table_num: "Invalid table num",
            invalid_email: "Invalid email",
            invalid_role: "Invalid role" 
        }
    },
    tables: {
        title: "Mese",
        add: "Adauga masa",
        edit: "Editare masa",
        delete: {
            title: "Stergere masa",
            confirm: "Esti sigur ca vrei sa stergi masa "
        }
    },
    settings: {
        menu: "Meniu",
        kitchen: "Bucatarie",
        admin: "Admin",
        general: "Setari generale",
        background_image: "Imagine de fundal",
        logo: "Logo"
    },
    users: {
        add: "Adauga utilizator",
        edit: "Editeaza utilizator",
        email: "Email",
        password: "Parola"
    },
    dashboard: {
        orders: "Comenzi",
        online_customers: "Clienți online",
        monthly_turnover: "Vanzare Lunara",
        happy_customers: "Clienții satisfăcuți",
        totalAndTodayOrders: "Total orders: {total}, orders today: {today}",
    }
}