import { combineReducers } from 'redux';
import { LIST_ORDERTYPE_PRICES, LIST_ORDERTYPE_DISCOUNTS } from '../actions/types';

const initialState = {

    ordertype_prices: [],
    ordertype_discounts: [],
    
}

export default function(state = initialState, action) {
    switch(action.type) {
        case LIST_ORDERTYPE_PRICES: {
            return {
                ...state,
                ordertype_prices: action.payload,
            }
        }
        case LIST_ORDERTYPE_DISCOUNTS: {
            return {
                ...state,
                ordertype_discounts: action.payload,
            }
        }
        default:
            return state;
    }
}