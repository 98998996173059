import React from 'react';
import { NavLink } from 'react-router-dom';
import { ADMIN_URL } from '../../constants.js';
// import DeleteProductVariantModal from './DeleteProductVariantModal';

const AddEditProductVariantForm = (props) => {
    // console.log('props.alergens', props.alergens)
    // console.log('props.productAllergens', props.productAllergens)

    // console.log('allAlergens', props.allAlergens)
    // console.log('productAllergens', props.productAllergens)


    return (
        <React.Fragment>

            <form>
                <div className="card-body">

                    <div className=" row">
                        <label className="col-md-12 col-form-label">{props.translations.alergens.title}</label>

                        {Object.values(props.allAlergens).map(a =>
                            <div className="col-md-4 col-sm-6 col-12 col col-form-label centered">
                                <div>{a.name}</div>
                                <label className="switch switch-label switch-pill switch-outline-primary-alt">
                                   
                                    <input className="switch-input" type="checkbox" name="active"
                                        checked={typeof props.productAllergens[a.id] !== "undefined"}
                                        onChange={() => props.onChangeProductAlergens(typeof props.productAllergens[a.id] !== "undefined", a.id)}
                                    />

                                    <span className="switch-slider" data-checked={props.translations.common.yes} data-unchecked={props.translations.common.no}></span>
                                </label>
                            </div>
                        )}

                    </div>

                    <br />

                </div>

            </form>
        </React.Fragment>
    )
}

export default AddEditProductVariantForm;