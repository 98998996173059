import React, { Component } from 'react'
import AddEditTableRegionForm from './AddEditRestaurantHallForm';
// import { addEditRestaurantHalls, listRestaurantHalls } from '../actions/restaurantHalls.actions';
import { listRestaurantHalls, addEditRestaurantHalls } from '../actions/restaurantHalls.actions.js';

import { addErrorMessages } from '../../messages/messages.actions';
import { listTables } from '../../../actions/tablesActions';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { ADMIN_URL } from '../../../constants';
import Utils from '../../../utils/Utils';
import Auth from '../../../utils/Auth';

let md5 = require('md5');

class EditRestaurantHallPage extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			id: this.props.match.params.id,
			name: '',
			// tables: [],
			active: 1,
		}
	}

	componentWillMount() {
		this.props.listTables();
	}

	componentWillReceiveProps(nextProps) {
		let tables = [];

		console.log('nextProps', nextProps);
		if (nextProps.restaurantHall.id) {
			// nextProps.restaurantHall.tables.map(t => {
			// 	let table = Object.values(nextProps.tables).find(tt => tt.id == t.id);

			// 	tables.push({ 
			// 		value: t.id, 
			// 		'label': table.table_num
			// 	})

			// })

			this.setState({
				id: nextProps.match.params.id,
				name: nextProps.restaurantHall.name ? nextProps.restaurantHall.name : 0,
				active: nextProps.restaurantHall.active ? nextProps.restaurantHall.active : 0,
				// tables: tables,
			});
		}



	}

	componentWillMount() {
		this.props.listRestaurantHalls();

		// let restaurantHallOrdertype = [];
		// if (this.props.restaurantHall.ordertypes.length > 0) {
		// 	// nextProps.restaurantHall.ordertypes.map(type => {
		// 	restaurantHallOrdertype.push({ value: parseInt(this.props.restaurantHall.ordertypes[0].id), label: this.props.restaurantHall.ordertypes[0].type })
		// 	// })
		// }

		// this.setState({
		// 	restaurantHallOrdertype: restaurantHallOrdertype,
		// 	restaurantHallOrdertypeValue: restaurantHallOrdertype && restaurantHallOrdertype[0] && restaurantHallOrdertype[0] || ""
		// })
	}

	onChange = (e) => {
		this.setState({ [e.target.name]: e.target.value })
	}

	onChangeCheckbox = (e) => {
		this.setState({ [e.target.name]: e.target.checked });
	}

	onChangeSelectAutocomplete = (fieldName, valueLabel) => {
		this.setState({ [fieldName]: valueLabel });
	}

	validate = () => {
		let errors = [];

		// if(!this.state.tables || this.state.tables.length == 0) {
		// 	errors.push(this.props.translations.messages.errors.invalid_tables);			
		// }
		return errors;
	}

	onSubmit = (e) => {
		e.preventDefault();

		const errors = this.validate();
		if (errors.length > 0) {
			this.props.addErrorMessages(errors);
			return;
		}

		// let tablesIds = this.state.tables.map(t => t.value);

		const post = {
			id: this.state.id,
			name: this.state.name,
			active: this.state.active,
			// tables_ids: tablesIds
		}

		// dispatch(showLoading());

		this.props.addEditRestaurantHalls(post)
			.then((post) => {
				post = post.data;
				this.props.listRestaurantHalls();
				// this.props.history.push("/admin/restaurantHalls/edit/"+post.id);                
				// dispatch(hideLoading());
			})
			.catch(function (error) {
				this.props.addErrorMessages('Error occured');
			})
	}

	deleteTableRegion = (e, id) => {
		e.preventDefault();
		// this.props.removeTableRegion(id, this.props.history);
	}



	render() {
		// console.log("STATE", this.state);
		return (
			<main className="main">

				{/* <!-- Breadcrumb--> */}
				<ol className="breadcrumb">
					<li className="breadcrumb-item"><NavLink to={ADMIN_URL}>{this.props.translations.common.dashboard}</NavLink></li>
					{/* <li className="breadcrumb-item">Меню</li> */}
					<li className="breadcrumb-item"><NavLink to={ADMIN_URL + "restaurantHalls"}>{this.props.translations.table_regions.title}</NavLink></li>
					<li className="breadcrumb-item active">{this.props.translations.table_regions.edit}</li>
					{/* <!-- Breadcrumb Menu--> */}
					<li className="breadcrumb-menu d-md-down-none">
						<div className="btn-group" role="group" aria-label="Button group">

						</div>
					</li>
				</ol>

				<div className="container-fluid">
					<div className="animated fadeIn">
						<div className="row">

							{/* <!-- /.col--> */}
							<div className="col-sm-12">
								<div className="card">

									<AddEditTableRegionForm
										onSubmit={this.onSubmit}
										onChange={this.onChange}
										onChangeCheckbox={this.onChangeCheckbox}
										onChangeSelectAutocomplete={this.onChangeSelectAutocomplete}
										tables={this.props.tables}
										restaurantHall={this.state}
										deleteTableRegion={this.deleteTableRegion}
										actionText="Запази"
										isAdding={false}
										translations={this.props.translations}
									/>

								</div>
							</div>

						</div>
					</div>
				</div>

			</main>
		)
	}
}

const mapStateToProps = (state, ownProps) => {
	let restaurantHallId = ownProps.match.params.id;
	console.log('restaurantHallId', restaurantHallId);
	console.log('state.restaurantHalls.restaurantHalls', state.restaurantHalls.restaurantHalls);
	let tables = [];

	let restaurantHall = state.restaurantHalls.restaurantHalls.find(a => a.id == restaurantHallId);

	console.log('restaurantHall',restaurantHall);
	if (!restaurantHall) restaurantHall = {
		id: '',
		name: '',
		active: '',
		// tables: [],
	};

	return {
		restaurantHall: restaurantHall,
		tables: state.tables.tables,
		translations: state.lang,
	}


};

export default connect(mapStateToProps, { listRestaurantHalls, addEditRestaurantHalls, addErrorMessages, listTables })(EditRestaurantHallPage);