import axios from 'axios';
import { GET_DELIVERY_SHAPE } from './types';
import { API_URL, ADMIN_URL } from './../../../constants';
import { addSuccessMessage, addErrorMessages} from './../../messages/messages.actions';
import Auth from './../../../utils/Auth';


export const getDeliveryShape = () => dispatch => {
    let getParam = '';
    getParam += "?place_id=" + Auth.getUserPlace();

    axios
        .get(API_URL + 'delivery-shapes' + getParam)
        .then(res => {
            dispatch({
                type: GET_DELIVERY_SHAPE,
                payload: res.data
            });
        })
        .catch(error => {
            throw (error);
        });
}

export const importDeliveryShape = (data) => dispatch => {
    if(!data.placeId) {
        data.placeId = Auth.getUserPlace();
    }

    axios
    .post(API_URL + 'delivery-shapes', data)
    .then(res => {
        // dispatch(listDeliveryRadiuses());
        dispatch(getDeliveryShape());

        dispatch(addSuccessMessage("Successfully imported delivery map"))

    })
    .catch(error => {
        throw (error);
    });
}


export const deleteDeliveryShape = () => dispatch => {
    let data = {};
    data.placeId = Auth.getUserPlace();

    axios
    .post(API_URL + 'delivery-shapes/delete', data)
    .then(res => {
        dispatch(getDeliveryShape());
        dispatch(addSuccessMessage("Successfully deleted delivery map"))

    })
    .catch(error => {
        throw (error);
    });
}