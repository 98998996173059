import React from 'react'
import { NavLink } from 'react-router-dom';
import { ADMIN_URL } from '../../../constants.js'
import DeleteModal from './../../common/DeleteModal';
import { Link } from 'react-router-dom';
import MessagesContainer from './../../messages/MessagesContainer';
import Utils from './../../../utils/Utils';
import Select from 'react-select'
import LangDropdown from '../../common/LangDropdown.js';

// let md5 = require('md5');

const AddEditQuestionForm = (props) => {

    let questionTypesOptions = [
        { 'value': "stars", 'label': props.translations.feedback.questions.question_types['stars']},
        { 'value': "text", 'label': props.translations.feedback.questions.question_types['text'] }
    ];

    return (

    // <form onSubmit={props.onSubmit}>
    <form>
						
        <div className="card-body">
            <MessagesContainer/>	

            <LangDropdown
                isDisabled={props.isAdding == 1 ? 1 : ""}
                currLangOnChange={props.currLangOnChange}
                currLang={props.currLang}
                languages={props.languages}
            />


            <div className="row">
                <div className="col-sm-12">
                    <div className="form-group">
                        <label htmlFor="name">{props.translations.feedback.questions.question}</label>
                        <input className={`form-control`} id="name" type="text" placeholder={props.translations.feedback.questions.question} name="text" onChange={props.onChange} value={props.question.text}/>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="form-group col-sm-12">
                    <label htmlFor="catHierarchyId">{props.translations.feedback.questions.question_type}</label>

                    <Select
                        value={props.question.type}
                        // isMulti
                        name="colors"
                        options={questionTypesOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(valueLabel) => props.onFieldChange('type', valueLabel)}
                    />
                </div>
            </div>

            <div className=" row">
                <label className="col-md-1 col-form-label">{props.translations.common.active}</label>
                <div className="col-md-11 col-form-label">
                    <label className="switch switch-label switch-pill switch-outline-primary-alt">
                        <input className="switch-input" type="checkbox"  name="active" onChange={props.onChangeCheckbox} checked={props.question.active}/>
                        <span className="switch-slider" data-checked={props.translations.common.yes} data-unchecked={props.translations.common.no}></span>
                    </label>
                </div>
            </div>
        
            
            <hr/>
            <br/>

            <div className="form-actions">
                {props.isAddingQuestion == '1' ? '' : <button className="btn btn-danger mright10" type="button" data-toggle="modal" data-target={"#dangerModal" + props.questionId} > {props.translations.common.delete}</button>}
            
                <DeleteModal
                    id={props.question.id}
                    title={props.question.name}
                    deleteFunc={props.deleteQuestion}
                    translations={props.translations}
                />

                <NavLink to={ADMIN_URL+'questions'}>
                    <button className="btn btn-secondary" type="button">
                        {props.translations.common.cancel}
                    </button>
                </NavLink>

                <button className="fright btn btn-primary" type="submit" onClick={props.onSubmit}>
                    {props.isAddingQuestion == '1' ? props.translations.common.add : props.translations.common.save}
                </button>

            </div>

        </div>

    </form>
)}

export default AddEditQuestionForm;