import React from 'react'
import { NavLink } from 'react-router-dom';
import { ADMIN_URL } from '../../../constants.js'
import DeleteModal from './../../common/DeleteModal';
import { Link } from 'react-router-dom';
import MessagesContainer from './../../messages/MessagesContainer';
import Utils from './../../../utils/Utils';

// let md5 = require('md5');

const AddEditImageForm = (props) => {

    return (

        // <form onSubmit={props.onSubmit}>
        <form>

            <div className="card-body">
                <MessagesContainer />



                <div className="row">
                    
                    {props.isAdding == 1 ? 
                        <React.Fragment>
                            <label className="col-md-1 col-form-label vertical-center" htmlFor="file-multiple-input">{props.translations.common.image}</label>
                            <div className="col-md-12 col-form-label vertical-center" style={{ justifyContent: 'center' }}>
                                {/* <input id="file-multiple-input" type="file" name="file-multiple-inpuproductisLiquidt" multiple=""/> */}
                                <input type='file' name='file' id='placeImage' style={{ display: 'none' }} onChange={props.addUploadedFileText} />
                                <label for="placeImage" type="button" className="btn btn-secondary" style={{ borderBottomLeftRadius: 0, borderBottomRightRadius: 0, margin: 0 }}>{!props.isAdding && props.productImage && props.productImage.length > 0 ? props.translations.products.replace_image : props.translations.products.upload_image}</label>
                                {/* {editImage} */}
                                {props.placeImage.uploadedImgName}
                            </div>
                        </React.Fragment>
                    :
                        null
                    }
                    

                    <div className="form-group col-sm-12">
                        <label htmlFor="name">{props.translations.common.description}</label>
                        <input className={`form-control`} id="text" type="text" name="text" onChange={props.onChange} value={props.placeImage.text} />
                        {/* <div className="invalid-feedback">{props.nameError.message ? props.nameError.message : props.nameError }</div>  */}
                    </div>

                    <label className="col-md-1 col-form-label vertical-center">{props.translations.common.approved}</label>
                    <div className="col-md-2 col-form-label vertical-center">
                        <label className="switch switch-label switch-pill switch-outline-primary-alt">
                            <input className="switch-input" type="checkbox" name="approved" onChange={props.onChangeCheckbox} checked={props.placeImage.approved} />
                            <span className="switch-slider" data-checked={props.translations.common.yes} data-unchecked={props.translations.common.no}></span>
                        </label>
                    </div>

                </div>

            
                <hr />
                <br />

                <div className="form-actions">

                    <NavLink to={ADMIN_URL + 'place-images'}>
                        <button className="btn btn-secondary" type="button">
                            {props.translations.common.cancel}
                        </button>
                    </NavLink>

                    <button className="fright btn btn-primary" type="submit" onClick={props.onSubmit}>
                        {props.isAddingIngredient == '1' ? props.translations.common.add : props.translations.common.save}
                    </button>

                </div>

            </div>

        </form>
    )
}

export default AddEditImageForm;