import React from 'react'
import { NavLink } from 'react-router-dom';
import { ADMIN_URL } from '../../../constants.js'
import DeleteModal from './../../common/DeleteModal';
import Auth from '../../../utils/Auth.js';

const ListEachIngredient = (props) => (
    <tr key={props.ingredient.id}>
        <td>
            {/* If not added translation for the defailt language */}
            {props.ingredient.name}
        </td>
        <td>
            {/* If not added translation for the defailt language */}
            {props.ingredient.price}
        </td>
        <td>
            {props.ingredient.active ? <span className="badge badge-success">{props.translations.common.active}</span> : <span className="badge badge-secondary">{props.translations.common.inactive}</span>}
        </td>
        <td>
                <input type='text'
                    onChange={(ev) => props.onPriorityChange(ev, props.ingredient.id)}
                    onBlur={(ev) => props.updatePriority(ev, props.ingredient.id)}
                    value={props.priority} className="form-control"
                    style={{ maxWidth: 80 }}
                />
        </td>
        {(Auth.hasAccess("SUPER_ADMIN") || Auth.hasAccess('CLIENT_ADMIN') || Auth.hasAccess('PLACE_ADMIN')) && props.currPlace && props.currPlace.warehouseSystemId ?
                <td>
                    <input type='text'
                        onChange={(ev) => props.onWsCodeChange(ev, props.ingredient.id)}
                        onBlur={(ev) => props.updateWsCode(ev, props.ingredient.id)}
                        value={props.wsCode} className="form-control"
                        style={{ maxWidth: 80, minWidth: 65 }}
                    />
                </td>
                :
                null
            }
        <td>
            <NavLink to={ADMIN_URL+'ingredients/edit/' + props.ingredient.id}>
                <button className="btn btn-pill btn-link" type="button">
                    <i className="fas fa-edit"></i>
                </button>
            </NavLink>
            
            <button className="btn btn-pill btn-link" type="button" data-toggle="modal" data-target={"#dangerModal" + props.ingredient.id} >
                <i className="fas fa-trash"></i>
            </button>

            <DeleteModal
                id={props.ingredient.id}
                title={props.ingredient.name}
                deleteFunc={props.deleteIngredient}
                translations={props.translations}
            />

        </td>
    </tr>
)

export default ListEachIngredient;