import axios from 'axios';
import {
    LIST_CATEGORIES_HIERARCHY, ADD_EDIT_CATEGORY_HIERARCHY_ERROR,
    ADD_EDIT_CATEGORY_TRANSLATION_ERROR, GET_CATEGORY_SAMPLE_IMAGES
} from './types';
import { API_URL, ADMIN_URL } from './../constants';
import { addErrorMessages, addSuccessMessage } from './../components/messages/messages.actions';
import Utils from './../utils/Utils';
import Auth from './../utils/Auth';
import { showLoading, hideLoading } from './../components/common/loading/loading.actions';

export const listCategoriesHierarchy = (place_id = 0) => dispatch => {
    // if(place_id == 0) {
    //     place_id = Auth.getUserPlace();
    // }
    let getParam = '';
    if (place_id == 0) {
        place_id = Auth.getUserPlace();
        getParam += "?place_id=" + place_id;
    }

    axios
        .get(API_URL + 'categoriesHierarchyAdmin' + getParam)
        .then(res =>
            dispatch({
                type: LIST_CATEGORIES_HIERARCHY,
                payload: res && res.data || []
            })
        ).catch(error => {
            throw (error);
        });
}


export const getSampleImages = (place_id = 0) => dispatch => {
    axios
        .get(API_URL + 'categoriesHierarchy/getSampleImages')
        .then(res =>
            dispatch({
                type: GET_CATEGORY_SAMPLE_IMAGES,
                payload: res.data
            })
        ).catch(error => {
            throw (error);
        });
}

export const addCategoryHierarchy = (postData, history, imageData, langId) => dispatch => {
    // if(!postData.place_id) {
    //     postData.place_id = Auth.getUserPlace();
    // }
    dispatch(showLoading());
    axios
        .post(API_URL + 'categoriesHierarchy', postData)
        .then(post => {
            post = post.data;
            dispatch(addEditCatHierarchyTranslation(post.id, langId, postData, 1));
            // dispatch(addImage(imageData, post.id, history));
            if (imageData && history) {
                if (imageData.has("file")) {
                    dispatch(addImage(imageData, post.id, history));
                }
                if (imageData.has("file_bckg")) {
                    dispatch(addImageBackground(imageData, post.id, history));
                }
            }

            dispatch(hideLoading());
            if(!imageData || (!imageData.has("file") && !imageData.has("file_bckg"))) {
                history.push(ADMIN_URL + `categories-hierarchy/edit/${post.id}`);
            }
            dispatch(addSuccessMessage("Success adding category"))

        })
        .catch(function (error) {
            if(error && error.response && error.response.data) {
                dispatch(addErrorMessages(error.response.data.error.errors));
            } else {
                console.log(error);
            }
        });
}

export const editCategoryHierarchy = (postData, history = null, imageData = null, langId = null) => dispatch => {
    // if(!postData.place_id) {
    //     postData.place_id = Auth.getUserPlace();
    // } 

    var postForTrans = null;
    //if we translate in another lang -> dont update the cat fields  | update translated cat fields only if we translate in the default lang
    if (Utils.getDefaultLang() && Utils.getDefaultLang().id != langId) {
        postForTrans = { ...postData };
        delete postData.name;
        delete postData.description;
        delete postData.links;
        delete postData.video_title;
        delete postData.video_description;
        delete postData.promo_title;
    }

    axios
        .post(API_URL + 'categoriesHierarchy', postData)
        .then((post) => {
            if (langId) {
                post = post.data;

                dispatch(addEditCatHierarchyTranslation(post.id, langId, postForTrans ? postForTrans : postData, 0));
            }

            if (imageData && history) {
                if (imageData.has("file")) {
                    dispatch(addImage(imageData, post.id, history));
                }
                if (imageData.has("file_bckg")) {
                    dispatch(addImageBackground(imageData, post.id, history));
                }
            } else {
                dispatch(listCategoriesHierarchy());
            }

            if (!langId && !history && !imageData) {
                dispatch(addSuccessMessage("Successfully edited category"))
            }
        })
        .catch(function (error) {
            console.log('error', error);
            // dispatch(addErrorMessages(error.response.data));
            dispatch(addErrorMessages(error.response && error.response.data && error.response.data.error && error.response.data.error.errors ? error.response.data.error.errors : 'Error'));
        })
}

export const addEditCatHierarchyTranslation = (cat_id, lang_id, data, isAdding = 1) => dispatch => {
    axios
        .post(API_URL + `categoriesHierarchy/${cat_id}/translations/${lang_id}`, data, {
            'Content-Type': 'multipart/form-data'
        })
        .then(res => {
            dispatch(listCategoriesHierarchy());

            if (isAdding) {
                dispatch(addSuccessMessage("Successfully created category"))
            } else {
                dispatch(addSuccessMessage("Successfully updated category"))
            }

        })
        .catch(err => {

        })
}

export const addImage = (file, catId, history) => dispatch => {
    if (file == null) {
        history.push(ADMIN_URL + `categories-hierarchy`);
        return;
    }

    axios
        .post(API_URL + `categoriesHierarchy/${catId}/upload`, file)
        .then(function (res) {
            if (res.error) {
                //TODO image not uploaded Toastr
            }
            else {
                // history.push(ADMIN_URL + 'products_admin');
            }
            // history.push(ADMIN_URL + `categories-hierarchy/edit/${catId}`);
            history.push(ADMIN_URL + `categories-hierarchy/edit/${catId}`);

        }).catch(err => {
            dispatch(addErrorMessages([err.response.data.error]));
        })
}

export const addImageBackground = (file, catId, history) => dispatch => {
    if (file == null) {
        history.push(ADMIN_URL + `categories-hierarchy`);
        return;
    }

    axios
        .post(API_URL + `categoriesHierarchy/${catId}/uploadBckg`, file)
        .then(function (res) {
            if (res.error) {
                //TODO image not uploaded Toastr
            }
            else {
                // history.push(ADMIN_URL + 'products_admin');
            }
            // history.push(ADMIN_URL + `categories-hierarchy/edit/${catId}`);
            history.push(ADMIN_URL + `categories-hierarchy/edit/${catId}`);
        }).catch(err => {
            dispatch(addErrorMessages([err.response.data.error]));
        })
}

export const removeCategoryHierarchy = (id, history) => dispatch => {
    axios
        .post(API_URL + 'categoriesHierarchy/delete', { categoryId: id })
        // .post(API_URL + 'categoriesHierarchy/', { id: id, deleted: true })
        .then(
            function (res) {
                if (history) {
                    // Deleting from /edit
                    history.push(ADMIN_URL + "categories-hierarchy")
                } else {
                    // Deleting from /list
                    dispatch(listCategoriesHierarchy())
                }
            }
        )
        .catch(function (e) {

            // dispatch(addErrorMessages(e.response.data.error.errors));
            dispatch(addErrorMessages([{ message: "The category has subcategories. Remove them first in order to delete it." }]));

        });
}

export const addRemovePromoCategoryProduct = (adding = true, data) => dispatch => {
    const route = adding ? 'addCatPromoProduct' : 'removeCatPromoProduct';

    axios
        .post(API_URL + `categoriesHierarchy/${route}`, data)
        .then(function (res) {
            dispatch(listCategoriesHierarchy())

            if (adding) {
                dispatch(addSuccessMessage("Success adding promo product to category"))
            } else {
                dispatch(addSuccessMessage("Success removing promo product from category"))
            }
        })
        .catch(err => {
            dispatch(addErrorMessages(err.response.data.error.errors));

        })
}

export const imageCategoryUpload = (data, catId, history) => dispatch => {
    // if (file == null) {
    //     history.push(ADMIN_URL + `categories-hierarchy`);
    //     return;
    // }

    dispatch(showLoading());

    axios({
        method: 'post',
        url: API_URL + `categoriesHierarchy/uploadImageCategoryImages`,
        data: data,
        headers: { 'Content-Type': 'multipart/form-data' },
        maxContentLength: 100000000,
        maxBodyLength: 1000000000
    })
        .then(function (res) {
            dispatch(listCategoriesHierarchy());
            dispatch(hideLoading());

            dispatch(addSuccessMessage("Successfully uploaded image"))

        }).catch(err => {
            throw err;
        })

    // axios
    //     .post(API_URL + `categoriesHierarchy/uploadImageCategoryImages`, data)
    //     .then(function (res) {

    //         dispatch(listCategoriesHierarchy());
    //     }).catch(err => {
    //         dispatch(addErrorMessages(err.response.data.error.errors));
    //     })
}

export const editImageCategoryImage = (data) => dispatch => {
    axios
        .post(API_URL + `categoriesHierarchy/editImageCategory`, data)
        .then(function (res) {
            dispatch(addSuccessMessage("Success editing image"))

            dispatch(listCategoriesHierarchy());
        }).catch(err => {
            dispatch(addErrorMessages(err.response.data.error.errors));
        })
}

export const deleteImageCategoryImage = (data) => dispatch => {
    axios
        .post(API_URL + `categoriesHierarchy/deleteImageCategory`, data)
        .then(function (res) {
            dispatch(addSuccessMessage("Success deleting image"))
            dispatch(listCategoriesHierarchy());
        }).catch(err => {
            dispatch(addErrorMessages(err.response.data.error.errors));
        })
}

export const updateDiscountPercentForCatAndProducts = (data) => dispatch => {
    axios
        .post(API_URL + `categoriesHierarchy/updateDiscountPercentForCatAndProducts`, data)
        .then(function (res) {
            dispatch(addSuccessMessage("Success editing category"))
            dispatch(listCategoriesHierarchy());
        }).catch(err => {
            dispatch(addErrorMessages(err.response.data.error.errors));
        })
}

export const uploadVideo = (data) => dispatch => {
    axios
        .post(
            API_URL + `categoriesHierarchy/upload-video`,
            data,
            // {
            //     headers: {
            //         // "Authorization": "YOUR_API_AUTHORIZATION_KEY_SHOULD_GOES_HERE_IF_HAVE",
            //         "Content-type": "multipart/form-data;charset=utf-8",
            //     },                    
            // }    
        )
        .then(function (res) {
            dispatch(addSuccessMessage("Success editing category"))
            dispatch(listCategoriesHierarchy());
        }).catch(err => {
            console.log(err.response, err.response.data)
            // dispatch(addErrorMessages(err.response.data));
        })
}