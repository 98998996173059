import React, { Component } from 'react'
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { ADMIN_URL, PRODUCT_IMG_URL, DEFAULT_CATEGORIES_IMAGES_PATH } from '../../constants.js'
import DeleteCategoryHierarchyModal from './DeleteCategoryHierarchyModal.js';
import MessagesContainer from './../messages/MessagesContainer';
import { getSampleImages } from '../../actions/categoriesHierarchyActions';
import LangDropdown from './../common/LangDropdown';
import Utils from '../../utils/Utils.js';
import Auth from '../../utils/Auth.js';
import Select from 'react-select'
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { bindActionCreators } from 'redux'
// import listReactFiles from 'list-react-files'

var path = require('path');

class AddEditCategoryHierarchyForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            linkTypeRows: 0,
            uploadedImgName: '',
            uploadedBckgImgName: '',
        }
    }

    addUploadedFileText = e => {
        if (e && e.target && e.target.files && e.target.files[0] && e.target.files[0].name) {
            this.setState({ [e.target.name]: e.target.files[0].name })
        }
    }

    componentWillMount() {
        const { links } = this.props;
        // console.log('links', links)
        this.props.getSampleImages();
    }

    componentDidMount() {
        const { links } = this.props;
        // console.log('links', links)

    }

    componentWillReceiveProps(nextProps) {
        // let updatedLinks = 0
        // console.log('nextProps', nextProps)
        // console.log('nextProps.links', JSON.parse(nextProps.links)
        // console.log('Object.values(nextProps.links).length', Object.values(JSON.parse(nextProps.links)).length)
        // console.log('this.state.linkTypeRows', this.state.linkTypeRows)
        if (this.state.linkTypeRows == 0) {
            if (nextProps.isAddingCategory == '1') {
                this.setState({ linkTypeRows: 1 })
            } else if (nextProps && nextProps.links) {
                this.setState({ linkTypeRows: Object.values(JSON.parse(nextProps.links)).length })
            }
        }
    }



    render() {
        const onChangeSelectAutocompleteType = (e) => {
            const props = this.props;
            if (e && e.value && e.value == 'link' && this.state.linkTypeRows == 0) {
                this.setState({ linkTypeRows: (props.category.links && props.category.links != '' && Object.values(JSON.parse(props.category.links)).length > 0 ? Object.values(JSON.parse(props.category.links)).length : 1) })
            }
            props.onChangeSelectAutocompleteType(e);
        }
        const props = this.props;
        // console.log('props.category.links', props.category.links)
        const links = props.category.links && props.category.links != '' && Object.values(props.category.links) && Object.values(props.category.links).length > 0 ? JSON.parse(props.category.links) : null;

        // Initial set
        // if (links && links.length > this.state.linkTypeRows) {
        // this.setState({ linkTypeRows: links.length })
        // }

        // console.log('props', props)
        let editBckgImage;
        let editImage;
        if (props.isAddingCategory == '0' && (props.icon && props.icon.length > 0) && (props.icon)) {
            editImage = <img src={Utils.getCategoryImagePath(props.icon)} className="editPagesImage" />
        }
        if (props.isAddingCategory == '0' && (props.background_image && props.background_image.length > 0) && (props.background_image)) {
            editBckgImage = <img src={Utils.getCategoryImagePath(props.background_image)} className="editPagesImage" />
        }

        let selectedParent;
        let selectedType = { 'value': (props.type ? props.type : 'default'), 'label': props.translations.common.types[props.type] };
        // console.log('props.type', props.type)
        // let selectedType = props.type ? props.type : 'default';
        let autocompleteCats = [];
        autocompleteCats.push({ 'value': null, 'label': "Основна категория (категория родител)" })
        Object.values(props.categoriesHierarchy).map(cat => {
            if (props.category.parentId == cat.id) {
                selectedParent = { 'value': cat.id, 'label': cat.name + (cat.code ? ' (' + cat.code + ')' : '') };
            }

            if (/*cat.active == 1 &&*/ cat.deleted == 0) {
                let dashes = '';
                for (let i = 0; i < cat.hierarchyLevel - 1; i++) {
                    dashes += "-";
                }

                autocompleteCats.push({ title: cat.active != 1 ? 'Inactive/Неактивна' : '', 'value': cat.id, 'label': (cat.active != 1 ? 'X | ' : '') + dashes + cat.name + (cat.code ? ' (' + cat.code + ')' : '') + (props.categoriesHierarchy && props.categoriesHierarchy[cat.parentId] && props.categoriesHierarchy[cat.parentId].name ? (" /" + props.categoriesHierarchy[cat.parentId].name + '/') : '') });
            }
        });

        let activeDaysOfWeek = [];
        for (let i = 1; i <= 7; i++) {
            let inpName = `activeDaysOfWeek.${i}`
            activeDaysOfWeek.push(
                <div className="row">
                    <label className="col-md-2 col-form-label" style={{ whiteSpace: 'nowrap' }}>{props.translations.common.activeDaysOfWeek[i]}</label>
                    <div className="col-md-10 col-form-label">
                        <label className="switch switch-label switch-pill switch-outline-primary-alt">
                            <input className="switch-input" type="checkbox" name={i} onChange={props.onChangeCheckboxActiveDaysOfWeek} checked={props.category.activeDaysOfWeek[i]} />
                            <span className="switch-slider" data-checked={props.translations.common.yes} data-unchecked={props.translations.common.no}></span>
                        </label>
                    </div>
                </div>
            )
        }



        //     <div className=" row">
        //     <label className="col-md-1 col-form-label">{props.translations.common.active}</label>
        //     <div className="col-md-11 col-form-label">
        //         <label className="switch switch-label switch-pill switch-outline-primary-alt">
        //             <input className="switch-input" type="checkbox"  name="active" onChange={props.onChangeCheckbox} checked={props.category.active}/>
        //             <span className="switch-slider" data-checked={props.translations.common.yes} data-unchecked={props.translations.common.no}></span>
        //         </label>
        //     </div>
        // </div>



        // console.log("PLACES ", props.places)
        let placesOptions = [];
        let currPlace = props.places && props.places.find(p => p.id == Auth.getUserPlace()) || null;
        let currClientId = 0;
        if (currPlace) {
            currClientId = currPlace.clientId;
        }
        Object.values(props.places).map(place => {
            if (place.active == 1 && place.clientId == currClientId) {
                placesOptions.push({ 'value': place.id, 'label': place.name });
            }
        });

        // let groupedSampleImages = {}
        // Object.keys(this.props.sampleImages).map(folderKey => {
        //     // let folder = img.split('\\')[1];
        //     if(!groupedSampleImages[folderKey]) {
        //         groupedSampleImages[folder] = [];
        //     }
        //     groupedSampleImages[folder].push(img);

        // })

        // console.log("GROUPED ", groupedSampleImages)


        let linkRows = [];
        // if (links && Object.values(links).length > 0) {
        //     console.log('links', links)
        for (let i = 0; i < parseInt(this.state.linkTypeRows); i++) {
            linkRows.push(
                <>
                    <div className="col-md-3 col-sm-12 col-form-label vertical-center">
                        <input className={`form-control`} id={"links_name_" + i} type="text" placeholder={props.translations.common.name} name="name" onChange={e => props.onChangeLink(e, i)} value={links && links[i] ? links[i].name : ''} />
                    </div>
                    <div className="col-md-3 col-sm-12 col-form-label vertical-center">
                        <input className={`form-control`} id={"links_link_" + i} type="text" placeholder={props.translations.common.link} name="link" onChange={e => props.onChangeLink(e, i)} value={links && links[i] ? links[i].link : ''} />
                    </div>
                    <div className="col-md-3 col-sm-12 col-form-label vertical-center">
                        <input className={`form-control`} id={"links_image_" + i} type="text" placeholder={props.translations.common.image} name="image" onChange={e => props.onChangeLink(e, i)} value={links && links[i] ? links[i].image : ''} />
                    </div>
                    <div className="col-md-3 col-sm-12 col-form-label vertical-center">
                        {parseInt(this.state.linkTypeRows) - 1 == i ?
                            <div style={{ display: 'flex', 'grid-template-rows': '1fr 1fr', 'grid-column-gap': 10 }}>
                                <button className="btn btn-secondary w-100" type="button" onClick={() => this.setState({ linkTypeRows: parseInt(this.state.linkTypeRows) + 1 })}>
                                    {props.translations.common && props.translations.common.add_row ? props.translations.common.add_row : 'add'}
                                </button>
                                <button className="btn btn-danger w-100" type="button" disabled={this.state.linkTypeRows == 1} onClick={() => { this.props.removeLink(); this.setState({ linkTypeRows: parseInt(this.state.linkTypeRows) - 1 }); }}>
                                    {props.translations.common && props.translations.common.remove_row ? props.translations.common.remove_row : 'remove'}
                                </button>
                            </div>
                            :
                            null
                        }
                    </div>
                </>
            )
        }
        // }


        return (
            <form onSubmit={props.onSubmit} >
                <MessagesContainer />
                <div className="card-body">

                    <LangDropdown
                        isDisabled={props.isAddingCategory == 1 ? 1 : ""}
                        currLangOnChange={props.currLangOnChange}
                        currLang={props.currLang}
                        languages={props.languages}
                    />

                    {/* <div className="row">
                        <div className="col-sm-12">
                            <div className="form-group">
                                <select className="form-control" id="currLang" type="text"  name="currLang"
                                    disabled={props.isAddingCategory == 1 ? 1 : ""}
                                    onChange={props.currLangOnChange} value={props.currLang}>
                                    <option value="en">en</option>
                                    <option value="bg">bg</option>
                                </select>
                            </div>
                        </div>
                    </div> */}

                    <div className="row">
                        <div className="form-group col-sm-12">
                            <label htmlFor="name">{props.translations.common.name}</label>
                            <input className={`form-control`} id="name" type="text" placeholder={props.translations.common.category_name} name="name" onChange={props.onChange} value={props.category.name} />
                            {/* <div className="invalid-feedback">{props.nameError.message ? props.nameError.message : props.nameError }</div>  */}
                        </div>

                        <div className="form-group col-sm-12">
                            <label htmlFor="description">{props.translations.common.description}</label>
                            <input className={`form-control`} id="description" type="text" placeholder={props.translations.common.description} name="description" onChange={props.onChange} value={props.category.description} />
                            {/* <CKEditor name="description" data={props.category.description} onChange={props.onCKEditorChange} /> */}
                            {/* <div className="invalid-feedback">{props.nameError.message ? props.nameError.message : props.nameError }</div>  */}
                        </div>

                        <div className="form-group col-sm-12">
                            <label htmlFor="parentId">{props.translations.common.category}</label>
                            <span data-toggle="tooltip" data-placement="bottom" title={'Категориите започващи с "X | " са неактивни'}> 🛈</span>

                            <Select name="parentId" options={autocompleteCats}
                                onChange={props.onChangeSelectAutocompleteCategory} value={selectedParent}
                                placeholder={"Основна категория (родител)"}
                            />

                            {/* <select className="form-control" id="parentId" name="parentId" onChange={props.onChange} value={selectedParent}>
                                <option value={null}>Основна категория</option>

                                {categoriesParentOptions}
                            </select> */}
                        </div>

                        <div className="form-group col-sm-12">
                            <label htmlFor="type">{props.translations.common.type}</label>
                            <Select name="type" options={[{ value: 'default', label: props.translations.common.types.default }, { value: 'image', label: props.translations.common.types.image }, { value: 'info', label: props.translations.common.types.info }, { value: 'link', label: props.translations.common.types.link }]}
                                onChange={onChangeSelectAutocompleteType} value={selectedType}
                                placeholder={props.translations.common.types.default}
                            />

                            {/* <select className="form-control" id="parentId" name="parentId" onChange={props.onChange} value={selectedParent}>
                                <option value={null}>Основна категория</option>

                                {categoriesParentOptions}
                            </select> */}
                        </div>
                        {selectedType.value == 'default' ?
                            <div className="form-group col-sm-12">
                                <label htmlFor="code">{props.translations.common.code}</label>
                                <input className={`form-control`} id="code" type="text" placeholder={props.translations.common.code} name="code" onChange={props.onChange} value={props.category.code} />
                                {/* <div className="invalid-feedback">{props.nameError.message ? props.nameError.message : props.nameError }</div>  */}
                            </div>
                            :
                            null
                        }
                    </div>


                    {selectedType.value == 'link' ?
                        <div className="row">
                            {linkRows}
                        </div>
                        :
                        null
                    }


                    <div className="row">

                        <div className="form-group col-md-6 vertical-center">
                            <div className="row">


                                {Auth.hasAccess("SUPER_ADMIN") ?
                                    <>

                                        <div className="form-group col-sm-3 vertical-center">

                                            <label className=" vertical-center" htmlFor="icon">{props.translations.common.background_image}</label>
                                            <div className=" vertical-center" style={{ justifyContent: 'center' }}>
                                                <input type='file' name='uploadedBckgImgName' id='multi1' ref="bckg_image" style={{ display: 'none' }} onChange={this.addUploadedFileText} />
                                                <label htmlFor="multi1" type="button" className="btn btn-secondary" style={{ borderBottomLeftRadius: 0, borderBottomRightRadius: 0, margin: 0 }}>{props.isAddingCategory == '0' && (props.background_image && props.background_image.length > 0) && (props.background_image) ? props.translations.products.replace_image : props.translations.products.upload_image}</label>
                                                {editBckgImage}
                                                {this.state.uploadedBckgImgName}

                                            </div>
                                        </div>

                                        <div className=" col-sm-3">
                                            <label className=" col-form-label vertical-center">{props.translations.common.open_call_waiterCat}</label>
                                            <div className=" col-form-label vertical-center">
                                                <label className="switch switch-label switch-pill switch-outline-primary-alt">
                                                    <input className="switch-input" type="checkbox" name="open_call_waiter" onChange={props.onChangeCheckbox} checked={props.category.open_call_waiter} />
                                                    <span className="switch-slider" data-checked={props.translations.common.yes} data-unchecked={props.translations.common.no}></span>
                                                </label>
                                            </div>
                                        </div>

                                    </>
                                    :
                                    null
                                }

                                <div className="form-group col-sm-6 vertical-center">


                                    <label className=" vertical-center" htmlFor="icon">{props.translations.common.main_image}</label>
                                    <div className=" vertical-center" style={{ justifyContent: 'center' }}>
                                        {/* <input className={`form-control`} id="icon" type="text" placeholder={props.translations.common.icon} name="icon" onChange={props.onChange} value={props.categoryIcon}/> */}
                                        {/* <div className="invalid-feedback">{props.nameError.message ? props.nameError.message : props.nameError }</div>  */}

                                        <input type='file' name='uploadedImgName' id='multi' ref="image" style={{ display: 'none' }} onChange={this.addUploadedFileText} />
                                        <label htmlFor="multi" type="button" className="btn btn-secondary" style={{ borderBottomLeftRadius: 0, borderBottomRightRadius: 0, margin: 0 }}>{props.isAddingCategory == '0' && (props.icon && props.icon.length > 0) && (props.icon) ? props.translations.products.replace_image : props.translations.products.upload_image}</label>
                                        {editImage}
                                        {this.state.uploadedImgName}

                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className="form-group col-md-6 vertical-center">
                            <div style={{ height: 75, border: '1px solid #ccc', overflow: 'scroll', resize: 'both' }}>
                                {Object.keys(this.props.sampleImages).map(imgCat =>
                                    <React.Fragment>
                                        <div>{imgCat}</div>
                                        {this.props.sampleImages[imgCat].map(img => {
                                            img = DEFAULT_CATEGORIES_IMAGES_PATH + path.join(imgCat, img);
                                            return <img className="categories-sample-gallery" onClick={() => props.onFieldChange('icon', img)} src={img} style={{ width: 50, height: 50, border: props.category.icon == img ? '2px solid black' : null }} />
                                        })}
                                    </React.Fragment>
                                )}
                            </div>
                        </div>
                    </div>


                    {selectedType.value == 'default' ?
                        <div className=" row">
                            <div className="col-md-3 vertical-center">
                                <div className="form-group row">
                                    <div className="col-md-6 vertical-center">
                                        <label className="col-form-label" title={this.props.translations.common.rotation_start_date_info}>
                                            {props.translations.common.rotation_start_date}
                                            <span data-toggle="tooltip" data-placement="bottom"> 🛈 </span>
                                        </label>
                                    </div>
                                    <div className="col-md-6 vertical-center">

                                        <DatePicker
                                            className="form-control vertical-center"
                                            selected={props.category.rotation_start_date}
                                            onChange={date => props.onFieldChange('rotation_start_date', date)}
                                            dateFormat="d-M-Y"
                                            style={{ display: 'block' }}
                                        // onCalendarClose={handleCalendarClose}
                                        // onCalendarOpen={handleCalendarOpen}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-3 vertical-center">
                                <div className="form-group row">
                                    <div className="col-md-6 vertical-center">
                                        <label htmlFor="rotation_days_count" title={this.props.translations.common.rotation_days_count_info}>
                                            {props.translations.common.rotation_days_count}
                                            <span data-toggle="tooltip" data-placement="bottom"> 🛈 </span>
                                        </label>
                                    </div>
                                    <div className="col-md-6 vertical-center">
                                        <input className={`form-control`} id="rotation_days_count" type="number" style={{ display: "inline-block", width: "100%" }}
                                            placeholder={props.translations.common.rotation_days_count} name="rotation_days_count" onChange={props.onChange} value={props.category.rotation_days_count} />
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-3 vertical-center">
                                <div className="form-group row">
                                    <div className="col-md-6 vertical-center">
                                        <label className="">{props.translations.common.activeStartTime}</label>
                                    </div>
                                    <div className="col-md-6 vertical-center">
                                        <label className="switch switch-label switch-pill switch-outline-primary-alt" style={{ width: '100%' }}>
                                            <input className={`form-control`} type="time" name="activeStartTime" onChange={props.onChange} value={props.category.activeStartTime} style={{ width: '100%' }} />
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-3 vertical-center">
                                <div className="form-group row">
                                    <div className="col-md-6 vertical-center">
                                        <label className="">{props.translations.common.activeEndTime}</label>
                                    </div>
                                    <div className="col-md-6 vertical-center">
                                        <label className="switch switch-label switch-pill switch-outline-primary-alt" style={{ width: '100%' }}>
                                            <input className={`form-control`} type="time" name="activeEndTime" onChange={props.onChange} value={props.category.activeEndTime} style={{ width: '100%' }} />
                                        </label>
                                    </div>
                                </div>
                            </div>

                        </div>
                        :
                        null
                    }


                    {selectedType.value == 'default' ?
                        <div className="row">
                            <div className="col-md-6 col-sm-12">
                                <label className=" col-form-label vertical-center">{props.translations.common.active}</label>
                                <div className=" col-form-label vertical-center">
                                    <label className="switch switch-label switch-pill switch-outline-primary-alt">
                                        <input className="switch-input" type="checkbox" name="active" onChange={props.onChangeCheckbox} checked={props.category.active} />
                                        <span className="switch-slider" data-checked={props.translations.common.yes} data-unchecked={props.translations.common.no}></span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        :
                        null
                    }

                    <div className=" row">
                        <div className="col-md-6 col-sm-12">
                            <label className=" col-form-label vertical-center">{props.translations.common.show_cat_only_for_spot_orders_text}</label>
                            <div className=" col-form-label vertical-center">
                                <label className="switch switch-label switch-pill switch-outline-primary-alt">
                                    <input className="switch-input" type="checkbox" name="show_cat_only_for_spot_orders" onChange={props.onChangeCheckbox} checked={props.category.show_cat_only_for_spot_orders} />
                                    <span className="switch-slider" data-checked={props.translations.common.yes} data-unchecked={props.translations.common.no}></span>
                                </label>
                            </div>
                        </div>

                        <div className="col-md-6 col-sm-12">
                            <label className=" col-form-label vertical-center">{props.translations.common.hide_cat_only_for_spot_orders_text}</label>
                            <div className=" col-form-label vertical-center">
                                <label className="switch switch-label switch-pill switch-outline-primary-alt">
                                    <input className="switch-input" type="checkbox" name="hide_cat_only_for_spot_orders" onChange={props.onChangeCheckbox} checked={props.category.hide_cat_only_for_spot_orders} />
                                    <span className="switch-slider" data-checked={props.translations.common.yes} data-unchecked={props.translations.common.no}></span>
                                </label>
                            </div>
                        </div>
                    </div>


                    <div className=" row">
                        <div className="col-md-6 col-sm-12">
                            <label className=" col-form-label vertical-center">{props.translations.common.hide_cat_only_for_delivery_orders_text}</label>
                            <div className=" col-form-label vertical-center">
                                <label className="switch switch-label switch-pill switch-outline-primary-alt">
                                    <input className="switch-input" type="checkbox" name="hide_cat_only_for_delivery_orders" onChange={props.onChangeCheckbox} checked={props.category.hide_cat_only_for_delivery_orders} />
                                    <span className="switch-slider" data-checked={props.translations.common.yes} data-unchecked={props.translations.common.no}></span>
                                </label>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <label className=" col-form-label vertical-center">{props.translations.common.show_cat_only_for_delivery_orders_text}</label>
                            <div className=" col-form-label vertical-center">
                                <label className="switch switch-label switch-pill switch-outline-primary-alt">
                                    <input className="switch-input" type="checkbox" name="show_cat_only_for_delivery_orders" onChange={props.onChangeCheckbox} checked={props.category.show_cat_only_for_delivery_orders} />
                                    <span className="switch-slider" data-checked={props.translations.common.yes} data-unchecked={props.translations.common.no}></span>
                                </label>
                            </div>
                        </div>
                    </div>

                    <div className=" row">
                        <div className="col-md-6 col-sm-12">
                            <label className="col-form-label vertical-center">{props.translations.common.show_only_for_spot_browse}</label>
                            <div className="col-form-label vertical-center">
                                <label className="switch switch-label switch-pill switch-outline-primary-alt">
                                    <input className="switch-input" type="checkbox" name="show_only_for_spot_browse" onChange={props.onChangeCheckbox} checked={props.category.show_only_for_spot_browse} />
                                    <span className="switch-slider" data-checked={props.translations.common.yes} data-unchecked={props.translations.common.no}></span>
                                </label>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <label className="col-form-label vertical-center">{props.translations.common.hide_only_for_spot_browse}</label>
                            <div className="col-form-label vertical-center">
                                <label className="switch switch-label switch-pill switch-outline-primary-alt">
                                    <input className="switch-input" type="checkbox" name="hide_only_for_spot_browse" onChange={props.onChangeCheckbox} checked={props.category.hide_only_for_spot_browse} />
                                    <span className="switch-slider" data-checked={props.translations.common.yes} data-unchecked={props.translations.common.no}></span>
                                </label>
                            </div>
                        </div>
                    </div>


                    {selectedType.value == 'default' ?
                        <div className="col-md-6">
                            <div className="row m0">
                                {activeDaysOfWeek}
                            </div>
                        </div>
                        :
                        null
                    }

                    <br />

                    {Auth.hasAccess("CLIENT_ADMIN") && Utils.getCurrClientPlacesCount(props.places) > 1 ?
                        <div className="row">
                            <div className="form-group col-sm-12">
                                <label htmlFor="catHierarchyId">{props.translations.categories.places_for_category}</label>
                                <Select
                                    value={props.category.currPlaces}
                                    isMulti
                                    name="colors"
                                    options={placesOptions}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    onChange={props.onChangeSelectAutocompletePlaces}
                                // onChange={(values, { action, removedValue }) => props.ingredientsOnChange(values, { action, removedValue }, 'removable')}
                                />
                            </div>
                        </div>
                        :
                        null}


                    <hr />
                    <br />

                    <div className="form-actions">
                        {props.isAddingCategory == '1' ? '' : <button className="btn btn-danger mright10" type="button" data-toggle="modal" data-target={"#dangerModal" + props.categoryId} >{props.translations.common.delete}</button>}

                        <DeleteCategoryHierarchyModal
                            categoryId={props.categoryId}
                            categoryName={props.category.name}
                            deleteCategory={props.deleteCategory}
                            translations={props.translations}
                        />

                        <NavLink to={ADMIN_URL + 'categories-hierarchy'}>
                            <button className="btn btn-secondary" type="button">
                                {props.translations.common.cancel}
                            </button>
                        </NavLink>

                        <button className="fright btn btn-primary" type="submit">
                            {props.isAddingCategory == '1' ? props.translations.common.add : props.translations.common.save}
                            {/* {props.actionText} */}
                        </button>

                    </div>

                </div>

            </form >
        )
    }
}





const mapStateToProps = (state, dispatch) => {
    return {
        sampleImages: state.categoriesHierarchy.sampleImages || [],
        translations: state.lang,
    }
};

const mapDispatchToProps = (dispatch, props) => {
    return {
        dispatch,
        ...bindActionCreators({ getSampleImages }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddEditCategoryHierarchyForm);