import React, { Component } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'

import { addEditInvoice, listInvoices } from '../actions/invoices.actions';
import ListInvoicesRow from './ListInvoicesRow';
import { NavLink } from 'react-router-dom';
import { ADMIN_URL, ITEMS_PER_PAGE, ITEMS_PAGE_RANGE_DISPLAYED } from '../../../constants.js'
import Pagination from "react-js-pagination";
import { push } from 'react-router-redux';
// import Filter from './Filter';
import MessagesContainer from '../../messages/MessagesContainer';
import { translations } from '../../common/translations';
import Filter from './Filter';
import Utils from '../../../utils/Utils';
const INVOICE_STATUSES = ['new', 'sent', 'paid', 'canceled'];

class ListInvoicesPage extends React.Component {
    constructor(props) {
        super(props);
        this.activeDefaultLanguage = 'bg'; // TODO add it in db
        this.state = {
            search_invoice_date: '',
            search_company_name: '',
            search_number: '',
            search_status: '',
            search_month: '',
            search_place: '',
            search_year: '',
            sort_by: '',
            search_without_cancelled: '',
            checked_checkboxes_ids: [],
        }
    }

    componentWillMount() {
        this.props.listInvoices();
    }

    deleteInvoice = (e, id) => {
        e.preventDefault();
        this.props.addEditInvoice({ id: id, deleted: true });
    }

    handlePageChange = (page) => {
        this.props.dispatch(push('/admin/invoices?page=' + page))
    }

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
        this.handlePageChange(1);
    }

    updateStatus = (e, invoiceId) => {
        let newStatus = e.target.options[e.target.options.selectedIndex].value;

        const post = {
            id: invoiceId,
            status: newStatus
        }

        this.props.addEditInvoice(post, this.props.history)
            .then((post) => {
                this.props.listInvoices();
            })
            .catch((error) => {
                this.props.addErrorMessages(error);
            })
    }

    updateRemindDate = (date, invoiceId) => {
        const post = {
            id: invoiceId,
            remind_on: date
        }

        this.props.addEditInvoice(post, this.props.history)
            .then((post) => {
                this.props.listInvoices();
            })
            .catch((error) => {
                this.props.addErrorMessages(error);
            })
    }


    toggleAllCheckboxes = () => {
        let allInvoicesIds = []
        for (let k = 0; k < this.props.items.length; k++) {
            allInvoicesIds.push(this.props.items[k].id)
        }

        this.setState({
            checked_checkboxes_ids: this.state.checked_checkboxes_ids.length == 0 ? allInvoicesIds : [],
        })
    }


    toggleCheckboxValue = (invoice_id) => {
        let checkboxesIds = this.state.checked_checkboxes_ids;
        if (invoice_id) {
            if (checkboxesIds.includes(invoice_id)) {
                checkboxesIds.splice(checkboxesIds.indexOf(invoice_id), 1)
            } else {
                checkboxesIds.push(invoice_id);
            }
            this.setState({ checked_checkboxes_ids: checkboxesIds });
        }
    }
    updateInvoice = (object) => {
        const post = object;

        this.props.addEditInvoice(post, this.props.history)
            .then((post) => {
                this.props.listInvoices();
            })
            .catch((error) => {
                this.props.addErrorMessages(error);
            })
    }

    render() {
        let invoices = this.props.items;
        let places = this.props.places;

        if (this.state.sort_by) {
            if (this.state.sort_by == 'date') {
                invoices = invoices.sort(Utils.sortByRemindOn());
                // } else {
                //     invoices = this.props.items;
            }
        }

        if (this.state.search_company_name.length > 0
            || this.state.search_number != 0
            || this.state.search_without_cancelled != ''
            || this.state.search_status != ''
            || this.state.search_place != ''
            || (this.state.search_year != '')
            || (this.state.search_month != '' && this.state.search_year != '')
        ) {
            invoices = Object.values(invoices).filter(i => {
                let invoiceDateYear = new Date(i.invoice_date).getFullYear();
                let invoiceDateMonth = new Date(i.invoice_date).getMonth() + 1;

                return (
                    ((i.company_name).toLocaleLowerCase().includes((this.state.search_company_name).toLocaleLowerCase()))
                    && ((i.number).includes((this.state.search_number).toLocaleLowerCase()))
                    && (this.state.search_without_cancelled != '' ? i.status != 'canceled' : true)
                    && (this.state.search_status != '' ? i.status == this.state.search_status : true)
                    && (this.state.search_place != '' ? i.placeId == this.state.search_place : true)
                    && (this.state.search_year != '' ? invoiceDateYear == this.state.search_year : true)
                    && (this.state.search_month != '' && this.state.search_year != '' ? (invoiceDateYear == this.state.search_year && invoiceDateMonth == this.state.search_month
                    ) : true)
                )
            })
        }

        const items_count = (invoices).length
        const current_page = this.props.page;
        const start_offset = (current_page - 1) * ITEMS_PER_PAGE;
        let start_count = 0;

        let totalSumInvoices = 0
        invoices.map(i => totalSumInvoices += parseFloat(i.price))

        return (
            <main className="main">

                {/* <!-- Breadcrumb--> */}
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><NavLink to={ADMIN_URL}>{this.props.translations.common.dashboard}</NavLink></li>
                    {/* <li className="breadcrumb-item">Меню</li> */}
                    <li className="breadcrumb-item active">{this.props.translations.invoices.title}</li>
                    {/* <!-- Breadcrumb Menu--> */}
                    <li className="breadcrumb-menu d-md-down-none">

                        <div className="btn-group c-pointer" role="group" aria-label="Button group" onClick={() => this.setState({ sort_by: (this.state.sort_by != 'date' ? 'date' : '') })}>
                            <span>
                                <i className="fas fa-calendar"></i> &nbsp; {this.props.translations.invoices.sort_by_date}
                            </span>
                        </div>

                        <div className="btn-group" role="group" aria-label="Button group">
                            <NavLink to={ADMIN_URL + `invoices/add`} className="btn" >
                                <i className="fas fa-plus"></i> &nbsp; {this.props.translations.invoices.add}
                            </NavLink>

                            {/* 
                            <!--<a className="btn" href="./">
                                <i className="icon-graph"></i>  Dashboard</a>
                            <a className="btn" href="#">
                                <i className="icon-settings"></i>  Settings</a>
                            --> 
                            */}
                        </div>
                    </li>


                </ol>

                <div className="container-fluid">
                    <div className="animated fadeIn">
                        <div className="row">

                            <Filter
                                places={places}
                                translations={this.props.translations}
                                onChange={this.onChange}
                                state={this.state}
                            // search_invoice_date={this.state.search_invoice_date}
                            // search_company_name={this.state.search_company_name}
                            />

                            {/* <!-- /.col--> */}
                            <div className="col-lg-12">
                                <div className="card">

                                    <div className="card-body">

                                        <MessagesContainer />

                                        <table className="table table-responsive-sm table-striped">
                                            <thead>
                                                <tr>
                                                    <th style={{ width: '10%' }}>{this.props.translations.invoices.number}</th>
                                                    <th style={{ width: '5%' }}>{this.props.translations.invoices.date}</th>
                                                    <th style={{ width: '5%' }}>{this.props.translations.invoices.company_name}</th>
                                                    <th style={{ width: '5%' }}>{this.props.translations.invoices.company_eik}</th>
                                                    <th style={{ width: '5%' }}>{this.props.translations.invoices.company_person}</th>
                                                    <th style={{ width: '15%' }}>{this.props.translations.invoices.company_email}</th>
                                                    <th style={{ width: '10%' }}>{this.props.translations.invoices.status}</th>
                                                    <th style={{ width: '10%' }} title={'Total sum filtered invoices: ' + parseFloat(totalSumInvoices).toFixed(2)}>
                                                        <span onClick={() => {
                                                            localStorage.setItem('visibleTotalSum', localStorage.getItem('visibleTotalSum') == 1 ? 0 : 1);
                                                            window.location.reload();
                                                        }}>
                                                            {this.props.translations.invoices.price}
                                                        </span>
                                                        {localStorage.getItem('visibleTotalSum') == 1 ? <><br />{parseFloat(totalSumInvoices).toFixed(2)}</> : ''}
                                                    </th>
                                                    <th style={{ width: '5%' }}>{this.props.translations.common.place}</th>
                                                    {/* <th style={{ width: '10%' }}>{this.props.translations.invoices.remind_on}</th> */}
                                                    <th style={{ width: '15%' }}>{this.props.translations.invoices.partly_payment}</th>
                                                    <th style={{ width: '10%' }}>{this.props.translations.invoices.download}</th>
                                                    {/* <th>{this.props.translations.invoices.invoice_original}</th>
                                                    <th>{this.props.translations.invoices.invoice_copy}</th> */}
                                                    {/* <th>{this.props.translations.common.icon}</th> */}
                                                    <th style={{ width: '5%' }}>{this.props.translations.common.action}</th>
                                                    <th style={{ width: '5%' }}>
                                                        <input type="checkbox"
                                                            className="d-inline medium-checkbox valign-middle c-pointer w-10 m-right-7 valign-middle"
                                                            style={{ width: 'auto !important' }}
                                                            onChange={this.toggleAllCheckboxes}
                                                        />
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                {(invoices).map((invoice, index) => {
                                                    if (index >= start_offset && start_count < ITEMS_PER_PAGE) {
                                                        start_count++;
                                                        return <ListInvoicesRow
                                                            key={invoice.id}
                                                            state={this.state}
                                                            invoice={invoice}
                                                            deleteInvoice={this.deleteInvoice}
                                                            updateStatus={this.updateStatus}
                                                            updateDate={this.updateRemindDate}
                                                            updateInvoice={this.updateInvoice}
                                                            // activeDefaultLanguage={this.activeDefaultLanguage}
                                                            translations={this.props.translations}
                                                            currLang={this.props.currLang}
                                                            initInvoiceSettings={this.props.initInvoiceSettings}
                                                            invoicesSmsLogs={this.props.invoicesSmsLogs}
                                                            invoiceStatuses={INVOICE_STATUSES}
                                                            toggleCheckboxValue={this.toggleCheckboxValue}
                                                        />
                                                    }
                                                })}

                                            </tbody>
                                        </table>

                                        <Pagination
                                            className="pagination"
                                            itemClass="page-item"
                                            activePage={current_page}
                                            activeClass="active"
                                            linkClass="page-link"
                                            prevPageText="<"
                                            nextPageText=">"
                                            firstPageText="<<"
                                            lastPageText=">>"
                                            itemsCountPerPage={ITEMS_PER_PAGE}
                                            totalItemsCount={items_count}
                                            pageRangeDisplayed={ITEMS_PAGE_RANGE_DISPLAYED}
                                            onChange={this.handlePageChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            {/* <!-- /.col--> */}
                        </div>
                    </div>
                </div>
            </main>
        )
    }
}

const mapStateToProps = (state, dispatch) => {
    return {
        items: state.invoices.invoices || [],
        page: Number(state.router.location.query.page) || 1,
        translations: state.lang,
        invoicesSmsLogs: state.invoices.invoices_sms_logs || [],
        currLang: "bg",
        places: state.places.places || [],
    }
};

const mapDispatchToProps = (dispatch, props) => {
    return {
        dispatch,
        ...bindActionCreators({ addEditInvoice, listInvoices }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ListInvoicesPage);
