import React, { Component } from 'react'
import AddEditPromocodeForm from './AddEditPromocodeForm';
import { editTable, removeTable } from '../../actions/tablesActions';
import { addErrorMessages } from '../messages/messages.actions';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { ADMIN_URL } from '../../constants';
import Utils from '../../utils/Utils';
import Auth from '../../utils/Auth';
import { listPromocodes, addEditPromocode } from './actions/promocode.action';

let md5 = require('md5');

class EditTablePage extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			id: this.props.match.params.id,
			discount_value: this.props.promocode.discount_value,
			discount_type: this.props.promocode.discount_type,
			valid_from: this.props.promocode.valid_from,
			valid_to: this.props.promocode.valid_to,
			used: this.props.promocode.used,
			active: this.props.promocode.active,
			createdAt: this.props.promocode.createdAt,
			updatedAt: this.props.promocode.updatedAt,
			placeId: this.props.promocode.placeId,
			ordertypes: this.props.promocode.ordertypes,
			code: this.props.promocode.code,
			ignore_other_discounts: this.props.promocode.ignore_other_discounts,
			never_become_used: this.props.promocode.never_become_used,
			description: this.props.promocode.description
		}
	}

	componentWillReceiveProps(nextProps) {
		// let tableOrdertype = [];
		// if (nextProps.table.ordertypes.length > 0) {
		// 	// nextProps.table.ordertypes.map(type => {
		// 	tableOrdertype.push({ value: parseInt(nextProps.table.ordertypes[0].id), label: nextProps.table.ordertypes[0].type })
		// 	// })
		// }
		// console.log("RECEIVE ", tableOrdertype);
		// console.log(`nextProps.currPlaceOrderTypes`, nextProps.currPlaceOrderTypes)

		let ordertypes = [];
		if (nextProps.promocode.ordertypes.length > 0) {
			nextProps.promocode.ordertypes.map(ot => {
				let currPlace = nextProps.currPlaceOrderTypes.find(p => p.id == ot.id);
				let currPlaceName = currPlace ? currPlace.type : "";
				ordertypes.push({ value: parseInt(ot.id), label: currPlaceName })
			})
		}
		console.log("nextProps.promocode", nextProps.promocode)
		this.setState({
			id: nextProps.promocode.id,
			discount_value: nextProps.promocode.discount_value,
			discount_type: nextProps.promocode.discount_type,
			valid_from: nextProps.promocode.valid_from,
			valid_to: nextProps.promocode.valid_to,
			used: nextProps.promocode.used,
			active: nextProps.promocode.active,
			createdAt: nextProps.promocode.createdAt,
			updatedAt: nextProps.promocode.updatedAt,
			placeId: nextProps.promocode.placeId,
			// ordertypes: nextProps.promocode.ordertypes,
			ordertypes: ordertypes,
			code: nextProps.promocode.code,
			ignore_other_discounts: nextProps.promocode.ignore_other_discounts,
			never_become_used: nextProps.promocode.never_become_used,
			description: nextProps.promocode.description,
		});
	}

	componentWillMount() {
		this.props.listPromocodes({search_place_id: Auth.getUserPlace()});
	}

	onChange = (e) => {
		this.setState({ [e.target.name]: e.target.value })
	}

	onChangeCheckbox = (e) => {
		this.setState({ [e.target.name]: e.target.checked });
	}

	onChangeSelectAutocomplete = (fieldName, valueLabel) => {
		this.setState({ [fieldName]: valueLabel });
	}

	onFieldChange = (fieldName, value) => {
		this.setState({ [fieldName]: value });
	}


	onChangeSelect = (name, valueLabel) => {
		let newState = [];
		newState[name] = valueLabel.value;
		this.setState(newState);
	}

	validate() {
		let errors = [];

		if (this.state.code.length == 0) {
			errors.push(this.props.translations.messages.errors.invalid_table_num);
		}

		if (this.state.placeId.length == '') {
			errors.push(this.props.translations.messages.errors.invalid_place);
		}

		return errors;
	}

	onSubmit = (e) => {
		e.preventDefault();
		const errors = this.validate();
		if (errors.length > 0) {
			this.props.addErrorMessages(errors);
			return;
		}

		let ordertypes = this.state.ordertypes.map(c => c.value);

		const post = {
			id: this.state.id,
			discount_value: this.state.discount_value,
			discount_type: this.state.discount_type,
			valid_from: this.state.valid_from,
			valid_to: (new Date(this.state.valid_to).setHours(23, 59, 59)), //this.state.valid_to,
			used: this.state.used,
			active: this.state.active,
			createdAt: this.state.createdAt,
			updatedAt: this.state.updatedAt,
			placeId: this.state.placeId,
			ordertypes: ordertypes,
			code: this.state.code,
			ignore_other_discounts: this.state.ignore_other_discounts,
			never_become_used: this.state.never_become_used,
			description: this.state.description
		}

		this.props.addEditPromocode(post, this.props.history);

	}

	deleteFunc = (e, id) => {
		e.preventDefault();
		// this.props.removeProduct(id);
		this.props.addEditPromocode({ id: id, deleted: true }, this.props.history);
	}


	render() {
		return (
			<main className="main">

				{/* <!-- Breadcrumb--> */}
				<ol className="breadcrumb">
					<li className="breadcrumb-item"><NavLink to={ADMIN_URL}>{this.props.translations.common.dashboard}</NavLink></li>
					<li className="breadcrumb-item"><NavLink to={ADMIN_URL + "promocodes"}>{this.props.translations.promocodes.title}</NavLink></li>
					<li className="breadcrumb-item active">{this.props.translations.promocodes.edit}</li>
					<li className="breadcrumb-menu d-md-down-none">
						<div className="btn-group" role="group" aria-label="Button group">
						</div>
					</li>
				</ol>

				<div className="container-fluid">
					<div className="animated fadeIn">
						<div className="row">

							{/* <!-- /.col--> */}
							<div className="col-sm-12">
								<div className="card">

									<AddEditPromocodeForm
										onSubmit={this.onSubmit}
										onChange={this.onChange}
										onChangeCheckbox={this.onChangeCheckbox}
										onChangeSelect={this.onChangeSelect}
										onChangeSelectAutocomplete={this.onChangeSelectAutocomplete}
										onFieldChange={this.onFieldChange}
										promocode={this.state}
										places={this.props.places}
										currPlaceOrderTypes={this.props.currPlaceOrderTypes}
										deleteFunc={this.deleteFunc}
										actionText="Запази"
										isAdding="0"
										nameError={this.props.errors.find(error => error.path === 'name') ? this.props.errors.find(error => error.path === 'name') : ""}
										translations={this.props.translations}
									/>
								</div>
							</div>

						</div>
					</div>
				</div>

			</main>
		)
	}
}

const mapStateToProps = (state, ownProps) => {
	let promocodeId = ownProps.match.params.id;

	let orderTypes = [];

	if (state.places.places) {
		let currPlace = state.places.places.find(p => p.id == Auth.getUserPlace());
		if (currPlace) {
			orderTypes = currPlace.ordertypes;
		}
	}

	let promocode = state.promocodes.promocodes.find(p => p.id == promocodeId);
	if (!promocode) promocode = {
		id: '',
		discount_value: '',
		discount_type: '',
		valid_from: '',
		valid_to: '',
		used: '',
		active: '',
		createdAt: '',
		updatedAt: '',
		placeId: '',
		ordertypes: '',
		code: '',
		ignore_other_discounts: '',
		never_become_used: ''
	};
	return {
		promocode: promocode,
		promocodes: state.promocodes.promocodes,
		currPlaceOrderTypes: orderTypes,
		errors: state.tables.errors,
		translations: state.lang,

		clients: state.clients.clients,
		places: state.places.places,
	}


};

export default connect(mapStateToProps, { listPromocodes, addEditPromocode, editTable, removeTable, addErrorMessages })(EditTablePage);