import React, { Component } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'

import { countPeopleSawTheMenu } from './../actions/statistics.actions';
import moment from 'moment';
import {LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer} from 'recharts';
// const data = [
//     {name: '20.09.2020', pv: 2400},
//     {name: '19.09.2020', pv: 1398},
//     {name: '18.09.2020', pv: 9800},
//     {name: '17.09.2020', pv: 3908},
//     {name: '16.09.2020', pv: 4800},
//     {name: '15.09.2020', pv: 3800},
//     {name: '15.09.2020', pv: 4300},
// ];


class UsersByDateChart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            
        }
    }

    componentWillMount() {
        // this.props.countPeopleSawTheMenu();
    }

	render () {

        
        return (
            <div>
                <h5>
                    {this.props.translations.statistics.users_by_date_count} : {moment(this.props.filter_start_date).format("D/M/Y")} - {moment(this.props.filter_end_date).format("D/M/Y")}
                </h5>
                <ResponsiveContainer width="99%" aspect={3}>

                <LineChart width={700} height={300} data={this.props.peopleSawTheMenuCount} margin={{top: 5, right: 30, left: 20, bottom: 5}}>
                    <XAxis dataKey="date"/>
                    <YAxis/>
                    <CartesianGrid strokeDasharray="3 3"/>
                    <Tooltip formatter={(label) => "Брой потребители " + label } />
                    <Legend />
                    <Line type="monotone" dataKey="uniqueUsersCount" stroke="#8884d8" activeDot={{r: 8}}/>
                </LineChart>
                </ResponsiveContainer>
            </div>
            
        );
  }
}

const mapStateToProps = (state, dispatch) => {
    return {
        peopleSawTheMenuCount: state.statistics.people_saw_the_menu_count || [],
        translations: state.lang,
        currLang: "bg",
    }
};

const mapDispatchToProps = (dispatch, props) => {
    return {
        dispatch,
        ...bindActionCreators({ countPeopleSawTheMenu }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UsersByDateChart);
