import React from 'react'
import ListSettingRow from './ListSettingRow';

const ListSearchedSettings = (props) => {

    return (
        <table className="table table-responsive-sm table-striped break-content">
            <thead>
                <tr>
                    {/* <th width="40%">Коментар</th> */}
                    <th width="40%">{props.translations.common.name}</th>
                    <th width="40%">{props.translations.common.value}</th>
                    <th width="20%">{props.translations.common.action}</th>
                </tr>
            </thead>
            <tbody>
                {props.settings.length > 0 ?
                    Object.values(props.settings).map((setting, index) => {
                        return <ListSettingRow
                            key={setting.id}
                            setting={setting}
                            translations={props.translations}
                            userRole={props.userRole}
                        />
                    })
                :
                    <tr>
                        <td colSpan={3}>No settings found</td>
                    </tr>
                }
               
            </tbody>
        </table>
    )
}

export default ListSearchedSettings;