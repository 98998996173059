import React, { Component } from 'react'
import { NavLink } from 'react-router-dom';
import { ADMIN_URL, PRODUCT_IMG_URL } from '../../../constants.js'
import DeleteModal from './../../common/DeleteModal';
import MessagesContainer from '../../messages/MessagesContainer';
import Utils from '../../../utils/Utils.js';
import LangDropdown from './../../common/LangDropdown';
import Select from 'react-select'

class AddEditRegionForm extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const props = this.props;
        return (
            <form onSubmit={props.onSubmit}>
                <MessagesContainer />
                <div className="card-body">
                    <div className="row">
                        <div className="col-sm-12">
                            <h3>{props.translations.registered_user_discount.how_it_works}</h3>
                            <p>{props.translations.registered_user_discount.description}</p>        
                        </div>

                    
                        <div className="col-sm-12">
                            <div className="form-group">
                                <label htmlFor="name">{props.translations.common.discount_value}</label>
                                <input className={`form-control`} id="name" type="text" regionholder={props.translations.common.discount_amount} name="discount_amount" onChange={props.onChange} value={props.discount.discount_amount} />
                            </div>
                        </div>

                        <div className="col-sm-12">
                            <div className="form-group">
                                <label htmlFor="name">{props.translations.common.discount_type}</label>

                                <select name="discount_type" value={props.discount.discount_type} onChange={props.onChange} className="form-control">
                                                                    <option value="money">лв.</option>
                                                                    <option value="percent">%</option>
                                                                </select>
                            </div>
                        </div>

                        <div className="col-sm-12">
                            <div className="form-group">
                                <label htmlFor="name">{props.translations.common.on_order_number}</label>
                                <input className={`form-control`} id="name" type="number" regionholder={props.translations.common.on_order_number} name="on_order_number" onChange={props.onChange} value={props.discount.on_order_number} />
                            </div>
                        </div>
                    </div>
{/* 
                    <div className="row">
                        <div className="form-group col-sm-12">
                            <label htmlFor="parentId">{props.translations.common.region}</label>
                            <Select name="parentId" options={autocompleteItems} onChange={props.onChangeSelectAutocompleteItems} value={selectedParent} 
                                regionholder={"Client"}
                            />
                        </div>
                    </div> */}

                    <hr />
                    <br />

                    <div className="form-actions">
                        <button className="fright btn btn-primary" type="submit">
                            {props.translations.common.save}
                        </button>
                    </div>

                </div>

            </form>
        )
    }
}

export default AddEditRegionForm;