import axios from 'axios';
import { LIST_CATEGORIES, ADD_EDIT_CATEGORY_ERROR, ADD_EDIT_CATEGORY_TRANSLATION_ERROR } from './types';
import { API_URL, ADMIN_URL } from './../constants';
import { addErrorMessages, addSuccessMessage } from './../components/messages/messages.actions';
import Utils from './../utils/Utils';

export const listCategories = () => dispatch => {
    axios
        .get(API_URL + 'categoriesWithSubcategories')
        .then(res =>
            dispatch({
                type: LIST_CATEGORIES,
                payload: res.data
            })
        ).catch(error => {
            console.log('erroring ', error)
            throw (error);
        });
}

export const addCategory = (postData, history, imageData, langId) => dispatch => {
    axios
        .post(API_URL + 'categories', postData)
        .then(post => {
                post = post.data;
                dispatch(addEditCatTranslation(post.id, langId, postData));
                dispatch(addImage(imageData, post.id, history));

                dispatch(addSuccessMessage())
            }
        )
        .catch(function (error) {
            dispatch(addErrorMessages(error.response.data.error.errors));
        });
}

export const addEditCatTranslation = (cat_id, lang_id, data) => dispatch => {
    axios
        .post(API_URL + `categories/${cat_id}/translations/${lang_id}`, data)
        .then(res => {

        })
        .catch(err => {

        })
}

export const addImage = (file, subcatId, history) => dispatch => {
    if (file == null) {
        history.push(ADMIN_URL + 'categories');
        return;
    }

    axios
        .post(API_URL + `categories/${subcatId}/upload`, file)
        .then(function (res) {
            if (res.error) {
                //TODO image not uploaded Toastr
            }
            else {
                // history.push(ADMIN_URL + 'products_admin');
            }
            history.push(ADMIN_URL + 'categories');
        }).catch(e =>
            history.push(ADMIN_URL + 'categories')
        )
}

export const editCategory = (postData, history, imageData, langId) => dispatch => {
    axios
        .put(API_URL + 'categories/' + postData.id, postData)
        .then((post) => {
            post = post.data;
            dispatch(addEditCatTranslation(post.id, langId, postData));

            dispatch(addImage(imageData, post.id, history));
        })
        .catch(function (error) {
            // dispatch(addErrorMessages(error.response.data));
            dispatch(addErrorMessages(error.response.data.error.errors));
        })
}

export const removeCategory = (id, history) => dispatch => {
    axios
        .put(API_URL + 'categories/' + id, { deleted: true })
        .then(
            function (res) {
                if (history) {
                    // Deleting from /edit
                    history.push(ADMIN_URL + "categories")
                } else {
                    // Deleting from /list
                    dispatch(listCategories())
                }
            }
        )
        .catch(function (e) {
            console.log("error", e);
        });
}
