import { LIST_PRODUCTS, ADD_EDIT_PRODUCT_ERROR, ADD_EDIT_PRODUCT_SUCCESS, ADD_EDIT_VARIANT_SUCCESS, SET_PRODUCT_FILTER, CLEAR_PRODUCT_FILTER } from '../actions/types';

const initialState = {
    products: {},
    errors: [],
    messages: [],
    filter: {},
}

export default function (state = initialState, action) {
    switch (action.type) {
        case LIST_PRODUCTS:
            return {
                ...state,
                products: action.payload,
                errors: [] // Reset error
            }
        case ADD_EDIT_PRODUCT_ERROR:
            return {
                ...state,
                errors: action.payload
            }
        case ADD_EDIT_PRODUCT_SUCCESS:
            return {
                ...state,
                messages: action.payload
            }
        case ADD_EDIT_VARIANT_SUCCESS:
            return {
                ...state,
                messages: action.payload
            }
        case SET_PRODUCT_FILTER:
            let currFilter = state.filter;
            if (currFilter && Object.keys(currFilter).length > 0) {
                currFilter = JSON.parse(state.filter);
            }
            currFilter[action.payload.name] = action.payload.value;

            return {
                ...state,
                filter: JSON.stringify(currFilter)
            }
        case CLEAR_PRODUCT_FILTER:
            return {
                ...state,
                filter: {}
            }
        default:
            return state;
    }
}