import { LIST_LANGUAGES, ADD_EDIT_LANGUAGE_ERROR } from '../actions/types';

const initialState = {
    languages: [],
    errors: [],
}

export default function(state = initialState, action) {
    switch(action.type) {
        case LIST_LANGUAGES:
            return {
                ...state,
                languages: action.payload,
                errors: [] // Reset add/edit categories error
            }
        case ADD_EDIT_LANGUAGE_ERROR:
            return {
                ...state,
                errors: action.payload
            }
        default:
            return state;
    }
}