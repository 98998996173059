import React from 'react'
import { connect } from 'react-redux';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { updateVersion, viewVersionOpened } from './../versions/actions/versions.action'

const ViewVersionFeaturesDialog = (props) => {
    // console.log(`props.versions`, props.versions)
    // console.log(`props.viewVersionOpenedId`, props.viewVersionOpenedId)
    const currentVersion = props.versions && props.viewVersionOpenedId ? props.versions.find(v => v.id == props.viewVersionOpenedId) : [];

    return (
        <Dialog
            open={props.viewVersionOpenedId}
            onClose={() => props.viewVersionOpened(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className={"mui-dialog-title-info"}
        >
            <DialogTitle id="alert-dialog-title">
                {props.translations.versions.features_about_version}&nbsp;{currentVersion && currentVersion.version ? currentVersion.version : ''}
            </DialogTitle>
            <DialogContent className={"mui-dialog-content"}>
                <DialogContentText id="alert-dialog-description">
                    {currentVersion && currentVersion.features ?
                        Object.values(JSON.parse(currentVersion.features)).map(f =>
                            <li>{f}</li>
                        )
                        :
                        ''
                    }
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => props.viewVersionOpened(false)}
                    className={"mui-color-secondary"} autoFocus>
                    {props.translations.common.close}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
// export default ViewVersionFeaturesDialog;

const mapStateToProps = (state, ownProps) => {
    return {
        viewVersionOpenedId: state.versions.viewVersionOpenedId,
        translations: state.lang,
        versions: state.versions.versions
    }
};

export default connect(mapStateToProps, { updateVersion, viewVersionOpened })(ViewVersionFeaturesDialog);
