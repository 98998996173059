import React from 'react'
import { NavLink } from 'react-router-dom';
import { ADMIN_URL } from '../../../constants.js'
import DeleteModal from './../../common/DeleteModal';
import moment from 'moment';

const ListAnswerRow = (props) => (
    <tr key={props.answer.id}>
        <td>{moment(props.answer.createdAt).format("D-M-Y HH:mm")}</td>
        <td>
            {props.answer.feedbackquestion.text}
        </td>
        <td style={{maxWidth: 250}}>
            {/* If not added translation for the defailt language */}
            {/* {props.translations.feedback.answers.answer_types[props.answer.type]} */}
            
            {props.answer.feedbackquestion.type == 'text' ? props.answer.text : [...Array(props.answer.stars).keys()].map(i => {
                return <span><i class="fas fa-star" aria-hidden="true"></i></span>
            })}
        </td>
        <td>
            <div className="col-md-2 col-form-label">
                <label className="switch switch-label switch-pill switch-outline-primary-alt">
                    <input className="switch-input" type="checkbox" name="approved" onChange={(e) => props.onChangeCheckboxApproved(e, props.answer.id)} checked={props.answer.approved} />
                    <span className="switch-slider" data-checked={props.translations.common.yes} data-unchecked={props.translations.common.no}></span>
                </label>
            </div>
            {/* {props.answer.approved ? <span className="badge badge-success">{props.translations.common.active}</span> : <span className="badge badge-secondary">{props.translations.common.inactive}</span>} */}
        </td>
        <td>
            {props.answer && props.answer.user ?
                <React.Fragment>
                    {props.answer.user.fullname || ""}
                    <br/>
                    <a href={"mailto:"+ props.answer.user.email || ""}>
                        {props.answer.user.email || ""}
                    </a>
                </React.Fragment> 
            : 
                "-"
            }
        </td>
        <td>
            {props.answer && props.answer.table && props.answer.table.table_num || "-"}
        </td>
        {/* <td>
            <NavLink to={ADMIN_URL+'feedback/answers/edit/' + props.answer.id}>
                <button className="btn btn-pill btn-link" type="button">
                    <i className="fas fa-edit"></i>
                </button>
            </NavLink>
            
            <button className="btn btn-pill btn-link" type="button" data-toggle="modal" data-target={"#dangerModal" + props.answer.id} >
                <i className="fas fa-trash"></i>
            </button>

            <DeleteModal
                id={props.answer.id}
                title={props.answer.name}
                deleteFunc={props.deleteFunc}
                translations={props.translations}
            />

        </td> */}
    </tr>
)

export default ListAnswerRow;