import axios from 'axios';
import { LIST_DELIVERY_RADIUSES } from './types';
import { API_URL, ADMIN_URL } from './../../../constants';
import { addSuccessMessage, addErrorMessages} from './../../messages/messages.actions';
import { changeLanguage } from './../../../actions/languagesActions';
import Auth from './../../../utils/Auth';

export const listDeliveryRadiuses = () => dispatch => {
    let getParam = '';
    getParam += "?place_id=" + Auth.getUserPlace();

    axios
        .get(API_URL + 'delivery-radiuses' + getParam)
        .then(res => {
            dispatch({
                type: LIST_DELIVERY_RADIUSES,
                payload: res.data
            });
        })
        .catch(error => {
            throw (error);
        });
}

export const setDeliveryRadiuses = (data) => dispatch => {
    axios
    .post(API_URL + 'delivery-radiuses', data)
    .then(res => {
        dispatch(listDeliveryRadiuses());
        dispatch(addSuccessMessage("Successfully changed"))

    })
    .catch(error => {
        throw (error);
    });
}