import axios from 'axios';
import { COUNT_PEOPLE_SAW_THE_MENU, GET_AVG_TIME_SPENT_PER_PAGE, COUNT_TOTAL_UNIQUE_USERS, 
    TOTAL_ORDERS_AND_AMOUNT, ORDERS_COUNT_BY_DATE, MOST_ORDERED_PRODUCT_VARIANTS} from './types';
import { API_URL, ADMIN_URL } from './../../../constants';
import { addSuccessMessage, addErrorMessages } from './../../messages/messages.actions';
import { changeLanguage } from './../../../actions/languagesActions';
import Auth from './../../../utils/Auth';
import moment from 'moment';

export const countPeopleSawTheMenu = (place_id = null, startDate = null, endDate = null) => dispatch => {
    let query = ``;

    if(place_id == null) {
        query = `?place_id=${Auth.getUserPlace()}`;
    } else {
        query = `?place_id=${place_id}`;
    }

    if(startDate) {
        startDate = moment(startDate).format('YYYY-MM-DD')
        query += `&start_date=${startDate}`;
    }
    if(endDate) {
        endDate = moment(endDate).format('YYYY-MM-DD') + " 23:59";
        query += `&end_date=${encodeURIComponent(endDate)}`;
    }
    
    axios
        .get(API_URL + `statistics/count_people_saw_the_menu${query}`)
        .then(res => {
            dispatch({
                type: COUNT_PEOPLE_SAW_THE_MENU,
                payload: res.data
            });
        })
        .catch(error => {
            throw (error);
        });
}

export const countTotalUniqueUsers = (place_id = null, startDate = null, endDate = null) => dispatch => {
    let query = ``;

    if(place_id == null) {
        query = `?place_id=${Auth.getUserPlace()}`;
    } else {
        query = `?place_id=${place_id}`;
    }

    if(startDate) {
        startDate = moment(startDate).format('YYYY-MM-DD')
        query += `&start_date=${startDate}`;
    }
    if(endDate) {
        endDate = moment(endDate).format('YYYY-MM-DD') + " 23:59";
        query += `&end_date=${encodeURIComponent(endDate)}`;
    }

    axios
        .get(API_URL + `statistics/count_total_unique_users${query}`)
        .then(res => {
            dispatch({
                type: COUNT_TOTAL_UNIQUE_USERS,
                payload: res.data
            });
        })
        .catch(error => {
            throw (error);
        });
}

export const getAvgTimeSpentPerPage = (place_id = null, startDate = null, endDate = null) => dispatch => {
    let query = ``;

    if(place_id == null) {
        query = `?place_id=${Auth.getUserPlace()}`;
    } else {
        query = `?place_id=${place_id}`;
    }

    if(startDate) {
        startDate = moment(startDate).format('YYYY-MM-DD')
        query += `&start_date=${startDate}`;
    }
    if(endDate) {
        endDate = moment(endDate).format('YYYY-MM-DD') + " 23:59";
        query += `&end_date=${encodeURIComponent(endDate)}`;
    }


    axios
        .get(API_URL + `statistics/avg_time_spent_per_page${query}`)
        .then(res => {
            dispatch({
                type: GET_AVG_TIME_SPENT_PER_PAGE,
                payload: res.data
            });
        })
        .catch(error => {
            throw (error);
        });
}

export const countOrdersAndAmount = (place_id = null, startDate = null, endDate = null) => dispatch => {
    let query = ``;

    if(place_id == null) {
        query = `?place_id=${Auth.getUserPlace()}`;
    } else {
        query = `?place_id=${place_id}`;
    }

    if(startDate) {
        startDate = moment(startDate).format('YYYY-MM-DD')
        query += `&start_date=${startDate}`;
    }
    if(endDate) {
        endDate = moment(endDate).format('YYYY-MM-DD') + " 23:59";
        query += `&end_date=${encodeURIComponent(endDate)}`;
    }

    axios
        .get(API_URL + `statistics/count_orders_and_amount${query}`)
        .then(res => {
            dispatch({
                type: TOTAL_ORDERS_AND_AMOUNT,
                payload: res.data
            });
        })
        .catch(error => {
            throw (error);
        });
}

export const getOrdersCountByDate = (place_id = null, startDate = null, endDate = null) => dispatch => {
    let query = ``;

    if(place_id == null) {
        query = `?place_id=${Auth.getUserPlace()}`;
    } else {
        query = `?place_id=${place_id}`;
    }

    if(startDate) {
        startDate = moment(startDate).format('YYYY-MM-DD')
        query += `&start_date=${startDate}`;
    }
    if(endDate) {
        endDate = moment(endDate).format('YYYY-MM-DD') + " 23:59";
        query += `&end_date=${encodeURIComponent(endDate)}`;
    }

    axios
        .get(API_URL + `statistics/orders_count_by_date${query}`)
        .then(res => {
            dispatch({
                type: ORDERS_COUNT_BY_DATE,
                payload: res.data
            });
        })
        .catch(error => {
            throw (error);
        });
}


export const getMostOrderedProductVariants = (place_id = null, startDate = null, endDate = null) => dispatch => {
    let query = ``;

    if(place_id == null) {
        query = `?place_id=${Auth.getUserPlace()}`;
    } else {
        query = `?place_id=${place_id}`;
    }

    if(startDate) {
        startDate = moment(startDate).format('YYYY-MM-DD')
        query += `&start_date=${startDate}`;
    }
    if(endDate) {
        endDate = moment(endDate).format('YYYY-MM-DD') + " 23:59";
        query += `&end_date=${encodeURIComponent(endDate)}`;
    }

    axios
        .get(API_URL + `statistics/most_ordered_product_variants${query}`)
        .then(res => {
            dispatch({
                type: MOST_ORDERED_PRODUCT_VARIANTS,
                payload: res.data
            });
        })
        .catch(error => {
            throw (error);
        });
}