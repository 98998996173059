// Categories
export const LIST_CATEGORIES = 'LIST_CATEGORIES';
// export const ADD_CATEGORY = 'ADD_CATEGORY'; //Not used
export const ADD_EDIT_CATEGORY_ERROR = 'ADD_EDIT_CATEGORY_ERROR';
export const ADD_EDIT_CATEGORY_TRANSLATION_ERROR = 'ADD_EDIT_CATEGORY_TRANSLATION_ERROR';
export const GET_CATEGORY_SAMPLE_IMAGES = 'GET_CATEGORY_SAMPLE_IMAGES';

// Subcategories
export const LIST_SUBCATEGORIES = 'LIST_SUBCATEGORIES';
export const ADD_EDIT_SUBCATEGORY_ERROR = 'ADD_EDIT_SUBCATEGORY_ERROR';
export const ADD_EDIT_SUBCATEGORY_TRANSLATION_ERROR = 'ADD_EDIT_SUBCATEGORY_TRANSLATION_ERROR'

// Products
export const LIST_PRODUCTS = 'LIST_PRODUCTS';
export const ADD_EDIT_PRODUCT_TRANSLATION_ERROR = 'ADD_EDIT_PRODUCT_TRANSLATION_ERROR';
export const ADD_EDIT_PRODUCT_SUCCESS = 'ADD_EDIT_PRODUCT_SUCCESS';
export const ADD_EDIT_PRODUCT_ERROR = 'ADD_EDIT_PRODUCT_ERROR';
export const SET_PRODUCT_FILTER = 'SET_PRODUCT_FILTER';
export const CLEAR_PRODUCT_FILTER = 'CLEAR_PRODUCT_FILTER';

// Tables
export const LIST_TABLES = 'LIST_TABLES';
export const LIST_TABLES_FOR_ORDERS = 'LIST_TABLES_FOR_ORDERS';
export const ADD_EDIT_TABLE_SUCCESS = 'ADD_EDIT_TABLE_SUCCESS';
export const ADD_EDIT_TABLE_ERROR = 'ADD_EDIT_TABLE_ERROR';

//Product variants
export const ADD_EDIT_VARIANT_ERROR = 'ADD_EDIT_VARIANT_ERROR';
export const ADD_EDIT_VARIANT_SUCCESS = 'ADD_EDIT_VARIANT_SUCCESS';

// Settings
export const LIST_SETTINGS = 'LIST_SETTINGS';

// Settings - Languages
export const LIST_LANGUAGES = 'LIST_LANGUAGES';
export const ADD_EDIT_LANGUAGE_ERROR = 'ADD_EDIT_LANGUAGE_ERROR';

export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const LOGOUT = 'LOGOUT';
// Users
export const LIST_USERS = 'LIST_USERS';
export const LIST_REGISTERED_USERS = 'LIST_REGISTERED_USERS';
export const ADD_EDIT_USER_ERROR = 'ADD_EDIT_USER_ERROR';
export const LIST_USER_ROLES = 'LIST_USER_ROLES';
// Orders
export const LIST_ORDERS = 'LIST_ORDERS';
export const LIST_USER_NEEDS = 'LIST_USER_NEEDS';
export const LOADING_ORDERS_START = 'LOADING_ORDERS_START';
export const LOADING_ORDERS_END = 'LOADING_ORDERS_END';

// CAT hierarchy
export const LIST_CATEGORIES_HIERARCHY = 'LIST_CATEGORIES_HIERARCHY';
export const ADD_EDIT_CATEGORY_HIERARCHY_ERROR = 'ADD_EDIT_CATEGORY_HIERARCHY_ERROR';

// Admin common settings
export const SET_SOUND_ACTIVATION = 'SET_SOUND_ACTIVATION';
export const CHANGE_ORDER_STYLE_TYPE = 'CHANGE_ORDER_STYLE_TYPE';
export const OPEN_CONFIRM_MODAL = 'OPEN_CONFIRM_MODAL';
export const CLOSE_CONFIRM_MODAL = 'CLOSE_CONFIRM_MODAL';

// Snackbar
export const SET_SNACKBAR_TEXT = 'SET_SNACKBAR_TEXT';