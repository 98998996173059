import { COUNT_PEOPLE_SAW_THE_MENU, GET_AVG_TIME_SPENT_PER_PAGE, COUNT_TOTAL_UNIQUE_USERS, 
    TOTAL_ORDERS_AND_AMOUNT, ORDERS_COUNT_BY_DATE, MOST_ORDERED_PRODUCT_VARIANTS} from '../actions/types';

const initialState = {
    people_saw_the_menu_count: [],
    avg_time_spent_per_page: [],    
    total_unique_users: [],
    total_orders_and_amount: [],
    orders_count_by_date: [],
    most_ordered_product_variants: [],
}

export default function(state = initialState, action) {
    switch(action.type) {
        case COUNT_PEOPLE_SAW_THE_MENU: {
            return {
                ...state,
                people_saw_the_menu_count: action.payload,
            }
        }
        case GET_AVG_TIME_SPENT_PER_PAGE: {
            return {
                ...state,
                avg_time_spent_per_page: action.payload
            }
        }
        case COUNT_TOTAL_UNIQUE_USERS: {
            return {
                ...state,
                total_unique_users: action.payload
            }
        }
        case TOTAL_ORDERS_AND_AMOUNT: {
            return {
                ...state,
                total_orders_and_amount: action.payload
            }
        }
        case ORDERS_COUNT_BY_DATE: {
            return {
                ...state,
                orders_count_by_date: action.payload
            }
        }
        case MOST_ORDERED_PRODUCT_VARIANTS: {
            return {
                ...state,
                most_ordered_product_variants: action.payload
            }
        }
        default:
            return state;
    }
}