import React, { Component } from 'react'
import AddEditDeliveryShapeForm from './AddEditDeliveryShapeForm';
import PolygonMap from './PolygonMap';
import { listDeliveryShapes, addEditDeliveryShape } from './../actions/deliveryShapes.actions';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { ADMIN_URL } from './../../../constants';
import Utils from './../../../utils/Utils';

let md5 = require('md5');

class EditDeliveryShapePage extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			id: this.props.match.params.id,
			name: this.props.deliveryShape.name ? this.props.deliveryShape.name : '',
			priority: this.props.deliveryShape.priority ? this.props.deliveryShape.priority : '',
			min_order_price: this.props.deliveryShape.min_order_price ? this.props.deliveryShape.min_order_price : '',
			delivery_price: this.props.deliveryShape.delivery_price ? this.props.deliveryShape.delivery_price : '',
			required_order_price_for_free_delivery: this.props.deliveryShape.required_order_price_for_free_delivery ? this.props.deliveryShape.required_order_price_for_free_delivery : '',
			coords: this.props.deliveryShape.coordinates ? this.props.deliveryShape.coordinates : [],
			active: this.props.deliveryShape.active ? this.props.deliveryShape.active : 1,
		}

		// this.onChange = this.onChange.bind(this);
		// this.onChangeCheckbox = this.onChangeCheckbox.bind(this);
		// this.onSubmit = this.onSubmit.bind(this);
	}

	componentDidMount() {
        this.props.listDeliveryShapes();
    }

	componentWillReceiveProps(nextProps) {

		let coords = "";
		if(nextProps.deliveryShape.coordinates) {
			nextProps.deliveryShape.coordinates.map(coord => {
				coords += coord.lat + ", " + coord.lng + "\n";
			})
		}

		this.setState({
			id: nextProps.match.params.id,
			name: nextProps.deliveryShape.name ? nextProps.deliveryShape.name : '',
			priority: nextProps.deliveryShape.priority ? nextProps.deliveryShape.priority : '',
			min_order_price: nextProps.deliveryShape.min_order_price ? nextProps.deliveryShape.min_order_price : '',
			delivery_price: nextProps.deliveryShape.delivery_price ? nextProps.deliveryShape.delivery_price : '',
			required_order_price_for_free_delivery: nextProps.deliveryShape.required_order_price_for_free_delivery ? nextProps.deliveryShape.required_order_price_for_free_delivery : '',
			coords: coords, //nextProps.deliveryShape.delivery_shape_coordinates ? nextProps.deliveryShape.delivery_shape_coordinates : [],
			
			active: nextProps.deliveryShape.active ? nextProps.deliveryShape.active : 1,
		});
	}
	onChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
	}

	onChangeField = (fieldName, fieldValue) => {
		this.setState({ [fieldName]: fieldValue });
	}

	onChangeCheckbox = (e) => {
		this.setState({ [e.target.name]: e.target.checked });
	}
	
	validate() {
		let errors = [];

		if (this.state.name.length === 0) {
			errors.push(this.props.translations.messages.errors.invalid_name);
		}
		return errors;
	}

	onSubmit = (e) => {
		e.preventDefault();

		const errors = this.validate();
		if (errors.length > 0) {
			this.props.addErrorMessages(errors);
			return;
		}

		let coords = this.state.coords
        coords = coords.replace(" ", '');
        coords = coords.split('\n')

        console.log(coords)
        //|| coords[0]*1 != coords[coords.length - 1]
        if(coords.length < 3 ) {
			this.props.addErrorMessages(["Invalid coords"]);
            return;
        }

        let coordsToSend = [];
        coords.map(c => {
			if(c.length != 0 && c.includes(",")) {
				let cc = c.split(",");

				coordsToSend.push({
					lat: cc[0]*1,
					lng: cc[1]*1,
					// placeId: Auth.getUserPlace()
				})
			} 
        })

		const post = {
			id: this.state.id,
			name: this.state.name,
			priority: this.state.priority,
			min_order_price: this.state.min_order_price,
			delivery_price: this.state.delivery_price,
			required_order_price_for_free_delivery: this.state.required_order_price_for_free_delivery,
			coords: coordsToSend,
			active: this.state.active,
		}

		this.props.addEditDeliveryShape(post, this.props.history);

	}

	deleteDeliveryShape = (e, id) => {
		e.preventDefault();
		let data = {
			id: id,
			deleted: true
		};
		this.props.addEditDeliveryShape(data);
		Utils.navigateTo(ADMIN_URL + 'deliveryShapes')
	}


	render() {

		return (
			<main className="main">

				{/* <!-- Breadcrumb--> */}
				<ol className="breadcrumb">
					<li className="breadcrumb-item"><NavLink to={ADMIN_URL}>{this.props.translations.common.dashboard}</NavLink></li>
					<li className="breadcrumb-item"><NavLink to={ADMIN_URL + 'settings'}>{this.props.translations.sidemenu.settings}</NavLink></li>
					<li className="breadcrumb-item"><NavLink to={ADMIN_URL + 'settings/category/6'}>{this.props.translations.sidemenu.orders}</NavLink></li>
					<li className="breadcrumb-item"><NavLink to={ADMIN_URL + "delivery-shapes"}>{this.props.translations.delivery_shapes.title}</NavLink></li>
					<li className="breadcrumb-item active">{this.props.translations.delivery_shapes.edit}</li>
				</ol>

				<div className="container-fluid">
					<div className="animated fadeIn">
						<div className="row">

							{/* <!-- /.col--> */}
							<div className="col-sm-12">
								<div className="card">

									<AddEditDeliveryShapeForm
										onSubmit={this.onSubmit}
										onChange={this.onChange}
										onChangeField={this.onChangeField}
										onChangeCheckbox={this.onChangeCheckbox}
										deliveryShape={this.state}
										deleteDeliveryShape={this.deleteDeliveryShape}
										actionText="Запази"
										isAddingDeliveryShape="0"
										translations={this.props.translations}
									/>

									

								</div>
							</div>

						</div>
					</div>
				</div>

			</main>
		)
	}
}

const mapStateToProps = (state, ownProps) => {
	let deliveryShapeId = ownProps.match.params.id;

	let deliveryShape = state.deliveryShapes.deliveryShapes.find(s => s.id == deliveryShapeId);

	if (!deliveryShape) deliveryShape = {
		id: '',
		name: '',
		priority: '',
		min_order_price: '',
		delivery_price: '',
		required_order_price_for_free_delivery: '',
		coords: [],
		active: 1,
	};
	return {
		deliveryShape: deliveryShape,
		// errors: state.deliveryShapes.errors,
		translations: state.lang,
		languages: state.languages.languages || {},
		currLang: state.settings.settings['default_lang'] && state.settings.settings['default_lang'].value || 'bg',

	}


};

export default connect(mapStateToProps, { listDeliveryShapes, addEditDeliveryShape })(EditDeliveryShapePage);