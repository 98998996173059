import React from 'react'
import { NavLink } from 'react-router-dom';
import { Multiselect } from 'multiselect-react-dropdown';
import Auth from './../../../utils/Auth';
import Select from 'react-select'

import DatePicker from "react-datepicker";

const Filter = (props) => {
    // const [startDate, setStartDate] = useState(new Date());
    let placesOptions = [];
    let currPlace = props.places && props.places.find(p => p.id == Auth.getUserPlace()) || null;
    let currClientId = 0;
    if(currPlace) {
        currClientId = currPlace.clientId;
    }

    if(Auth.hasAccess("SUPER_ADMIN")) {
        placesOptions.push({ 'value': 'all',  'label': 'All' });
    }

    Object.values(props.places).map(place => {
        if (place.active == 1) {
            placesOptions.push({ 'value': place.id,  'label': place.name });
        }
    });

    return (
        // <div className="container-fluid p-0">
            // <div className="animated fadeIn">
                <div className="row">
                    <div className="col-md-3">
                        <label>{props.translations.common.start_date}</label>
                        <DatePicker
                            className="form-control"
                            selected={props.filter_start_date}
                            onChange={date => props.onDateChange('filter_start_date', date)}
                            dateFormat="d-M-Y"
                            style={{ display: 'block' }}
                            // onCalendarClose={handleCalendarClose}
                            // onCalendarOpen={handleCalendarOpen}
                        />
                    </div>
                    
                    <div className="col-md-3">
                        <label>{props.translations.common.end_date}</label>
                        <DatePicker
                            className="form-control"
                            selected={props.filter_end_date}
                            onChange={date => props.onDateChange('filter_end_date', date)}
                            dateFormat="d-M-Y"
                            // onCalendarClose={handleCalendarClose}
                            // onCalendarOpen={handleCalendarOpen}
                        />
                    </div>


                    {Auth.hasAccess("CLIENT_ADMIN") ?
                        <div className="col-md-3">
                            <label htmlFor="place">{props.translations.common.place}</label>
                            <Select name="place" options={placesOptions} placeholder={""}
                                onChange={(valueLabel) => props.onChangeSelectAutocomplete('filter_place', valueLabel)} 
                            />
                        </div>
                    :
                        null
                    }
                    
                    <div className="col-md-3">
                        <button className="btn btn-primary" onClick={props.filter}>{props.translations.common.filter}</button>
                    </div>

                    <hr/>
                </div>
            // </div>
        // </div>
    )
}

export default Filter;