import React from 'react'
import { NavLink } from 'react-router-dom';
import { ADMIN_URL } from '../../../constants.js'
import DeleteModal from './../../common/DeleteModal';

const ListDeliveryShapeRow = (props) => (
    <tr key={props.deliveryShape.id}>
        <td>
            {/* If not added translation for the defailt language */}
            {props.deliveryShape.name}
        </td>
        <td>
            {props.deliveryShape.priority}
        </td>
        <td>
            {props.deliveryShape.min_order_price}
        </td>
        <td>
            {props.deliveryShape.delivery_price}
        </td>
        <td>
            {props.deliveryShape.required_order_price_for_free_delivery}
        </td>
        {/* <td>
            {props.deliveryShape.active ? <span className="badge badge-success">{props.translations.common.active}</span> : <span className="badge badge-secondary">{props.translations.common.inactive}</span>}
        </td> */}
        <td>
                <div className="col-md-2 col-form-label">
                    <label className="switch switch-label switch-pill switch-outline-primary-alt">
                        <input className="switch-input" type="checkbox" name="active" onChange={(e) => props.updateActive(e, props.deliveryShape.id)} checked={props.deliveryShape.active} />
                        <span className="switch-slider" data-checked={props.translations.common.yes} data-unchecked={props.translations.common.no}></span>
                    </label>
                </div>
            </td>
        <td>
            <NavLink to={ADMIN_URL+'delivery-shapes/edit/' + props.deliveryShape.id}>
                <button className="btn btn-pill btn-link" type="button">
                    <i className="fas fa-edit"></i>
                </button>
            </NavLink>
            
            <button className="btn btn-pill btn-link" type="button" data-toggle="modal" data-target={"#dangerModal" + props.deliveryShape.id} >
                <i className="fas fa-trash"></i>
            </button>

            <DeleteModal
                id={props.deliveryShape.id}
                title={props.deliveryShape.name}
                deleteFunc={props.deleteDeliveryShape}
                translations={props.translations}
            />

        </td>
    </tr>
)

export default ListDeliveryShapeRow;