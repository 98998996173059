import { VIEW_VERSION_OPENED, LIST_VERSIONS } from '../actions/types';

const initialState = {
    versions: {},
    viewVersionOpenedId: false,
}

export default function (state = initialState, action) {
    switch (action.type) {
        case LIST_VERSIONS: {
            return {
                ...state,
                versions: action.payload,
            }
        }
        case VIEW_VERSION_OPENED: {
            return {
                ...state,
                viewVersionOpenedId: action.payload,
            }
        }
        default:
            return state;
    }
}