import React, { Component } from 'react'
import { connect } from 'react-redux';
import { addEditAlergen } from '../actions/alergens.action';
import AddEditCategoryForm from './AddEditAlergenForm';
import { NavLink } from 'react-router-dom';
import { ADMIN_URL } from '../../../constants';
import { addErrorMessages } from '../../messages/messages.actions';

class AddAlergenPage extends React.Component {
	constructor(props) {
		super(props)

		this.state = { 
			currLang: this.props.currLang,
			name: '',
			image: '',
		}
	  
		this.onChange = this.onChange.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
	}

	onChange(e) {
		this.setState({ [e.target.name]: e.target.value });
	}
		
	validate() {
		let errors = [];

		if (this.state.name.length === 0) {
			errors.push(this.props.translations.messages.errors.invalid_name);
		}
		return errors;
    }

	onSubmit(e) {
        e.preventDefault();

        const errors = this.validate();
        if(errors.length > 0) {
            this.props.addErrorMessages(errors);
            return;
		}
		
		const post = {
			name: this.state.name,
			image: this.state.image,
		}

		this.props.addEditAlergen(post, this.props.history, this.props.languages[this.state.currLang].id);
	}

	componentWillReceiveProps(nextProps) {
		this.setState({
			name: '',
			image: '',
		});
	}

    render() {
		
    	return (
			<main className="main">

				{/* <!-- Breadcrumb--> */}
				<ol className="breadcrumb">
					<li className="breadcrumb-item"><NavLink to={ADMIN_URL}>{this.props.translations.common.alergens}</NavLink></li>
					{/* <li className="breadcrumb-item">Меню</li> */}
					<li className="breadcrumb-item"><NavLink to={ADMIN_URL + "alergens"}>{this.props.translations.alergens.title}</NavLink></li>
					<li className="breadcrumb-item active">{this.props.translations.alergens.add}</li>
					{/* <!-- Breadcrumb Menu--> */}
					<li className="breadcrumb-menu d-md-down-none">
                        <div className="btn-group" role="group" aria-label="Button group">
                            {/* 
                            <!--<a className="btn" href="./">
                                <i className="icon-graph"></i>  Dashboard</a>
                            <a className="btn" href="#">
                                <i className="icon-settings"></i>  Settings</a>
                            --> 
                            */}
                        </div>
                    </li>
				</ol>

        		<div className="container-fluid">
           			<div className="animated fadeIn">
              			<div className="row">
                
							{/* <!-- /.col--> */}                
							<div className="col-sm-12">
								<div className="card">

									<AddEditCategoryForm
										onSubmit={this.onSubmit}
										onChange={this.onChange}
										alergenName={this.state.name}
										alergenImage={this.state.image}
										isAdding="1"
										nameError={this.props.errors.find(error => error.path === 'name') ? this.props.errors.find(error => error.path === 'name') : "" }
										translations={this.props.translations}
										currLang={this.state.currLang}
										languages={this.props.languages}
									/>

								</div>
							</div>

				  		</div>
				  	</div>
				</div>

			</main>
    	)
  	}
}

const mapStateToProps = state => ({
	errors: state.categories.errors,
	translations: state.lang,
	languages: state.languages.languages || {},
	currLang: state.settings.settings['default_lang'].value,
});

export default connect(mapStateToProps, { addEditAlergen, addErrorMessages })(AddAlergenPage);