import axios from 'axios';
import { LIST_TAGS } from './types';
import { API_URL } from './../../../constants';

export const getTags = () => dispatch => {
    axios
        .get(API_URL + 'tags')
        .then(res => {
            dispatch({
                type: LIST_TAGS,
                payload: res.data
            });
        })
        .catch(error => {
            throw (error);
        });
}