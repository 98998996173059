import axios from 'axios';
import { LIST_PLACE_IMAGES } from './types';
import { API_URL, ADMIN_URL } from './../../../constants';
import { addSuccessMessage, addErrorMessages } from './../../messages/messages.actions';
import { changeLanguage } from './../../../actions/languagesActions';
import Auth from './../../../utils/Auth';

export const listPlaceImages = (place_id = 0) => dispatch => {
    let getParam = '';
    if(place_id == 0) {
        place_id = Auth.getUserPlace();
        getParam += "?place_id=" + place_id;
    }

    axios
        .get(API_URL + 'places/place-images' + getParam)
        .then(res => {
            dispatch({
                type: LIST_PLACE_IMAGES,
                payload: res && res.data || []
            });
        })
        .catch(error => {
            throw (error);
        });
}


export const addEditPlaceImage = (data, history) => dispatch => {
    if(!data.placeId) {
        data.placeId = Auth.getUserPlace();
    }
    
    axios
        .post(API_URL + 'places/place-images', data)
        .then((post) => {
            post = post.data;
                dispatch(addSuccessMessage("Changes saved"))
                // dispatch(listPlaceImages());
                history.push(ADMIN_URL + 'place-images');
        })
        .catch(function (error) {
            // dispatch(addErrorMessages('Error occured'));
        })
}

export const deletePlaceImage = (data) => dispatch => {
    axios
        .post(API_URL + 'places/place-images/delete', data)
        .then((post) => {
            post = post.data;
                dispatch(addSuccessMessage("Place image deleted"))
                dispatch(listPlaceImages());
                // history.push(ADMIN_URL + 'place-images');
        })
        .catch(function (error) {
            // dispatch(addErrorMessages('Error occured'));
        })
}

// export const addEditIngredientTranslation = (ingredient_id, lang_id, data) => dispatch => {
//     axios
//         .post(API_URL + `ingredients/${ingredient_id}/translations/${lang_id}`, data)
//         .then(res => {

//         })
//         .catch(err => {

//         })
// }