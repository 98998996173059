import React, { Component } from 'react'
import { connect } from 'react-redux';
import { listProducts } from './../../../actions/productsActions';
import { addRemovePromoCategoryProduct, editCategoryHierarchy } from './../../../actions/categoriesHierarchyActions';
import MessagesContainer from './../../messages/MessagesContainer';
import Select from 'react-select';
import LangDropdown from '../../common/LangDropdown';

class PromoCategoryProductsTab extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            promoProductsForCategory: [],
            // promo_title: ""
        }
    }

    componentDidMount() {
        this.props.listProducts();
    }

    componentWillReceiveProps(nextProps) {

        if (nextProps.cat && nextProps.cat.promo_category_products && this.props.products) {
            let allProducts = this.props.products;
            let promoProdsForCat = [];

            nextProps.cat.promo_category_products.map(pcp => {
                let prodName = allProducts && allProducts[pcp.productId] && allProducts[pcp.productId].name || ""

                promoProdsForCat.push({
                    value: pcp.productId, label: prodName
                })
            })

            this.setState({
                promoProductsForCategory: promoProdsForCat,
                // promo_title: nextProps.cat.promo_title
            })

        }
    }

    prodsSelectOnChange = (values, { action, removedValue }) => {
        // console.log(values, action, removedValue)
        let data;
        let stateValueToChange = "promoProductsForCategory";   //ex. addable + Values = addableValues in state
        // return;
        switch (action) {
            case 'select-option':
                this.setState({
                    [stateValueToChange]: values
                });
                let productId = values[values.length - 1].value; // take only the newly added ingredient (i.e. the last one )
                data = {
                    category_id: this.props.cat.id,
                    product_id: productId,
                }
                // console.log("DA", data);return;
                this.props.addRemovePromoCategoryProduct('add' == 'add', data);
                break;
            case 'remove-value':
                this.setState({
                    [stateValueToChange]: values
                });
                data = {
                    category_id: this.props.cat.id,
                    product_id: removedValue.value,
                }
                this.props.addRemovePromoCategoryProduct('remove' != 'remove', data);
                break;
                // case 'pop-value':
                //   if (removedValue.isFixed) {
                //     return;
                //   }
                break;
            case 'clear':
                data = {
                    category_id: this.props.cat.id,
                    product_id: 'all',
                }
                this.props.addRemovePromoCategoryProduct('remove' != 'remove', data);

                break;
        }
    }

    getSelectValues = () => {
        return Object.values(this.props.products).filter(p => p.is_promo === true).map(i => {
            return { value: i.id, label: i.name }
        })
    }

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    save = () => {
        let post = {
            id: this.props.cat.id,
            promo_title: this.props.categoryState.promo_title
        }

        this.props.editCategoryHierarchy(post, null, null, this.props.languages[this.props.currLang].id);

    }

    render() {
        const selectValues = this.getSelectValues();
        return (

            <div className="">
                <MessagesContainer />

                <LangDropdown
                    isDisabled={0}
                    currLangOnChange={this.props.currLangOnChange}
                    currLang={this.props.currLang}
                    languages={this.props.languages}
                />
                
                <div className="row">
                    <div className="col-sm-12">
                        <label htmlFor="code">{this.props.translations.categories.promo_title}</label>
                        <div className="input-group">
                            <input className={`form-control`} id="promo_title" type="text" placeholder={this.props.translations.categories.promo_title} name="promo_title" onChange={e => this.props.onFieldChange('promo_title', e.target.value)} value={this.props.categoryState.promo_title} />
                            <span className="input-group-append">
                                <button className="btn btn-primary" onClick={this.save}>{this.props.translations.common.save}</button>
                            </span>
                        </div>
                    </div>
                </div>

                <br />

                <div className="form-group">
                    <label>{this.props.translations.categories.tabs.promo_products_for_category}</label>
                    <Select
                        value={this.state.promoProductsForCategory}
                        isMulti
                        name="colors"
                        options={selectValues}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(values, { action, removedValue }) => this.prodsSelectOnChange(values, { action, removedValue })}
                    />
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        products: state.products.products || [],
        translations: state.lang,
    }
};

export default connect(mapStateToProps, { listProducts, addRemovePromoCategoryProduct, editCategoryHierarchy })(PromoCategoryProductsTab);